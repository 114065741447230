import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-notifications-marketing.html';

/**
 * Marketing notifications settings widget.
 */
class NotificationsMarketingWidget extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-notifications-marketing');
    this._marketingNotificationsFieldset = this.querySelector('.widget-notifications-marketing-fieldset');
    this._title = this.querySelector('.widget-notifications-marketing-title');
    this._receiveMaterialsInput = this.querySelector('.widget-notifications-marketing-materials');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  setData(preferences) {
    if (!!preferences) {
      this._receiveMaterialsInput.setValue(preferences.marketingMaterials === '0');
    }
  }
}

customElements.define('widget-settings-notifications-marketing', withInitDOM(NotificationsMarketingWidget));
