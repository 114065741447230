import i18n from '@sgx/sgx-localisation-service';
import ConfigService from 'sgx-config-service';
import CompanyAnnouncementsAggregator from 'aggregators/company-announcements-aggregator';

const constants = {
  companyAnnouncement: {
    DATASTORE: 'companyAnnouncement',
    serviceConfig: {
      pageStart: 0,
      pageSize: 3,
      searchType: 'announcements',
      searchValue: '',
      // get all announcements from max (rolling window of 2 years till today)
      from: CompanyAnnouncementsAggregator.getValidatedFromDate(null, true, -2),
      to: CompanyAnnouncementsAggregator.getValidatedToDate(null, true, 0),
    },
    headerTitle: i18n.getTranslation('app.widget-list-row-tiles-wrapper.companyAnnouncement.title'),
    footer: {
      link: {
        text: i18n.getTranslation('app.widget-list-row-tiles-wrapper.common.view'),
        href: ConfigService.links.INVESTORS_PORTAL_COMPANY_ANNOUNCEMENT,
      },
      text: i18n.getTranslation('app.widget-list-row-tiles-wrapper.common.updates'),
    },
  },
  marketUpdates: {
    DATASTORE: 'marketUpdates',
    headerTitle: i18n.getTranslation('app.widget-list-row-tiles-wrapper.marketUpdates.title'),
    footer: {
      link: {
        text: i18n.getTranslation('app.widget-list-row-tiles-wrapper.common.view'),
        href: ConfigService.links.INVESTORS_PORTAL_MARKET_RESEARCH,
      },
      text: i18n.getTranslation('app.widget-list-row-tiles-wrapper.common.updates'),
    },
  },
  DateFormat: 'DD MMM YYYY',
};

export { constants };
