import {withInitDOM} from "@sgx/sgx-base-code";
import tmpl from "./widget-stop-paper.html";
import i18n from 'sgx-localisation-service';
import DateUtil from 'utils/date-util';

class WidgetStopPaper extends HTMLElement {

  async connectedCallback() {

  }

  disconnectedCallback() {
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-stop-paper');

    this._title = this.querySelector('.widget-stop-paper-title');
    this._statusTag = this.querySelector('.widget-stop-paper-status-tag');
    this._description = this.querySelector('.widget-stop-paper-description');
    this._date = this.querySelector('.widget-stop-paper-status-date');

    this._title.textContent = i18n.getTranslation('widget-stop-paper.title')
    this._statusTag.setData({text: i18n.getTranslation('widget-stop-paper.subscribed'), icon: 'success'});
  }

  /**
   * Sets the widget data
   * @param {Object} data
   * @param {String} endDate
   * @param {String} data.notificationSubscribeMode With Waiver - 1 | With Permanent Waiver - 2| With Payment - 3
   */
  setData({endDate, notificationSubscribeMode}) {
    // set end date to empty string if subscribed with permanent waiver
    this._date.textContent = notificationSubscribeMode !== '2' ? i18n.getTranslation('widget-stop-paper.endDate') + DateUtil.formatDateTo(endDate, 'YYYY-MM-DD', i18n.getTranslation('app.format.date.default')) : '';
    this._description.innerHTML = notificationSubscribeMode !== '2' ? i18n.getTranslation('widget-stop-paper.desc') : '';

  }
}

customElements.define('widget-stop-paper', withInitDOM(WidgetStopPaper));
