import { withInitDOM } from 'sgx-base-code';
import tmpl from './template-settings-notifications.html';
import { fromEvent } from 'rxjs';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import StoreRegistry from 'stores/store-registry';
import {
  createFormConfigFromSchema,
  addCustomValidators,
  takeSnapshot,
  isPristine,
  isInvalid,
  getFormInputs
} from 'utils/form-util';
import userService from 'services/user-service';
import UserPreferencesService from 'services/user-preferences-service';
import jsonSchema from 'schemas/settings-user';
import i18n from 'sgx-localisation-service';
import { toggleAuthenticatedFeature } from 'utils/auth-util';
import { ACCOUNT_STATUS, ACCOUNT_STATES } from 'services/user-service/src/user-service-constants';
import { isAuthenticated } from 'utils/auth-util';
import ConfigService from 'sgx-config-service';

class TemplateSettingsNotifications extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-settings-notifications');
    this._form = this.querySelector('.template-notifications-form');
    this._actionWidget = this.querySelector('.widget-settings-notifications-actions');
    this._smsWidget = this.querySelector('.widget-settings-notifications-sms');
    this._marketingWidget = this.querySelector('.widget-settings-notifications-marketing');
    this._emailWidget = this.querySelector('.widget-settings-notifications-email');
    this._submitButton = this.querySelector('.widget-notifications-button-submit');
    this._loginPrompt = this.querySelector('cmp-login-prompt');
    this._loginPromptText = this.querySelector('.cmp-login-prompt-text');
    this._indicator = this.querySelector('sgx-status-indicator');
  }

  connectedCallback() {
    this._store = StoreRegistry.settingsNotifications;
    this._cdpStore = StoreRegistry.cdpSession;

    toggleAuthenticatedFeature({
      prompt: this._loginPrompt,
      promptText: i18n.getTranslation('app.settings.login-prompt', {
        link: ConfigService.links.CDP_ACCOUNT_CREATE
      }),
      feature: this._form
    });

    this._subscriptions = [];

    // Set up the validation config
    const validationConfig = this._createValidationConfig();

    // Initialize form with validation config and querySelects all sgx-inputs internally
    this._form.setConfig(validationConfig);

    // Set up the data binding to the store
    this._initDataBinding();

    // Update the checkbox state for market notification
    this.setMarketNotificationStatus();

    if (isAuthenticated()) {
      Promise.all([
        userService.getUserAccounts(),
        UserPreferencesService.getUserNotifications()
      ])
        .then(values => {
          const accounts = values[0];
          const notificationData = values[1];
          const hasSuspendedAccount = accounts.some(account => account.accountStatus === ACCOUNT_STATUS.SUSPENDED);

          const marketing = notificationData.clients;
          const notifications = notificationData.notificationInfo;

          this._marketingWidget.setData(marketing);
          this._smsWidget.setData(notifications);
          this._emailWidget.setData(notifications);

          // If one of user's account is suspended, set form to read only
          if (hasSuspendedAccount) {
            this._actionWidget.setStatus('401');
            getFormInputs(this._form).forEach(input => {
              input.setReadOnly(true);
            })
          }
        })
        .then(() => {
          // Take a snapshot of data in pristine state
          this._pristineData = takeSnapshot(this._form.getJsonData());
        });
    }
  }


  setMarketNotificationStatus() {
    // AccountTypes which are not allowed to edit;
    const readOnlyAccountTypes = [ACCOUNT_STATES.CORPORATE, ACCOUNT_STATES.CORPORATE_TRUSTEE];

    // Readonly status for corporate and corporate-trustee
    this._subscriptions.push(this._cdpStore.subscribe(account => {

      if (readOnlyAccountTypes.includes(account.accountType)) {
        getFormInputs(this._marketingWidget).forEach(input => {
          input.setReadOnly(true);
        });
      }

    }));
  }

  disconnectedCallback() {
    (this._subscriptions || []).map(sub => sub.unsubscribe());
  }

  /**
   * Create a validation config for the form.
   * Also adds the required custom validators to the form.
   * @return {Object} the aggregated validation configuration
   */
  _createValidationConfig() {
    // Reference
    const validate = this._form.validator.validate;

    // Add custom validators here
    this._form.validator.validate.validators = addCustomValidators(this._form.validator.validate.validators);

    // Validation configuration
    this._validationConfig = createFormConfigFromSchema(validate, jsonSchema, 5);

    return this._validationConfig;
  }

  /**
   * Initialize the form data binding to the underlying store.
   */
  _initDataBinding() {

    // Subscribe to form changes
    this._subscriptions.push(fromEvent(this._form, 'change')
      .pipe(
        debounceTime(10),
        switchMap(() => of(this._form.getJsonData())),
      )
      .subscribe(formData => {
        this._store.setData(formData);

        // Enable or disable update button based on whether data is pristine or invalid
        if (isPristine(formData, this._pristineData) || isInvalid(this._form)) {
          this._submitButton.setAttribute('disabled', true);
        } else {
          this._submitButton.removeAttribute('disabled');
        }
      })
    );

    // Disable default form submit event
    this._subscriptions.push(fromEvent(this._form, 'submit')
      .subscribe(e => {
        e.preventDefault();
      })
    );

    // Listen for submit on child form, timeout to allow sgx-form to finish creating child form
    setTimeout(() => {
      this._childForm = this._form.firstElementChild;
      this._subscriptions.push(fromEvent(this._childForm, 'submit')
        .subscribe(e => {

          // Show loading indicator
          this._actionWidget.showLoading();

          // Initialize form update call
          this._sendFormData(this._createFormData());

          // Prevent defaults
          e.preventDefault();
          e.stopImmediatePropagation();
        })
      );
    }, 0);
  }

  /**
   * Create the form data to send to the server.
   * @return {Object} the pre-processed form data
   */
  _createFormData() {
    // Retrieve JSON object
    const content = this._store.getData();
    const pristineState = JSON.parse(this._pristineData);

    for (let key in content) {
      if (content.hasOwnProperty(key)) {
        if (content[key] === pristineState[key]) {
          delete content[key];
        } else {
          content[key] = content[key] ? 'Y' : 'N';
        }
      }
    }

    return content;


    // Set up fields
    /*    let formData = new FormData();

        formData.append('content', JSON.stringify(content));
        return formData;*/
  }

  /**
   * Send the form data to the server.
   * @param {Object} formData the form data to submit
   * @return {Promise} the submission completion promise
   */
  _sendFormData(formData) {
    UserPreferencesService.updateUserPreferences(formData)
      .then(response => {
        // Set status in action widget
        this._actionWidget.setStatus(response.status && response.status.toString());

        // If response is success or pending, reset form state to pristine
        this._pristineData = takeSnapshot(this._store.getData());
        this._submitButton.setAttribute('disabled', true);
      })
      .catch(error => {
        // Set status in action widget
        this._actionWidget.setStatus('500');

        console.warn('Cannot submit form. ', error);
      })
  }
}

customElements.define('template-settings-notifications', withInitDOM(TemplateSettingsNotifications));
