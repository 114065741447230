import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-security-details-financial.html';
import i18n from 'sgx-localisation-service';
import DateService from 'sgx-date-time-service';
import { formatThousands } from 'utils/price-util';
import DeviceService from 'sgx-device-service';

class SecurityDetailsFinancialWidget extends HTMLElement {
  constructor() {
    super();
    this._isIE = DeviceService.isIe();

    this._onBackToTop = this._onBackToTop.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-financial');
    // References
    this._indicator = this.querySelector('.widget-security-details-financial-indicator');
    this._accordion = this.querySelector('.widget-security-details-financial-accordion');
    this._accordionContainer = this.querySelector('.widget-security-details-financial-accordion-container');
    this._tabsContainer = document.querySelector('.widget-security-details-tabs');
    this._timestamp = this.querySelector('.widget-security-details-financial-timestamp');
    this._footer = this.querySelector('cmp-stocks-terms');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
    this._accordion.removeEventListener('click', this._onBackToTop);
  }

  setData(data) {
    const reports = (((data || {}).stockData || {}).financialReports || {}).reports;

    if (reports && reports.length) {
      this._indicator.hide();
    } else {
      this._indicator.show({
        status: 'neutral',
        title: i18n.getTranslation('app.shared-text.status-indicator.empty.description')
      });
      return;
    }

    this._labels = i18n.getTranslation('app.widget-security-details-financial', {returnObjects: true});

    const incomeStatements = reports.map(i => i.incomeStatement);
    const balanceSheets = reports.map(i => i.balanceSheet);
    const cashFlows = reports.map(i => i.cashFlow);
    const ratios = reports.map(i => i.ratios);
    const growths = reports.map(i => i.growths);

    // Header row
    const fiscalYears = [{
      isColumnHeader: true,
      label: this._labels.fiscalYear
    }, ...reports.map(i => ({label: i.fiscalYear}))];

    const commonTableData = [
      [this._labels.periodEnded, ...reports.map(i => DateService(i.endDate).format(i18n.getTranslation('app.securities.format.date.default')))]
    ];

    const incomeTopRows = [
      ...commonTableData,
      this._getRowData('periodLength', incomeStatements, null, this._labels.periodLength),
      this._getRowData('source', incomeStatements, null, this._labels.source)
    ];

    const balanceSheetTopRows = [
      ...commonTableData,
      this._getRowData('source', balanceSheets, null, this._labels.source)
    ];

    const cashTopRows = [
      ...commonTableData,
      this._getRowData('source', cashFlows, null, this._labels.source)
    ];

    const accordionData = [
      this._createAccordionItem('income-statement', incomeStatements, fiscalYears, incomeTopRows, 2),
      this._createAccordionItem('balance-sheet', balanceSheets, fiscalYears, balanceSheetTopRows, 2),
      this._createAccordionItem('cash-flows', cashFlows, fiscalYears, cashTopRows, 2),
      this._createAccordionItem('ratios', ratios, fiscalYears, commonTableData, 3),
      this._createAccordionItem('growths', growths, fiscalYears, commonTableData, 2)
    ];

    this._accordion.setData(accordionData, 'cmp-accordion-item', false);
    this._accordion.addEventListener('click', this._onBackToTop);

    const timestampString = (((data || {}).stockData || {}).financialReports || {}).timestamp;

    if (timestampString) {
      const timestamp = DateService(timestampString).format(i18n.getTranslation('app.widget-security-details-financial.format.date.timestamp'));
      this._timestamp.textContent = timestamp;
    }

    this._footer.classList.remove('sgx-hidden');
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  _createAccordionItem(type, data, headerRow, topRows, dp) {
    const table = document.createElement('sgx-content-table');

    table.setConfig({
      columns: headerRow,
      allowScrolling: true,
      rightAlignValues: true,
      stickyFirstColumn: this._isIE ? false : true,
      stickyHeader: this._isIE ? false : true,
      equalColumnWidth: true
    });

    const tableType = type + '-table';
    // FIXME translation labels and configurations of tables should not be mixed
    const items = i18n.getTranslation('app.widget-security-details-financial.' + tableType, {returnObjects: true});
    const tableData = [
      ...topRows,
      ...this._getTableData(Object.keys(items), data, dp, this._labels[tableType])
    ];
    table.setData(tableData);

    const backToTop = document.createElement('a');
    backToTop.textContent = i18n.getTranslation('app.widget-security-details-financial.backToTop');
    backToTop.classList.add('widget-security-details-financial-back-to-top');

    return {title: this._labels[type], children: [table, backToTop]};
  }

  _getTableData(fields, table, dp, labels) {
    return fields.map(field => this._getRowData(field, table, dp, labels[field].label, labels[field].tooltip));
  }

  _getRowData(field, table, dp, labelText, tooltip) {
    const label = tooltip ? {
      type: 'html',
      value: this._getTooltipHtml(labelText, tooltip)
    } : labelText;

    return [label, ...table.map(i => {
      const val = (i || {})[field];
      if (val == null || val === '') {
        return '-';
      }
      return dp ? formatThousands(val, dp, true) : val;
    })];
  }

  _getTooltipHtml(label, tooltip) {
    return `
      ${label}
      <sgx-tooltip>
        <div data-sgx-tooltip-content>${tooltip}</div>
        <button class="sgx-icon sgx-tooltip-button"></button>
      </sgx-tooltip>
    `;
  }

  _onBackToTop(e) {
    if (e.target.classList.contains('widget-security-details-financial-back-to-top')) {
      const nearestItemHeader = e.target.closest('cmp-accordion-item').firstElementChild;
      this._tabsContainer.scrollTop = nearestItemHeader.offsetTop;
    }
  }
}

customElements.define('widget-security-details-financial', withInitDOM(SecurityDetailsFinancialWidget));
