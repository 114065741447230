import { withInitDOM } from 'sgx-base-code';
import { toFixed } from 'utils/price-util';
import tmpl from './cmp-portfolio-unit-cost.html';
import { fromEvent, merge } from 'rxjs';

class CmpPortfolioUnitCost extends HTMLElement {
  connectedCallback() {
    // Reference
    this._change$ = fromEvent(this._unitCost, 'change');
    this._keyup$ = fromEvent(this._unitCost, 'keyup');
    this._unitCostEvents$ = merge(
      this._change$,
      this._keyup$
    );

    if (!this._subscription) {
      this._subscription = this._unitCostEvents$.subscribe(e => this._onUnitCostChange(e));
    }
  }

  disconnectedCallback() {
    this._subscription && this._subscription.unsubscribe();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    // Reference
    this._unitCost = this.querySelector('#unitCost');
  }

  show(data) {
    this.update(data);
  }

  update(data) {
    const cost = data;
    this._unitCost.value = cost;
    this._unitCost.focus();
    this._unitCost.select();
  }

  getData() {
    const value = this._unitCost.value;
    return !value && value !== 0 ? '' : this._toFixed(value);
  }

  _toFixed(value) {
    return toFixed(value, 3);
  }

  _onUnitCostChange(e) {
    let isValid = !(!!this._unitCost.querySelector('[type="number"]:invalid'));
    if (e.keyCode === 13) {
      e.preventDefault();

      if (!isValid) {
        return;
      }

      this.dispatchEvent(new CustomEvent('unit-cost-submit', {
        bubbles: true
      }));
      return;
    }

    const value = (e.target.value && e.target.value.toString()) || '';

    if (!!~value.indexOf('.')) {
      const decimalCount = value.split('.')[1].length;

      if (decimalCount > 3) {
        isValid = false;
      }
    }

    this.dispatchEvent(new CustomEvent('unit-cost-change', {
      bubbles: true,
      detail: {
        unitCost: this.getData(),
        valid: isValid
      }
    }));
  }
}

customElements.define('cmp-portfolio-unit-cost', withInitDOM(CmpPortfolioUnitCost));
