import { withInitDOM, Utils } from 'sgx-base-code';
import tmpl from './widget-security-details-overview.html';
import i18n from 'sgx-localisation-service';
import SecuritiesService from '@sgx/sgx-securities-service';
import AnnouncementsService from '@sgx/sgx-announcements-service';
import DateService from 'sgx-date-time-service';
import CHART_CONFIG from './conf/widget-security-details-chart-config';

const {extend, debounce} = Utils;
const HISTORIC_TOOLTIP = i18n.getTranslation('app.charts.historic-tooltip-date-formats', {returnObjects: true});
const INTRADAY_TOOLTIP = i18n.getTranslation('app.charts.intraday-tooltip-date-formats', {returnObjects: true});
const SERIES_KEYS = ['prices', 'volumes'];
const INVESTOR_SUITABILITY_PRODUCT_CODES = ['etfs', 'structuredwarrants', 'dlcertificates', 'liprod', 'listedcertificates'];

export default class TemplateSecurityDetailsOverviewWidget extends HTMLElement {
  constructor() {
    super();
    this._parseChartData = this._parseChartData.bind(this);
    this._renderChart = this._renderChart.bind(this);
    this._onPeriodChange = this._onPeriodChange.bind(this);
    this._manageIPOSectionVisibility = this._manageIPOSectionVisibility.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-overview');
    this._chart = this.querySelector('.widget-security-details-overview-chart');
    this._chartLoader = this.querySelector('.widget-security-details-overview-chart-loader');
    this._chartTimestamp = this.querySelector('.widget-security-details-overview-chart-timestamp');
    this._period = this.querySelector('.widget-security-details-overview-chart-period');
    this._pricesDetails = this.querySelector('widget-security-prices-details');
    this._termsFooter = this.querySelector('cmp-stocks-terms');
    this._consensusContainer = this.querySelector('.security-details-consensus');
    this._consensus = this.querySelector('cmp-stocks-consensus');
    this._investorSuitability = this.querySelector('.security-details-investor-suitability');
    this._investorSuitabilityDesc = this.querySelector('.security-details-investor-suitability-description');
    this._ipoPerformanceSection = this.querySelector('.security-details-ipo-performance');
    this._widgetIPO = this.querySelector('.widget-security-details-ipo-performance');
    this._productSpecificationSection = this.querySelector('.security-details-product-specification');
    this._widgetProductSpecification = this.querySelector('widget-security-details-product-specifications');

    this._onPeriodChange = debounce(this._onPeriodChange, 200, this);
    this._onResize = debounce(this._onResize, 200, this);
  }

  connectedCallback() {
    this._period.addEventListener('change', this._onPeriodChange);
    this._widgetIPO.addEventListener('widget-security-details-ipo-performance', this._manageIPOSectionVisibility);
    window.addEventListener('resize', this._onResize);
  }

  disconnectedCallback() {
    this._period.removeEventListener('change', this._onPeriodChange);
    this._widgetIPO.addEventListener('widget-security-details-ipo-performance', this._manageIPOSectionVisibility);
    window.removeEventListener('resize', this._onResize);
  }

  setData(data) {
    this._data = data;
    if (this._period.getOptions().length === 0) {
      this._period.setOptions(['1d', '5d', '1m', '1y', '5y'].map((period, index) => {
        return {
          'value': period,
          'label': i18n.getTranslation(`app.security-details.chart.${period}`),
          'selected': index === 0
        };
      }));
      this._period.setValue('1y');
    }

    this._stockfactsMode = data.isStockfacts;
    this._pricesDetails.setData(data);

    if (this._stockfactsMode) {
      this._termsFooter.classList.remove('sgx-hidden');
      this._consensusContainer.classList.remove('sgx-hidden');
      this._setConsensusData(data.stockData);
    } else {
      this._termsFooter.classList.add('sgx-hidden');
      this._consensusContainer.classList.add('sgx-hidden');
    }
    this._onPeriodChange(); // refresh chart
    this._shouldDisplayInvestorSuitability();
    this._shouldDisplayIPOPerformanceSection();
    this._shouldShowProductSpecification();
  }

  /**
   * @desc manage visibility of IPO Section based on event from ipo performance widget
   * */
  _manageIPOSectionVisibility() {
    const {type} = this._data || {};
    if (['liprod'].includes(type)) {
      this._ipoPerformanceSection.classList.remove('sgx-hidden');
    }
  }

  /**
   * @desc show the IPO Section and create the ipo-performance widget
   * */
  _shouldDisplayIPOPerformanceSection() {
    const {type} = this._data || {};
    if (['etfs', 'adrs', 'liprod'].includes(type)) {
      if (type !== 'liprod') {
        this._ipoPerformanceSection.classList.remove('sgx-hidden');
      }
      this._widgetIPO.setData(this._data);
      return;
    }
    this._ipoPerformanceSection.classList.add('sgx-hidden');
  }

  _shouldShowProductSpecification() {
    const { type, cmsProductDetails } = this._data || {};
    if (type === 'dlcertificates' && cmsProductDetails) {
      this._productSpecificationSection.classList.remove('sgx-hidden');
      this._widgetProductSpecification.setData(this._data);
      return;
    }
    this._productSpecificationSection.classList.add('sgx-hidden');
  }

  _shouldDisplayInvestorSuitability() {
    const { type } = this._data || {};
    const pricesAndChartsTitle = this.querySelector('.widget-security-details--print-title');
    if (INVESTOR_SUITABILITY_PRODUCT_CODES.includes(type)) {
      this._investorSuitability.classList.remove('sgx-hidden');
      this._investorSuitabilityDesc.innerHTML = i18n.getTranslation(`app.security-details.investors-suitability.${type}_html`);
      pricesAndChartsTitle.style.display = 'block';
      return;
    }
    this._investorSuitability.classList.add('sgx-hidden');
    pricesAndChartsTitle.style.removeProperty('display');
  }

  _renderChart(data, flagsConfig) {
    CHART_CONFIG.tooltip.dateTimeLabelFormats = this._chartType === 'historic' ? HISTORIC_TOOLTIP : INTRADAY_TOOLTIP;
    const modifiedConfig = this._updateChartConfigDataAggregation(CHART_CONFIG, this._chartPeriod);
    this._renderChartFromData(modifiedConfig, this._chart, data, SERIES_KEYS, flagsConfig);
    this._period.style.display = 'block';
  }

  _updateChartConfigDataAggregation(baseConfig, period) {
    var units = null;
    var modifiedConfig = {};

    switch (period) {
      case '1d':
        var curTime = DateService.utc();
        modifiedConfig.xAxis = {
          min: curTime.set({'hour': 8, 'minute': 30, 'second': 0}).valueOf(),
          max: curTime.set({'hour': 17, 'minute': 30, 'second': 0}).valueOf(),
          ordinal: false
        };
        units = [[
          'minute', [2]
        ]];
        break;
      case '5d':
        units = [[
          'minute', [15]
        ]];
        break;
      case '1y':
        units = [[
          'day', [1]
        ]];
        break;
      case '5y':
        units = [[
          'week', [1]
        ]];
        break;
      default:
        break;
    }

    modifiedConfig.series = [
      {
        dataGrouping: {
          units: units
        }
      },
      {
        dataGrouping: {
          units: units
        }
      }
    ];

    return extend(true, {}, baseConfig, modifiedConfig);
  }

  _renderChartFromData(chartConfig, chart, data, seriesKeys, flagsConfig) {
    var seriesLength = seriesKeys.length;
    var hasData = false;
    var i;

    // Check that data object exists and each series we are looking for has data in it
    if (data) {
      var hasDataCounter = 0;
      for (i = 0; i < seriesLength; i++) {
        var series = data[seriesKeys[i]];
        if (series && series.length) {
          hasDataCounter++;
        }
      }
      hasData = (seriesLength === hasDataCounter);
    }

    // If all series have data then update the config with them and render the chart
    if (hasData) {

      for (i = 0; i < seriesLength; i++) {
        chartConfig.series[i].data = data[seriesKeys[i]];
      }

      if (flagsConfig) {
        chartConfig.series.push(flagsConfig);
      }

      chart.setData(chartConfig);
      chart.getChartInstance().reflow();
    } else {
      // Clear the chart config and show a no data message if not enough data was found
      for (i = 0; i < seriesLength; i++) {
        chartConfig.series[i].data = [];
      }
      this._renderChartError(chart, chartConfig, i18n.getTranslation('app.message.no-data'));
    }
  }

  _setConsensusData(stockData) {
    if (this._data.nc === this._securityCode) {
      return;
    }
    const {ratiosReport = {}, snapshotReport = {}} = stockData || {};
    const {tradedCurrency} = snapshotReport || {};
    const {consensusAnalystsCount, consensus, targetPrice} = ratiosReport || {};
    this._securityCode = this._data.nc;
    this._consensus.setData({
      analystsCount: consensusAnalystsCount,
      consensus,
      targetPrice,
      tradedCurrency
    });
  }

  _renderChartError(chart, chartConfig, message) {
    chart.setData(chartConfig);
    chart.getChartInstance().reflow();
    chart.getChartInstance().showLoading(message);
  }

  _onResize() {
    this._chart.getChartInstance().reflow();
  }

  _onPeriodChange(e) {
    this._chartPeriod = this._period.getValue();
    this._chartType = this._chartPeriod === '1d' || this._chartPeriod === '5d' ? 'intraday' : 'historic';
    this._chartLoader.show();

    const fetchChartDataPromise = this._fetchChartData({
      'category': this._data.type,
      'chartType': this._chartType,
      'idType': 'code',
      'id': this._data.nc,
      'period': this._chartPeriod
    });
    const fetchChartFlagsDataPromise = this._stockfactsMode ? this._fetchFlagData() : Promise.resolve(null);

    Promise.all([fetchChartDataPromise, fetchChartFlagsDataPromise]).then(responses => {
      this._renderChart(this._parseChartData(responses[0]), responses[1]);
    })
      .catch(_ => {
        this._renderChartError(this._chart, CHART_CONFIG, i18n.getTranslation('app.message.error-display-data'));
      }).then(_ => {
      this._chartLoader.hide();
      this._chartTimestamp.textContent = DateService().format(i18n.getTranslation('app.format.date.timestamp'));
    });
  }

  /**
   * Get data for chart.
   *
   * @param {Object} data the security data
   * @return {Promise<Object>} chart data
   */
  _fetchChartData(data) {
    return SecuritiesService.getChartData(data, {
      params: 'trading_time,vl,lt'
    });
  }

  _parseChartData = function (response) {
    const data = response.data;
    const isHistoric = this._chartType === 'historic';
    const format = CHART_CONFIG.sgxApiDateFormat;
    return data.reduce(function (result, item) {
      var timestamp = DateService.utc(item.trading_time, format);
      timestamp = isHistoric ? timestamp.startOf('d').valueOf() :
        timestamp.add(DateService().utcOffset(), 'm').valueOf();
      result.volumes.push([timestamp, isHistoric ? item.vl : item.vlDif]);
      result.prices.push([timestamp, item.lt]);
      return result;
    }, {
      volumes: [],
      prices: []
    });
  };

  /**
   * Get data for chart flags config object.
   *
   * @return {Promise<Object>} chart flags configuration object
   */
  _fetchFlagData() {
    return AnnouncementsService.getAnnouncements({
      categories: 'ANNC',
      subcategories: 'ANNC17', // Get company announcements that are part of 'Financial Statements' category
      searchType: 'securitycode',
      searchValue: this._data.nc,
      from: this._getPeriodStart(this._chartPeriod)
    }).then(function (announcements) {
      return {
        type: 'flags',
        name: 'Announcements',
        cursor: 'pointer',
        data: this._getCleanedAnnouncements(announcements),
        onSeries: 'price-series',
        shape: 'squarepin',
        events: {
          click: function (event) {
            window.open((event.point || {}).extraProp, '_blank');
          }
        },
        y: 5,
        zIndex: 100,
        allowOverlapX: (this._chartPeriod === '1y' || this._chartPeriod === '5y')
      };
    }.bind(this));
  }

  /**
   * Get first timestamp given chart period
   *
   * @param {String} chartPeriod specified chart period
   * @return {Timestamp} timestamp
   */
  _getPeriodStart(chartPeriod) {
    switch (chartPeriod) {
      case '1d':
        return DateService().subtract(1, 'days');
      case '5d':
        return DateService().subtract(5, 'days');
      case '1m':
        return DateService().subtract(1, 'months');
      case '1y':
        return DateService().subtract(1, 'years');
      case '5y':
        return DateService().subtract(5, 'years');
      default:
        return DateService().valueOf();
    }
  }


  /**
   * Data pipeline to clean announcements data
   *
   * @param {Object[]} announcements Collection of raw announcements
   * @return {Object[]} Collection of cleaned announcements
   */
  _getCleanedAnnouncements(announcements) {
    const dateFormat = i18n.getTranslation('app.article.list.date-format', {returnObjects: true});

    return announcements.filter(function (item) {
      return item.title.indexOf('::Notification') === -1; // remove announcements that only serve to notify financial statements are coming up
    }).map(function (item) {
      let headline = item.title;
      headline = typeof headline.split('::')[1] !== 'undefined' ? headline.split('::')[1] : headline;

      const broadcastDate = DateService(item.broadcast_date_time);
      const displayDate = broadcastDate.format(dateFormat);

      return {
        x: broadcastDate.add(DateService().utcOffset(), 'm').valueOf(),
        text: `
          <strong>${headline}</strong><br />
          ${displayDate}
        `,
        extraProp: item.url
      };
    }).sort(function (a, b) {
      return a.x - b.x;
    }).map(function (item, index) {
      item.title = index + 1;
      return item;
    });
  }
}

customElements.define('widget-security-details-overview', withInitDOM(TemplateSecurityDetailsOverviewWidget));
