/* Dependencies */
const fetchJSON = require('sgx-base-code').FetchUtils.fetchJSON;
const URLUtils = require('sgx-base-code').URLUtils;
const ConfigService = require('sgx-config-service');
const METADATA_API_URL = ConfigService.endpoints.METADATA_API_URL;
const METADATA_API_GATEWAY_URL = ConfigService.endpoints.METADATA_READ;

let instance = null;
let cache = {};
/**
 * MetaData service retrieves different meta information for particular stock code.
 * @module sgx-metadata-service
 * @type {HTMLElement}
 */
class MetadataService {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Fetches Metadata based on params
   *
   * @param {Object} params an object of params to determine the type of API call to make
   * @returns {Promise<Object>} returns a Metadata object
   */
  getMetaData(params) {
    var url = URLUtils.setQueryParams(METADATA_API_URL, params || {});
    return fetchJSON(url).then(function (response) {
      if (response && response.data) {
        return response.data[0];
      }
      return {};
    });
  }

  /**
  * Fetches Metadata via stock-code
  *
  * @param {String} stockCode stock code to find Metadata for
  * @returns {Promise<Object>} returns a Metadata object
  */
  getMetaDataByStockCode(stockCode) {
    return this._getMetaDataByField('stock-code', stockCode);
  }

  /**
  * Fetches Metadata via ibm-code
  *
  * @param {String} ibmCode ibm code to find Metadata for
  * @returns {Promise<Object>} returns a Metadata object
  */
  getMetaDataByIbmCode(ibmCode) {
    return this._getMetaDataByField('ibm-code', ibmCode);
  }

  /**
  * Fetches Metadata via isin-code
  *
  * @param {String} isinCode isin code to find Metadata for
  * @returns {Promise<Object>} returns a Metadata object
  */
  getMetaDataByIsinCode(isinCode) {
    return this._getMetaDataByField('isin-code', isinCode);
  }

  /**
  * Fetches Metadata via list of ibm-codes
  *
  * @param {Array<String>} codeList list of ibm codes
  * @returns {Promise<Object>} returns a Metadata object
  */
  getMetaDataByIbmCodes(codeList) {
    if (!codeList || !codeList.length) {
      return Promise.resolve([]);
    }

    const body = JSON.stringify({ codeList });
    return fetchJSON(METADATA_API_GATEWAY_URL, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response && response.data || []);
  }

  /**
  * Fetches Metadata via stock-code
  *
  * @param {String} fieldName field name
  * @param {String} fieldValue field value
  * @returns {Promise<Object>} returns a Metadata object
  */
  _getMetaDataByField(fieldName, fieldValue) {
    if (!fieldName || !fieldValue) {
      return Promise.resolve({});
    }
    const callParam = { [fieldName]: fieldValue };
    const url = URLUtils.setQueryParams(METADATA_API_URL, callParam);

    return fetchJSON(url).then(function (response) {
      let result = {};
      if (response && response.data) {
        result = response.data[0];
      }
      return result;
    });
  }

  /**
   * Fetches All Metadata
   *
   * @returns {Promise<Object>} returns a Metadata object
   */
  getAllMetadata() {
    if (this._allMetadata) {
      return this._allMetadata;
    }

    return fetchJSON(METADATA_API_URL).then(function (response) {
      this._allMetadata = response.data || {};
      return this._allMetadata;
    }.bind(this));
  }


  /**
   * Fetches Chinese stock names
   *
   * @returns {Promise<Object>} returns an array of chinese stock names
   */
  getChineseNames() {
    if (this._chineseNames) {
      return this._chineseNames;
    }

    return this.getAllMetadata().then(function (response) {
      var names = [];
      response.filter(function (item) {
        return item.stockCode && item.chineseName;
      }).map(function (item) {
        names[item.stockCode] = item.chineseName;
      });

      this._chineseNames = names;
      return this._chineseNames;
    }.bind(this));
  }
}

/* Export the singleton */
const MetadataServiceInstance = new MetadataService();
export default MetadataServiceInstance;
