import i18n from 'sgx-localisation-service';
import { verticallyAndHorizontallyCenterTitle, CHART_COLORS } from 'utils/chart-util';

const EMPTY_INDICATOR_TITLE = i18n.getTranslation('sgx-table.messages.no-filter-title');
const getChartConfig = (data, title) => {
  return {
    navigator: { enabled: false },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    tooltip: {
      headerFormat: null,
      followPointer: false,
      pointFormat: '{point.name}: <b>{point.y}%</b><br/>',
      valueDecimals: 1
    },
    chart: {
      type: 'pie',
      events: {
        load: verticallyAndHorizontallyCenterTitle,
        redraw: verticallyAndHorizontallyCenterTitle
      },
      animation: false,
      height: 200
    },
    legend: {
      enabled: false
    },
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: title
    },
    plotOptions: {
      pie: {
        colors: CHART_COLORS,
        size: 180,
        innerSize: '65%',
        point: {
          events: {
            legendItemClick: event => event.preventDefault()
          }
        }
      },
      series: {
        animation: false,
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [{
      type: 'pie',
      data
    }]
  };
};

export {
  getChartConfig,
  EMPTY_INDICATOR_TITLE
};
