module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-settings-notifications-actions\">\n  <div class=\"widget-notifications-container\">\n    <div class=\"widget-notifications-row--top\">\n      <button type=\"submit\" class=\"sgx-button--primary widget-notifications-button-submit\" data-i18n=\"app.widget-settings-notifications-actions.buttons.update\" disabled></button>\n    </div>\n    <div class=\"widget-notifications-row--bottom\">\n      <sgx-status-indicator class=\"settings-status-indicator\"></sgx-status-indicator>\n      <div class=\"widget-notifications-status\">\n        <p class=\"widget-notifications-status-text sgx-base-text-body-14\"></p>\n        <span class=\"widget-notifications-status-icon sgx-icon\"></span>\n      </div>\n    </div>\n  </div>\n</template>";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()