import { FetchUtils } from 'sgx-base-code';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';

const store = StoreRegistry.cdpSession;

class BaseService {

  get HEADERS() { // Use lowercase header keys to keep consistent with services
    return {
      'accept': 'application/json',
      'content-type': 'application/json'
    }
  }

  get _configService() {
    return ConfigService;
  }

  get _requestHeaders() {
    return this._configService.request.headers;
  }

  get _endpoints() {
    return this._configService.endpoints;
  }

  get _session() {
    return store.getData();
  }

  _authFetch(url, options) {
    return this._authFetchText(url, options)
      .then(response => response.json());
  }

  _authFetchText(url, options) {
    if (!options) {
      options = {};
    }

    const {userId, token} = this._session;

    // Automatically add the auth token and user id to the header
    options.headers = {
      ...(Object.assign(this.HEADERS, options.headers)),
      [this._requestHeaders.authToken]: token,
      [this._requestHeaders.userId]: userId
    };

    return this._fetchText(url, options)
      .catch(e => {
        if (e.status === 403) {
          return this._handleForbidden(e);
        } else {
          return Promise.reject(e);
        }
      });
  }

  /**
   * If any auth related endpoint returns SESSION_INVALID_OR_EXPIRED,
   * clear the session and, log the user out
   * @param {Object} e Error object containing details of the error message
   * */
  _handleForbidden(e) {
    return e.json()
      .then(response => {
        if (response.errors[0].reason === 'SESSION_INVALID_OR_EXPIRED') {
          StoreRegistry.cdpSession.clear();
          StoreRegistry.permissions.clear();
          sessionStorage.clear();
          StoreRegistry.user.clear();
          window.location = window.location; // reloads the page
        } else {
          return Promise.reject(e);
        }
      });
  }

  _fetch(url, options) {
    return FetchUtils.fetchJSON(url, options);
  }

  _fetchText(url, options) {
    return FetchUtils.fetch(url, options);
  }
}

export default BaseService;
