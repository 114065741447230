module.exports = (function() {
    var templateString = module.exports = "<template id=\"template-about-page\">\n  <div class=\"row\">\n    <div class=\"col-md-6 col-xs-12\">\n      <widget-onboarding-guide></widget-onboarding-guide>\n    </div>\n    <div class=\"col-md-6 col-xs-12\">\n      <widget-join-video-call class=\"sgx-card widget-join-video-call\"></widget-join-video-call>\n    </div>\n    <div class=\"col-md-6 col-xs-12\">\n      <div class=\"template-about-links template-about-links-cdp sgx-card\">\n        <h3 data-i18n=\"app.about.cdp-links-title\"></h3>\n        <div class=\"template-about-related-links-content\">\n          <img class=\"template-about-related-links-content-img\" src=\"" + require("../../assets/images/related-links.svg") + "\"></img>\n          <div class=\"template-about-related-links-content-main\">\n            <div class=\"template-about-related-links-content-cdp\">\n              <span class=\"template-about-related-links-content-column-header\">CDP</span>\n              <div class=\"template-about-related-links-content-cdp-links\">\n                <div class=\"template-about-related-links-content-cdp-1\">\n                  <a ref=\"sgx-v2-contact\"></a>\n                </div>\n              </div>\n            </div>\n            <div class=\"template-about-related-links-content-sgx-group\">\n              <span class=\"template-about-related-links-content-column-header\">SGX Group</span>\n              <div class=\"template-about-related-links-content-sgx-group-links\">\n                <div class=\"template-about-related-links-content-sgx-group-1\">\n                  <a ref=\"sgx-v2-terms-use\"></a>\n                  <a ref=\"sgx-v2-security-advisory\"></a>\n                </div>\n              </div>\n            </div>\n          </div>\n\n        </div>\n      </div>\n    </div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()