import { withInitDOM } from 'sgx-base-code';
import DeviceService from '@sgx/sgx-device-service';
import StoreRegistry from 'stores/store-registry';
import tmpl from './widget-dcs-update-dialog.html';
import userService from '../../services/user-service';
import { isAuthenticated } from 'utils/auth-util';
import { formatAccountId } from 'utils/bank-account-util';
import BankAccountService from 'services/bank-account-service';
import i18n from 'sgx-localisation-service';
import sgxAnalyticsService from '@sgx/sgx-analytics-service';
import { get } from 'lodash';

const {
  ACCOUNT_STATUS,
  ACCOUNT_CATEGORY,
  ACCOUNT_TYPES
} = userService.constants;


/**
 * @class WidgetDcsUpdateDialog
 *
 * This widget is used as a reminder for users with Individual/Joint-ALT account
 * to link their DCS for regular payouts.
 *
 * The Dialog is shown on page load after authentication. Incase of multi-accounts
 * the user will still be prompted if either of their individual or join-alt account
 * doesnot have DCS subscribed.
 *
 * This dialog wont be shown is the user contact reminder is shown first.
 *
 * USAGE:
 * <widget-dcs-update-dialog></widget-dcs-update-dialog>
 */
export default class WidgetDcsUpdateDialog extends HTMLElement {

  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());

    this._dialog = this.querySelector('.widget-dcs-update-dialog');
    this._router = document.getElementById('sgx-app-router');
    this._accountSelectStore = StoreRegistry.accountSelect;
    this._userService = userService;
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  /**
   * @public
   * @method
   *
   * @param {boolean} [show=true]
   *
   * This method is checks if user has any Individual/Join-Alt account without
   * DCS being linked. If the DCS is not active, the account category and type
   * with number is displayed in dialog as a reminder to user to update dcs.
   *
   * This method is called from `widget-user-details-dialog` widget.
   * Reason being we need to show the dcs update dialog only when the
   * update contact reminder is not shown.
   *
   */
  async checkAndShow(show = true) {
    const userDetailsDisplayable = StoreRegistry.user.getData('userDetailsDisplayable');
    let inactiveDCSAccounts = [];
    // contact reminder is available only for authenticated users
    if (!isAuthenticated() || userDetailsDisplayable) {
      return;
    }

    try {
      this._isWebview = StoreRegistry.appContext.getData('webview');
      const accounts = await this._userService.getUserAccounts();
      // only get active/suspended accounts
      const allowedStatus = [ACCOUNT_STATUS.SUSPENDED, ACCOUNT_STATUS.ACTIVE];
      let activeAccounts = (accounts || []).filter(account => !!~allowedStatus.indexOf(account.accountStatus));
      this._accountSelectStore.setData(activeAccounts, 'options');
      activeAccounts = (activeAccounts || []).filter(account => account.accountStatus === ACCOUNT_STATUS.ACTIVE);
      for(const account of activeAccounts) {
        const isEligible = this._isAccountEligibileForUpdate(account);
        if (isEligible) {
          const bankAccounts = await BankAccountService.getBankAccounts(account.accountId);
          const { ACTIVE, PENDING_ACTIVATION } = BankAccountService.constants.DISPLAY_ACCOUNT_STATUS;
          const activeAccount = bankAccounts.some(dcsAccount => ([ACTIVE, PENDING_ACTIVATION].includes(BankAccountService.getDisplayStatus(dcsAccount.status))));
          if (!activeAccount) {
            inactiveDCSAccounts.push({text: this._toDisplayContent(account), account});
          }
        }
      }
      if (inactiveDCSAccounts.length) {
        this._accountSelectStore.setData(inactiveDCSAccounts, 'inactiveDCSAccounts');
        if (show) {
          this._showDialog();
        }
      } else {
        this._accountSelectStore.setData([], 'inactiveDCSAccounts');
      }
    }  catch(_) {
      // Do nothing in case of error as this dialog will only appear if we're able to retrieve user's contact details and accounts
    }
  }

  /**
   * @private
   * @method
   *
   * Only accounts of type Individual and Joint Alternate are eligible for this reminder.
   *
   * @param {String} accountCategory
   * @param {String} typeOfApproval
   * @returns {Boolean}
   */
  _isAccountEligibileForUpdate({accountCategory, typeOfApproval}) {
    const accountType = `${accountCategory}-${typeOfApproval}`;
    return (accountCategory === ACCOUNT_CATEGORY.INDIVIDUAL) ||
      ((accountCategory === ACCOUNT_CATEGORY.JOINT) && (accountType === ACCOUNT_TYPES.JOINT_OR));


  }

  /**
   * @private
   * @method
   * This method is used to form the String with format of `{accountCategory} {type} Account No: {accound id}`,
   * Ex., JOINT-OR ACCOUNT NO: 129432123123112
   * @param {Object} account
   * @return
   */
  _toDisplayContent(account) {
    const path = this._getCategoryTranslationPath(account.accountCategory, account.typeOfApproval);
    return i18n.getTranslation(path, {accountNumber: formatAccountId(account.accountId)});
  }

  /**
   * @private
   * @method
   * This method is used to get the translation key to form the String with format of `{accountCategory} {type} Account No: {accound id}`,
   * Ex., JOINT-OR ACCOUNT NO: 129432123123112
   * @param {String} account
   * @param {String} typeOfApproval
   * @return
   */
  _getCategoryTranslationPath(accountCategory, typeOfApproval) {
    let categoryKey = `${accountCategory}-${typeOfApproval}`;
    if (accountCategory === ACCOUNT_CATEGORY.INDIVIDUAL || accountCategory === ACCOUNT_CATEGORY.CORPORATE) {
      categoryKey = accountCategory;
    }

    return `widget-account-select.category.${categoryKey}`;
  }

  /**
   * @private
   * @method
   * @param {String} account
   * @param {String} typeOfApproval
   *
   * This method shows the dialog once the eligible check is done.
   * the dialog will have two actions dismiss and proceed.
   *
   * Dismiss will close the dialog and proceed will redirect user to `/profile/account` to update
   * DCS.
   *
   * @return
   */
  _showDialog() {
    const dialogConfig = {
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      actions: {
        dismiss: _ => this._onDismiss(),
        proceed: _ => this._navigateToAccount()
      }
    };
    return this._dialog.show(dialogConfig);
  }

  /**
   * @private
   * @method
   *
   * This method is used as action for the dialog `dismiss` button.
   */
  _onDismiss() {
    const title = get(document.querySelector('body'), 'dataset.analyticsCategory');
    sgxAnalyticsService.sendEvent(
      title,
      `DCS Reminder - Dismiss`,
      'click'
    );
    this._dialog.hide();
  }

  /**
   * @private
   * @method
   *
   * This method is used as action for the dialog `proceed` button. Redirects user to `profile/account`
   */
  _navigateToAccount() {
    const title = get(document.querySelector('body'), 'dataset.analyticsCategory');
    sgxAnalyticsService.sendEvent(
      title,
      `DCS Reminder - Proceed`,
      'click'
    );
    this._dialog.hide();
    this._router.navigateToURL('/profile/account');
  }
}

customElements.define('widget-dcs-update-dialog', withInitDOM(WidgetDcsUpdateDialog));
