import SgxTableCellBase from 'sgx-table/src/sub-components/sgx-table-cell/sgx-table-cell-base';
import { withInitDOM } from 'sgx-base-code';
import { Big } from 'utils/big-number-util';

export default class SgxTableCellInputButton extends SgxTableCellBase {
  constructor() {
    super();
    this.defaultConfig = {
      formatThousands: false,
      decimalPlaces: 2,
      defaultValue: '-',
      replaceZero: true
    };
  }

  initDOM() {
    const div = document.createElement('div');
    div.className = 'sgx-input-control';
    this.appendChild(div);
    this._inputButton = this.querySelector('.sgx-input-control');
  }

  connectedCallback() {
    super.connectedCallback();
  }

  setConfig(config, columnConfig, tableConfig) {
    this._config = {...this.defaultConfig, ...config};
  }

  setData(data, action) {
    const { replaceZero, defaultValue } = this._config;
    const decimalPlaces = this._config.decimalPlaces || null;
    let displayText = data;

    if (
      replaceZero && displayText == 0
      || (!data && data !== 0)
      || displayText === '-'
    ) {
      displayText = defaultValue;
    }

    if ((data || data === 0) && this._config.formatThousands) {
      try {
        displayText = Big(data).toFormat(decimalPlaces);
      } catch(e) {
        // not a number, do nothing and use the current string value
      }
    }

    this._inputButton.textContent = displayText;
  }
}

customElements.define('sgx-table-cell-input-button', withInitDOM(SgxTableCellInputButton));
