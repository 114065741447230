/**
 * @module AppRouter Config
 * @desc Application Router Configuration, used by router5
 */

import routes from './routes.json';
import {
  fetchDataMiddleware,
  paramMiddleware,
  dashboardTitleMiddleware,
  helpCenterMiddleware } from './middlewares';

export default {
  routes,
  middleware: [fetchDataMiddleware, paramMiddleware, dashboardTitleMiddleware, helpCenterMiddleware],
  externalNewTab: true,
  routerOptions: {
    defaultRoute: 'home',
    defaultParams: {first: 'home'},
    strictTrailingSlash: false // to cater for /securities, not only investors.sgx.com/securities/
  }
};
