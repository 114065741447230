import ConfigService from 'sgx-config-service';

/**
 * @function handle404Middleware
 * @desc Used to redirect 404 based on mapping in configservice
 * @param  {Object} router Router5 instance of application
 * @return {*} undefined
 */
function handle404Middleware(/* router */) {
  return (newState, previousState, doneFn) => {
    if (newState.name !== '404') {
      doneFn();
      return;
    }

    const redirectURI = ConfigService.redirectionMap[newState.params['404']];

    if (redirectURI) {
      window.location.href = redirectURI;
    }

    doneFn();
  };
}

export default handle404Middleware;
