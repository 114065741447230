import {withInitDOM, URLUtils} from 'sgx-base-code';
import {deleteQueryParams} from 'utils/query-param-util';
import StoreRegistry from 'stores/store-registry';
import tmpl from "./widget-user-particulars-update-sso.html";
import localSecurityAuthUtil from "services/auth-service/src/utils/local-security-auth-util";
import userService from 'services/user-service';
import i18n from 'sgx-localisation-service';
import {Events as RouterEvents} from '@sgx/sgx-app-router';
import {createFormData} from 'utils/profile-particulars';
import ConfigService from "@sgx/sgx-config-service/src/sgx-config-service";

export default class WidgetUserParticularsUpdateSso extends HTMLElement {

  constructor() {
    super();
    this._cdpStore = StoreRegistry.cdpSession;
    this._userStore = StoreRegistry.settingsUser;

    this._stateSuccessCB = function () {
    };

    this._onClickSuccessCB = function () {
    };

    this._onClickErrorCB = function () {
    };

    this._pageTransitionSuccess = this._pageTransitionSuccess.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._txnSigningDialog = this.querySelector('cmp-txn-signing');
    this._myinfoConfirmationDialog = this.querySelector('.cmp-user-profile-myinfo-confirmation');

    // child component dialog of _myinfoConfirmationDialog
    this._userDetailsMyInfoDialog = document.querySelector('widget-user-details-myinfo');
    // child component dialog of _txnSigningDialog
    this._txnSigningSingpassTab = document.querySelector('cmp-txn-signing-singpass');
    // Router Instance
    this._router = document.querySelector('sgx-app-router');
  }

  connectedCallback() {
    this._setEventListeners(true);
  }

  disconnectedCallback() {
    this._setEventListeners(false);
  }

  /**
   * @private
   * @desc handle event listeners on DOM references in the widget
   * */
  _setEventListeners(type) {
    const funcName = type ? 'addEventListener' : 'removeEventListener';
    this._router[funcName](RouterEvents.TRANSITION_SUCCESS, this._pageTransitionSuccess)
  }

  /**
   * @private
   * @desc handle pageTransitionSuccess
   * */
  _pageTransitionSuccess() {
    let {code, state} = this._getCodeAndState();
    this._code = code;
    this._state = state;
    this._setSingPassRedirectState(code, state);
  }

  /**
   * @private
   * @returns the value of code, state after checking for myInfoRedirection
   * */
  _getCodeAndState() {
    let {code, state} = URLUtils.getQueryParams(window.location.search);
    if (this._myInfoUserParticularTransaction()) {
      if (code) {
        this._userDetailsMyInfoDialog.showDialog(code);
      }
      state = null;
      code = null;
      deleteQueryParams(['code', 'state']); // Delete so call is not re-called if user refreshes page
    }
    return {code, state};
  }

  /**
   * @private
   * @returns Boolean to check if MyInfoUserParticulars initiated
   * */
  _myInfoUserParticularTransaction() {
    return StoreRegistry.cdpSession.getData().updateParticularMode === "myinfo";
  }

  /**
   * @public
   * @param {String} type | type = "txn-signing", "my-info"
   * @param {String} fullList | fullList = true
   * @desc: Show dialog box, based on the argument passed
   * */
  showDialog({type, fullList = true}) {
    switch (type) {
      case 'txn-signing':
        this._userStore.setData(this._router.getState().path, 'redirect-to');
        this._handleTxnSigningDialog();
        break;

      case 'my-info':
        this._myinfoConfirmationDialog.showDialog(fullList);
        break;
    }
  }

  /**
   * @private
   * @desc: handleTxnSigning Dialog
   * */
  _handleTxnSigningDialog() {
    const payload = createFormData();
    this._txnSigningDialog.setData({
      service: () => userService.updateUserParticulars({
        payload
      }),
      isSingPassRedirect: this._userStore.getData('singpass-redirect')
    });
    this._txnSigningDialog.showDialog();
  }

  /**
   * @private
   * Makes the second, final service call to update particulars.
   * @param {Object} payload form data to update
   */
  _finishTxnSigning(payload, code, state) {
    this._txnSigningDialog.showIndicator({status: 'loading'});
    userService.updateUserParticulars({payload, token: code, state})
      .then(({status = 200}) => {
        this._finishTxnSigning__successCB(status);
      })
      .catch((error) => {
        const status = error.status;
        error.json()
          .then(response => {
            const reason = response.errors[0].reason;

            this._finishTxnSigning__errorCB(status, reason)
          })
          .catch(_ => this._finishTxnSigning__errorCB(status))

      });
  }

  /**
   * @private
   * @param {Number} status
   * @desc handle the success callback for finishTxnSigning
   * */
  _finishTxnSigning__successCB(status) {
    this._stateSuccessCB();
    this._txnSigningDialog.showIndicator({
      status: 'warning',
      description: i18n.getTranslation(`app.settings-user.success.${status}`),
      button: {
        text: i18n.getTranslation('app.settings-user.buttons.ok'),
        callback: () => {
          this._txnSigningDialog.teardown();
          this._setSingPassRedirectState();
          this._onClickSuccessCB();
        }
      }
    });
  }


  /**
   * @private
   * @param {Number} status
   * @param {String} reason
   * @desc handle the error callback for finishTxnSigning
   * */
  _finishTxnSigning__errorCB(status, reason) {
    let description = i18n.getTranslation(`app.settings-user.error.500`);
    if (reason === 'US_TAX_RESIDENCY_MANDATORY') {
      description = i18n.getTranslation(`app.userDetailsSSO.error.US_TAX_RESIDENCY_MANDATORY_html`, {
        link: ConfigService.links.SGX_V2_UPDATE_PARTICULARS
      });
    }
    this._txnSigningDialog.showIndicator({
      status: 'error',
      description,
      button: {
        text: i18n.getTranslation('app.settings-user.buttons.ok'),
        callback: () => {
          if (status === 401) {
            this._txnSigningSingpassTab.initSingPassQR();
          }
          if (status !== 401) {
            this._txnSigningDialog.teardown();
            this._setSingPassRedirectState();
            this._onClickErrorCB();
          }
          this._txnSigningDialog.hideIndicator();
        }
      }
    });
  }

  /**
   * @public
   *
   * @desc: Store Txn-Signing usage specific callbacks
   * */
  initTxnSigning({
                   stateSuccessCB,
                   onClickSuccessCB,
                   onClickErrorCB
                 }) {
    this._stateSuccessCB = stateSuccessCB;
    this._onClickSuccessCB = onClickSuccessCB;
    this._onClickErrorCB = onClickErrorCB;
  }

  /**
   * @private
   * @desc: handleSingPass Redirect
   * */
  _handleSingPassRedirect(code, state) {
    const payload = createFormData({isMyinfo: false});
    this._handleTxnSigningDialog();
    this._finishTxnSigning(payload, code, state);
  }

  /**
   * @private
   * @desc: handle redirection within IP based on singpassRedirect
   * */
  _redirectToIPOrigin() {
    const navigateTo = this._userStore.getData('redirect-to');
    const currentPath = this._router.getState().path;
    if (navigateTo && navigateTo !== currentPath) {
      setTimeout(() => this._router.navigateToURL(navigateTo), 0);
    }
  }

  /**
   * @private
   * @desc set the value of singPassRedirect in store and instance variable
   * */
  _setSingPassRedirectState(code = false, state = false) {
    const singpassRedirect = !!code && !!state;
    if (!Object.keys(this._userStore.getData()).length) {
      return;
    }

    if (singpassRedirect) {
      this._redirectToIPOrigin();
      this._handleSingPassRedirect(code, state);
    }
    // remove redirect-to and set the updated data in settingsStore
    const userStoreData = this._userStore.getData();
    delete userStoreData['redirect-to'];
    this._userStore.setData({
      ...userStoreData,
      'singpass-redirect': singpassRedirect
    });
  }

}


customElements.define('widget-user-particulars-update-sso', withInitDOM(WidgetUserParticularsUpdateSso));
