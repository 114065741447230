module.exports = (function() {
    var templateString = module.exports = "<template>\n  <div class=\"widget-corporate-actions-list-title-container\">\n    <h3 class=\"widget-corporate-actions-list-title\"></h3>\n    <div class=\"widget-corporate-actions-list-tag\" data-i18n=\"app.widget-corporate-actions-list.tag\"></div>\n  </div>\n  <div class=\"widget-corporate-actions-list-container\">\n    <div class=\"sgx-base-text-body\">\n      <p data-i18n=\"app.widget-corporate-actions-list.description1\"></p>\n      <p class=\"widget-corporate-actions-list-rights-desc\"></p>\n      <sgx-alert-bar class=\"widget-corporate-actions-alert\"></sgx-alert-bar>\n      <p class=\"widget-corporate-actions-list-desc2\" data-i18n=\"app.widget-corporate-actions-list.description2\"></p>\n      <div class=\"widget-corporate-actions-ca-standing-instruction-notes\">\n        <p class=\"widget-corporate-actions-alert2\"></p>\n      </div>\n    </div>\n    <sgx-table class=\"widget-corporate-actions-list-table\"></sgx-table>\n    <sgx-status-indicator></sgx-status-indicator>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()