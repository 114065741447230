import { withInitDOM } from 'sgx-base-code';

import { formatThousands } from 'utils/price-util';
import tmpl from './cmp-portfolio-transfer-qty.html';

class CmpPortfolioTransferQty extends HTMLElement {
  constructor() {
    super();
    this._initialize = false;
    this._onSubmitQuantity = this._onSubmitQuantity.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._quantity = this.querySelector('#quantity');
    this._freeBalance = this.querySelector('#freeBalance');
    this._form = this.querySelector('.cmp-portfolio-transfer-qty-form');

    const config = {
      alignment: 'vertical',
      validate: {
        rules: {
          quantity: {}
        },
        onValidate: (e) => {
          this.dispatchEvent(new CustomEvent('quantity-change', {
            bubbles: true,
            detail: {
              quantity: this._quantity.value,
              valid: e.validated
            }
          }));
        }
      }
    };

    this._form.setConfig(config);
  }

  connectedCallback() {
    // Reference
    this._setListeners(true);
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  show(data) {
    this.update(data);
  }

  update(data) {
    const {freeBalance, quantity, validationMessage} = data;
    const validate = this._form.validator.validate;
    this._quantity.value = quantity;
    this._freeBalance.value = formatThousands(freeBalance, 0, true);

    this._form.validator.updateInputRules('quantity', {
      numericality: {
        lessThanOrEqualTo: +freeBalance,
        greaterThan: 0,
        message: (value, _, validatorOptions) => {
          const numericValue = +value;
          if (isNaN(numericValue)) {
            return validate.format(`^${validationMessage.numeric}`);
          }

          if (!numericValue) {
            return validate.format(`^${validationMessage.presence}`);
          }

          if (numericValue > validatorOptions.lessThanOrEqualTo) {
            return validate.format(`^${validationMessage.lessThanOrEqualTo}`);
          }

          if (numericValue < validatorOptions.greaterThan) {
            return validate.format(`^${validationMessage.greaterThan}`);
          }
        }
      }
    });
    this._form.validate();
    this._quantity.focus();
  }

  getData() {
    return +this._quantity.value;
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this._quantity[fnName]('keypress', this._onSubmitQuantity);
  }

  _onSubmitQuantity(e) {
    if (e.keyCode === 13 && this._form.validate()) {
      this.dispatchEvent(new CustomEvent('quantity-submit', {
        bubbles: true
      }));
    }
  }
}

customElements.define('cmp-portfolio-transfer-qty', withInitDOM(CmpPortfolioTransferQty));
