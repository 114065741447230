import SgxTableDecoratorBase from '@sgx/sgx-table/src/sub-components/sgx-table-decorator/sgx-table-decorator-base';

class SgxTableDecoratorRowNumber extends SgxTableDecoratorBase {
  initDOM() {
    this.style.display = 'none';
  }

  connectedCallback() {}

  decorate(decoratorObject) {
    const config = decoratorObject.decoratorConfig;
    const rowElem = decoratorObject.element;
    this._cellsToDecorate = Array.isArray(config.cellToDecorate) ? config.cellToDecorate : [config.cellToDecorate];

    this._cellsToDecorate.forEach(cell => {
      const elem = rowElem.querySelector(`[data-column-id = ${cell}]`);
      elem.textContent = +rowElem.dataset.rowIndex + 1;
    });
  }
}

customElements.define('sgx-table-decorator-row-number', SgxTableDecoratorRowNumber);
const decorator = document.createElement('sgx-table-decorator-row-number');
document.body.appendChild(decorator);
export default decorator;
