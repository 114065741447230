import { withInitDOM } from 'sgx-base-code';
import StoreRegistry from 'stores/store-registry';
import ConfigService from 'sgx-config-service';
import i18n from 'sgx-localisation-service';
import CircularsService from 'services/sgx-circulars-service';
import tmpl from './widget-security-details-product-information.html';
import MetadataService from "services/sgx-metadata-service";

class WidgetSecurityDetailsProductInformation extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-product-information');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._list = this.querySelector('.w-security-details-product-information-list');
    this._link = this.querySelector('.w-security-details-product-information-link');
    this._linkContainer = this.querySelector('.w-security-details-product-information-list-link-container');
    this._issuerSection = this.querySelector('.w-security-details-product-information-issuer-section');
    this._issuerName = this.querySelector('.w-security-details-product-information-issuer--name');
    this._issuerWebsite = this.querySelector('.w-security-details-product-information-issuer--website');
    this._termsheetTitle = this.querySelector('.w-security-details-product-information-termsheet');
  }

  connectedCallback() {}

  disconnectedCallback() {}

  async setData(data) {
    const lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');
    if (data.type === 'listedcertificates') {
      this._link.href = ConfigService.links.SGX_SUPPLEMENT_DOCUMENT[lang];
    }
    else {
      this._link.href = `${ConfigService.links.SGX_PROSPECTUS[lang]}&value=${data.n}`;
    }
    this._list.setConfig({
      'rowElementName': 'cmp-list-row-security-announcement',
      'rowClickedCallback': rowData => {
        window.open(`${ConfigService.links.PROSPECTUS_CIRCULARS_API_URL}/${rowData.id}`);
      }
    });
    await this._getTermsheet(data);
    this._setIssuer(data.cmsProductDetails);
  }

  async _setIssuer(cmsProductDetails) {
    const { issuer = {}, designatedMarketMaker = {} } = cmsProductDetails || {};
    this._issuerSection.classList.add('sgx-hidden');

    if (!cmsProductDetails || !issuer) {
      return;
    }
    const { title } = designatedMarketMaker.data || {};
    const { website } = issuer.data || {};
    this._issuerName.textContent = title;

    if (!website) {
      this._issuerWebsite.innerHTML = '-';
    } else {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = website;
      a.textContent = website;
      this._issuerWebsite.innerHTML = '';
      this._issuerWebsite.appendChild(a);
    }
    this._issuerSection.classList.remove('sgx-hidden');
  }

  async _getTermsheet(data) {
    let companyname = data.n;
    const stockCode = data.nc;
    this._termsheetTitle.textContent = data.type === 'dlcertificates' ? i18n.getTranslation('widget-security-details-product-information.section.offer-docs') : i18n.getTranslation('widget-security-details-product-information.section.termsheet');
    if (data.type === 'dlcertificates') {
      companyname = (data.cmsProductDetails && data.cmsProductDetails.issuer && data.cmsProductDetails.issuer.data && data.cmsProductDetails.issuer.data.title) || '';
      this._link.classList.add('sgx-hidden');
    } else {
      this._link.classList.remove('sgx-hidden');
    }


    const {isinCode = undefined} = await MetadataService.getMetaDataByStockCode(stockCode);

    if (!companyname) {
      this._list.setData([]);
      return;
    }
    this._indicator.show();

    const options = {
      pagesize: 5,
      pagestart: 0,
      companyname
    };

    if (data.type === 'listedcertificates' && isinCode) {
      options.isincode = isinCode;
      options.prospectusType = 'Supplement Document';
      this._link.href += `&isincode=${isinCode}`;
      delete options.companyname;
    }

    if (!['dlcertificates', 'listedcertificates'].includes(data.type)) {
      options.prospectusType = 'Prospectus';
    }

    try {
      const response = await CircularsService.getCirculars(options);
      const dataLength = response.data.length;

      this._list.setData(this._mapToAnnouncementFormat(response.data));
      this._indicator.hide();

      if (!dataLength) {
        this._indicator.show({
          status: 'neutral',
          title: i18n.getTranslation('app.shared-text.status-indicator.empty.description')
        });
        this._linkContainer.classList.add('sgx-hidden');
      } else {
        this._linkContainer.classList.remove('sgx-hidden');
      }
    } catch(_) {
      this._indicator.show({
        status: 'error',
        title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
        description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
      });
    }
  }

  _mapToAnnouncementFormat(arr) {
    // since we're reusing cmp-list-row-security-announcement, we need to map the data to a format accepted by the component
    return (arr || []).map(data => {
      const { prospectusType, subject, documentDate } = data;
      return {
        ...data,
        title: subject,
        category_name: prospectusType,
        broadcast_date_time: documentDate
      };
    });
  }
}

customElements.define('widget-security-details-product-information', withInitDOM(WidgetSecurityDetailsProductInformation));
