import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-security-password-dialog.html';
import i18n from '@sgx/sgx-localisation-service';
import { fromEvent } from 'rxjs';
import { isComplete } from 'utils/form-util';
import StoreRegistry from 'stores/store-registry';
import AuthService from 'services/auth-service';
import { passwordValidators } from 'utils/password-util';

class UpdatePasswordDialog extends HTMLElement {
  constructor() {
    super();

    // Init Instance variables
    this._subscriptions = [];

    const {userId} = StoreRegistry.cdpSession.getData();
    this.userId = userId;

    // Bind the references
    this._setListeners = this._setListeners.bind(this);
    this._setValidation = this._setValidation.bind(this);
    this.resetInputFields = this.resetInputFields.bind(this);
    this._invalidateOldPassword = this._invalidateOldPassword.bind(this);
    this._invalidateNewPassword = this._invalidateNewPassword.bind(this);
    this._createOtpErrorEvent = this._createOtpErrorEvent.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-security-password-dialog');

    // References
    this._dialog = this.querySelector('.widget-password-dialog');
    this._title = this.querySelector('.widget-password-dialog-title');
    this._form = this.querySelector('.widget-password-dialog-form');
    this._oldPasswordInput = this.querySelector('.widget-password-dialog-old-password');
    this._newPasswordInput = this.querySelector('.widget-password-dialog-new-password');
    this._confirmPasswordInput = this.querySelector('.widget-password-dialog-confirm-password');
    this._dismissButton = this.querySelector('.widget-password-dialog-button-dismiss');
    this._confirmButton = this.querySelector('.widget-password-dialog-button-confirm');
    this._otpPrompt = this.querySelector('cmp-otp-prompt');

    // this._form.hide = this.resetInputFields;
  }

  connectedCallback() {
    // Set up store
    this._store = StoreRegistry.settingsPasswordDialog;

    // Delay required to ensure form listeners are applied after dialog setup
    setTimeout(() => this._setListeners(), 0);
    this._setEventListeners(true);
  }

  _setEventListeners(enable) {
    const funcName = enable ? 'addEventListener' : 'removeEventListener';

    this._otpPrompt[funcName]('reset-change-password-state', this.resetInputFields);
    this._otpPrompt[funcName]('cancel-otp-prompt', _ => {
      this._dialog.show();
    });
    this._otpPrompt[funcName]('handle-password-error', this._invalidateNewPassword);
  }

  disconnectedCallback() {
    this._setEventListeners(false);
    (this._subscriptions || []).forEach(sub => sub.unsubscribe());
  }

  _setValidation() {
    // Add custom password validators
    const _this = this;
    const validateInstance = this._form.validator.validate;
    validateInstance.validators = {
      ...validateInstance.validators,
      ...passwordValidators,
      wrongPassword(value, options) {
        if (_this.oldPasswordMatched === false) {
          (value !== _this.oldPasswordValue) && (_this.oldPasswordMatched = true); // reset the test
          return options.message;
        }
      },
      validateNewPassword(value, options) {
        if (_this.invalidNewPassword === true) {
          (value !== _this.newPasswordValue) && (_this.invalidNewPassword = false); // reset the test
          return options.message;
        }
      },
      validateNewPasswordWithUserId(value, options) {
        if (_this.userId && _this.userId.toLowerCase() === value.toLowerCase()) {
          return options.message;
        }
      },
      validateWithConfirm(value, options) {
        const confirmPasswordElem = _this._form.querySelector(`#${options.attribute}`);
        const confirmPasswordValue = confirmPasswordElem.getValue();
        if (!!confirmPasswordValue) {
          if (confirmPasswordValue !== value) {
            return options.message;
          } else if (confirmPasswordElem.getMessage()) {
            setTimeout(_ => {
              _this._form.validate();
            })
          }
        }
      },
      validateWithNew(value, options) {
        return passwordValidators.validateSamePassword(value, options, _this._form);
      },
      validateWithOld(value, options) {
        return passwordValidators.validateSamePassword(value, options, _this._form);
      }
    };

    const config = {
      alignment: 'vertical',
      validate: {
        rules: {
          'oldPassword': {
            presence: {
              message: i18n.getTranslation('app.validation.presence')
            },
            wrongPassword: {
              message: i18n.getTranslation('app.validation.wrongPassword')
            },
            equality: {
              attribute: 'newPassword',
              message: i18n.getTranslation('app.validation.error-new-same'),
              comparator: function (v1, v2) {
                return v1 !== v2;
              }
            },
            validateWithNew: {
              attribute: 'newPassword',
              message: i18n.getTranslation('app.validation.error-new-same')
            }
          },
          'newPassword': {
            presence: {
              message: i18n.getTranslation('app.validation.presence')
            },
            length: {
              minimum: 8,
              maximum: 20,
              message: i18n.getTranslation('app.validation.length', { minimum: 8, maximum: 20 })
            },
            equality: {
              attribute: 'oldPassword',
              message: i18n.getTranslation('app.validation.error-old-same'),
              comparator: function (v1, v2) {
                return v1 !== v2;
              }
            },
            validateNewPasswordWithUserId: {
              message: i18n.getTranslation('app.validation.newpassword-userid-same')
            },
            validateNewPassword: {
              message: i18n.getTranslation('app.validation.newpassword-invalid')
            },
            validateWithConfirm: {
              attribute: 'confirmPassword',
              message: i18n.getTranslation('app.validation.newPasswordEquality')
            },
            validateWithOld: {
              attribute: 'oldPassword',
              message: i18n.getTranslation('app.validation.error-old-same')
            },
            twoCharacterTypes: {
              message: i18n.getTranslation('app.validation.twoCharacterTypes')
            }
          },
          'confirmPassword': {
            presence: {
              message: i18n.getTranslation('app.validation.presence')
            },
            equality: {
              attribute: 'newPassword', // References the 'id' attribute, not 'name'
              message: i18n.getTranslation('app.validation.passwordEquality'),
              comparator: (v1, v2) => {
                if (v1 === v2
                  && this._form.querySelector('#newPassword').getMessage()) {
                  setTimeout(_ => {
                    this._form.validate();
                  });
                }
                return v1 === v2;
              }
            }
          }
        },
        onValidate: (e) => {
          if (e.validated) {
            return this._confirmButton.removeAttribute('disabled');
          }
          return this._confirmButton.setAttribute('disabled', true);
        }
      }
    };

    // Only set config on a form once
    if (!this._form._isConfigured) {
      this._form.setConfig(config);
      this._form._isConfigured = true;
    }
  }


  _setListeners() {

    this._setValidation();

    // Toggle dialog
    this._subscriptions.push(fromEvent(this._dismissButton, 'click')
      .subscribe(() => {
        this.resetInputFields();
        this._dialog.hide();
      })
    );

    // Construct rule for presence
    const presenceRule = {
      presence: {
        message: i18n.getTranslation('app.shared-text.form-validation.is-required')
      }
    };

    // Listen for submit on child form, timeout to allow sgx-form to finish creating child form
    setTimeout(() => {
      this._childForm = this._form.firstElementChild;
      this._subscriptions.push(fromEvent(this._childForm, 'submit')
        .subscribe(e => {
          // Prevent defaults
          e.preventDefault();
          e.stopImmediatePropagation();
          this._confirmButton.setAttribute('disabled', true);

          AuthService.verifyPassword(this._oldPasswordInput.getValue())
            .then(res => {
              this._oldPasswordInput.setMessage('');
              this.oldPasswordMatched = true;
              this._form.validate();
              AuthService.updatePassword(
                this._newPasswordInput.getValue(),
                this._oldPasswordInput.getValue()
              );
              AuthService.requestToken()
                .then(({maskedMobileNumber}) => {
                  // this.resetInputFields();
                  this._otpPrompt.setData({maskedMobileNumber});
                  this._otpPrompt.show('sms');
                })
                .catch((error) => {
                  if(this._otpPrompt && error) {
                    this._otpPrompt.show('');
                    this._otpPrompt.showStatusIndicator('', error);
                  }
                  console.error(error);
                });
            })
            .catch(err => {
              err.json().then(res => {
                const reason = res.errors[0].reason;
                switch (reason) {
                  case 'VERIFY_ONE_FA_LOGIN_FAILED':
                    this._invalidateOldPassword();
                    break;
                  case 'USER_SUSPENDED':
                    this._dialog.hide();
                    this._createOtpErrorEvent();
                    break;
                  default:
                    this._invalidateOldPassword();
                }
              });
            })


        })
      );
    }, 0);
  }

  _createOtpErrorEvent(){
    this._otpPrompt.dispatchEvent(new CustomEvent('handle-otp-error', {
      bubbles: false
    }))
  }

  _invalidateNewPassword() {
    this.invalidNewPassword = true;
    this.newPasswordValue = this._newPasswordInput.getValue();
    this._form.validate();
  }

  _invalidateOldPassword() {
    this.oldPasswordMatched = false;
    this.oldPasswordValue = this._oldPasswordInput.getValue();
    this._form.validate();
  }

  resetInputFields() {
    this._newPasswordInput.setValue('');
    this._newPasswordInput.setMessage('');
    this._oldPasswordInput.setValue('');
    this._oldPasswordInput.setMessage('');
    this._confirmPasswordInput.setValue('');
    this._confirmPasswordInput.setMessage('');
    this._confirmButton.setAttribute('disabled', true);
  }
}

customElements.define('widget-settings-security-password-dialog', withInitDOM(UpdatePasswordDialog));
