import SgxTableCellBase from 'sgx-table/src/sub-components/sgx-table-cell/sgx-table-cell-base';
import { withInitDOM } from 'sgx-base-code';

/**
 * A custom HTML element used to render cells for securities prices widget
 * @module sgx-table-cell-securities
 * @type {HTMLElement}
 */
class SgxTableCellSecurities extends SgxTableCellBase {
  constructor() {
    super();
  }

  initDOM() {
    this.innerHTML = `
        <a class="sgx-table-cell-security-name website-link" rel="external" href="#" target="_blank"></a>
        <span class="sgx-table-cell-security-icons">
          <sgx-tooltip class="sgx-table-cell-security-info" data-sgx-tooltip-hover data-sgx-tooltip-pos="right">
            <div data-sgx-tooltip-content></div>
          </sgx-tooltip>

          <sgx-tooltip class="sgx-table-cell-security-rmk" data-sgx-tooltip-hover data-sgx-tooltip-pos="right">
            <div data-sgx-tooltip-content></div>
          </sgx-tooltip>

          <sgx-tooltip class="sgx-table-cell-security-swl" data-sgx-tooltip-hover data-sgx-tooltip-pos="right">
            <div data-sgx-tooltip-content></div>
          </sgx-tooltip>

          <sgx-tooltip class="sgx-table-cell-security-spac" data-sgx-tooltip-hover data-sgx-tooltip-pos="right">
            <div data-sgx-tooltip-content></div>
          </sgx-tooltip>

          <sgx-tooltip class="sgx-table-cell-security-tt" data-sgx-tooltip-hover>
            <div data-sgx-tooltip-content></div>
          </sgx-tooltip>
        </span>
      `;
    this.securityName = this.children[0];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  setConfig(config, columnConfig, tableConfig) {
  }

  setData(data) {
    this.securityName.title = data;
    this.securityName.textContent = data;
    this.securityName.dataset.analyticsLabel = data;
  }
}

customElements.define('sgx-table-cell-securities', withInitDOM(SgxTableCellSecurities));
