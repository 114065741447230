import ConfigService from 'sgx-config-service';
import BaseService from 'services/base-service';

let instance = null;

/**
 * Service responsible for managing the audit log.
 * @module audit-log-service
 */
class AuditLogService extends BaseService {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Get audit logs.
   * @return {Promise<Object>} Returns an object containing audit-log.
   */
  getAuditLogs() {
    return this._authFetch(ConfigService.endpoints.AUDIT_TRANSACTION_LOGGER_READ, {
      method: 'GET',
      headers: this.HEADERS
    });
  }

}

export default new AuditLogService();
