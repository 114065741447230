import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-portfolio-account-details.html';
import tabConfig from './widget-portfolio-account-details-config';
import StoreRegistry from 'stores/store-registry';
import { ACCOUNT_STATES } from 'services/user-service/src/user-service-constants';

const REGISTERED_ACCOUNTS_WIDGET = 'WIDGET-PORTFOLIO-REGISTERED-ACCOUNTS';

class WidgetPortfolioAccountDetails extends HTMLElement {
  constructor() {
    super();
    this._onTabChange = this._onTabChange.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._tabs = this.querySelector('.portfolio-view-details-tabs');
  }

  connectedCallback() {
    this._tabs.addEventListener('stateChange', this._onTabChange);
    this._tabs.setConfig(tabConfig.config);
    this._tabs.setState(this.getUpdatedTabState());
  }

  setData({ accountId, accountDesc }) {
    if (this._accountId !== accountId) {
      this.querySelector('#account-detail').innerHTML = accountDesc;
      this._accountId = accountId;

      this._setRegisteredAccountsWidget(this._currentTab);
    }
  }

  getUpdatedTabState() {
    let { accountType } = StoreRegistry.cdpSession.getData();
    let tabStates = [...tabConfig.state.states];

    StoreRegistry.cdpSession.subscribe(acc => {
      if (accountType !== acc.accountType && acc.accountType) {
        accountType = acc.accountType;
      }
    });

    if (accountType === ACCOUNT_STATES.JOINT_AND) {
      tabStates = tabStates.filter(state => state.data !== 'widget-portfolio-notification-settings');
    }

    return { ...tabConfig, states: tabStates };
  }

  disconnectedCallback() {
    if (this._tabs) {
      this._tabs.removeEventListener('stateChange', this._onTabChange);
    }
  }

  get _currentTab() {
    return this.querySelector('.portfolio-view-details-tabs-item:not(.sgx-hidden)');
  }

  _onTabChange(e) {
    const oldTab = this._currentTab;
    const activeTab = this.querySelector(`${e.detail}`);
    if (oldTab) {
      oldTab.classList.add('sgx-hidden');
    }
    activeTab.classList.remove('sgx-hidden');
    this._setRegisteredAccountsWidget(activeTab);
  }

  _setRegisteredAccountsWidget(tab) {
    if (tab.tagName === REGISTERED_ACCOUNTS_WIDGET) {
      tab.setData({ accountId: this._accountId });
    }
  }
}

customElements.define('widget-portfolio-account-details', withInitDOM(WidgetPortfolioAccountDetails));
