import { Utils, withInitDOM } from 'sgx-base-code';
import cloneDeep from 'lodash/cloneDeep';
import tmpl from './widget-security-details.html';
import tabConfig from './conf/widget-security-details-tab-config';
import stockfactsTabConfig from './conf/widget-security-details-stockfacts-tab-config';
import StockfactsAggregator from 'aggregators/stockfacts-aggregator';
import i18n from 'sgx-localisation-service';
import SgxComCmsService from 'services/sgx-com-cms-service';
import { formatApiDate } from 'utils/date-util';
import SecuritiesService from 'sgx-securities-service';
import ConfigService from '@sgx/sgx-config-service';
import { get } from 'lodash';

const OVERVIEW_WIDGET_TAG = 'WIDGET-SECURITY-DETAILS-OVERVIEW';

class SecurityDetailsWidget extends HTMLElement {
  constructor() {
    super();
    this._ready = Utils.deferred();
    this._renderedTabs = []; // holds the list of tabs that has been rendered to prevent api request every time we trigger setData of the tab item/widget

    // Bound methods
    this._onTabChange = this._onTabChange.bind(this);
    this._setDataOnTab = this._setDataOnTab.bind(this);
    this._iconClick = this._iconClick.bind(this);
    this._addCorporateActionsTab = this._addCorporateActionsTab.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details');

    this._router = document.getElementById('sgx-app-router');
    this._icon = this.querySelector('cmp-star-icon');
    this._nameElem = this.querySelector('.widget-security-details-name');
    this._tabsHeader = this.querySelector('sgx-tabs');
    this._tabsContainer = this.querySelector('.widget-security-details-tabs');
    this._priceInfo = this.querySelector('.widget-security-details-price-info');
    this._priceChanges = this.querySelector('.widget-security-details-price-changes');
    this._priceValue = this.querySelector('.widget-security-details-price-last-value');
    this._generalInfo = this.querySelector('.widget-security-details-general-info');
    this._industry = this.querySelector('.widget-security-details-general-industry');
    this._currency = this.querySelector('.widget-security-details-general-currency');
    // this._timestamp = this.querySelector('.widget-security-details-general-timestamp');
    this._printButton = this.querySelector('.widget-security-details-print');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._remarksWidget = this.querySelector('widget-security-details-remarks');

    // TODO - If tabs require a preference then dynamically create the tab container and append --active suffix on tab to show
    this._overviewTab = this.querySelector('.widget-security-details-overview');
    this._informationTab = this.querySelector('.widget-security-details-information');
    this._financialTab = this.querySelector('.widget-security-details-financial');
    this._announcementsTab = this.querySelector('.widget-security-details-announcements');
    this._marketTab = this.querySelector('.widget-security-details-market');
    this._gtiTab = this.querySelector('.widget-security-details-gti');
    this._warrantTab = this.querySelector('.widget-security-details-warrant-info');
    this._corporateActionsTab = this.querySelector('.widget-security-details-corporate-actions');

    const head = document.querySelector('head');
    this._metaDescription = head.querySelector('meta[name="description"]');
    this._metaOGTitle = head.querySelector('meta[property="og:title"]');
    this._metaOGImage = head.querySelector('meta[property="og:image"]');
    this._metaOGDescription = head.querySelector('meta[property="og:description"]');
    this._metaOGUrl = head.querySelector('meta[property="og:url"]');
    this._metaTwitterTitle = head.querySelector('meta[name="twitter:title"]');
    this._metaTwitterImage = head.querySelector('meta[name="twitter:image"]');
    this._metaTwitterDescription = head.querySelector('meta[name="twitter:description"]');
  }

  connectedCallback() {
    this._setListeners(true);
    this._ready.resolve();
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  async setData(data) {
    // Remove overview tag from _renderedTabs to allow it to be updated every 1 minute
    this._renderedTabs = this._renderedTabs.filter(val => val !== OVERVIEW_WIDGET_TAG);
    this._setSEOFields({code: data.nc, name: data.n});
    try {
      const [cmsProductDetails] = await Promise.all([
        SgxComCmsService.getProductDetails(data && data.nc),
        this._whenReady()
      ]);
      if (cmsProductDetails) {
        data.cmsProductDetails = cmsProductDetails;
      }
    } catch(e) {
      console.error(e)
    }
    this._render(data);
  }

  _setSEOFields({code, name}) {
    const title = i18n.getTranslation('app.security-details.meta.title', {
      counter_name: name
    });
    const description = i18n.getTranslation('app.security-details.meta.description', {
      counter_name: name
    });
    const lang = i18n.getLanguage().replace(/-/g, "_");
    const url = `${ConfigService.links.SGX_EQUITIES[lang]}${code}`
    document.title = title;
    this._metaDescription.setAttribute('content', description);
    this._metaOGTitle.setAttribute('content', title);
    this._metaOGDescription.setAttribute('content', description);
    this._metaTwitterTitle.setAttribute('content', title);
    this._metaTwitterDescription.setAttribute('content', description);
    this._metaOGUrl.setAttribute('content', url);
  }

  // Triggered by dialog when widget-security-details is use as dialog-body
  show(data) {
    this.setData(data);
  }

  _whenReady() {
    return this._ready.promise;
  }

  _render(data) {
    if (!data) {
      this._indicator.show({
        status: 'neutral',
        description: i18n.getTranslation('app.shared-text.status-indicator.empty.description')
      });
      this._tabsContainer.classList.add('sgx-hidden');
      return;
    }

    this._tabsContainer.classList.remove('sgx-hidden');
    this._indicator.hide();
    let ric = undefined;
    data.isStockfacts = data.type === 'stocks' || data.type === 'reits' || data.type === 'businesstrusts';
    if (data.isFavorite === null || data.isFavorite === undefined) {
      this._icon.classList.add('sgx-hidden');
    } else {
      this._icon.classList.remove('sgx-hidden');
      this._icon.selected = data.isFavorite;
    }

    if (!data.nc) { // TODO - potentially display an error here
      return;
    }

    this._remarksWidget.setData(data);

    if (data.isStockfacts) {
      if (this._securityCode !== data.nc) {
        Promise.all([
          SecuritiesService.getPrices({
          category: data.type,
          code: data.nc
        }),
          StockfactsAggregator.getProductData(data.nc),
          StockfactsAggregator.getScreenerData()
        ]).then(([pricesData, productData, allData]) => {
          let {sector, industry, reportingCurrency} = (((productData || {}).stockData || {}).snapshotReport || {});
          this._generalInfo.classList.remove('sgx-hidden');
          this._printButton.classList.remove('sgx-hidden');
          this.dataset.stockfacts = true; // use in print css to identify which title to show
          sector = `${sector ? sector : ''}`;
          industry = `${industry ? industry : ''}`;
          const comma = `${sector ? ', ' : ''}`;
          const industryText = (!sector && !industry) ? '-' : `${sector}${comma}${industry}`;
          this._industry.textContent = `${i18n.getTranslation('app.security-details.header.industry')}: ${industryText}`;
          this._currency.textContent = `${i18n.getTranslation('app.security-details.header.currency')}: ${reportingCurrency ? reportingCurrency : '-'}`;
          if (productData.stockData) {
            productData.stockData.snapshotReport.roe = allData[data.nc]['roe-perc'];
          }
          ric = allData[data.nc]['ric'];
          if (ric) {
            this._nameElem.textContent = `${data.n} (${data.nc}/${ric})`;
          }
          this._data = {...data, ...productData, pe: allData[data.nc]['pe'], yield: allData[data.nc]['yield']};
          this._configureTabs(stockfactsTabConfig);
          this._priceInfo.setData({...data, reportingCurrency, curr: pricesData[data.nc].cur})
        })
          .then(_ => {
            // trigger setData of all tabs needed for printing
            this._printableTabs.forEach(tab => this._setDataOnTab(tab));
          });

      }
    } else {
      this.dataset.stockfacts = false;  // use in print css to identify which title to show
      this._data = data;
      const config = cloneDeep(tabConfig);
      const LISTED_CERTS_TYPE = 'listedcertificates';

      // include corporate actions tab for ETFs and ADRs
      if (['etfs', 'adrs'].includes(data.type)) {
        config.state.states.push({
          label: i18n.getTranslation('app.security-details.tabs.corporate-actions'),
          data: 'widget-security-details-corporate-actions'
        });
      }

      // use widget-security-details-product-information tab instead of widget-security-details-information
      // for Structured Warrants and Listed Certificates
      // TODO: include listed certificates once we have the product code
      if (['structuredwarrants', 'companywarrants'].includes(data.type)) {
        config.state.states[1].data = 'widget-security-details-product-information';
      }

      // use widget-security-details-product-information tab instead of widget-security-details-information
      // for Daily Leverage Certificates ONLY if cmsProductDetails exist
      if (data.type === 'dlcertificates') {
        if (data.cmsProductDetails) {
          config.state.states[1].data = 'widget-security-details-product-information';
        } else {
          config.state.states.splice(1, 1);
        }
      }

      if (data.type === LISTED_CERTS_TYPE) {
        get(config, 'state.states').splice(1, 1, {
          label: i18n.getTranslation('app.security-details.tabs.product-information'),
          data: 'widget-security-details-product-information'
        });
      }

      if (['etfs', 'adrs', 'structuredwarrants', 'dlcertificates', 'listedcertificates'].includes(data.type)) {
        config.state.states.forEach(state => {
          if(state.data === 'widget-security-details-announcements'){
            state.label = i18n.getTranslation('app.security-details.tabs.issuer-announcements')
          }
        })
      }

      if (['structuredwarrants', 'dlcertificates', 'companywarrants', 'adrs', 'listedcertificates'].includes(data.type)) {
        let indexToDelete = -1;
        config.state.states.forEach(({data}, index) => {
          if (data === 'widget-security-details-market') indexToDelete = index;
        });

        config.state.states.splice(indexToDelete, 1);
      }

      if (['companywarrants', 'structuredwarrants'].includes(data.type)) {
        let indexToDelete = -1;
        config.state.states.forEach(({data}, index) => {
          if (data === 'widget-security-details-product-information') {
            indexToDelete = index;
          }
        });

        config.state.states.splice(indexToDelete, 1, {
          label: i18n.getTranslation('app.security-details.tabs.warrant-info'),
          data: 'widget-security-details-warrant-info'
        });

        this._warrantTab.setData(data);
      }

      if (['liprod'].includes(data.type)) {
        config.state.states.forEach(state => {
          if(state.data === 'widget-security-details-announcements'){
            state.label = i18n.getTranslation('app.security-details.tabs.company-announcements')
          }
        });

        this._corporateActionsTab.setData(data);
      }

      if (['companywarrants'].includes(data.type)) {
        config.state.states.forEach(state => {
          if(state.data === 'widget-security-details-announcements'){
            state.label = i18n.getTranslation('app.security-details.tabs.issuer-announcements')
          }
        });
      }


      this._configureTabs(config);
      // trigger setData of all tabs needed for printing
      this._printableTabs.forEach(tab => this._setDataOnTab(tab));
    }

    // Calculate price info
    this._priceInfo.setData(data);
    let name = `${data.n} (${data.nc})`;
    ric = get(this, '_data.ric');
    if (ric) {
      name = `${data.n} (${data.nc}/${ric})`;
    }
    this._nameElem.textContent = name;
    // this._timestamp.textContent = formatApiDate(data.trading_time);
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';

    this._tabsHeader[fnName]('stateChange', this._onTabChange);
    this._icon[fnName]('cmp-star-icon-click', this._iconClick);
    this._printButton[fnName]('click', this._onPrintClick);
    this._corporateActionsTab[fnName]('widget-security-details-corporate-actions', this._addCorporateActionsTab)
  }

  _addCorporateActionsTab(){
    const states = this._tabsHeader && this._tabsHeader.getState().states;
    if (states[states.length - 1].data !== 'widget-security-details-corporate-actions') {
      this._tabsHeader.addTab(
        i18n.getTranslation('app.security-details.tabs.corporate-actions'),
        'widget-security-details-corporate-actions'
      );

      this._tabsHeader.activateTab(0);
    }
  }

  _configureTabs(config) {
    if (!this._tabsHeader.getConfig()) {
      this._tabsHeader.setConfig(config.config);
      this._tabsHeader.setState(config.state);
      config.state.states.forEach(({ data }) => {
        this.querySelector(`.${data}`).classList.add('widget-security-details-tabs-item--print')
      });
    } else {
      this._setDataOnTab(this._currentTab);
    }
  }

  _setDataOnTab(activeTab) {
    const data = this._data;
    if (this._securityCode !== data.nc) {
      this._securityCode = data.nc;
    }

    const currentTabTagName = activeTab.tagName;
    const hasBeenRendered = !!~this._renderedTabs.indexOf(currentTabTagName);
    if (!hasBeenRendered) {
      this._renderedTabs.push(currentTabTagName);
      activeTab.setData(data);
      return;
    }

    if (typeof activeTab.recalculateSize === 'function') {
      activeTab.recalculateSize();
    }
  }

  get _currentTab() {
    return this.querySelector('.widget-security-details-tabs-item--active');
  }

  get _printableTabs() {
    return [...this.querySelectorAll('.widget-security-details-tabs-item--print')];
  }

  _onTabChange(e) {
    const oldTab = this._currentTab;
    const activeTab = this.querySelector(`.${e.detail}`);
    oldTab.classList.remove('widget-security-details-tabs-item--active');
    activeTab.classList.add('widget-security-details-tabs-item--active');
    this._setDataOnTab(activeTab);
  }

  _iconClick() {
    const event = new CustomEvent('security-favorite', {
      bubbles: true,
      detail: {
        securityCode: this._securityCode,
        selected: this._icon.selected
      }
    });
    this.dispatchEvent(event);
  }

  _onPrintClick() {
    // TODO - Adjust print once Stockfacts tabs are implemented
    window.print();
  }
}

customElements.define('widget-security-details', withInitDOM(SecurityDetailsWidget));
