const TRADE_SIDE = {
  B: 'buy',
  S: 'sell'
};

const TRADE_ALLOCATION_STATUS = {
  N: 'new',
  C: 'cancelled'
};

// TODO - remove the underscore once API remove it from the response
const BALANCE_TYPE = {
  ASSIGNMENT_PLEDGED: '22',
  ASSIGNMENT_RECEIVED: '11',
  BLOCKED: '29',
  BORROWED: '18',
  CHARGE: '7',
  EARMARKED: '26',
  FREE: '0',
  FROZEN: '1',
  LIEN_HELD: '20',
  ON_LOAN: '25',
  PENDING_ASSIGNMENT: '9',
  PENDING_ASSIGNMENT_RECEIPT: '10',
  PENDING_ASSIGNMENT_REVERSAL: '12',
  PENDING_BORROW: '17',
  PENDING_BUY: '27',
  PENDING_CREDIT: '32',
  PENDING_DEBIT: '33',
  PENDING_DEPOSIT: '3',
  PENDING_ENTITLEMENT: '30',
  PENDING_FREEZE: '2',
  PENDING_HYPOTHECATION: '6',
  PENDING_HYPOTHECATION_REVERSAL: '8',
  PENDING_LOAN: '16',
  PENDING_RELEASE: '5',
  PENDING_RETURN: '19',
  PENDING_REVERSIBLE_TRANSFER: '13',
  PENDING_REVERSIBLE_TRANSFER_RECEIPT: '14',
  PENDING_REVERSIBLE_TRANSFER_REVERSAL: '15',
  PENDING_SELL: '28',
  PENDING_WITHDRAWAL: '4',
  REVERSIBLE_TRANSFER: '23',
  REVERSIBLE_TRANSFER_RECEIVED: '24',
  SUB_CHARGE: '21',
  SUB_LIEN_HELD: '31'
};

// TODO - remove the underscore once API remove it from the response
const BALANCE_SUB_TYPE = {
  BROKER_WITHHOLD: '8',
  CORPORATE_ACTIONS: '4',
  FORECAST: '6',
  HELD: '0',
  MORATORIUM: '7',
  REF_FIELD: '0',
  SBL: '5',
  SETTLEMENT_BATCH: '1',
  SETTLEMENT_INSTRUCT: '2',
  SETTLEMENT_RTGS: '3'
};

export {
  BALANCE_TYPE,
  BALANCE_SUB_TYPE,
  TRADE_SIDE,
  TRADE_ALLOCATION_STATUS
};
