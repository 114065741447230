import i18n from 'sgx-localisation-service';

/**
 * Helper method that produces an error message for a given
 * error status code.
 * @param {Object} object The object passed as an argument
 * @param {string} object.method The method name
 * @param {string} object.status The error status code
 * @param {string} object.reason The error reason if one is passed
 * @param {string} Object.accountType Account type (individual or corporate).
 * @param {string} object.params i18n params for interpolation
 */
export function errorMessage({ method = '', status = '', reason = '', params = {}, accountType = '' }) {
  let specificError = '';

  if (accountType) {
    specificError = reason
      ? `sgx-login.error.${method}.reason.${reason.toLowerCase().replace(/_/g, '-')}`
      : `sgx-login.error.${method}.${accountType}.${status}`;
  } else {
    specificError = reason
      ? `sgx-login.error.${method}.reason.${reason.toLowerCase().replace(/_/g, '-')}`
      : `sgx-login.error.${method}.${status}`;
  }
  const defaultError = `sgx-login.error.default`;

  return i18n.i18next.exists(specificError)
    ? i18n.getTranslation(specificError, params)
    : i18n.getTranslation(defaultError);
}
