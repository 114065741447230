/**
 * PTS System Status for Account Statement/Confirmation Note :: Investors Portal Notification Status
 * - Pending Verification Hard Copy (0) :: CHECKED_PV_HC
 * - Verified Hard Copy (1) :: CHECKED_V_HC
 * - Subscribed Hard Copy (2) :: UNCHECKED_SUB_HC
 * - Terminated Hard Copy (3) :: CHECKED_TER_HC
 * - Suspended Hard Copy (4) :: CHECKED_SUS_HC
 */
const PTS_HARD_COPY_STATUS = {
  CHECKED_PV_HC: '0',
  CHECKED_V_HC: '1',
  UNCHECKED_SUB_HC: '2',
  CHECKED_TER_HC: '3',
  CHECKED_SUS_HC: '4'
};

const PTS_CDP_HARD_COPY_STATUS = {
  CHECKED_PV_HC: '0',
  CHECKED_V_HC: '1',
  UNCHECKED_SUB_HC: '2',
  CHECKED_TER_HC: '3',
  CHECKED_SUS_HC: '4'
};

/** PSN Service Status
 * - Pending Verification Soft Copy (0) :: UNCHECKED_PV_SC
 * - Verified Soft Copy (1) :: UNCHECKED_V_SC
 * - Subscribed Soft Copy (2) :: CHECKED_SUB_SC
 * - Terminated Soft Copy (3) :: UNCHECKED_TER_SC
 * - Suspended Soft Copy (4) :: UNCHECKED_SUS_SC
 */
const PSN_SOFT_COPY_STATUS = {
  UNCHECKED_PV_SC: '0',
  UNCHECKED_V_SC: '1',
  CHECKED_SUB_SC: '2',
  UNCHECKED_TER_SC: '3',
  UNCHECKED_SUS_SC: '4'
};

export {
  PTS_HARD_COPY_STATUS,
  PTS_CDP_HARD_COPY_STATUS,
  PSN_SOFT_COPY_STATUS
};
