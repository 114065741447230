import { withInitDOM } from 'sgx-base-code';
import tmpl from './template-settings-page.html';
import StoreRegistry from 'stores/store-registry';
import * as constants from 'services/user-service/src/user-service-constants';
import userService from 'services/user-service';

/**
 * Settings page template.
 */
class TemplateSettingsPage extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-settings-page');
    this._container = this.querySelector('.template-settings-page-container');
    this._userTemplate = this.querySelector('template-settings-user');
    this._accountTemplate = this.querySelector('template-settings-account');
    this._securityTemplate = this.querySelector('template-settings-security');
    this._notificationsTemplate = this.querySelector('template-settings-notifications');
    this._applicationTemplate = this.querySelector('template-settings-application');
  }

  disconnectedCallback() {
    this._subscription.unsubscribe();
  }

  setData() {
    this._initStore();
  }

  /**
   * Subscribe to the store handling which template to show.
   */
  _initStore() {
    this._store = StoreRegistry.pageHeaderNavigation;
    this._subscription = this._store.subscribe(data => {
      if (data && data.section) {
        this._renderTemplate(data.section);
      }
    });
  }

  /**
   * Render the template given a current store section.
   * @param {String} section The current store section
   */
  _renderTemplate(section) {

    // Construct array of templates
    const templates = [
      this._userTemplate,
      this._accountTemplate,
      this._securityTemplate,
      this._notificationsTemplate,
      this._applicationTemplate
    ];

    // Remove all active states
    templates.forEach(template => {
      if (template.classList.contains('template-settings-page-section--active')) {
        template.classList.toggle('template-settings-page-section--active');
      }
    });

    // Apply active state to section
    if (section && this[`_${section}Template`]) {
      this[`_${section}Template`].classList.add('template-settings-page-section--active');
    }
  }

  // todo: this is commented, in-case required back in future | should be removed eventually

  /*updateNavigationHeaders(headers) {
    const {accountType} = StoreRegistry.cdpSession.getData();

    // If no cdp data in store, reject the promise
    if (!Object.keys(StoreRegistry.cdpSession.getData()).length) {
      return Promise.reject({subLinks: headers, activateTab: 4})
    }

    if (!accountType) {
      return userService.getUserAccounts()
        .then(_ => this.updateNavigationHeaders(headers))
        .catch(_ => {
          return Promise.reject({subLinks: headers, activateTab: 4})
        });
    }

    if (accountType === constants.ACCOUNT_STATES.JOINT_AND) {
      headers = headers.filter(header => {
        return header.link !== './profile/notifications'
      });
    }
    return Promise.resolve(headers);
  }*/

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

customElements.define('template-settings-page', withInitDOM(TemplateSettingsPage));
