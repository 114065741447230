import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-account-bank.html';

class BankAccountWidget extends HTMLElement {
  constructor() {
    super();
    this._showDcsDialog = this._showDcsDialog.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-account-bank');
    this._title = this.querySelector('.widget-account-bank-title');
    this._dcsDisplay = this.querySelector('.widget-account-bank-dcs-display');
    this._updateDcsDialog = this.querySelector('.widget-dcs-dialog');
  }

  connectedCallback() {
    this._setListeners(true);
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  setData({ accountId } = {}) {
    this._accountId = accountId;
    this._dcsDisplay.setData({ accountId });
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this[fnName]('show-dcs-dialog', this._showDcsDialog);
  }

  /**
   * Shows the create/update DCS dialog.
   */
  _showDcsDialog(e) {
    const autoCcy = e.detail && e.detail.autoCcy;
    this._updateDcsDialog.show({ accountId: this._accountId, autoCcy });
  }
}

customElements.define('widget-settings-account-bank', withInitDOM(BankAccountWidget));
