let instance = null;

class UserPreferencesService {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Mock get user preferences call.
   */
  getUserNotifications() {
    return import(`../stubs/user-notifications.json`)
      .then(response => response.data);
  }

  /**
   * Mock update user preferences call.
   */
  updateUserPreferences(data) {

    // const mockCall = {
    //   "status": {
    //     "code": "403",
    //     "message": "Forbidden",
    //     "reason": "TRANSACTION_SIGNING_REQUIRED"
    //   }
    // };

    const mockCall = {
      "status": {
        "code": "200",
        "message": "Success",
      }
    };

    return new Promise((resolve, reject) => {
      if (mockCall) {
        setTimeout(() => resolve(mockCall), 1000);
      }
      else {
        reject(Error('Failed to update user particulars.'));
      }
    });
  }

  /**
   * Gets a user's favorite securities
   *
   * @return {Promise<Array>} a promise
   */
  getFavoriteSecurities() {
    return import('../stubs/user-preferences-favorite-securities.json')
      .then(response => (response.data && response.data.watchlist) || [])
      .catch(error => Promise.reject(error));
  }

  /**
   * Updates the entire list of user's favorite securities
   *
   * @param {array<string>} counters list of the favorite security codes
   * @return {Promise} a promise
   */
  updateFavoriteSecurities(counters) {
    // TODO - get loginId from a store instead of passing it as a param?
    const mockCall = {
      "status": {
        "code": "200",
        "message": "Success",
      }
    };

    return new Promise((resolve, reject) => {
      if (mockCall) {
        setTimeout(() => resolve(mockCall), 1000);
      }
      else {
        reject(Error('Failed to add security as favorite.'));
      }
    });
  }
}

export default new UserPreferencesService();
