import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-ccy-subscription.html';
import i18n from '@sgx/sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import { getSubscriptionStateToRender } from 'utils/ccy-util';
import CcyService from 'services/ccy-service';
import { ACCOUNT_STATES } from 'services/user-service/src/user-service-constants';

export default class WidgetCCYSubscription extends HTMLElement {

  constructor() {
    super();

    this._cdpSessionStore = StoreRegistry.cdpSession;
    this.showSubscriptionDialog = this.showSubscriptionDialog.bind(this);
    this.updateCurrentSubscriptionStatus = this.updateCurrentSubscriptionStatus.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-ccy-subscription');

    this._ccyStatusIndicator = this.querySelector('.widget-ccy-subscription-status-indicator');
    this._updateSubscriptionBtn = this.querySelector('.widget-ccy-subscription-update-btn');
    this._footerTitle = this.querySelector('.widget-ccy-subscription-footer-title');
    this._footerDescription = this.querySelector('.widget-ccy-subscription-footer-description');
    this._widgetCCYSubscriptionDialog = this.querySelector('widget-ccy-subscription-dialog');
  }

  connectedCallback() {
    this.setListeners(true);
    this.updateCurrentSubscriptionStatus();
  }

  disconnectedCallback() {
    this.setListeners(false);
  }

  updateCurrentSubscriptionStatus() {
    const readOnlyAccountTypes = [ACCOUNT_STATES.CORPORATE, ACCOUNT_STATES.CORPORATE_TRUSTEE, ACCOUNT_STATES.JOINT_AND];

    this._cdpSessionStore.subscribe(({status = 'UNSUBSCRIBED', canCCYUpdate, selectedAccountType, bankId}) => {
      if (status) {
        const methodName = !readOnlyAccountTypes.includes(selectedAccountType) && canCCYUpdate ? 'remove' : 'add';
        this._updateSubscriptionBtn.classList[methodName]('sgx-hidden');
        this.setData(status);
      }
    });
  }

  setData(status) {
    const footerDescription = i18n.getTranslation(`widget-ccy-subscription.footer.description_${getSubscriptionStateToRender(status)}_html`);
    const footerTitle = i18n.getTranslation(`widget-ccy-subscription.footer.title_${getSubscriptionStateToRender(status)}_html`);
    const buttonState = i18n.getTranslation(`widget-ccy-subscription.button.${getSubscriptionStateToRender(status)}`);

    this.setFooterDescription(footerDescription, footerTitle);
    this.setSubscriptionButton(buttonState);
    this.setStatusIndicator(status);
  }

  setListeners(state) {
    const funcName = state ? 'addEventListener' : 'removeEventListener';
    this._updateSubscriptionBtn[funcName]('click', this.showSubscriptionDialog);
  }

  setFooterDescription(description, title) {
    this._footerDescription.innerHTML = description;
    this._footerTitle.innerHTML = title;
  }

  setStatusIndicator(state) {
    const text = i18n.getTranslation(`widget-ccy-subscription.status.${CcyService.getDisplayStatusForSubscription(state).toLowerCase().replace(' ', '-')}`);
    const icon = CcyService.getDisplayStatusForSubscriptionIcon(state);

    this._ccyStatusIndicator.setData({text, icon})
  }

  setSubscriptionButton(state) {
    this._updateSubscriptionBtn.textContent = state;
    if (state.toLowerCase() === 'subscribe') {
      this._updateSubscriptionBtn.classList.add('sgx-button--primary');
      this._updateSubscriptionBtn.classList.remove('sgx-button--secondary');
    } else {
      this._updateSubscriptionBtn.classList.remove('sgx-button--primary');
      this._updateSubscriptionBtn.classList.add('sgx-button--secondary');
    }
  }

  showSubscriptionDialog() {
    this._widgetCCYSubscriptionDialog.showDialog();
  }

}

customElements.define('widget-ccy-subscription', withInitDOM(WidgetCCYSubscription));
