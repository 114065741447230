module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-settings-security-password-dialog\">\n  <sgx-dialog class=\"widget-password-dialog\" role=\"dialog\">\n    <h2 class=\"widget-password-dialog-title\" data-i18n=\"app.widget-settings-security-password-dialog.title\" data-dialog=\"title\"></h2>\n    <sgx-form class=\"widget-password-dialog-form sgx-form\" data-dialog=\"body\">\n      <div class=\"widget-password-dialog-container\">\n        <p class=\"widget-password-dialog-description sgx-base-text-body-14\" data-i18n=\"[html]app.widget-settings-security-password-dialog.description_html\"></p>\n        <sgx-input-password name=\"oldPassword\" id=\"oldPassword\" class=\"widget-password-dialog-old-password fieldset-input\" data-i18n=\"[label]app.widget-settings-security-password-dialog.inputs.oldPassword.label\"></sgx-input-password>\n        <sgx-input-password name=\"newPassword\" id=\"newPassword\" class=\"widget-password-dialog-new-password fieldset-input\" data-i18n=\"[label]app.widget-settings-security-password-dialog.inputs.newPassword.label\"></sgx-input-password>\n        <sgx-input-password name=\"confirmPassword\" id=\"confirmPassword\" class=\"widget-password-dialog-confirm-password fieldset-input\" data-i18n=\"[label]app.widget-settings-security-password-dialog.inputs.confirmPassword.label\"></sgx-input-password>\n      </div>\n      <div class=\"widget-password-dialog-buttons\">\n        <button type=\"button\" class=\"widget-password-dialog-button-dismiss sgx-button--text\">\n          <span data-i18n=\"app.widget-settings-security-password-dialog.buttons.cancel\"></span>\n        </button>\n        <button type=\"submit\" class=\"widget-password-dialog-button-confirm sgx-button--primary\" disabled>\n          <span data-i18n=\"app.widget-settings-security-password-dialog.buttons.confirm\"></span>\n        </button>\n      </div>\n    </sgx-form>\n  </sgx-dialog>\n\n  <cmp-otp-prompt></cmp-otp-prompt>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()