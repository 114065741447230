import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-notifications-actions.html';
import i18n from 'sgx-localisation-service';
import ConfigService from 'sgx-config-service';

class NotificationsActionsWidget extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-notifications-actions');
    this._statusContainer = this.querySelector('.widget-notifications-status');
    this._status = this.querySelector('.widget-notifications-status-text');
    this._icon = this.querySelector('.widget-notifications-status-icon');
    this._indicator = this.querySelector('sgx-status-indicator');
  }

  connectedCallback() {
    this._statusContainer.style.display = 'none';
    this._indicator.hide();
  }

  /**
   * Shows a loading status
   */
  showLoading() {
    this._indicator.show({status: 'loading'});
  }

  /**
   * Set the status of the update response.
   * @module widget-settings-notifications-actions
   * @type {HTMLElement}
   * @param code The http response code
   */
  setStatus(code) {

    if (code) {
      // Hide status indicator
      this._indicator.hide();

      // Show status message and icon
      this._statusContainer.style.display = 'flex';
    }

    // Determine status message
    switch (code) {
      case '200':
        this._displayStatus('success');
        break;
      case '202':
        this._displayStatus('pending');
        break;
      case '401':
        this._status.textContent = i18n.getTranslation('app.widget-settings-user-actions.status.suspended');
        this._status.classList.add('widget-notifications-status--red');
        break;
      case '500':
        this._displayStatus('failed');
        break;
      default:
        this._status.classList.remove('widget-notifications-status--red');
    }
  }

  /**
   * Helper function to display update status.
   * @module widget-settings-notifications-actions
   * @type {HTMLElement}
   * @param type The type of status update
   */
  _displayStatus(type) {

    // Set status text and icon
    this._status.textContent = i18n.getTranslation(`app.widget-settings-notifications-actions.status.${type}`);
    this._icon.classList.add(`widget-notifications-status-icon--${type}`);

    // Remove status after 7 seconds
    setTimeout(() => this._statusContainer.style.display = 'none', ConfigService.env.timeout.settings);
  }
}

customElements.define('widget-settings-notifications-actions', withInitDOM(NotificationsActionsWidget));
