import {withInitDOM} from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import SecuritiesService from 'sgx-securities-service';
import DateService from 'sgx-date-time-service';
import CorporateActionsService from 'services/sgx-corporate-actions-service';
import {abbreviateNumber, formatThousands, toFixed} from 'utils/price-util';
import {
  dividendTableConfig,
  ownershipTableConfig,
  stockFactsTabConfig
} from './widget-security-prices-details-config';
import tmpl from './widget-security-prices-details.html';

const DATE_FORMAT = i18n.getTranslation('app.format.date.default');

/**
 * Security Prices Details for both stocks and non-stocks.
 */
class WidgetSecurityPricesDetails extends HTMLElement {
  constructor() {
    super();
    this._onToggleMetrics = this._onToggleMetrics.bind(this);
    this._onStockFactsTabChange = this._onStockFactsTabChange.bind(this);
  }

  initDOM() {
    this.classList.add('widget-security-prices-details');
    this.appendChild(tmpl.getNode());

    this._stockFactsTab = this.querySelector('.prices-stockfacts-tabs');
    this._valuation = this.querySelector('.prices-valuation-container');
    this._financials = this.querySelector('.prices-financials-container');
    this._overview = this.querySelector('.prices-overview-container');
    this._metrics = this.querySelector('.prices-metrics-container');
    this._dividendTable = this.querySelector('.prices-dividend-table');
    this._ownershipTable = this.querySelector('.prices-ownership-table');
    this._metricsToggle = this.querySelector('.prices-metrics-toggle');
    this._dividendLink = this.querySelector('.prices-dividend-link');
    this._financialTimestamp = this.querySelector('.prices-financials-timestamp');
  }

  connectedCallback() {

    this._dividendTable.setConfig(dividendTableConfig);
    this._ownershipTable.setConfig(ownershipTableConfig);
    this._stockFactsTab.setConfig(stockFactsTabConfig.config);
    this._stockFactsTab.setState(stockFactsTabConfig.state);

    this._stockFactsTab.addEventListener('stateChange', this._onStockFactsTabChange);
    this._metricsToggle.addEventListener('click', this._onToggleMetrics);
  }

  disconnectedCallback() {

    this._stockFactsTab.removeEventListener('stateChange', this._onStockFactsTabChange);
    this._metricsToggle.removeEventListener('click', this._onToggleMetrics);
  }

  setData(data) {
    this._data = data;
    if (this._securityCode !== data.nc) {
      this._securityCode = data.nc;
      this._stockData = data.stockData || {};
      this._historicPrices = null;
      this._dividends = null;
    }

    this._setOverview();
    this._setValuation();
    this._setFinancials();
    this._setDividends();
    this._setOwnership();
  }

  _setOverview() {
    const {isStockfacts} = this._data;
    if (isStockfacts) {
      this._setStocksfactsOverview();
    } else {
      this._setNonStocksOverview();
    }
  }

  _setStocksfactsOverview() {
    const callback = ([historicPrices, pricesResponse]) => {
      this._historicPrices = historicPrices || {};
      const {h = null, l = null, o, vl, lt, trading_time} = this._historicPrices;
      const prices = pricesResponse && pricesResponse[this._data.nc] || {};
      const currencyPrefix = prices.cur || '';
      const {ratiosReport = {}} = this._stockData || {};
      const adjustedVWAP = prices && prices['adjusted-vwap'] !== null && `${prices['vwap-currency']} ${prices['adjusted-vwap']}`;
      const unadjustedVWAP = prices && prices.vwap !== null && `${prices['vwap-currency']} ${prices.vwap}`;
      const prevDayHl = h == null && l == null ? null : `${formatThousands(h, 3, true)} - ${formatThousands(l, 3, true)}`;
      const fiftyTwoHL = ratiosReport && ratiosReport.high52Week == null && ratiosReport.low52Week == null ? null : `${formatThousands(ratiosReport.high52Week, 3, true)} - ${formatThousands(ratiosReport.low52Week, 3, true)}`;

      const data = this._createCollapsibleColumnData({
        'prev-open-price': o ? `${currencyPrefix} ${formatThousands(o, 3, true)}` : '',
        'prev-high-low': prevDayHl ? `${currencyPrefix} ${prevDayHl}` : '',
        'prev-vol': vl ? `${formatThousands(vl, 0, true)}K` : '',
        'prev-close': lt ? `${currencyPrefix} ${formatThousands(lt, 3)}` : '',
        'prev-close-date': trading_time ? DateService.utc(trading_time, 'YYYYMMDD_HHmmss').format(DATE_FORMAT) : '',
        '52-week-high-low': fiftyTwoHL,
        '5-year-beta': formatThousands(ratiosReport.beta5Year, 2),
        'shares-outstanding': ratiosReport.sharesOutstanding ? abbreviateNumber(Number(ratiosReport.sharesOutstanding), 2) : '',
        'avg-3-mth-vol': ratiosReport.average3MonthVol ? `${formatThousands(ratiosReport.average3MonthVol, 2, true)}M` : '',
        'normalized-diluted-eps': formatThousands(ratiosReport.normalDilutedEps, 2),
        'unadj-6-mth-vwap': unadjustedVWAP,
        'adj-6-mth-vwap': adjustedVWAP
      }, 'overview.stocks');
      this._stockFactsTab.classList.remove('sgx-hidden');
      this._overview.setData(data);
      this._setMetricsData({addTotalMarketCapFlag: true, stProduct: false});
      this._reAlignOverviewTabData();
    };

    Promise.all([
      this._getHistoricPrices().catch(_ => {
      }),
      this._getVwapRelatedInfo().catch(_ => [])
    ])
      .then(callback);
  }

  _setNonStocksOverview() {
    const data = this._data;
    const currency = data.type !== "dlcertificates" ? (data.cur || '') : '';
    const columnData = this._createCollapsibleColumnData({
      'open-st': data.o ? `${currency} ${formatThousands(data.o, 3, true)}` : '',
      'high': abbreviateNumber(data.h, 3) || '-',
      'low': abbreviateNumber(data.l, 3) || '-',
      'close-st': data.cx ? `${currency} ${formatThousands(data.cx, 3, true)}` : '',
      'value': Number(data.v) === 0 ? '-' : `$ ${formatThousands(data.v, 2, false)}M`,
      'volume': `${formatThousands(data.vl, 1, true)}K`,
      'sell': Number(data.s) === 0 ? '-' : abbreviateNumber(Number(data.s) || 0, 3),
      'buy': Number(data.b) === 0 ? '-' : abbreviateNumber(Number(data.b) || 0, 3),
      'sell-volume': Number(data.sv) === 0 ? '-' : `${formatThousands(data.sv, 1, false)}K`,
      'buy-volume': Number(data.bv) === 0 ? '-' : `${formatThousands(data.bv, 1, false)}K`
    }, 'overview.non-stocks');
    this._stockFactsTab.classList.add('sgx-hidden');
    this._overview.setData(columnData);
    this._setDLCIssuer();
    this._setMetricsData({addTotalMarketCapFlag: false, stProduct: true});
    this._reAlignOverviewTabData();
  }

  _setMetricsData({addTotalMarketCapFlag, stProduct}) {
    const data = this._data;
    const commonData = {
      'activities': typeof data.rmk !== 'string' ? data.rmk.join(' ') : data.rmk,
      'listing-type': data.ls,
      'market': data.m,
      'sector': data.sc,
      'board-lot': data.bl,
      'sip': data.sip,
      'spac': data.sip.toUpperCase() === 'SP' ? i18n.getTranslation('app.widget-security-prices-details.metrics.values.sip.yes') : i18n.getTranslation('app.widget-security-prices-details.metrics.values.sip.no'),
      'tt': data.sip.toUpperCase() === 'TT' ? i18n.getTranslation('app.widget-security-prices-details.metrics.values.sip.yes') : i18n.getTranslation('app.widget-security-prices-details.metrics.values.sip.no'),
      'issuer': data.dlc_issuer || data.i,
      'dual-class-shares': data.dcs,
      'currency': data.cur,
      'home-exchange': data.ex,
    };

    const nonSTData = {
      ...commonData,
      'expiry-date': data.ed ? DateService(data.ed).format(DATE_FORMAT) : '',
      'exercise-level': data.el,
      'conversion-ratio': data.cr,
      'jurisdiction': data.ej,
      'clo': data.clo,
    }

    const stData = {
      ...commonData,
    }

    let columnData = this._createCollapsibleColumnData(stProduct ? {...stData} : {...nonSTData}, 'metrics');
    if (addTotalMarketCapFlag) {
      // This one is pushed last because of odd number of displayed items
      const {ratiosReport = {}} = this._stockData || {};
      const totalMktCap = this._createCollapsibleColumnData({
        'total-mkt-cap': this._getSuffixedValue(ratiosReport.totalMarketCap)
      }, 'overview.stocks');
      columnData = columnData.concat(totalMktCap);
    }
    this._metrics.setData(columnData);
    this._metricsToggle.classList.remove('sgx-hidden');
  }

  /**
   * @desc re-align data points as mentioned in INVESTORS-2005
   * */
  _reAlignOverviewTabData() {
    const overViewData = this._overview.getData();
    const metricsData = this._metrics.getData();
    let overviewIndexMap = {};
    let metricsIndexMap = {};
    overViewData.forEach((item, index) => {
      switch (item.key) {
        case 'adj-6-mth-vwap':
        case 'unadj-6-mth-vwap':
          overviewIndexMap[item.key] = index;
      }
    });


    metricsData.forEach((item, index) => {
      switch (item.key) {
        case 'total-mkt-cap':
        case 'clo':
        case 'sector':
          metricsIndexMap[item.key] = index;
      }
    })

    let adj6MthVwap = overViewData[overviewIndexMap['adj-6-mth-vwap']];
    let unAdj6MthVwap = overViewData[overviewIndexMap['unadj-6-mth-vwap']];

    const totalMktCap = metricsData[metricsIndexMap['total-mkt-cap']];
    const sector = metricsData[metricsIndexMap['sector']];
    const clo = metricsData[metricsIndexMap['clo']];

    if (adj6MthVwap && totalMktCap) {
      overViewData[overviewIndexMap['adj-6-mth-vwap']] = totalMktCap;
      metricsData[metricsIndexMap['total-mkt-cap']] = adj6MthVwap;
    }

    if (unAdj6MthVwap && sector && clo) {
      overViewData[overviewIndexMap['unadj-6-mth-vwap']] = sector;
      metricsData[metricsIndexMap['sector']] = clo;
      metricsData[metricsIndexMap['clo']] = unAdj6MthVwap;
    }

    this._overview.setData(overViewData);
    this._metrics.setData(metricsData);
  }

  /**
   * @desc update some DLC Issuer
   */
  _setDLCIssuer() {
    const ISSUER_NAME = 'UBS AG';
    const DLC_CODES = [
      "WA9W",
      "JQYW",
      "QFYW",
      "VJLW",
      "LQRW",
      "TSGW",
      "TYTW",
      "HMMW",
      "KNXW",
      "HOHW",
      "IKRW",
      "MJHW",
      "QAIW",
      "SNEW",
      "9HNW",
      "JMFW",
      "5L7W",
      "FDWW",
      "UPRW",
      "XKJW",
      "YCPW",
      "IFDW",
      "QGGW",
      "EHUW",
      "RQQW",
      "LXDW",
      "KGRW",
      "NMCW",
      "UORW",
      "HBYW",
      "UFSW",
      "X5HW",
      "WNLW",
      "OOSW",
      "9QEW",
      "5O9W",
      "ITQW",
      "HSCE",
      "HSTECH",
      "IXH",
      "0857.HK",
      "0939.HK",
      "0992.HK",
      "1919.HK",
      "2020.HK",
      "2269.HK",
      "2331.HK",
      "2382.HK",
      "2628.HK",
      "3968.HK",
      "1299.HK",
      "0027.HK"
    ]
    DLC_CODES.forEach(code => {
      if (this._data.nc === code) {
        this._data.dlc_issuer = ISSUER_NAME
      }
    });
  }

  _setValuation() {
    const {ratiosReport = {}, snapshotReport = {}} = this._stockData || {};
    const data = this._createCollapsibleColumnData({
      'price-book-value': formatThousands(ratiosReport.priceBookVal, 3),
      'enterprise-value': this._getSuffixedValue(snapshotReport.enterpriseValue),
      'price-sales': formatThousands(ratiosReport.priceSales, 3),
      'price-cf': formatThousands(ratiosReport.priceCf, 3),
      'dividend-yield': formatThousands(this._data.yield, 2),
      'pe-ratio': formatThousands(this._data.pe, 2),
      'dividend-yield-5-year': formatThousands(ratiosReport.yield5Year, 3),
      'net-debt': this._getSuffixedValue(ratiosReport.netDebt)
    }, 'valuation');
    this._valuation.setData(data);
  }

  _setFinancials() {
    const {ratiosReport = {}, snapshotReport = {}, financialReports = {}} = this._stockData || {};
    const reports = (financialReports && financialReports.reports) ? financialReports.reports : [];
    const latestFinancialReport = Array.isArray(reports) && reports.length ?
      reports[reports.length - 1] : {};
    let {balanceSheet = {}, cashFlow = {}, incomeStatement = {}} = latestFinancialReport;
    balanceSheet = balanceSheet || {};
    cashFlow = cashFlow || {};
    incomeStatement = incomeStatement || {};
    const timestamp = (ratiosReport  || {}).timestamp;
    const timestampContent = timestamp && DateService(timestamp).format(i18n.getTranslation('app.widget-security-prices-details.financials.timestamp')) || '';
    const data = this._createCollapsibleColumnData({
      'total-assets': this._getSuffixedValue(balanceSheet.totalAssets),
      'total-debt': this._getSuffixedValue(balanceSheet.totalDebt),
      'cash-short-term-investments': this._getSuffixedValue(balanceSheet.cashShortTermInvest),
      'long-term-debt-equity': formatThousands(ratiosReport.longTermDebt, 3),
      'roe': snapshotReport.roe,
      'roa': formatThousands(ratiosReport.roa, 3),
      'asset-turnover': formatThousands(ratiosReport.assetTurnover, 3),
      'capex': this._getSuffixedValue(cashFlow.capitalExpenditures),
      'current-ratio': formatThousands(ratiosReport.currentRatio, 3),
      'quick-ratio': formatThousands(ratiosReport.quickRatio, 3),
      'total-rev': this._getSuffixedValue(ratiosReport.totalRevenue),
      'rev-employee': formatThousands(ratiosReport.revEmployee, 2),
      'ebitda': this._getSuffixedValue(ratiosReport.ebitda, 2),
      'net-interest-coverage': formatThousands(ratiosReport.netInterestCoverage, 3),
      'operating-income': this._getSuffixedValue(incomeStatement.operatingIncome),
      'net-income': this._getSuffixedValue(ratiosReport.netIncome),
      'operating-margin': formatThousands(ratiosReport.operatingMargin, 3),
      'net-profit-margin': formatThousands(ratiosReport.netProfitMargin, 3),
      'revenue-share-5-year': formatThousands(ratiosReport.revShare5Year, 3),
      'eps-5-year': formatThousands(ratiosReport.eps5Year, 3)
    }, 'financials');
    this._financials.setData(data);
    this._financialTimestamp.textContent = timestampContent;
  }

  _setDividends() {
    if (this._dividends) {
      this._dividendTable.recalculateSize();
      return;
    }

    const corporateActionsURL = './company-disclosures/corporate-actions';

    this._dividendTable.classList.add('loading');
    this._getDividends()
      .then(dividends => {
        if (!this._dividends) {
          this._dividends = dividends.map(item => ({
            ...item,
            id: item.id,
            particulars: {
              label: item.particulars,
              link: `${corporateActionsURL}?annc=${item.id}`
            }
          }));
          this._dividendTable.setData(this._dividends);
          this._dividendTable.recalculateSize();
          this._dividendTable.classList.remove('loading');
        }
      })
      .then(_ => {
        if (!this._dividends || !this._dividends.length) {
          this._dividendLink.classList.add('sgx-hidden');
        } else {
          this._dividendLink.href = `${corporateActionsURL}?cat=DIVIDEND&value=${this._dividends[0].name.toUpperCase()}`;
          this._dividendLink.classList.remove('sgx-hidden');
        }
      })
      .catch(_ => {
        this._dividendTable.classList.remove('loading');
        this._dividendTable.classList.add('error');
      });
  }

  _setOwnership() {
    const {shareholderReports = []} = this._stockData || {};
    const tableData = shareholderReports.map((item, index) => ({
      id: index,
      holdingsDate: item.holdingsDate,
      name: item.name || '-',
      type: item.type || '-',
      holdingsPercentage: item.holdingsPercentage ? `${toFixed(item.holdingsPercentage, 4)}%` : '-',
      sharesHeld: formatThousands(item.sharesHeld, 0),
      sharesHeldChange: formatThousands(item.sharesHeldChange, 0, true),
      turnoverRating: item.turnoverRating || '-'
    }));
    this._ownershipTable.setData(tableData);
    this._ownershipTable.recalculateSize();
  }

  _getHistoricPrices() {
    return new Promise((resolve, reject) => {
      if (this._historicPrices) {
        resolve(this._historicPrices);
        return;
      }

      SecuritiesService.getChartData({
        category: this._data.type,
        chartType: 'historic',
        idType: 'code',
        id: this._data.nc,
        period: '1w'
      })
        .then(result => {
          const data = result.data || [];
          resolve(Array.isArray(data) && data.length ? data[data.length - 1] : {});
        })
        .catch(reject);
    })
  }

  _getVwapRelatedInfo() {
    const {type, nc} = this._data || {};
    return SecuritiesService.getPrices({
      category: type,
      code: nc
    });
  }

  _getDividends() {
    return new Promise((resolve, reject) => {
      if (this._dividends) {
        resolve(this._dividends);
        return;
      }

      const ibmcode = this._data && this._data.ibmCode || '';
      const options = {
        pagesize: 10,
        pagestart: 0,
        ibmcode,
        cat: 'dividend'
      };

      if (!ibmcode) {
        return resolve([]);
      }

      CorporateActionsService.getCorporateActions(options)
        .then(result => {
          const data = result.data || [];
          resolve(data);
        })
        .catch(reject);
    });
  }

  get _currentTab() {
    return this.querySelector('.prices-stockfacts-tab-item:not(.sgx-hidden)');
  }

  _createCollapsibleColumnData(data, translationKey) {
    return Object.keys(data).reduce((arr, key) => {
      arr.push({
        title: i18n.getTranslation(`app.widget-security-prices-details.${translationKey}.${key}`),
        value: data[key] || '-',
        tooltip: i18n.getTranslation(`app.widget-security-prices-details.${translationKey}.${key}-tooltip`, {defaultValue: null}),
        key
      });
      return arr;
    }, []);
  }

  _onToggleMetrics(e) {
    e.preventDefault();
    const pathKey = this._metrics.classList.toggle('sgx-hidden') ? 'expand' : 'collapse';
    this._metricsToggle.textContent = i18n.getTranslation(`app.widget-security-prices-details.metrics.link.${pathKey}`);
  }

  _onStockFactsTabChange(e) {
    const oldTab = this._currentTab;
    const position = this._stockFactsTab.getState().activeStateId + 1;
    const activeTab = this.querySelector(`.prices-stockfacts-tab-item:nth-child(${position})`);
    const fnName = e.detail;
    if (oldTab) {
      oldTab.classList.add('sgx-hidden');
    }
    activeTab.classList.remove('sgx-hidden');
    this[fnName]();
  }

  _getSuffixedValue(value) {
    let newVal = formatThousands(value, 2, true);
    return Number(newVal) !== 0 ? `${newVal}M` : null;
  }
}

customElements.define('widget-security-prices-details', withInitDOM(WidgetSecurityPricesDetails));
