module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-portfolio-account-details\">\n  <div>\n    <div class=\"account-info-header\">\n        <span class=\"sgx-input-label\" data-i18n=\"app.widget-settings-account-actions.label\"></span>\n        <span id=\"account-detail\"></span>\n      </div>\n      <sgx-tabs class=\"portfolio-view-details-tabs\"></sgx-tabs>\n      <div class=\"portfolio-view-details-tab-items-container\">\n        <widget-portfolio-owner-info class=\"portfolio-view-details-tabs-item\"></widget-portfolio-owner-info>\n        <widget-portfolio-registered-accounts\n          class=\"portfolio-view-details-tabs-item sgx-hidden\"></widget-portfolio-registered-accounts>\n        <widget-portfolio-notification-settings\n          class=\"portfolio-view-details-tabs-item sgx-hidden\"></widget-portfolio-notification-settings>\n      </div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()