import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-list-row-quick-links.html';

class QuickLink extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-quick-links');
    this._setAllSelectors();
  }

  connectedCallback() {}

  /**
   * Sets value to the html template
   * @param {array} data
   * @param {string} data.title
   * @param {object} data.image
   * @param {string} data.image.url
   * @param {object} data.links
   * @param {string} data.links.text
   * @param {string} data.links.url
   * @param {string} data.type
   *
   */
  setData(data) {
    if (!data) return;
    this._data = data;

    this._setTitleBgImage(this._data);
    this._setLinks(this._data);
  }

  _setLinks(value) {
    for (const OBJ of value.links) {
      const divEle = document.createElement('div');
      this._linkList.appendChild(divEle);
      // check for login type
      const classType = this._data.type === 'preLogin' ? 'pre-login' : 'post-login';


      this._linkList.classList.add(`cmp-list-row-quick-links--${classType}-links`);
      const divClassList = [`cmp-list-row-quick-links--${classType}-each-link`];
      divEle.classList.add(...divClassList);

      const spanEle = document.createElement('span');
      divEle.appendChild(spanEle);
      `/*const spanClassList = ['sgx-icon', 'cmp-list-row-quick-links--link-icon'];
      spanEle.classList.add(...spanClassList);*/`

      const anchorEle = document.createElement('a');
      divEle.appendChild(anchorEle);

      anchorEle.innerText = OBJ.text;
      anchorEle.setAttribute('data-analytics-label', OBJ.url);
      anchorEle.setAttribute('href', OBJ.url);
      anchorEle.setAttribute('target', '_blank');
      const anchorClassList = ['sgx-base-text-body-14', 'cmp-list-row-quick-links--anchor-tag'];
      anchorEle.classList.add(...anchorClassList);
    }
  }

  _setTitleBgImage(value) {
    const titleTextEle = document.createElement('h4');
    // this._textBgImage.appendChild(titleTextEle);
    this._textBgImage.innerText = value.title;
    // titleTextEle.innerHTML = value.title;
    this._textBgImage.style.backgroundImage = `url(${value.image.url})`;
  }

  _setAllSelectors() {
    this._textBgImage = this.querySelector('.cmp-list-row-quick-links--text-bg-image');
    this._linkList = this.querySelector('#cmp-list-row-quick-links--links');
  }
}

customElements.define('cmp-list-row-quick-links', withInitDOM(QuickLink));
