import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-application-language.html';
import i18n from 'sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import { fromEvent } from 'rxjs';
import { take, debounceTime } from 'rxjs/operators';

class LanguageSettingsWidget extends HTMLElement {
  constructor() {
    super();
    this._store = StoreRegistry.appSettings;
    this._cdpStore = StoreRegistry.cdpSession;
    this._store.loadData();
    this._store.setAutoSave(true);
    this._subscriptions = [];
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-application-language');
    this._languageInput = this.querySelector('.widget-language-options');
  }

  connectedCallback() {

    // Initialize options
    const languageOptions = ['en', 'zh-cn'].map(lang => {
      return {
        'value': lang,
        'label': i18n.getTranslation(`app.widget-settings-application-language.language.${lang}`)
      };
    });
    this._languageInput.setOptions(languageOptions);

    this._setListeners();
  }

  disconnectedCallback() {
    (this._subscriptions || []).forEach(sub => sub.unsubscribe());
  }

  _setListeners() {

    this._store
      .pipe(take(1))
      .subscribe(prefs => {
        Object.assign(prefs, {
          lang: prefs.lang || i18n.getLanguage()
        });
        this._languageInput.setValue(prefs.lang);
        this._store.setData(prefs);
      });

    this._subscriptions.push(
      fromEvent(this._languageInput, 'change')
        .pipe(debounceTime(500))
        .subscribe(() => {
          const lang = this._languageInput.getValue();
          this._store.setData(lang, 'lang');
          window.location.reload();
        }));

    // update the language to english if user loggedIn
    /*this._subscriptions.push(this._cdpStore.subscribe(({userId}) => {
      if (userId) {
        this._languageInput.setAttribute('disabled', 'disabled');
      }
    }));*/
  }
}

customElements.define('widget-settings-application-language', withInitDOM(LanguageSettingsWidget));
