import { withInitDOM } from '@sgx/sgx-base-code';
import i18n from '@sgx/sgx-localisation-service';
import DeviceService from '@sgx/sgx-device-service';
import dialogTemplate from './widget-dashboard-corporate-actions-dialog-template';

const DIALOG_ID = 'widget-dashboard-corporate-actions-dialog';

class WidgetCorporateActionsDialog extends HTMLElement {
  constructor() {
    super();
    this._afterShowDialog = this._afterShowDialog.bind(this);
  }

  initDOM() {
    this._makeDialogIfNeeded();
  }

  connectedCallback() {}

  showDialog() {
    const dialogConfig = {
      title: i18n.getTranslation('app.widget-dashboard-corporate-actions.title'),
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      afterShow: this._afterShowDialog
    };

    return this._dialog.show(dialogConfig)
      .then(() => this._dialogBody.getData())
      .catch(_ => {});
  }

  _makeDialogIfNeeded() {
    const template = dialogTemplate(DIALOG_ID);
    this._dialog = document.getElementById(DIALOG_ID);

    if (this._dialog) {
      this._dialogBody = this._dialog.querySelector('[data-dialog="body"]');
      return this._dialog;
    }

    document.body.insertAdjacentHTML('beforeend', template);

    this._dialog = document.getElementById(DIALOG_ID);
    this._dialogBody = this._dialog.querySelector('[data-dialog="body"]');
    return this._dialog;
  }

  _afterShowDialog() {
    this._dialogBody.setConfig({ context: 'dialog' });
    this._dialogBody.setData();
    this._dialogBody.recalculateSize();
  }
}

customElements.define('widget-dashboard-corporate-actions-dialog', withInitDOM(WidgetCorporateActionsDialog));
