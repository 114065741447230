import StoreRegistry from 'stores/store-registry';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { isBlankString } from 'utils/form-util';
import { ACCOUNT_STATES } from 'services/user-service/src/user-service-constants';
import InternationalCallingCode from '@sgx/sgx-forms/src/sgx-input-phone/InternationalCallingCode.js';

/**
 * @public
 * @desc: Save the data in format as expected in userStore
 * */
function formatDataForUserStore(data) {
  const addresses = [
    _getAddresses({addressObj: data.residentialAddress, type: 'RESIDENTIAL'}),
    _getAddresses({addressObj: data.mailingAddress, type: 'MAILING'}),
    _getAddresses({addressObj: data.caAddress, type: 'CA ADDRESS'}),
  ];

  const contacts = _getContacts(data);

  const email = data.email;
  const fullName = data.name;
  const taxResidencyDetails = data.taxResidencyDetails;
  const fatcaCrsDueDCheck = data.fatcaCrsDueDCheck;
  const _control = {
    mailingAddressOption: data.mailingAddress.country === 'SG' ? "withinSG" : "outsideSG",
    residentialAddressOption: data.residentialAddress.country === 'SG' ? "withinSG" : "outsideSG",
    useResidentialForMailing: JSON.stringify(data.mailingAddress) === JSON.stringify(data.residentialAddress)
  };
  const signatoryName = '';

  return {
    addresses,
    contacts,
    email,
    fatcaCrsDueDCheck,
    fullName,
    signatoryName,
    taxResidencyDetails,
    _control
  };

}

/**
 * @private
 * @desc sets the addresses based on type provided
 * */
function _getAddresses({
                         addressObj,
                         type
                       }) {

  const isCA = type === 'CA ADDRESS';
  const emptyIfNull = val => val === null ? '' : val;

  return {
    addressType: emptyIfNull(type),
    country: emptyIfNull(addressObj.country),
    postalCode: emptyIfNull(addressObj.postalCode || addressObj.postalcode),
    state: emptyIfNull(addressObj.state),
    streetOne: emptyIfNull(isCA ? addressObj.addressline2 : addressObj.addressline1),
    streetTwo: emptyIfNull(isCA ? addressObj.addressline1 : addressObj.addressline2),
    streetThree: emptyIfNull(addressObj.addressline3),
    streetFour: emptyIfNull(addressObj.addressline4),
  };
}

/**
 * @private
 * @desc: Set Values for contacts
 * */
function _getContacts(obj) {
  const mobilePhone = {
    number: obj.otherPhoneNumber.number,
    code: obj.otherPhoneNumber.countryCode && obj.otherPhoneNumber.countryCode.replace('+', '')
  };

  const homePhone = {
    number: obj.phoneNumber.number,
    code: obj.phoneNumber.countryCode && obj.phoneNumber.countryCode.replace('+', '')
  };

  const officePhone = {
    number: obj.officeNumber.number,
    code: obj.officeNumber.countryCode && obj.officeNumber.countryCode.replace('+', '')
  };

  return [mobilePhone, homePhone, officePhone];
}

/**
 * @method
 * @private
 * @param {Object} formData
 * @returns {Boolean}
 *
 * This method perform FATCA CRS DUE D check if residential/mailing address and
 * contact details against the country of tax residency to ensure a corresponding
 * tax details is declared for each country of address and contact details.
 */
function _setFatcaCheckFlag(formData) {
  let { hasAddressMismatch, hasIddMismatch, hasMismatch} = false;
  const mismatchCountries = []
  const countryFields = ['mailingAddressCountry', 'residentalCountry'];
  const phoneFields = ['homeContactIddCode', 'mobileContactIddCode', 'officeContactIddCode'];
  const declaredCountries = formData.taxResidencyDetails.map((taxResidency) => taxResidency.country);

  countryFields.forEach((countryField) => {
    if (formData[countryField]) {
      let isDeclared = declaredCountries.includes(formData[countryField]);
      if (!isDeclared) {
        hasAddressMismatch = true;
        mismatchCountries.push(formData[countryField]);
      }
    }
  });

  for(var i=0; i <= phoneFields.length; i++) {
    const phoneField = phoneFields[i];
    if (formData[phoneField]) {
      let codeDetails = InternationalCallingCode.getCountryByDialCode(formData[phoneField]) || {};
      let isDeclared = declaredCountries.includes(codeDetails.countryCode);
      if (codeDetails.countryCode === 'SG' && declaredCountries.includes('SG')) {
        isDeclared = true;
        hasIddMismatch = false;
        break;
      }
      if (!isDeclared) {
        hasIddMismatch = true;
        mismatchCountries.push(codeDetails.countryCode);
      }
    }
  }

  hasMismatch = hasIddMismatch || hasAddressMismatch;

  formData.fatcaCrsDueDCheck = hasMismatch ? '1' : '0';
}

/**
 * @public
 * @param {String} pristineData
 * @desc: convert the data object stored in settingsUser store, in a format expected by BE API
 * @returns: {Object} formObj
 * */
function createFormData(config = {}) {
  // Retrieve JSON object
  const isMyinfo = config.isMyinfo || false;
  const content = StoreRegistry.settingsUser.getData();
  const initialUserData = StoreRegistry.initialUserData.getData();
  const taxResidencyFieldset = document.querySelector('.widget-tax-residency-fieldsets');

  const phoneObj = { code: '', number: '' };
  const addressObj = {
    country: '',
    postalCode: '',
    state: '',
    streetOne: '',
    streetTwo: '',
    streetThree: '',
    streetFour: ''
  };

  //#region Adjust submission form data

  // Copy residential address to mailing if option was checked
  if (content._control.useResidentialForMailing) {
    content.addresses[1] = cloneDeep(content.addresses[0]);
    set(content, 'addresses[1].addressType', 'MAILING');

    if (content._control.residentialAddressOption === 'withinSG') {
      content.addresses[2] = cloneDeep(content.addresses[0]);
      set(content, 'addresses[2].addressType', 'CA ADDRESS');
    }
  } else if (content._control.mailingAddressOption === 'withinSG') {
    content.addresses[2] = cloneDeep(content.addresses[1]);
    set(content, 'addresses[2].addressType', 'CA ADDRESS');
  }


  const mobileContactNumberObj = content.contacts[0] || { ...phoneObj };
  const homeContactNumberObj = content.contacts[1] || { ...phoneObj };
  const officeContactNumberObj = content.contacts[2] || { ...phoneObj };

  const residentialAddressObj = content.addresses[0] || { ...addressObj };
  const mailingAddressObj = content.addresses[1] || { ...addressObj };
  const corporateAddressObj = content.addresses[2] || { ...addressObj };

  // Remove CA mailing address if totally empty
  if (content.addresses[2]
    && isBlankString(content.addresses[2].postalCode)
    && isBlankString(content.addresses[2].streetOne)
    && isBlankString(content.addresses[2].streetTwo)
    && isBlankString(content.addresses[2].streetThree)
    && isBlankString(content.addresses[2].streetFour)) {
    content.addresses.pop();
  } else {
    // todo: not sure of it's use-case
   // content.addresses[2].state = this.corporateAddressState;
  }

  const mailingAddress = {
    'mailingAddressLine1': mailingAddressObj.streetOne,
    'mailingAddressLine2': mailingAddressObj.streetTwo,
    'mailingAddressLine3': mailingAddressObj.streetThree,
    'mailingAddressLine4': mailingAddressObj.streetFour,
    'mailingAddressState': mailingAddressObj.state,
    'mailingAddressCountry': mailingAddressObj.country,
    'mailingPostalCode': mailingAddressObj.postalCode
  };

  const residentialAddress = {
    'residentalAddressLine1': residentialAddressObj.streetOne,
    'residentalAddressLine2': residentialAddressObj.streetTwo,
    'residentalAddressLine3': residentialAddressObj.streetThree,
    'residentalAddressLine4': residentialAddressObj.streetFour,
    'residentalState': residentialAddressObj.state,
    'residentalCountry': residentialAddressObj.country,
    'residentalPostalCode': residentialAddressObj.postalCode
  };

  const registeredAddress = {
    'registeredAddressLine1': residentialAddressObj.streetOne,
    'registeredAddressLine2': residentialAddressObj.streetTwo,
    'registeredAddressLine3': residentialAddressObj.streetThree,
    'registeredAddressLine4': residentialAddressObj.streetFour,
    'registeredState': residentialAddressObj.state,
    'registeredCountry': residentialAddressObj.country,
    'registeredPostalCode': residentialAddressObj.postalCode
  };

  const isMailingAddressSameAsCorporate = mailingAddressObj.country === 'SG';

  const corporateAddress = {
    'caAddressLine1': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.streetOne,
    'caAddressLine2': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.streetTwo,
    'caAddressLine3': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.streetThree,
    'caAddressLine4': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.streetFour,
    'caState': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.state || '',
    'caCountry': isMailingAddressSameAsCorporate ? '' : (corporateAddressObj.streetOne || corporateAddressObj.streetTwo || corporateAddressObj.streetThree || corporateAddressObj.streetFour || corporateAddressObj.state || corporateAddressObj.postalCode) ? corporateAddressObj.country : '',
    'caPostalCode': isMailingAddressSameAsCorporate ? '' : (corporateAddressObj.postalCode || '')
  };

  const officeContact = {
    'officeEmail': '',
    'officeContactIddCode': officeContactNumberObj.code,
    'officeContactCountry': officeContactNumberObj.country || _getCountryFromIDD(officeContactNumberObj.code) || '',
    'officeContactNum': officeContactNumberObj.number
  };

  const homeContact = {
    'homeContactIddCode': homeContactNumberObj.code,
    'homeContactCountry': homeContactNumberObj.country || _getCountryFromIDD(homeContactNumberObj.code) || '',
    'homeContactNum': homeContactNumberObj.number
  };

  const personalContact = {
    'personalEmail': content.email,
    'mobileContactIddCode': mobileContactNumberObj.code,
    'mobileContactNum': mobileContactNumberObj.number,
    'gender': content.gender,
    'title': content.title,
    'mobileContactCountry': mobileContactNumberObj.country || _getCountryFromIDD(mobileContactNumberObj.code) || '',
    'mobileContactNum': mobileContactNumberObj.number
  };

  let formObj = { ...officeContact, ...homeContact, ...personalContact, ...mailingAddress, ...corporateAddress };

  // Registered Address should only be sent in case of corporate user
  if (StoreRegistry.cdpSession.getData().accountType === ACCOUNT_STATES.CORPORATE) {
    formObj = { ...formObj, ...registeredAddress }
  } else {
    formObj = { ...formObj, ...residentialAddress }
  }

  let taxResidencies = (content.taxResidencies || content.taxResidencyDetails || []).filter((taxResidency) => {
    return taxResidency && taxResidency.country;
  });

  let taxResidencyDetails = {
    'taxResidencyDetails': taxResidencies
  }

  if (isMyinfo) {
    taxResidencyDetails = taxResidencyFieldset ?
      taxResidencyFieldset.getData() :
      { 'taxResidencyDetails' : initialUserData.taxResidencyDetails };
  }

  formObj = { ...formObj, ...taxResidencyDetails};

  if (!isMyinfo) {
    const fatcaCheckObj = content.fatcaCRS;
    if (fatcaCheckObj && fatcaCheckObj.isChecked) {
      formObj.fatcaCrsDueDCheck = fatcaCheckObj.value;
    } else {
      _setFatcaCheckFlag(formObj);
    }
  } else {
    _setFatcaCheckFlag(formObj);
  }

  return formObj;
}

function _getCountryFromIDD(code = '') {
  const { countryCode } = InternationalCallingCode.getCountryByDialCode(code) || {};
  return countryCode || '';
}

export {formatDataForUserStore, createFormData};
