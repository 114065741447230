import i18n from 'sgx-localisation-service';
import { Big } from 'utils/big-number-util';
import ConfigService from 'sgx-config-service';

const SECURITY_DETAILS_URL = ConfigService.links.INVESTORS_SECURITY_DETAILS;
const SECURITY_DETAILS_URL_WITH_TYPE = ConfigService.links.INVESTORS_SECURITY_DETAILS_WITH_TYPE;
const SECURITY_LIST_URL = ConfigService.links.INVESTORS_PORTAL_SECURITIES;
const MAS_LISTED_URL = ConfigService.links.MAS_LISTED_URL;
const SGX_FIXED_INCOME_URL = ConfigService.links.SGX_FIXED_INCOME_URL;

/**
 * Transforms product/sector data into chart data series format
 *
 * @param {Array} data product/sector mapping
 * @param {number} totalMarketValue total market value
 * @return {Array} transformed chart data series format
 */
function mapToChartSeriesData(data, totalMarketValue) {
  const names = {
    ...i18n.getTranslation('app.widget-portfolio-overview.chart.product-codes', { returnObjects: true }),
    ...i18n.getTranslation('app.widget-portfolio-overview.chart.sector-codes', { returnObjects: true }),
    others: i18n.getTranslation('app.widget-portfolio-overview.chart.others')
  };

  return Object.keys(data).reduce((acc, key) => {
    if (!+data[key].toFixed(2)) {
      return acc;
    }
    const percentage = Big(data[key]).div(totalMarketValue).times(100);
    const label = `${percentage.toFixed(1)}%`;

    acc.push({
      name: names[key],
      description: `${Big(data[key]).toFormat(3)} - ${label}`,
      label,
      y: parseFloat(percentage)
    });
    return acc;
  }, [])
    .sort((a, b) => b.y - a.y);
}

function getSecurityLink(stockCode, type, bondType='') {
  if (bondType === 'MAS') {
    return MAS_LISTED_URL;
  } else if (bondType === 'SGX') {
    return SGX_FIXED_INCOME_URL;
  }

  let url = `${SECURITY_LIST_URL}?security=NA`;

  if (!!stockCode && stockCode !== 'N/A') {
    url = type ? `${SECURITY_DETAILS_URL_WITH_TYPE}/${type}/${stockCode}`: `${SECURITY_DETAILS_URL}/${stockCode}`;
  }
  return url;
}

export {
  mapToChartSeriesData,
  getSecurityLink
}
