module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-dcs-update-dialog\">\n  <sgx-dialog class=\"widget-dcs-update-dialog\" role=\"dialog\">\n    <h3 data-dialog=\"title\" data-i18n=\"widget-dcs-update-dialog.title\" class=\"widget-dcs-update-dialog--title\"></h3>\n    <div class=\"widget-dcs-update-dialog--body\" data-dialog=\"body\">\n      <p class=\"description\" data-i18n=\"widget-dcs-update-dialog.descriptionOne\"></p>\n      <button class=\"widget-dcs-update-dialog--proceed sgx-button--primary\" data-dialog=\"button\" data-action=\"proceed\" data-i18n=\"widget-dcs-update-dialog.buttons.proceed\"></button>\n      <button class=\"widget-dcs-update-dialog--dismiss sgx-button--text\" data-dialog=\"button\" data-action=\"dismiss\" data-i18n=\"widget-dcs-update-dialog.buttons.dismiss\"></button>\n    </div>\n  </sgx-dialog>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()