module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-rights-dialog\">\n  <sgx-dialog class=\"w-rights-dialog\" id=\"widget-rights-dialog\" role=\"dialog\">\n    <div class=\"w-rights-dialog-header\" data-dialog=\"title\">\n      <div class=\"w-rights-dialog-title sgx-base-text-h1--with-carrier\" data-i18n=\"widget-rights-dialog.title\"></div>\n      <button class=\"sgx-button--link sgx-button--small sgx-icon--before screener-dialog-icon--close\"\n        data-i18n=\"widget-rights-dialog.button.close\" data-action=\"close\"></button>\n    </div>\n    <div data-dialog=\"body\" class=\"w-rights-dialog-container\">\n      <div class=\"row mx-0\">\n        <sgx-payments class=\"col-lg-6 col-md-12\"></sgx-payments>\n        <cmp-rights-payment-summary class=\"col-lg-6 col-md-12\"></cmp-rights-payment-summary>\n      </div>\n    </div>\n  </sgx-dialog>\n</template>\n\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()