module.exports = (function() {
    var templateString = module.exports = "<template id=\"template-logout\">\n  <div class=\"logout-row\">\n    <div class=\"logout-description\">\n      <h3 class=\"logout-title sgx-base-text-title-20\"></h3>\n      <p class=\"logout-survey sgx-base-text-body-16\"></p>\n    </div>\n    <div class=\"logout-buttons\">\n      <a href=\"/dashboard\" class=\"logout-button sgx-button--primary\" data-i18n=\"app.logout.button\"></a>\n      <a href=\"/login.html\" class=\"login-button sgx-button--primary sgx-hidden\"\n          data-i18n=\"app.logout.proceed-login-button\"></a>\n    </div>\n  </div>\n  <widget-audit-log></widget-audit-log>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()