module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-otp-sms\">\n  <h5 class=\"sgx-base-text-h5\">\n    <span data-i18n=\"app.widget-otp-sms.labels.sms-sent-msg-label\"></span>\n    <span class=\"masked-phone-number\"></span>\n  </h5>\n  <div class=\"otp-desc\">\n    <span data-i18n=\"app.widget-otp-sms.labels.enter-otp-msg-label\"></span>\n    <sgx-input-text class='otp-sms'></sgx-input-text>\n    <a class=\"link resend-otp\" data-i18n=\"app.widget-otp-sms.labels.resend-otp-msg-label\"></a>\n  </div>\n\n  <p class=\"footnote\" data-i18n=\"app.widget-otp-sms.labels.footnote-msg-label\"></p>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()