import { withInitDOM } from 'sgx-base-code';
import tmpl from './template-tools-page.html';

/**
 * Tools page template, including the stock screener.
 */
class TemplateToolsPage extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-tools-page');
    this._stockScreener = this.querySelector('widget-stock-screener');
  }

  connectedCallback() {
  }

  setData() {
    this._stockScreener.setData();
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

customElements.define('template-tools-page', withInitDOM(TemplateToolsPage));
