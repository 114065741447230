import { withInitDOM, FetchUtils } from 'sgx-base-code';
import tmpl from './widget-resources.html';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';
import { get } from 'lodash';

class ResourcesWidget extends HTMLElement {
  constructor() {
    super();
    this._lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-resources');
    this._tiles = this.querySelector('.widget-resources-tiles');
    this._links = Array.from(this.querySelector('.widget-resources-links').children);
    this._indicator = this.querySelector('sgx-status-indicator');
  }

  connectedCallback() {
    this._setTiles();
    this._setLinks();
  }

  _setTiles() {
    const lang = i18n.getLanguage();
    this._indicator.show({
      status: 'loading',
      description: i18n.getTranslation('app.shared-text.status-indicator.loading.description')
    });
    return FetchUtils.fetchJSON(`${ConfigService.env.api.resources[lang]}`)
      .then(response => get(response, 'data.route.data.data.widgets') || [])
      .then(widgets => {
        const tilesFragment = widgets.reduce((fragment, widget) => {
          const { tiles, widgetType } = widget.data;
          if (widgetType === 'widget_tile_list') {
            tiles.forEach(tile => {
              const tileElement = document.createElement('cmp-tile');
              const { title: text, link, media } = tile.data;
              const { mediaType, video, image } = media.data;
              let linkHref = link && link.href.url;

              // Concat https://sgx.com on relative paths
              if (linkHref && link.href.internal) {
                linkHref = `${ConfigService.links.SGX_V2}${linkHref}`;
              }

              tileElement.setData({
                text,
                mediaType,
                link: video || linkHref,
                image: image && image.data.image.url
              });
              tileElement.className = 'cmp-tile col-xxs-12 col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-4';
              fragment.appendChild(tileElement);
            });
          }
          return fragment;
        }, document.createDocumentFragment());
        this._tiles.appendChild(tilesFragment);
        this._indicator.hide();
      })
      .catch(e => {
        console.error(e);
        this._indicator.show({
          status: 'error',
          title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
          description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
        });
      });
  }

  _setLinks() {
    const linkText = Object.values(i18n.getTranslation(`app.widget-resources.links`, { returnObjects: true }));
    this._links.forEach((link, index) => {
      const ref = link.getAttribute('ref');
      const url = ConfigService.links[ref.toUpperCase().replace(/-/g, '_')];
      link.href = url[this._lang] ? url[this._lang] : url;
      link.textContent = linkText[index];
    });
  }
}

customElements.define('widget-resources', withInitDOM(ResourcesWidget));
