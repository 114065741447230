import { BehaviorSubject } from 'rxjs';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Simple store for data storage and update broadcast.
 * Components can subscribe to this store using RxJS 'subscribe' methods.
 */
export class SimpleStore extends BehaviorSubject {
  static get STORE_ID_PREFIX() {
    return 'sgx-store-';
  }

  static get STORES() {
    if (!SimpleStore._stores) {
      SimpleStore._stores = [];
    }
    return SimpleStore._stores;
  }

  /**
   * Constructor.
   *
   * @param {string} id the store unique identifier for the whole application
   * @param  {...any} args BehaviorSubject arguments (init value)
   */
  constructor(id, ...args) {
    super(...args);
    this.id = `${SimpleStore.STORE_ID_PREFIX}${id}`;
    this.data = {};
    this._bindings = {};
  }

  /**
   * Gets the store with the queried identifier.
   *
   * @param {string} id the store identifier
   * @return {SimpleStore} the simple store unique instance for the given identifier
   */
  static get(id) {
    return SimpleStore.STORES[id] || (SimpleStore.STORES[id] = new SimpleStore(id));
  }

  /**
   * Gets a copy of the data at the queried location in the store.
   *
   * @param {string} path Optional: the path to the required data
   * @return {object} the currently stored data at the given path
   */
  getData(path) {
    return cloneDeep(path ? get(this.data, path) : this.data);
  }

  /**
   * Updates either a chunk or the whole data in the store and notifies all subscribers.
   *
   * @param {object} data the new data
   * @param {string} path Optional: the path to the data to update
   */
  setData(data, path) {
    let copiedData = cloneDeep(data);
    if (path) {
      set(this.data || {}, path, copiedData);
    } else {
      this.data = copiedData;
    }
    this.next(this.data);
    if (this._autoSave) {
      this.saveData();
    }
  }

  /**
   * Clears the store.
   *
   */
  clear() {
    this.data = {};
    this.next(this.data);
  }
}

export default SimpleStore;
