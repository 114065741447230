import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-list-standalone.html';

/**
 * Simple list rendering custom or plain row elements.
 */
class CmplListStandalone extends HTMLElement {
  constructor() {
    super();
    this._onRowClick = this._onRowClick.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-standalone');
    this._rowsContainer = this.querySelector('.cmp-list-standalone-rows-container');
  }

  connectedCallback() {
    this._toggleEventListeners(true);
  }

  disconnectedCallback() {
    this._toggleEventListeners(false);
  }

  _toggleEventListeners(enable) {
    var methodName = enable === true ? 'addEventListener' : 'removeEventListener';
    this._rowsContainer[methodName]('click', this._onRowClick, false);
  }

  _onRowClick(e) {
    const key = e.target.closest('.cmp-list-standalone-row').dataset.key;
    if (!key) {
      return;
    }
    e.stopPropagation();
    this._callback(this._data[key]);
  }

  setConfig({ rowElementName = 'div', rowElementClasses = [], rowClickedCallback = _ => void (_)} ) {
    this._rowElementName = rowElementName;
    this._rowElementClasses = rowElementClasses;
    this._callback = rowClickedCallback;
  }

  setData(data) {
    if (data && this._data) {
      this._rowsContainer.innerHTML = '';
    }

    this._data = data;

    const docfrag = document.createDocumentFragment();
    data.forEach((rowData, idx) => {
      const row = document.createElement(this._rowElementName);
      if (row.setConfig) {
        row.setConfig(this._rowConfig);
      }
      if (row.setData) {
        row.setData(rowData);
      }
      row.classList.add('cmp-list-standalone-row');
      this._rowElementClasses.forEach(className => {
        row.classList.add(className);
      });
      row.dataset.key = idx;
      docfrag.appendChild(row);
    });
    this._rowsContainer.appendChild(docfrag);
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('cmp-list-standalone', withInitDOM(CmplListStandalone));
