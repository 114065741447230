/**
 * An Enum with all the icons available for data tool
 * @enum {String}
 */
var DATA_MODEL_TOOL_ICON = {
  'SEARCH': 'search',
  'FILTER': 'filter',
  'PRINT': 'print',
  'DOWNLOAD': 'download',
  'EXCHANGE': 'exchange',
  'REFRESH': 'refresh'
};

export {
  DATA_MODEL_TOOL_ICON
};
