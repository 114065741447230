import { Utils, withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-list-row-security.html';
import i18n from 'sgx-localisation-service';

/**
 * Security summary within a list.
 */
class CmpListRowSecurity extends HTMLElement {
  constructor() {
    super();
    this._ready = Utils.deferred();

    // Bound methods
    this._iconClick = this._iconClick.bind(this);
  }

  initDOM() {
    this.classList.add('cmp-list-row-security');
    this.appendChild(tmpl.getNode());
    // References
    this._priceInfo = this.querySelector('cmp-price-info');
    this._icon = this.querySelector('cmp-star-icon');
    this._name = this.querySelector('.cmp-list-row-security-column-name');
    this._code = this.querySelector('.cmp-list-row-security-code');
    this._status = {
      swl: this.querySelector('.cmp-list-row-security-status--swl'),
      rmk: this.querySelector('.cmp-list-row-security-status--rmk'),
      info: this.querySelector('.cmp-list-row-security-status--info')
    };
    this._tooltip = {
      rmk: this._status['rmk'].querySelector('[data-sgx-tooltip-content]'),
      info: this._status['info'].querySelector('[data-sgx-tooltip-content]')
    };

    // Listeners
    this._ready.resolve();
  }

  connectedCallback() {
    this._icon.addEventListener('cmp-star-icon-click', this._iconClick);
  }

  disconnectedCallback() {
    this._icon.removeEventListener('cmp-star-icon-click', this._iconClick);
  }

  setConfig(config) {
  }

  setData(security) {
    this._security = security;
    this.whenReady()
      .then(_ => this._render());
  }

  getData() {
    return this._security;
  }

  updateData(data) {
    const values = (data && data.values) || {};
    this._security = {...this._security, ...values};
    this.setData(this._security);
  }

  setState(state) {
  }

  setSelected(selected) {
    if (selected) {
      this.setAttribute('selected', '');
    } else if (this.hasAttribute('selected')) {
      this.removeAttribute('selected');
    }
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  whenReady() {
    return this._ready.promise;
  }

  _render() {
    if (this._security.isFavorite === null || this._security.isFavorite === undefined) {
      this._icon.classList.add('sgx-hidden');
    } else {
      this._icon.classList.remove('sgx-hidden');
      this._icon.selected = this._security.isFavorite;
    }

    this._name.textContent = this._security.n;
    this._code.textContent = this._security.nc;

    if (this._security['swl']) {
      this._status['swl'].classList.remove('sgx-hidden');
    } else {
      this._status['swl'].classList.add('sgx-hidden');
    }
    if (this._security['rmk'] && this._security['rmk'].length > 0) {
      this._tooltip['rmk'].textContent = this._security['rmk'].join(';').trim();
      this._status['rmk'].classList.remove('sgx-hidden');
    } else {
      this._status['rmk'].classList.add('sgx-hidden');
    }
    if (this._security['info'] && this._security['info'].length > 0) {
      this._tooltip['info'].textContent = this._security['info'].join(' ').trim();
      this._status['info'].classList.remove('sgx-hidden');
    } else {
      this._status['info'].classList.add('sgx-hidden');
    }

    if(this._security['sip'] && this._security['sip'].toUpperCase() === 'SP'){
      this._tooltip['info'].textContent = i18n.getTranslation('app.cmp-list-row-security.spac');
      this._status['info'].classList.remove('sgx-hidden');
    }

    if(this._security['sip'] && this._security['sip'].toUpperCase() === 'TT'){
      this._tooltip['info'].textContent = i18n.getTranslation('app.cmp-list-row-security.tt');
      this._status['info'].classList.remove('sgx-hidden');
    }

    this._priceInfo.setData(this._security);
  }

  _iconClick() {
    const event = new CustomEvent('security-favorite', {
      bubbles: true,
      detail: {
        securityCode: this._security.nc,
        selected: this._icon.selected
      }
    });
    this.dispatchEvent(event);
  }
}

customElements.define('cmp-list-row-security', withInitDOM(CmpListRowSecurity));
