import { withInitDOM } from '@sgx/sgx-base-code';
import i18n from '@sgx/sgx-localisation-service';
import tmpl from './cmp-rights-payment-summary.html';

import Big from '../../utils/big-number-util';

class CmpPaymentSummary extends HTMLElement {
  constructor() {
    super();
    this._data = {};
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
  }

  connectedCallback() {}

  disconnectedCallback() {}

  /**
   * Set the Payment Summary
   * @param {Object} data { sharesApplied, issuePrice, transFee, gst }
   * @param {Number} data.sharesApplied
   * @param {Number} data.issuePrice
   * @param {Number} data.transFee
   * @param {Number} data.gst
   * @param {Number} data.paymentAmount
   * @param {String} data.paymentCurrency
   */
  setData(data) {
    this._data = data;
    this._setSummaryValues(data);
  }

  getData() {
    return this._data;
  }

  _setSummaryValues(data) {
    const { sharesApplied, issuePrice, transFee, gst, totalAmount: paymentAmount = 'NA', paymentCurrency = '', exchangeRate } = data;

    const valueIndexes = ['A', 'B', 'C', 'D', 'E', 'F'];
    let currentValueIndex = 0;

    this.querySelector('#cmp-payment-summary-no-of-shares-index').innerText = `${valueIndexes[currentValueIndex++]}. `;
    this.querySelector('#cmp-payment-summary-no-of-shares-value').innerText = sharesApplied !== 'NA' ? Big(sharesApplied).toFormat() : sharesApplied;

    this.querySelector('#cmp-payment-summary-issue-price-index').innerText = `${valueIndexes[currentValueIndex++]}. `;
    this.querySelector('#cmp-payment-summary-issue-price-currency').innerText = paymentCurrency;
    this.querySelector('#cmp-payment-summary-issue-price-value').innerText = issuePrice;

    if (exchangeRate) {
      this.querySelector('#cmp-payment-summary-exchange-rate-index').innerText = `${valueIndexes[currentValueIndex++]}. `;
      this.querySelector('#cmp-payment-summary-exchange-rate-currency').innerText = paymentCurrency;
      this.querySelector('#cmp-payment-summary-exchange-rate-value').innerText = this._getAmountWith2Decimals(exchangeRate);
    }
    this.querySelector('#cmp-payment-summary-exchange-rate-container').classList.toggle('sgx-hidden', !exchangeRate);

    const section2StartIndex = currentValueIndex;

    this.querySelector('#cmp-payment-summary-shares-in-pay-currency-index').innerText = `${valueIndexes[currentValueIndex++]}. `;
    this.querySelector('#cmp-payment-summary-shares-in-pay-currency-note').innerText = `(${valueIndexes.slice(0, section2StartIndex).join(' * ')})`;
    this.querySelector('#cmp-payment-summary-shares-in-pay-currency-currency').innerText = paymentCurrency;
    this.querySelector('#cmp-payment-summary-shares-in-pay-currency-value').innerText = this._getRightSharesInPayCurrency(paymentAmount, gst, transFee);

    if (transFee) {
      this.querySelector('#cmp-payment-summary-transaction-fee-index').innerText = `${valueIndexes[currentValueIndex++]}. `;
      this.querySelector('#cmp-payment-summary-transaction-fee-currency').innerText = paymentCurrency;
      this.querySelector('#cmp-payment-summary-transaction-fee-value').innerText = this._getAmountWith2Decimals(transFee);
    }
    this.querySelector('#cmp-payment-summary-transaction-fee-container').classList.toggle('sgx-hidden', !transFee);

    if (gst) {
      this.querySelector('#cmp-payment-summary-gst-index').innerText = `${valueIndexes[currentValueIndex++]}. `;
      this.querySelector('#cmp-payment-summary-gst-currency').innerText = paymentCurrency;
      this.querySelector('#cmp-payment-summary-gst-value').innerText = this._getAmountWith2Decimals(gst);
    }
    this.querySelector('#cmp-payment-summary-gst-container').classList.toggle('sgx-hidden', !gst);

    this.querySelector("#cmp-payment-summary-total-note").innerText = `(${valueIndexes.slice(section2StartIndex, currentValueIndex).join(' + ')})`;
    this.querySelector("#cmp-payment-summary-total-currency").innerText = paymentCurrency;
    this.querySelector("#cmp-payment-summary-total-value").innerText = this._getAmountWith2Decimals(paymentAmount);
  }

  _getRightSharesInPayCurrency(totalAmount, gst, transFee) {
    if (isNaN(Number(totalAmount))) {
      return 'NA';
    }
    let amount = totalAmount;
    if (!isNaN(Number(gst))) {
      amount -= gst;
    }
    if (!isNaN(Number(transFee))) {
      amount -= transFee;
    }
    return this._getAmountWith2Decimals(amount);
  }

  _getAmountWith2Decimals(val) {
    if (isNaN(Number(val)) || val === null || val === undefined) {
      return 'NA';
    } else {
      return Big(val).toFormat(2);
    }
  }
}

customElements.define('cmp-rights-payment-summary', withInitDOM(CmpPaymentSummary));
