import SgxDataModelToolBaseProto from '../../sgx-data-model-tool-base';
import { withInitDOM } from 'sgx-base-code';

// #region - Private Helpers

/**
 * Converts value to a sort object format that is accepted by the data model
 *
 * @param {string} value - string value that follows this format <COLUMN_NAME>||<ORDER_BY>||<COLUMN_TYPE>) e.g('ageColumn||asc||number')
 * @return {object} formated sort object
 */
const getSortDetailValue = value => {
  const [sortKey, sortDirection, sortType] = value.split('||');
  return {
    [sortKey]: {
      type: sortType,
      dir: sortDirection
    }
  };
};

// #endregion

/**
 * Sort tool.
 */
class SgxDataModelToolSort extends SgxDataModelToolBaseProto {
  // #region - Custom Element APIs

  constructor() {
    super();
    // Bound Methods
    this._onInputSelectChangeCallback = this._onInputSelectChangeCallback.bind(this);

  }

  initDOM() {
    this.appendChild(document.createElement('sgx-input-select'));
    this.classList.add('sgx-data-model-tool-sort');
    this._select = this.querySelector('sgx-input-select');
    const opts = [].slice.call(this.querySelectorAll('sgx-data-model-tool-sort > optgroup, sgx-data-model-tool-sort > option') || []);
    if (opts.length) {
      this._select.setOptions(opts, true);
    }
  }

  connectedCallback() {

    this._select.addEventListener('change', this._onInputSelectChangeCallback);
  }

  disconnectedCallback() {

    this._select.removeEventListener('change', this._onInputSelectChangeCallback);
  }

  // #endregion

  // #region - Private methods

  _onInputSelectChangeCallback(evt) {
    const model = this.getDataModel();
    if (!model) {
      return;
    }

    model.getState().then(state => model.setState({
      sorts: getSortDetailValue(evt.target.value),
      filters: state.filters
    }));
  }

  // #endregion

  // #region - Public APIs

  setOptions(options, selectFirst, silent) {
    this._select.setOptions(options, selectFirst, silent);
  }
}

// #endregion

customElements.define('sgx-data-model-tool-sort', withInitDOM(SgxDataModelToolSort));
