import { withInitDOM, FetchUtils } from 'sgx-base-code';
import tmpl from './widget-dashboard-quick-links.html';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';
import { get } from 'lodash';
import { constants } from './config';
import StatusIndicatorUtil from 'utils/status-indicator-util';
import SGXInViewport from 'sgx-in-viewport';
import { fromEvent } from 'rxjs';
import SgxAnalyticsService from 'sgx-analytics-service';

class QuickLinkWidget extends SGXInViewport {
  constructor() {
    super();
    this._lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');
  }

  _sendGAEvent(event) {
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    SgxAnalyticsService.sendEvent(
      eventCategory,
      `${this._title}`,
      'widget-viewed'
    );
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-dashboard-quick-links');
    this._container = this.querySelector('#widget-dashboard-quick-links--container');
    this._indicator = this.querySelector('sgx-status-indicator');
    super.initDOM();
  }

  async connectedCallback() {
    const data = await this._getData();
    this.setData(data);
    fromEvent(this, 'onViewPortEnter').subscribe(event => this._sendGAEvent(event));
  }

  /**
   * Sets value to the html template
   * @param {array} data
   * @param {string} data.title
   * @param {object} data.image
   * @param {string} data.image.url
   * @param {object} data.links
   * @param {string} data.links.text
   * @param {string} data.links.url
   * @param {string} data.type
   *
   */
  setData(data) {
    if (!data) return;
    this._data = data;

    // check login type and add class for fixed minimum height
    const loginTypeClass = this._loginType === 'preLogin' ? 'pre-login' : 'post-login';
    this._container.classList.add(`widget-dashboard-quick-links--${loginTypeClass}-container`);

    for (const OBJ of this._data) {
      const childComponent = document.createElement('cmp-list-row-quick-links');
      this._container.appendChild(childComponent);
      childComponent.setData(OBJ);
    }
  }

  async _getData() {
    this._dataStoreValue = this.getAttribute('data-store');

    this._title = constants[this._dataStoreValue].TITLE;
    this._bannerImage = constants[this._dataStoreValue].BANNER_IMAGE;
    this._loginType = this.getAttribute('type');

    const lang = i18n.getLanguage();

    StatusIndicatorUtil.displayLoader(this._indicator);

    // check which widget to load
    if (this._dataStoreValue === 'accountOpening' || this._dataStoreValue === 'resources') {
      return this._getDataFromConfig();
    }

    return FetchUtils.fetchJSON(`${ConfigService.env.api.resources[lang]}`)
      .then((response) => get(response, 'data.route.data.data.widgets') || [])
      .then((widgets) => {
        const componentData = [];
        for (const widget of widgets) {
          const { tiles, widgetType } = widget.data;
          if (widgetType === 'widget_tile_list') {
            const linkArr = [];
            tiles.forEach((tile) => {
              const { title: text, link } = tile.data;
              let linkHref = link && link.href.url;

              // Concat https://sgx.com on relative paths
              if (linkHref && link.href.internal) {
                linkHref = `${ConfigService.links.SGX_V2}${linkHref}`;
              }

              linkArr.push({
                text: text,
                url: linkHref,
              });
            }); // end of tiles loop

            componentData.push({
              title: this._title,
              image: this._bannerImage,
              links: linkArr,
              type: this._loginType,
            });
          } // end of if
        } // end of for loop

        StatusIndicatorUtil.hideStatusIndicator(this._indicator);

        if (componentData.length > 1) {
          const linksArray = [];
          for (const OBJ of componentData) {
            linksArray.push(...OBJ.links);
          }
          return [
            {
              title: this._title,
              image: this._bannerImage,
              links: linksArray,
              type: this._loginType,
            },
          ];
        }
        return componentData;
      })
      .catch((e) => {
        console.error(e);
        StatusIndicatorUtil.displayNoData(this._indicator);
      });
  }

  _getDataFromConfig() {
    StatusIndicatorUtil.hideStatusIndicator(this._indicator);
    return [
      {
        title: this._title,
        image: this._bannerImage,
        links: constants[this._dataStoreValue].LINKS,
        type: this._loginType,
      },
    ];
  }
}

customElements.define('widget-dashboard-quick-links', withInitDOM(QuickLinkWidget));
