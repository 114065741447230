import i18n from 'sgx-localisation-service';

export default {
  config: {
    type: 'content',
    editable: false
  },
  state: {
    activeStateId: 0,
    states: [
      {
        label: i18n.getTranslation('otp-prompt.sms.label'),
        type: 'sms'
      },
      {
        label: i18n.getTranslation('otp-prompt.token.label'),
        type: 'token'
      }
    ]
  }
};
