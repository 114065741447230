import { withInitDOM, Utils } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import SecuritiesService from 'sgx-securities-service';
import DeviceService from 'sgx-device-service';
import DateService from '@sgx/sgx-date-time-service';
import exportData from 'sgx-table/src/exportData';
import { SgxContextMenuService } from 'sgx-context-menu';
import UserPreferencesService from 'services/user-preferences-service';
import PrintService from 'services/print-service';
import StoreRegistry from 'stores/store-registry';
import tmpl from './template-securities-page.html';
import { FILTER_CONFIG, FILTER_STATE, SORT_VALUES, FAVORITES_TAB, FAVORITES_FILTER } from './template-securities-page-constants';
import { isAuthenticated } from 'utils/auth-util';
import { download } from 'utils/pdf-util';
import { formatThousands } from 'utils/price-util';

const EMPTY_FAVORITES = {
  status: 'neutral',
  title: i18n.getTranslation('app.securities.list-details.indicator.favorites.empty.title'),
  description: i18n.getTranslation('app.securities.list-details.indicator.favorites.empty.description')
};

/**
 * Securities page template.
 */
class TemplateSecuritiesPage extends HTMLElement {
  // #endregion

  constructor() {
    super();
    // References
    this._router = document.getElementById('sgx-app-router');
    this._filterButton = null;

    // Callbacks bindings
    this._onListRowClick = this._onListRowClick.bind(this);
    this._onRefreshButtonClick = this._onRefreshButtonClick.bind(this);
    this._onMoreActionsButtonClick = this._onMoreActionsButtonClick.bind(this);
    this._resolveSelectedSecurity = this._resolveSelectedSecurity.bind(this);
    this._onListDataModelUpdate = this._onListDataModelUpdate.bind(this);
    this._onFavoriteClick = Utils.debounce(this._onFavoriteClick.bind(this), 300, this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-securities-page');
    this._listDetails = this.querySelector('.sgx-list-details');
    this._loginPrompt = this.querySelector('cmp-login-prompt');
    this._loginPromptText = this.querySelector('.cmp-login-prompt-text');
    this._loginPromptText.textContent = i18n.getTranslation('app.securities.generic-login-prompt');
  }

  connectedCallback() {
    // List details configuration
    const initState = {
      sorts: getSortDetailValue(
        getToolbarInputSelectValues()['top-value']
      )
    };

    this._listDetails.setConfig({
      'toolbar': {
        'rows': [
          {
            'layout': 'default',
            'tools': [
              {
                'element': 'sgx-data-model-tool-sort',
                'position': 'left',
                'onAttach': (elem) => {
                  this._setToolbarInputSelect(elem);
                }
              },
              {
                'element': 'div',
                'onAttach': elem => {
                  this._createToolbarActions(elem)
                    .then(({ filter, refresh, moreActions }) => {
                      this._filterButton = filter;
                      this._refreshButton = refresh;
                      this._moreActions = moreActions;

                      this._refreshButton.setAttribute('icon', 'refresh');
                      this._refreshButton.addEventListener('click', this._onRefreshButtonClick);
                      this._moreActions.addEventListener('click', this._onMoreActionsButtonClick);

                      this._filterButton.setAttribute('label-hidden', 'true');
                      this._filterButton.setConfig({
                        filter: FILTER_CONFIG,
                        model: this._listDataModel
                      });
                    });
                },
                'onDettach': _ => {
                  this._refreshButton.removeEventListener('click', this._onRefreshButtonClick);
                  this._moreActions.removeEventListener('click', this._onMoreActionsButtonClick);
                  this._filterButton = null;
                  this._refreshButton = null;
                  this._moreActions = null;
                }
              }
            ]
          },
          {
            'layout': 'custom',
            'customToolbarLayout': new Promise((resolve, reject) => {
              const toolbarFragment = document.createDocumentFragment();

              const customToolbar = document.createElement('div');
              customToolbar.classList.add('sgx-list-details-list-toolbar-custom');
              customToolbar.innerHTML = `
                <div class="sgx-data-model-tool-display-container">
                  <sgx-data-model-tool-display-count></sgx-data-model-tool-display-count>
                  <sgx-data-model-tool-display-latest-update></sgx-data-model-tool-display-latest-update>
                </div>
              `;

              toolbarFragment.appendChild(customToolbar);

              if (toolbarFragment.hasChildNodes) {
                resolve(toolbarFragment);
              } else {
                reject(Error('Could not create custom toolbar'));
              }
            }),
            'onAttach': () => {
              const displayCountTool = this.querySelector('sgx-data-model-tool-display-count');
              const displayLatestUpdateTool = this.querySelector('sgx-data-model-tool-display-latest-update');

              const customTools = [displayCountTool, displayLatestUpdateTool];
              customTools.forEach(tool => {
                tool.setConfig({ model: this._listDataModel });
              });
            }
          }
        ]
      },
      'list': {
        'view': {
          'type': 'infinite',
          'rowHeight': 52,
          'rowElementName': 'cmp-list-row-security',
          'selectionMode': 'single',
          'emptyMsg': i18n.getTranslation('app.shared-text.status-indicator.empty.description'),
          'errorMsg': i18n.getTranslation('app.shared-text.status-indicator.error.description')
        },
        initState
      },
      'details': {
        'element': 'widget-security-details',
        'resolveSelectedData': this._resolveSelectedSecurity,
        'resolveSelectedLoadingTitle': () => i18n.getTranslation('app.securities.loading-security'),
        'resolveSelectedTitleForMobile': resolvedSelectedData => resolvedSelectedData ? `${resolvedSelectedData.n} (${resolvedSelectedData.nc})` : ''
      }
    });

    // Listeners
    this._listDetails.addEventListener('list-rowclick', this._onListRowClick);
    this._listDetails.addEventListener('security-favorite', this._onFavoriteClick);
    this._listDataModel.addEventListener('update', this._onListDataModelUpdate);

    this._displayContent(StoreRegistry.pageHeaderNavigation.getData('section'));
    this._attached = true;
  }

  disconnectedCallback() {
    SecuritiesService.unsubscribeFromPrices(this.priceSubscriptionId);
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this._listDetails.removeEventListener('list-rowclick', this._onListRowClick);
    this._listDetails.removeEventListener('security-favorite', this._onFavoriteClick);
    this._listDataModel.removeEventListener('update', this._onListDataModelUpdate);
    this._router = null;
    this._attached = null;
  }

  setData(data, params) {
    // IE and FF fix to make sure that DataModel is already configured before setting the filter state
    if (!this._attached) {
      setTimeout(() => {
        this.setData(data, params);
      }, 0);
      return;
    }
    this._routesBound = !!data.pageData.routesBound;
    this._requestedSecurity = params.security;
    this._storeId = data.pageData.storeId;
    this._subscribeToSecuritiesPrices();
  }

  get _exportName() {
    return `Favourites ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`;
  }


  get _listDataModel() {
    return this._listDetails.getList().getDataModel();
  }

  _createToolbarActions(elem) {
    return new Promise(resolve => {
      const refresh = document.createElement('sgx-data-model-tool');
      const filter = document.createElement('sgx-data-model-tool-filter');
      const moreActions = document.createElement('button');

      moreActions.className = 'sgx-button--low-emp sgx-context-menu-button sgx-icon sgx-hidden securities-favorite--action';

      elem.appendChild(filter);
      elem.appendChild(refresh);
      elem.appendChild(moreActions);
      resolve({ filter, refresh, moreActions });
    });
  }

  /**
   * Resolves the selected security to inject to the details panel based on the selected row data.
   * @param {Object} selectedSecurity the selected security data
   * @return {Promise} the data retrieval completion promise
   */
  _resolveSelectedSecurity(selectedSecurity) {
    // Update route
    if (this._routesBound && !this._requestedSecurity) {
      this._router.navigateWithoutTransition({
        security: selectedSecurity.id
      });
    }
    if (!!this._requestedSecurity) {
      this._selectedListDetailRowId = this._requestedSecurity;
      this._requestedSecurity = null;
    }

    return new Promise((resolve, reject) => {
      resolve(selectedSecurity);
    });
  }

  /**
   * Subscribe to the store handling which Securities section page to show (which translates into applying a filter).
   */
  _initStore() {
    this._store = StoreRegistry.pageHeaderNavigation;
    this._subscription = this._store.subscribe(data => {
      if (data && data.section === 'structuredcertificates') {
        this._section = 'listedcertificates';
      }
      else {
        this._section = (data && data.section) || '';
      }

      if (this._section === 'all') {
        this._section = '';
      }
      this._setFilters(this._section);
      this._shouldShowMoreActions(this._section);
    });
  }

  /**
   * Subscribe to the SecuritiesService Prices. It's initially triggered when setData method is executed
   * but can be manually triggered by the user when they click on the refresh button
   *
   * @param {boolean} [showDetails=true] show/slides-in the details
   */
  _subscribeToSecuritiesPrices(showDetails = true, isRefresh = false) {
    this.firstDataLoad = true;
    // Make sure to unsubscribe the current price subscription as this method is triggered every time the user clicks refresh button
    if (this.priceSubscriptionId) {
      SecuritiesService.unsubscribeFromPrices(this.priceSubscriptionId);
    }

    this.priceSubscriptionId = SecuritiesService.subscribeToPrices(securities => {
      if (!this._subscription) {
        // For the very first arrival on the page
        this._initStore();
      }

      if (this.firstDataLoad) {
        toSecuritiesPricesSetDataFormat(securities, isAuthenticated()).then(data => {
          this._listDetails.setData(data);
          if (this._requestedSecurity) {
            this._listDataModel.getRowData(this._requestedSecurity).then(rowData => {
              if (this._attached) {
                this._listDetails.setDetails(rowData, !isRefresh, showDetails)
              }
            });
          } else if (!this._requestedSecurity && DeviceService.isDesktop()) {
            this._listDataModel.getRowDataByIndex(0).then(data => {
              if (data && data.id) {
                this._listDataModel.getRowData(data.id).then(rowData => {
                  if (this._attached) {
                    this._listDetails.setDetails(rowData, !isRefresh, showDetails);
                  }
                });
              }
            });
          }
        });
        this.firstDataLoad = false;
      } else {
        this._listDetails.updateData(toSecuritiesPricesUpdateDataFormat(securities));
        if (this._selectedListDetailRowId) {
          // Do not show/slide-in details everytime we get update
          this._listDataModel.getRowData(this._selectedListDetailRowId).then(rowData => this._listDetails.setDetails(rowData, false, false));
        }
      }
    }, { exclude: 'bonds', params: 'nc,lt,o,h,l,vl,v,b,s,bv,sv,c,p,ed,cx,sip,issuer-name' });
  }

  /**
   * Checks whether the list should be displayed or the login prompt
   *
   * @param {String} productCode
   * @return {Boolean} returns true if it should display list details otherwise false
   */
  _shouldDisplayList(productCode) {
    return !((isFavoritesTab(productCode) && isAuthenticated()) || !isFavoritesTab(productCode));
  }

  /**
   * Combines the product code and toolbars filter before calling the listDetails setFilter method
   */
  _setFilters(productCode) {
    this._displayContent(productCode);
    if (!isAuthenticated() && isFavoritesTab(productCode)) {
      return;
    }

    this._listDataModel.getState().then(state => {
      if (this.firstDataLoad) {
        this._listDetails.getList().getView().showLoadingStatusIndicator();
      }
      const privateFilter = toFilterStateFormat(productCode);
      let filters = {};
      filters.operator = FILTER_STATE.operator;
      if (!state.filters.conditions.length) {
        filters.conditions = FILTER_STATE.conditions.concat(privateFilter);
      } else {
        filters.conditions = state.filters.conditions
          .filter(condition => condition.columnId !== 'type' && condition.columnId !== 'isFavorite')
          .concat(privateFilter);
      }
      this._listDetails.setFilters(filters);
    });
  }

  _setToolbarInputSelect(elem) {
    const values = getToolbarInputSelectValues();
    const options = getLabelValuePair(values, 'app.securities.list-details.toolbar.select-options');
    elem.setOptions(options, true);
  }

  /**
   * Fills the _selectedListDetailRowId to be able to set the current selected row's detail
   * whenever we receive updates from the price subscription
   *
   * @param {event} evt list-rowclick event
   */
  _onListRowClick(evt) {
    this._selectedListDetailRowId = evt.detail.key;
  }

  /**
   * Triggered everytime user clicks on the refresh button
   *
   * @param {event} evt refresh-click event
   */
  _onRefreshButtonClick(evt) {
    // Show latest update text as "Refreshing" text while refreshing
    this._latestUpdate = this.querySelector('.sgx-data-model-tool-display-latest-update__text');
    if (this._latestUpdate) {
      this._latestUpdate.textContent = i18n.getTranslation('app.shared-text.list-details.header.refresh-message');
    }
    this._listDetails.getList().getView().showLoadingStatusIndicator();
    // Set the _requestedSecurity with the current selected security to make sure that the details will be updated as well every time user clicks on refresh
    this._requestedSecurity = this._selectedListDetailRowId;
    this._subscribeToSecuritiesPrices(false, true);
  }

  _onMoreActionsButtonClick(evt) {
    SgxContextMenuService.show([
      {
        label: i18n.getTranslation('app.shared-text.download'),
        id: 'download'
      },
      {
        label: i18n.getTranslation('app.shared-text.print'),
        id: 'print'
      }
    ], evt.target).then(action => {
      if (action) {
        const fnName = `_on${action.replace(/^\w/, c => c.toUpperCase())}Favorites`;
        this[fnName]();
      }
    });
  }

  _onListDataModelUpdate(_) {
    setTimeout(() => this._updateStatusIndicator(), 0); // to display the correct empty state message
    this._moreActions.disabled = this._listDataModel.getFilteredRowCount() === 0;
  }

  /**
   * Shows/hides the more actions button based on user's authentication and current tab
   * @param {string} productCode selected tab
   */
  _shouldShowMoreActions(productCode) {
    const fnName = isAuthenticated() && isFavoritesTab(productCode) ? 'remove' : 'add';
    this._moreActions.classList[fnName]('sgx-hidden');
  }

  _updateStatusIndicator() {
    const list = this._listDetails.getList();
    if (isFavoritesTab(this._section) && list.getDataModel().getFilteredRowCount() === 0) {
      list.getView().getStatusIndicator().show(EMPTY_FAVORITES);
    } else if(list.getDataModel().getFilteredRowCount() === 0) {
      list.getView().updateStatusIndicator();
    }
  }

  /**
   * Updates security favorite state
   *
   * @param {String} securityCode security
   * @param {Boolean} isFavorite add/delete security code from user's preference
   */
  _updateSecurityFavorite(securityCode, isFavorite) {
    this._listDetails.updateData({
      type: 'update',
      id: securityCode,
      values: { isFavorite }
    });

    if (securityCode === this._selectedListDetailRowId) {
      this._listDetails.querySelector('widget-security-details cmp-star-icon').selected = isFavorite;
    }
  }

  /**
   * Triggered everytime user clicks on the star icon on the list/detail
   *
   * @param {event} evt security-favorite event
   * @param {Object} evt.detail contains the details of the target security
   * @param {String} evt.detail.securityCode security code
   * @param {Boolean} evt.detail.selected
   */
  _onFavoriteClick(evt) {
    const { securityCode, selected } = evt.detail;
    this._updateSecurityFavorite(securityCode, selected);

    this._getFavoritesFromList()
      .then(({ keys }) => UserPreferencesService.updateFavoriteSecurities(keys))
      .then(() => this._updateStatusIndicator())
      .catch(_ => {
        // revert the changes if it throws an error
        const errorState = {
          status: 'error',
          dialogTitle: i18n.getTranslation('app.securities.favorite-securities.error-state.dialog-title'),
          title: i18n.getTranslation('app.securities.favorite-securities.error-state.title'),
          description: i18n.getTranslation('app.securities.favorite-securities.error-state.description')
        };
        const errorHandlerDialog = document.querySelector('cmp-error-handling');
        errorHandlerDialog.setData(errorState);
        errorHandlerDialog.show();
        this._updateSecurityFavorite(securityCode, !selected);
      });
  }

  _onPrintFavorites() {
    this._getFavoritesFromList()
      .then(({ values }) => {
        const userName = StoreRegistry.user.getData('name');
        const fields = Object.keys(values).map((key, index) => {
          const { n: securityName, nc: securityCode, r, lt: marketPriceStr,
            c: priceChangeDollarStr, p: priceChangePercentageStr, h: dayHighStr,
            l: dayLowStr, vl: volumeStr, swl, i } = values[key];
          const order = (index + 1).toString();
          let remark = r;

          if (!remark) {
            remark = i;
          } else {
            if (i) {
              remark = `${remark},${i}`;
            }
          }

          return {
            order,
            securityName: `${securityName}${swl ? '^' : ''}`,
            securityCode,
            remark,
            marketPriceStr: formatThousands(marketPriceStr, 3, true),
            priceChangeDollarStr: formatThousands(priceChangeDollarStr, 3, true),
            priceChangePercentageStr: formatThousands(priceChangePercentageStr, 3, true),
            dayHighStr: formatThousands(dayHighStr, 3, true),
            dayLowStr: formatThousands(dayLowStr, 3, true),
            volumeStr: formatThousands(volumeStr, 3, true)
          };
        });
        const body = {
          qualifiedName: 'watchlist',
          params: { userName },
          fields
        };

        PrintService.getPdf(body)
          .then(base64 => {
            download(base64, this._exportName);
          });
      });
  }

  _onDownloadFavorites() {
    this._getFavoritesFromList().then(({ values }) => {
      const sortedData = Object.keys(values).reduce((sortedData, key, index) => {
        const cv = values[key];
        const securityCode = cv.nc;
        const securityName = `${cv.n}${cv.swl ? '^' : ''}`;
        const marketPriceStr = cv.lt;
        const priceChangeDollarStr = cv.c;
        const priceChangePercentageStr = cv.p;
        const dayHighStr = cv.h;
        const dayLowStr = cv.l;
        const volumeStr = cv.vl;
        const order = index + 1;
        let remark = cv.r;

        if (!remark) {
          remark = cv.i;
        } else {
          if (cv.i) {
            remark = `${remark},${cv.i}`;
          }
        }
        sortedData.keys.push(securityCode);
        sortedData.values[securityCode] = {
          order, securityCode, securityName, remark, marketPriceStr, priceChangeDollarStr,
          priceChangePercentageStr, dayHighStr, dayLowStr, volumeStr
        };
        return sortedData;
      }, { keys: [], values: {} });
      const config = {
        columns: {
          order: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.order') },
          securityCode: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.security-code') },
          securityName: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.security-name') },
          remark: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.remarks') },
          marketPriceStr: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.market-price'), export: val => formatThousands(val, 3, true) },
          priceChangeDollarStr: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.change'), export: val => formatThousands(val, 3, true) },
          priceChangePercentageStr: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.change-percentage'), export: val => formatThousands(val, 3, true) },
          dayHighStr: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.high'), export: val => formatThousands(val, 3, true) },
          dayLowStr: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.low'), export: val => formatThousands(val, 3, true) },
          volumeStr: { filter: false, sort: false, cell: false, label: i18n.getTranslation('app.securities.csv-headers.volume'), export: val => formatThousands(val, 3, true) },
        },
        options: {
          exportDataFileName: this._exportName
        }
      };
      const state = {
        order: [
          'order', 'securityName', 'securityCode', 'remark',
          'marketPriceStr', 'priceChangeDollarStr', 'priceChangePercentageStr',
          'dayHighStr', 'dayLowStr', 'volumeStr'
        ],
        columns: {
          order: { show: true },
          securityCode: { show: true },
          securityName: { show: true },
          remark: { show: true },
          marketPriceStr: { show: true },
          priceChangeDollarStr: { show: true },
          priceChangePercentageStr: { show: true },
          dayHighStr: { show: true },
          dayLowStr: { show: true },
          volumeStr: { show: true }
        }
      };
      exportData(sortedData, config, state);
    });
  }

  _getFavoritesFromList() {
    return this._listDataModel.getDataForState({ filters: FAVORITES_FILTER });
  }

  _displayContent(productCode) {
    if (!this._shouldDisplayList(productCode)) {
      this._listDetails.style.display = 'flex';
      this._loginPrompt.hide();
    } else {
      this._listDetails.style.display = 'none';
      this._loginPrompt.show();
    }
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'flex';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

// #region Private Helpers API
const getToolbarInputSelectValues = () => {
  return SORT_VALUES;
};

const getLabelValuePair = (obj, translationKeyPath) => {
  return Object.keys(obj).map(key => ({
    value: obj[key],
    label: i18n.getTranslation(`${translationKeyPath}.${key}`, '')
  }));
};

const getSortDetailValue = sortFormat => {
  const [sortKey, sortDirection, sortType] = sortFormat.split('||');
  return {
    [sortKey]: {
      type: sortType,
      dir: sortDirection
    }
  };
};

const isFavoritesTab = productCode => productCode === FAVORITES_TAB;

/**
 * Transforms a product code to filter format
 * @param {string} productCode the current selected product code
 *
 * @return {Object} static filter
 */
const toFilterStateFormat = productCode => {
  if (!isFavoritesTab(productCode)) {
    const productFilter = productCode ?
      { 'columnId': 'type', 'method': 'eq', 'value': productCode, 'type': 'text' } :
      { 'columnId': 'type' };
    return [productFilter];
  }
  return [{ 'columnId': 'isFavorite', 'method': 'eq', 'value': 'true', 'type': 'text' }];
};

/**
 * Transforms the security prices data from the subscription into an array of security prices
 * @param {object} securities the security prices received from the SecuritiesService.subscribeToPrices
 * @param {object} isAuthenticated whether is authenticated or not
 *
 * @return {Promise<Array>} array of security prices
 */
const toSecuritiesPricesSetDataFormat = (securities, isAuthenticated) => {
  const getSecurities = (favorites = {}) => {
    return Object.keys(securities).map(security => {
      const code = securities[security].nc;
      return {
        ...securities[security],
        id: security,
        isFavorite: isAuthenticated === true ? !!favorites[code] : null,
        order: favorites[code] || ''
      }
    });
  };

  if (isAuthenticated === true) {
    return UserPreferencesService.getFavoriteSecurities().then(data => {
      const favorites = data.reduce((accumulator, { securityCode, securityOrder }) => {
        accumulator[securityCode] = securityOrder;
        return accumulator;
      }, {});

      return Promise.resolve(getSecurities(favorites));
    });
  }

  return Promise.resolve(getSecurities(false));
};

/**
 * Similar to toSecuritiesPricesSetDataFormat but it returns an array that satifies the datamodel's updateData arguments
 * @param {object} securities the security prices received from the SecuritiesService.subscribeToPrices
 *
 * @return {Array} the array format accepted by the DataModel's updateData
 */
const toSecuritiesPricesUpdateDataFormat = securities => {
  return Object.keys(securities).reduce((arr, security) => {
    // Do not push items that has ONLY trading_time changes
    if (Object.keys(securities[security]).length > 1) {
      arr.push({
        values: { ...securities[security] },
        id: security,
        type: 'update'
      });
    }
    return arr;
  }, []);
};

customElements.define('template-securities-page', withInitDOM(TemplateSecuritiesPage));
