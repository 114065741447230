import i18n from 'sgx-localisation-service';
import PriceUtil from 'utils/price-util';

export default {
  chart: {
    animation: false,
    zoomType: 'xy',
    spacingTop: 0,
    height: null
  },
  credits: false,
  rangeSelector: false,
  scrollbar: false,
  navigator: false,
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: i18n.getTranslation('app.charts.xaxis-date-formats', { returnObjects: true })
  },
  yAxis: [
    {
      id: 'price',
      labels: {
        formatter: PriceUtil.getAbbreviateFormatter(2)
      },
      opposite: false,
      title: {
        text: i18n.getTranslation('app.widget-equity-single.price'),
        style: {
          fontWeight: 'bold'
        }
      }
    },
    {
      id: 'volume',
      title: {
        text: i18n.getTranslation('app.widget-equity-single.volume'),
        style: {
          fontWeight: 'bold'
        }
      },
      min: 0,
      labels: {
        formatter: PriceUtil.getAbbreviateFormatter(2, true)
      },
      opposite: true,
      gridZIndex: 3
    }
  ],
  tooltip: {
    valueDecimals: 3
  },
  series: [{
    animation: false,
    name: i18n.getTranslation('app.widget-equity-single.volume'),
    type: 'column',
    yAxis: 'volume',
    color: '#BDD831',
    tooltip: {
      valueDecimals: 3
    }
  }, {
    animation: false,
    name: i18n.getTranslation('app.widget-equity-single.price'),
    yAxis: 'price',
    color: '#0095B5',
    tooltip: {
      valueDecimals: 3
    },
    zIndex: 2
  }]
};