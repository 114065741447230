import {withInitDOM} from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DeviceService from 'sgx-device-service';
import tmpl from './widget-rights-dialog.html';
import ConfigService from '@sgx/sgx-config-service';
import DateService from '@sgx/sgx-date-time-service';
import CorporateActionsService from 'services/corporate-actions-service';
import StoreRegistry from 'stores/store-registry';

class WidgetRightsDialog extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());

    this._dialog = this.querySelector('.w-rights-dialog');
    this._paymentSummary = this.querySelector('cmp-rights-payment-summary');
    this._paymentsCmp = this.querySelector('sgx-payments');
    this._timeoutRef = null;
    this._intervalTenure = 5000;
  }

  connectedCallback() {
    this._setEventListeners(true);
  }

  disconnectedCallback() {
    this._setEventListeners(false);
  }

  _setEventListeners(enable) {
    const methodName = enable ? 'addEventListener' : 'removeEventListener';
    this._paymentsCmp[methodName]('secondaryLinkClicked', e => {
      e.preventDefault();
      this._dialog.hide();
    })
  }

  showDialog() {
    const dialogConfig = {
      isModal: true,
      fullscreen: DeviceService.isMobile()
    };
    return this._dialog.show(dialogConfig);
  }

  async setData(data) {

    const {currency, total, referenceNumber: refNumber, accountNumber, expiryDateTime, accountId} = data;
    //todo: this is just temp data
    const beneficiaryName = ConfigService.BENEFICIARY.name;
    const beneficiaryAccount = ConfigService.BENEFICIARY.account;
    const bankName = ConfigService.BENEFICIARY.bank;
    const bankBIC = ConfigService.BENEFICIARY.bic;
    const uen = ConfigService.BENEFICIARY.uen;
    const company = ConfigService.BENEFICIARY.company;
    const {timestamp} = await CorporateActionsService.status()
    let timer = DateService.tz(expiryDateTime, 'Asia/Singapore').diff(DateService.tz(timestamp, 'Asia/Singapore'), 'seconds');

    if(timer <= 0){
      timer = 1;
    }

    this._paymentSummary.setData({
      paymentCurrency: currency,
      sharesApplied: data.rightsApplied,
      issuePrice: data.issuePrice,
      transFee: data.txnFee,
      gst: data.gst,
      total,
      totalAmount: data.totalAmount
    });

    const selectedOption = i18n.getTranslation(`widget-rights-dialog.paymentModes.${data.rightsPaymentMode}`);
    this._paymentsCmp.setConfig({
      config: {
        paynow: {
          visible: selectedOption == 'paynow',
          selected: selectedOption == 'paynow',
          hideReapply: true
        },
        bt: {
          visible: selectedOption == 'bt',
          selected: selectedOption == 'bt'
        },
        tt: {
          visible: selectedOption == 'tt',
          selected: selectedOption == 'tt'
        }
      },
      selectedOption
    });

    this._paymentsCmp.setData({
      notesTitle: i18n.getTranslation('widget-rights-dialog.application-options.notes_html'),
      paymentInfo: {
        paynow: {
          amount: total,
          payee: beneficiaryName,
          paynowQRInfo: {
            uen,
            amount: data.totalAmount,
            editable: false,
            expiry:  DateService.tz(expiryDateTime, 'Asia/Singapore').format('YYYYMMDDHHmmss'),
            refNumber: refNumber,
            company
          },
          accountNumber,
          timer
        },
        bt: {
          accountNumber,
          refNumber,
          amount: total,
          beneficiaryName,
          beneficiaryAccount,
          bankName,
          bankBIC
        },
        tt: {
          accountNumber,
          refNumber,
          amount: total,
          beneficiaryName,
          beneficiaryAccount,
          bankName,
          bankBIC,
          footer: i18n.getTranslation('widget-rights-dialog.application-options.tt.footer_html', {
            link: ConfigService.links.TT_LINK
          })
        }
      }
    }, {
      success: {
        received: {
          title: i18n.getTranslation('widget-rights-dialog.paynow-messages.success.received.title'),
          description: i18n.getTranslation('widget-rights-dialog.paynow-messages.success.received.description'),
          text: i18n.getTranslation('widget-rights-dialog.paynow-messages.success.received.button.text'),
          link: '/portfolio'
        }
      },
      error: {
        timeout: {
          title: i18n.getTranslation('widget-rights-dialog.paynow-messages.error.timeout.title'),
          description: i18n.getTranslation('widget-rights-dialog.paynow-messages.error.timeout.description'),
          text: i18n.getTranslation('widget-rights-dialog.paynow-messages.error.timeout.button.text'),
          link: '/portfolio',
        },
        unsuccessful: {
          title: i18n.getTranslation('widget-rights-dialog.paynow-messages.error.unsuccessful.title'),
          description: i18n.getTranslation('widget-rights-dialog.paynow-messages.error.unsuccessful.description'),
          text: i18n.getTranslation('widget-rights-dialog.paynow-messages.error.unsuccessful.button.text'),
          link: '/portfolio',
        }
      },
      secondaryLink: {
        text: i18n.getTranslation('widget-rights-dialog.paynow-messages.secondary-link.text'),
        href: `${window.location.origin}${ConfigService.links.INVESTORS_PORTAL_DASHBOARD}`
      }
    });
    this._paymentsCmp.showStage(2);
    await this._pollPaymentStatus({accountId, refNumber});
  }

  getPaynowExpiryTime(expiry) {
      const TIMEZONE = ConfigService.env.timezone;
      // let expiry = new Date();
      expiry = DateService.tz(expiry, 'Asia/Singapore').format('YYYYMMDDHHmmss');
      expiry = expiry.substring(0, 10) + (+(expiry.charAt(10) + expiry.charAt(11)) + 5).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
      }) + expiry.substring(12);
      return expiry;
  }

  async _pollPaymentStatus({accountId, refNumber}) {
    const response = await CorporateActionsService.getPaymentStatus({
      accountId,
      refId: refNumber
    });

    this._currentTime = response.meta.timestamp;
    this._expiryTime = response.data.expiredDateTime;

    let shouldPoll = true;

    if (DateService.tz(this._currentTime, 'Asia/Singapore') > DateService.tz(this._expiryTime, 'Asia/Singapore')) {
      this._paymentsCmp.showMessage({status: 'error', type: 'timeout'}, true);
      shouldPoll = false;
    } else if (response.data.instructionStatus !== 'PENDING_PAYMENT') {
      const status = ['VERIFICATION_IN_PROGRESS', 'APPLICATION_PENDING_PROCESSING', 'SUCCESSFUL'].includes(response.data.instructionStatus) ? 'success' : 'error';
      this._paymentsCmp.showMessage({
        status,
        type: status === 'success' ? 'received' : 'unsuccessful'
      }, true);
      shouldPoll = false;
    }

    if (this._timeoutRef) {
      clearTimeout(this._timeoutRef);
    }

    if (shouldPoll) {
      this._timeoutRef = setTimeout(_ => {
        this._pollPaymentStatus({accountId, refNumber})
      }, this._intervalTenure)
    }
  }

}

customElements.define('widget-rights-dialog', withInitDOM(WidgetRightsDialog));
