module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-transfer-securities-prompt\">\n  <div class=\"cmp-transfer-securities-prompt-icon sgx-icon--before\"></div>\n  <!-- TODO: check with core if sgx-base-text-title-18 needs to be added as it's not yet existing at the moment but it was mentioned on the specs -->\n  <p class=\"cmp-transfer-securities-prompt-title sgx-base-text-title-18\" data-i18n=\"transfer-securities-prompt.title\"></p>\n  <p class=\"cmp-transfer-securities-prompt-description sgx-base-text-body-14\"></p>\n  <button type=\"button\" class=\"cmp-transfer-securities-prompt-button sgx-button--primary\">\n    <span data-i18n=\"transfer-securities-prompt.button\"></span>\n  </button>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()