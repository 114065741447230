module.exports = (function() {
    var templateString = module.exports = "<template class=\"widget-social-links\">\n  <section class=\"widget-social-links--social-padding\">\n    <div class=\"row\">\n      <div class=\"col-12 widget-social-links--social-pd-bt\">\n        <div class=\"widget-social-links--title widget-social-links--sgx-title-18\"></div>\n      </div>\n      <div class=\"col-12 widget-social-links--social-list\"></div>\n      <div class=\"col-12 widget-social-links--view-more widget-social-links--sgx-title-16\">\n        <a class=\"widget-social-links--view-more-link\" href=\"https://www.sgx.com/social\" target=\"_blank\"></a>\n      </div>\n    </div>\n  </section>\n  <sgx-dialog class=\"widget-social-links-wechat-dialog\" id=\"widget-social-links-wechat-dialog\" role=\"dialog\">\n    <h2 data-dialog=\"title\" class=\"widget-social-links-wechat-dialog--title\"></h2>\n    <div data-dialog=\"body\" class=\"widget-social-links-wechat-dialog--body\">\n      <div class=\"widget-social-links-wechat-dialog--text-small widget-social-links-wechat-dialog--wechat-body-content\">\n      </div>\n      <img class=\"widget-social-links-wechat-dialog--wechat-qr-code\" alt=\"\">\n    </div>\n  </sgx-dialog>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()