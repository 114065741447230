import { Utils, withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import { abbreviateNumber, formatThousands } from 'utils/price-util';
import tmpl from './cmp-price-info.html';

class CmpPriceInfo extends HTMLElement {
  constructor() {
    super();
    this._ready = Utils.deferred();
  }

  connectedCallback() {}

  initDOM() {
    this.classList.add('cmp-price-info');
    this.appendChild(tmpl.getNode());
    this._priceChanges = this.querySelector('.cmp-price-info-changes');
    this._priceValue = this.querySelector('.cmp-price-info-last-value');
    this._ready.resolve();
  }

  setData(data) {
    this.whenReady()
      .then(_ => this._render(data))
  }

  whenReady() {
    return this._ready.promise;
  }

  _render({c, p, lt, reportingCurrency= '', curr}) {
    let change = c;
    let percentage = formatThousands(p, 3, true);
    let currency = '';

    if (change > 0) {
      this._priceChanges.textContent = `+${change} (${percentage}%)`;
      this._priceChanges.classList.add('cmp-price-info-changes--green');
      this._priceChanges.classList.remove('cmp-price-info-changes--red');
      this._priceValue.classList.add('cmp-price-info-last-value--up');
      this._priceValue.classList.remove('cmp-price-info-last-value--down');
    } else if (change === 0) {
      this._priceChanges.textContent = `(${i18n.getTranslation('cmp-price-info.no-price-change')})`;
      this._priceChanges.classList.remove('cmp-price-info-changes--red');
      this._priceChanges.classList.remove('cmp-price-info-changes--green');
      this._priceValue.classList.remove('cmp-price-info-last-value--up');
      this._priceValue.classList.remove('cmp-price-info-last-value--down');
    } else {
      this._priceChanges.textContent = `${change} (${percentage}%)`;
      this._priceChanges.classList.remove('cmp-price-info-changes--green');
      this._priceChanges.classList.add('cmp-price-info-changes--red');
      this._priceValue.classList.remove('cmp-price-info-last-value--up');
      this._priceValue.classList.add('cmp-price-info-last-value--down');
    }

    if (reportingCurrency && curr) {
      currency = reportingCurrency.toLowerCase() === curr.toLowerCase() ? '' : curr;
    }

    this._priceValue.textContent = `${abbreviateNumber(lt, 3)} ${currency}`;
  }
}

customElements.define('cmp-price-info', withInitDOM(CmpPriceInfo));
