import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DateUtil from 'utils/date-util';
import tmpl from './widget-security-details-product-specifications.html';

class WidgetSecurityDetailsProductSpecifications extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.classList.add('widget-security-details-product-specifications');
    this.appendChild(tmpl.getNode());

    this._column = this.querySelector('.w-security-details-product-specifications-column');
  }

  connectedCallback() {}

  disconnectedCallback() {}

  setData(data) {
    this._setCollapsibleColumns(data);
  }

  _createCollapsibleColumnData(data) {
    return Object.keys(data).reduce((arr, key) => {
      arr.push({
        title: i18n.getTranslation(`w-security-details-product-specifications.labels.${key}`),
        value: data[key] || '-'
      });
      return arr;
    }, []);
  }

  _setCollapsibleColumns(data) {
    const productDetail = (data && data.cmsProductDetails) || {};
    const columnData = this._createCollapsibleColumnData({
      'stock-code': productDetail.sgxStockCode,
      'underlying-index': productDetail.underlyingIndex,
      'isin-code': productDetail.isinCode,
      'leverage': productDetail.leverage,
      'type': productDetail.dlcType,
      'dlc-currency': productDetail.currency && productDetail.currency.name,
      'underlying-currency': productDetail.underlyingCurrency && productDetail.underlyingCurrency.name,
      'airbag-level': productDetail.airbagLevel,
      'leveraged-index': productDetail.leveragedIndex,
      'calculating-agent': productDetail.calculatingAgent,
      'listing-date': this._getListingDate(productDetail.listingDate),
      'expiry-date': this._getFormattedDate(productDetail.expiryDate),
      'sip-eip': productDetail.classification && productDetail.classification.toUpperCase(),
      'board-lot-size': productDetail.boardLotSize
    });
    this._column.setData(columnData);
  }

  _getListingDate(date) {
    return i18n.getTranslation('w-security-details-product-specifications.listed-date', {
      date: this._getFormattedDate(date)
    });
  }

  _getFormattedDate(unformattedDate) {
    return DateUtil.formatDateTo((unformattedDate * 1000), null, i18n.getTranslation('app.securities.format.date.default'), true);
  }
}

customElements.define('widget-security-details-product-specifications', withInitDOM(WidgetSecurityDetailsProductSpecifications));
