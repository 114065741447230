import i18n from '@sgx/sgx-localisation-service';

const constants = {
  FULL_DATE_FORMAT: i18n.getTranslation('app.shared-text.date-format-full-date'),
  PDF_DATE_FORMAT: 'MMM YYYY',
  CDP_NOTIFICATIONS: {
    NOT_AVAILABLE: i18n.getTranslation('app.widget-portfolio-transactions.table.cdp-notifications.column.not-available'),
    AVAILABLE: i18n.getTranslation('app.widget-portfolio-transactions.table.cdp-notifications.column.yes')
  },
  BBF: i18n.getTranslation('app.widget-portfolio-transactions.table.transaction-type.column.bbf'),
  EXPORT_NAME: 'ShareTransactions',
  DEFAULT_TABLE_HEIGHT: '320px',
  TABLE_TOOLBAR_AND_HEADER_HEIGHT: 86,
  TABLE_ROW_HEIGHT: 32
};

const tableConfig = {
  options: {
    localise: false,
    downloadData: false,
    columnsConfiguration: false,
    sortable: false,
    resizable: true,
    reorderable: true,
    filterable: false,
    timezone: 'Asia/Singapore',
    autoWidth: true,
    exportDataFileName: constants.EXPORT_NAME
  },
  layout: {
    desktop: {
      rowHeight: 32,
      horizontalScrollStep: 96,
      verticalScrollStep: 96
    },
    mobile: {
      cellPerRow: [2, 2, 2],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    },
    tablet: {
      cellPerRow: [2, 2, 2],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    }
  },
  columns: {
    security: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'link',
        newTab: true,
        className: 'security-link-cell'
      },
      filter: false,
      sort: {
        type: 'text',
        valueKey: 'label'
      },
      label: i18n.getTranslation('app.widget-portfolio-transactions.table.security-name.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-transactions.table.security-name.tooltip')
    },
    date: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'date',
        format: constants.FULL_DATE_FORMAT
      },
      sort: {
        type: 'date'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-transactions.table.transaction-date.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-transactions.table.transaction-date.tooltip')
    },
    description: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-transactions.table.transaction-type.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-transactions.table.transaction-type.tooltip')
    },
    changedQty: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-transactions.table.transaction-qty.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-transactions.table.transaction-qty.tooltip')
    },
    balance: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        decimalPlaces: 0,
        replaceZero: false
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-transactions.table.balance-qty.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-transactions.table.balance-qty.tooltip')
    },
    cdpNotifications: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text',
        valueKey: 'label',
      },
      sort: {
        type: 'text'
      },
      filter: false,
      export: obj => obj && obj.label || '',
      label: i18n.getTranslation('app.widget-portfolio-transactions.table.cdp-notifications.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-transactions.table.cdp-notifications.tooltip')
    }
  },
  decorators: {
    rows: [
      {
        name: 'cdp-notification',
        config: {
          cellToDecorate: ['cdpNotifications']
        }
      },
      {
        name: 'row-background',
        config: {
          cellToDecorate: ['security'],
          isEven: rowData => rowData.group % 2 === 0
        }
      }
    ]
  },
  states: {
    activeStateId: 0,
    states: [
      {
        data: {
          order: [
            'security',
            'date',
            'description',
            'changedQty',
            'balance',
            'cdpNotifications'
          ],
          columns: {
            security: {
              show: true
            },
            date: {
              show: true
            },
            description: {
              show: true
            },
            changedQty: {
              show: true
            },
            balance: {
              show: true
            },
            cdpNotifications: {
              show: true
            }
          },
          filters: {},
          sorts: {}
        }
      }
    ]
  }
};

export {
  tableConfig,
  constants
};
