import i18n from 'sgx-localisation-service';

export default {
  config: {
    type: 'content',
    editable: false
  },
  state: {
    activeStateId: 0,
    states: [
      {
        label: i18n.getTranslation('app.widget-portfolio.account-details.tabs.owner-info'),
        data: 'widget-portfolio-owner-info'
      },
      {
        label: i18n.getTranslation('app.widget-portfolio.account-details.tabs.registered-accounts'),
        data: 'widget-portfolio-registered-accounts'
      },
      {
        label: i18n.getTranslation('app.widget-portfolio.account-details.tabs.notification-settings'),
        data: 'widget-portfolio-notification-settings'
      }
    ]
  }
};
