import {FetchUtils} from '@sgx/sgx-base-code';
import ConfigService from '@sgx/sgx-config-service';
import i18n from '@sgx/sgx-localisation-service';
import get from 'lodash/get';
import BaseService from "services/base-service";
import * as constants from './corporate-actions-service-constants';
import DateService from 'sgx-date-time-service';
import { URLUtils } from 'sgx-base-code';

/**
 * corporate-actions-service is unrelated to sgx-corporate-actions-service.
 * this is a temporary approach where we get the data from the JSON file until we have our actual CMS
 */
class CorporateActionsService extends BaseService {
  /**
   * Get the constants associated to the Corporate-Actions service
   */
  get constants() {
    return constants;
  }

  getCorporateActionsList() {
    const lang = i18n.getLanguage();
    return FetchUtils.fetchJSON(ConfigService.env.api['document-list'][lang])
      .then(data => {
        const url = get(data, 'data.list.results[0].data.file.data.url', '');
        if (!url) {
          return [];
        }
        return FetchUtils.fetchJSON(url);
      });
  }

  /**
   * method to get the pts status as well as the current timestamp from the server
   * to compare it against the event close date and time
   */
  status() {
    return FetchUtils.fetchJSON(ConfigService.endpoints.PTS_STATUS)
      .then(response => ({
        status: get(response, 'data.result', false),
        timestamp: get(response, 'meta.timestamp')
      }));
  }

  /**
   * Gets the utils shared instance.
   * @return {CorporateActionsService} the utils shared instance
   */
  static get instance() {
    if (!CorporateActionsService._instance) {
      CorporateActionsService._instance = new CorporateActionsService();
    }
    return CorporateActionsService._instance;
  }

  /**
   * @desc Gets the standing instructions for the passed accountId
   * */
  getStandingInstructions({accountId}) {
    return this._authFetch(ConfigService.endpoints.CA_STANDING_INSTRUCTIONS, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => {
        return response || []
      })
      .catch(error => Promise.reject(error));
  }

  /**
   * @desc Cancel Standing Instruction
   * */
  cancelStandingInstruction({
                              accountId,
                              securityCode,
                              eventType,
                              optionType
                            }) {
    return this._authFetch(ConfigService.endpoints.CA_STANDING_INSTRUCTIONS, {
      method: 'DELETE',
      headers: {
        [this._requestHeaders.accountId]: accountId
      },
      body: JSON.stringify({
        instructions: [{securityCode, eventType, optionType}]
      })
    })
      .then(response => {
        return response.data || []
      })
      .catch(error => Promise.reject(error));

  }

  /**
   * @param {String} currentDateTime
   * @param {String} electionStart
   * @param {String} electionEnd
   * @returns valid event comparing currentDateTime and electionStartDateTime and electionEndDateTime
   * */
  getValidElectionDateTime({ currentDateTime, electionStart, electionEnd }) {
    const currentTimeFormatted = DateService(currentDateTime).tz(ConfigService.env.timezone).lang('en').format(this.constants.DATE_TIME_FORMAT);
    const currentTimeStamp = DateService(currentTimeFormatted).format('X');
    const electionStartDateTime = DateService(electionStart);
    const electionEndDateTime = DateService(electionEnd);

    return (currentTimeStamp > electionStartDateTime.format('X') && currentTimeStamp < electionEndDateTime.format('X'))

  }

  getActivePaymentStatus({currentDateTime, paymentDateTime}) {
    const currentTimeFormatted = DateService(currentDateTime).tz(ConfigService.env.timezone).lang('en').format(this.constants.DATE_TIME_FORMAT);
    const currentTimeStamp = DateService(currentTimeFormatted).format('X');
    const paymentEndDateTime = DateService(paymentDateTime);

    return (currentTimeStamp < paymentEndDateTime.format('X'))
  }


  /**
   * @param {String} accountId
   * @param {String} corporateActionRef
   * */
  getRights({accountId, corporateActionRef}) {
    const options = {};
    if(corporateActionRef){
      options['corporateActionRef'] = corporateActionRef
    }
    const url = URLUtils.setQueryParams(this._endpoints.PORTFOLIO_RIGHTS, {...options});

    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => (response && response.data && response.data.submissions) || []);
  }

  getPaymentStatus({accountId, refId}) {
    const url = `${this._endpoints.PORTFOLIO_RIGHTS}/refId/${refId}`;
    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
  }
}

export default CorporateActionsService.instance;
