import BaseService from 'services/base-service';
import * as constants from './trading-account-service-constants';

let instance = null;
class TradingAccountService extends BaseService {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Get the constants associated to the trading account service
   */
  get constants() {
    return constants;
  }

  /**
   * Get broker trading accounts.
   * @return {Promise<Object>} Returns an object containing trading account information.
   * @param {String} accountId The id associated with the CDP account
   */
  getTradingAccounts(accountId) {
    return this._authFetch(this._endpoints.TRADING_ACCOUNTS_READ, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => {
        const accounts = (response && response.data && response.data.positionAccountDetails) || [];
        return accounts.map(account => ({ ...account.positionAccount }));
      })
      .catch(error => Promise.reject(error));
  }
}

export default new TradingAccountService();
