import { withInitDOM } from 'sgx-base-code';
import ConfigService from 'sgx-config-service';
import i18n from 'sgx-localisation-service';
import tmpl from './cmp-transfer-securities-prompt.html';

export default class CmpTransferSecuritiesPrompt extends HTMLElement {
  constructor() {
    super();
    this._onLinkButtonClick = this._onLinkButtonClick.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-transfer-securities-prompt');
    this._linkButton = this.querySelector('.cmp-transfer-securities-prompt-button');
    this._description = this.querySelector('.cmp-transfer-securities-prompt-description');
    this._description.innerHTML = i18n.getTranslation('transfer-securities-prompt.description_html', {
      email: ConfigService.common.CONTACT_EMAIL, number: ConfigService.common.CONTACT_NUMBER
    });
  }

  connectedCallback() {
    this._linkButton.addEventListener('click', this._onLinkButtonClick);
  }

  disconnectedCallback() {
    this._linkButton.removeEventListener('click', this._onLinkButtonClick);
  }

  _onLinkButtonClick() {
    // TODO - Go to link token
  }

  show() {
    this.style.display = 'flex';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('cmp-transfer-securities-prompt', withInitDOM(CmpTransferSecuritiesPrompt));
