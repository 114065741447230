import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import tmpl from './widget-portfolio-notification-settings.html';
import UserPreferencesService from 'services/user-preferences-service';
import { isAuthenticated } from 'utils/auth-util';
import {
  PSN_SOFT_COPY_STATUS, PTS_CDP_HARD_COPY_STATUS,
  PTS_HARD_COPY_STATUS
} from '../../services/user-preferences-service/src/user-preferences-service-constants';

const enabledMsg = i18n.getTranslation('app.portfolio-notification-settings.enabled');
const disabledMsg = i18n.getTranslation('app.portfolio-notification-settings.disabled');

class WidgetPortfolioNotificationSettings extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.getNofiticationsInfo();
  }

  connectedCallback() {
  }

  getNofiticationsInfo() {
    if (isAuthenticated()) {
      UserPreferencesService.getUserNotifications()
        .then(data => this.setData(data))
    }
  }

  setData(data) {
    data = data || {};
    const notificationInfo = data.notificationInfo || {};
    this.querySelector('#email-pre-settlement').innerHTML = notificationInfo.psnEmailNotificationOption === PSN_SOFT_COPY_STATUS.CHECKED_SUB_SC ? enabledMsg : disabledMsg;
    this.querySelector('#sms-pre-settlement').innerHTML = notificationInfo.psnServiceStatus === PSN_SOFT_COPY_STATUS.CHECKED_SUB_SC ? enabledMsg : disabledMsg;
  }
}

customElements.define('widget-portfolio-notification-settings', withInitDOM(WidgetPortfolioNotificationSettings));
