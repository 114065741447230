import i18n from '@sgx/sgx-localisation-service';
import SgxTableDecoratorBase from '@sgx/sgx-table/src/sub-components/sgx-table-decorator/sgx-table-decorator-base';

/**
 * A custom HTML element used as a decorator for portfolio overview
 * @module sgx-table-decorator-blocked-balance
 * @type {SgxTableDecoratorBase}
 */

class SgxTableDecoratorBlockedBalance extends SgxTableDecoratorBase {
  initDOM() {
    this.style.display = 'none';
  }

  connectedCallback() {}

  decorate(decoratorObject) {
    const config = decoratorObject.decoratorConfig;
    const rowData = decoratorObject.rowData;
    const rowElem = decoratorObject.element;
    this._cellsToDecorate = Array.isArray(config.cellToDecorate) ? config.cellToDecorate : [config.cellToDecorate];

    this._cellsToDecorate.forEach(cell => {
      const elem = rowElem.querySelector(`[data-column-id = ${cell}]`);
      const tooltipContent = elem.querySelector(`[data-sgx-tooltip-content]`);
      if (tooltipContent.children.length) {
        tooltipContent.removeChild(tooltipContent.children[0]); // there will be only one children
      }
      tooltipContent.appendChild(this._createBreakdownList(rowData.blockedBalanceBreakdown));
    });
  }

  _createBreakdownList(blockedBalanceBreakdown) {
    const fragment = document.createDocumentFragment();
    const breakdownList = Object.keys(blockedBalanceBreakdown).reduce((ul, key) => {
      const li = document.createElement('li');
      const label = i18n.getTranslation(`sgx-table-cell-blocked-balance.${key}`);
      li.textContent = `${label} = ${blockedBalanceBreakdown[key]}`;
      ul.appendChild(li);
      return ul;
    }, document.createElement('ul'));

    fragment.appendChild(breakdownList);
    return fragment;
  }
}

customElements.define('sgx-table-decorator-blocked-balance', SgxTableDecoratorBlockedBalance);
const decorator = document.createElement('sgx-table-decorator-blocked-balance');
document.body.appendChild(decorator);
export default decorator;
