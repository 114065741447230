/**
* @function helpCenterMiddleware
* @desc Middleware used to change the page title of the dashboard page depeding whether if the user is authenticated or not
* @param  {Object} router Router5 instance of application
* @return {*} {}
*/
function helpCenterMiddleware(/* router */) {
  return (newState, previousState, doneFn) => {
    let name = newState.name;
    if (name !== 'about') {
        newState.pageData.showHelpCenter = true;
        doneFn();
    } else {
      doneFn();
    }
  };
}

export default helpCenterMiddleware;
