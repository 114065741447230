import {withInitDOM} from 'sgx-base-code';
import tmpl from './widget-security-details-warrant-info.html';
import ConfigService from 'sgx-config-service';
import SecurityDetailsAggregator from 'aggregators/security-details-aggregator/security-details-aggregator';
import i18n from 'sgx-localisation-service';
import MetadataService from "sgx-metadata-service";

const corpInfoBaseLink = ConfigService.links.CORPORATE_INFORMATION_BASE;

class WidgetSecurityDetailsWarrantInfo extends HTMLElement {
  constructor() {
    super();
    this._onIframeLoadCallback = this._onIframeLoadCallback.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-warrant-info');

    // Fallback iframe references
    this._indicator = this.querySelector('sgx-status-indicator');
    this._iframe = this.querySelector('.widget-security-details-warrant-info-iframe');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
    this._iframe.removeEventListener('load', this._onIframeLoadCallback);
  }

  async setData(data) {
    this._indicator.show({
      status: 'neutral',
      title: i18n.getTranslation('app.shared-text.status-indicator.empty.description')
    });
    const metadataResponse = await MetadataService.getMetaDataByStockCode(data.nc)
      .catch(_ => this._indicator.show({
        status: 'error',
        title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
        description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
      }));
    this._iframe.src = `${ConfigService.links.V1_WARRANTS_DATA_URL}/${metadataResponse.ibmCode}`;

    this._iframe.addEventListener('load', this._onIframeLoadCallback);
  }


  _onIframeLoadCallback() {
    setTimeout(() => this._indicator.hide());
  }
}

customElements.define('widget-security-details-warrant-info', withInitDOM(WidgetSecurityDetailsWarrantInfo));
