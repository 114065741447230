import i18n from 'sgx-localisation-service';
import PriceUtil from 'utils/price-util';

export default {
  sgxApiDateFormat: 'YYYYMMDD_hhmmss',
  chart: {
    animation: false,
    zoomType: false,
    spacingTop: 0,
    height: 300,
    backgroundColor: 'none'
  },
  credits: false,
  rangeSelector: false,
  scrollbar: false,
  navigator: false,
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: i18n.getTranslation('app.charts.xaxis-date-formats', { returnObjects: true })
  },
  yAxis: [
    {
      id: 'price',
      labels: {
        formatter: PriceUtil.getAbbreviateFormatter(2)
      },
      opposite: false,
      title: {
        text: i18n.getTranslation('app.security-details.chart.price'),
        style: {
          fontWeight: 'bold'
        }
      },
      height: '100%'
    },
    {
      id: 'volume',
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      min: 0,
      labels: {
        formatter: PriceUtil.getAbbreviateFormatter(2)
      },
      top: '60%',
      height: '40%'
    }
  ],
  tooltip: {
    valueDecimals: 3
  },
  series: [
    {
      animation: false,
      name: i18n.getTranslation('app.security-details.chart.price'),
      yAxis: 'price',
      id: 'price-series',
      color: '#0095B5',
      tooltip: {
        valueDecimals: 3
      },
      zIndex: 2
    },
    {
      animation: false,
      name: i18n.getTranslation('app.security-details.chart.volume'),
      type: 'column',
      yAxis: 'volume',
      color: '#BDD831',
      tooltip: {
        valueDecimals: 3
      }
    }
  ]
};
