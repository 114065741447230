import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-portfolio-owner-info.html';
import userService from 'services/user-service';
import { isAuthenticated } from 'utils/auth-util';


class WidgetPortfolioOwnerInfo extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.getUserInfo();
  }

  connectedCallback() {
  }

  getUserInfo() {
    // Fetch all required form data
    if (isAuthenticated()) {
      userService.getUserParticulars()
        .then(data => this.setData(data));
    }
  }

  setData({ name, phoneNumber, otherPhoneNumber, officeNumber, residentialAddress, email }) {
    const address = Object.values(residentialAddress).filter(Boolean).reverse().toString().replace(/,/g, ', ');
    this.querySelector('#name').setValue(name);
    const mobileNumber = otherPhoneNumber;
    const homeNumber = phoneNumber;
    const numbers = { mobileNumber, homeNumber, officeNumber };
    ['mobile', 'home', 'office'].forEach(phone => {
      this.querySelector(`#${phone}-number`).setJsonValue({
        number: numbers[`${phone}Number`].number,
        code: numbers[`${phone}Number`].countryCode
      });
      this.querySelector(`#${phone}-number`).querySelector('.sgx-input-phone-country-selector').style.display = 'none';
    });
    this.querySelector('#email').setValue(email);
    this.querySelector('#residential-address').setValue(address);
  }
}

customElements.define('widget-portfolio-owner-info', withInitDOM(WidgetPortfolioOwnerInfo));
