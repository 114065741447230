import { withInitDOM } from 'sgx-base-code';
import DateService from 'sgx-date-time-service';
import DeviceService from 'sgx-device-service';
import i18n from '@sgx/sgx-localisation-service';
import TableUtil from 'utils/table-util';
import { formatThousands } from 'utils/price-util';
import { getPastMonths, ISO_FORMAT } from 'utils/date-util';
import { download } from 'utils/pdf-util';
import { getSecurityLink } from 'utils/portfolio-util';
import {
  tableConfig, constants } from './widget-portfolio-transactions-config';
import tmpl from './widget-portfolio-transactions.html';
import PortfolioService from 'services/portfolio-service';
import MetadataService from 'services/sgx-metadata-service';
import PrintService from 'services/print-service';

const {
  CDP_NOTIFICATIONS,
  BBF,
  PDF_DATE_FORMAT,
  EXPORT_NAME,
  DEFAULT_TABLE_HEIGHT,
  TABLE_ROW_HEIGHT,
  TABLE_TOOLBAR_AND_HEADER_HEIGHT } = constants;
class WidgetPortfolioTransactions extends HTMLElement {
  constructor() {
    super();
    this._onDownloadNotification = this._onDownloadNotification.bind(this);
  }

  initDOM() {
    this.classList.add('widget-portfolio-transactions');
    this.appendChild(tmpl.getNode());
    this._table = this.querySelector('.widget-portfolio-transactions-table');
    this._loader = this.querySelector('.portfolio-transactions-loader');

    this._table.setConfig(tableConfig);
    TableUtil.addCustomToolbar(this._table, 'cmp-portfolio-blb-toolbar');
    this._toolbar = this.querySelector('cmp-portfolio-blb-toolbar');
    this._periodSelect = this._toolbar.querySelector('.cmp-portfolio-blb-toolbar-period-select');

    this._setPeriods();
  }

  connectedCallback() {
    this._setListeners(true);
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener': 'removeEventListener';

    this[fnName]('portfolio-toolbar-change', this._onToolbarChange);
    this[fnName]('portfolio-toolbar-download', this._onToolbarDownload);
    this[fnName]('portfolio-toolbar-print', this._onToolbarPrint);
    this._table[fnName]('row-notification-click', this._onDownloadNotification);
  }

  _onToolbarChange(evt) {
    this._type = evt.detail.type;
    this._period = evt.detail.period;
    this.refresh(evt.detail.type, evt.detail.period);
  }

  _onDownloadNotification(evt) {
    const { rowId } = evt.detail;
    this._table.getDataModel().getRowData(rowId)
      .then(transaction => {
        const notfId = transaction && transaction.cdpNotifications && transaction.cdpNotifications.value;
        if (notfId) {
          this._downloadNotification(notfId);
        }
      });
  }

  _downloadNotification(notfId) {
    return PortfolioService.getNotificationById(this._accountId, notfId)
      .then(data => data && data.content && download(data.content, notfId));
  }

  _setPeriods() {
    const options = getPastMonths(1);
    const latestOption = options[0];
    latestOption.selected = true; // select the latest month on the list

    this._toolbar.setData({
      period: options
    });
  }

  setData({ accountId, accountDesc, accountIdFormatted }) {
    if (this._accountId !== accountId) {
      this._accountId = accountId;
      this._accountIdFormatted = accountIdFormatted;
      this._accountDesc = accountDesc;
      this.refresh();
    } else {
      this._table.recalculateSize();
    }
  }

  refresh(type, period) {
    if (!period) {
      period = this._periodSelect.getValue();
    }

    const startDate = DateService(period, ISO_FORMAT.YEAR_MONTH).startOf('month').format(ISO_FORMAT.FULL_DATE);
    const endDate = DateService(period, ISO_FORMAT.YEAR_MONTH).endOf('month').format(ISO_FORMAT.FULL_DATE);

    this._monthName = DateService(period, ISO_FORMAT.YEAR_MONTH).format(PDF_DATE_FORMAT);
    this._loader.show({ status: 'loading' });
    this._periodSelect.setDisabled(true);

    Promise.all([
      this._getTransactions(this._accountId, startDate, endDate),
      this._table.whenConfigured()
    ])
      .then(([transactions]) => {
        this._loader.hide();
        this._periodSelect.setDisabled(false);
        // group is used to identify which background color will be use for its row
        let group = 0;
        let currentTransaction = '';
        const data = (transactions || []).map(transaction => {
          if (currentTransaction !== transaction.securityAbbreviatedName) {
            currentTransaction = transaction.securityAbbreviatedName;
            group++;
          }
          transaction.group = group;
          return transaction;
        });
        if (DeviceService.isDesktop()) {
          this._table.style.height = data.length && data.length > 5 ? `${(data.length * TABLE_ROW_HEIGHT) + TABLE_TOOLBAR_AND_HEADER_HEIGHT}px` : DEFAULT_TABLE_HEIGHT;
        }
        this._table.setData(data);
        this._table.recalculateSize();
      })
      .catch(e => {
        if (e === 'mismatch accountId') {
          // do nothing if the accountId mismatch. accounId mismatch happens when we switch to a different account while the other request is still pending
          return;
        }
        this._loader.show({ status: 'error' });
        this._periodSelect.setDisabled(false);
      });
  }

  _getTransactions(accountId, startDate, endDate) {
    const notfEndDate = DateService().format(ISO_FORMAT.FULL_DATE);
    return Promise.all([
      PortfolioService.getTransactions(accountId, startDate, endDate),
      PortfolioService.getNotifications(accountId, startDate, notfEndDate)
    ])
      .then(([movements, notifications]) => {
        if (movements.gsa === this._accountId) {
          const transactions = movements.movementDetails || [];
          return [transactions, notifications];
        }
        // prevent setting incorrect transaction records when user switches from one account to another.
        return Promise.reject('mismatch accountId');
      })
      .then(([transactions, notifications]) => transactions.map(transaction => {
        const {movFlag, movementType} = transaction;
        let {changedQty} = transaction;
        let cdpNotifications = {
          label: CDP_NOTIFICATIONS.NOT_AVAILABLE,
          value: ''
        };

        transaction.date = transaction.date.replace(/\//g, '-');
        if (movementType === 3) {
          const notification = notifications.filter(notif => notif.notfDate === transaction.date);
          cdpNotifications = !notification.length ? cdpNotifications : {
            label: CDP_NOTIFICATIONS.AVAILABLE,
            value: notification[0] && notification[0].notfId
          };
        }

        if (movFlag && changedQty > 0) {
          changedQty = `+${formatThousands(changedQty, 0, true)}`;
        } else {
          changedQty = formatThousands(changedQty, 0, true);
        }

        return {
          ...transaction,
          cdpNotifications,
          changedQty
        };
      }))
      .then(transactions => this._mapIbmCodeToStockCode(transactions))
      .then(transactions => {
        let securityBffExist = {};
        return transactions.reduce((accumulator, transaction) => {
          const securityLink = getSecurityLink(transaction.stockCode);
          // set the 'Balance Brought Forward' for each of the securities which should be the first thing to be displayed on top of each transaction
          const bffKey = `${transaction.securityAbbreviatedName}`;
          if (!securityBffExist[bffKey]) {
            securityBffExist[bffKey] = true;
            accumulator.push({
              securityAbbreviatedName: transaction.securityAbbreviatedName,
              date: '',
              changedQty: '',
              description: BBF,
              balance: transaction.bbf,
              cdpNotifications: '',
              securityCode: transaction.securityCode,
              stockCode: transaction.stockCode,
              id: accumulator.length + 1,
              security: {
                label: transaction.securityAbbreviatedName,
                link: securityLink
              }
            });
          }

          accumulator.push({
            ...transaction,
            securityName: transaction.securityAbbreviatedName,
            id: accumulator.length + 1,
            security: {
              label: DeviceService.isDesktop() ? '' : transaction.securityAbbreviatedName,
              link: securityLink
            }
          });
          return accumulator;
        }, []);
      });
  }

  _mapIbmCodeToStockCode(transactions) {
    const ibmCodes = transactions.map(transaction => transaction.securityCode); // securityCode is the ibm code

    return MetadataService.getMetaDataByIbmCodes(ibmCodes)
      .then(metadata => metadata.reduce((acc, currentValue) => {
        acc[currentValue.ibmCode] = currentValue;
        return acc;
      }, {}))
      .then(metadata => transactions.map(transaction => ({
        ...transaction,
        stockCode: metadata[transaction.securityCode] && metadata[transaction.securityCode].securityCode || 'N/A'
      })))
      .then(transactions => transactions.sort((a, b) => {
        // sort by security name and transaction date
        if (a.securityAbbreviatedName === b.securityAbbreviatedName) {
          // transaction date is only important when security are the same
          return new Date(a.date) - new Date(b.date);
        }
        return a.securityAbbreviatedName > b.securityAbbreviatedName ? 1 : -1;
      }));
  }

  _onToolbarDownload() {
    this._table.setExportDataFileName(`${EXPORT_NAME} ${this._accountIdFormatted} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`);
    this._table.exportData();
  }

  _onToolbarPrint() {
    this._table.getDataModel().getCurrentStateData()
      .then(({ values }) => {
        if (!this._monthName) {
          return;
        }
        const monthName = this._monthName;
        const accountNoDesc = this._accountDesc;
        const fields = Object.keys(values).reduce((acc, key) => {
          const { balance, securityCode, securityAbbreviatedName, description, changedQty, date, securityName, cdpNotifications, bbf } = values[key];
          // Filter out 'Balance Brought Forward' transaction type by checking the transaction date as the print service automatically includes bbf
          if (date) {
            acc.push({
              transactionDateStr: date,
              transactionQuantityStr: changedQty,
              securityCode,
              securityName,
              transactionTypeDesc: description,
              cdpNotification: cdpNotifications && cdpNotifications.label || '',
              balanceQuantityStr: formatThousands(balance, 0, true),
              balanceBroughtForwardStr: bbf.toString()
            });
          }

          return acc;
        }, []);
        const body = {
          qualifiedName: 'movements',
          params: {
            monthName,
            accountNoDesc
          },
          fields
        };

        PrintService.getPdf(body)
          .then(base64 => download(base64, `${EXPORT_NAME} ${this._accountIdFormatted} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`));
      });
  }
}

customElements.define('widget-portfolio-transactions', withInitDOM(WidgetPortfolioTransactions));
