import tmpl from './cmp-txn-signing-singpass.html';
import { withInitDOM } from 'sgx-base-code';
import ServiceBus from 'sgx-service-bus';
import ConfigService from 'sgx-config-service';
import { SINGPASS } from '../cmp-txn-signing-constants';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';
import AuthService from 'services/auth-service';
import UserService from 'services/user-service';

class CmpTxnSigningSingPass extends HTMLElement {
  constructor() {
    super();
    this._redirectUri = ConfigService.env['singpass-redirect-uri']['txn-signing']['user-particulars'];
    this._clientId = ConfigService.env['singpass-client-id']['txn-signing'];
    this._fetchSingPassParams = this._fetchSingPassParams.bind(this);
    this._handleSingpassLoginCTA = this._handleSingpassLoginCTA.bind(this);

    this._singpassAccountAvailable = StoreRegistry.cdpSession.getData().singpassAccountAvailable || false;
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-txn-signing-singpass');
    this._singpassQr = this.querySelector('#cmp-txn-signing-singpass-qr');
    this._helpInfoContainer = this.querySelector('.cmp-txn-signing-singpass-help');
    this._helpInfo = this.querySelector('.cmp-txn-signing-singpass-help-info');
    this._helpSingpass = this.querySelector('.cmp-txn-signing-singpass-help-singpass');
    this._txnSigningBase = document.querySelector('cmp-txn-signing');
    this._singpassTxnIntro = this.querySelector('.cmp-txn-signing-singpass-intro');
    this._statusIndicator = this._txnSigningBase.querySelector('.cmp-txn-signing-indicator');
    this._singpassNotice = this.querySelector('.c-txn-signing-singpass-notice');
  }

  connectedCallback(){
    this._setEventListener(true);
  }

  disconnectedCallback(){
    this._setEventListener(false);
  }

  _setEventListener(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this._singpassTxnIntro[fnName]('click', this._handleSingpassLoginCTA)
  }

  /**
   * handle logout and redirection to login
   * CTA = Call-to-Action
   * */
  _handleSingpassLoginCTA(e) {
    const lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');
    this._statusIndicator = this._txnSigningBase.getIndicator();

    if (e.target.className === 'c-txn-signing-login-link') {
      // show a loading indicator
      this._statusIndicator.show({
        status: 'loading'
      });

      AuthService.logout()
        .then(_ => window.location.href = ConfigService.links.USER_PARTICULARS_LOGIN_INDIVIDUAL_LINK[lang])
        .catch(error => {
          console.error(`Failed to logout. ${error}`);
          this._statusIndicator.show({
            status: 'error',
            title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
            description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
          });
        });
    }
  }

  setData({ service, isSingPassRedirect } = {}) {
    this._service = service;

    if (isSingPassRedirect) return;
    this._setSingpassIntro();
    this._setSingpassNotice();
  }

  /**
   * Initialize singpass QR if singpassAccountAvailable,
   * else update the intro message
   * todo: handling of logout scenario on click of link, to be discussed further
   * */
  _setSingpassIntro() {
    if (this._singpassAccountAvailable) {
      this._singpassTxnIntro.innerHTML = i18n.getTranslation('app.txn-signing.intro');
      this._helpInfo.innerHTML = i18n.getTranslation('app.txn-signing.singpass.help.no-mobile_html', { link: ConfigService.links.SINGPASS_MOBILE });
      this._helpSingpass.innerHTML = i18n.getTranslation('app.txn-signing.singpass.help.singpass_html', { link: ConfigService.links.SINGPASS_CONTACT });
      this._singpassQr.classList.remove('sgx-hidden');
      this.initSingPassQR();
      return;
    }

    this._singpassTxnIntro.innerHTML = i18n.getTranslation('app.txn-signing.singpass.unavailable_html', {
      singpassLink: ConfigService.links.SINGPASS_LINK,
      formLink: ConfigService.links.SGX_V2_UPDATE_PARTICULARS
    });
    this._singpassQr.classList.add('sgx-hidden');
    this._helpInfoContainer.classList.add('sgx-hidden');
  }

  /**
   * Resets SingPass side to initial state.
   */
  reset() {
    const singpassJunk = this._singpassQr.firstElementChild;
    if (singpassJunk) {
      this._singpassQr.removeChild(singpassJunk);
    }
  }

  /**
   * Function that SingPass module calls to retrieve the JWT.
   * Any errors occuring when attempting to retrieve the JWT are handled by SingPass.
   * @returns {Promise} resolves the JWT
   */
  _fetchSingPassParams() {
    return this._service({ authenticator: SINGPASS })
      .catch(response => {
        return {
          state: response.headers.get(ConfigService.request.headers.singPassState),
          nonce: response.headers.get(ConfigService.request.headers.singPassNonce),
          txnInfo: response.headers.get(ConfigService.request.headers.singPassJwt)
        }
      });
  }

  /**
   * Makes a call to SingPass module to fetch the QR code.
   * Occurs when the user first lands on the page.
   */
  initSingPassQR() {
    const onError = (id, message) => {
      console.error(`[SingPass] - ${message}${id ? ` - ${id}` : ''}`);
    };
    ServiceBus.request('singpass-txn-signing-service', {
      params: [
        'cmp-txn-signing-singpass-qr', // Id of QR code DOM element container
        { // Called 'clientParams' in SingPass docs
          clientId: this._clientId,
          redirectUri: this._redirectUri
        },
        this._fetchSingPassParams, // Called 'transactionParamsSupplier' in SingPass docs
        onError
      ],
      forceNew: true
    });
  }

  /**
   * @desc Set notice if logged-in user is a foreigner
   * */
  _setSingpassNotice() {
    const {idType} = StoreRegistry.cdpSession.getData();

    if(idType !== UserService.constants.USER_TYPES.FOREIGNER){
      return;
    }

    this._singpassNotice.classList.remove('sgx-hidden');
    this._singpassNotice.setData({
      status: 'informational',
      text: i18n.getTranslation(`app.txn-signing.singpass.notice-foreigner`),
      background: true
    });
  }
}

customElements.define('cmp-txn-signing-singpass', withInitDOM(CmpTxnSigningSingPass));
