module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-login-prompt\">\n  <div class=\"cmp-login-prompt-container container\">\n    <div class=\"cmp-login-prompt-text sgx-base-text-body\"></div>\n    <div class=\"cmp-login-prompt-tiles row\">\n      <a class=\"cmp-login-prompt-tile-link col-md-6\" data-tile=\"individual\">\n        <img class=\"cmp-login-prompt-tile-image\" src=\"" + require("../../assets/images/cdp_individual.jpg") + "\" />\n        <h3 class=\"cmp-login-prompt-tile-text sgx-base-text-title-16\" data-account-type=\"individual\" data-i18n=\"cmp-login-prompt.individual.title\"></h3>\n      </a>\n      <a class=\"cmp-login-prompt-tile-link col-md-6\" data-tile=\"corporate\">\n        <img class=\"cmp-login-prompt-tile-image\" src=\"" + require("../../assets/images/cdp_corporate.jpg") + "\" />\n        <h3 class=\"cmp-login-prompt-tile-text sgx-base-text-title-16\" data-account-type=\"corporate\" data-i18n=\"cmp-login-prompt.corporate.title\"></h3>\n      </a>\n    </div>\n    <div class=\"cmp-login-prompt-button-container\">\n      <a type=\"button\" rel=\"external\" class=\"cmp-login-prompt-cdp-account-link cmp-login-prompt-button sgx-button--primary\" data-i18n=\"cmp-login-prompt.button.individual\"></a>\n      <a type=\"button\" rel=\"external\" class=\"cmp-login-prompt-token-management-link cmp-login-prompt-button sgx-button--text\" data-i18n=\"cmp-login-prompt.button.corporate\"></a>\n    </div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()