import { withInitDOM } from '@sgx/sgx-base-code';
import i18n from '@sgx/sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import tmpl from './widget-account-select.html';
import userService from '../../services/user-service';
import { isAuthenticated } from 'utils/auth-util';
import { formatAccountId } from 'utils/bank-account-util';
import get from 'lodash/get';

const {ACCOUNT_STATUS, ACCOUNT_CATEGORY, ACCOUNT_STATES} = userService.constants;
const WIDTH_PER_CHARACTER = 10;
const ACCOUNT_SUSPENDED = i18n.getTranslation('widget-account-select.status.suspended');

/**
 * Accounts input select component
 * Expects an attribute of store-name
 * @module widget-account-select
 * @type { HTMLElement }
 */


class WidgetAccountSelect extends HTMLElement {
  constructor() {
    super();
    this._accountSelectStore = StoreRegistry.accountSelect;
    this._userService = userService;

    this._onInputSelectChange = this._onInputSelectChange.bind(this);

    this._accountSelectStore.setAutoSave(true);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-account-select');
    // References
    this._inputSelect = this.querySelector('sgx-input-select');
    // TODO - use sgx-input-select's label attribute once inline/horizontal label is supported
    this._label = this.querySelector('label');
  }

  static get observedAttributes() {
    return ['label', 'account-suspension-disabled', 'readonly'];
  }

  connectedCallback() {
    // Listeners
    this._inputSelect.addEventListener('change', this._onInputSelectChange);

    if (isAuthenticated()) {
      this._setInputSelectOptions();
    }
  }

  disconnectedCallback() {
    this._inputSelect.removeEventListener('change', this._onInputSelectChange);
  }

  /**
   * Getter property for sgx-input-select value property
   */
  get value() {
    return this._inputSelect.value;
  }

  get selectedOptions() {
    return this._inputSelect.selectedOptions;
  }

  /**
   * Getter and setter for readonly attribute
   */
  get readonly() {
    return this.hasAttribute('readonly');
  }

  set readonly(value) {
    if (value) {
      this.setAttribute('readonly', value);
    } else {
      this.removeAttribute('readonly');
    }
  }

  /**
   * Getter property for account-suspension-disabled attribute
   */
  get accountSuspensionDisabled() {
    return this.hasAttribute('account-suspension-disabled');
  }

  get storeName() {
    return this.getAttribute('store-name');
  }

  get usedIn() {
    return this.getAttribute('used-in');
  }

  /**
   * Setter property for account-suspension-disabled attribute
   */
  set accountSuspensionDisabled(value) {
    if (value) {
      this.setAttribute('account-suspension-disabled', '');
    } else {
      this.removeAttribute('account-suspension-disabled');
    }
  }

  attributeChangedCallback(attr, oldValue, newValue) {
    switch (attr) {
      case 'account-suspension-disabled':
        this._setInputSelectOptions();
        break;
      case 'label':
        this._setLabelContent(newValue);
        break;
      case 'readonly':
        this._setInputSelectReadonly();
        break;
    }
  }

  openInputSelect() {
    this._inputSelect.querySelector('input').click();
  }

  /**
   * Set the input select options based on the user's account info API.
   */
  _setInputSelectOptions() {
    // Requests account info using the userService if store is empty, otherwise get the accout list from store
    if (!this._accountSelectStore.getData('options')) {
      this._userService.getUserAccounts()
        .then(accounts => {
          // only get active/suspended accounts
          const allowedStatus = [ACCOUNT_STATUS.SUSPENDED, ACCOUNT_STATUS.ACTIVE];
          return (accounts || []).filter(account => !!~allowedStatus.indexOf(account.accountStatus));
        })
        .then(accounts => {
          const options = this._toInputSelectOptions(accounts);
          this._accountSelectStore.setData(accounts, 'options');
          this._inputSelect.setOptions(options, true);
          this._setInputSelectWidth(options);
        });
    } else {
      const options = this._toInputSelectOptions(this._accountSelectStore.getData('options'));
      this._inputSelect.setOptions(options, true);
      this._setInputSelectWidth(options);
    }
  }

  _setInputSelectWidth(options) {
    try {
      const maxContent = options.sort((a, b) => b.label.length - a.label.length)[0].label.length;
      this._inputSelect.style.width = `${Math.floor(maxContent * WIDTH_PER_CHARACTER)}px`;
    } catch (_) {
      // no need to set width
    }
  }

  _setLabelContent(value) {
    // TODO - set sgx-input-select's label attribute once inline/horizontal label is supported
    this._label.textContent = value || '';
  }

  _setInputSelectReadonly() {
    this._inputSelect.readOnly = this.readonly;
  }

  /**
   * Transforms the array of accounts to input select options format
   *
   * @param {Array<Object>} accounts an array of account
   * @return {Array<Object>} input select options format
   */
  _toInputSelectOptions(accounts) {
    let inactiveAccounts = [];
    const inactiveDCSAccounts = this._accountSelectStore.getData('inactiveDCSAccounts') || [];

    if (this.usedIn === 'dcs' && inactiveDCSAccounts.length) {
      inactiveAccounts = this._accountSelectStore.getData('inactiveDCSAccounts').map(({account}) => {
        return `${account.accountId}--${account.typeOfApproval}--${account.accountCategory}`
      });
    }

    return accounts.map(data => {
      const accountSuspensionDisabled = this.accountSuspensionDisabled;
      const disabled = accountSuspensionDisabled ? data.accountStatus === ACCOUNT_STATUS.SUSPENDED : false;
      const path = this._getCategoryTranslationPath(data.accountCategory, data.typeOfApproval);
      let obj = {
        label: i18n.getTranslation(path, {accountNumber: formatAccountId(data.accountId)}),
        value: data.accountId,
        disabled
      };
      if (inactiveAccounts.includes(`${data.accountId}--${data.typeOfApproval}--${data.accountCategory}`)) {
        obj.highlighted = true;
        obj.className = 'account-error';
      }

      return obj;
    });
  }

  _getCategoryTranslationPath(accountCategory, typeOfApproval) {
    let categoryKey = `${accountCategory}-${typeOfApproval}`;
    if (accountCategory === ACCOUNT_CATEGORY.INDIVIDUAL || accountCategory === ACCOUNT_CATEGORY.CORPORATE) {
      categoryKey = accountCategory;
    }

    return `widget-account-select.category.${categoryKey}`;
  }

  _onInputSelectChange() {
    if (this.storeName && StoreRegistry[this.storeName]) {
      StoreRegistry[this.storeName].setData(this.value, 'accountId');
    }

    const options = this._accountSelectStore.getData('options') || [];
    const account = options.find(option => option.accountId === this.value);

    userService.updateCDPSessionSelectedAccountType(account);

    StoreRegistry.cdpSession.setData(get(account, 'accountStatus') === ACCOUNT_STATUS.SUSPENDED, 'selectedAccountSuspended');

    this._inputSelect.message = account && account.accountStatus === ACCOUNT_STATUS.SUSPENDED ? ACCOUNT_SUSPENDED : '';

    const {accountType} = StoreRegistry.cdpSession.getData();

    this.toggleNotificationTab(options.length === 1 && [ACCOUNT_STATES.JOINT_AND].includes(accountType));
  }

  /*
  * Public method to toggle the view state of notification tab, basis argument passed
  * */
  toggleNotificationTab(hide) {
    const tabElem = document.querySelector('template-header-navs');
    const notificationTab = tabElem && tabElem.querySelector('[href="./profile/notifications"]');
    const funcName = hide ? 'add' : 'remove';

    notificationTab && notificationTab.parentElement.classList[funcName]('sgx-hidden');
  }
}

// #region Registeration of the Element

customElements.define('widget-account-select', withInitDOM(WidgetAccountSelect));

// #endregion
