import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-account-actions.html';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';
import UserService from 'services/user-service';
import ConfigService from '@sgx/sgx-config-service';

const {ACCOUNT_STATUS} = UserService.constants;

class SettingsAccountActionsWidget extends HTMLElement {
  constructor() {
    super();
    this._subscriptions = [];
    // References
    this._store = StoreRegistry.settingsAccount;
    this._storeAccountSelect = StoreRegistry.accountSelect;

    this._updateStatus = this._updateStatus.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._statusContainer = this.querySelector('.widget-user-status-container');
    this._status = this.querySelector('.widget-user-status');
    this._icon = this.querySelector('.widget-user-icon');
    this.classList.add('widget-settings-account-actions');
    this._indicator = this.querySelector('.widget-account-select-indicator');
    this._accountSelectRef = this.querySelector('.settings-account-select');
    this._router = document.querySelector('sgx-app-router');
    const {path} = this._router.getState();

    if (path === '/profile/account' && this._storeAccountSelect.getData('inactiveDCSAccounts')) {
      this._accountSelectRef.openInputSelect();
    }
  }

  connectedCallback() {

    this._store.subscribe(({accountId}) => {
      if (accountId !== this._selectedAccount) {
        this._selectedAccount = accountId;
      }
    });

  }

  _updateStatus(e) {
    this.setStatus(e.detail && e.detail.status, true);
  }

  setStatus(code, hasTokenStatus) {
    // Determine status message

    if (code) {
      // Show status message and icon
      this._statusContainer.style.display = 'flex';
    }

    switch (code) {
      case '200':
      case '201':
        this._displayStatus('success', hasTokenStatus);
        break;
      case '202':
        this._displayStatus('pending');
        break;
      case '401':
        this._displayStatus('suspended');
        break;
      case '403':
        this._displayStatus('unauthorized');
        break;
      case '500':
        this._displayStatus('failed');
        break;
    }
  }

  /**
   * Helper function to display update status.
   * @module widget-settings-user-actions
   * @type {HTMLElement}
   * @param type The type of status update
   * @param hasTokenStatus Boolean to set type of status to token
   */
  _displayStatus(type, hasTokenStatus) {

    // Reset classes to default state
    this._resetClasses();

    // Set status text and icon
    this._status.textContent = hasTokenStatus ? i18n.getTranslation(`app.widget-settings-user-actions.token.status.${type}`) : i18n.getTranslation(`app.widget-settings-user-actions.status.${type}`);
    this._icon.classList.add(`widget-user-icon--${type}`);

    // Set red text for failed states
    if (type === 'suspended' || type === 'unauthorized' || type === 'failed') {
      this._status.classList.add('widget-user-status--red');
    }

    setTimeout(() => this._statusContainer.style.display = 'none', ConfigService.env.timeout.settings);

  }

  /**
   * Helper function to reset classes of an element to its default state.
   * @module widget-settings-user-actions
   * @type {HTMLElement}
   */
  _resetClasses() {

    const elements = [
      {
        name: 'icon',
        classList: this._icon.classList
      },
      {
        name: 'status',
        classList: this._status.classList
      }
    ];

    elements.forEach(element => {
      element.classList.forEach(item => {
        if (item.startsWith(`widget-user-${element.name}--`)) {
          element.classList.remove(item);
        }
      })
    })
  }

  disconnectedCallback() {
  }
}

customElements.define('widget-settings-account-actions', withInitDOM(SettingsAccountActionsWidget));
