module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-list-row-announcement\">\n  <div class=\"cmp-list-row-announcement__header\">\n    <div\n      class=\"cmp-list-row-announcement__header-issuer cmp-list-row-announcement--ellipsis sgx-base-text-strong\"></div>\n  </div>\n\n  <div class=\"cmp-list-row-corporate-actions__footer\">\n    <!-- Type -->\n    <div class=\"col-6 cmp-list-row-announcement__footer-col\">\n      <div class=\"cmp-list-row-announcement__footer-header\"\n           data-i18n=\"cmp-list-row-corporate-actions.label.type\">\n      </div>\n      <div class=\"cmp-list-row-announcement__footer-value cmp-list-row-announcement__footer-type\"></div>\n    </div>\n    <div class=\"col-1\"></div>\n    <!-- Ex-Date -->\n    <div class=\"col-5 cmp-list-row-announcement__footer-col\">\n      <div class=\"cmp-list-row-announcement__footer-header\" data-i18n=\"cmp-list-row-corporate-actions.label.ex-date\">\n      </div>\n      <div class=\"cmp-list-row-announcement__footer-value cmp-list-row-announcement__footer-ex-date\"></div>\n    </div>\n  </div>\n\n  <div class=\"cmp-list-row-announcement__footer\">\n    <!-- Particulars -->\n    <div class=\"col-6 cmp-list-row-announcement__footer-col\">\n      <div class=\"cmp-list-row-announcement__footer-header\" data-i18n=\"cmp-list-row-corporate-actions.label.particulars\"></div>\n      <div class=\"cmp-list-row-announcement__footer-value cmp-list-row-announcement__footer-particulars\"></div>\n    </div>\n    <div class=\"col-1\"></div>\n    <!-- Announcement Date -->\n    <div class=\"col-5 cmp-list-row-announcement__footer-col\">\n      <div class=\"cmp-list-row-announcement__footer-header\" data-i18n=\"cmp-list-row-corporate-actions.label.announcement-date\">\n      </div>\n      <div class=\"cmp-list-row-announcement__footer-value cmp-list-row-announcement__footer-annc-date\"></div>\n    </div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()