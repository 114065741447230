import SgxTableHeaderBase from 'sgx-table/src/sub-components/sgx-table-header/sgx-table-header-base';
import { withInitDOM } from 'sgx-base-code';

export default class SgxTableHeaderTextDoubleRow extends SgxTableHeaderBase {
  constructor() {
    super();
    this._id = null;
    this._columnConfig = null;
  }

  initDOM() {
    this.innerHTML = `<header>
          <div class="sgx-table-header-text-double-row-label"></div>
          <div class="sgx-table-header-text-double-row-label"></div>
        </header>`;
    this._labelElements = this.querySelectorAll('.sgx-table-header-text-double-row-label');
  }

  connectedCallback() {
    this._labelElements[0].textContent = this._columnConfig.label[0] || '';
    this._labelElements[1].textContent = this._columnConfig.label[1] || '';
  }

  setConfig(id, columnConfig, tableConfig) {
    this._id = id;
    this._columnConfig = columnConfig;
    this._tableConfig = tableConfig;
  }

  setState(state) {

  }
}

customElements.define('sgx-table-header-text-double-row', withInitDOM(SgxTableHeaderTextDoubleRow));
