import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-stock-quick-search.html';
import ConfigService from '@sgx/sgx-config-service';
import sgxAnalyticsService from '@sgx/sgx-analytics-service';
import { get } from 'lodash';
import SGXInViewport from 'sgx-in-viewport';
import StoreRegistry from 'stores/store-registry';
import { fromEvent } from 'rxjs';


class WidgetStockQuickSearch extends SGXInViewport {
  constructor() {
    super();
    this._onFormSubmit = this._onFormSubmit.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-stock-quick-search');
    this._form = this.querySelector('.widget-stock-quick-search-form');
    this._input = this.querySelector('.widget-stock-quick-search-input');
    this._router = document.getElementById('sgx-app-router');
    super.initDOM();
  }

  _sendGAEvent() {
    const cdpSessionData = StoreRegistry.cdpSession;
    const { userId } = cdpSessionData.getData();
    const eventLabel = userId ? 'Stock Search - Post Login' : 'Stock Search';
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    sgxAnalyticsService.sendEvent(
      eventCategory,
      eventLabel,
      'widget-viewed',
    );
  }

  connectedCallback() {
    this._setListeners(true);
    fromEvent(this, 'onViewPortEnter').subscribe(event => this._sendGAEvent(event));
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this._form[fnName]('submit', this._onFormSubmit);
  }

  _onFormSubmit(e) {
    e.preventDefault();
    if (this._router) {
      const parentElement = this.closest('[data-analytics-category]');
      const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
      sgxAnalyticsService.sendEvent(
        eventCategory,
        `Stock Screener Search: ${this._input.value}`,
        'Stock Search'
      )
      // TODO fix redirection that refreshes the browser
      this._router.navigateToURL(`${ConfigService.links.INVESTORS_PORTAL_STOCK_SCREENER}?search=${this._input.value}`);
    }
  }
}

customElements.define('widget-stock-quick-search', withInitDOM(WidgetStockQuickSearch));
