import StoreRegistry from 'stores/store-registry';
import { CCY_DISPLAY_SUBSCRIPTION_STATUS } from 'services/ccy-service/src/ccy-service-constants';


/**
 * Organize CCY Subscription state to prepare for redering:
 *  - If the status is subscribed, return subscribe
 *  - If the status is unsubscribed, return unsubscribe
 * @param {String} ccySubscriptionState current state of CCY
 * @returns {String} updatedState to be used for render
 */

function getSubscriptionStateToRender(ccySubscriptionState) {
  const { SUBSCRIBED, PENDING_ACTIVATION, SUSPENDED, PENDING_TERMINATION, VERIFIED, PENDING_VERIFICATION } = CCY_DISPLAY_SUBSCRIPTION_STATUS;
  switch (ccySubscriptionState.replace('_', ' ')) {
    case SUBSCRIBED:
    case SUSPENDED:
    case PENDING_TERMINATION:
      return 'unsubscribe';
    case PENDING_ACTIVATION:
    case PENDING_VERIFICATION:
    case VERIFIED:
      return 'pending';
    default:
      return 'subscribe';
  }
}

/**
 * Update CDPSessionStore with bank-details:
 * @param {Number} accountNumber: Active DCS bankAccountNumber
 * @param {String} bankId: Active DCS bankId
 */
function updateCDPSessionStore({ accountNumber, bankId }) {
  const { bankAccountNumber} = StoreRegistry.cdpSession.getData();
  if (!bankAccountNumber) {
    StoreRegistry.cdpSession.setData({...StoreRegistry.cdpSession.getData(),
      bankAccountNumber: accountNumber,
      bankId
    });
  }
}

export {
  getSubscriptionStateToRender,
  updateCDPSessionStore
}
