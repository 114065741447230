module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-media-centre\">\n  <sgx-status-indicator data-i18n=\"[description]app.shared-text.status-indicator.loading.description\"></sgx-status-indicator>\n  <h3 data-i18n=\"app.widget-media-centre.title\"></h3>\n  <cmp-list-standalone class=\"widget-media-centre-container\"></cmp-list-standalone>\n  <div class=\"widget-media-centre-footer\">\n    <a data-i18n=\"app.widget-media-centre.link\" class=\"widget-media-centre-link\"></a>\n    <div class=\"widget-media-centre-count sgx-base-text-caption\"></div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()