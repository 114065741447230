import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { SimpleStore } from 'stores/simple-store';

export const PersistedStoreMode = {
  local: 'local',
  session: 'session'
};

/**
 * Persisted store using localstorage to save handled data.
 * Components can subscribe to this store using RxJS 'subscribe' methods.
 */
export class PersistedStore extends SimpleStore {
  static get STORES() {
    if (!PersistedStore._stores) {
      PersistedStore._stores = [];
    }
    return PersistedStore._stores;
  }

  /**
   * Constructor.
   *
   * @param {string} id the store unique identifier for the whole application
   * @param {string} [mode=local] the type of storage that will be use by the store whether it is localStorage or sessionStorage (refer to PersistedStoreMode for the enums)
   * @param  {...any} args BehaviorSubject arguments (init value)
   */
  constructor(id, mode, ...args) {
    super(id, ...args);
    this._autoSave = false;
    this._latestSaveTS = Date.now();
    this._mode = !PersistedStoreMode[mode] ? `${PersistedStoreMode.local}Storage` : `${PersistedStoreMode[mode]}Storage`;
    this.loadData();
  }

  /**
   * Gets the store with the queried identifier.
   *
   * @param {string} id the store identifier
   * @param {string} [mode=local] the type of storage that will be use by the store whether it is localStorage or sessionStorage (refer to PersistedStoreMode for the enums)
   * @return {PersistedStore} the persisted store unique instance for the given identifier
   */
  static get(id, mode = PersistedStoreMode.local) {
    return PersistedStore.STORES[id] || (PersistedStore.STORES[id] = new PersistedStore(id, mode));
  }

  /**
   * Turns on/off the auto-save feature on the store (off by default).
   *
   * @param {boolean} on true to turn on, false to turn off
   */
  setAutoSave(on) {
    this._autoSave = !!on;
  }

  /**
   * Updates either a chunk or the whole data in the store and notifies all subscribers.
   *
   * @param {object} data the new data
   * @param {string} path Optional: the path to the data to update
   */
  setData(data, path) {
    super.setData(data, path);
    if (this._autoSave) {
      this.saveData();
    }
  }

  /**
   * Saves the current state of the data to the local storage.
   * @param {Array} ignoredFields Optional list of field names to ignore
   * @return {Number} the timestamp for this save action
   */
  saveData(ignoredFields) {
    const safeIgnoredFields = ignoredFields || [];
    const storage = window[this._mode];
    if (!storage) {
      console.warn(`${this._mode} is not available`);
      return 0;
    }
    let dataToPersist = cloneDeep(this.data);
    safeIgnoredFields.forEach(
      ignoredField => {
        set(dataToPersist, ignoredField, null);
      }
    );
    storage.setItem(this.id, JSON.stringify(dataToPersist));
    this._latestSaveTS = Date.now();
    storage.setItem(`${this.id}--ts`, this._latestSaveTS);
    return this._latestSaveTS;
  }

  /**
   * Loads the saved data to this store if any are currently in the local storage.
   */
  loadData() {
    const storage = window[this._mode];
    if (!storage) {
      console.warn('Local storage is not available');
      return;
    }
    this.setData(JSON.parse(storage.getItem(this.id) || '{}'));
    this._latestSaveTS = (+storage.getItem(`${this.id}--ts`)) || Date.now();
  }

  /**
   * Returns the timestamp for the latest save.
   * @return {Number} the timestamp for the latest save
   */
  getLatestSaveTS() {
    return this._latestSaveTS;
  }

  /**
   * Checks if latest save is older than 30 minutes.
   * Sensitive data should be cleaned up if they are older than 30 minutes.
   * @return {Boolean} the timestamp for the latest save
   */
  isLatestSaveOlderThanThirtyMinutes() {
    return Date.now() - this.getLatestSaveTS() > 1800000;
  }

  /**
   * Clears the store.
   *
   */
  clear() {
    super.clear();
    this.saveData();
  }
}

export default PersistedStore;
