import i18n from 'sgx-localisation-service';

const DATE_FORMAT = i18n.getTranslation('app.format.date.default');

const defaultStateData = {
  'order': ['anncType', 'exDate', 'recDate', 'datePaid', 'particulars'],
  'columns': {
    'anncType': {
      'show': true
    },
    'exDate': {
      'show': true
    },
    'recDate': {
      'show': true
    },
    'datePaid': {
      'show': true
    },
    'particulars': {
      'show': true
    }
  },
  'filters': {},
  'sorts': {}
};

export default {
  'states': {
    activeStateId: 0,
    states: [
      {
        label: 'All',
        data: defaultStateData,
        editable: false
      }
    ]
  },
  'options': {
    'downloadData': false, // show download button
    'columnsConfiguration': false, // show column configure button to hide/show and reorder columns
    'filterable': false, // enable filtering
    'sortable': false, // enable sorting
    'localise': false, // enable localisation
    'reorderable': false, // enable column reordering
    'autoWidth': true, // enable table column width expanding to fit container
    'timezone': 'Asia/Singapore'
  },
  // column configuration, shared with data model
  'columns': {
    'anncType': {
      'resizable': false,
      'autoWidth': false,
      'minWidth': 100,
      'label': i18n.getTranslation('app.widget-security-prices-details.dividend.columns.type'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'filter': false,
      'sort': false
    },
    'exDate': {
      'resizable': false,
      'autoWidth': false,
      'minWidth': 100,
      'label': i18n.getTranslation('app.widget-security-prices-details.dividend.columns.ex-date'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'date',
        'format': DATE_FORMAT
      },
      'filter': false,
      'sort': false
    },
    'recDate': {
      'resizable': false,
      'minWidth': 80,
      'label': i18n.getTranslation('app.widget-security-prices-details.dividend.columns.record-date'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'date',
        'format': DATE_FORMAT
      },
      'filter': false,
      'sort': false
    },
    'datePaid': {
      'resizable': false,
      'minWidth': 80,
      'label': i18n.getTranslation('app.widget-security-prices-details.dividend.columns.payment-date'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'date',
        'format': DATE_FORMAT
      },
      'filter': false,
      'sort': false
    },
    'particulars': {
      'resizable': false,
      'minWidth': 100,
      'label': i18n.getTranslation('app.widget-security-prices-details.dividend.columns.particulars'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'link',
        'newTab': true
      },
      'filter': false,
      'sort': false
    }
  },
  'layout': {
    'desktop': {
      'recDate': 'infinite',
      'rowHeight': 32,
      'horizontalScrollStep': 96,
      'verticalScrollStep': 96,
      'scrollbar': false,
      'showAllRows': false,
      'height': 360
    },
    'mobile': {
      'recDate': 'paged',
      'cellPerRow': [3, 1],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5,
      'height': 360
    },
    'tablet': {
      'recDate': 'paged',
      'cellPerRow': [3, 1],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5,
      'height': 360
    }
  }
};
