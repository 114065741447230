import i18n from 'sgx-localisation-service';
import { withInitDOM } from 'sgx-base-code';
import tpl from './sgx-data-model-tool-filter.html';
import SgxDataModelToolBaseProto from '../../sgx-data-model-tool-base';
import { DATA_MODEL_TOOL_ICON } from '../../sgx-data-model-tool-constants';

/**
 * Filter tool.
 */
class SgxDataModelToolFilter extends SgxDataModelToolBaseProto {
  constructor() {
    super();
    this._dialogConfig = {
      title: i18n.getTranslation('data-model.tools.filter')
    };

    // Bound methods
    this._onClickCallback = this._onClickCallback.bind(this);
    this.getFiltersDialog = this.getFiltersDialog.bind(this);
    this.toggleListeners = this.toggleListeners.bind(this);
    this.showDialog = this.showDialog.bind(this);
  }

  initDOM() {
    this.classList.add('sgx-data-model-tool-filter');
    this.appendChild(tpl.getNode());

    // References
    this._tool = this.querySelector('sgx-data-model-tool');
    this._filtersDialog = this.querySelector('sgx-filters-dialog');
    if (!this['label-hidden']) {
      this._tool.setAttribute('label', i18n.getTranslation('data-model.tools.filter'));
    }
    this._tool.setAttribute('icon', DATA_MODEL_TOOL_ICON.FILTER);
  }

  static get observedAttributes() {
    return ['label-hidden'];
  }

  connectedCallback() {
    this.addEventListener('click', this._onClickCallback);
    this.toggleListeners(true);
  }

  disconnectedCallback() {
    this.toggleListeners(false);
  }

  toggleListeners(enable) {
    const method = enable ? 'addEventListener' : 'removeEventListener';
    this[method]('click', this._onClickCallback);
  }

  get 'label-hidden'() {
    return this.hasAttribute('label-hidden');
  }

  set 'label-hidden'(val) {
    if (val) {
      this.setAttribute('label-hidden', val);
      return;
    }
    this.removeAttribute('label-hidden');
  }

  attributeChangedCallback(attr, oldValue, newValue) {
    if (attr === 'label-hidden') {
      if (newValue) {
        this._tool.removeAttribute('label');
        return;
      }
      this._tool.setAttribute('label', i18n.getTranslation('data-model.tools.filter'));
    }
  }

  setConfig(config) {
    this._model = config.model;
    Object.assign(this._dialogConfig, config.dialog);
    this._filtersDialog.setConfig(config);
    this._init = true;
  }

  getTool() {
    return this._tool;
  }

  getFiltersDialog() {
    return this._filtersDialog;
  }

  showDialog() {
    this._filtersDialog.showDialog(this._dialogConfig)
      .then( _=> {
        const filters = this._filtersDialog.getFilters();
        const count = filters.getValidFilterCount().valid || 0;
        this._tool.setSelected(false);
        this._updateCount(count);
        if (count === 0) {
          this.emit('unlock');
          this.classList.remove('sgx-data-model-tool--is-active');
        }
      })
      .catch(_=> { // state is reverted back to previous
        this.emit('unlock');
        this.classList.remove('sgx-data-model-tool--is-active');
      });
  }

  _onClickCallback() {
    if (!this._init || this._tool.getDisabled()) {
      return;
    }

    if (this.classList.contains('sgx-data-model-tool--is-active')) {
      this.showDialog();
      return;
    }
    this.classList.add('sgx-data-model-tool--is-active');
    this.emit('lock', {callback: 'showDialog'});
  }

  _updateCount(count) {
    if (this._count === count) {
      return;
    }
    this._count = count;
    this._tool.setCount(count ? count : null);
  }
}

customElements.define('sgx-data-model-tool-filter', withInitDOM(SgxDataModelToolFilter));
