module.exports = (function() {
    var templateString = module.exports = "<template id=\"w-security-details-product-information\">\n  <section>\n    <h4 class=\"widget-security-details-overview--title w-security-details-product-information-termsheet sgx-base-text-title-16\"></h4>\n    <div class=\"w-security-details-product-information-list-container\">\n      <sgx-status-indicator></sgx-status-indicator>\n      <cmp-list-standalone class=\"w-security-details-product-information-list\"></cmp-list-standalone>\n      <div class=\"w-security-details-product-information-list-link-container sgx-hidden\">\n        <a href=\"#\" target=\"_blank\" class=\"w-security-details-product-information-link\" data-i18n=\"app.shared-text.view-all\"></a>\n        <span data-i18n=\"widget-security-details-product-information.latest\"></span>\n      </div>\n    </div>\n  </section>\n  <section class=\"w-security-details-product-information-issuer-section sgx-hidden\">\n    <h4 class=\"widget-security-details-overview--title sgx-base-text-title-16\" data-i18n=\"widget-security-details-product-information.section.issuer\"></h4>\n    <div class=\"w-security-details-product-information-issuer-list\">\n      <div class=\"w-security-details-product-information-issuer-item\">\n        <label class=\"sgx-input-label\" data-i18n=\"widget-security-details-product-information.issuer.name\"></label>\n        <span class=\"w-security-details-product-information-issuer--name\"></span>\n      </div>\n      <div class=\"w-security-details-product-information-issuer-item\">\n        <label class=\"sgx-input-label\" data-i18n=\"widget-security-details-product-information.issuer.website\"></label>\n        <div class=\"w-security-details-product-information-issuer--website\"></div>\n      </div>\n    </div>\n  </section>\n  <cmp-stocks-terms></cmp-stocks-terms>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()