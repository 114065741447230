import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-dcs-display.html';
import BankAccountService from 'services/bank-account-service';
import CcyService from 'services/ccy-service';
import ReferenceDataService from 'services/reference-data-service';
import StoreRegistry from 'stores/store-registry';
import DateUtil from 'utils/date-util';
import { organizeDcsAccounts, matchDcsCcy } from 'utils/bank-account-util';
import get from 'lodash/get';
import i18n from 'sgx-localisation-service';

class WidgetDcsDisplay extends HTMLElement {
  constructor() {
    super();
    this._handleCCYUpdate = this._handleCCYUpdate.bind(this);
    this._accountSelectStore = StoreRegistry.accountSelect;
    this._dcsDisplayStore = StoreRegistry.dcsDisplay;
  }

  get readonly() {
    return this.getAttribute('readonly') === '' ? true : false;
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-dcs-display');
    this._errorRow = this.querySelector('.widget-dcs-display-row-error');
    this._promptRow = this.querySelector('.widget-dcs-display-row-prompt');
    this._statusIndicator = this.querySelector('.widget-dcs-display-loader');
    this._elementToCreate = this.getAttribute('create-element');
  }

  connectedCallback() {
    this._handleCCYUpdate();
  }

  disconnectedCallback() {}

  _handleCCYUpdate() {
    StoreRegistry.cdpSession.subscribe(({ccyUpdated}) => {
      if (ccyUpdated) {
        this.setData({accountId: this._accountId});
        setTimeout(_ => {
          StoreRegistry.cdpSession.setData({...StoreRegistry.cdpSession.getData(), ccyUpdated: false})
        }, 0);
      }
    })
  }

  setData({ accountId } = {}) {
    this._accountId = accountId;

    this._statusIndicator.show();

    Promise.all([
      BankAccountService.getBankAccounts(this._accountId),
      CcyService.getStandingInstructions(this._accountId)
    ])
      .then(([dcsAccounts, standingInstructions]) => {
        this._render(dcsAccounts, standingInstructions);
      })
      .then(() => this._statusIndicator.hide())
      .catch(error => {
        console.error(error);
        this._statusIndicator.show({
          status: 'error',
          title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
          description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
        });
      });
  }

  _render(dcsAccounts = [], standingInstructions = []) {
    this._dcsDisplayStore.setData([]); // reset dcs display data
    renderListBlock: {
      let organizedDcsAccounts;

      organizedDcsAccounts = organizeDcsAccounts(dcsAccounts);
      organizedDcsAccounts = matchDcsCcy(organizedDcsAccounts, standingInstructions);

      // Render CCY Subscription Widget after data is ready. Show CCY section even there is no DCS
      if (this._elementToCreate && !this.parentElement.querySelector(this._elementToCreate)) {
        this.insertAdjacentElement('afterend', document.createElement(this._elementToCreate))
      }

      if (organizedDcsAccounts.some(dcsAccount => dcsAccount.hasErrorState)) {
        this._showErrorRow();
        break renderListBlock;
      } else {
        this._errorRow.classList.add('sgx-hidden');
      }

      // If there is no error state and no accounts, hide list and show prompt
      if (!dcsAccounts.length) {
        this.readonly
          ? this._statusIndicator.show({ status: 'error', description: i18n.getTranslation('app.message.no-data') })
          : this._showPromptRow();
        break renderListBlock;
      }

      // If DCS accounts exist and are in good standing, check if they are updatable
      const updatable = this.readonly ? false : this._calculateDcsUpdatable(organizedDcsAccounts);

      // Then, create display objects containing only the properties the row requires to render
      const displayObjects = organizedDcsAccounts.map(dcsAccount => ({
        dcs: this._calculateDcsDisplay({ dcsAccount, updatable }),
        ccy: this._calculateCcyDisplay({ ccy: dcsAccount.standingInstruction })
      }));

      // Finally, create and render the list
      this._createList(displayObjects);
    }
  }

  _showErrorRow() {
    if (this._list) {
      this._list.hide();
    }
    this._promptRow.hide();
    this._errorRow.classList.remove('sgx-hidden');
  }

  _showPromptRow() {
    if (this._list) {
      this._list.hide();
    }
    this._promptRow.classList.remove('sgx-hidden');
    this._promptRow.setConfig({
      text: i18n.getTranslation(`app.widget-dcs-display.prompts.apply.text`),
      button: i18n.getTranslation(`app.widget-dcs-display.prompts.apply.button`),
      callback: this._showDcsDialog
    });

    this._promptRow.show();
  }

  /**
   * Calculate if the update DCS button should be displayed
   * @param {Array} dcs
   */
  _calculateDcsUpdatable(dcsAccounts) {
    const { PENDING_ACTIVATION: DCS_PENDING_ACTIVATION } = BankAccountService.constants.DISPLAY_ACCOUNT_STATUS;
    const {
      PENDING_ACTIVATION: CCY_PENDING_ACTIVATION,
      PENDING_TERMINATION: CCY_PENDING_TERMINATION
    } = CcyService.constants.CCY_DISPLAY_INSTRUCTION_STATUS;

    // If any DCS or CCY statuses are pending, DCS is not updatable
    const updatable = !dcsAccounts.some(dcsAccount => {
      const dcsDisplayStatus = dcsAccount.displayStatus;
      const ccyDisplayStatus = dcsAccount.standingInstruction && dcsAccount.standingInstruction.displayStatus;
      const pendingDcs = dcsDisplayStatus === DCS_PENDING_ACTIVATION;
      const pendingCcy = (ccyDisplayStatus === CCY_PENDING_ACTIVATION) || (ccyDisplayStatus === CCY_PENDING_TERMINATION);
      return pendingDcs || pendingCcy;
    });

    return updatable;
  }

  _getDcsLogo(bankId) {
    return ReferenceDataService.getBank(bankId)
      .then(bank => ({
        src: get(bank, 'data.logo.data.data.image.url'),
        alt: get(bank, 'data.logo.data.data.image.alt')
      }))
      .catch(error => console.error(error));
  }

  /**
   * Calculate the display properties that describe the DCS account
   * @param {Object} object
   * @param {Object} object.dcsAccount the DCS account to describe
   * @param {Boolean} object.updatable whether the DCS is updatable or not
   */
  _calculateDcsDisplay({ dcsAccount, updatable }) {
    const status = dcsAccount.displayStatus;
    const color = BankAccountService.getDisplayStatusColor(status);
    const date = this._formatDate(dcsAccount[`${BankAccountService.getDisplayStatusDate(status)}`]);
    this._calculateInfoColumnWidth(dcsAccount);
    return {
      accountNumber: dcsAccount.accountNumber,
      bankId: dcsAccount.bankId,
      logo: this._getDcsLogo(dcsAccount.bankId),
      status,
      color,
      updatable,
      date
    };
  }

  /**
   * Calculate the display properties that describe the CCY standing instruction
   * @param {Object} ccy the CCY standing instruction to describe
   */
  _calculateCcyDisplay({ ccy = {} } = {}) {
    const status = ccy.displayStatus;
    return {
      status,
      color: CcyService.getDisplayStatusColor(status),
      date: this._formatDate(ccy[`${CcyService.getDisplayStatusDate(status)}`])
    };
  }

  _formatDate(rawDate) {
    const since = i18n.getTranslation('app.widget-dcs-display.labels.since');
    const formattedDate = DateUtil.formatDateTo(rawDate, BankAccountService.constants.ISO_DATE_FORMAT, i18n.getTranslation('app.format.date.default'));
    return rawDate ? `${since} ${formattedDate}` : '';
  }

  _calculateInfoColumnWidth(dcs) {
    try {
      if (!this._widestInfoColumnWidth) {
        this._widestInfoColumnWidth = 100; // Set initial value
      }
      const chars = dcs.accountNumber.length;
      const width = +chars * 10;
      if (width > this._widestInfoColumnWidth) {
        this._widestInfoColumnWidth = width;
      }
    } catch (error) {
      // Do nothing
    }
  }

  _createList(dcsAccounts) {
    if (!this._list) {
      this._list = document.createElement('cmp-list-standalone');
      this._list.setConfig({
        rowElementName: 'cmp-list-row-account-bank',
        rowElementClasses: ['widget-dcs-display-row']
      });
      this.appendChild(this._list);
    }

    // Ensure all info columns are the same width for display
    const dcsAccountsForDisplay = dcsAccounts.map(dcsAccount => {
      dcsAccount.dcs.infoColumnWidth = this._widestInfoColumnWidth;
      return dcsAccount;
    });

    this._dcsDisplayStore.setData(dcsAccountsForDisplay);
    this._list.setData(dcsAccountsForDisplay);
    this._list.show();
    this._promptRow.hide();
  }

  _showDcsDialog() {
    this.dispatchEvent(new CustomEvent('show-dcs-dialog', { bubbles: true, detail: { autoCcy: true } }));
  }
}

customElements.define('widget-dcs-display', withInitDOM(WidgetDcsDisplay));
