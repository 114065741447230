import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-notifications-email.html';
import {
  PTS_CDP_HARD_COPY_STATUS,
  PTS_HARD_COPY_STATUS,
  PSN_SOFT_COPY_STATUS
} from '../../services/user-preferences-service/src/user-preferences-service-constants';

/**
 * Email notifications settings widget.
 */
class NotificationsEmailWidget extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-notifications-email');
    this._emailNotificationsFieldset = this.querySelector('.widget-notifications-email-fieldset');
    this._title = this.querySelector('.widget-notifications-email-title');
    this._accountStatementsInput = this.querySelector('.widget-notifications-email-account');
    this._cdpNotificationsInput = this.querySelector('.widget-notifications-email-cdp');
    // TODO: Hide PSN for email as this is a future implementation on the PTS side
    this._preSettlementNotificationsInput = this.querySelector('.widget-notifications-email-settlement');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  setData(preferences) {
    if (!!preferences) {
      this._accountStatementsInput.setValue(preferences.acctStatementNotificationOption !== PTS_HARD_COPY_STATUS.UNCHECKED_SUB_HC);
      this._cdpNotificationsInput.setValue(preferences.confirmationNotificationOption !== PTS_CDP_HARD_COPY_STATUS.UNCHECKED_SUB_HC);
      this._preSettlementNotificationsInput.setValue(preferences.psnEmailNotificationOption && preferences.psnEmailNotificationOption === PSN_SOFT_COPY_STATUS.CHECKED_SUB_SC);
    }
  }
}

customElements.define('widget-settings-notifications-email', withInitDOM(NotificationsEmailWidget));
