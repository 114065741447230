
import { URLUtils, FetchUtils } from '@sgx/sgx-base-code';
import BaseService from 'services/base-service';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';
import { USER_TYPES } from 'services/user-service/src/user-service-constants';

let instance = null;

/**
 * Myinfo service responsible for myinfo related operations,
 * particulars
 * @module myinfo-service
 */
class MyInfoService extends BaseService{
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    this._myInfoStore = StoreRegistry.myInfoSession;
    return instance;
  }

  /**
   * Get myinfo user particulars.
   * @module myinfo-service
   * @param {String} authCode myinfo authCode
   * @param {Boolean} isRegisterFin identifier if it's from 'Register Fin' / 'Update Particulars with MyInfo'
   * @param {String} routerState if specified, updates the redirectUri if routerState is either dashboard/portfolio
   * @return {Promise<Object>} Returns an object containing user particulars.
   */
  getParticulars(authCode, isRegisterFin, routerState) {
    // return Promise.resolve('eyJhbGciOiJFUzI1NiIsImtpZCI6IkdXd3lpcGlvV0pqSUdadVZmS2ZHQWhjSTZnbVNSaEk3ZktJaUFWYzkxbE0iLCJ0eXBlIjoiSldUIn0.eyJlbWFpbCI6eyJjbGFzc2lmaWNhdGlvbiI6IkMiLCJsYXN0dXBkYXRlZCI6IjIwMjQtMDQtMjQiLCJzb3VyY2UiOiI0IiwidmFsdWUiOiJteWluZm90ZXN0aW5nQGdtYWlsLmNvbSJ9LCJleHAiOjE3MTc1NTczOTgsImlhdCI6MTcxNzU1NjQ5OCwiaXNzIjoiYXBpNS5xYXNneC5jb20vbXlpbmZvIiwibW9iaWxlbm8iOnsiYXJlYWNvZGUiOnsidmFsdWUiOiI2NSJ9LCJjbGFzc2lmaWNhdGlvbiI6IkMiLCJsYXN0dXBkYXRlZCI6IjIwMjQtMDQtMjQiLCJuYnIiOnsidmFsdWUiOiI5NzM5OTI0NSJ9LCJwcmVmaXgiOnsidmFsdWUiOiIrIn0sInNvdXJjZSI6IjQifSwidWluZmluIjp7ImNsYXNzaWZpY2F0aW9uIjoiQyIsImxhc3R1cGRhdGVkIjoiMjAyNC0wNC0yNCIsInNvdXJjZSI6IjEiLCJ2YWx1ZSI6IlM5OTEyMzYzWiJ9fQ.ZaPcjJhOA1Pjy_bVuapd8b2zp06oN5YK7hF9qHI6jwY2dtoiibX2nhorLaca_Asle_mWH5uGHYCNOC0ww_RZPg');
    const { idType, issuingCountry } = StoreRegistry.cdpSession.getData();
    const {fullList = true} = StoreRegistry.user.getData();

    const isForeigner = (idType == USER_TYPES.FOREIGNER)  || (idType == USER_TYPES.SC_PR && issuingCountry !== 'SG');
    const configKey = isRegisterFin ? 'fin' : 'particulars';

    const finKey = StoreRegistry.appContext.getData('webview') ? 'fin-webview' : 'fin';
    let myInfoRedirectKey = isRegisterFin ? finKey : 'particulars';

    if (['portfolio', 'dashboard'].includes(routerState) && myInfoRedirectKey === 'particulars') {
      myInfoRedirectKey = routerState;
    }

    const redirectUri = ConfigService.env['myinfo-redirect-uri'][myInfoRedirectKey];
    let attributesKey = !isRegisterFin && isForeigner ? `${configKey}-foreigner` : configKey;
    if(!fullList){
      attributesKey = 'particulars-short';
    }

    if (['fin', 'fin-webview'].includes(myInfoRedirectKey)) {
      attributesKey = 'register_fin';
    }

    const attributes = ConfigService.env['myinfo-attributes'][attributesKey];
    const clientAlias = ConfigService.env['myinfo-client-alias'][configKey];
    const clientId = ConfigService.env['myinfo-clientId'][configKey];
    const code_challenge = this._myInfoStore.getData('code_challenge');
    const code_challenge_method = this._myInfoStore.getData('code_challenge_method');


    let url = URLUtils.setQueryParams(ConfigService.endpoints.MY_INFO_USER, {
      redirect_uri: redirectUri,
      auth_code: authCode,
      attributes,
      client_alias: clientAlias,
      client_id: clientId,
      code_challenge,
      code_challenge_method
    });

    return FetchUtils.fetchJSON(url, {method: 'GET'})
      .then(response => {return response.data.personDetails || []})
      .catch(error => Promise.reject(error));
  }

  /**
   *
   * @return {Promise<Object>} Returns an object containing {purpose_id, code_challenge, code_challenge_method}.
   * */
  getSession(isRegisterFin) {
    const configKey = isRegisterFin ? 'fin' : 'particulars';

    const clientAlias = ConfigService.env['myinfo-client-alias'][configKey];
    let url = URLUtils.setQueryParams(ConfigService.endpoints.MY_INFO_SESSION, {
      client_alias: clientAlias,
    });

    return FetchUtils.fetchJSON(url, {
      method: 'GET'
    })
      .then(response => {
        const {code_challenge, code_challenge_method} = response.data;
        this._myInfoStore.setData(code_challenge, 'code_challenge');
        this._myInfoStore.setData(code_challenge_method, 'code_challenge_method');

        return response.data
      })
      .catch(error => Promise.reject(error));
  }
}


export default new MyInfoService();
