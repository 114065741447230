/**
 * @module auth-service
 * @desc Investors authentication provider for login and transaction signing.
 */
import MetaAuthService from './meta';
import ConfigService from 'sgx-config-service';
import SgxAnalyticsService from 'sgx-analytics-service';
import BaseService from 'services/base-service';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';
import FeatureToggleUtil from 'utils/feature-toggle-util';
import LocalSecurityUtil from './utils/local-security-auth-util';
import {
  encryptPasswordForUas,
  encryptPasswordForChangePasswordUAS
} from './utils/crypto-auth-util';
import { errorMessage } from './utils/error-auth-util';
import { shapeSerialNumber } from './utils/format-auth-util';
import { FetchUtils } from 'sgx-base-code';
import { USER_ID_TYPE } from './auth-service-constants';
import { clearUserSession } from 'utils/auth-util';
import { LOGIN_SOURCE } from 'services/singpass-services/singpass-service-constants.js';

let instance = null;

class AppLoginService extends BaseService {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }

    this.changePassword = this.changePassword.bind(this);

    return instance;
  }

  get endpoints() {
    return {
      status: ConfigService.endpoints.AUTH_STATUS_READ,
      singpass: ConfigService.endpoints.AUTH_SINGPASS_SUBMIT,
      singpassSession: ConfigService.endpoints.AUTH_SINGPASS_SESSION,
      login: ConfigService.endpoints.AUTH_1FA_SUBMIT,
      logout: ConfigService.endpoints.AUTH_LOGOUT,
      requestToken: ConfigService.endpoints.AUTH_SMS_TOKEN_READ,
      submitToken: ConfigService.endpoints.AUTH_2FA_SUBMIT,
      forgotPassword: ConfigService.endpoints.AUTH_ANONYMOUS_SMS_TOKEN_READ,
      forgotPasswordCorporate: ConfigService.endpoints.AUTH_ANONYMOUS_SMS_TOKEN_CORPORATE_READ,
      changePassword: ConfigService.endpoints.AUTH_CHANGE_PASSWORD_UPDATE, // For use in the main app
      resetPassword: ConfigService.endpoints.AUTH_RESET_PASSWORD_SUBMIT, // For use in the login page
      forgotUserId: ConfigService.endpoints.AUTH_FORGOT_USER_ID_SUBMIT,
      registerUserId: ConfigService.endpoints.AUTH_REGISTER_USER_ID_SUBMIT,
      createUserId: ConfigService.endpoints.AUTH_CREATE_USER_ID_SUBMIT,
      updateTokenPreference: ConfigService.endpoints.USER_PARTICULARS_PREF_OTP_UPDATE,
      verifyUser: ConfigService.endpoints.AUTH_VERIFY_USER_SUBMIT,
      verifyUserCorporate: ConfigService.endpoints.AUTH_VERIFY_USER_CORPORATE_SUBMIT,
      verifyPassword: ConfigService.endpoints.AUTH_VERIFY_PASSWORD_SUBMIT,
      linkToken: ConfigService.endpoints.AUTH_LINK_TOKEN,
      linkTokenFirstTIme: ConfigService.endpoints.AUTH_LINK_TOKEN_FIRST_TIME_UPDATE,
      preAuthenticate: ConfigService.endpoints.PRE_AUTHENTICATE
    }
  }

  get OLD_PASSWORD() {
    return this.oldPassword;
  }

  get NEW_PASSWORD() {
    return this.newPassword;
  }

  set OLD_PASSWORD(oldPassword) {
    this.oldPassword = oldPassword;
  }

  set NEW_PASSWORD(newPassword) {
    this.newPassword = newPassword;
  }

  _preAuthenticate() {
    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.preAuthenticate, {
        method: 'POST'
      }).then(response => {
        resolve(response.json());
      }).catch((err) => {
        console.error(err);
        reject('sgx-login.error.uas.tokenFailed')
      })
    })
  }

  /**
   * Ping the server to get the system status (up or down).
   * @returns {Promise}
   */
  ping() {
    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.status, { headers: this.HEADERS })
        .then(response => {
          response ? resolve('up') : reject('down');
        })
        .catch(() => {
          reject('sgx-login.error.ping.failed');
        });
    });
  }

  /**
   * Send token from SingPass to our endpoint to start a session.
   * @param {String} authorizationCode token returned from SingPass
   * @param {String} state state used in SingPass initialization
   * @param {String} redirectUri redirect uri used in SingPass initialization
   * @param {Object} headers additional request headers
   * @param {Function} errCallback pass this callback to called on click of button in the status-indicator
   */
  loginWithSingPass({ authorizationCode = '', state = '', redirectUri = '', headers = {}, errCallback }) {
    const loginSource = StoreRegistry.singpassRedirect.getData('loginSource');
    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.singpass, {
        method: 'POST',
        headers: {
          ...headers,
          ...this.HEADERS
        },
        body: JSON.stringify({
          authorizationCode,
          state,
          redirectUri,
          loginSource
        })
      })
        .then(response => {
          const sessionToken = response.headers.get(this._requestHeaders.authToken);
          return response.json()
            .then(({ data }) => {
              if (!sessionToken) {
                console.warn('Failed to get session token.');
                reject('sgx-login.error.default');
              } else {
                sessionStorage.clear();
                StoreRegistry.cdpSession.setData({
                  ...data,
                  singpassAccountAvailable: true,
                  token: sessionToken
                });
                StoreRegistry.permissions.setData('2FA'); // SingPass is equivalent to 2FA
                try {
                  FeatureToggleUtil.isGoogleAnalyticsEnabled()
                    .then(enabled => {
                      try {
                        if (enabled) {
                          const userId = LocalSecurityUtil.sha256Hash(data.userId);
                          const {webview} = StoreRegistry.appContext.getData();
                          const pageView = webview ? 'loggedin-singpass-mobile-app' : 'loggedin-singpass';
                          const pageViewLoginSource = loginSource === LOGIN_SOURCE.SINGPASS ? `${pageView}--redirection` : `${pageView}--qr`;
                          SgxAnalyticsService.set('userId', userId);
                          SgxAnalyticsService.sendPageView(pageView);
                          SgxAnalyticsService.sendPageView(pageViewLoginSource);
                          SgxAnalyticsService.sendEvent(pageView, 'authenticated', 'authentication');
                          StoreRegistry.cdpSession.setData({
                            ...StoreRegistry.cdpSession.getData(),
                            hashedUserID: userId
                          });
                        }
                      } finally {
                        setTimeout(() => {
                          resolve();
                        }, 100);
                      }
                    });
                } catch(err) {
                  resolve();
                }
              }
            })
            .catch(() => {
              reject('sgx-login.error.default');
            });
        })
        .catch(error => {
          const status = error.status;
          reject({message: errorMessage({method: 'loginWithSingPass', status}), callback: errCallback});
      });
    });
  }

  /**
   * Login to the application with 1FA.
   * @param {String} username
   * @param {String} password
   * @param {String} loginType the account type of the user, individual or corporate
   * @returns {Promise}
   */
  async login(username = '', password = '', loginType = '') {
    try {
      let authCodes = await this._preAuthenticate();
      authCodes = authCodes.data;

      try {
        let response  = await FetchUtils.fetch(this.endpoints.login, {
          method: 'POST',
          headers: this.HEADERS,
          body: JSON.stringify({
            userId: username.toUpperCase(), // CDP requires uppercase
            challengeToken: authCodes.challengeToken,
            uasPassword: encryptPasswordForUas(authCodes, password),
            loginType: loginType.toUpperCase() // CDP requires uppercase
          })
        });
        const sessionToken = response.headers.get(this._requestHeaders.authToken);
        return response.json().then(({ data }) => {
          if (!sessionToken) {
            console.warn('Failed to get session token.');
            throw 'sgx-login.error.default';
          } else {
            const { resetPasswordRequired, resetUserIdRequired } = data;
            sessionStorage.clear();
            StoreRegistry.cdpSession.setData({
              ...data,
              token: sessionToken,
              ds3AccountAvailable: true
            });
            StoreRegistry.permissions.setData('1FA');

            if (resetPasswordRequired) {
              return { target: 'change-password', data: { resetUserIdRequired }};
            }
            if (resetUserIdRequired) {
              return { target: 'create-user-id' };
            }

            return { target: '2FA', data };
          }
        });
      } catch({status}) {
        throw errorMessage({ method: 'login', status, accountType: loginType });
      }

    }
    catch(err) {
      throw err;
    }
  }

  /**
   * Log out of the remote session.
   * @returns {Promise}
   */
  logout() {
    const requestOpts = {
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: StoreRegistry.cdpSession.getData().token
      }
    };
    return FetchUtils.fetch(this.endpoints.logout, requestOpts)
      .then(_ => clearUserSession())
      .catch(_ => clearUserSession());
  }

  /**
   * Request an SMS or OneKey one time password (OTP) token.
   * @returns {Promise}
   */
  requestToken() {
    const requestOpts = {
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: StoreRegistry.cdpSession.getData().token
      }
    };

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.requestToken, requestOpts)
        .then(response => {
          return response.json().then(response => {
            const { maskedMobileNumber } = response.data;
            resolve({
              success: true,
              maskedMobileNumber
            });
          });
        })
        .catch(error => {
          const status = error.status;
          error.json()
            .then(response => {
              const reason = response.errors[0].reason;
              switch(reason) {
                case 'TOO_MANY_TOKENS':
                  reject(errorMessage({ method: 'requestToken', reason }));
                  break;
                case 'NO_MEDIA_DESTINATION':
                  reject(i18n.getTranslation('sgx-login.error.requestToken.reason.no-media-destination', {
                    link: ConfigService.links.SGX_V2_UPDATE_PARTICULARS
                  }));
                  break;
                case 'MAX_SMS_OTP_ATTEMPTS_EXCEEDED':
                  reject(i18n.getTranslation('sgx-login.error.requestToken.reason.max-sms-attempts-exceeded'));
                  break;
                default:
                  reject(errorMessage({ method: 'requestToken', status }));
              }
            })
            .catch(() => {
              reject(errorMessage({ method: 'requestToken', status }));
            })
        });
    });
  }

  /**
   * Submit an SMS or Onekey one time password (OTP) token.
   * @param {String} token OTP token to be sent to the backend
   * @param {String} action type of action being performed
   * @param {String} type of submit i.e sms or token
   * @returns {Promise}
   */
  submitToken(token, action = 'login', type) {
    const session = StoreRegistry.cdpSession.getData();
    const requestOpts = {
      method: 'POST',
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: session.token,
        [this._requestHeaders.authOtp]: token
      }
    };

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.submitToken, requestOpts)
        .then(response => {
          const upgradedSessionToken = response.headers.get(this._requestHeaders.authToken);
          if (!upgradedSessionToken) {
            console.warn('Failed to get upgraded session token.');
            reject('sgx-login.error.submitToken.400');
          } else {
            const session = StoreRegistry.cdpSession.getData();
            StoreRegistry.cdpSession.setData({
              ...session,
              token: upgradedSessionToken
            });
            StoreRegistry.permissions.setData('2FA');
            try {
              FeatureToggleUtil.isGoogleAnalyticsEnabled()
                .then(enabled => {
                  try {
                    if (enabled) {
                      const userId = LocalSecurityUtil.sha256Hash(session.userId);
                      const {webview} = StoreRegistry.appContext.getData();
                      const pageView = webview ? 'loggedin-cdp-mobile-app' : 'loggedin-cdp';
                      SgxAnalyticsService.set('userId', userId);
                      SgxAnalyticsService.sendPageView(pageView);
                      SgxAnalyticsService.sendEvent(pageView, 'authenticated', 'authentication');

                      StoreRegistry.cdpSession.setData({
                        ...StoreRegistry.cdpSession.getData(),
                        hashedUserID: userId
                      });
                    }
                  } finally {
                    setTimeout(() => {
                      resolve(action);
                    }, 100);
                  }
                });
            } catch(err) {
              resolve(action);
            }
          }
        })
        .catch(error => {
          const status = error.status;
          error.json()
            .then(response => {
              const reason = response.errors[0].reason;
              switch(reason) {
                case 'TWO_FA_LOCKOUT':
                  reject(errorMessage({ method: 'submitToken', reason }));
                  break;
                case 'NO_MEDIA_DESTINATION':
                  reject(i18n.getTranslation('sgx-login.error.submitToken.reason.no-media-destination', {
                    link: ConfigService.links.SGX_V2_UPDATE_PARTICULARS
                  }));
                  break;
                default:
                  reject(errorMessage({ method: 'submitToken', status }));
              }
            })
            .catch(() => {
              reject(errorMessage({ method: 'submitToken', status }));
            })
        });
    });
  }

  /**
   * Update the token preference for a given user.
   * @param {string} tokenPreference the token preference, either 'SMS' or 'ONEKEY_TOKEN'
   */
  updateTokenPreference(tokenPreference = 'SMS') {
    const session = StoreRegistry.cdpSession.getData();
    const requestOpts = {
      method: 'PUT',
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: session.token,
        [this._requestHeaders.userId]: session.userId
      },
      body: JSON.stringify({
        option: tokenPreference
      })
    };

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.updateTokenPreference, requestOpts)
        .then(() => {
          resolve({ success: true });
        })
        .catch(({ status }) => {
          reject(errorMessage({ method: 'updateTokenPreference', status }));
        });
    });
  }

  /**
   * Link Onekey token to User ID.
   * @param {string} serial Onekey device serial number
   * @param {string} username
   * @param {string} otp Onekey token
   * @param {boolean} isFirstTime flag for first time 2FAs
   * @return {Promise}
   */
  linkNAFToken(serial, username, otp, isFirstTime) {
    const session = StoreRegistry.cdpSession.getData();
    let requestOpts = {
      method: 'PUT',
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: session.token
      },
      body: JSON.stringify({
        nafUserName: username,
        otp,
        serialNumber: shapeSerialNumber(serial)
      })
    };
    let endpoint = this.endpoints.linkToken;

    if (isFirstTime) {
      endpoint = this.endpoints.linkTokenFirstTIme;
      requestOpts.method = 'POST';
    }

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(endpoint, requestOpts)
        .then(response => {
          if (isFirstTime) {
            const upgradedSessionToken = response.headers.get(this._requestHeaders.authToken);
            if (!upgradedSessionToken) {
              console.warn('Failed to get upgraded session token.');
              reject('sgx-login.error.default');
            } else {
              const session = StoreRegistry.cdpSession.getData();
              StoreRegistry.cdpSession.setData({
                ...session,
                token: upgradedSessionToken
              });
              StoreRegistry.permissions.setData('2FA');
              resolve({ success: true });
            }
          } else {
            resolve({ success: true });
          }
        })
        .catch(error => {
          const status = error.status;
          error.json()
            .then(response => {
              const reason = response.errors[0].reason;
              switch(reason) {
                case 'INVALID_OTP':
                case 'INVALID_TOKEN_SERIAL_NUMBER':
                case 'TOKEN_ALREADY_LINKED':
                  reject(errorMessage({ method: 'linkNAFToken', reason }));
                  break;
                default:
                  reject(errorMessage({ method: 'linkNAFToken', status }));
              }
            })
            .catch(() => {
              reject(errorMessage({ method: 'linkNAFToken', status }));
            });
        });
    });
  }

  /**
   * Get the OneKey token info associated with the User ID.
   * @return {Promise}
   */
  getNAFTokenInfo() {
    const { token } = StoreRegistry.cdpSession.getData();
    const requestOpts = {
      method: 'GET',
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: token
      }
    };

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.linkToken, requestOpts)
        .then(response => {
          return response.json().then(({ data }) => {
            StoreRegistry.cdpSession.setData(data, 'hardTokenInfo');
            resolve(data);
          });
        })
        .catch(({ status }) => {
          reject(errorMessage({ method: 'getNAFTokenInfo', status }));
        });
    });
  }

  /**
   * Initiate forgot password flow.
   * @param {Object} object The object passed as an argument
   * @param {string} object.accountType The type of account (e.g. individual or corporate)
   * @param {Object} object.payload The payload JSON to send to the backend
   * @param {string} [object.payload.idType] (Individual) The id type (e.g. nric, passport)
   * @param {string} [object.payload.id] (Individual) The id number (e.g. nric number, passport number)
   * @param {string} [object.payload.idIssuingCountry] (Individual) The two letter issuing country code (e.g. SG)
   * @param {string} [object.payload.cdpAccountNumber] (Corporate) The cdp account number
   * @param {string} [object.payload.companyRegistrationNumber] (Corporate) The company's registration number
   * @returns {Promise}
   */
  forgotPassword({ accountType = 'individual', payload = {} }) {
    // Reset token preference flag so no token preference checkboxes are rendered in forgot password flows
    StoreRegistry.cdpSession.setData(null, 'pref2FaSms');

    let requestOpts = {
      method: 'POST',
      headers: this.HEADERS
    };

    let endpoint = this.endpoints.forgotPassword;
    let modifiedPayload = { ...payload };

    if (accountType === 'individual') {
      modifiedPayload.idType = USER_ID_TYPE[modifiedPayload.idType];
    }

    if (accountType === 'corporate') {
      endpoint = this.endpoints.forgotPasswordCorporate;
    }

    requestOpts.body = JSON.stringify(modifiedPayload);

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(endpoint, requestOpts)
        .then(response => {
          return response.json().then(response => {
            const { maskedMobileNumber } = response.data;
            resolve({
              success: true,
              maskedMobileNumber
            });
          });
        })
        .catch(error => {
          const status = error.status;
          error.json()
            .then(response => {
              const reason = response.errors[0].reason;
              switch(reason) {
                case 'MAX_SMS_OTP_ATTEMPTS_EXCEEDED':
                  reject(i18n.getTranslation('sgx-login.error.requestToken.reason.max-sms-attempts-exceeded'));
                  break;
                case 'NO_AUTH_ACCOUNT':
                  // resolve the promise and handle the error in sgx-login-triplets
                  resolve({ success: false, reason });
                  break;
                default:
                  reject(errorMessage({ method: 'forgotPassword', status }));
              }
            })
            .catch(() => {
              reject(errorMessage({ method: 'forgotPassword', status }));
            });
        });
    });
  }

  /**
   * Verify a user before resetting their password.
   * @param {Object} triplets The payload JSON to send to the backend
   * @param {string} [triplets.idType] (Individual) The id type (e.g. nric, passport)
   * @param {string} [triplets.id] (Individual) The id number (e.g. nric number, passport number)
   * @param {string} [triplets.idIssuingCountry] (Individual) The two letter issuing country code (e.g. SG)
   * @param {string} otp the token
   * @returns {Promise}
   */
  verifyUser(triplets, otp, accountType) {
    let requestOpts = {
      method: 'POST',
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authOtp]: otp,
        [this._requestHeaders.transactionType]: 'RESET_PASSWORD'
      }
    };

    let endpoint = this.endpoints.verifyUser;
    let modifiedPayload = { ...triplets };

    if (accountType === 'individual') {
      modifiedPayload.idType = USER_ID_TYPE[modifiedPayload.idType];
    }

    if (accountType === 'corporate') {
      endpoint = this.endpoints.verifyUserCorporate;
    }

    requestOpts.body = JSON.stringify(modifiedPayload);

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(endpoint, requestOpts)
        .then(response => {
          const sessionToken = response.headers.get(this._requestHeaders.authToken);
          return response.json().then(response => {
            if (!sessionToken) {
              console.warn('Failed to get session token.');
              reject('sgx-login.error.login.400');
            } else {
              StoreRegistry.cdpSession.setData({
                ...response.data,
                token: sessionToken
              });
              resolve({ success: true });
            }
          });
        })
        .catch(({ status }) => {
          reject(errorMessage({ method: 'verifyUser', status }));
        });
    });
  }

  verifyUserToCreatePassword(triplets, otp, accountType) {
    return this.verifyUser(triplets, otp, accountType);
  }

  /**
   * Reset password from the login page.
   * @param {string} password the new password to reset to
   * @returns {Promise}
   */
  async resetPassword(password) {
    let authCodes = await this._preAuthenticate();
    authCodes = authCodes.data;

    const requestOpts = {
      method: 'PUT',
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: StoreRegistry.cdpSession.getData().token
      },
      body: JSON.stringify({
        password: encryptPasswordForUas(authCodes, password),
        challengeToken: authCodes.challengeToken,
      })
    };

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.resetPassword, requestOpts)
        .then(() => {
          resolve({ success: true });
        })
        .catch(({ status }) => {
          reject(errorMessage({ method: 'resetPassword', status }));
        });
    });
  }

  /**
   * Request an email with instructions to retrieve User ID.
   * @param {Object} object The object passed as an argument
   * @param {string} object.accountType The type of account (e.g. individual or corporate)
   * @param {Object} object.payload The payload JSON to send to the backend
   * @param {string} [object.payload.idType] (Individual) The id type (e.g. nric, passport)
   * @param {string} [object.payload.id] (Individual) The id number (e.g. nric number, passport number)
   * @param {string} [object.payload.idIssuingCountry] (Individual) The two letter issuing country code (e.g. SG)
   * @returns {Promise}
   */
  forgotUserId({ accountType = '', payload = {} }) {
    let requestOpts = {
      method: 'POST',
      headers: this.HEADERS
    };

    if (accountType === 'individual') {
      payload.idType = USER_ID_TYPE[payload.idType];
    }

    requestOpts.body = JSON.stringify(payload);

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.forgotUserId, requestOpts)
        .then(() => {
          resolve({ success: true });
        })
        .catch(error => {
          const status = error.status;
          error.json()
            .then(response => {
              const reason = response.errors[0].reason;
              switch(reason) {
                case 'NO_AUTH_ACCOUNT':
                  // resolve the promise and handle the error in sgx-login-triplets
                  resolve({ success: false, reason });
                  break;
                default:
                  reject(errorMessage({ method: 'forgotUserId', status }));
              }
            })
            .catch(() => {
              reject(errorMessage({ method: 'forgotUserId', status }));
            });
        });
    });
  }

  /**
   * Request an email with instructions to register User ID.
   * @param {Object} object The object passed as an argument
   * @param {Object} object.payload The payload JSON to send to the backend
   * @param {string} [object.payload.idType] (Individual) The id type (e.g. nric, passport)
   * @param {string} [object.payload.id] (Individual) The id number (e.g. nric number, passport number)
   * @param {string} [object.payload.idIssuingCountry] (Individual) The two letter issuing country code (e.g. SG)
   * @returns {Promise}
   */
  registerUserId({ payload = {} }) {
    let requestOpts = {
      method: 'POST',
      headers: this.HEADERS
    };
    payload.idType = USER_ID_TYPE[payload.idType];
    requestOpts.body = JSON.stringify(payload);

    return new Promise((resolve, reject) => {
      const params = { link: ConfigService.links.SGX_V2_UPDATE_PARTICULARS };
      return FetchUtils.fetch(this.endpoints.registerUserId, requestOpts)
        .then(response => response.json().then(response => {
          const { maskedEmailAddress } = response.data;
          resolve({
            success: true,
            maskedEmailAddress
          });
        }))
        .catch((error) => {
          const {status} = error;
          error.json()
            .then(response => {
              const reason = response.errors[0].reason;
              reject(errorMessage({ method: 'registerUserId', status, reason, params }));
            })
        });
    });
  }

  /**
   * Create a User ID if user is required to.
   * @param {string} newUserId preferred user ID
   * @param {string} otp the token
   * @returns {Promise}
   */
  createUserId(newUserId, otp) {
    let requestOpts = {
      method: 'PUT',
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: StoreRegistry.cdpSession.getData().token,
        [this._requestHeaders.authOtp]: otp
      },
      body: JSON.stringify({ newUserId })
    };

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.createUserId, requestOpts)
        .then(() => {
          resolve({ success: true });
        })
        .catch(error => {
          const status = error.status;
          error.json()
            .then(response => {
              const reason = response.errors[0].reason;
              switch(reason) {
                case 'USER_ID_EXIST':
                case 'USER_ID_INVALID':
                  reject(errorMessage({ method: 'createUserId', reason }));
                  break;
                default:
                  reject(errorMessage({ method: 'createUserId', status }));
              }
            })
            .catch(() => {
              reject(errorMessage({ method: 'createUserId', status }));
            });
        });
    });
  }

  /**
   * Verifies the password of the logged-in user.
   * @param {string} password the password to verify
   * @returns {Promise}
   */
  async verifyPassword(password) {
    const session = StoreRegistry.cdpSession.getData();
    let authCodes = await this._preAuthenticate();
    authCodes = authCodes.data;
    const requestOpts = {
      method: 'POST',
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: session.token
      },
      body: JSON.stringify({
        'password': encryptPasswordForUas(authCodes, password),
        'challengeToken': authCodes.challengeToken
      })
    };

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.verifyPassword, requestOpts)
        .then(response => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Makes password references of the user for use in other methods.
   * @param {string} newPassword
   * @param {string} oldPassword
   */
  updatePassword(newPassword, oldPassword) {
    this.NEW_PASSWORD = newPassword;
    this.OLD_PASSWORD = oldPassword;
  }

  /**
   * Changes the password of the logged-in user.
   * @param {string} otp the one time password from transaction signing
   * @param {string} fetchText boolean to get read headers from the response
   * @returns {Promise}
   */
  async changePassword(otp, fetchText) {
    const session = StoreRegistry.cdpSession.getData();
    const oldPassword = this.OLD_PASSWORD;
    const newPassword = this.NEW_PASSWORD;
    let authCodes = await this._preAuthenticate();
    authCodes = authCodes.data;

    const requestOpts = {
      method: 'PUT',
      headers: {
        ...this.HEADERS,
        [this._requestHeaders.authToken]: session.token,
        [this._requestHeaders.authOtp]: otp
      },
      body: JSON.stringify({
        'password': encryptPasswordForChangePasswordUAS({ authCodes, oldPassword, newPassword }),
        'challengeToken': authCodes.challengeToken
      })
    };

    if (fetchText) {
      return FetchUtils.fetchText(this.endpoints.changePassword, requestOpts);
    }

    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.changePassword, requestOpts)
        .then(response => {
          resolve(response.json());
        })
        .catch(({ status }) => {
          reject(errorMessage({ method: 'changePassword', status }));
        });
    });
  }

  /**
   * Initiate an anonymous session to get state and nonce.
   */
  getSingpassSession() {
    return new Promise((resolve, reject) => {
      return FetchUtils.fetch(this.endpoints.singpassSession, {
        method: 'POST',
        headers: this.HEADERS
      })
        .then(response => {
          const authToken = response.headers.get(ConfigService.request.headers.authToken);
          return response.json()
            .then(responseBody => {
              const data = responseBody.data || {};
              const { state, nonce } = data;
              return resolve({ authToken, state, nonce });
            });
        })
        .catch(() => reject(errorMessage({ method: 'loginWithSingPass', status: 500 })));
    });
  }

  /**
   * Resets the password references to the default state.
   */
  resetPasswordState() {
    this.OLD_PASSWORD = '';
    this.NEW_PASSWORD = '';
  }
}

export default new AppLoginService();
