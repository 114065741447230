import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-company-announcements.html';
import { FILTER_CONFIG, FILTER_STATE } from './widget-company-announcements-filter-config';
import i18n from 'sgx-localisation-service';
import DeviceService from 'sgx-device-service';
import AnncService from 'sgx-announcements-service';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Dashboard page template.
 */
class WidgetCompanyAnnouncements extends HTMLElement {
  constructor() {
    super();
    // References
    this._router = document.getElementById('sgx-app-router');
    this._filterButton = null;
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-company-announcements-page');
    this._listDetails = this.querySelector('.sgx-list-details');
    this._filterContainer = this.querySelector('#template-company-announcements-filter-container');
  }

  connectedCallback() {
    this._attached = true;
  }

  disconnectedCallback() {
    this._attached = null;
  }

  setData(data, params) {
    this._routesBound = !!data.pageData.routesBound;
    this._requestedAnnouncement = params.annc;
    this._setConfig(params); // retrieve model after config has been processed
    const model = this._listDetails.getList().getDataModel();
    // Setup needed for filter dialog
    this._filterButton.setConfig({filter: FILTER_CONFIG, model});

    // waiting to process only once, the event type: 'update' and event action: 'getDataRange'
    // as it ensures search results have been retrieved and processed by the underlying data model
    fromEvent(model, 'update')
      .pipe(take(2))
      .subscribe((this._renderDetails.bind(this, model)));
  }

  _setConfig(config) {
    const initState = this._getInitStateFilters(config);
    this._listDetails.setConfig({
      'toolbar': {
        'rows': [
          {
            'layout': 'default',
            'tools': [
              {
                'element': 'sgx-data-model-tool-filter',
                'onAttach': elem => {
                  this._filterButton = elem;
                },
                'onDetach': _ => {
                  this._filterButton = null;
                }
              }
            ]
          },
          {
            'layout': 'custom',
            'customToolbarLayout': new Promise((resolve, reject) => {
              const toolbarFragment = document.createDocumentFragment();

              const customToolbar = document.createElement('div');
              customToolbar.classList.add('sgx-list-details-list-toolbar-custom');
              customToolbar.innerHTML = `
                <div class="sgx-data-model-tool-display-container">
                  <sgx-data-model-tool-display-count></sgx-data-model-tool-display-count>
                  <sgx-data-model-tool-display-latest-update></sgx-data-model-tool-display-latest-update>
                </div>
              `;

              toolbarFragment.appendChild(customToolbar);

              if (toolbarFragment.hasChildNodes) {
                resolve(toolbarFragment);
              } else {
                reject(Error('Could not create custom toolbar'));
              }
            }),
            'onAttach': () => {
              const displayCountTool = this.querySelector('sgx-data-model-tool-display-count');
              const displayLatestUpdateTool = this.querySelector('sgx-data-model-tool-display-latest-update');

              const customTools = [displayCountTool, displayLatestUpdateTool];
              customTools.forEach(tool => {
                tool.setConfig({
                  model: this._listDetails.getList().getDataModel(),
                  isExternal: true
                });
              });
            }
          }
        ]
      },
      'list': {
        'view': {
          'type': 'infinite',
          'currentPage': config.page ? config.page - 1 : null,
          'pageSize': 30,
          'rowHeight': 100,
          'rowElementName': 'cmp-list-row-announcement',
          'selectionMode': 'single',
          'emptyMsg': i18n.getTranslation('app.shared-text.status-indicator.empty.description'),
          'errorMsg': i18n.getTranslation('app.shared-text.status-indicator.error.description'),
          'offsets': 7,
          'offsetsSmall': 7
        },
        initState,
        'model': 'cmp-data-model-announcements',
        'modelLazyLoad': {
          'pageSize': 250,
          'threshold': 0.9
        }
      },
      'details': {
        'resolveSelectedData': selectedRowData => {
          // Update route
          if (this._routesBound && !this._requestedAnnouncement) {
            this._router.navigateWithoutTransition({
              annc: selectedRowData.id
            });
          }
          if (!!this._requestedAnnouncement) {
            this._requestedAnnouncement = null;
          }
          if (!selectedRowData) {
            return Promise.reject(null);
          }
          selectedRowData.src = selectedRowData.url;
          return Promise.resolve(selectedRowData);
        },
        'resolveSelectedLoadingTitle': () => i18n.getTranslation('app.securities.loading-security'),
        'resolveSelectedTitleForMobile': resolvedSelectedData => resolvedSelectedData && resolvedSelectedData.title || null
      }
    });
    FILTER_CONFIG.columns.cat.options.forEach(option => {
      option.label = i18n.getTranslation(option.label);
    });
  }



  /**
   * @desc Update the filters passed to init state in list-details config
   * @params {Object} config = {value: ""} | value (company-name)s
   * @returns initState
   * */
  _getInitStateFilters(config = {value: '', securityCode: ''}) {
    const initState = {
      filters: FILTER_STATE
    };

    initState.filters.conditions = FILTER_STATE.conditions.map(condition => {

      switch (condition.columnId) {
        case 'name':
          condition.value = config.value || null;
          break;
        case 'securityCode':
          condition.value = config.securityCode || null;
          break;
      }

      return condition;
    });

    return initState;

  }

  _renderDetails(model, evt) {
    // this._listDetails.setFilters(FILTER_STATE);
    const showDetails = true;
    const isRefresh = false;
    if (!(evt.detail && evt.detail.action === 'getDataRange')) {
      return;
    }
    if (this._requestedAnnouncement) {
      model.getRowData(this._requestedAnnouncement).then(rowData => {
        if (this._attached) {
          if (rowData) {
            this._listDetails.setDetails(rowData);
          } else {
            AnncService.getAnnouncementById(this._requestedAnnouncement).then(announcement => {
              this._listDetails.setDetails(announcement);
            });
          }
        }
      });
    } else if (!this._requestedAnnouncement && DeviceService.isDesktop()) {
      model.getRowDataByIndex(0).then(data => {
        if (this._attached && data && data.id) {
          model.getRowData(data.id).then(rowData => this._listDetails.setDetails(rowData, !isRefresh, showDetails));
        }
      });
    }
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'flex';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

customElements.define('widget-company-announcements', withInitDOM(WidgetCompanyAnnouncements));
