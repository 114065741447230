import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DateService from 'sgx-date-time-service';
import PortfolioService from 'services/portfolio-service';
import DeviceService from 'sgx-device-service';
import PrintService from 'services/print-service';
import ExchangeRateService from 'services/exchange-rate-service';
import PortfolioAggregator from 'aggregators/portfolio-aggregator';
import tmpl from './widget-portfolio-overview.html';
import { Big } from 'utils/big-number-util';
import { addCustomToolbar } from 'utils/table-util';
import { download } from 'utils/pdf-util';
import { mapToChartSeriesData } from 'utils/portfolio-util';
import {
  getChartConfig, blbTableConfig, sblTableConfig, exportFormatThousands,
  blbDefaultStateData, constants } from './widget-portfolio-overview-config';

const { FULL_DATE_FORMAT, EXPORT_NAME, MARKET_VALUE_SUB_TOTAL, PROFIT_LOSS_SUB_TOTAL,
  DEFAULT_TABLE_HEIGHT, TABLE_TOOLBAR_AND_HEADER_HEIGHT, TABLE_ROW_HEIGHT, EMPTY_INDICATOR_TITLE } = constants;

class WidgetPortfolioOverview extends HTMLElement {
  constructor() {
    super();
    // Bound methods
    this._onUnitCostColumnClick = this._onUnitCostColumnClick.bind(this);
    this._onBlbTableModelUpdate = this._onBlbTableModelUpdate.bind(this);
    this._onBlbDownloadClick = this._onBlbDownloadClick.bind(this);
    this._onBlbPrintClick = this._onBlbPrintClick.bind(this);
    this._onSlbDownloadClick = this._onSlbDownloadClick.bind(this);
    this._onSblPrintClick = this._onSblPrintClick.bind(this);
    this._onOrientationChange = this._onOrientationChange.bind(this);
    this._onTouch = this._onTouch.bind(this);
    this._onBlbStateChanged = this._onBlbStateChanged.bind(this);
  }

  initDOM() {
    this.classList.add('widget-portfolio-overview');
    this.appendChild(tmpl.getNode());
    // References
    this._unitCostDialog = this.querySelector('cmp-portfolio-unit-cost-dialog');
    this._productChart = this.querySelector('.account-overview-details-product-chart');
    this._sectorChart = this.querySelector('.account-overview-details-sector-chart');
    this._productIndicator = this.querySelector('.account-overview-details-product-indicator');
    this._sectorIndicator = this.querySelector('.account-overview-details-sector-indicator');
    this._productChartContainer = this.querySelector('.account-overview-details-product-chart-container');
    this._sectorChartContainer = this.querySelector('.account-overview-details-sector-chart-container');
    this._blbTable = this.querySelector('.blb-table');
    this._sblTable = this.querySelector('.sbl-table');
    this._totalProfitLoss = this.querySelector('#total-profit-loss-value');
    this._totalMarketValue = this.querySelector('#total-market-value');
    this._tableSummary = this.querySelector('.blb-table-summary');
    this._errorState = this.querySelector('.portfolio-account-no-data');
    this._portfolioAccountContainer = this.querySelector('.account-overview-details');

    addCustomToolbar(this._blbTable, 'widget-portfolio-overview-blb-toolbar');
    addCustomToolbar(this._sblTable, 'widget-portfolio-overview-sbl-toolbar');

    // get the reference once toolbar has been attached to the table
    this._blbDownload = this._blbTable.querySelector('sgx-data-model-tool[icon="download"]');
    this._blbPrint = this._blbTable.querySelector('sgx-data-model-tool[icon="print"]');
    this._sblDownload = this._sblTable.querySelector('sgx-data-model-tool[icon="download"]');
    this._sblPrint = this._sblTable.querySelector('sgx-data-model-tool[icon="print"]');
  }

  connectedCallback() {
    // Set table config
    this._blbTable.setConfig(blbTableConfig);
    this._sblTable.setConfig(sblTableConfig);

    this._setListeners(true);
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';

    this._blbTable[fnName]('ROW_INPUT_BUTTON_CLICKED', this._onUnitCostColumnClick);
    this._blbTable.getDataModel()[fnName]('update', this._onBlbTableModelUpdate);
    this._blbDownload[fnName]('click', this._onBlbDownloadClick);
    this._blbPrint[fnName]('click', this._onBlbPrintClick);
    this._sblDownload[fnName]('click', this._onSlbDownloadClick);
    this._sblPrint[fnName]('click', this._onSblPrintClick);
    window[fnName]('orientationchange', this._onOrientationChange);
    // hack for iOS browsers to scroll
    this._sectorChartContainer[fnName]('touchstart', this._onTouch);
    this._sectorChartContainer[fnName]('touchend', this._onTouch);
    this._productChartContainer[fnName]('touchstart', this._onTouch)
    this._productChartContainer[fnName]('touchend', this._onTouch)

    this._blbTable.addEventListener(this._blbTable.Events.SGX_TABLE_STATE_CHANGED, this._onBlbStateChanged);
  }

  _onBlbStateChanged(evt) {
    const sorts = evt.target.getSorts();
    if (evt.detail['change-type'] !== evt.target.Events.SGX_TABLE_SORTS_CHANGED) {
      return;
    }
    if (Object.keys(sorts).length === 0) {
      evt.target.setSorts({
        'currency': 'asc',
        'security': 'asc'
      });
    } else if (sorts['currency'] && !sorts['security']) {
      sorts['security'] = 'asc';
      evt.target.setSorts(sorts);
    }
  }

  // hack for iOS browsers to scroll properly after clicking on high stocks
  _onTouch() {
    this._productChart.style.overflow =  this._productChart.style.overflow === 'visible' ? 'hidden' : 'visible';
    this._sectorChart.style.overflow =  this._sectorChart.style.overflow === 'visible' ? 'hidden' : 'visible';
  }

  _onOrientationChange() {
    setTimeout(_ => {
      this._productChart.getChartInstance().reflow();
      this._sectorChart.getChartInstance().reflow();
    },200);
  }

  _onBlbDownloadClick() {
    this._blbTable.setExportDataFileName(`${EXPORT_NAME.PORTFOLIO} ${this._accountIdFormatted} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`);
    this._blbTable.exportData();
  }

  _onSlbDownloadClick() {
    this._sblTable.setExportDataFileName(`${EXPORT_NAME.SBL} ${this._accountIdFormatted} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`);
    this._sblTable.exportData();
  }

  _onBlbPrintClick() {
    const getPdfFieldsForState = (state, currencySort) => this._blbTable.getDataModel().getDataForState(state)
      .then(({ keys, values }) => {
        const lastIndex = keys.length - 1;
        const currencies = i18n.getTranslation(`app.shared-text.currency`,{ returnObjects: true });
        let totalProfitLoss = Big(0);
        let totalMarketValue = Big(0);
        let profitNanCtr = 0;
        let marketValueNanCtr = 0;

        return Object.keys(keys).map((key, index) => {
          const sortedKey = keys[key];
          const {securityName, stockCode, currency, profit, corporateActions} = values[sortedKey];
          const currencyDesc = currencies[currency.label] || '';
          let {marketPrice, unitCost, marketValue, blockedBalance, freeBalance, totalBalance} = values[sortedKey];
          let corporateActionsString = '';
          corporateActions.forEach(item => {
            corporateActionsString += `${item.label}; `
          });

          if (!corporateActions.length) {
            corporateActionsString = 'N/A';
          }
          let fields = {
            securityName,
            hpCode: stockCode,
            freeBalance: exportFormatThousands(freeBalance, 0, true),
            pendingBalance: exportFormatThousands(blockedBalance, 0, true),
            totalStr: exportFormatThousands(totalBalance, 0, true),
            marketPriceStr: exportFormatThousands(marketPrice, 3, true),
            unitCostStr: (!!+unitCost || unitCost === 0) && exportFormatThousands(unitCost, 3, true, '') || '',
            marketValueStr: exportFormatThousands(marketValue, 2, true),
            profitOrLossStr: exportFormatThousands(profit, 2, true),
            currencyCodeStr: `${currencyDesc} (${currency.label})`,
            jasperBlockIsShow: true,
            currencySort,
            chargeBalanceStr: '', // TODO: Need to confirm where to map chargeBalance or if it is needed
            corporateActionEvent: corporateActionsString
          };

          totalMarketValue = totalMarketValue.plus(isNaN(marketValue) ? 0 : marketValue);
          totalProfitLoss = totalProfitLoss.plus(isNaN(profit) ? 0 : profit);

          if (isNaN(marketValue)) {
            marketValueNanCtr++;
          }

          if (isNaN(profit)) {
            profitNanCtr++;
          }

          if (lastIndex === index) {
            fields.marketValue = (marketValueNanCtr === lastIndex + 1) ? 'N/A' : exportFormatThousands(totalMarketValue, 2, true);
            fields.profitOrLoss = (profitNanCtr === lastIndex + 1) ? 'N/A' :exportFormatThousands(totalProfitLoss, 2, true);
          }

          return fields;
        });
      });

    Promise.all(
      this._blbTable.getState().states.filter((state) => {
        return state.data.filters.currency; // filter out the 'All' tab state as it contains the superset of the other currencies
      }).map((state, index) => {
        const currencySort = (index + 1).toString();
        const stateData = { filters: state.data.filters, sorts: this._blbTable.getSorts() };
        return getPdfFieldsForState(stateData, currencySort);
      })
    )
      .then(values => values.reduce((acc, cv) => {
          acc.push(...cv);
          return acc;
        }, [])
      )
      .then(fields => {
        const accountNoDesc = this._accountDesc;
        const body = {
          qualifiedName: 'securityHoldings',
          params: {accountNoDesc},
          fields
        };
        PrintService.getPdf(body)
          .then(response => download(response, `${constants.EXPORT_NAME.PORTFOLIO} ${this._accountIdFormatted} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`));
      });
  }

  _onSblPrintClick() {
    this._sblTable.getDataModel().getCurrentStateData()
      .then(({ keys, values }) => {
        const accountNoDesc = this._accountDesc;
        const fields = Object.keys(keys).map(key => {
          const { securityName, stockCode, effectiveDate, qty, status, lenderRate } = values[keys[key]];
          return {
            securityName,
            securityCode: stockCode,
            effectiveDateStr: effectiveDate && DateService(effectiveDate).format(FULL_DATE_FORMAT) || 'N/A',
            quantityStr: exportFormatThousands(qty, 0, true),
            lenderRate: exportFormatThousands(lenderRate, 2, true),
            status: status
          };
        });
        const body = {
          qualifiedName: 'securityOnLoan',
          params: { accountNoDesc },
          fields
        };

        PrintService.getPdf(body)
          .then(base64 => download(base64, `${constants.EXPORT_NAME.SBL} ${this._accountIdFormatted} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`));
      });
  }

  /**
   * Shows unit cost dialog whenever unitCost column data is clicked. Also triggers the
   * calculation of profit & loss whenever the user clicks on the calculate button of the dialog.
   *
   * @param {CustomEvent} event ROW_INPUT_BUTTON_CLICKED custom event
   * @param {Object} event.detail contains the rowId and colId of the row data that was clicked
   * @return {Array} transformed chart data series format
   */
  _onUnitCostColumnClick({ detail }) {
    const { rowId, colId } = { ...detail };
    this._blbTable.getDataModel().getRowData(rowId).then(data => {
      const { marketPrice, totalBalance, stockCode: securityCode } = data;
      this._unitCostDialog.showDialog({
        title: i18n.getTranslation('app.widget-portfolio-overview.dialog.title'),
        data: data[colId]
      })
        .then(unitCost => {
          let profit = PortfolioAggregator.calculateProfitLoss(marketPrice, totalBalance, unitCost);
          profit = profit === '' ? 'N/A' : profit;
          if (!isNaN(unitCost)) {
            PortfolioService.updatePortfolioCost(this._accountId, {
              securityCode,
              unitPrice: unitCost
            })
              .catch(_ => {
                // TODO - notify user that error occured when updating unit cost?
                // revert to previous state
                this._blbTable.updateData({
                  id: rowId,
                  values: {
                    ...data
                  },
                  type: 'update'
                });
                this._calculateTotalProfitLoss();
              });
          }
          this._blbTable.updateData({
            id: rowId,
            values: {
              ...data,
              unitCost,
              profit
            },
            type: 'update'
          });
          this._calculateTotalProfitLoss();
        })
        .catch(e => console.log(e));
    });
  }

  _onBlbTableModelUpdate({ detail }) {
    const { action } = detail;
    if(action === 'setState') {
      this._selectedCcy = detail.result.filters.currency && detail.result.filters.currency.value || null;
    } else if (action === 'getDataRange') {
      this._setBlbTableTotalSummary();
    }
  }

  _createBlbSummaryItem(label, value, valueClass) {
    const summaryItem = document.createElement('div');
    summaryItem.classList.add('blb-table-summary-item');
    const subTotalLabel = document.createElement('label');
    subTotalLabel.classList.add('sgx-base-text-body-bold-14');
    subTotalLabel.textContent = label;
    const subTotalValue = document.createElement('span');
    subTotalValue.classList.add('sgx-base-text-body-14');
    subTotalValue.classList.add(valueClass);
    subTotalValue.textContent = value;
    summaryItem.appendChild(subTotalLabel);
    summaryItem.appendChild(subTotalValue);
    return summaryItem;
  }

  _setBlbTableTotalSummary() {
    Promise.all(this._getBlbStatesData())
      .then(fragments => {
        const summaryFragment = fragments.reduce((aggregator, fragment) => {
          aggregator.appendChild(fragment);
          return aggregator;
        }, document.createDocumentFragment());
        this._tableSummary.innerHTML = '';
        this._tableSummary.appendChild(summaryFragment);
      })
      .catch(e => console.error(e));
  }

  _getBlbStatesData() {
    return this._blbTable.getState().states.reduce((aggregator, state) => {
      if(!state.data.filters.currency || ( this._selectedCcy && this._selectedCcy !== state.data.filters.currency.value ) ) {
        return aggregator; // compute currency for each tab except 'All' tab, or a specific tab
      }
      aggregator.push(this._getSummaryRow(state.data));
      return aggregator;
    }, []);
  }

  _getSummaryRow(stateData) {
    return this._blbTable.getDataModel().getDataForState(stateData).then(data => {
      const investments = data.values && Object.keys(data.values).map(key => data.values[key]) || [];
      let totalProfitLoss = Big(0);
      let totalMarketValue = Big(0);
      let profitNanCtr = 0;
      let marketValueNanCtr = 0;
      let currency;

      investments.forEach(investment => {
        const { profit, marketValue } = investment;
        totalProfitLoss = totalProfitLoss.plus(isNaN(profit) ? 0 : profit);
        totalMarketValue = totalMarketValue.plus(isNaN(marketValue) ? 0 : marketValue);

        if (isNaN(marketValue)) {
          marketValueNanCtr++;
        }

        if (isNaN(profit)) {
          profitNanCtr++;
        }
        currency = investment.currency.label;
      });
      let profitLoss = 'N/A';
      let marketValue = 'N/A';
      if (currency) {
        const investmentCount = investments.length;
        profitLoss = (profitNanCtr === investmentCount) ? 'N/A' : `${totalProfitLoss.toFormat(2)}`;
        marketValue = (marketValueNanCtr === investmentCount) ? 'N/A' : `${totalMarketValue.toFormat(2)}`;
      }

      const fragment = document.createDocumentFragment();
      fragment.appendChild(this._createBlbSummaryItem(MARKET_VALUE_SUB_TOTAL + ` (${currency || 'N/A'}):`, marketValue, 'market-value'));
      fragment.appendChild(this._createBlbSummaryItem(PROFIT_LOSS_SUB_TOTAL + ` (${currency || 'N/A'}):`, profitLoss, 'profit-loss'));
      return fragment;
    });
  }

  /**
   * Sums up the 'profit & loss' and 'market value' for the 'Account Overview' and 'Investment Details'
   */
  _calculateTotalProfitLoss() {
    this._blbTable.getDataModel().getAllData().then(data => {
      let totalProfitLoss = Big(0);
      let totalMarketValue = Big(0);
      for (let key in data.values) {
        const { currency, profit, marketValue } = data.values[key];
        const currencyCode = currency && currency.label || '';
        const p = isNaN(profit) ? 0 : profit;
        const m = isNaN(marketValue) ? 0 : marketValue;
        // Convert profit and marketValue to SGD
        const rate = this._rates[currencyCode] || 1;
        const convertedProfit = Big(p || 0).times(rate);
        const convertedMarketValue = Big(m || 0).times(rate);

        totalProfitLoss = totalProfitLoss.plus(convertedProfit);
        totalMarketValue = totalMarketValue.plus(convertedMarketValue);
      }
      this._formatValueToCurrency(this._totalProfitLoss, totalProfitLoss);
      this._formatValueToCurrency(this._totalMarketValue, totalMarketValue);
    });

    this._setBlbTableTotalSummary();
  }

  /**
   * Sets the data of product and sector chart, calculates total balance of _blbTable and sets total investments value
   */
  _setAccountOverviewData() {
    const products = {};
    const sectors = {};
    let totalMarketValue = Big(0);

    this._blbTable.getDataModel().getAllData().then(({ values }) => {
      for (const key in Object.keys(values)) {
        const item = values[key];
        const { currency } = item;
        const rate = this._rates[currency.label] || 1;
        let marketValue = Big(isNaN(item.marketValue) ? 0 : item.marketValue).times(rate);

        if (marketValue) {
          let { productCode, sectorCode } = item;
          productCode = (!productCode || productCode === '*') ? 'others' : productCode;
          sectorCode = (!sectorCode || sectorCode === '*') ? 'others' : sectorCode;

          totalMarketValue = totalMarketValue.plus(marketValue);

          products[productCode] = Big(products[productCode] || 0).plus(marketValue);
          sectors[sectorCode] = Big(sectors[sectorCode] || 0).plus(marketValue);
        }
      }

      const productSeries = mapToChartSeriesData(products, totalMarketValue);
      const sectorSeries = mapToChartSeriesData(sectors, totalMarketValue);

      this._productChart.setData(getChartConfig(productSeries, i18n.getTranslation('app.widget-portfolio-overview.chart.product')));
      this._sectorChart.setData(getChartConfig(sectorSeries, i18n.getTranslation('app.widget-portfolio-overview.chart.sector')));

      if (!productSeries.length && !sectorSeries.length) {
        this._toggleErrorState(true);
      } else {
        this._shouldShowChartIndicator(this._productIndicator, productSeries);
        this._shouldShowChartIndicator(this._sectorIndicator, sectorSeries);
      }
    });
  }

  _toggleErrorState(state) {
    this._portfolioAccountContainer.classList[state ? 'add' : 'remove']('sgx-hidden');
    this._errorState.classList[state ? 'remove' : 'add']('sgx-hidden');
  }


  _shouldShowChartIndicator(indicator, data) {
    if (!data.length) {
      indicator.show({ title: EMPTY_INDICATOR_TITLE, status: 'neutral' });
      return;
    }
    indicator.hide();
  }

  /**
   * Sets the target element's value
   *
   * @param {HTMLElement} element target element
   * @param {number} value
   */
  _formatValueToCurrency(element, value) {
    const total = i18n.getTranslation('app.widget-portfolio-overview.currency-format', { value: value.toFormat(2) });
    element.value = total;
  }

  setData({ accountId, accountDesc, accountIdFormatted }) {
    this._accountId = accountId;
    this._accountDesc = accountDesc;
    this._accountIdFormatted = accountIdFormatted;

    this._toggleErrorState(false);
    this._setInvestmentDetailsAndOverview();
    this._setSblTable();
  }

  _getExchangeRate(currencies) {
    return ExchangeRateService.getExhangeRatesToSgd(currencies)
      .then(exchangeRates => {
        return exchangeRates.reduce((rates, { source, rate }) => {
          rates[source] = rate;
          return rates;
        }, {});
      });
  }

  _getDistinctCurrencies(investments) {
    return investments.map(investment => investment.currency.label)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort(function(a,b) { // sort currencies ascending with SGD always first
        if(a ==='SGD') {
          return -1;
        } else if ( b === 'SGD') {
          return 1;
        }
        return a < b ? -1 : 1;
      });
  }

  _getBlbTableState(currencies) {
    let states = currencies.map((currency) => ({
      label: '',
      data: {
        ...blbDefaultStateData,
        filters: {
          currency: {
            type: 'text',
            value: currency,
            method: 'equals',
            valueKey: 'label'
          }
        }
      },
      editable: false
    }));

    if(currencies.length > 1) { // display all tab
      states.unshift({
        label: '',
        data: {
          ...blbDefaultStateData,
          filters: {}
        },
        editable: false
      });
    }

    if (!currencies.length) {
      states.push({
        label: '',
        data: {
          ...blbDefaultStateData,
          filters: {
            currency: {
              type: 'text',
              value: '',
              method: 'equals',
              valueKey: 'label'
            }
          }
        },
        editable: false
      });
    }

    return {
      activeStateId: 0,
      states
    };
  }

  _setInvestmentDetailsAndOverview() {
    // Applying loading masks
    const overviewLoader = this.querySelector('.account-overview-details-loader');
    const overviewBlbLoader = this.querySelector('.account-overview-blb-loader');
    const overviewSblLoader = this.querySelector('.account-overview-sbl-loader');

    overviewLoader.show({status: 'loading'});
    overviewBlbLoader.show({status: 'loading'});
    overviewSblLoader.show({status: 'loading'});

    let requestedAccountId = this._accountId;
    PortfolioAggregator.getInvestmentDetails(requestedAccountId)
      .then(response => {
        if (requestedAccountId !== this._accountId) {
          // Account changed during request processing
          overviewLoader.hide();
          overviewBlbLoader.hide();
          overviewSblLoader.hide();
          return;
        }
        const investments  = response.map(investment => {
          investment.currency = {
            label: investment.currency,
            order: investment.currency === 'SGD' ? '1' : investment.currency
          };
          return investment;
        });
        const currencies = this._getDistinctCurrencies(investments);
        const blbTableState = this._getBlbTableState(currencies);

        this._blbTable.setState(blbTableState);

        if(DeviceService.isDesktop()) {
          this._blbTable.style.height = investments.length && investments.length > 5 ? `${(investments.length * TABLE_ROW_HEIGHT) + TABLE_TOOLBAR_AND_HEADER_HEIGHT}px` : DEFAULT_TABLE_HEIGHT;
        }
        this._blbTable.setData(investments);
        this._blbTable.recalculateSize();
        return this._getExchangeRate(currencies);
      })
      .then(rates => {
        if (!rates) {
          // Account changed during request processing
          return;
        }
        this._rates = rates;
        this._setAccountOverviewData();
        this._calculateTotalProfitLoss();
        overviewLoader.hide();
        overviewBlbLoader.hide();
      })
      .catch(_ => {
        overviewLoader.show({status: 'error'});
        overviewBlbLoader.show({status: 'error'});
      });
  }

  _setSblTable() {
    const overviewSblLoader = this.querySelector('.account-overview-sbl-loader');
    PortfolioAggregator.getSecuritiesLoanAndIntransit(this._accountId)
      .then(sbl => {
        if(DeviceService.isDesktop()) {
          this._sblTable.style.height = sbl.length && sbl.length > 5 ? `${(sbl.length * TABLE_ROW_HEIGHT) + TABLE_TOOLBAR_AND_HEADER_HEIGHT}px` : DEFAULT_TABLE_HEIGHT;
        }
        this._sblTable.setData(sbl);
        this._sblTable.recalculateSize();
        overviewSblLoader.hide();
      })
      .catch(_ => {
        overviewSblLoader.show({status: 'error'});
      });
  }
}

customElements.define('widget-portfolio-overview', withInitDOM(WidgetPortfolioOverview));
