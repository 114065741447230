/**
 * Base for sgx-service-bus compliant services.
 * @property {String} _topic required topic for use by sgx-service-bus
 */
class BaseBusService {

  activate(bus) {
    bus.startTopic(this._topic);
  }

  deactivate(bus) {
    bus.endTopic(this._topic);
  }
}

export {
  BaseBusService
}
