import { withInitDOM } from 'sgx-base-code';
import tmpl from './template-settings-account.html';
import i18n from 'sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import { toggleAuthenticatedFeature } from '../../utils/auth-util';
import userService from 'services/user-service';
import { ACCOUNT_STATUS, ACCOUNT_STATES } from 'services/user-service/src/user-service-constants';
import { isAuthenticated } from 'utils/auth-util';
import ConfigService from 'sgx-config-service';
import StopPaperService from 'services/stop-paper-service'

class TemplateSettingsAccount extends HTMLElement {
  constructor() {
    super();
    this._subscriptions = [];
    this._store = StoreRegistry.settingsAccount;
    this._cdpStore = StoreRegistry.cdpSession;

    this._handleUpdateDCS = this._handleUpdateDCS.bind(this);
    this.updateDisabledState = this.updateDisabledState.bind(this);
    this.setAccountActionState = this.setAccountActionState.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-settings-account');
    this._container = this.querySelector('.container-fluid--full-width');
    this._bankAccountWidget = this._container.querySelector('.widget-settings-account-bank');
    this._tradingAccountWidget = this._container.querySelector('.widget-settings-account-trading');
    this._accountActionsWidget = this._container.querySelector('.widget-settings-account-actions');
    this._corporateActionsWidget = this._container.querySelector('.widget-ca-standing-instructions');
    this._loginPrompt = this.querySelector('cmp-login-prompt');
    this._loginPromptText = this.querySelector('.cmp-login-prompt-text');
    this._stopPaperWidget = this._container.querySelector('.widget-stop-paper');
  }

  _setListeners(funcName) {
    this._bankAccountWidget[funcName]('update-dcs', this._handleUpdateDCS);
    this[funcName]('readonly-state', _ => this.updateDisabledState);
  }

  updateDisabledState(disable = true) {
    // TODO - Figure out why in Safari (iOS and macOS) the sgx-dialog inside
    // widget-dcs-dialog is getting moved to a child of the <body> tag AFTER
    // calling this.querySelectorAll('button') to disable buttons. What results
    // is in Safari, buttons in the dialog were getting disabled inadvertently.

    // Select specifically the buttons to disable
    const buttons = [
      '.widget-ccy-subscription-update-btn', // CCY subscription button
      '.cmp-list-row-account-state-button' // DCS update button
    ];

    const toggleButtons = (buttons, disable) => {
      buttons.forEach(button => {
        const buttonElem = this.querySelector(button);
        const fnName = `${disable ? 'set' : 'remove'}Attribute`;
        if (buttonElem) {
          buttonElem[fnName]('disabled', disable);
        }
      });
      const dcsPromptRow = this.querySelector('.widget-dcs-display-row-prompt');
      const fnName = `${disable ? 'disable' : 'enable'}`;
      if (dcsPromptRow && dcsPromptRow.disable && typeof dcsPromptRow.disable === 'function') {
        dcsPromptRow[fnName]();
      }
    }

    // Disable or enable the buttons
    toggleButtons(buttons, disable);
  }

  _handleUpdateDCS(evt) {
    if (evt.detail && evt.detail.status) {
      this._accountActionsWidget.setStatus(evt.detail.status);
    }
  }

  connectedCallback() {
    toggleAuthenticatedFeature({
      prompt: this._loginPrompt,
      promptText: i18n.getTranslation('app.settings.login-prompt', {
        link: ConfigService.links.CDP_ACCOUNT_CREATE
      }),
      feature: this._container
    });

    if (isAuthenticated()) {
      this._subscriptions = this._store.subscribe(async ({accountId}) => {
        if (accountId !== this._selectedAccount) {
          this._selectedAccount = accountId;
          this._bankAccountWidget.setData({accountId: this._selectedAccount});
          this._tradingAccountWidget.setData({accountId: this._selectedAccount});
          this._corporateActionsWidget.setData({accountId: this._selectedAccount});
          const {status, endDate, notificationSubscribeMode} = await StopPaperService.getPaperSubscriptionStatus({accountId: this._selectedAccount});
          const funcName = status ? 'remove' : 'add';
          this._stopPaperWidget.classList[funcName]('sgx-hidden');
          this._stopPaperWidget.setData({endDate, notificationSubscribeMode});
        }
        // this.updateUserActionState();
      });

      this.setAccountActionState();
    }
    this._setListeners('addEventListener');
  }

  updateUserActionState() {
    userService.getUserAccounts()
      .then(res => {
        if (res[0].accountStatus === ACCOUNT_STATUS.SUSPENDED) {
          // this._accountActionsWidget.setStatus('401');
          this.updateDisabledState();
        }
      })

  }

  setAccountActionState() {
    // AccountTypes which are not allowed to edit;
    const readOnlyAccountTypes = [
      ACCOUNT_STATES.CORPORATE,
      ACCOUNT_STATES.CORPORATE_TRUSTEE,
      ACCOUNT_STATES.JOINT_AND
    ];

    // Readonly status for corporate and corporate-trustee
    this._cdpStore.subscribe(({ selectedAccountType, selectedAccountSuspended = false }) => {
      const selectedAccountReadOnly = readOnlyAccountTypes.includes(selectedAccountType);
      setTimeout(() => this.updateDisabledState(selectedAccountReadOnly || selectedAccountSuspended), 0);
    });
  }

  disconnectedCallback() {
    this._setListeners('removeEventListener');
  }
}

customElements.define('template-settings-account', withInitDOM(TemplateSettingsAccount));
