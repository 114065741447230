import i18n from '@sgx/sgx-localisation-service';

const constants = {
  FULL_DATE_FORMAT: i18n.getTranslation('app.shared-text.date-format-full-date'),
  PDF_DATE_FORMAT: 'MMM YYYY',
  EXPORT_NAME_PAYOUTS: 'Payouts',
  EXPORT_NAME_CCY: 'Currency Conversion Service',
  DEFAULT_TABLE_HEIGHT: '320px',
  TABLE_TOOLBAR_AND_HEADER_HEIGHT_PAYOUTS: 118,
  TABLE_ROW_HEIGHT_PAYOUTS: 64,
  TABLE_TOOLBAR_AND_HEADER_HEIGHT_CCY: 78,
  TABLE_ROW_HEIGHT_CCY: 32,
};

const toString = function(val) {
  return val.toString();
};

const payoutsTableConfig = {
  options: {
    localise: false,
    downloadData: false,
    columnsConfiguration: false,
    sortable: true,
    resizable: true,
    reorderable: false,
    timezone: 'Asia/Singapore',
    autoWidth: true,
    exportDataFileName: constants.EXPORT_NAME_PAYOUTS,
    model: 'sgx-data-model'
  },
  layout: {
    desktop: {
      rowHeight: 64,
      horizontalScrollStep: 96,
      verticalScrollStep: 96,
      showAllRows: false
    },
    mobile: {
      cellPerRow: [2, 2, 3, 3],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    },
    tablet: {
      cellPerRow: [2, 2, 3, 3],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    }
  },
  columns: {
    security: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'link',
        newTab: true,
        className: 'security-link-cell'
      },
      filter: false,
      sort: {
        type: 'text',
        valueKey: 'label'
      },
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.security-name.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.security-name.tooltip')
    },
    paymentDate: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'date',
        format: constants.FULL_DATE_FORMAT,
        defaultValue: 'N/A'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.date-paid.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.date-paid.tooltip')
    },
    paymentTypeAndRate: {
      minWidth: 40,
      header: {
        type: 'text-double-row'
      },
      cell: {
        type: 'text-double-row'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: [
        i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.payment-type.label'),
        i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.payment-rate.label')
      ],
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.payment-type.tooltip')
    },
    entitlementQuantity: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        decimalPlaces: 0
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.registered-holdings.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.registered-holdings.tooltip')
    },
    bookClosureDate: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'date',
        format: constants.FULL_DATE_FORMAT
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.book-closure-date.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.book-closure-date.tooltip')
    },
    grossAmount: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text-double-row'
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.gross-amount.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.gross-amount.tooltip'),
      export: toString // TODO - make this ccy field more usable when imported to excel
    },
    exchangeRate: {
      minWidth: 40,
      header: {
        type: 'number'
      },
      cell: {
        type: 'number',
        decimalPlaces: 2,
        padZeros: true,
        replaceZero: false
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.exchange-rate.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.exchange-rate.tooltip')
    },
    taxFormatted: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.tax.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.tax.tooltip')
    },
    handlingFeeGst: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.handling-fee-gst.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.handling-fee-gst.tooltip')
    },
    amountPaid: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.amount-paid.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.payouts.amount-paid.tooltip'),
      export: toString // TODO - make this ccy field more useful when imported to excel
    }
  },
  states: {
    activeStateId: 0,
    states: [
      {
        data: {
          order: [
            'security',
            'paymentDate',
            'paymentTypeAndRate',
            'entitlementQuantity',
            'bookClosureDate',
            'grossAmount',
            'exchangeRate',
            'taxFormatted',
            'handlingFeeGst',
            'amountPaid'
          ],
          columns: {
            security: {
              show: true
            },
            paymentDate: {
              show: true
            },
            paymentTypeAndRate: {
              show: true
            },
            entitlementQuantity: {
              show: true
            },
            bookClosureDate: {
              show: true
            },
            grossAmount: {
              show: true
            },
            exchangeRate: {
              show: true
            },
            taxFormatted: {
              show: true
            },
            handlingFeeGst: {
              show: true
            },
            amountPaid: {
              show: true
            }
          },
          filters: {},
          sorts: {
            security: 'dsc'
          }
        }
      }
    ]
  }
};

const ccyTableConfig = {
  options: {
    localise: false,
    downloadData: false,
    columnsConfiguration: false,
    sortable: true,
    resizable: true,
    reorderable: false,
    timezone: 'Asia/Singapore',
    autoWidth: true,
    exportDataFileName: constants.EXPORT_NAME_CCY,
    model: 'sgx-data-model'
  },
  layout: {
    desktop: {
      rowHeight: 32,
      horizontalScrollStep: 96,
      verticalScrollStep: 96,
      showAllRows: false
    },
    mobile: {
      cellPerRow: [2, 2, 3, 3],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    },
    tablet: {
      cellPerRow: [2, 2, 3, 3],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    }
  },
  columns: {
    paymentReference: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.payment-reference.label')
    },
    sentDate: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'date',
        format: constants.FULL_DATE_FORMAT,
        defaultValue: 'N/A'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.sent-date.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.sent-date.tooltip')
    },
    originalAmount: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.src-payment.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.src-payment.tooltip')
    },
    transactionFee: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.transaction-fee.label')
    },
    netAmount: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.net-payment.label')
    },
    conversionRate: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        decimalPlaces: 8
      },
      sort: {
        type: 'number'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.conversion-rate.label')
    },
    convertedAmount: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.dest-payment.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.dest-payment.tooltip')
    },
    status: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.status.label')
    },
    failedReason: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.failed-reason.label')
    },
    returnedAmount: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.amount-returned.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-payouts.table.ccy.amount-returned.tooltip')
    }
  },
  states: {
    activeStateId: 0,
    states: [
      {
        data: {
          order: [
            'paymentReference',
            'sentDate',
            'originalAmount',
            'transactionFee',
            'netAmount',
            'conversionRate',
            'convertedAmount',
            'status',
            'failedReason',
            'returnedAmount'
          ],
          columns: {
            paymentReference: {
              show: true
            },
            sentDate: {
              show: true
            },
            originalAmount: {
              show: true
            },
            transactionFee: {
              show: true
            },
            netAmount: {
              show: true
            },
            conversionRate: {
              show: true
            },
            convertedAmount: {
              show: true
            },
            status: {
              show: true
            },
            failedReason: {
              show: true
            },
            returnedAmount: {
              show: true
            }
          },
          filters: {},
          sorts: {}
        }
      }
    ]
  }
};

export {
  payoutsTableConfig,
  ccyTableConfig,
  constants
};
