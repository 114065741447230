import i18n from 'sgx-localisation-service';

export default {
  config: {
    type: 'content',
    editable: false,
  },
  state: {
    activeStateId: 0, // the index of the active tab (relative to the states array)
    states: [
      {
        label: i18n.getTranslation('app.security-details.tabs.prices-and-chart'),
        data: 'widget-security-details-overview'
      },
      {
        label: i18n.getTranslation('app.security-details.tabs.company-information'),
        data: 'widget-security-details-information'
      },
      {
        label: i18n.getTranslation('app.security-details.tabs.financial-statements'),
        data: 'widget-security-details-financial'
      },
      {
        label: i18n.getTranslation('app.security-details.tabs.company-announcements'),
        data: 'widget-security-details-announcements'
      },
      {
        label: i18n.getTranslation('app.security-details.tabs.gti'),
        data: 'widget-security-details-gti'
      }
    ]
  }
};
