import { StocksConstants } from '@sgx/sgx-stocks-service';

const SCREENER_FIELD_KEYS = StocksConstants.SCREENER_FIELD_KEYS;

export const rangeFilterKeys = [
  SCREENER_FIELD_KEYS.TOTAL_REVENUE,
  SCREENER_FIELD_KEYS.MARKET_CAP,
  SCREENER_FIELD_KEYS.PE,
  SCREENER_FIELD_KEYS.YIELD,
  SCREENER_FIELD_KEYS.PRICE_PERC_CHANGE_4,
  SCREENER_FIELD_KEYS.PRICE_PERC_CHANGE_13,
  SCREENER_FIELD_KEYS.PRICE_PERC_CHANGE_26,
  SCREENER_FIELD_KEYS.PRICE_PERC_CHANGE_52,
  SCREENER_FIELD_KEYS.NET_PROFIT_MARGIN,
  SCREENER_FIELD_KEYS.ROE_PERC,
  SCREENER_FIELD_KEYS.PRICE_BOOK_RATIO,
  SCREENER_FIELD_KEYS.CASH_FLOW,
  SCREENER_FIELD_KEYS.DEBT_EQUITY_RATIO,
  SCREENER_FIELD_KEYS.SALES_PERC_CHANGE
];

// TODO - implement filter decimal key logic, find out what needs to be done
const rangeFilterDecimalKeys = [
  SCREENER_FIELD_KEYS.YIELD,
  SCREENER_FIELD_KEYS.PRICE_BOOK_RATIO
];

export const formatFilterData = (data) => {
  const rangeValuesMap = {};
  const sectorsList = [];

  Object.keys(data).forEach(key => {
    const stock = data[key];
    const filterLen = rangeFilterKeys.length;

    // Extract data from each stock using the list of specified range filter keys
    for (let i = 0; i < filterLen; i++) {
      const filterKey = rangeFilterKeys[i];
      const val = Number(stock[filterKey]);

      if (!isNaN(val)) {
        if (rangeValuesMap[filterKey]) {
          rangeValuesMap[filterKey].push(val);
        } else {
          rangeValuesMap[filterKey] = [val];
        }
      }
    }

    const sector = stock.sector;
    if (sectorsList.indexOf(sector) < 0) {
      sectorsList.push(sector);
    }
  });
  return { rangeValuesMap, sectorsList };
};

// Uses a quartile algorithm to filter out all the values that fall far outside the standard deviation of the range values
const filterOutliers = (someArray) => {
  if (someArray.length < 4) {
    return someArray;
  }

  const values = someArray.slice().sort((a, b) => a - b);// copy array fast and sort
  let q1 = 0;
  let q3 = 0;
  let iqr = 0;
  let maxValue = 0;
  let minValue = 0;

  // find the quartiles
  if ((values.length / 4) % 1 === 0) {
    q1 = 1 / 2 * (values[(values.length / 4)] + values[(values.length / 4) + 1]);
    q3 = 1 / 2 * (values[(values.length * (3 / 4))] + values[(values.length * (3 / 4)) + 1]);
  } else {
    q1 = values[Math.floor(values.length / 4 + 1)];
    q3 = values[Math.ceil(values.length * (3 / 4) + 1)];
  }

  iqr = q3 - q1;
  maxValue = q3 + iqr * 1.5;
  minValue = q1 - iqr * 1.5;

  return values.filter((x) => (x >= minValue) && (x <= maxValue));
};

// Set the range configuration for each sgx-range-filter component based on the values pulled from the data
export const createRangeConfig = (name, rangeVals=[]) => {
  rangeVals.sort((a, b) => a - b);

  const normalizedVals = filterOutliers(rangeVals);
  const minVal = rangeVals[0];
  const maxVal = rangeVals[rangeVals.length - 1];

  let min = isNaN(minVal) ? 0 : Math.floor(minVal);
  let max = isNaN(maxVal) ? 1 : Math.ceil(maxVal);

  // Ensure min is not less than max and vice versa
  min = min >= max ? (max - 1) : min;

  const rangeConfig = {
    range: {
      min: min,
      max: max
    },
    start: [min, max],
    step: rangeFilterDecimalKeys.indexOf(name) >= 0 ? 0.01 : 1
  };

  /**
   * Values that fall below the normalized range will go in the first 10% of the scale:
   * values that fall above the normalized range will go in the last 10% of the scale
   */
  if (rangeVals.length > normalizedVals.length) {
    const normalizedMin = normalizedVals[0];
    const normalizedMax = normalizedVals[normalizedVals.length - 1];
    const threshold = (normalizedMax - normalizedMin) * 0.5;

    if (normalizedMin > min && (normalizedMin - min) > threshold) {
      rangeConfig.range['10%'] = normalizedMin;
    }

    if (normalizedMax < max && (max - normalizedMax) > threshold) {
      rangeConfig.range['90%'] = normalizedMax;
    }
  }

  return rangeConfig;
};

