module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-list-row-account-trading\">\n  <div class=\"trading-account-row\">\n    <div class=\"trading-account-row-logo\"></div>\n    <div class=\"trading-account-row-column\">\n      <div class=\"trading-account-info\">\n        <sgx-input-text name=\"tradingAccountNumber\" readonly class=\"trading-account-row-number-input\" data-i18n=\"[label]tradingAccountRow.number.label\"></sgx-input-text>\n        <div class=\"trading-account-row-state\">\n          <div class=\"trading-account-row-state-status sgx-base-text-body-14\"></div>\n          <p class=\"trading-account-row-state-date sgx-base-text-body-14\"></p>\n        </div>\n      </div>\n      <div class=\"trading-account-row-blb\">\n        <div class=\"trading-account-row-blb-label-container\">\n          <sgx-input-text name=\"blbAccountNumber\" class=\"trading-account-row-blb-input\" data-i18n=\"[label]tradingAccountRow.blb.label;[hint]tradingAccountRow.blb.hint_html\"></sgx-input-text>\n        </div>\n        <div class=\"trading-account-row-blb-item\">\n          <button class=\"trading-account-row-blb-button sgx-button--secondary sgx-button--small\" data-i18n=\"tradingAccountRow.blb.number.setup\"></button>\n        </div>\n      </div>\n    </div>\n  </div>\n</template>";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()