const INACTIVE_TIME = 900; // 15 minutes in seconds = 900
const IDLE_INTERVAL = 5000; // to check every 5 seconds
const TIMEOUT_INTERVAL = 1000; // to check every 1 second
const TIMER_COUNTDOWN = 60; // when to show the session timeout window, default to 60
const IDLE_INTERVAL_IN_SECONDS = IDLE_INTERVAL / 1000; // to get the interval to update in seconds

export {
  INACTIVE_TIME,
  IDLE_INTERVAL,
  TIMEOUT_INTERVAL,
  TIMER_COUNTDOWN,
  IDLE_INTERVAL_IN_SECONDS
}
