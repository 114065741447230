import { Utils, withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import tmpl from './sgx-list-details.html';

/**
 * A custom element that renders a list linked to a details container.
 * It provides an API to configure the list, details container, and (optionally)
 * a toolbar with more than one row.
 *
 * This element can be used to list items that when clicked show further detail
 * about the clicked item.
 *
 * sgx-list-details depends on other core components:
 * - sgx-list
 * - sgx-data-model
 * - sgx-data-model-tools
 * - sgx-status-indicator
 *
 * sgx-list-details expects a setConfig function to be set containing:
 * - List configuration
 * - Details configuration
 * - Toolbar configuration (optional)
 *
 * @module sgx-list-details
 * @extends {HTMLElement}
 */
class SgxListDetails extends HTMLElement {
  constructor() {
    super();

    // Bound methods
    this._listRowClickCallback = Utils.debounce(this._listRowClickCallback, 200, this);
    this._detailsBackButtonCallback = this._detailsBackButtonCallback.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('sgx-list-details');
    this._toolbar = this.querySelector('.sgx-list-details-list-toolbar');
    this._list = this.querySelector('.sgx-list-details-list-list');
    this._details = this.querySelector('.sgx-list-details-details');
    this._detailsBackButton = this.querySelector('.sgx-list-details-details-header-back');
    this._detailsLoader = this.querySelector('.sgx-list-details-details-loader');
  }

  connectedCallback() {
    this._detailsBackButton.addEventListener('click', this._detailsBackButtonCallback);
  }

  disconnectedCallback() {
    this._list.removeEventListener('rowclick', this._listRowClickCallback);
    this._detailsBackButton.removeEventListener('click', this._detailsBackButtonCallback);

    this._toolbarConfig.rows.forEach(row => {
      if (row && row.layout === 'default') {
        this._destroyToolbarTools(row);
      }
    })
    // Clean up all references to parent component
    this._toolbarConfig = null;
    this._detailsConfig = null;
  }

  setConfig({list = {}, toolbar, details = {}}) {
    // TODO Validate configuration
    this._toolbarConfig = toolbar;
    this._detailsConfig = {...this._getDefaultDetailsConfig(), ...details};
    this._listRowTagName = list.view && list.view.rowElementName;
    this._list.setConfig({...list});

    this._list.addEventListener('rowclick', this._listRowClickCallback);
    this._createToolbarTools();
  }

  setData(data) {
    this._list.setData(data);
    setTimeout(() => {
      this._list.getView().recalculateSize();
    }, 0);
  }

  updateData(data) {
    this._list.updateData(data);
  }

  setFilters(filters) {
    if (!filters) return;
    this._list.setFilters(filters);
  }

  setSorts(sorts) {
    this._list.setSorts(sorts);
  }

  setSelectedRowId(rowId) {
    if (this._detailsRowId !== rowId) {
      // Updating details
      if (!!this._detailsContent && this._details.contains(this._detailsContent)) {
        this._details.removeChild(this._detailsContent);
      }
      this._detailsContent = document.createElement(this._detailsConfig.element);
      this._detailsLoader.show({description: i18n.getTranslation('list-details.status-indicator.loading.description')});

      // Retrieve row data
      const listDataModel = this._list.getDataModel();

      listDataModel.getRowData(rowId)
        .then(rowData => {
          this.setDetails(rowData, false, false);
          this._detailsRowId = rowId;
        }) // details already reset and displayed
        .catch(err => this._setDetailsError(null, err));
    }

    this._shouldSlideInDetails(true);
  }

  setDetails(rowData, contentReset = true, showDetails = true) {
    if (contentReset) {
      if (!!this._detailsContent && this._details.contains(this._detailsContent)) {
        this._details.removeChild(this._detailsContent);
      }
      this._detailsContent = document.createElement(this._detailsConfig.element);
    }

    // Handling race between quick selections: take the last one with oldest timestamp
    const thisCallTS = Date.now();
    this._latestCallTS = thisCallTS;

    // Display loading details title
    if (typeof this._detailsConfig.resolveSelectedLoadingTitle === 'function') {
      this._setDetailsHeaderTitle(this._detailsConfig.resolveSelectedLoadingTitle(rowData));
    }

    // Retrieve details data
    this._detailsConfig.resolveSelectedData(rowData)
      .then(data => {
        this._detailsContent.removeAttribute('style');
        if (thisCallTS === this._latestCallTS) {
          this._detailsContent.setData(data);
          this._detailsLoader.hide();
          this._resolveDetailsHeaderTitle(data);
          this._latestCallTS = null;
        }
      })
      .catch(err => this._setDetailsError(rowData, err));
    this._detailsContent.style.display = 'none';

    if (!this._details.contains(this._detailsContent)) {
      this._details.appendChild(this._detailsContent);
    }

    if (showDetails) {
      this._shouldSlideInDetails(true);
    }
  }

  getList() {
    return this._list;
  }

  //#region Private Methods

  _getDefaultDetailsConfig() {
    return {
      'element': 'sgx-list-details-iframe',
      'resolveSelectedData': selectedRowData => {
        return Promise.resolve(selectedRowData)
      }
    };
  }

  _setDetailsError(rowData, err) {
    this._resolveDetailsHeaderTitle(rowData, err);
    const errorText = (typeof this._detailsConfig.resolveErrorText === 'function') ? this._detailsConfig.resolveErrorText(rowData, err) : i18n.getTranslation('list-details.status-indicator.error.description');
    this._detailsLoader.show({
      status: 'error',
      title: i18n.getTranslation('list-details.status-indicator.error.title'),
      description: errorText
    });
  }

  /*
   * Append tools to toolbar.
   *
   * Accepts a default left-right layout or a custom layout function.
   *
   * @method module:sgx-list-details#_createToolbarTools
   */
  _createToolbarTools() {
    if (!this._toolbarConfig) {
      return;
    }

    this._toolbarConfig.rows.forEach(row => {
      const rowElem = document.createElement('div');
      rowElem.classList.add('sgx-list-details-list-toolbar-row');
      this._toolbar.appendChild(rowElem);

      const {layout = 'default', customToolbarLayout, onAttach} = row;
      switch (layout) {
        case 'custom':
          if (typeof customToolbarLayout.then === 'function') {
            customToolbarLayout.then(customToolbar => {
              rowElem.appendChild(customToolbar);
            })
              .then(() => {
                if (typeof onAttach === 'function') {
                  onAttach();
                }
              })
          }
          break;
        default:
          rowElem.appendChild(this._getDefaultToolbarTools(row));
      }
    });
  }

  /*
   * Creates tools with default left-right layout inside a row.
   *
   * @param {Object} [row] a toolbar row from the config
   * @method module:sgx-list-details#_getDefaultToolbarTools
   */
  _getDefaultToolbarTools(row) {
    const toolbarFragment = document.createDocumentFragment();
    ['sgx-list-details-list-toolbar-left', 'sgx-list-details-list-toolbar-right'].forEach(className => {
      const divElem = document.createElement('div');
      divElem.classList.add(className);
      toolbarFragment.appendChild(divElem);
    });

    const leftToolsElement = toolbarFragment.querySelector('.sgx-list-details-list-toolbar-left');
    const rightToolsElement = toolbarFragment.querySelector('.sgx-list-details-list-toolbar-right');
    const model = this._list.getDataModel();
    (row.tools || []).forEach(({element, position, onAttach}) => {
      const tool = document.createElement(element);
      if (position === 'left') {
        leftToolsElement.appendChild(tool);
      } else {
        // Default
        rightToolsElement.appendChild(tool);
      }
      if (typeof onAttach === 'function') {
        onAttach(tool);
      }
      if (typeof tool.setDataModel === 'function') {
        tool.setDataModel(model);
      }
    });
    leftToolsElement.style.flex = leftToolsElement.childElementCount;
    rightToolsElement.style.flex = rightToolsElement.childElementCount;
    return toolbarFragment;
  }

  /*
   * Destroys tools with default left-right layout.
   *
   * @param {Object} [row] a toolbar row from the config
   * @method module:sgx-list-details#_destroyToolbarTools
   */
  _destroyToolbarTools(row) {
    this._destroyToolbarToolsOnPosition(row, 'left');
    this._destroyToolbarToolsOnPosition(row, 'right');
  }

  /*
   * Destroys tools given a left or right position.
   *
   * @param {Object} [row] a toolbar row from the config
   * @param {Object} [position] left or right position in the toolbar row
   * @method module:sgx-list-details#_destroyToolbarToolsOnPosition
   */
  _destroyToolbarToolsOnPosition(row, position) {
    const toolsConfig = row.tools || [];
    const toolsContainer = this._toolbar.querySelector(`.sgx-list-details-list-toolbar-${position}`);
    toolsConfig
      .filter(tool => tool.position === position)
      .forEach(({onDetach}, index) => {
        if (typeof onDetach === 'function') {
          onDetach(toolsContainer.children[index]);
        }
      });
  }

  showToolbar() {
    this._toolbar.classList.remove('sgx-list-details-list-toolbar--hidden');
  }

  hideToolbar() {
    this._toolbar.classList.add('sgx-list-details-list-toolbar--hidden');
  }

  _listRowClickCallback(e) {
    e.stopPropagation();
    this.setSelectedRowId(e.detail.key);
    this.dispatchEvent(new CustomEvent('list-rowclick', {
      detail: e.detail
    }));
  }

  _detailsBackButtonCallback() {
    this._shouldSlideInDetails(false);
  }

  _resolveDetailsHeaderTitle(data, err = null) {
    let title = '';
    if (err) {
      title = (typeof this._detailsConfig.resolveErrorTitleForMobile === 'function') ?
        this._detailsConfig.resolveErrorTitleForMobile(data, err) :
        i18n.getTranslation('list-details.mobile-error-title');
    } else {
      title = (typeof this._detailsConfig.resolveSelectedTitleForMobile === 'function') ?
        this._detailsConfig.resolveSelectedTitleForMobile(data) : '';
    }
    this._setDetailsHeaderTitle(title);
  }

  _setDetailsHeaderTitle(title) {
    this._details.querySelector('.sgx-list-details-details-header--title').textContent = title;
  }

  _shouldSlideInDetails(flag) {
    const callback = () => {
      this._toggleDetailsTransitionClass();
      this.removeEventListener('transitionend', callback);
    };
    const toggleKey = flag ? 'add' : 'remove';

    this.addEventListener('transitionend', callback);
    this._toggleDetailsTransitionClass();
    this.classList[toggleKey]('sgx-list-details--active');
    this._details.classList.add('sgx-list-details-details--bring-to-front');
  }

  _toggleDetailsTransitionClass() {
    this.classList.toggle('sgx-list-details--transition');
    if (!this.classList.contains('sgx-list-details--active')) {
      this._details.classList.remove('sgx-list-details-details--bring-to-front');
    }
  }
}

customElements.define('sgx-list-details', withInitDOM(SgxListDetails));

//#endregion
