module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-otp-token\">\n\n  <ol>\n    <li data-i18n=\"app.widget-otp-token.labels.press-and-hold-label\"></li>\n    <li>\n      <span data-i18n=\"app.widget-otp-token.labels.enter-otp-msg-label\"></span>\n      <span> <sgx-input-text class='otp-token'></sgx-input-text> </span>\n    </li>\n  </ol>\n  <sgx-input-checkbox name=\"otp-token-preference\"\n                      data-i18n=\"[label]app.widget-otp-token.labels.token-as-preference-label\"></sgx-input-checkbox>\n\n  <ul class=\"footnote\">\n    <li>\n      <span data-i18n=\"app.widget-otp-token.labels.activate-token-label\"></span> <a href=\"#\" data-i18n=\"app.widget-otp-token.labels.here-label\"></a>\n    </li>\n    <li>\n      <span data-i18n=\"app.widget-otp-token.labels.request-token-label\"></span> <a href=\"#\" data-i18n=\"app.widget-otp-token.labels.here-label\"></a>\n    </li>\n  </ul>\n\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()