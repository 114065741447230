import validate from '@sgx/sgx-forms/src/sgx-form/validatejs';
import { addCustomValidators } from 'utils/form-util';
import DateService from 'sgx-date-time-service';

const MYINFO_DATA_SOURCE_NOT_APPLICABLE = '3';
// Date of Birth format
const DOB_FROM_FORMAT = 'YYYY-MM-DD';
const DOB_TO_FORMAT = 'DD MMM YYYY';

validate.validators = addCustomValidators(validate.validators);

function retrieveValueFromMyInfoDataItemHolder(myInfoDataItemValueHolder) {
  if (!myInfoDataItemValueHolder) {
    return null;
  }
  if (MYINFO_DATA_SOURCE_NOT_APPLICABLE === myInfoDataItemValueHolder.source) {
    return null;
  }
  if (myInfoDataItemValueHolder.unavailable) {
    return null;
  }
  return myInfoDataItemValueHolder.value;
}

function retrieveValueFromMyInfoValueHolder(myInfoValueHolder) {
  if (!myInfoValueHolder) {
    return null;
  }
  return myInfoValueHolder.value;
}

function retrieveCodeFromMyInfoDataItemHolder(myInfoDataItemCodeHolder) {
  if (!myInfoDataItemCodeHolder) {
    return null;
  }
  if (MYINFO_DATA_SOURCE_NOT_APPLICABLE.equals(myInfoDataItemCodeHolder.source)) {
    return null;
  }
  if (myInfoDataItemCodeHolder.unavailable) {
    return null;
  }
  return myInfoDataItemCodeHolder.code;
}

function retrieveCodeFromMyInfoCodeHolder(myInfoCodeHolder) {
  if (!myInfoCodeHolder) {
    return null;
  }
  return myInfoCodeHolder.code;
}

function validateContact(myInfoMobile) {
  myInfoMobile = myInfoMobile || {};
  const prefix = validateLengthAs255(retrieveValueFromMyInfoValueHolder(myInfoMobile.prefix));
  const code = validateLengthAs255(retrieveValueFromMyInfoValueHolder(myInfoMobile.areacode));
  const number = validateLengthAs255(retrieveValueFromMyInfoValueHolder(myInfoMobile.nbr));
  const mobileNumber = `${prefix.value}${code.value} ${number.value}`;
  let mobileValidator;

  try {
    mobileValidator = validate({ mobileConstraints: mobileNumber }, getConstraints());
  } catch(_) {
    // set mobileValidator to invalid when validator throws an exception
    mobileValidator = { mobileConstraints: [] };
  }

  const isValid = mobileValidator && !mobileValidator.mobileConstraints;

  if (MYINFO_DATA_SOURCE_NOT_APPLICABLE === myInfoMobile.source) {
    return { isValid: false };
  }

  if (myInfoMobile.unavailable) {
    return { isValid: false };
  }

  return { prefix: prefix.value, code: code.value, number: number.value, isValid };
}

function validateRegAddress(myInfoAddress) {
  myInfoAddress = myInfoAddress || {};
  const type = myInfoAddress.type;
  // validate each fields from the myinfo address
  const { country, postal, street, unit, floor, block } = ['postal', 'street', 'unit', 'floor', 'block'].reduce((accumulator, key) => {
    accumulator[key] = validateLengthAs255(retrieveValueFromMyInfoValueHolder(myInfoAddress[key]));
    return accumulator;
  }, { country: validateLengthAs255(retrieveCodeFromMyInfoCodeHolder(myInfoAddress.country), true) });
  const addressObj = {
    country: country.value,
    postal: postal.value,
    street: street.value,
    unit: unit.value,
    floor: floor.value,
    block: block.value
  };
  let addressValidator;

  try {
    addressValidator = validate({ addressConstraints: postal.value }, getConstraints(country.value));
  } catch(_) {
    // set addressValidator to invalid when validator throws an exception
    addressValidator = { addressConstraints: [] };
  }

  // country, postal and steet is mandatory
  const isValid = country.isValid && postal.isValid && street.isValid && addressValidator && !addressValidator.addressConstraints;

  if (MYINFO_DATA_SOURCE_NOT_APPLICABLE == myInfoAddress.source || myInfoAddress.unavailable) {
    return { isValid: false };
  }

  if (type && type !== 'SG') {
    return { isValid: false };
  }

  return { isValid,  ...addressObj, type, highlightError: country.highlightError };
}

function validateEmail(myInfoEmail) {
  const email = validateLengthAs255(retrieveValueFromMyInfoDataItemHolder(myInfoEmail));
  const emailValidator = validate({ emailConstraints: email.value }, getConstraints());
  const isValid = email.isValid && emailValidator && !emailValidator.emailConstraints;
  return { email: email.value, isValid };
}

function validateUinfin(myInfoUinfin) {
  const { isValid, value } = validateLengthAs255(retrieveValueFromMyInfoValueHolder(myInfoUinfin));
  return { isValid, value };
}

/**
 * Validates the Date of Birth
 */
function validateDob(dob, format='') {
  let value = retrieveValueFromMyInfoValueHolder(dob);
  format = format || DOB_TO_FORMAT;
  const birthDate = DateService(value, DOB_FROM_FORMAT, true);
  const isValid = value && birthDate.isValid();

  if (isValid) {
    value = birthDate.locale('en').format(format);
  }

  return { isValid, value};
}

function validateName(name) {
  const { isValid, value } = validateLengthAs255(retrieveValueFromMyInfoValueHolder(name));
  return { isValid, value };
}

/**
 * @link Check residential status codes at https://www.ndi-api.gov.sg/library/myinfo/implementation-myinfo-data
 */
function validateResidentialStatus(residential) {
  // should be invalid for Citizen and PR
  return {
    isValid: !(residential && ['C', 'P'].includes(residential.code)),
    value: residential && residential.desc
  }
}

function validateLengthAs255(value, skipEmptyCheck) {
  if (!skipEmptyCheck && !value) {
    return { isValid: false, value: '' };
  }
  const isValid = value.length <= 255;
  const obj = { isValid, value: value.substr(0, 255) };
  if(skipEmptyCheck && !value){
    obj.highlightError = true;
  }
  return obj;
}

function getConstraints(country) {
  return {
    addressConstraints: {
      singaporePostalCodeIf: {
        predicate: () => country === 'SG',
        scope: this
      }
    },
    mobileConstraints: {
      presence: true,
      format: {
        pattern: '^$|^\\+?[1-9][0-9]*\\s[0-9]+$',
      },
      singaporePhone: {
        mobileOnly: true,
      }
    },
    emailConstraints: {
      presence: true,
      email: true
    }
  };
}


export {
  validateContact,
  validateRegAddress,
  validateEmail,
  validateUinfin,
  validateName,
  validateResidentialStatus,
  validateDob
}
