import { URLUtils, FetchUtils } from '@sgx/sgx-base-code';
import ConfigService from 'sgx-config-service';

const {fetchJSON} = FetchUtils;
const CORPORATE_ACTIONS_API_URL = ConfigService.endpoints.CORPORATE_ACTIONS_API_URL;
const CORPORATE_ACTIONS_API_METALIST = CORPORATE_ACTIONS_API_URL + '/metalist';

let instance = null;

/**
 * Corporate Actions service retrieves corporate actions and metadata regarding them
 * https://confluence.sgx.com/display/DIGITAL/Corporate+Action+API+-+Specification+V1.0
 * @module sgx-corporate-actions-service
 * @type {HTMLElement}
 */

class CorporateActionsService {
  constructor() {
    if (!instance) {
      instance = this;
    }
    this._mediaCentreMetadata = null;
    return instance;
  }

  /**
   * Fetches an object containing the Corporate Actions found by a particular search
   *
   * See the API documentation for a list of possible parameters to include in config
   *
   * @param {Object} params an object of query parameters that can be appended to the API call
   * @param {String} [params.pagestart] page number to start the search results on
   * @param {String} [params.pagesize] size of each page of results
   * @param {String} [params.name] name of the company to filter for
   * @param {String} [params.ibmCode] ibm code of the company to filter for
   * @returns {Promise<Object>} returns an object containing the Corporate Actions
   */
  getCorporateActions(params = {pagestart: '0', pagesize: '20'}) {
    params.params = 'id,anncType,dateAnnc,exDate,name,particulars,recDate,datePaid'; // to return only these fields
    params.order = 'desc';
    params.orderBy = 'dateAnnc';
    const url = URLUtils.setQueryParams(CORPORATE_ACTIONS_API_URL, params);
    return fetchJSON(url).then(this._processCorporateActionsSearchResponse);
  }

  /**
   * Fetches a summary list of companies with Announcements falling within a specified date range
   *
   * @param {String} announcementId the announcement ID
   * @returns {Promise<Object>} returns an object containing the announcement if it exists
   */
  getCorporateActionById(announcementId) {
    const url = URLUtils.setQueryParams(CORPORATE_ACTIONS_API_URL, {id: announcementId});
    return fetchJSON(url).then(this._processAnouncementByIdResponse);
  }

  /**
   * Fetches an object containing the metadata of the Corporate Actions
   *
   * @param {Object} type data type ('companyName' || 'category')
   * @return {Promise<Object>} Resolves to a metadata object
   */
  getCorporateActionsMetadata(type) {
    if (this._metadata) {
      return Promise.resolve(this._metadata[type]);
    }

    return fetchJSON(CORPORATE_ACTIONS_API_METALIST).then(response => {
      const i = response.data.category.indexOf('ENTITL.');
      if (i >= 0) {
        response.data.category.splice(i, 1);
      }
      if (response.data.category.indexOf('ENTITLEMENT') < 0) {
        response.data.category.push('ENTITLEMENT');
      }
      this._metadata = {
        companyName: this.createBasicOptions(response.data.companyName),
        category: this.createBasicOptions(response.data.category)
      };
      return Promise.resolve(this._metadata[type]);
    });
  }

  createBasicOptions(arr, returnUnsorted) {
    const rawValues = arr || [];
    const optionsArray = rawValues.reduce((options, item) => {
      if (item) {
        options.push({value: item, label: item});
      }
      return options;
    }, []);
    return returnUnsorted ? optionsArray : optionsArray.sort(this.basicSortOptions);
  }

  basicSortOptions(a, b) {
    return String(a.label).trim().localeCompare(String(b.label).trim());
  }

  _processCorporateActionsSearchResponse(response) {
    if (response == null || response.message) {
      console.warn('Error: Response timeout');
      return Promise.resolve({});
    }
    if (response.data == null || response.data.length === 0) {
      return Promise.resolve({
        data: [],
        totalItems: 0,
        totalPages: 0
      });
    }
    response.data.forEach(d => {
      d.anncType = d.anncType.replace('ENTITL.', 'ENTITLEMENT');
    });
    return Promise.resolve({
      data: response.data,
      totalItems: response.meta.totalItems,
      totalPages: response.meta.totalPages
    });
  }

  _setQueryParams(url, opts) {
    var params = {};
    if (opts.from) {
      params.periodstart = opts.from.format('YYYYMMDD_HHmmss');
    }
    if (opts.to) {
      params.periodend = opts.to.format('YYYYMMDD_HHmmss');
    }
    if (opts.categories) {
      params[opts.excludeCats ? 'excat' : 'cat'] = opts.categories;
    }
    if (opts.subcategories) {
      params[opts.excludeSubcats ? 'exsub' : 'sub'] = opts.subcategories;
    }
    if (opts.titleFilter) {
      params.titleFilter = opts.titleFilter;
    }
    if (opts.searchValue) {
      params.value = opts.searchValue;
    }
    if (opts.id) {
      params.id = opts.id;
    }
    if (opts.pageStart != null) {
      var num = Number(opts.pageStart);
      if (num > 0) {
        num = num - 1; // the page parameter backend API starts at 0
        params.pagestart = num;
      }
    }
    if (opts.pageSize != null) {
      params.pagesize = opts.pageSize;
    }
    return URLUtils.setQueryParams(url, params);
  }
}

/* Export the singleton */
const corporateActionsServiceInstance = new CorporateActionsService();
export default corporateActionsServiceInstance;
