'use strict';

/**
 * Executes a promise then returns an object with the result and a resolution status
 * indicating whether it succeeded or failed. Useful for promises inside a Promise.all()
 * that you don't want to cause the whole thing to fail when they encounter an error
 * @param {Promise} promise - The promise to reflect and wrap in a resolution object
 * @returns {Object} - An object wrapping the promise response with a resolution status
 */
function reflect(promise) {
  return promise.then(function(data) {
    return {
      data: data,
      resolved: true
    };
  },
  function(err) {
    return {
      data: err,
      resolved: false
    };
  });
}

module.exports = {
  reflect: reflect
};
