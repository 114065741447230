import { withInitDOM, URLUtils } from 'sgx-base-code';
import DeviceService from '@sgx/sgx-device-service';
import ConfigService from '@sgx/sgx-config-service';
import i18n from '@sgx/sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import UserService from 'services/user-service';
import { ACCOUNT_STATUS, ACCOUNT_STATES, USER_TYPES, ACCOUNT_TYPES, CONTACT_REMINDER_ACTION } from 'services/user-service/src/user-service-constants';
import { isAuthenticated } from 'utils/auth-util';
import {
  createFormConfigFromSchema,
  addCustomValidators,
  isInvalid,
  showHideEl
} from 'utils/form-util';
import jsonSchema from 'schemas/settings-user';
import tmpl from './widget-user-details-dialog.html';
import contactUsSvg from '../../assets/images/contact-us.svg';
import InternationalCallingCode from '@sgx/sgx-forms/src/sgx-input-phone/InternationalCallingCode.js';

export default class WidgetUserDetailsDialog extends HTMLElement {

  constructor() {
    super();
    this._onCheckboxChange = this._onCheckboxChange.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());

    this._userParticularsSSO = document.querySelector('widget-user-particulars-update-sso');
    this._router = document.getElementById('sgx-app-router');

    this._dialog = this.querySelector('.widget-user-details-dialog');
    this._form = this.querySelector('.widget-user-details-dialog-form');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._alertBar = this.querySelector('.widget-user-details-dialog-alert-bar');
    this._confirmationBox = this.querySelector('[name="confirmationBox"]');
    this._actionList = this.querySelector('[name="actionList"]');

    this._mobilePhoneInput = this.querySelector('[name="mobileNumber"]');
    this._emailInput = this.querySelector('[name="email"]');

    this._mobilePhoneInputDisp = this.querySelector('.widget-user-details-display--mobile');
    this._emailInputDisp = this.querySelector('.widget-user-details-display--email');
    this._displayWrapper = this.querySelector('.widget-user-details-display');
    this._dialogTitle = this.querySelector('.widget-user-details-dialog-title');
    this._methods = this.querySelector('.widget-user-details-dialog-methods');
    this._defaultDesc = this.querySelector('.widget-user-details-dialog-description');

    this._backButton = this.querySelector('.widget-user-details-dialog-btn--back');
    this._confirmButton = this.querySelector('.widget-user-details-dialog-btn--confirm');
    this._laterButton = this.querySelector('.widget-user-details-dialog-btn--later');
    this._proceedButton = this.querySelector('.widget-user-details-dialog-btn--proceed');
    this._updateButton = this.querySelector('.widget-user-details-dialog-btn--update');
    this._myInfoButton = this.querySelector('.widget-user-details-dialog-btn--myinfo');
    this._nextButton = this.querySelector('.widget-user-details-dialog-btn--next');
    this._updatenowButton = this.querySelector('.widget-user-details-dialog-btn--updatenow');
    this._dcsUpdateDialog = document.querySelector('widget-dcs-update-dialog');

    this._img = this.querySelector('.widget-user-details-dialog-image');
    this._img.src = contactUsSvg;
  }

  connectedCallback() {
    this._setListeners(true);
    this._initData();
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  _setListeners(enable) {
    const listener = enable ? 'addEventListener' : 'removeEventListener';
    this._confirmationBox[listener]('change', this._onCheckboxChange);
    this._actionList[listener]('change', this._onCheckboxChange);
    this._myInfoButton[listener]('click', () => { this._updateUsingMyinfo()});
  }

  get _isAbleToModifyEmailOrMobile() {
    const { clientAlternateIds, clientInfoId } = this._particulars;
    const accounts = this._accounts;
    const { clientIdType } = clientInfoId || {};
    const hasIndividualOrJointOrAccount = accounts.some(({ accountCategory, typeOfApproval, accountStatus }) => {
      const isIndividual = accountCategory === ACCOUNT_TYPES.INDIVIDUAL && accountStatus !== ACCOUNT_STATUS.CLOSED;
      const isJointOR = `${accountCategory}-${typeOfApproval}` === ACCOUNT_TYPES.JOINT_OR && accountStatus !== ACCOUNT_STATUS.CLOSED;
      return isIndividual || isJointOR;
    });
    const hasSuspendedAccount = accounts.some(account => account.accountStatus === ACCOUNT_STATUS.SUSPENDED);
    // FIN user - foreigner with FIN
    const isFinUser = USER_TYPES.FOREIGNER === clientIdType && clientAlternateIds;
    const isSCPR = USER_TYPES.SC_PR === clientIdType;

    return (isFinUser || isSCPR) && hasIndividualOrJointOrAccount && !hasSuspendedAccount;
  }

  get _actionListOptions() {
    const isInValid = isInvalid(this._form);
    const primaryAction = isInValid ? 'updatenow' : 'next';
    return [
      {
        label: i18n.getTranslation('widget-user-details-dialog.fields.action-list.confirm'),
        value: 'confirm'
      },
      {
        label: i18n.getTranslation('widget-user-details-dialog.fields.action-list.update'),
        // set the value to `proceed` to prevent user from updating contact details on sgx mobile app,
        // instead show the 'Proceed to Investors Portal' button
        value: !this._isWebview ? primaryAction : 'proceed'
      }
    ];
  }

  get _actionState() {
    // if email is in readonly mode, it means that we're currently on the first step
    if (this._emailInput.readOnly) {

      if (!this._isAbleToModifyEmailOrMobile) {
        return this._confirmationBox.checked ? 'confirm' : 'later';
      }

      return this._actionList.getValue();
    }
    return 'update';
  }

  async _initData() {
    // contact reminder is available only for authenticated users
    if (!isAuthenticated()) {
      return;
    }

    this._userStore = StoreRegistry.settingsUser;
    try {
      const { displayable, skippable } = await UserService.getUserContactReminder();
      this._displayable = displayable;
      this._skippable = skippable;
      this._isWebview = StoreRegistry.appContext.getData('webview');
      this._shouldShowDialog();
    }  catch(_) {
      // Do nothing in case of error as this dialog will only appear if we're able to retrieve user's contact details and accounts
    }
  }

  /**
   * @desc: Shows the dialog when user is authenticated and `_displayable` reminder flag is true
   * and `userDetailsShown` is not true
   */
  async _shouldShowDialog() {
    const userDetailsShown = StoreRegistry.user.getData('userDetailsShown');
    let { state } = URLUtils.getQueryParams(window.location.search);
    const hideDialog =  (userDetailsShown || state || !this._displayable);

    /**
     * show the dcs dialog only when we dont display the update contact reminder
     * popup. We are still calling check and show inorder to update the local storage
     * with inactive accounts.
     */
    this._dcsUpdateDialog.checkAndShow(hideDialog);

    if (hideDialog) {
      return;
    }

    try {
      const [accounts, particulars] = await this._getUserInfo();
      this._accounts = accounts;
      this._particulars = particulars;
      this._setStateBasedOnUserAccounts();
      this._showDialog();
      StoreRegistry.user.setData(true, 'userDetailsShown');
      StoreRegistry.user.saveData();
    } catch(_) {
      // Do nothing in case of error as this dialog will only appear if we're able to retrieve user's contact details and accounts
    }
  }

  _setFormValidationConfig() {
    const validate = this._form.validator.validate;

    // Add custom validators here
    this._form.validator.validate.validators = addCustomValidators(this._form.validator.validate.validators);

    // Validation configuration
    let validationConfig = createFormConfigFromSchema(validate, jsonSchema, 5);

    const userContactWidget = document.createElement('widget-settings-user-contact');
    validationConfig = userContactWidget.updateValidationConfig(validate, validationConfig);

    const rules = validationConfig.validate.rules;
    rules['contacts[0]'].mobilePhoneRule.scope = this._mobilePhoneInput;
    const config = {
      alignment: 'vertical',
      validate: {
        rules: {
          email: rules.email,
          mobileNumber: rules['contacts[0]']
        }
      }
    };

    this._form.setConfig(config);
  }

  _getUserInfo() {
    return Promise.all([
      UserService.getUserAccounts(),
      UserService.getUserParticulars()
    ]);
  }

  _showDialog() {
    const dialogConfig = {
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      actions: {
        update: _ => isInvalid(this._form) || !this._confirmationBox.checked ? '' : this._updateParticulars(),
        proceed: _ => this._proceedToInvestors(),
        confirm: _ => this._confirmContactDetails(),
        later: _ => this._skipOrConfirmLater(),
        back: _ => this._updateContactFormFields(true),
        next: _ => this._navigateToProfile(),
        updatenow: _ => this._updateContactFormFields(false)
      }
    };
    this._dialog.footer.classList.remove('sgx-hidden');
    this._dialog.bodyElement.classList.remove('active-indicator');
    return this._dialog.show(dialogConfig);
  }

  _navigateToProfile() {
    this._router.navigateToURL('/profile/user');
    this._dialog.hide()
  }

  _setStateBasedOnUserAccounts() {
    const { email, otherPhoneNumber } = this._particulars;
    this._setFormValidationConfig();
    this._setInputValues(email, otherPhoneNumber);
    this._setRadioCheckboxFields();
    this._setFooterButtons();
    this._setAlertBar();
  }

  _setAlertBar() {
    const status = this._isAbleToModifyEmailOrMobile && !this._skippable ? 'alert' : 'informational';
    let translationPath = this._isAbleToModifyEmailOrMobile ? 'info' : 'info-form';
    if(isInvalid(this._form)) {
      translationPath = 'info-update';
    }
    const { accountType } = StoreRegistry.cdpSession.getData();
    let link = ConfigService.links.SGX_V2_UPDATE_PARTICULARS;

    if (accountType === ACCOUNT_STATES.CORPORATE) {
      link = ConfigService.links.UPDATE_PARTICULARS_CORPORATE_LINK;
    } else if (accountType === ACCOUNT_STATES.CORPORATE_TRUSTEE) {
      link = ConfigService.links.UPDATE_PARTICULARS_CORPORATE_TRUSTEE_LINK;
    }

    this._alertBar.setData({
      status: status,
      text: i18n.getTranslation(`widget-user-details-dialog.${translationPath}`, { link }),
      background: true
    });
  }

  _setInputValues(email, otherPhoneNumber) {
    const countryCode = (otherPhoneNumber.countryCode && otherPhoneNumber.countryCode.replace('+', '')) || '';
    this._emailInput.setValue(email || '-');
    this._confirmationBox.setDisabled(false);

    this._mobilePhoneInput.setJsonValue({
      number: otherPhoneNumber.number,
      code: countryCode
    });

    this._emailInputDisp.innerHTML = email || '-';
    this._mobilePhoneInputDisp.innerHTML = otherPhoneNumber.number ? `+${countryCode} ${otherPhoneNumber.number}` : '-';
  }

  /**
   * Redirects user to profile page
   */
  _proceedToInvestors() {
    this._dialog.hide();
    window.open(ConfigService.links.INVESTORS_PORTAL_PROFILE_USER);
  }

  async _skipOrConfirmLater() {
    const action = this._actionState === 'later' ? CONTACT_REMINDER_ACTION.CONFIRM_LATER : CONTACT_REMINDER_ACTION.SKIP;
    try {
      await this._updateContactReminder(action);
    } catch(_) {
      // do nothing if skip/confirm_later fails and just show the dialog again the next time user logs in based on the reminder
    }
    this._dialog.hide();
  }

  async _updateParticulars() {
    this._dialog.hide();
    this._updateUserStore();
    try {
      await this._updateContactReminder(CONTACT_REMINDER_ACTION.SKIP);
    } catch(_) {
      // do nothing if skip fails just show the txn signing dialog
    }

    StoreRegistry.user.setData(false, 'fullList');
    StoreRegistry.user.saveData();
    this._userParticularsSSO.showDialog({type: 'txn-signing', fullList: false});
  }

  async _updateUsingMyinfo() {
    this._dialog.hide();
    try {
      await this._updateContactReminder(CONTACT_REMINDER_ACTION.SKIP);
    } catch(_) {
      // do nothing if skip fails just show the myinfo dialog
    }
    StoreRegistry.user.setData(false, 'fullList');
    StoreRegistry.user.saveData();
    this._userParticularsSSO.showDialog({ type: 'my-info', fullList: false });
  }

  async _confirmContactDetails() {
    let status = 'success';
    let description = i18n.getTranslation('widget-user-details-dialog.success.description');
    const button = {
      text: i18n.getTranslation('sgx-dialog.ok'),
      callback: () => {
        this._dialog.hide();
      }
    };

    this._indicator.show();
    this._dialog.bodyElement.classList.add('active-indicator');
    this._dialog.bodyElement.scrollTop = 0;

    try {
      await this._updateContactReminder(CONTACT_REMINDER_ACTION.CONFIRM);
    } catch(_) {
      status = 'error';
      description = i18n.getTranslation('widget-user-details-dialog.error.description_html');
    }

    this._indicator.show({
      status,
      description,
      button
    });
    this._dialog.footer.classList.add('sgx-hidden');
  }

  /**
   * @desc rerenders the footer buttons whenever the _confirmationBox/_actionList gets change
   */
  _onCheckboxChange() {
    if(this._confirmationBox.checked){
      this._confirmationBox.message = "";
    } else {
      this._confirmationBox.message = i18n.getTranslation('widget-user-details-dialog.confirmationBox.checkedMessage');
    }
    this._setFooterButtons();
  }

  /**
   * @desc Handles the radio-list and checkbox whether which field to show/hide based on certain scenarios
   */
  _setRadioCheckboxFields() {
    const isValidContactDetails = !isInvalid(this._form);
    // if email is not in readonly mode, it means that we're currently on step 2 which allows the user to update his contact details
    if (!this._emailInput.readOnly) {
      if (this._emailInput.value === '-') {
        this._emailInput.setValue('');
      }
      this._actionList.classList.add('sgx-hidden');
      this._confirmationBox.classList.remove('sgx-hidden');
      return;
    }

    if (this._isAbleToModifyEmailOrMobile) {
      const currentSelectedValue = this._actionList.value;
      const options = this._actionListOptions;
      // auto-select the confirm option if both email and mobile are valid
      options[0].selected = !currentSelectedValue ? isValidContactDetails : currentSelectedValue === options[0].value;
      // disable the confirm option if either email/mobile is invalid/empty
      options[0].disabled = !isValidContactDetails;

      // auto-select the update option if either email/mobile is invalid/empty
      options[1].selected = !currentSelectedValue ? !isValidContactDetails : currentSelectedValue === options[1].value;
      this._actionList.setOptions(options);
      if (!isValidContactDetails) {
        this._actionList.classList.add('sgx-hidden');
        this._confirmationBox.classList.add('sgx-hidden');
      } else {
        this._actionList.classList.remove('sgx-hidden');
        this._confirmationBox.classList.add('sgx-hidden');
      }
    } else {
      this._actionList.classList.add('sgx-hidden');
      this._confirmationBox.classList.remove('sgx-hidden');
      this._confirmationBox.setDisabled(!isValidContactDetails);
    }
  }


  _setFooterButtons() {
    const state = this._actionState;
    switch (state) {
      case 'update':
        this._showHideButtons(['confirm', 'later', 'proceed', 'next', 'updatenow'], false);
        this._showHideButtons(['back', 'myInfo', 'update'], true);
        this._confirmationBox.checked ? this._updateButton.removeAttribute('disabled') :
          this._updateButton.setAttribute('disabled', true);
        break;

      case 'proceed':
        this._showHideButtons(['confirm', 'later', 'back', 'myInfo', 'update', 'next', 'updatenow'], false);
        this._showHideButtons(['proceed'], true);
        break;

      case 'later':
        this._laterButton.textContent = i18n.getTranslation('widget-user-details-dialog.button.later.confirm');
        this._laterButton.classList.add('sgx-button--primary');
        this._laterButton.classList.remove('sgx-button--text');
        this._showHideButtons(['back', 'confirm', 'proceed', 'update', 'myInfo', 'next', 'updatenow'], false);
        this._showHideButtons(['later'], true);
        break;

      default:
        const visibleButtons = [state];
        // shows the update later button when `skipable` is true and user is able to update the contact details and either 1st radio list option is selected or form is invalid
        // shows the confirm later button when confirmationBox is NOT checked and user is NOT able to update contact details
        if ((this._skippable && this._isAbleToModifyEmailOrMobile && (this._actionList.getValue() === 'confirm' || isInvalid(this._form)))||
          (!this._isAbleToModifyEmailOrMobile && !this._confirmationBox)) {
          if (isInvalid(this._form)) visibleButtons.push('later');
          this._laterButton.textContent = i18n.getTranslation('widget-user-details-dialog.button.later.update');
          this._laterButton.classList.remove('sgx-button--primary');
          this._laterButton.classList.add('sgx-button--text');
        }
        this._showHideButtons(['back', 'later', 'proceed', 'update', 'myInfo', 'confirm', 'next', 'updatenow'], false);
        this._showHideButtons(visibleButtons, true);
        break;
    }
  }

  _showHideButtons(buttons, show = true) {
    const funcName = show ? 'remove' : 'add';
    buttons.forEach(button => {
      const key = `_${button}Button`;
      this[key].classList[funcName]('sgx-hidden');
    });
  }

  _updateContactFormFields(readonly) {
    showHideEl(this._displayWrapper, readonly);
    showHideEl(this._alertBar, readonly);
    showHideEl(this._defaultDesc, readonly);
    showHideEl(this._methods, !readonly);
    showHideEl(this._form, !readonly);
    this._dialogTitle.innerHTML = i18n.getTranslation(`widget-user-details-dialog.title${readonly ? '' : '-update'}`);
    const imgClassFunc = readonly ? 'remove' : 'add';
    this._emailInput.readOnly = readonly;
    this._mobilePhoneInput.readOnly = readonly;
    this._img.classList[imgClassFunc]('sgx-hidden');
    this._confirmationBox.message = i18n.getTranslation('widget-user-details-dialog.confirmationBox.checkedMessage');

    // reset form values back to the original state
      this._resetFormValues();
    this._setRadioCheckboxFields();
    this._setFooterButtons();
  }

  async _updateContactReminder(action) {
    return await UserService.updateUserContactReminder(action);
  }

  _resetFormValues() {
    const { email, otherPhoneNumber } = this._particulars;
    this._setInputValues(email, otherPhoneNumber);
    this._confirmationBox.checked = false;
  }

  _getCountryFromIDD(code = '') {
    const { countryCode } = InternationalCallingCode.getCountryByDialCode(code) || {};
    return countryCode || '';
  }

  _updateUserStore() {
    const data = this._userStore.getData();
    data.email = this._emailInput.value;

    if (!data.contacts || !data.contacts.length) {
      data.contacts = [];
    }

    const prevCountryCode = data.contacts[0] ? data.contacts[0].code : '';
    const mobileNumber = this._mobilePhoneInput.getJsonValue({ countryCodeTemplate: '${country} +${code}' });

    if (this._getCountryFromIDD(prevCountryCode) !== mobileNumber.country) {
      //Setting isChecked to false, will trigger fatca check in profile-particulars
      data.fatcaCRS = { isChecked: false, value: null };
    } else {
      //when email is updated or country is not changed, marked fatca check as true and value to null.
      data.fatcaCRS = { isChecked: true, value: null };
    }

    data.contacts[0] = mobileNumber;
    this._userStore.setData(data);
  }
}

customElements.define('widget-user-details-dialog', withInitDOM(WidgetUserDetailsDialog));
