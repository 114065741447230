import tmpl from './cmp-otp-prompt.html';
import tabConfig from './cmp-otp-prompt-tabs-config';
import { URLUtils, withInitDOM } from 'sgx-base-code';
import { fromEvent } from 'rxjs';
import i18n from '@sgx/sgx-localisation-service';

export default class CmpOTPPrompt extends HTMLElement {
  constructor() {
    super();
    this._subscriptions = [];
    this._onTabChange = this._onTabChange.bind(this);
    this._updateTabsVisibility = this._updateTabsVisibility.bind(this);
    this._handleErrorReasons = this._handleErrorReasons.bind(this);
  }

  // All the getter and setter should be ideally, just after the constructor
  get _currentTab() {
    return this._dialog.querySelector('.cmp-otp-tab:not(.sgx-hidden)');
  }

  initDOM() {
    this.appendChild(tmpl.getNode());

    // References
    this._tabs = this.querySelector('.cmp-otp-tabs');
    this._dialog = this.querySelector('.cmp-otp-dialog');
    this._submitButton = this._dialog.querySelector('.submit-button');
    this._cancelButton = this._dialog.querySelector('.cancel-button');
    this._continueButton = this._dialog.querySelector('.continue-button');
    this._tabsContainer = this._dialog.querySelector('.cmp-otp-prompt-tabs-container');
    this._statusIndicatorContainer = this._dialog.querySelector('.status-indicator-container');
    this._statusIndicator = this._dialog.querySelector('.cmp-otp-indicator');
    this._ctaContainer = this._dialog.querySelector('.cta-container');
    this._dialogHeader = this._dialog.querySelector('.sgx-dialog-header');

    // Call to functions dependent on element references
    this._initializeTabs();
  }

  connectedCallback() {
    this._setListeners();
    this._resetState();
    this._tabs.addEventListener('stateChange', this._onTabChange);
    this._setEventListener(true);

  }

  _setEventListener(state) {
    const funcName = state ? 'addEventListener' : 'removeEventListener';
    this._continueButton[funcName]('click', _ => {
      this._resetState();
      this.hide();
    });

    this[funcName]('handle-otp-error', e => {
      this.show('sms');
      this.toggleHeader(true);
      this.showStatusIndicator('USER_SUSPENDED');
    })
  }

  setData({maskedMobileNumber}) {
    this.maskedMobileNumber = maskedMobileNumber;
  }

  _setListeners() {
    this._setSubmitListener();
    this._setCancelListener();
  }

  _setSubmitListener() {
    this._subscriptions.push(fromEvent(this._submitButton, 'click')
      .subscribe(e => {
        e.preventDefault();
        e.stopPropagation();
        this._currentTab.handleSubmit()
          .then(res => {
            this._handleResetState('200');
          })
          .catch(err => {
            // reset the input fields on error state
            this._currentTab.resetInputFields && this._currentTab.resetInputFields();

            switch (err.status) {
              case 500:
                this.showStatusIndicator();
                // this._handleResetState('500');
                break;
              case 400:
              case 429:
                err.json()
                  .then(error => {
                    this._handleErrorReasons(error.errors[0].reason);
                    // this._currentTab.handleError(i18n.getTranslation('otp-prompt.errors.' + error.errors[0].reason));
                  });
                break;
              default:
                this._currentTab.handleError(i18n.getTranslation('otp-prompt.errors.REQUEST_VALIDATION_ERROR'));
            }
          });
      })
    );
  }

  toggleHeader(state) {
    const methodName = state ? 'add' : 'remove';
    this._dialogHeader.classList[methodName]('sgx-hidden');
  }

  _handleErrorReasons(reason) {
    switch (reason) {
      case 'PASSWORD_USED_BEFORE':
        this.dispatchEvent(new CustomEvent('handle-password-error', {
          bubbles: true,
          data: {
            reason
          }
        }));
        this.hide();
        break;
      case 'USER_SUSPENDED':
        this.showStatusIndicator(reason);
        break;
      case 'MAX_SMS_OTP_ATTEMPTS_EXCEEDED':
        this.showStatusIndicator(reason);
        break;
      default:
        this._currentTab.handleError(i18n.getTranslation('otp-prompt.errors.' + reason));
    }
  }

  showStatusIndicator(reason = 'REASON_USER_SUSPENDED', message = '') {
    this._ctaContainer.classList.add('cta-container--centered');
    this._submitButton.classList.add('sgx-hidden');
    this._cancelButton.classList.add('sgx-hidden');
    this._continueButton.classList.remove('sgx-hidden');

    this._tabsContainer.classList.add('sgx-hidden');
    this._statusIndicatorContainer.classList.remove('sgx-hidden');
    const errorDesc = message || i18n.getTranslation('otp-prompt.errors.' + reason);
    this._statusIndicator.show({
      title: '',
      description: errorDesc,
      status: 'error'
    })
  }

  _resetState() {
    this._ctaContainer.classList.remove('cta-container--centered');
    this._submitButton.classList.remove('sgx-hidden');
    this._cancelButton.classList.remove('sgx-hidden');
    this._continueButton.classList.add('sgx-hidden');

    this._tabsContainer.classList.remove('sgx-hidden');
    this._statusIndicatorContainer.classList.add('sgx-hidden');
    this.toggleHeader(false);
  }

  _handleResetState(code) {
    this.dispatchEvent(new CustomEvent('reset-change-password-state', {
      bubbles: true,
      detail: {
        code: code
      }
    }));
    this._currentTab.resetInputFields && this._currentTab.resetInputFields();
    this._dialog.hide();
  }

  _setCancelListener() {
    this._subscriptions.push(fromEvent(this._cancelButton, 'click')
      .subscribe(e => {
        e.stopPropagation();
        e.preventDefault();
        this.dispatchEvent(new CustomEvent('cancel-otp-prompt', {
          bubbles: true
        }));
        this._dialog.hide();
        this._currentTab.handleCancel();
      })
    );
  }

  _initializeTabs() {
    this._tabs.setConfig(tabConfig.config);
    this._tabs.setState(tabConfig.state);
  }

  _onTabChange(e) {
    const oldTab = this._currentTab;
    const activeTab = this._dialog.querySelector(`${e.detail}`);
    if (oldTab) {
      oldTab.classList.add('sgx-hidden');
    }
    activeTab.classList.remove('sgx-hidden');
  }

  show(tabToShow) {
    if (tabToShow) {
      this._updateTabsVisibility(tabToShow);
    } else {
      this._resetTabVisibility();
    }
    this._dialog.show();
  }

  hide() {
    this._dialog.hide();
  }

  //todo: not satisfied with this, should be supported in core-lib
  _updateTabsVisibility(tabToShow) {

    // Manually handling the visibility of dialog boxes :(
    this._dialog.querySelectorAll('.cmp-otp-tab')
      .forEach(widget => {
        widget.classList.add('sgx-hidden');
      });

    this._tabs.querySelectorAll('.sgx-tab').forEach(tab => {

      tab.classList.add('sgx-hidden');
      tab.classList.remove('sgx-tab--active');

      if (tabToShow === 'sms' && tab.dataset.stateId === '0') {
        tab.classList.remove('sgx-hidden');
        tab.classList.add('sgx-tab--active');
        const widgetOTPSMS = this._dialog.querySelector('widget-otp-sms');

        widgetOTPSMS.classList.remove('sgx-hidden');
        widgetOTPSMS.setData({maskedMobileNumber: this.maskedMobileNumber});
      }

      if (tabToShow === 'token' && tab.dataset.stateId === '1') {
        tab.classList.remove('sgx-hidden');
        tab.classList.add('sgx-tab--active');

        this._dialog.querySelector('widget-otp-token')
          .classList.remove('sgx-hidden');
      }

    })
  }

  _resetTabVisibility() {
    this._tabs.querySelectorAll('.sgx-tab').forEach(tab => {
      tab.classList.remove('sgx-hidden');
    });
  }

  disconnectedCallback() {
    this._tabs && this._tabs.removeEventListener('stateChange', this._onTabChange);
    this._setEventListener(false);
  }
}

customElements.define('cmp-otp-prompt', withInitDOM(CmpOTPPrompt));
