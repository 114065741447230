const template = (id) => `
  <sgx-dialog id="${id}" role="dialog">
    <h2 data-dialog="title" data-i18n="app.widget-portfolio.account-details.dialog.title"></h2>
    <widget-portfolio-account-details data-dialog="body"></widget-portfolio-account-details>
    <button class="sgx-button--primary sgx-button--small" data-i18n="app.widget-portfolio.account-details.dialog.button.update" data-dialog="ok"></button>
    <button class="sgx-button--link sgx-button--small" data-i18n="app.widget-portfolio.account-details.dialog.button.dismiss" data-dialog="cancel"></button>
  </sgx-dialog>
`;

export default template;
