import i18n from 'sgx-localisation-service';

const tableStateData = {
  'order': ['check', 'serialNo', 'securityName', 'freeBalance', 'quantity', 'rate', 'fees', 'status'],
  'columns': {
    'check': {
      'show': false
    },
    'serialNo': {
      'show': true
    },
    'securityName': {
      'show': true
    },
    'freeBalance': {
      'show': true
    },
    'quantity': {
      'show': false
    },
    'rate': {
      'show': false
    },
    'fees': {
      'show': false
    },
    'status': {
      'show': false
    }
  },
  'filters': {},
  'sorts': {}
};

const tableConfig = {
  'states': {
    activeStateId: 0,
    states: [
      {
        label: 'All',
        data: tableStateData,
        editable: false
      }
    ]
  },
  'options': {
    'model': 'sgx-worker-model',
    'downloadData': false, // show download button
    'columnsConfiguration': false, // show column configure button to hide/show and reorder columns
    'filterable': false, // enable filtering
    'sortable': true, // enable sorting
    'localise': false, // enable localisation
    'reorderable': false, // enable column reordering
    'autoWidth': true, // enable table column width expanding to fit container
  },
  // column configuration, shared with data model
  'columns': {
    'check': {
      'resizable': false,
      'autoWidth': false,
      'minWidth': 40,
      'label': '',
      'tooltip': '',
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'star'
      },
      'filter': false,
      'sort': false
    },
    'serialNo': {
      'resizable': false,
      'autoWidth': false,
      'minWidth': 40,
      'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.table.column.serial-no'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'filter': false,
      'sort': false
    },
    'securityName': {
      'resizable': false,
      'minWidth': 40,
      'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.table.column.security-name'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'filter': false,
      'sort': {
        'type': 'text'
      }
    },
    'freeBalance': {
      'resizable': false,
      'minWidth': 40,
      'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.table.column.free-balance'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'number',
        'decimalPlaces': 0
      },
      'filter': false,
      'sort': false
    },
    'quantity': {
      'resizable': false,
      'minWidth': 40,
      'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.table.column.quantity'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'input-button',
        'formatThousands': true,
        'decimalPlaces': 0
      },
      'filter': false,
      'sort': false
    },
    'rate': {
      'resizable': false,
      'minWidth': 40,
      'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.table.column.rate'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'number',
        'decimalPlaces': 2,
        'padZeros': true
      },
      'filter': false,
      'sort': false
    },
    'fees': {
      'resizable': false,
      'minWidth': 40,
      'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.table.column.fees'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'number',
        'decimalPlaces': 2,
        'padZeros': true,
        'replaceZero': false
      },
      'filter': false,
      'sort': false
    },
    'status': {
      'resizable': false,
      'minWidth': 40,
      'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.table.column.status'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'filter': false,
      'sort': false
    }
  },
  'decorators': {
    'rows': [
      {
        'name': 'star',
        'config': {
          'cellToDecorate': 'check'
        }
      },
      {
        'name': 'input-button',
        'config': {
          'cellToDecorate': ['quantity'],
          'shouldDisable': (rowData, tableConfig) => {
            return !(rowData.check && tableConfig.states.states[0].data.columns.check.show);
          }
        }
      },
      {
        'name': 'price-movement-color',
        'config': {
          'cellToDecorate': ['status'],
          'cellMovement': 'statusColorInd',
          'colors': {
            'positive': ['green', null],
            'negative': ['red', null],
            'neutral': ['black', null]
          }
        }
      }
    ]
  },
  'layout': {
    'desktop': {
      'type': 'infinite',
      'rowHeight': 32,
      'horizontalScrollStep': 96,
      'verticalScrollStep': 96,
      'scrollbar': true,
      'showAllRows': false
    },
    'mobile': {
      'type': 'paged',
      'cellPerRow': [2, 2, 2, 2],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5
    },
    'tablet': {
      'type': 'paged',
      'cellPerRow': [2, 5, 5, 5],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5
    }
  }
};

const progressNavTranslations = {
  next: i18n.getTranslation('app.widget-portfolio-transfer-securities.button.next'),
  confirm: i18n.getTranslation('app.widget-portfolio-transfer-securities.button.confirm'),
  dismiss: i18n.getTranslation('app.widget-portfolio-transfer-securities.button.dismiss'),
  submit: i18n.getTranslation('app.widget-portfolio-transfer-securities.button.submit'),
  back: i18n.getTranslation('app.widget-portfolio-transfer-securities.button.back'),
  new: i18n.getTranslation('app.widget-portfolio-transfer-securities.button.new')
};

const progressNavs = [
  {
    prev: '',
    next: progressNavTranslations.next
  },
  {
    prev: progressNavTranslations.dismiss,
    next: progressNavTranslations.confirm
  },
  {
    prev: progressNavTranslations.dismiss,
    next: progressNavTranslations.submit
  },
  {
    prev: progressNavTranslations.back,
    next: progressNavTranslations.new
  }
];

const visibilityStateColumns = {
  step1Empty: ['serialNo', 'freeBalance'],
  step1Selected: ['check', 'freeBalance', 'quantity', 'rate'],
  step2: ['freeBalance', 'quantity', 'fees'],
  step3: ['freeBalance', 'quantity', 'fees'],
  step4: ['quantity', 'fees', 'status']
};

const fieldStepStates = {
  step1NoSetup: 'step1NoSetup',
  step1Empty: 'step1Empty',
  step1Selected: 'step1Selected',
  step2: 'step2',
  step3: 'step3',
  step4: 'step4',
}

export {
  tableConfig,
  tableStateData,
  visibilityStateColumns,
  progressNavs,
  fieldStepStates
};
