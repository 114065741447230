/**
 * Theme utilities for the application.
 */
class ThemesUtils {
  /**
   * Constructor initializing properties and underlying theme loader setup.
   */
  constructor() {
    this._theme = 'light';
    this._availableThemes = ['light', 'dark'];

    const localImporter = (theme) => import(/* webpackChunkName: "[request]" */`./../theme-${theme}.scss`);
    this._loadTheme = require('sgx-base-code/styles/themes/loadTheme').bind(null, localImporter);
  }
  /**
   * Loads a theme.
   * @returns the current theme
   */
  loadTheme(theme) {
    if (theme !== this._theme && this._availableThemes.indexOf(theme) !== -1) {
      this._loadTheme.call(null, theme);
      this._theme = theme;
    }
  }
  /**
   * Gets the current theme.
   * @returns the current theme
   */
  get currentTheme() {
    return this._theme;
  }
  /**
   * Gets the available themes.
   * @returns the available themes
   */
  get availableThemes() {
    return this._availableThemes;
  }
  /**
   * Gets the utils shared instance.
   * @returns the utils shared instance
   */
  static get instance() {
    if (!ThemesUtils._instance) {
      ThemesUtils._instance = new ThemesUtils();
    }
    return ThemesUtils._instance;
  }
}

export default ThemesUtils.instance;