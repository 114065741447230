import i18n from '@sgx/sgx-localisation-service';

const constants = {
  FULL_DATE_FORMAT: i18n.getTranslation('app.widget-corporate-actions-list.date-format'),
  CURRENT_DATE_TIME_FORMAT: 'DD MMM YYYY, hh:mm A',
  MINIMUM_ROW_VISIBLE_SIZE: 5,
  DEFAULT_TABLE_HEIGHT: '202px',
  TABLE_TOOLBAR_AND_HEADER_HEIGHT: 43,
  TABLE_ROW_HEIGHT: 32
};

const states = {
  activeStateId: 0,
  states: [
    {
      label: 'All',
      data: {
        order: ['securityName', 'type', 'electionStart', 'electionEnd', 'particulars'],
        columns: {
          securityName: {
            show: true
          },
          type: {
            show: true
          },
          electionStart: {
            show: true
          },
          electionEnd: {
            show: true
          },
          particulars: {
            show: true
          }
        },
        filters: {},
        sorts: {}
      },
      editable: false
    }
  ]
}

const options = {
  downloadData: false,
  columnsConfiguration: false,
  filterable: false,
  sortable: true,
  localise: false,
  reorderable: false,
  resizable: true,
  autoWidth: true
}

const layout = {
  desktop: {
    type: 'infinite',
    rowHeight: 32,
    horizontalScrollStep: 96,
    verticalScrollStep: 96,
    scrollbar: true,
    showAllRows: false
  },
  mobile: {
    type: 'paged',
    cellPerRow: [2, 2, 2, 2, 2, 2],
    noRowsDisplayedByDefault: 10,
    noRowsToAddStep: 5
  },
  tablet: {
    type: 'paged',
    cellPerRow: [2, 2, 2, 2],
    noRowsDisplayedByDefault: 10,
    noRowsToAddStep: 5
  }
}

// Individual columns
const securityName = {
  minWidth: 40,
  label: i18n.getTranslation('app.widget-corporate-actions-list.columns.name'),
  header: {
    type: 'text'
  },
  cell: {
    type: 'link',
    newTab: true
  },
  filter: false,
  sort: false
}

const type = {
  minWidth: 40,
  label: i18n.getTranslation('app.widget-corporate-actions-list.columns.type'),
  header: {
    type: 'text'
  },
  cell: {
    type: 'text'
  },
  filter: false,
  sort: false
}

const electionStart = {
  minWidth: 40,
  label: i18n.getTranslation('app.widget-corporate-actions-list.columns.start'),
  header: {
    type: 'text'
  },
  cell: {
    type: 'text'
  },
  filter: false,
  sort: false
}

const electionEnd = {
  minWidth: 50,
  label: i18n.getTranslation('app.widget-corporate-actions-list.columns.end'),
  header: {
    type: 'text'
  },
  cell: {
    type: 'text'
  },
  filter: false,
  sort: false,
}

const particulars = {
  minWidth: 50,
  label: i18n.getTranslation('app.widget-corporate-actions-list.columns.particulars'),
  header: {
    type: 'text'
  },
  cell: {
    type: 'text'
  },
  filter: false,
  sort: false,
}

// Base table config
const defaultTableConfig = {
  tabs: false,
  states,
  options,
  layout,
  columns: {
    securityName,
    type,
    electionStart,
    electionEnd,
    particulars
  }
}

// Base config altered for dialog context
const dialogTableConfig = {
  tabs: false,
  states,
  options,
  layout,
  columns: {
    securityName: {
      ...securityName,
      autoWidth: false,
      minWidth: 260
    },
    type: {
      ...type,
      autoWidth: false,
      minWidth: 140
    },
    electionStart: {
      ...electionStart,
      autoWidth: false,
      minWidth: 180
    },
    electionEnd: {
      ...electionEnd,
      autoWidth: false,
      minWidth: 180
    },
    particulars: {
      ...particulars,
      autoWidth: true,
      minWidth: 180
    }
  }
}

export {
  constants,
  defaultTableConfig,
  dialogTableConfig
}
