import SgxDataModelToolBaseProto from '../../sgx-data-model-tool-base';
import tmpl from './sgx-data-model-tool-display-latest-update.html';
import i18n from 'sgx-localisation-service';
import DateService from 'sgx-date-time-service';
import { withInitDOM } from 'sgx-base-code';

/**
 * Display latest update of displayed list data (e.g. "As of 01 Apr 2019 03:55 PM").
 */
class SgxDataModelToolDisplayLatestUpdate extends SgxDataModelToolBaseProto {
  constructor() {
    super();

    // Bound methods
    this._onModelUpdate = this._onModelUpdate.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('sgx-data-model-tool-display-latest-update');
    this._dateInfo = this.querySelector('.sgx-data-model-tool-display-latest-update__text');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
    if (this._model) {
      this._model.removeEventListener('update', this._onModelUpdate);
    }
  }

  setConfig({model}) {
    this._model = model;
    this._model.addEventListener('update', this._onModelUpdate);
  }

  setDateInfo(date) {
    if (date) {
      const isStatic = this.hasAttribute('static-text');
      this._dateInfo.textContent = isStatic ? i18n.getTranslation('sgx-data-model-tool-display-latest-update.note') : i18n.getTranslation(
        'app.shared-text.list-details.header.date-time-requested', {date});
    }
  }

  _onModelUpdate(event) {
    switch (event.detail.action) {
      case 'setData':
      case 'getDataRange':
        const date = this._getFormattedDate(Date.now());
        this.setDateInfo(date);
        break;
      default:
        break;
    }
  }

  _getFormattedDate(date) {
    return DateService(date).format(i18n.getTranslation('app.shared-text.list-details.header.date-format'));
  }
}

customElements.define(
  'sgx-data-model-tool-display-latest-update',
  withInitDOM(SgxDataModelToolDisplayLatestUpdate)
);
