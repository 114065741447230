import ConfigService from 'sgx-config-service';
import { FetchUtils, Utils } from 'sgx-base-code';
import SecuritiesService from 'sgx-securities-service';
import CorporateInformationService from 'sgx-corporate-information-service';
import * as StocksService from 'sgx-stocks-service';
import { formatThousands } from 'utils/price-util';
import { reflect } from 'promise-util';

const { extend } = Utils;
const { PRODUCT_CODES } = SecuritiesService.Constants;
const SCREENER_FIELD_KEYS = StocksService.StocksConstants.SCREENER_FIELD_KEYS;

let instance = null;

/**
 * Stockfacts related services aggregator.
 * It allows the retrieval of the Stockfacts token from the CMS before using it for a series of aggregated calls to Refinitiv in one shot.
 * @module stockfacts-aggregator
 */
class StockfactsAggregator {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  get STOCKFACTS_TOKEN_ENDPOINT() {
    return ConfigService.env.api['stockfacts-token'];
  }

  /**
   * Retrieves the Stockfacts token for further calls.
   * @return {Promise} a promise with the token
   */
  _getStockfactsToken() {
    return FetchUtils.fetchJSON(this.STOCKFACTS_TOKEN_ENDPOINT, {
      method: 'GET',
      mode: 'cors',
    })
    .then(response => {
      const token = response.data && response.data.stockfactsScreenerToken;
      if (!token) {
        throw new Error('Cannot retrieve Stockfacts token');
      }
      return token;
    })
  }

  /**
   * Queries Corporate Information API based on given Securities API stock code
   */
  getScreenerData() {
    return Promise.all([
      StocksService.getAllStocks(),
      SecuritiesService.getStaticData(),
      SecuritiesService.getAllGtis(),
      SecuritiesService.getPrices({ exclude: 'bonds', params: 'nc,lt,p,c,sip' })
    ]).then(data =>
      this._mergeScreenerData(data)
    ).catch(err => console.error(err))
  }

  /**
   * Get the aggregated product details from several services (SecuritiesService and StocksService)
   * based on security code
   *
   * @param {string} code - Security code
   * @return {Promise} a promise with aggregated product details
   */
  getProductData(code) {
    return Promise.all([
      SecuritiesService.getStaticData({ code }),
      SecuritiesService.getMetaDataByStockCode(code)
    ])
      .then(data => this._aggregateProductPageData(data));
  }

  _aggregateProductPageData(data) {
    const staticData = data[0];
    const metadata = data[1];

    if (!staticData) {
      return Promise.reject('Error fetching product data');
    }

    let productDetail = {};
    productDetail.title = staticData.n;
    productDetail.type = staticData.type;
    productDetail.sgxStockCode = staticData.nc;

    if (productDetail.type === PRODUCT_CODES.DL_CERTIFICATES) {
      productDetail.leveraged = true;
    }

    if (metadata) {
      productDetail.ibmCode = metadata.ibmCode;
      productDetail.fullName = metadata.fullName;
      productDetail.isinCode = metadata.isinCode;
      productDetail.masterCode = metadata.masterCode;
    }

    if (this._checkIfStock(productDetail.type)) {
      return StocksService.getRIC(productDetail.sgxStockCode)
        .then(ric => {
          if (!ric) {
            console.warn('No RIC code can be found for this product');
            return Promise.resolve();
          }

          productDetail.ric = ric;
          return this._getStockData(ric, productDetail.ibmCode);
        })
        .then(stockData => {
          productDetail.stockData = stockData;
          return Promise.resolve(productDetail);
        });
    }

    return Promise.resolve(productDetail);
  }

  _getStockData(code, ibmCode) {
    const stockPromises = [
      StocksService.getSnapshotReport(code),
      StocksService.getFinancialReports(code),
      StocksService.getRatiosReports(code),
      StocksService.getShareholderReports(code),
      StocksService.getGeneralInfo(code),
      StocksService.getNewsItems(code),
      CorporateInformationService.getCorporateInformation({ ibmcode: ibmCode })
    ];

    return Promise.all(stockPromises.map(reflect)).then(resolvedPromises => {
      const hasStockError = this._checkIfHasStockError(resolvedPromises);
      const [snapshotReport, financialReports, ratiosReport, shareholderReports, generalInfo, newsItems, corpInfo] =
        resolvedPromises.map(report => {
          return report.resolved ? report.data : null;
        });
      let corporateInfo = ((corpInfo || {}).data || [])[0] || {};

      return {
        hasStockError,
        snapshotReport,
        financialReports,
        ratiosReport,
        shareholderReports,
        generalInfo,
        newsItems,
        corporateInfo
      };
    });
  }

  _checkIfHasStockError(reports) {
    for (let i = 0; i < reports.length; i++) {
      if (!reports[i].resolved && reports[i].data && reports[i].data.hasMissingCompanyData === false) {
        return true;
      }
    }
    return false;
  }

  _checkIfStock(type) {
    return !!~[PRODUCT_CODES.REITS, PRODUCT_CODES.STOCKS, PRODUCT_CODES.BUSINESS_TRUSTS].indexOf(type);
  }

  _mergeScreenerData(dataArr) {
    let result = {};
    const screenerData = dataArr[0] || {};
    const staticData = dataArr[1] || {};
    const gtiData = dataArr[2] || {};
    const prices = dataArr[3] || {};

    const stocks = staticData[PRODUCT_CODES.STOCKS] || [];
    const businessTrusts = staticData[PRODUCT_CODES.BUSINESS_TRUSTS] || [];
    const reits = staticData[PRODUCT_CODES.REITS] || [];

    stocks.concat(businessTrusts, reits).forEach(staticItem => {
      const code = staticItem.nc;
      if (code) {
        result[code] = extend({}, prices[code] || {}, screenerData[code] || {}, staticData[code] || {}, gtiData[code] || {}, {relatedContent: null});
        switch (prices[code].sip.toUpperCase()) {
          case 'SP':
            result[code].sip = 'SP'; // for special purpose acquisition company (SPAC)
            break;
          case 'TT':
            result[code].sip = 'TT'; // for Transaction Tax indicator (TT)
            break;
        }
      }
    });

    return result;
  }

  formatTableData(stocksData) {
    const formattedData = {};

    Object.keys(stocksData).forEach(key => {
      const item = extend({}, stocksData[key]);
      const currency = item[SCREENER_FIELD_KEYS.CURRENCY];
      const marketCapVal = item[SCREENER_FIELD_KEYS.MARKET_CAP];
      const totalRevVal = item[SCREENER_FIELD_KEYS.TOTAL_REVENUE];

      item[SCREENER_FIELD_KEYS.MARKET_CAP] = {
        label: (currency && marketCapVal) ? currency + ' ' + formatThousands(marketCapVal, null, true) : '-',
        value: marketCapVal
      };

      item[SCREENER_FIELD_KEYS.TOTAL_REVENUE] = {
        label: (currency && totalRevVal) ? currency + ' ' + formatThousands(totalRevVal, null, true) : '-',
        value: totalRevVal
      };

      formattedData[key] = item;
    });

    return formattedData;
  }
}

/* Export the singleton */
const stockfactsAggregatorInstance = new StockfactsAggregator();
export default stockfactsAggregatorInstance;
