module.exports = (id) => {
  return `
    <sgx-dialog id="${id}" role="dialog">
      <h2 data-dialog="title"></h2>
      <div data-dialog="body">
        <button class="sgx-button--low-emp sgx-icon sgx-data-model-tool-search-reset" data-action="reset"></button>
        <sgx-input-text class="sgx-data-model-tool-search-input" data-i18n="[placeholder]app.sgx-data-model-tool-search.search-placeholder"></sgx-input-text>
      </div>
      <button disabled class="sgx-button--primary" data-dialog="ok" data-i18n="sgx-filters-dialog.labels.apply"></button>
      <button class="sgx-button--text" data-dialog="cancel" data-i18n="sgx-filters-dialog.labels.dismiss"></button>
    </sgx-dialog>
  `;
};
