import i18n from '@sgx/sgx-localisation-service';
import ConfigService from 'sgx-config-service';

const lang = i18n.getLanguage().replace(/-/g, "_");

// Load the svg images
import ACCOUNT_OPENING_BG_IMAGE from 'assets/images/dashboard/Account-Opening-v2.jpg';
import CDP_BG_IMAGE from 'assets/images/dashboard/CDP-Services-v2.jpg';
import RESOURCES_BG_IMAGE from 'assets/images/dashboard/Resources-v2.jpg';

const constants = {
  accountOpening: {
    TITLE: i18n.getTranslation('app.widget-dashboard-quick-links.accountopening.title'),
    BANNER_IMAGE: {
      url: ACCOUNT_OPENING_BG_IMAGE,
      alt: 'Banner Image',
    },
    LINKS: [
      {
        url: ConfigService.links.ACCOUNT_OPENING,
        text: i18n.getTranslation('app.widget-dashboard-quick-links.accountopening.links.individualcdp'),
      },
      {
        url: ConfigService.links.SGX_V2_CREATE_JOINT_ACCOUNT[lang],
        text: i18n.getTranslation('app.widget-dashboard-quick-links.accountopening.links.jointcdp'),
      },
      {
        url: ConfigService.links.SGX_V2_CREATE_CORPORATE_ACCOUNT[lang],
        text: i18n.getTranslation('app.widget-dashboard-quick-links.accountopening.links.corporatecdp'),
      },
      {
        url: ConfigService.links.SGX_V2_RETAIL_BROKERS[lang],
        text: i18n.getTranslation('app.widget-dashboard-quick-links.accountopening.links.tradingaccount'),
      },
    ],
  },
  cdpServices: {
    TITLE: i18n.getTranslation('app.widget-dashboard-quick-links.cdpservices.title'),
    BANNER_IMAGE: {
      url: CDP_BG_IMAGE,
      alt: 'Banner Image',
    },
  },
  resources: {
    TITLE: i18n.getTranslation('app.widget-dashboard-quick-links.resources.title'),
    BANNER_IMAGE: {
      url: RESOURCES_BG_IMAGE,
      alt: 'Banner Image',
    },
    LINKS: [
      {
        url: ConfigService.links.SGX_V2_INVESTOR_BEGINNER_GUIDE[lang],
        text: i18n.getTranslation('app.widget-dashboard-quick-links.resources.links.beginnerguide'),
      },
      {
        url: ConfigService.links.SGX_V2_CDP_BUY_IN[lang],
        text: i18n.getTranslation('app.widget-dashboard-quick-links.resources.links.cdpreports'),
      },
      {
        url: ConfigService.links.INVESTORS_PORTAL_PORTFOLIO_LENDING_POOL[lang],
        text: i18n.getTranslation('app.widget-dashboard-quick-links.resources.links.securitiesborrowing'),
      },
      {
        url: ConfigService.links.SIP_QUALIFICATION,
        text: i18n.getTranslation('app.widget-dashboard-quick-links.resources.links.sip'),
      },
      {
        url: ConfigService.links.ACADEMY,
        text: i18n.getTranslation('app.widget-dashboard-quick-links.resources.links.sgxacademy'),
      },
      {
        url: ConfigService.links.CPF,
        text: i18n.getTranslation('app.widget-dashboard-quick-links.resources.links.cpf'),
      },
      {
        url: ConfigService.links.SGX_V2_dashboard.QUICK_LINKS.RESOURCES.LINKS.SIAS_URL,
        text: i18n.getTranslation('app.widget-dashboard-quick-links.resources.links.sias'),
      },
    ],
  },
};

export { constants };
