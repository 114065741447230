import i18n from 'sgx-localisation-service';

const FILTER_STATE = {
  'operator': 'and',
  'conditions': [
    {
      'columnId': 'n',
      'type': 'text',
      'method': 'contains',
      'value': '',
      'valid': false
    },
    {
      'columnId': 'nc',
      'type': 'text',
      'method': 'contains',
      'value': '',
      'valid': false
    },
    {
      'columnId': 'rmk',
      'type': 'text',
      'method': 'contains',
      'value': null,
      'valid': false
    },
    {
      'columnId': 'm',
      'type': 'text',
      'method': 'contains',
      'value': null,
      'valid': false
    }
  ]
};

const FILTER_CONFIG = {
  // column configuration, shared with data model
  'editable': false,
  'hideOperator': true,
  'columns': {
    'n': {
      'label': i18n.getTranslation('app.securities.list-details.toolbar.filter-labels.name'),
      'type': 'text',
      'method': 'contains'
    },
    'nc': {
      'label': i18n.getTranslation('app.securities.list-details.toolbar.filter-labels.code'),
      'type': 'text',
      'method': 'contains'
    },
    'rmk': {
      'label': i18n.getTranslation('app.securities.list-details.toolbar.filter-labels.activities'),
      'type': 'select',
      'method': 'contains',
      'options': i18n.getTranslation('app.securities.list-details.toolbar.activities-options', { returnObjects: true })
    },
    'm': {
      'label': i18n.getTranslation('app.securities.list-details.toolbar.filter-labels.market'),
      'type': 'select',
      'method': 'contains',
      'options': i18n.getTranslation('app.securities.list-details.toolbar.market-options', { returnObjects: true })
    }
  }
};

const SORT_VALUES = {
  'top-value': 'v||dsc||number',
  'top-volume': 'vl||dsc||number',
  'advancers': 'c||dsc||number',
  'advancers-percent': 'p||dsc||number',
  'decliners': 'c||asc||number',
  'decliners-percent': 'p||asc||number',
  'a-z': 'n||asc||text'
};

const FAVORITES_TAB = 'favourites';

const FAVORITES_FILTER = {
  isFavorite: {
    method: 'equals',
    type: 'text',
    value: 'true'
  }
};

export {
  FILTER_CONFIG,
  FILTER_STATE,
  SORT_VALUES,
  FAVORITES_TAB,
  FAVORITES_FILTER
};
