import SgxTableDecoratorBase from '@sgx/sgx-table/src/sub-components/sgx-table-decorator/sgx-table-decorator-base';

class SgxTableDecoratorPriceMovement extends SgxTableDecoratorBase {
  decorate(decoratorObject, action) {
    var config = decoratorObject.decoratorConfig;
    var rowData = decoratorObject.rowData;
    var rowElem = decoratorObject.element;
    var cells = Array.isArray(config.cellToDecorate) ? config.cellToDecorate : [config.cellToDecorate];

    cells.forEach(cell => {
      var cellElem = rowElem.querySelector(`[data-column-id = ${cell}]`);

      if (rowData[config.cellMovement] < 0) {
        cellElem.dataset.priceMovement = 'down';
      } else if (rowData[config.cellMovement] > 0) {
        cellElem.dataset.priceMovement = 'up';
      } else {
        delete cellElem.dataset.priceMovement;
      }
    });
  }
}

customElements.define('sgx-table-decorator-price-movement', SgxTableDecoratorPriceMovement);
const decorator = document.createElement('sgx-table-decorator-price-movement');
document.body.appendChild(decorator);
export default decorator;
