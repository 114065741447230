import { withInitDOM } from 'sgx-base-code';
import tmpl from './template-logout.html';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';
import ConfigService from 'sgx-config-service';

/**
 * Logout page.
 */
class TemplateLogout extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-logout');
    this.logoutTitleElem = this.querySelector('.logout-title');
    this.loginBtnElem = this.querySelector('.login-button');
    this.logoutBtnElem = this.querySelector('.logout-button');
    this._survey = this.querySelector('.logout-survey');
    this._mockPermissions = StoreRegistry.mockPermissions;
    this._userStore = StoreRegistry.user;
  }

  connectedCallback() {
    this._survey.innerHTML = i18n.getTranslation('app.logout.survey_html', { link: ConfigService.links.SURVEY_LINK });
    this.setEventListeners(true);
  }

  disconnectedCallback() {
    this.setEventListeners(false);
  }

  setEventListeners(state) {
    const funcName = state ? 'addEventListener' : 'removeEventListener';

    this.loginBtnElem[funcName]('click', e => {
      e.preventDefault();
      window.location.href = '/login.html'
    });
  }

  setData() {
    this.updateLogoutState(StoreRegistry.cdpSession.getData()['session-timeout']);
  }

  updateLogoutState(sessionTimeout) {
    if (sessionTimeout) {
      this.logoutTitleElem.textContent = i18n.getTranslation('app.logout.session-timeout-description');
      this.loginBtnElem.classList.remove('sgx-hidden');
      this.logoutBtnElem.classList.remove('sgx-button--primary');
    } else {
      this.logoutTitleElem.textContent = i18n.getTranslation('app.logout.description');
      this.loginBtnElem.classList.add('sgx-hidden');
      this.logoutBtnElem.classList.add('sgx-button--primary');
    }
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

customElements.define('template-logout', withInitDOM(TemplateLogout));
