import SgxTableDecoratorBase from '@sgx/sgx-table/src/sub-components/sgx-table-decorator/sgx-table-decorator-base';

/**
 * A custom HTML element used as a decorator for portfolio share transactions
 * @module sgx-table-decorator-cdp-notification
 * @type {SgxTableDecoratorBase}
 */

class SgxTableDecoratorCdpNotification extends SgxTableDecoratorBase {
  constructor() {
    super();
    this._onCellClick = this._onCellClick.bind(this);
  }

  initDOM() {
    this.style.display = 'none';
  }

  disconnectedCallback() {
    if (this._tableList) {
      this._tableList.removeEventListener('click', this._onCellClick);
    }
  }

  decorate(decoratorObject) {
    const config = decoratorObject.decoratorConfig;
    const tableConfig = decoratorObject.tableConfig;
    const rowData = decoratorObject.rowData;
    const rowElem = decoratorObject.element;
    const notification = rowData && rowData.cdpNotifications && rowData.cdpNotifications.value || '';
    const table = rowElem.closest('sgx-table');
    this._cellsToDecorate = Array.isArray(config.cellToDecorate) ? config.cellToDecorate : [config.cellToDecorate];

    this._cellsToDecorate.forEach(cell => {
      const elem = rowElem.querySelector(`[data-column-id=${cell}]`);
      if (notification) {
        elem.setAttribute('data-notification', notification);
      } else {
        elem.removeAttribute('data-notification');
      }
    });

    if (!table) {
      return;
    }

    this._tableList = table.querySelector('sgx-table-list');
    this._tableList.addEventListener('click', this._onCellClick);
  }

  _onCellClick(evt) {
    const target = evt.target.closest('[data-column-id]');
    const {columnId} = target.dataset;
    if (!!~this._cellsToDecorate.indexOf(columnId)) {
      evt.preventDefault();
      const rowId = target.parentElement.dataset.rowId;

      target.dispatchEvent(new CustomEvent('row-notification-click', {
        bubbles: true,
        detail: {
          colId: columnId,
          rowId
        }
      }));
    }
  }
}

customElements.define('sgx-table-decorator-cdp-notification', SgxTableDecoratorCdpNotification);
const decorator = document.createElement('sgx-table-decorator-cdp-notification');
document.body.appendChild(decorator);
export default decorator;
