import { take } from 'rxjs/operators';
import i18n from 'sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';

/**
* @function fetchDataMiddleware
* @desc Middleware used to change the page title of the dashboard page depeding whether if the user is authenticated or not
* @param  {Object} router Router5 instance of application
* @return {*} {}
*/
function dashboardTitleMiddleware(/* router */) {
  return (newState, previousState, doneFn) => {
    let name = newState.name;
    if (name === 'dashboard') {
      StoreRegistry.user
        .pipe(take(2))
        .subscribe(({ name }) => {
          newState.pageData.pageTitle = i18n.getTranslation('app.dashboard.page-title.authenticated', { name });
          doneFn();
        });
    } else {
      doneFn();
    }
  };
}

export default dashboardTitleMiddleware;
