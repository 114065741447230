import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-list-row-tiles.html';

class CmpListTiles extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {}

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-tiles');
    this._setAllSelectors = this._setAllSelectors.bind(this);
    this._setAllSelectors();
  }

  /**
   * Sets value to the html template
   * @param {object} [data]  
   * @param {string} [data.title]
   * @param {object} [data.body]
   * @param {string} [data.body.name]
   * @param {object} [data.body.link]
   * @param {string} [data.body.link.href]
   * @param {string} [data.body.link.text]
   *
   * Object Structure:
   * {
   *  title: { main, sub },
   *  body: { name, link: { href, text }},
   * }
   * 
   * @returns void
   */
  setData(data) {
    if (!data) return;
    this._data = data;

    const setTextData = [
      {
        element: this._mainTitle,
        value: this._data.title.main,
      },
      {
        element: this._subTitle,
        value: this._data.title.sub,
      },
      {
        element: this._companyName,
        value: this._data.body.name || '',
      },
    ];

    for (const OBJ of setTextData) {
      const { element, value } = OBJ;
      this._setDataToElement(element, value);
    }

    this._setLink();
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  _setLink() {
    const anchorElement = document.createElement('a');
    this._linkText.appendChild(anchorElement);
    anchorElement.setAttribute('href', this._data.body.link.href);
    this._setDataToElement(anchorElement, this._data.body.link.text);
  }

  _setDataToElement(element, value) {
    if (value === '') {
      element.parentElement.removeChild(element);
      return;
    }
    if (typeof value === 'string') {
      element.innerText = value.trim();
    } else if (Array.isArray(value)) {
      for (const spanValue of value) {
        const spanEle = document.createElement('span');
        element.appendChild(spanEle);
        spanEle.classList.add('cmp-list-row-tiles--title-tag');
        spanEle.innerText = spanValue.trim();
      }
    }
  }

  _setAllSelectors() {
    this._mainTitle = this.querySelector('.cmp-list-row-tiles--title-top');
    this._subTitle = this.querySelector('.cmp-list-row-tiles--title-bottom');
    this._companyName = this.querySelector('.cmp-list-row-tiles--body-company-name');
    this._linkText = this.querySelector('.cmp-list-row-tiles--body-link');
  }
}

customElements.define('cmp-list-row-tiles', withInitDOM(CmpListTiles));
