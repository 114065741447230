import { withInitDOM } from 'sgx-base-code';
import refinitivLogo from '../../assets/images/refinitive-logo.png';
import i18n from 'sgx-localisation-service';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';

/**
 * Component to show the terms and conditions message for stock details pages
 * @module cmp-stocks-terms
 * @type {HTMLElement}
 */
class CmpStocksTerms extends HTMLElement {
  constructor() {
    super();
  }

  async initDOM() {
    this.classList.add('cmp-stocks-terms');
    this._lang = StoreRegistry.appSettings.getData('lang') || 'en';
    const sgxLogoPath = await import(`../../assets/images/SGX_Securities_logo_${this._lang}.png`);
    const terms = i18n.getTranslation('app.cmp-stocks-terms.terms_html', {link: ConfigService.links.SGX_V2_STOCKFACTS_TERMS[this._lang.replace(/-/g, '_')]})

    this.innerHTML = `
        <div class="cmp-stocks-terms-container">
          <img src="${sgxLogoPath.default}" alt="SGX Logo" class="cmp-stocks-terms-sgx">
          <span class="cmp-stocks-terms-separator"></span>
          <img src="${refinitivLogo}" alt="Refinitiv Logo" class="cmp-stocks-terms-refinitive">
        </div>
        ${terms}
      `;
  }

  connectedCallback() {
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('cmp-stocks-terms', withInitDOM(CmpStocksTerms));
