import {withInitDOM} from 'sgx-base-code';
import tmpl from './widget-settings-user-address.html';
import i18n from 'sgx-localisation-service';
import {setFieldOptions, getFormInputs, showHideEl} from 'utils/form-util';
import {fromEvent, merge} from 'rxjs';
import StoreRegistry from 'stores/store-registry';
import UserService from 'services/user-service';
import {debounceTime} from 'rxjs/operators';
import get from 'lodash/get';

/**
 * Address details widget.
 */
class UserAddressWidget extends HTMLElement {
  constructor() {
    super();
    this._sessionStore = StoreRegistry.cdpSession;
    this._settingsStore = StoreRegistry.settingsUser;

    this._validateSGPostalCode = {
      setCaAddress: true,
      setMailingAddress: true,
      setResidentialAddress: true
    };

    this._countries = [];

    this._postalAddressListener = this._postalAddressListener.bind(this);
    this._setReUseAddressListener = this._setReUseAddressListener.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-user-address');

    // Residential Address Title
    this._residentialAddressTitle = this.querySelector('.residential-address-title');

    // Fieldsets
    this._residentialAddress = this.querySelector('.residential-address');
    this._mailingAddress = this.querySelector('.mailing-address');
    this._caAddress = this.querySelector('.corporate-action-address');

    // Divs grouping fields in a fieldset
    this._residentialAddressFields = this.querySelector('.residential-address-fields');
    this._mailingAddressFields = this.querySelector('.mailing-address-fields');
    this._caAddressFields = this.querySelector('.corporate-action-address-fields');

    // Country inputs for each fieldset
    this._residentialCountryInput = this._residentialAddressFields.querySelector('.country');
    this._mailingCountryInput = this._mailingAddressFields.querySelector('.country');
    this._caCountryInput = this._caAddressFields.querySelector('.country');

    // Within or outside Singapore options
    this._residentialAddressOptions = this.querySelector('.residential-address-options');
    this._mailingAddressOptions = this.querySelector('.mailing-address-options');

    // Checkbox to reuse residential address for mailing address
    this._reuseAddresses = this.querySelector('.mailing-is-residential-address');
  }

  connectedCallback() {
    this._subscriptions = [];

    //Note: order of listeners should be first, before setting default options

    this._setReUseAddressListener();

    this._updateAddressFields();

    this.autoCompleteAddress();

    // Set default address options
    this._setDefaultAddressOptions();

  }

  _setReUseAddressListener() {
    // Subscribe to change of reuse addresses checkbox
    this._subscriptions.push(fromEvent(this._reuseAddresses, 'change')
      .subscribe(() => {
        showHideEl(this._mailingAddress, !this._reuseAddresses.getValue());
        showHideEl(this._caAddress, this._caAddressRequired());

        this.reUseMailingAddress();
      }));
  }

  _setDefaultAddressOptions() {
    // Set within or outside Singapore options
    setFieldOptions(this._residentialAddressOptions, ['withinSG', 'outsideSG'], 'app.widget-settings-user-address.inputs.options');
    setFieldOptions(this._mailingAddressOptions, ['withinSG', 'outsideSG'], 'app.widget-settings-user-address.inputs.options');

    if (!this._residentialAddressOptions.getValue()) {
      this._residentialAddressOptions.setValue('withinSG');
      showHideEl(this._residentialAddress.querySelector('.country'));
    }
    if (!this._mailingAddressOptions.getValue()) {
      this._mailingAddressOptions.setValue('withinSG');
    }
  }


  _updateAddressFields() {
    // Group key field references
    const fields = [{
      fieldSet: this._residentialAddress,
      optionsField: this._residentialAddressOptions,
      addressFields: this._residentialAddressFields
    }, {
      fieldSet: this._mailingAddress,
      optionsField: this._mailingAddressOptions,
      addressFields: this._mailingAddressFields
    }];

    // Subscribe to change of within or outside Singapore options
    fields.forEach(
      address => {
        const fieldSet = address.fieldSet;
        const optionsField = address.optionsField;
        const addressFields = address.addressFields;

        // Works with change on checkbox value
        this._subscriptions.push(fromEvent(optionsField, 'change')
          .subscribe(e => {
            this._updateFields(optionsField, fieldSet, addressFields);
            showHideEl(this._caAddress, this._caAddressRequired());
          }));

        // Works with setValue on input-radio-list
        this._subscriptions.push(fromEvent(optionsField, 'validate')
          .subscribe(e => {
            this._updateFields(optionsField, fieldSet, addressFields);
            showHideEl(this._caAddress, this._caAddressRequired());
          }));

      }, this);
  }

  _setVisibilityOfResidentialOptions() {
    try {
      const hasCountry = this._settingsStore.getData().addresses[0].country;
      const funcName = !hasCountry ? 'add' : 'remove';
      this._residentialAddressOptions.classList[funcName]('sgx-hidden');
      this._residentialCountryInput.classList[funcName]('sgx-hidden');
    } catch (e) {
      console.error('Error in reading residential address country.')
    }

  }

  /*
  * @desc: Handle the DOM manipulation for country set in three different types of address fields
  * [Residential, Mailing, Corporate]
  * */
  _handleDOM__COUNTRIES() {

    // Set countries input select options
    const countriesOptions = this._countries.map(country => {
      return {value: country.id, label: country.name}
    });
    const countriesInputs = [this._residentialCountryInput, this._mailingCountryInput, this._caCountryInput];

    countriesInputs.forEach(countryInput => {
      // Set country input options
      countryInput.setOptions(countriesOptions, false, false);

      // Undo unnecessary validation on set of country input options
      countryInput.classList.remove('sgx-input--has-error');
    });
  }

  /*
  * @desc: Handle the DOM manipulation for corporate account users
  * */
  _handleDOM__CORPORATEACCOUNT() {
    if (this._isCorporateAccount) {
      this._residentialAddressTitle.textContent = i18n.getTranslation('app.widget-settings-user-address.inputs.residential._groupCorporateTitle');
      getFormInputs(this._caAddress).forEach(input => {
        input.setReadOnly(true);
      });
    } else {
      this._residentialAddressTitle.textContent = i18n.getTranslation('app.widget-settings-user-address.inputs.residential._groupTitle');
    }
  }

  /*
  * @desc: Handle the DOM manipulation for suspended accounts
  * @param {boolean} force FORCE DOM manipulation irrespective of suspended-account
  * */
  _handleDOM__SUSPENDEDACCOUNT(force) {
    if (force || this._account[0].suspended) {
      showHideEl(this._residentialAddressOptions);
      showHideEl(this._mailingAddressOptions);
      showHideEl(this._reuseAddresses, this._reuseAddresses.getValue());

      // Show country for addresses within Singapore
      showHideEl(this._residentialCountryInput, true);
    }
  }

  /*
  * @desc: Handle the DOM manipulation for residential address
  * */
  _handleDOM__RESIDENTIALADDRESS() {
    // Populate form fields with user data
    if (!!this._user.residentialAddress) {
      this.setResidentialAddress(this._user.residentialAddress);
    }
  }

  /*
  * @desc: Handle the DOM manipulation for mailing address,
  * */
  _handleDOM__MAILINGADDRESS() {
    if (!!this._user.mailingAddress && !this._reuseAddresses.getValue()) {
      // Toggle required fieldsets
      this._reuseAddresses.setValue(false);
      showHideEl(this._mailingAddress, true);
      showHideEl(this._caAddress, this._caAddressRequired());

      // Fill fields
      this.setMailingAddress(this._user.mailingAddress);
    } else {
      showHideEl(this._mailingAddress, false);
      // Set the mailing address same as residential Address for form validation
      this.setMailingAddress(this._user.residentialAddress);
    }
  }

  /*
  * @desc Handle the DOM manipulation for Corporate Address,
  * */
  _handleDOM__CORPORATEADDRESS() {
    const {caAddress, residentialAddress, mailingAddress} = this._user;

    if (!!caAddress && !!residentialAddress && residentialAddress.country !== 'SG') {
      // Toggle CA fieldset
      this._residentialAddressOptions.setValue('outsideSG');
      showHideEl(this._caAddress, this._caAddressRequired());
    }

    if (!!caAddress && !!mailingAddress && mailingAddress.country !== 'SG') {
      // Toggle CA fieldset
      this._mailingAddressOptions.setValue('outsideSG');
      showHideEl(this._caAddress, this._caAddressRequired());
    }

    if (!!caAddress) {
      const address = mailingAddress.country === 'SG' ? {...mailingAddress} : {...caAddress};
      this.setCaAddress(address);
    }
  }

  /*
  * @desc contains all the functions which manipulte DOM based on the updated values from setData
  * */
  _handleDOMUpdates() {
    const {mailingAddress, residentialAddress} = this._user;

    // Set the value of reuseAddress based on the values retrieved from BE for mailing and residential address of the user
    this._reuseAddresses.setValue(JSON.stringify(mailingAddress) === JSON.stringify(residentialAddress));

    this._handleDOM__SUSPENDEDACCOUNT(this._isCorporateAccount); // force suspended account state if account type is corporate
    this._handleDOM__CORPORATEACCOUNT();
    this._handleDOM__COUNTRIES();
    this._handleDOM__RESIDENTIALADDRESS();
    this._handleDOM__MAILINGADDRESS();
    this._handleDOM__CORPORATEADDRESS();

    this._updateAddressFields();
    this.reUseMailingAddress();
    this._setVisibilityOfResidentialOptions();
  }

  /*
  * @desc Set the data for user, account and countries which is used to manipulate the DOM,
  * based on conditions written in the specific handleDOM methods
  * */
  setData(user, account, countries) {
    let {accountType} = this._sessionStore.getData();
    this._sessionStore.subscribe(acc => {
      if (accountType !== acc.accountType && acc.accountType) {
        accountType = acc.accountType;
        this.setData(user, account, countries);
      }
    });

    this._countries = countries;
    this._user = user;
    this._account = account;
    this._isCorporateAccount = accountType === UserService.constants.ACCOUNT_STATES.CORPORATE;

    this._handleDOMUpdates();

  }

  /*
  * @desc use to auto-populate the addresses based on the postal-code entered by the user in
  * [Residential, Mailing, Corporate] address input fields
  * */
  autoCompleteAddress() {
    const residentialAddress = this._residentialAddress.querySelector('.postalCode');
    const mailingAddress = this._mailingAddress.querySelector('.postalCode');
    const caAddress = this._caAddress.querySelector('.postalCode');

    this._postalAddressListener(residentialAddress, 'setResidentialAddress', this._residentialAddressOptions);
    this._postalAddressListener(mailingAddress, 'setMailingAddress', this._mailingAddressOptions);
    this._postalAddressListener(caAddress, 'setCaAddress', {getValue: _ => 'withinSG'});
  }

  /*
  * @desc event listener to listen for change in postal-code input-field
  * and call the _updatePostalCode based on the change triggered
  * */
  _postalAddressListener(address, funcName, ref) {
    this._subscriptions.push(
      merge(
        fromEvent(address, 'input'),
        fromEvent(address, 'change')
      )
        .pipe(
          debounceTime(500),
        )
        .subscribe(e => {
          this._updatePostalCode(address, funcName, ref)
        }));
  }


  _updatePostalCode(address, funcName, ref) {
    try {
      const addressValue = address.getValue();
      if (ref.getValue() === 'withinSG' && !isNaN(+addressValue) && +addressValue > 0) {
        UserService.getAddressDetails(addressValue)
          .then(res => {

            if (res.response === null) {
              this._validateSGPostalCode[funcName] = false;
              this._formRef.validate();
              this.updateValidationCheck();
              return;
            }

            this._validateSGPostalCode[funcName] = true;

            const postalAddress = res.response.postalAddress;
            const postalAddressNew = {};

            for (let key in postalAddress) {
              if (postalAddress.hasOwnProperty(key)) {
                postalAddressNew[key.toLowerCase()] = postalAddress[key]
              }
            }

            this[funcName](postalAddressNew, funcName !== 'setCaAddress');

            this.updateValidationCheck();
          });
        this.reValidate = false;
      } else {
        this.reValidate = funcName;
        this.updateValidationCheck();
      }
    } catch (e) {
      this.updateValidationCheck();
      this.reValidate = false;
      this._validateSGPostalCode[funcName] = false;
      console.error('Unable to check postal code: ', e);
    }
  }

  updateValidationCheck() {
    this.dispatchEvent(new CustomEvent('re-validate', {
      bubbles: true
    }))
  }

  setResidentialAddress(address, updateLocalAddress) {
    !updateLocalAddress && this._residentialAddress.querySelector('.country').setValue(address.country);
    this._residentialAddress.querySelector('.postalCode').setValue(address.postalCode || address.postalcode);
    this._residentialAddress.querySelector('.streetOne').setValue(address.addressline1);
    this._residentialAddress.querySelector('.streetTwo').setValue(address.addressline2);
    this._residentialAddress.querySelector('.streetThree').setValue(address.addressline3);
    this._residentialAddress.querySelector('.streetFour').setValue(address.addressline4);
    this._residentialAddress.querySelector('.state').setValue(address.state);

    const residentialAddress = {
      country: updateLocalAddress ? 'SG' : address.country,
      postalCode: address.postalCode || address.postalcode,
      streetFour: address.addressline4,
      streetOne: address.addressline1,
      streetThree: address.addressline3,
      streetTwo: address.addressline2
    };

    const settingsAddress = {...this._settingsStore.getData()};
    if (Object.keys(settingsAddress).length) {
      settingsAddress.addresses[0] = {...settingsAddress.addresses[0], ...residentialAddress};
      this._settingsStore.setData(settingsAddress);
    }
  }

  setMailingAddress(mailingAddress, updateLocalAddress) {
    !updateLocalAddress && this._mailingAddress.querySelector('.country').setValue(mailingAddress.country);
    this._mailingAddress.querySelector('.postalCode').setValue(mailingAddress.postalCode || mailingAddress.postalcode);
    this._mailingAddress.querySelector('.streetOne').setValue(mailingAddress.addressline1);
    this._mailingAddress.querySelector('.streetTwo').setValue(mailingAddress.addressline2);
    this._mailingAddress.querySelector('.streetThree').setValue(mailingAddress.addressline3);
    this._mailingAddress.querySelector('.streetFour').setValue(mailingAddress.addressline4);
    this._mailingAddress.querySelector('.state').setValue(mailingAddress.state);

    const mailingAddressToUpdate = {
      country: updateLocalAddress ? 'SG' : mailingAddress.country,
      postalCode: mailingAddress.postalCode || mailingAddress.postalcode,
      streetFour: mailingAddress.addressline4,
      streetOne: mailingAddress.addressline1,
      streetThree: mailingAddress.addressline3,
      streetTwo: mailingAddress.addressline2
    };

    const settingsAddress = {...this._settingsStore.getData()};
    if (Object.keys(settingsAddress).length) {
      settingsAddress.addresses[1] = {...settingsAddress.addresses[0], ...mailingAddressToUpdate};
      this._settingsStore.setData(settingsAddress);
    }
  }

  setCaAddress(caAddress, updateLocalAddress) {
    // Revert For caAddress
    // streetOne === addressLine2
    // streetTwo === addressLine1
    // Note: this is not a typo!
    if (updateLocalAddress) {
      this._caAddress.querySelector('.country').setValue(caAddress.country);
    } else {
      this._caAddress.querySelector('.country').setValue('SG');
    }
    this._caAddress.querySelector('.postalCode').setValue(caAddress.postalCode || caAddress.postalcode);
    this._caAddress.querySelector('.streetOne').setValue(caAddress.addressline2);
    this._caAddress.querySelector('.streetTwo').setValue(caAddress.addressline1);
    this._caAddress.querySelector('.streetThree').setValue(caAddress.addressline3);
    this._caAddress.querySelector('.streetFour').setValue(caAddress.addressline4);

    const caAddressToUpdate = {
      country: updateLocalAddress ? 'SG' : caAddress.country,
      postalCode: caAddress.postalCode || caAddress.postalcode,
      streetFour: caAddress.addressline4,
      streetOne: caAddress.addressline2,
      streetThree: caAddress.addressline3,
      streetTwo: caAddress.addressline1
    };

    const settingsAddress = {...this._settingsStore.getData()};
    if (Object.keys(settingsAddress).length) {
      settingsAddress.addresses[2] = {...settingsAddress.addresses[0], ...caAddressToUpdate};
      this._settingsStore.setData(settingsAddress);
    }
  }

  reUseMailingAddress() {
    if (!this._reuseAddresses.getValue()) {
      return;
    }

    if (this._formRef) {
      this._formRef.validator.updateInputRules('addresses[1]/postalCode', {
        ...this.originalMailingPostalValidation,
        wrongSingaporePostalCode: false
      });
    }

    const addressFields = [
      '.country',
      '.postalCode',
      '.streetOne',
      '.streetTwo',
      '.streetThree',
      '.streetFour',
      '.state'
    ];

    addressFields.forEach(field => {
      const mailingAddressElem = this._mailingAddress.querySelector(field);
      const residentialAddressElem = this._residentialAddress.querySelector(field);
      if (mailingAddressElem.getValue() !== residentialAddressElem.getValue()) {
        mailingAddressElem.setValue(residentialAddressElem.getValue());
      }
    });

    const mailingAddressOptionsElem = this._mailingAddress.querySelector('.mailing-address-options');
    const residentialAddressOptionsElem = this._residentialAddress.querySelector('.residential-address-options');

    if (mailingAddressOptionsElem.getValue() !== residentialAddressOptionsElem.getValue()) {
      mailingAddressOptionsElem.setValue(residentialAddressOptionsElem.getValue());
    }
  }

  disconnectedCallback() {
    (this._subscriptions || []).map(sub => sub.unsubscribe());
  }

  /**
   * Calculate whether Corporate Action address field is required.
   */
  _caAddressRequired() {
    const reuseAddresses = this._reuseAddresses.getValue();
    const residentialOutsideSG = this._residentialAddressOptions.getValue() === 'outsideSG';
    const mailingOutsideSG = this._mailingAddressOptions.getValue() === 'outsideSG';

    const isCAAddressRequired = mailingOutsideSG && !reuseAddresses || (residentialOutsideSG && reuseAddresses);

    this.validateResidentialAddress(!residentialOutsideSG);
    this.validateMailingAddress(!mailingOutsideSG);
    this.validateCorporateAddress(false, isCAAddressRequired);

    return isCAAddressRequired;
  }

  /**
   * Update form fields given the state of options selected.
   *
   * @param {sgx-input-radio-list} optionsField the radio list from which to get the input mode (withinSG or outsideSG)
   * @param {fieldset} fieldSet the field set containing the address
   * @param {div} addressFields the DIV wrapping the fields
   */
  _updateFields(optionsField, fieldSet, addressFields) {
    const option = optionsField.getValue();

    // Field references
    const countryFld = fieldSet.querySelector('.country');
    const postalCodeFld = fieldSet.querySelector('.postalCode');
    const stateFld = fieldSet.querySelector('.state');
    const streetOneFld = fieldSet.querySelector('.streetOne');
    const streetTwoFld = fieldSet.querySelector('.streetTwo');
    const streetThreeFld = fieldSet.querySelector('.streetThree');
    const streetFourFld = fieldSet.querySelector('.streetFour');

    // Show, hide country, state field
    showHideEl(countryFld, option === 'outsideSG');
    stateFld && showHideEl(stateFld, option === 'outsideSG');

    // Move postal code around and update hints
    if (option === 'withinSG') {
      addressFields.classList.add('within-sg');
      addressFields.classList.remove('outside-sg');
      postalCodeFld.setHint(i18n.getTranslation('app.widget-settings-user-address.inputs.withinSG.hintSgPostalCodesOnly'));
      countryFld.setValue('SG');
      if (this.reValidate) {
        this.validatePostalCode();
      }
      if (countryFld.nextElementSibling === postalCodeFld) {
        // Correct layout
        return;
      }
      // addressFields.insertBefore(postalCodeFld, streetOneFld);
      // addressFields.insertBefore(streetTwoFld, streetOneFld);
    } else {
      if (countryFld.getValue() === 'SG') {
        countryFld.setValue('');
      }
      addressFields.classList.remove('within-sg');
      addressFields.classList.add('outside-sg');
      postalCodeFld.setHint();

      if (countryFld.nextElementSibling !== postalCodeFld) {
        // Correct layout
        return;
      }
      // addressFields.insertBefore(streetOneFld, streetTwoFld);
      // addressFields.insertBefore(streetFourFld, streetThreeFld);
      // addressFields.appendChild(postalCodeFld);
    }

    // Update labels
    setTimeout(_ => {
      streetOneFld.setLabel(i18n.getTranslation(`app.widget-settings-user-address.inputs.${option}.streetOne`));
      streetTwoFld.setLabel(i18n.getTranslation(`app.widget-settings-user-address.inputs.${option}.streetTwo`));
      streetThreeFld.setLabel(i18n.getTranslation(`app.widget-settings-user-address.inputs.${option}.streetThree`));
      streetFourFld.setLabel(i18n.getTranslation(`app.widget-settings-user-address.inputs.${option}.streetFour`));
    });

    setTimeout(_ => {
      this.reUseMailingAddress()
    });

    // Undo unnecessary validation errors
    addressFields.querySelector('.postalCode').classList.remove('sgx-input--has-error');

  }

  validatePostalCode() {
    const residentialAddress = this._residentialAddress.querySelector('.postalCode');
    const mailingAddress = this._mailingAddress.querySelector('.postalCode');
    const caAddress = this._caAddress.querySelector('.postalCode');

    switch (this.reValidate) {
      case 'setResidentialAddress':
        this._updatePostalCode(residentialAddress, 'setResidentialAddress', this._residentialAddressOptions);
        break;
      case 'setMailingAddress':
        this._updatePostalCode(mailingAddress, 'setMailingAddress', this._mailingAddressOptions);
        break;
      case 'setCaAddress':
        this._updatePostalCode(caAddress, 'setCaAddress', {getValue: _ => 'withinSG'});
        break;
    }

  }

  updateValidationConfig(validate, config, formRef) {
    // Deletion of unwanted validation rules
    delete config.validate.rules['addresses[2]/streetOne'];

    this._formRef = formRef;
    const _this = this;
    const validateInstance = this._formRef.validator.validate;
    validateInstance.validators = {
      ...validateInstance.validators,
      wrongSingaporePostalCode(value, options) {
        if (value && _this._validateSGPostalCode[options.ref] === false) {
          return options.message();
        }
      }
    };

    Object.assign(config.validate.rules['addresses[0]/postalCode'], {
      singaporePostalCodeIf: {
        predicate: () => this._residentialAddressOptions.getValue() === 'withinSG',
        scope: this,
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {
          fieldName: i18n.getTranslation('app.shared-text.field-names.postalCode')
        })
      },
      wrongSingaporePostalCode: {
        predicate: () => true,
        ref: 'setResidentialAddress',
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {
          fieldName: i18n.getTranslation('app.shared-text.field-names.postalCode')
        })
      }
    });

    this.originalResidentialPostalValidation = {...config.validate.rules['addresses[0]/postalCode']};
    this._postalCodeResetRuleValidation = {
      presence: false,
      format: {
        pattern: "^$|^[A-Za-z0-9\\s]+$",
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.shared-text.field-names.postalCode')})
      }
    };

    Object.assign(config.validate.rules['addresses[1]/postalCode'], {
      singaporePostalCodeIf: {
        predicate: () => this._mailingAddressOptions.getValue() === 'withinSG',
        scope: this,
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {
          fieldName: i18n.getTranslation('app.shared-text.field-names.postalCode')
        })
      },
      wrongSingaporePostalCode: {
        predicate: () => true,
        ref: 'setMailingAddress',
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {
          fieldName: i18n.getTranslation('app.shared-text.field-names.postalCode')
        })
      }
    });

    this.originalMailingPostalValidation = {...config.validate.rules['addresses[1]/postalCode']};

    // Removing other constraints on CA postal codes: either empty or Singaporean
    Object.assign(config.validate.rules['addresses[2]/postalCode'], {
      singaporePostalCodeIf: {
        predicate: () => true,
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {
          fieldName: i18n.getTranslation('app.shared-text.field-names.postalCode')
        })
      },
      wrongSingaporePostalCode: {
        predicate: () => true,
        ref: 'setCaAddress',
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {
          fieldName: i18n.getTranslation('app.shared-text.field-names.postalCode')
        })
      }
    });

    // max character validation for mailing address fields
    ['streetOne', 'streetTwo', 'streetThree', 'streetFour', 'state'].forEach(key => {
      if (!config.validate.rules[`addresses[1]/${key}`]) {
        config.validate.rules[`addresses[1]/${key}`] = {};
      }

      Object.assign(config.validate.rules[`addresses[1]/${key}`], {
        length: {
          maximum: 30,
          message: () => {
            const errorMessage = ['streetOne', 'streetTwo', 'streetThree'].includes(key) ? 'too-long-with-field' : 'too-long';
            let fieldName = '';

            if (['streetOne', 'streetTwo'].includes(key)) {
              fieldName = 'streetTwo';
            } else if(key === 'streetThree') {
              fieldName = key;
            }

            return validate.format(i18n.getTranslation(`app.widget-settings-user-address.inputs.validation.error.message.${errorMessage}`), {
              fieldName: fieldName ? i18n.getTranslation(`app.widget-settings-user-address.inputs.validation.error.fields.${fieldName}`) : ''
            });
          }
        },
        addressScenario1: {
          message: () => validate.format(i18n.getTranslation('app.widget-settings-user-address.inputs.validation.address.scenario-1'))
        },
        addressScenario2: {
          message: () => validate.format(i18n.getTranslation('app.widget-settings-user-address.inputs.validation.address.scenario-2'))
        },
        addressScenario3: {
          message: () => validate.format(i18n.getTranslation('app.widget-settings-user-address.inputs.validation.address.scenario-3'))
        },
        addressScenario4: {
          message: () => validate.format(i18n.getTranslation('app.widget-settings-user-address.inputs.validation.address.scenario-4'))
        }
      });
    });

    this.originalCAPostalValidation = {...config.validate.rules['addresses[2]/postalCode']};
    this.originalCAStreetValidation = {...config.validate.rules['addresses[0]/streetOne']};

    return config;
  }


  validateCorporateAddress(validationOn, isCAAddressRequired) {
    if (!get(this._formRef, 'validator.instance')) {
      return;
    }

    const resetRule = {presence: false};

    if (validationOn) {
      this._formRef.validator.updateInputRules('addresses[2]/postalCode', this.originalCAPostalValidation);
      this._formRef.validator.updateInputRules('addresses[2]/streetTwo', this.originalCAStreetValidation);
    } else {
      const rules = {
        ...this.originalCAPostalValidation,
        ...resetRule
      };

      if (!isCAAddressRequired) {
        rules.wrongSingaporePostalCode = false;
      }

      this._formRef.validator.updateInputRules('addresses[2]/postalCode', {
        ...rules,
      });
      this._formRef.validator.updateInputRules('addresses[2]/streetTwo', resetRule);
    }

    this._formRef.validate();
  }


  validateResidentialAddress(validationOn) {
    if (!get(this._formRef, 'validator.instance')) {
      return;
    }

    const resetRule = {presence: false};

    if (validationOn) {
      this._formRef.validator.updateInputRules('addresses[0]/postalCode', this.originalResidentialPostalValidation);
    } else {
      this._formRef.validator.updateInputRules('addresses[0]/postalCode', resetRule);
    }


    this._formRef.validate();
  }

  validateMailingAddress(validationOn) {
    if (!get(this._formRef, 'validator.instance')) {
      return;
    }

    if (validationOn) {
      this._formRef.validator.updateInputRules('addresses[1]/postalCode', this.originalMailingPostalValidation);
    } else {
      this._formRef.validator.updateInputRules('addresses[1]/postalCode', this._postalCodeResetRuleValidation);
    }


    this._formRef.validate();
  }

  setResidentialAddress__READONLY() {
    getFormInputs(this._residentialAddress)
      .forEach(input => {
        // provide option to update mailing address
        (input.className.indexOf('mailing-is-residential-address') === -1)
        && input.setReadOnly(true);
      });
  }
}

customElements.define('widget-settings-user-address', withInitDOM(UserAddressWidget));
