import {withInitDOM} from 'sgx-base-code';
import tmpl from "./widget-ca-standing-instructions-dialog.html";
import DeviceService from "@sgx/sgx-device-service";
import CorporateActionsService from 'services/corporate-actions-service';
import i18n from "@sgx/sgx-localisation-service";
import sgxAnalyticsService from '@sgx/sgx-analytics-service';

export default class WidgetCaStandingInstructionsDialog extends HTMLElement {

  constructor() {
    super();

    this._cancelStandingInstruction = this._cancelStandingInstruction.bind(this);
    this._toggleSubmitBtnState = this._toggleSubmitBtnState.bind(this);
  }

  connectedCallback() {
    this._handleEventListeners(true);
  }

  disconnectedCallback() {
    this._handleEventListeners(false);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._dialog = this.querySelector('.widget-ca-standing-instructions-dialog');
    this._sgxStatusIndicator = this.querySelector('sgx-status-indicator');
    this._termsAgreement = this.querySelector('[name=termsAgreement]');
    this._additionalTerms = this.querySelector('[name=additionalTerms]');
    this._submitBtn = this.querySelector('.widget-ca-standing-instructions-dialog-subscription-submit-btn');
    this._cancelBtn = this.querySelector('.widget-ca-standing-instructions-dialog-subscription-cancel-btn');
    this._securityName = this.querySelector('.widget-ca-standing-instructions-dialog-security-name');
    this._contentBox = this.querySelector('.widget-ca-standing-instructions-dialog-content-box');
  }

  /**
   * @desc handle event listeners on the widget elements
   * */
  _handleEventListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';

    this._termsAgreement[fnName]('change', this._toggleSubmitBtnState)
    this._additionalTerms[fnName]('change', this._toggleSubmitBtnState)
  }

  /**
   * @desc enable/disable submit button based on the selection of the checkboxes
   * */
  _toggleSubmitBtnState() {
    const termsAgreed = this._termsAgreement.checked && this._additionalTerms.checked;
    this._submitBtn[termsAgreed ? 'removeAttribute' : 'setAttribute']('disabled', 'disabled');
  }

  showDialog(standingInstructionObject) {
    this._resetState();
    this._sgxStatusIndicator.hide();
    this._securityName.innerHTML = standingInstructionObject.securityName;
    const dialogConfig = {
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      actions: {
        submit: _ => this._cancelStandingInstruction(standingInstructionObject)
      }
    };
    return this._dialog.show(dialogConfig);
  }

  /**
   * @desc submit the standing-instruction cancellation request
   * */
  _cancelStandingInstruction(standingInstructionObject) {
    this._sgxStatusIndicator.show();
    const label = `delete-permanent-election--${standingInstructionObject.securityCode}`;
    CorporateActionsService.cancelStandingInstruction(standingInstructionObject)
      .then(_ => {
        this._sgxStatusIndicator.show({
          status: 'success',
          title: i18n.getTranslation(`widget-ca-standing-instructions-dialog.states.success.title`),
          description: i18n.getTranslation(`widget-ca-standing-instructions-dialog.states.success.description`, {
            bankName: standingInstructionObject.securityName
          }),
          button: {
            text: i18n.getTranslation('widget-ca-standing-instructions-dialog.buttons.dismiss'),
            callback: () => this._dialog.hide()
          }
        });
        this.dispatchEvent(new CustomEvent(`standing-instruction-cancelled`, {bubbles: true}));
        this._sendGaSubmitEvent({
          isSuccess: true,
          label
        });
        this._showOkayButton();
      })
      .catch(_ => {
        this._sgxStatusIndicator.show({
          status: 'error',
          title: i18n.getTranslation(`widget-ca-standing-instructions-dialog.states.error.title`),
          description: i18n.getTranslation(`widget-ca-standing-instructions-dialog.states.error.description`, {
            bankName: standingInstructionObject.securityName
          }),
          button: {
            text: i18n.getTranslation('widget-ca-standing-instructions-dialog.buttons.dismiss'),
            callback: () => this._dialog.hide()
          }
        });
        this._sendGaSubmitEvent({
          isSuccess: false,
          label
        });
        this._showOkayButton();
      })
  }

  _resetButtons() {
    this._submitBtn.classList.remove('sgx-hidden');
    this._cancelBtn.classList.remove('sgx-hidden');
    this._contentBox.classList.remove('sgx-hidden');

    this._submitBtn.setAttribute('disabled', 'disabled');
  }

  _resetCheckBoxStates() {
    this._termsAgreement.checked = false;
    this._additionalTerms.checked = false;
  }

  _showOkayButton() {
    this._submitBtn.classList.add('sgx-hidden');
    this._cancelBtn.classList.add('sgx-hidden');
    this._contentBox.classList.add('sgx-hidden');
  }

  _resetState() {
    this._resetButtons();
    this._resetCheckBoxStates();
  }

  _sendGaSubmitEvent({isSuccess, label}) {
    const val = isSuccess ? 'success' : 'failed';
    sgxAnalyticsService.sendEvent('corporate-actions', label, `submit--${val}`);
  }

}

customElements.define('widget-ca-standing-instructions-dialog', withInitDOM(WidgetCaStandingInstructionsDialog));
