import { withInitDOM } from '@sgx/sgx-base-code';
import tmpl from './widget-dashboard-carousel.html';
import DeviceService from '@sgx/sgx-device-service';
import CmsService from 'sgx-cms-service';
import StoreRegistry from 'stores/store-registry';
import StatusIndicatorUtil from 'utils/status-indicator-util';
import SGXInViewport from 'sgx-in-viewport';
import { fromEvent } from 'rxjs';
import SgxAnalyticsService from 'sgx-analytics-service';
import { get } from 'lodash';
import sgxAnalyticsService from '@sgx/sgx-analytics-service';

/**
 * The carousel widget
 * @module widget-dashboard-carousel
 * @type {HTMLElement}
 */

class CarouselWidget extends SGXInViewport {
  // #region Custom Element API

  constructor() {
    super();
    this._lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');
  }

  _bindCarouselEvents() {
    this._carouselButtons = this.querySelectorAll('.sgx-carousel-slide-buttons a');

    this._carouselButtons && fromEvent(this._carouselButtons, 'click').subscribe(event => this._sendGAEventForCarouselActions(event));
  }

  _sendGAEvent(event) {
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    const activeTab = this.querySelector('.sgx-carousel-pagination-button--active .sgx-carousel-pagination-button-title');
    const title = activeTab.textContent.trim();
    SgxAnalyticsService.sendEvent(
      eventCategory,
      `Carousel Pagination: ${title}`,
      'widget-viewed',
    );
  }

  _sendGAEventForCarousel(event) {
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    const targetText = get(event, 'target.textContent');
    if (targetText) {
      sgxAnalyticsService.sendEvent(eventCategory,
        targetText,
        'click'
      );
    }
  }

  _sendGAEventForCarouselActions(event) {
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    const carouselTitleElm = this._carousel.querySelector('.sgx-carousel-slide--active .sgx-carousel-slide-title');
    const carouselTitle = carouselTitleElm ? carouselTitleElm.textContent : '';
    const targetText = `Carousel Action Clicked : ${carouselTitle} : ${get(event, 'target.textContent')}`;
    if (targetText) {
      sgxAnalyticsService.sendEvent(eventCategory,
        targetText,
        'click'
      );
    }
  }

  initDOM() {
    this.appendChild(tmpl.getNode());

    this._indicator = this.querySelector('sgx-status-indicator');

    StatusIndicatorUtil.displayLoader(this._indicator);

    this._carousel = this.querySelector('sgx-carousel');
    this._paginationContainer = this.querySelector('.sgx-carousel-pagination');
    this._carousel.setConfig({
      autoplay: true,
      autoplaySpeed: 4000
    });
    CmsService.getCarouselListingData()
      .then((response) => {
        try {
          const data = response.data.route.data.data.header.data || '';
          if (!data) {
            this._displayNoDataIndicator();
            return;
          }
          this.setData(data);
          this._bindCarouselEvents();
          super.initDOM();
        } catch (e) {
          this._displayNoDataIndicator();
          return;
        }
      })
      .catch((e) => {
        console.error(e);
        this._showError();
      });
  }

  connectedCallback() {
    fromEvent(this, 'onViewPortEnter').subscribe(event => this._sendGAEvent(event));
    fromEvent(this._paginationContainer, 'click').subscribe(event => this._sendGAEventForCarousel(event));
  }

  // #endregion

  // #region Public Methods

  setData(data = {}) {
    const { carouselSlides = [] } = data;
    this._carousel.setData({ slides: this._getFormattedSlides(carouselSlides) });

    StatusIndicatorUtil.hideStatusIndicator(this._indicator);

    this._setWidthForCarouselChanger();
  }

  // #endregion

  // #region Private Methods

  /**
   * Pass the formatted values in expected structure by sgx-carousel component
   * @param {String} slideArr Array of objects passed by the CMS
   * Expected Format:
   * {
        title: '', // String
        masthead: {
          backgroundImage: {
            image: {
              url: '', // String
            },
          },
          mobilebackgroundImage: {
            image: {
              url:'', // String
            },
          },
        },
        description: '',
        link: {
          href: {
            url: '', // String
          },
          title: '', // String
        },
      }
   *
   */
  _getFormattedSlides(slideArr = []) {
    return slideArr.map((slide) => {
      let { title, masthead, description, link } = slide.data || {};
      const { backgroundImage, mobilebackgroundImage } = masthead.data || {};
      const { href: { url } = {}, title: ctaText } = link || {};
      const cta = url ? { url, text: ctaText } : null;

      let image;
      if (mobilebackgroundImage && DeviceService.isMobile()) {
        image = (mobilebackgroundImage.data.image || {}).url;
      } else {
        image = (backgroundImage.data.image || {}).url;
      }

      return {
        title,
        image,
        description,
        cta,
      };
    });
  }

  _displayNoDataIndicator() {
    StatusIndicatorUtil.displayNoData(this._indicator);
  }

  _showError() {
    StatusIndicatorUtil.displayNoData(this._indicator);
  }

  _setWidthForCarouselChanger() {
    if (DeviceService.isMobile()) return;

    const elements = this.querySelectorAll('.sgx-carousel-pagination-button');

    for (const item of elements) {
      item.style.width = `calc(100% / ${elements.length})`;
    }
  }

  // #endregion
}

// #endregion

// #region Registeration of the Element

customElements.define('widget-dashboard-carousel', withInitDOM(CarouselWidget));

// #endregion
