import SgxTableHeaderBase from 'sgx-table/src/sub-components/sgx-table-header/sgx-table-header-base';
import {withInitDOM} from 'sgx-base-code';

/**
 * A custom HTML element used to render cells for portfolio e-rights
 * @module sgx-table-cell-dialog
 * @type {HTMLElement}
 */
class SgxTableHeaderTooltip extends SgxTableHeaderBase {

  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = `<header>
                        <span class="sgx-header-tooltip-label">${this._columnConfig.label}</span>
                        <sgx-tooltip class="sgx-header-tooltip-icon" data-sgx-tooltip-pos="bottom">
                          <div data-sgx-tooltip-content>${this._columnConfig.tooltip_component}</div>
                          <button class="sgx-icon sgx-tooltip-button sgx-tooltip-button-info-icon"></button>
                        </sgx-tooltip>
                      </header>`;
  }

  setConfig(id, columnConfig, tableConfig) {
    this._id = id;
    this._columnConfig = columnConfig;
    this._tableConfig = tableConfig;
  }

  setState(state) {

  }
}

customElements.define('sgx-table-header-tooltip', withInitDOM(SgxTableHeaderTooltip));
