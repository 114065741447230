import {withInitDOM} from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DateUtil from 'utils/date-util';
import tpl from './cmp-market-research-details.html';
import ConfigService from '@sgx/sgx-config-service';
import StoreRegistry from "../../stores/store-registry";

/**
 * A details component utilize by market research's sgx-list-details
 * @module cmp-market-research-details
 * @type {HTMLElement}
 */

class CmpMarketResearchDetails extends HTMLElement {
  // #region Custom Element API
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tpl.getNode());
    this.classList.add('cmp-market-research-details');
    this._header = this.querySelector('.cmp-market-research-details__header');
    this._listDetailsIframe = this.querySelector('.cmp-market-research-details__content--frame');
    this._title = this.querySelector('.cmp-market-research-details__header--title');
    this._date = this.querySelector('.cmp-market-research-details__header--date');
    this._tags = this.querySelector('.cmp-market-research-details__header--assetclass');

    const head = document.querySelector('head');
    this._metaDescription = head.querySelector('meta[name="description"]');
    this._metaOGTitle = head.querySelector('meta[property="og:title"]');
    this._metaOGUrl = head.querySelector('meta[property="og:url"]');
    this._metaTwitterTitle = head.querySelector('meta[name="twitter:title"]');
  }

  connectedCallback() {
    this._attached = true;
  }

  // #endregion

  /**
   * A details component utilize by market research's sgx-list-details
   * @param {Object} data a market research data either a market update, market dialogue, sector or fund flow report (as of now only market update)
   */
  setData(data) {
    this._setSEO({
      title: i18n.getTranslation('cmp-market-research-details.meta.title', {
        article_title: data.title
      }),
      link: data.link.url
    })
    if (!this._attached) {
      setTimeout(() => this.setData(data), 0);
      return;
    }
    this._data = data;
    this._render();
  }

  getData() {
    return this._data;
  }

  _setSEO({title, link}) {
    const lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');

    document.title = title;
    this._metaOGTitle.setAttribute('content', title);
    this._metaTwitterTitle.setAttribute('content', title);
    this._metaOGUrl.setAttribute('content', `${ConfigService.links.SGX_COM[lang]}${link}`);
  }

  _render() {
    const {title, dateArticle, assetClassNames, mimeType, category} = this._data;
    const sector = [category.data.data.name];
    if (mimeType === 'text/html') {
      this._removeAllAssetClassChild();
      this._title.textContent = title;
      this._date.textContent = DateUtil.formatDateTo(dateArticle, 'X', i18n.getTranslation('app.market-research.list-details.row.date-format'));
      this._tags.appendChild(this._toAssetClassFragment([...sector, ...assetClassNames]));

      this._header.classList.remove('hidden');
    } else {
      this._header.classList.add('hidden');
    }
    this._listDetailsIframe.setData(this._data);
  }

  /**
   * Creates a new documentFragment based on assetClass provided
   * @param {Array} assetClass the assset classes of the news
   * @return {DocumentFragment} documentFragment to be appended as child of assetClass container
   */
  _toAssetClassFragment(assetClass) {
    return (assetClass || []).reduce((fragment, name) => {
      const span = document.createElement('span');
      span.textContent = name;
      span.classList.add('cmp-list-row-market-research__tag');
      fragment.appendChild(span);

      return fragment;
    }, document.createDocumentFragment());
  }

  /**
   * Removes all the child of assetClass container
   */
  _removeAllAssetClassChild() {
    while (this._tags.lastChild) {
      this._tags.removeChild(this._tags.lastChild);
    }
  }
}

customElements.define('cmp-market-research-details', withInitDOM(CmpMarketResearchDetails));
