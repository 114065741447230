import tmpl from './cmp-login-prompt.html';
import ConfigService from 'sgx-config-service';
import { URLUtils, withInitDOM } from 'sgx-base-code';
import StoreRegistry from 'stores/store-registry';

class CmpLoginPrompt extends HTMLElement {
  constructor() {
    super();
    this._onTilesClick = this._onTilesClick.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._promptText = this.querySelector('.cmp-login-prompt-text');
    this.tilesContainer = this.querySelector('.cmp-login-prompt-tiles');
    this._createCdpAccountLink = this.querySelector('.cmp-login-prompt-cdp-account-link');
    this._tokenManagementLink = this.querySelector('.cmp-login-prompt-token-management-link');
  }

  connectedCallback() {
    this.tilesContainer.addEventListener('click', this._onTilesClick);
    const lang = StoreRegistry.appSettings.getData('lang') || 'en';
    this._createCdpAccountLink.href = ConfigService.links.SGX_V2_CREATE_INDIVIDUAL_ACCOUNT[lang.replace(/-/g, '_')];
    this._tokenManagementLink.href = ConfigService.links.SGX_V2_TOKEN_MANAGEMENT[lang.replace(/-/g, '_')];
  }

  disconnectedCallback() {
    this.tilesContainer.removeEventListener('click', this._onTilesClick);
  }

  setConfig({ promptText }) {
    const { webview } = StoreRegistry.appContext.getData();
    this._promptText.innerHTML = !webview ? promptText.replace('target="_blank"', '') : promptText;
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  _onTilesClick(event) {
    const tileElem = event.target.closest('.cmp-login-prompt-tile-link');
    const accountType = tileElem.dataset.tile;

    if (!accountType) {
      return;
    }
    const url = URLUtils.setQueryParams('/login.html', {
      caller: window.location.pathname,
      accountType
    });
    window.location.assign(url);
  }
}

customElements.define('cmp-login-prompt', withInitDOM(CmpLoginPrompt));
