import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-session-timeout.html';
import i18n from 'sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import authService from 'services/auth-service';

import { INACTIVE_TIME, IDLE_INTERVAL, IDLE_INTERVAL_IN_SECONDS, TIMEOUT_INTERVAL, TIMER_COUNTDOWN } from './cmp-session-timeout-constants.js';

class CmpSessionTimeout extends HTMLElement {

  constructor() {
    super();

    this.idleTime = 0;
    this.inactiveTime = 0;
    this._cdpSessionData = StoreRegistry.cdpSession;

    //Update references
    this.handleContinueSession = this.handleContinueSession.bind(this);
    this.handleLogoutSession = this.handleLogoutSession.bind(this);

    // Initiate check for inactivity
    const sessionData = this._cdpSessionData.getData();
    if (sessionData && sessionData.userId) {
      this.calculateInactivity();
    }
  }

  initDOM() {
    this.classList.add('cmp-price-info');
    this.appendChild(tmpl.getNode());

    this.timeoutDialog = this.querySelector('.session-timeout-dialog');
    this.timeoutIndicator = this.querySelector('.session-timeout-indicator');
    this.continueSessionBtn = this.querySelector('.continue-session');
    this.logoutBtn = this.querySelector('.logout-session');
  }

  setEventListeners(state) {
    const funcName = state ? 'addEventListener' : 'removeEventListener';

    this.continueSessionBtn[funcName]('click', this.handleContinueSession);
    this.logoutBtn[funcName]('click', this.handleLogoutSession);

  }

  handleContinueSession() {
    authService.ping()
      .then(_ => this.resetInactivityState())
      .catch(_ => {
        if (window.location.pathname === '/logout') return;
        this.handleLogoutSession()
      });
  }

  handleLogoutSession() {
    this._cdpSessionData.setData({...this._cdpSessionData.getData(), ...{'session-timeout': true}});
    StoreRegistry.permissions.clear(); // Required for mobile injected function to perform correctly
    this.resetInactivityState();
    window.location.href = 'logout';
  }

  show() {
    this.timeoutDialog.show();
    this.timeoutIndicator.show({
      title: '',
      description: i18n.getTranslation('cmp-session-timeout.session-expire-msg', {time: this.inactiveTime}),
      status: 'error'
    })

  }

  hide() {
    this.timeoutDialog.hide();
  }

  connectedCallback() {
    this.setEventListeners(true);
  }

  disconnectedCallback() {
    this.setEventListeners(false);
  }

  // Session timeout after 15 minutes of inactivity.
  calculateInactivity() {

    this.idleInterval = setInterval(_ => {

      if (window.location.pathname === '/logout') return;

      this.idleTime += IDLE_INTERVAL_IN_SECONDS;

      if (this.idleTime === INACTIVE_TIME) {

        // reset the inactiveTime, and show the sessionTimeout window
        this.inactiveTime = TIMER_COUNTDOWN;
        this.show();
        this.updateInactivity();
      }
    }, IDLE_INTERVAL);

    document.body.addEventListener('keypress', _ => this.idleTime = 0);
    document.body.addEventListener('mousemove', _ => this.idleTime = 0);
  }

  updateInactivity() {
    this.countDownPopup = setInterval(_ => {

      --this.inactiveTime;

      const inactivityElem = this.timeoutIndicator.querySelector('.sgx-status-indicator-description');
      inactivityElem.textContent = inactivityElem.textContent.replace(/\d+/, this.inactiveTime);

      if (this.inactiveTime <= 0) {
        // open the logout page.
        this.handleLogoutSession();
      }
    }, TIMEOUT_INTERVAL);
  }

  resetInactivityState() {
    this.inactiveTime = 0;
    this.idleTime = 0;

    clearInterval(this.countDownPopup);
    clearInterval(this.idleInterval);

    this.hide();
    this.calculateInactivity();
  }
}

customElements.define('cmp-session-timeout', withInitDOM(CmpSessionTimeout));
