module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-settings-notifications-email\">\n  <fieldset class=\"widget-notifications-email-fieldset\">\n    <h3 class=\"widget-notifications-email-title\" data-i18n=\"app.widget-settings-notifications-email.title\"></h3>\n    <div class=\"widget-notifications-inputs\">\n      <sgx-input-checkbox name=\"acctStatementNotificationOption\" checked\n                          class=\"widget-notifications-email-account fieldset-input sgx-hidden\"\n                          data-i18n=\"[label]app.widget-settings-notifications-email.inputs.accountStatements.label;\"></sgx-input-checkbox>\n      <p class=\"fieldset-input-hint sgx-hidden\" data-i18n=\"app.widget-settings-notifications-email.inputs.accountStatements.hint\"></p>\n      <sgx-input-checkbox name=\"confirmationNotificationOption\" checked\n                          class=\"widget-notifications-email-cdp fieldset-input sgx-hidden\"\n                          data-i18n=\"[label]app.widget-settings-notifications-email.inputs.cdpNotifications.label;\"></sgx-input-checkbox>\n      <p class=\"fieldset-input-hint sgx-hidden\" data-i18n=\"app.widget-settings-notifications-email.inputs.cdpNotifications.hint\"></p>\n      <sgx-input-checkbox name=\"psnEmailNotificationOption\" checked class=\"widget-notifications-email-settlement fieldset-input\" data-i18n=\"[label]app.widget-settings-notifications-email.inputs.preSettlementNotifications.label;\"></sgx-input-checkbox>\n      <p class=\"fieldset-input-hint\" data-i18n=\"app.widget-settings-notifications-email.inputs.preSettlementNotifications.hint\"></p>\n    </div>\n  </fieldset>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()