import ConfigService from '@sgx/sgx-config-service';
import {FetchUtils, URLUtils} from '@sgx/sgx-base-code';

let instance = null;

class SgxIpoService {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }


  /**
   * Fetches all IPO Performance items based on params
   *
   * @param {Object} params an object of params to determine the type of API call to make
   * @param {Date} [params.year] the listing year to filter by
   * @param {Date} [params.issuemanager] the issue manager to filter by
   * @param {Date} [params.companyname] the company name to filter by
   * @returns {Promise<Array>} returns an array of IPO Performance items
   */
  getIpoPerformance(params) {
    const url = URLUtils.setQueryParams(ConfigService.endpoints.IPO_PERFORMANCE_API_URL, params || {});
    return FetchUtils.fetchJSON(url).then(function formatData(response) {
      return response.data || [];
    }).catch(error => {
      return Promise.reject();
    });
  }


}

/* Export the singleton */
const sgxIpoServiceInstance = new SgxIpoService();
export default sgxIpoServiceInstance;
