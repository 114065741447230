import tmpl from './cmp-list-row-corporate-actions.html';
import DateUtil from 'utils/date-util';
import i18n from 'sgx-localisation-service';
import ConfigService from '@sgx/sgx-config-service';
import { withInitDOM } from 'sgx-base-code';

/**
 * List Details row for company announcements page
 */
class CmpListRowCorporateActions extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-announcement');

    this._issuer = this.querySelector('.cmp-list-row-announcement__header-issuer');
    this._type = this.querySelector('.cmp-list-row-announcement__footer-type');
    this._particulars = this.querySelector('.cmp-list-row-announcement__footer-particulars');
    this._exDate = this.querySelector('.cmp-list-row-announcement__footer-ex-date');
    this._anncDate = this.querySelector('.cmp-list-row-announcement__footer-annc-date');
  }

  setConfig(config) {
  }

  setData(rowData) {
    this.dataset.id = rowData.id;
    this._data = rowData;
    this._data.src = ConfigService.endpoints.V1_CORPORATE_ACTIONS_DATA_URL + '/' + rowData.id; // for sgx-list-details-iframe
    this._render(this._data);
  }

  getData() {
    return this._data;
  }

  _render(data) {
    this._issuer.textContent = data.name || '-';
    this._type.textContent = data.anncType || '-';
    this._particulars.textContent = data.particulars || '-';
    this._exDate.textContent = DateUtil.formatDateTo(data.exDate, null, i18n.getTranslation('app.format.date.default'), true) || '-';
    this._anncDate.textContent = DateUtil.formatDateTo(data.dateAnnc, null, i18n.getTranslation('app.format.date.default'), true) || '-';
  }

  setState(state) {
  }

  setSelected(selected) {
    if (selected) {
      this.setAttribute('selected', '');
    } else if (this.hasAttribute('selected')) {
      this.removeAttribute('selected');
    }
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('cmp-list-row-corporate-actions', withInitDOM(CmpListRowCorporateActions));
