import { withInitDOM } from 'sgx-base-code';
import tmpl from './template-settings-user.html';
import { fromEvent, of, merge } from 'rxjs';
import { debounceTime, switchMap, distinctUntilChanged } from 'rxjs/operators';
import {
  get,
  set,
  capitalize
} from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import StoreRegistry from 'stores/store-registry';
import {
  createFormConfigFromSchema,
  isBlankString,
  addCustomValidators,
  getFormInputs,
  takeSnapshot,
  isPristine,
  isInvalidAndVisible
} from 'utils/form-util';
import jsonSchema from 'schemas/settings-user';
import i18n from 'sgx-localisation-service';
import userService from 'services/user-service';
import referenceDataService from 'services/reference-data-service';
import { toggleAuthenticatedFeature } from 'utils/auth-util';
import {
  ACCOUNT_STATUS,
  ACCOUNT_STATES,
  USER_TYPES,
  ACCOUNT_TYPES
} from 'services/user-service/src/user-service-constants';
import ConfigService from 'sgx-config-service';
import { isAuthenticated } from 'utils/auth-util';
import AuthService from 'services/auth-service';
import InternationalCallingCode from '@sgx/sgx-forms/src/sgx-input-phone/InternationalCallingCode.js';

/**
 * View template displaying an editable form for User Particulars.
 */
class TemplateSettingsUser extends HTMLElement {
  constructor() {
    super();
    this._initDataBinding = this._initDataBinding.bind(this);
    this._handleSingpassLoginCTA = this._handleSingpassLoginCTA.bind(this);
    this._stateSuccessCB = this._stateSuccessCB.bind(this);
    this._onClickSuccessCB = this._onClickSuccessCB.bind(this);
    this._onClickErrorCB = this._onClickErrorCB.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('t-settings-user');
    this._form = this.querySelector('.t-settings-user-form');
    // this._userActionsWidget = this._form.querySelector('.widget-settings-user-actions');
    this._userGeneralWidget = this._form.querySelector('.widget-settings-user-general');
    this._userSignatoryWidget = this._form.querySelector('.widget-settings-user-signatory');
    this._userContactWidget = this._form.querySelector('.widget-settings-user-contact');
    this._userAddressWidget = this._form.querySelector('.widget-settings-user-address');
    this._taxResidencyFieldset = this._form.querySelector('.widget-tax-residency-fieldsets');
    this._loginPrompt = this.querySelector('cmp-login-prompt');
    this._loginPromptText = this.querySelector('.cmp-login-prompt-text');
    this._statusIndicator = this.querySelector('.t-settings-user-form-loader');
    this._userDetailsMyInfoDialog = document.querySelector('widget-user-details-myinfo');
    this._userActionsNotice = this.querySelector('.t-settings-user-action-notice');
    this._userActionSubmit = this.querySelector('.t-settings-user-action-submit');
    this._userActionContainer = this.querySelector('.t-settings-user-action-container');
    this._userMyInfoActionSubmit = this.querySelector('.t-settings-user-myinfo-action-submit');
    this._myinfoConfirmationDialog = this.querySelector('cmp-user-profile-myinfo-confirmation');
    this._staticMessageText = this.querySelector('.t-settings-static-message');
    this._taxResidencyWrapper =  this.querySelector('.tax-residency--wrapper');
    this._settingsGeneralWrapper = this.querySelector('.settings-general--wrapper');

    // SSO (single-sign-on) based references
    this._widgetUserParticularsSSO = document.querySelector('widget-user-particulars-update-sso');
    this._dueDCheckDialog = this.querySelector('widget-due-d-check');
    this._skipDueDCheck = false;
    this._isReadOnly = false;
    this._taxExceptionDetails = {
      agreed: false,
    }
  }

  static FORMATS = {
    PHONES: {
      countryCodeTemplate: '${country} +${code}'
    }
  };

  connectedCallback() {
    this._userStore = StoreRegistry.settingsUser;
    this._cdpStore = StoreRegistry.cdpSession;

    toggleAuthenticatedFeature({
      prompt: this._loginPrompt,
      promptText: i18n.getTranslation('app.settings.generic-login-prompt', {
        link: ConfigService.links.CDP_ACCOUNT_CREATE
      }),
      feature: this._form
    });

    this._subscriptions = [];

    // Set up the validation config
    const validationConfig = this._createValidationConfig();

    this._cdpStore
      .pipe(distinctUntilChanged((a, b) => a.accountType === b.accountType && a.myinfoParticularsUpdatedTime === b.myinfoParticularsUpdatedTime))
      .subscribe(({
                    accountType,
                    pref2FaMobile: isOTPEnabled,
                    hardTokenInfo,
                    idType,
                    singpassAccountAvailable
                  }) => {
        if (this._userStore.getData('singpass-redirect')) {
          this._accountType = accountType;
          this._isOTPEnabled = isOTPEnabled;
          return;
        }

        this._loadData({
          accountType,
          isOTPEnabled,
          hardTokenInfo,
          idType,
          singpassAccountAvailable
        });
      });

    // Initialize form with validation config and querySelects all sgx-inputs internally
    this._form.setConfig(validationConfig);

    // Set up the data binding to the store
    this._initDataBinding();

    this._widgetUserParticularsSSO
      .initTxnSigning({
        stateSuccessCB: this._stateSuccessCB,
        onClickSuccessCB: this._onClickSuccessCB,
        onClickErrorCB: this._onClickErrorCB
      });

    fromEvent(this._dueDCheckDialog, 'onDueCheckComplete').subscribe((e) => {
      this._taxExceptionDetails = e.detail || {};
      this._skipDueDCheck = true;
      this._userActionSubmit.click();
    });

    this._setEventListeners(true);
  }

  disconnectedCallback() {
    this._setEventListeners(false);
    (this._subscriptions || []).map(sub => sub.unsubscribe());
  }

  /*
  * handle event listeners
  * */
  _setEventListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this._userActionsNotice[fnName]('click', this._handleSingpassLoginCTA);
  }

  /**
   * handle logout and redirection to login
   * CTA = Call-to-Action
   * */
  _handleSingpassLoginCTA(e) {
    const lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');

    if (e.target.className === 't-settings-user-login-link') {
      this._statusIndicator.show({
        status: 'loading'
      });
      AuthService.logout()
        .then(_ => window.location.href = ConfigService.links.USER_PARTICULARS_LOGIN_INDIVIDUAL_LINK[lang])
        .catch(error => {
          console.error(`Failed to logout. ${error}`);
          this._statusIndicator.show({
            status: 'error',
            title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
            description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
          });
        });
    }
  }

  /**
   *
   * @param {string} accountType
   * @param {boolean} isOTPEnabled
   * @param {Object} hardTokenInfo contains details of hardToken eg: linkedHardTokenSerialNumber, etc.
   * @param {String} idType used to identify the type of logged-in user.
   * @param {boolean} singpassAccountAvailable used to identify if singpass-login is available or not.
   */
  _loadData({
              accountType,
              isOTPEnabled,
              hardTokenInfo,
              idType,
              singpassAccountAvailable
            }) {

    if (isAuthenticated()) {

      const userActionData = {idType, singpassAccountAvailable, accountType};

      this._accountType = accountType;

      const loadDataPromises = [
        userService.getUserParticulars(),
        userService.getUserAccounts(),
        referenceDataService.getCountries()
      ];

      // @deprecate as part of Assurity Token Decommission
      /*if (!hardTokenInfo) {
        loadDataPromises.push(AuthService.getNAFTokenInfo().catch(error => { console.error(error) }));
      } else {
        // Set the user-action state
        userActionData.hardTokenInfo = hardTokenInfo;
      }*/

      // show a loading indicator
      this._statusIndicator.show({
        status: 'loading'
      });

      Promise.all(loadDataPromises)
        .then(values => {
          const user = values[0];
          const accounts = values[1];
          const countries = values[2].data;

          this._countryList = countries;

          // Set the issuing country value
          userActionData.issuingCountry = user.clientInfoId.issuingCountry;
          this._cdpStore.setData(userActionData.issuingCountry, 'issuingCountry');

          //Add the missing field => idType
          if (!userActionData.idType) {
            userActionData.idType = this._cdpStore.getData('idType');
          }

          //Add the missing field => singpassAccountAvailable
          if (!userActionData.singpassAccountAvailable) {
            userActionData.singpassAccountAvailable = this._cdpStore.getData('singpassAccountAvailable');
          }

          // Save as this will be sent along with the form
          this.corporateAddressState = user.caAddress.state;

          this._userGeneralWidget.setData(user, accounts);
          this._userSignatoryWidget.setData(user);
          this._userContactWidget.setData(user, accounts);
          this._userAddressWidget.setData(user, accounts, countries);
          this._taxResidencyFieldset.setData(user);
          this._fatcaCrsDueDCheck = user.fatcaCrsDueDCheck;
          // If one of user's account is suspended, set form to read only
          const hasSuspendedAccount = accounts.some(account => account.accountStatus === ACCOUNT_STATUS.SUSPENDED) || false;

          this._handleTxnSigningStatus({
            hasSuspendedAccount,
            ...userActionData
          });

          this._pristineValueFatcaFields(user);
          this._taxResidencyFieldset.classList.remove('sgx-hidden');
          if (this._taxResidencyWrapper.contains(this._userAddressWidget)) {
            this._taxResidencyWrapper.removeChild(this._userAddressWidget);
            this._settingsGeneralWrapper.appendChild(this._userAddressWidget);
          }

          // Make residential address as readonly for individual and joint-or
          if ([ACCOUNT_STATES.INDIVIDUAL, ACCOUNT_STATES.JOINT_OR, ACCOUNT_STATES.JOINT_AND].includes(accountType)) {
            this._userAddressWidget.setResidentialAddress__READONLY();
          }

          if ([ACCOUNT_STATES.CORPORATE, ACCOUNT_STATES.CORPORATE_TRUSTEE].includes(this._accountType)) {
            this._userGeneralWidget.classList.add('sgx-hidden');
          }

          this._statusIndicator.hide();
        })
        .then(() => {
          this._pristineData = takeSnapshot(this._form.getJsonData(this._propertyConfigs));
        })
        .catch(error => {
          console.error(`Failed to load data into form view. ${error}`);
          this._statusIndicator.show({
            status: 'error',
            title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
            description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
          });
        });
    }
  }

  /**
   * @method
   * @private
   * @param {Object} formData
   * Checks if the fields corresponding to the FATCA check is updated or not and returns a boolean
   * value accordingly.
   *
   * We need to perform FATCA validation only when IDD of phone, country of residential,
   * mailing address and the countries of tax residency is changed.
   *
   * @returns {Boolean}
   */
  _isFatcaCheckFieldsUpdated(formData) {
    const officeContact = {
      'officeContactIddCode': formData.officeContactIddCode,
      'officeContactCountry': formData.officeContactCountry || ''
    };

    const homeContact = {
      'homeContactIddCode': formData.homeContactIddCode,
      'homeContactCountry': formData.homeContactCountry
    };

    const personalContact = {
      'mobileContactIddCode': formData.mobileContactIddCode,
      'mobileContactCountry': formData.mobileContactCountry
    };

    const addressCountries = {
      'residentalCountry': formData.residentalCountry,
      'mailingAddressCountry': formData.mailingAddressCountry
    }

    const taxResidencyDetails = {
      'taxResidencyCountries':
        (formData.taxResidencyDetails || []).map((taxResidency) => {
          return taxResidency.country
    })
  }

    const data = {
      ...personalContact,
      ...officeContact,
      ...homeContact,
      ...addressCountries,
      ...taxResidencyDetails
    };

    data.taxResidencyCountries = (data.taxResidencyCountries || []).sort();


    return !isPristine(data, takeSnapshot(this._pristineDueDCheckFields));
  }

  /**
   * @method
   * @private
   * @param {Object} user
   *
   * This method is used to save the initial data from the backend in the `_pristineDueDCheckFields`
   * variable. This is for comparing if the value is changed or not in the form and then perform fatca crs check
   * accordingly.
   */
  _pristineValueFatcaFields(user) {
    const officeContact = {
      'officeContactIddCode': (get(user, 'officeNumber.countryCode') && user.officeNumber.countryCode.replace('+', '') || ''),
      'officeContactCountry': this._getCountryFromIDD(get(user, 'officeNumber.countryCode') && user.officeNumber.countryCode.replace('+', '')) || ''
    };

    const homeContact = {
      'homeContactIddCode': (get(user, 'phoneNumber.countryCode') && user.phoneNumber.countryCode.replace('+', '') || ''),
      'homeContactCountry': this._getCountryFromIDD(get(user, 'phoneNumber.countryCode') && user.phoneNumber.countryCode.replace('+', '')) || ''
    };

    const personalContact = {
      'mobileContactIddCode': (get(user, 'otherPhoneNumber.countryCode') && user.otherPhoneNumber.countryCode.replace('+', '') || ''),
      'mobileContactCountry': this._getCountryFromIDD(get(user, 'otherPhoneNumber.countryCode') && user.otherPhoneNumber.countryCode.replace('+', '')) || ''
    };

    const {mailingAddress, residentialAddress} = user;
    const addressCountries = {
      'residentalCountry': residentialAddress.country || '',
      'mailingAddressCountry': mailingAddress.country || ''
    }
    const taxResidencyDetails = {
      'taxResidencyCountries': (user.taxResidencyDetails || []).map((taxResidency) => {
        return taxResidency.country
      })
    }

    let data = {
      ...personalContact,
      ...officeContact,
      ...homeContact,
      ...addressCountries,
      ...taxResidencyDetails
    }

    data.taxResidencyCountries = data.taxResidencyCountries.sort();

    this._pristineDueDCheckFields = data;

    return data;
  }

  /**
   * Change the form state to read-only
   * */
  _setFormToReadOnly() {
    getFormInputs(this._form).forEach(input => {
      input.setReadOnly(true);
    });

    this._isReadOnly = true;
    this._taxResidencyFieldset.setEditable(false);
  }

  /**
   * updateReadOnlyState: This function will update the form in readonly state, and make the button hidden
   * */
  _updateReadOnlyState(isReadOnly) {
    if (isReadOnly) {
      // If txn-signing is not enabled, switch the form to read-only state
      this._setFormToReadOnly();

      this._userActionContainer.classList.add('sgx-hidden');
    } else {
      this._userActionContainer.classList.remove('sgx-hidden');
    }
  }


  /**
   * return txnSigningEnabled Status based on the details provided
   * update the alert bar based on the type identified
   * @param {Object} hardTokenInfo get the state of linkedHardToken
   * @param {String} idType get the state of idType to identify user-type [SC_PR, FOREIGNER, CORPORATE]
   * @param {Boolean} singpassAccountAvailable get the state of singpassAccountAvailable
   * @param {String} accountType type of account [individual, corporate, joint-and, joint-or, trustee]
   * @param {Boolean} hasSuspendedAccount boolean to show suspended account state
   * @param {String} issuingCountry string to get the value of issuingCountry
   * @link https://confluence.sgx.com/display/DIGITAL/SGX+Investors+-+Singpass+Integration#SGXInvestors-SingpassIntegration-SingPassTransactionSigning
   * */
  _handleTxnSigningStatus({
                            hardTokenInfo = {},
                            idType,
                            singpassAccountAvailable,
                            accountType,
                            hasSuspendedAccount,
                            issuingCountry
                          }) {
    const isForeigner = (idType == USER_TYPES.FOREIGNER)  || (idType == USER_TYPES.SC_PR && issuingCountry !== 'SG');
    const isSCPR = (idType == USER_TYPES.SC_PR) && (issuingCountry === 'SG');

    if (isForeigner) {
      this._staticMessageText.classList.remove('sgx-hidden');
      this._staticMessageText.setData({
        status: 'informational',
        text: i18n.getTranslation('app.settings-user.fin-static-message.text_html', {
          link: ConfigService.links.SGX_V2_UPDATE_PARTICULARS,
          interpolation: {'escapeValue': false}
        }),
        background: false
      });
    }

    if (isSCPR) {
      this._staticMessageText.classList.remove('sgx-hidden');
      this._staticMessageText.setData({
        status: 'informational',
        text: i18n.getTranslation('app.settings-user.scpr-static-message.text_html'),
        background: false
      });
    }

    if (hardTokenInfo && typeof hardTokenInfo === 'string') {
      hardTokenInfo = JSON.parse(hardTokenInfo);
    }

    // Add the case for app-test mode
    if (!ConfigService.features.txnSign) {

      window.toggleReadOnly = function (state) {
        this._updateReadOnlyState(state);
        getFormInputs(this._form).forEach(input => {
          input.setReadOnly(state);
        });
      }.bind(this);

      this._updateReadOnlyState(true);
      return;
    }

    // Add the case for suspended account
    if (hasSuspendedAccount) {
      this._setUserActionNotice('suspended', ConfigService.links.SGX_V2_UPDATE_PARTICULARS);
      this._updateReadOnlyState(true);

      if (isForeigner) {
        this._staticMessageText.classList.add('sgx-hidden');
      }

      return;
    }

    // If CORPORATE => NO TXN Signing
    if (accountType === ACCOUNT_STATES.CORPORATE) {
      this._setUserActionNotice('corporate', ConfigService.links.UPDATE_PARTICULARS_CORPORATE_LINK);
      this._updateReadOnlyState(true);
      return;
    }

    // If CORPORATE_TRUSTEE => NO TXN Signing
    if (accountType === ACCOUNT_STATES.CORPORATE_TRUSTEE) {
      this._setUserActionNotice('corporate_trustee', ConfigService.links.UPDATE_PARTICULARS_CORPORATE_TRUSTEE_LINK);
      this._updateReadOnlyState(true);
      return;
    }

    // If JOINT_AND => NO TXN Signing
    if (accountType === ACCOUNT_STATES.JOINT_AND) {
      this._handleState__JOINT_AND({
        singpassAccountAvailable,
        idType
      });
      return;
    }

    // Joint-and, corporate account not allowed to update using Myinfo
    const hasReadOnlyAccountTypes = [ACCOUNT_STATES.CORPORATE, ACCOUNT_STATES.CORPORATE_TRUSTEE, ACCOUNT_STATES.JOINT_AND].includes(accountType);
    if ((isSCPR || isForeigner) && !hasSuspendedAccount && !hasReadOnlyAccountTypes) {
      this._userMyInfoActionSubmit.removeAttribute('disabled');
      this._userMyInfoActionSubmit.classList.remove('sgx-hidden');

      // make the form readonly without hiding the action bar as it has the fin static message
      if (isForeigner && !singpassAccountAvailable) {
        this._setFormToReadOnly();
      }
      return;
    }

    this._updateReadOnlyState(false);
  }

  /**
   * update readonly state for joint-and (only) accounts
   * if user has multiple accounts, and any of the account is joint-alt or ind,
   * then user-particulars is editable else, show the alert bar with no update button
   * INDIVIDUAL => when accountCategory === ACCOUNT_CATEGORY.INDIVIDUAL [i.e 0]
   * JOINT_OR => when accountCategory-typeOfApproval === ACCOUNT_CATEGORY.JOINT_OR [i.e 1-2]
   * Original logic written in user-service.js in function updateCDPSessionAccountType
   * @param {Boolean} singpassAccountAvailable
   * @param {String} idType To check the loggedIn User Type
   * */
  _handleState__JOINT_AND({
                            singpassAccountAvailable,
                            idType
                          }) {
    const accounts = StoreRegistry.accountSelect.getData('options');
    let msg = 'joint_and';

    const isReadOnly = !(accounts.filter(({accountCategory, typeOfApproval}) => {

      // Check for individual account type, and write access
      const isIndividual = accountCategory === ACCOUNT_TYPES.INDIVIDUAL;
      const isJointOR = `${accountCategory}-${typeOfApproval}` === ACCOUNT_TYPES.JOINT_OR;

      return isIndividual || isJointOR;

    }).length > 0);

    isReadOnly && this._setUserActionNotice(msg, ConfigService.links.SGX_V2_UPDATE_PARTICULARS); // Show alert bar with joint-and message

    if (!isReadOnly) {
      this._userMyInfoActionSubmit.removeAttribute('disabled');
      this._userMyInfoActionSubmit.classList.remove('sgx-hidden');
    }

    if (idType == USER_TYPES.FOREIGNER) {
      // make the form readonly without hiding the action bar as it has the fin static message if singpass is not available
      !singpassAccountAvailable && this._setFormToReadOnly() || this._updateReadOnlyState(isReadOnly);
    } else {
      this._updateReadOnlyState(isReadOnly);
    }
  }

  /**
   * set User Action Notice based on the argument passed
   * type can be [singpass, onekey, suspended]
   * */
  _setUserActionNotice(type, link) {
    this._userActionsNotice.classList.remove('sgx-hidden');
    this._userActionsNotice.setData({
      status: i18n.getTranslation(`app.settings-user.notice-alert-bar.${type}.icon`),
      text: i18n.getTranslation(`app.settings-user.notice-alert-bar.${type}.message_html`, {
        link
      }),
      background: i18n.getTranslation(`app.settings-user.notice-alert-bar.${type}.background`)
    });
  }

  /**
   * Create a validation config for the form.
   * Also adds the required custom validators to the form.
   * @return {Object} the aggregated validation configuration
   */
  _createValidationConfig() {
    // Reference
    const validate = this._form.validator.validate;

    // Add custom validators here
    this._form.validator.validate.validators = addCustomValidators(this._form.validator.validate.validators);

    // Validation configuration
    this._validationConfig = createFormConfigFromSchema(validate, jsonSchema, 5);
    this._validationConfig = this._userAddressWidget.updateValidationConfig(validate, this._validationConfig, this._form);
    this._validationConfig = this._userContactWidget.updateValidationConfig(validate, this._validationConfig);
    this._validationConfig = this._taxResidencyFieldset.updateValidationConfig(validate, this._validationConfig, this._form);

    return this._validationConfig;
  }

  _updateSubmitState(formData) {
    const canCheckTaxResidency = !this._isReadOnly;
    let isInvalidTaxLength = false;
    // Force enable button because history is lost after SingPass page redirect
    if (this._userStore.getData('singpass-redirect')) {
      this._userActionSubmit.removeAttribute('disabled');
      return;
    }

    if (canCheckTaxResidency) {
      isInvalidTaxLength = this._taxResidencyFieldset.hasError();
    }

    // Otherwise do a check for the normal scenarios
    /**
     * Check if form has changed, if tax residency details have any warnings or errors.
     */
    if ((isPristine(formData, this._pristineData) || (isInvalidAndVisible(this._form) || isInvalidTaxLength))) {
      this._userActionSubmit.setAttribute('disabled', true);
    } else {
      this._userActionSubmit.removeAttribute('disabled');
    }
  }

  /**
   * Initialize the form data binding to the underlying store.
   */
  _initDataBinding() {

    // Init property config for data binding
    this._propertyConfigs = {
      'contacts[0]': TemplateSettingsUser.FORMATS.PHONES,
      'contacts[1]': TemplateSettingsUser.FORMATS.PHONES,
      'contacts[2]': TemplateSettingsUser.FORMATS.PHONES,
    };

    this._form.setJsonData({}, this._propertyConfigs);

    // Subscribe to change and input events
    const changeEvent = fromEvent(this._form, 'change');
    const inputEvent = fromEvent(this._form, 'input');
    const formEvents = merge(changeEvent, inputEvent);
    this._subscriptions.push(
      formEvents
        .pipe(
          debounceTime(10),
          switchMap(() => of(this._form.getJsonData(this._propertyConfigs))),
        )
        .subscribe(formData => {
          this._userStore.setData({
            'singpass-redirect': this._userStore.getData('singpass-redirect')
            , ...formData
          });

          // Enable or disable update button based on whether data is pristine or invalid
          this.formData = formData;
          this._form.validate();
          this._updateSubmitState(formData);
        })
    );

    // Disable default form submit event
    this._subscriptions.push(fromEvent(this._form, 'submit')
      .subscribe(e => {
        e.preventDefault();
      })
    );

    // Disable default form submit event
    this._subscriptions.push(fromEvent(this._userMyInfoActionSubmit, 'click')
      .subscribe(e => {
        e.preventDefault();
      })
    );

    // Listen for submit on child form, timeout to allow sgx-form to finish creating child form
    setTimeout(() => {
      this._childForm = this._form.firstElementChild;
      this._subscriptions.push(fromEvent(this._childForm, 'submit')
        .subscribe(e => {
          if (this._userActionSubmit.disabled) {
            return;
          }

          // to prevent user-details-myinfo dialog from showing when doing transaction signing
          StoreRegistry.cdpSession.setData({ ...StoreRegistry.cdpSession.getData(), updateParticularMode: '' });

          // Trigger a change event, to trigger change in the store
          this._form.dispatchEvent(new Event('change'));

          // Since there is a debounce of 10 milliseconds on change and updating the store
          setTimeout(_ => {
            // Initialize form update call
            this._sendFormData(this._createFormData());
          }, 20);

          // Prevent defaults
          e.preventDefault();
          e.stopImmediatePropagation();
        })
      );
    }, 0);

    // Listen for submit on child form, timeout to allow sgx-form to finish creating child form
    setTimeout(() => {
      // this._childForm = this._form.sec;
      this._subscriptions.push(fromEvent(this._userMyInfoActionSubmit, 'click')
        .subscribe(e => {
          if (this._userMyInfoActionSubmit.disabled) {
            return;
          }

          // Since there is a debounce of 10 milliseconds on change and updating the store
          setTimeout(_ => {
            this._widgetUserParticularsSSO.showDialog({type: 'my-info'});
          }, 20);

          // Prevent defaults
          e.preventDefault();
          e.stopImmediatePropagation();
        })
      );
    }, 0);

    this._subscriptions.push(fromEvent(this, 're-validate')
      .subscribe(e => {
        e.stopPropagation();
        this._updateSubmitState(this.formData);
      })
    );
  }

  _getCountryFromIDD(code = '') {
    const { countryCode } = InternationalCallingCode.getCountryByDialCode(code) || {};
    return countryCode || '';
  }

  /**
   * Create the form data to send to the server.
   * @return {Object} the pre-processed form data
   */
  _createFormData() {
    // Retrieve JSON object
    const content = this._userStore.getData();

    const phoneObj = { code: '', number: '' };
    const addressObj = {
      country: '',
      postalCode: '',
      state: '',
      streetOne: '',
      streetTwo: '',
      streetThree: '',
      streetFour: ''
    };

    //#region Adjust submission form data

    // Copy residential address to mailing if option was checked
    if (content._control.useResidentialForMailing) {
      content.addresses[1] = cloneDeep(content.addresses[0]);
      set(content, 'addresses[1].addressType', 'MAILING');

      if (content._control.residentialAddressOption === 'withinSG') {
        content.addresses[2] = cloneDeep(content.addresses[0]);
        set(content, 'addresses[2].addressType', 'CA ADDRESS');
      }
    } else if (content._control.mailingAddressOption === 'withinSG') {
      content.addresses[2] = cloneDeep(content.addresses[1]);
      set(content, 'addresses[2].addressType', 'CA ADDRESS');
    }

    const mobileContactNumberObj = content.contacts[0] || { ...phoneObj };
    const homeContactNumberObj = content.contacts[1] || { ...phoneObj };
    const officeContactNumberObj = content.contacts[2] || { ...phoneObj };

    const residentialAddressObj = content.addresses[0] || { ...addressObj };
    const mailingAddressObj = content.addresses[1] || { ...addressObj };
    const corporateAddressObj = content.addresses[2] || { ...addressObj };

    // Remove CA mailing address if totally empty
    if (content.addresses[2]
      && isBlankString(content.addresses[2].postalCode)
      && isBlankString(content.addresses[2].streetOne)
      && isBlankString(content.addresses[2].streetTwo)
      && isBlankString(content.addresses[2].streetThree)
      && isBlankString(content.addresses[2].streetFour)) {
      content.addresses.pop();
    } else {
      content.addresses[2].state = this.corporateAddressState;
    }

    const mailingAddress = {
      'mailingAddressLine1': mailingAddressObj.streetOne,
      'mailingAddressLine2': mailingAddressObj.streetTwo,
      'mailingAddressLine3': mailingAddressObj.streetThree,
      'mailingAddressLine4': mailingAddressObj.streetFour,
      'mailingAddressState': mailingAddressObj.state,
      'mailingAddressCountry': mailingAddressObj.country,
      'mailingPostalCode': mailingAddressObj.postalCode
    };

    const residentialAddress = {
      'residentalAddressLine1': residentialAddressObj.streetOne,
      'residentalAddressLine2': residentialAddressObj.streetTwo,
      'residentalAddressLine3': residentialAddressObj.streetThree,
      'residentalAddressLine4': residentialAddressObj.streetFour,
      'residentalState': residentialAddressObj.state,
      'residentalCountry': residentialAddressObj.country,
      'residentalPostalCode': residentialAddressObj.postalCode
    };

    const registeredAddress = {
      'registeredAddressLine1': residentialAddressObj.streetOne,
      'registeredAddressLine2': residentialAddressObj.streetTwo,
      'registeredAddressLine3': residentialAddressObj.streetThree,
      'registeredAddressLine4': residentialAddressObj.streetFour,
      'registeredState': residentialAddressObj.state,
      'registeredCountry': residentialAddressObj.country,
      'registeredPostalCode': residentialAddressObj.postalCode
    };

    const isMailingAddressSameAsCorporate = mailingAddressObj.country === 'SG';

    const corporateAddress = {
      'caAddressLine1': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.streetOne,
      'caAddressLine2': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.streetTwo,
      'caAddressLine3': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.streetThree,
      'caAddressLine4': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.streetFour,
      'caState': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.state || '',
      'caCountry': isMailingAddressSameAsCorporate ? '' : (corporateAddressObj.streetOne || corporateAddressObj.streetTwo || corporateAddressObj.streetThree || corporateAddressObj.streetFour || corporateAddressObj.state || corporateAddressObj.postalCode) ? corporateAddressObj.country : '',
      'caPostalCode': isMailingAddressSameAsCorporate ? '' : corporateAddressObj.postalCode
    };

    const officeContact = {
      'officeEmail': '',
      'officeContactIddCode': officeContactNumberObj.code,
      'officeContactNum': officeContactNumberObj.number,
      'officeContactCountry': officeContactNumberObj.country || ''
    };

    const homeContact = {
      'homeContactIddCode': homeContactNumberObj.code,
      'homeContactNum': homeContactNumberObj.number,
      'homeContactCountry': homeContactNumberObj.country || ''
    };

    const personalContact = {
      'personalEmail': content.email,
      'mobileContactIddCode': mobileContactNumberObj.code,
      'mobileContactNum': mobileContactNumberObj.number,
      'title': content.title,
      'gender': content.gender,
      'mobileContactCountry': mobileContactNumberObj.country || ''
    };

    let formObj = { ...officeContact, ...homeContact, ...personalContact, ...mailingAddress, ...corporateAddress };

    // Registered Address should only be sent in case of corporate user
    if (this._cdpStore.getData().accountType === ACCOUNT_STATES.CORPORATE) {
      formObj = { ...formObj, ...registeredAddress }
    } else {
      formObj = { ...formObj, ...residentialAddress }
    }

    formObj = {...formObj, ...this._taxResidencyFieldset.getData()}
    formObj.fatcaCrsDueDCheck = this._isReadOnly ? null : this._fatcaCrsDueDCheck;


    return formObj;

    //#endregion

    //#region End adjust submission form data
  }

  _getCountryName(countryCode) {
    let countryName = this._countryList.find(country => country.id === countryCode).name;
    let words = countryName.toLowerCase().split(' ');
    countryName = words.map((word) => capitalize(word)).join(' ');
    return countryName.trim();
  }

  /**
   * @method
   * @private
   * @param {Object} formData
   * @returns {Boolean}
   *
   * This method perform FATCA CRS DUE D check if residential/mailing address and
   * contact details against the country of tax residency to ensure a corresponding
   * tax details is declared for each country of address and contact details.
   */
  _performDueDCheck(formData) {
    let { hasAddressMismatch, hasIddMismatch, hasMismatch} = false;
    let mismatchCountries = [];
    let mismatchIddCountries = {};
    let mismatchAddressCountries = {};
    const countryFields = ['mailingAddressCountry', 'residentalCountry'];
    const phoneFields = ['homeContactCountry', 'mobileContactCountry', 'officeContactCountry'];
    const declaredCountries = formData.taxResidencyDetails.map((taxResidency) => taxResidency.country);

    countryFields.forEach((countryField) => {
      if (formData[countryField]) {
        let isDeclared = declaredCountries.includes(formData[countryField]);
        if (!isDeclared) {
          hasAddressMismatch = true;
          mismatchAddressCountries[formData[countryField]] = this._getCountryName(formData[countryField]);
        }
      }
    });

    for(var i=0; i <= phoneFields.length; i++) {
      const phoneField = phoneFields[i];
      if (formData[phoneField]) {
        let selectedCountry = formData[phoneField];
        let isDeclared = declaredCountries.includes(selectedCountry);
        if (selectedCountry === 'SG' && declaredCountries.includes('SG')) {
          isDeclared = true;
          hasIddMismatch = false;
          mismatchIddCountries = {};
          break;
        }
        if (!isDeclared) {
          hasIddMismatch = true;
          const countryDetails = InternationalCallingCode.getCountryByCountryCode(selectedCountry);
          if(!mismatchAddressCountries[selectedCountry]){
            mismatchIddCountries[selectedCountry] = countryDetails.name;
          }
        }
      }
    }

    hasMismatch = hasIddMismatch || hasAddressMismatch;
    const consolidatedCountries = {...mismatchAddressCountries, ...mismatchIddCountries};
    mismatchCountries = Object.keys(consolidatedCountries).map(key => consolidatedCountries[key]);

    if (hasMismatch) {
      this._dueDCheckCountries = mismatchCountries.filter((country, index) => mismatchCountries.indexOf(country) === index);
    }

    this._fatcaCrsDueDCheck = hasMismatch ? '1' : '0';
    formData.fatcaCrsDueDCheck = this._fatcaCrsDueDCheck;
    return hasMismatch;
  }


  /**
   * Send the form data to the server.
   * @param {Object} formData the form data to submit
   * @return {Promise} the submission completion promise
   */
  _sendFormData(formData) {
    //Only individual and joint or account are applicable for fatca due d check
    const fatcaFieldsUpdated = this._isFatcaCheckFieldsUpdated(formData);
    if (!this._isReadOnly && fatcaFieldsUpdated && !this._skipDueDCheck) {
      const hasMismatch = this._performDueDCheck(formData);
      if (hasMismatch) {
        this._dueDCheckDialog.setData(this._dueDCheckCountries);
        this._dueDCheckDialog.showDialog();
        return;
      }
    } else if (!fatcaFieldsUpdated) {
      formData.fatcaCrsDueDCheck = null;
    }

    const userSettingsData = this._userStore.getData();
    userSettingsData.taxResidencies = formData.taxResidencyDetails;
    this._userStore.setData(userSettingsData);
    this._userStore.setData({ isChecked: true, value: formData.fatcaCrsDueDCheck }, 'fatcaCRS');
    this._widgetUserParticularsSSO.showDialog({ type: 'txn-signing', payload: formData });
    this._skipDueDCheck = false;
  };

  /**
   * @private
   * @desc: handle success-state of txn-signing specific to the template
   * */
  _stateSuccessCB() {
    this._pristineData = takeSnapshot(this._form.getJsonData(this._propertyConfigs));
    this._userActionSubmit.setAttribute('disabled', true);
  }

  /**
   * @private
   * @desc: handle success state onClick of txn-signing button from widget-user-particulars-sso
   * */
  _onClickSuccessCB() {
    this._loadData({
      accountType: this._accountType,
      isOTPEnabled: this._isOTPEnabled
    });
  }


  /**
   * @private
   * @desc: handle error state onClick of txn-signing button from widget-user-particulars-sso
   * */
  _onClickErrorCB() {
    this._userActionSubmit.setAttribute('disabled', true);
    this._loadData({
      accountType: this._accountType,
      isOTPEnabled: this._isOTPEnabled
    });
  }

}

customElements.define('template-settings-user', withInitDOM(TemplateSettingsUser));

//#endregion
