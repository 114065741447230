module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-list-row-market-performance\">\n  <div class=\"cmp-list-row-market-performance__row sgx-base-text-body-14\">\n    <div class=\"cmp-list-row-market-performance-labels-container\">\n      <div class=\"cmp-list-row-market-performance--name\"></div>\n      <div class=\"cmp-list-row-market-performance--price sgx-icon--before\"></div>\n    </div>\n    <div class=\"cmp-list-row-market-performance-value-container\">\n      <div class=\"cmp-list-row-market-performance-labels\">\n        <div class=\"cmp-list-row-market-performance-volume-label\" data-i18n=\"cmp-list-row-market-performance.volume\"></div>\n        <div class=\"cmp-list-row-market-performance-value-label\" data-i18n=\"cmp-list-row-market-performance.value\"></div>\n      </div>\n      <div class=\"cmp-list-row-market-performance-values\">\n        <div class=\"cmp-list-row-market-performance--volume\"></div>\n        <div class=\"cmp-list-row-market-performance--value\"></div>\n      </div>\n    </div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()