module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-txn-signing\">\n  <sgx-dialog id=\"cmp-txn-signing-dialog\" role=\"dialog\">\n    <h2 data-dialog=\"title\" data-i18n=\"app.txn-signing.title\"></h2>\n    <div data-dialog=\"body\">\n      <div class=\"cmp-txn-signing-flows\">\n        <sgx-status-indicator class=\"cmp-txn-signing-indicator sgx-status-indicator--hidden\"></sgx-status-indicator>\n        <cmp-txn-signing-singpass></cmp-txn-signing-singpass>\n      </div>\n    </div>\n    <button class=\"sgx-button--text cmp-txn-signing-button-cancel\" data-dialog=\"cancel\" data-i18n=\"app.txn-signing.button.cancel\"></button>\n  </sgx-dialog>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()