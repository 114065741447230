import {withInitDOM} from 'sgx-base-code';
import tmpl from './widget-index-single.html';
import StoreRegistry from 'stores/store-registry';
import ConfigService from 'sgx-config-service';
import DateService from 'sgx-date-time-service';
import i18n from 'sgx-localisation-service';
import IndicesService from "sgx-indices-service";
import SGXInViewport from 'sgx-in-viewport';
import { fromEvent } from 'rxjs';
import SgxAnalyticsService from 'sgx-analytics-service';
import { get } from 'lodash';

export default class WidgetIndexSingle extends SGXInViewport {
  constructor() {
    super();
    this._store = StoreRegistry.dashboardEquity;
    this._id = '.STI';
    this._indexCardData = {};

  }

  _sendGAEvent(event) {
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    SgxAnalyticsService.sendEvent(
      eventCategory,
      `STI-${this._indexCardData.name}`,
      'widget-viewed'
    );
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._indicator = this.querySelector('sgx-status-indicator');
    this._dateInfo = this.querySelector('.widget-index-single-date');
    const indicesCard = document.createElement('sgx-indices-card');
    indicesCard.setAttribute('data-layout', this.dataset.layout);
    this.appendChild(indicesCard);
    this._indicesCard = this.querySelector('sgx-indices-card');
    super.initDOM();
  }

  connectedCallback() {
    this.setData();
    fromEvent(this, 'onViewPortEnter').subscribe(event => this._sendGAEvent(event));
  }

  disconnectedCallback() {

  }

  setData() {
    const date = DateService(Date.now()).format(i18n.getTranslation('app.shared-text.list-details.header.date-format'));
    this._dateInfo.textContent = i18n.getTranslation(
      'app.shared-text.list-details.header.date-time-requested', {date});
    this._renderIndex();
    this._indicator.hide();
  }

  async _renderIndex() {
    await this._priceSubscription();
    await this._chartSubscription();
    const lang = i18n.getLanguage().replace(/-/g, "_");

    this._indicesCard.setData({
      ...this._indexCardData,
      link: ConfigService.links.SGX_V2_INDICES_PRODUCTS[lang] + this._id.replace('.', '').toLowerCase()
    });
  }

  _priceSubscription() {
    return new Promise((res, rej) => {
      this._priceSubscriptionRef = IndicesService.subscribePriceData(
        this._id,
        data => {
          this._indexCardData = {
            ...this._indexCardData,
            ...{
              name: `${data.n} (STI)`,
              value: data.lp ? data.lp.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A',
              change: data.c,
              percentChange: data.ptc
            }
          };
          res(this._indexCardData);
        }
      );
    })

  }

  _chartSubscription() {
    return new Promise((res, rej) => {
      this._chartSubscriptionRef = IndicesService.subscribeChart(
        chartData => {
          this._indexCardData = {
            ...this._indexCardData,
            historicData: chartData.data.reduce((result, item) => {
              result.push(item[1]);
              return result;
            }, [])
          }
          res(this._indexCardData);
        },
        this._id,
        'intraday',
        5,
        'd',
      );
    })

  }
}

customElements.define('widget-index-single', withInitDOM(WidgetIndexSingle));
