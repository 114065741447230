import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-security-details-market.html';
import MarketResearchService from 'services/market-research-service';
import DateService from 'sgx-date-time-service';
import i18n from 'sgx-localisation-service';

class TemplateSecurityDetailsMarketWidget extends HTMLElement {
  constructor() {
    super();
    this._router = document.getElementById('sgx-app-router');
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-market');
    this._list = this.querySelector('.widget-security-details-market-list');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._latestUpdateTool = this.querySelector('sgx-data-model-tool-display-latest-update');
    this._countTool = this.querySelector('sgx-data-model-tool-display-count');
  }

  connectedCallback() {
    // Fixes issue where count resets to 0 on refresh
    if (this._fixedDataLength) {
      this._countTool.setCount(this._fixedDataLength);
    }
  }

  setData(securityData) {
    this._list.setConfig({
      'rowElementName': 'cmp-list-row-market-research',
      'rowClickedCallback': rowData => {
        if (this._router) {
          this._router.navigateToURL(`/market-research?news=${rowData.id}`);
        }
      }
    });
    if (!securityData.nc) {
      return;
    }
    this._indicator.show();
    MarketResearchService.getMarketUpdatesListingData({
      'securityCode': securityData.nc,
    }).then(({data, _}) => {
      const dataSubset = data.slice(0, 8);

      // Make a reference to fixed number of list items
      this._fixedDataLength = dataSubset.length;

      this._list.setData(dataSubset);
      this._indicator.hide();

      if (this._latestUpdateTool) {
        const date = DateService(Date.now()).format(i18n.getTranslation('app.shared-text.list-details.header.date-format'));
        this._latestUpdateTool.setDateInfo(date);
      }

      if (this._countTool) {
        this._countTool.setCount(this._fixedDataLength);
      }

      if (!this._fixedDataLength) {
        this._indicator.show({
          status: 'neutral',
          title: i18n.getTranslation('app.shared-text.status-indicator.empty.description')
        });
      }
    });
  }
}

customElements.define('widget-security-details-market', withInitDOM(TemplateSecurityDetailsMarketWidget));
