import i18n from 'sgx-localisation-service';
import { validateSingaporeNRIC } from 'utils/nric-util'

/**
 * @desc Populates a radio list or select field based on a list of possible values.
 * @param {sgx-input-radio-list|sgx-input-select} fieldEl HTML element that implements setOptions
 * @param {Array} propertyEnum the available values
 * @param {String} i18nNamespace the namespace including the labels for the values
 * @param {Boolean} addDefaultOption if a default option must be added (i18n will look up "_default" in the i18nNamespace)
 */
export function setFieldOptions(fieldEl, propertyEnum, i18nNamespace, config) {
  let options = [];
  config = config || {};
  if (config.addDefaultOption) {
    options.push({
      "value": null,
      "label": i18n.getTranslation(`${i18nNamespace}._default`)
    });
  }
  options = options.concat(propertyEnum.map(
    value => {
      const i18nKey = `${i18nNamespace}.${value}`;
      const label = i18n.getTranslation([i18nKey, value]);;
      return {
        "value": value,
        "label": label
      };
    }
  ));
  fieldEl.setOptions(options, config.selectFirst, config.silent);
}

/**
 * Toggles the display of the given element.
 *
 * @param {HTMLElement} el the element
 * @param {Boolean} show if the element should be shown
 */
export function showHideEl(el, show) {
  if (show) {
    el.classList.remove("sgx-hidden");
  } else if (!el.classList.contains("sgx-hidden")) {
    el.classList.add("sgx-hidden");
  }
}

/**
 * @desc Gets all the SGX inputs inside a given container.
 *
 * @param {HTMLElement} container the container to search
 * @return {HTMLElemet[]} the list of contained SGX inputs
 */
export function getFormInputs(container) {
  return [].slice.call(container.querySelectorAll('*')).filter(el => {
    return el.tagName.match(/^sgx-input/i);
  });
};

export function addCustomValidators(validators) {
  return Object.assign(validators, {
    presenceIf(value, options) {
      if (options.predicate.call(options.scope, options.param) && (value === null || value === undefined || /^\s*$/.test(value))) {
        return options.message.call(options.scope);
      }
      return;
    },
    mobilePhoneRule(value, options) {
      if(!options.scope.getNumber() && !options.scope.getCode()){
        return options.message.call(options.scope,i18n.getTranslation('app.widget-settings-user-general.inputs.mobilePhone.countryCodeAndNumber'))
      }
      if(!options.scope.getNumber()){
        return options.message.call(options.scope,i18n.getTranslation('app.widget-settings-user-general.inputs.mobilePhone.label'))
      }
      if(!options.scope.getCode()){
        return options.message.call(options.scope,i18n.getTranslation('app.widget-settings-user-general.inputs.mobilePhone.countryCode'))
      }
      if (!/^$|^\+[1-9][0-9]*\s[0-9]+$/.test(value)) {
        return options.message.call(options.scope,i18n.getTranslation('app.widget-settings-user-general.inputs.mobilePhone.label'));
      }
    },
    singaporePhone(value, options) {
      if (!value || value.substr(0, 4) !== '+65 ') {
        return;
      }
      if (options.mobileOnly && !/^\+65 (8|9)[0-9]{7}$/.test(value) || !/^\+65 (3|6|8|9)[0-9]{7}$/.test(value)) {
        return options.message.call(options.scope);
      }
    },
    singaporeNricIf: function(value, options) {
      if (!value) {
        return;
      }
      if (options.predicate.call(options.scope) && !validateSingaporeNRIC(value)) {
        return options.message.call(options.scope);
      }
    },
    singaporePostalCodeIf(value, options) {
      if (!value) {
        return;
      }
      if (options.predicate.call(options.scope) && !/^[0-9]{6}$/.test(value)) {
        return options.message.call(options.scope);
      }
    },
    addressScenario1: function (value, options) {
      if (!value) {
        return;
      }
      if (/[&'<>"]/.test(value)) {
        return options.message.call(options.scope);
      }
    },
    addressScenario2: function (value, options) {
      if (!value) {
        return;
      }
      if (/(\/ *\*)/.test(value)) {
        return options.message.call(options.scope);
      }
    },
    addressScenario3: function (value, options) {
      if (!value) {
        return;
      }
      if (/(- *-)/.test(value)) {
        return options.message.call(options.scope);
      }
    },
    addressScenario4: function (value, options) {
      if (!value) {
        return;
      }

      if (/[&'<>"](?=(\/ *\*))|(\/ *\*)( *(?=(?=[&'<>"])))|[&'<>"]( *(?=(?=(- *-))))|(- *-)(?=( *(?=[&'<>"])))|(\/ *\*)( *(?=(?=(- *-))))|(- *-)(?=(\/ *\*))$/.test(value)) {
        return options.message.call(options.scope);
      }
    }
  });
}

/**
 * Creates a validation configuration from a JSON schema.
 *
 * @param {validatejs} validate the validate.js instance being used
 * @param {Object} schema the JSON schema to parse
 * @param {Integer} arraysMaxSize the maximum size for the array-structured data
 *
 * @return {Object} a form configuration based on the JSON schema
 */
export function createFormConfigFromSchema(validate, schema, arraysMaxSize = 0) {
  const formConfig = {
    alignment: 'vertical',
    validate: {
      rules: {}
    }
  };

  let props;
  if (!schema || !(props = schema.properties)) {
    return formConfig;
  }

  formConfig.validate.rules = _createRulesFromProperties(validate, schema.definitions, arraysMaxSize, props);
  formConfig.validate.rules.onValidate = () => {};
  formConfig.validate.rules.onValidateSuccess = () => {};

  return formConfig;
}

/**
 * Creates a set of validation rules from schema information.
 *
 * @param {validatejs} validate the validate.js instance being used
 * @param {Object} defs the definitions given by the schema
 * @param {Integer} arraysMaxSize the maximum size for the array-structured data
 * @param {Object} props the props for which to produce validation rules
 * @param {Object[]} initialRules the initial set of rules to add to
 * @param {String} prefix the prefix of the properties names
 *
 * @return {Object[]} the created set of rules
 */
function _createRulesFromProperties(validate, defs, arraysMaxSize, props, initialRules = {}, prefix = '') {
  return Object.getOwnPropertyNames(props).reduce((rules, propName) => {
    if (propName === '$ref') {
      // Definition
      const defName = props[propName].split('/').pop();
      const def = defs[defName];
      if (def.type !== 'array' && def.type !== 'object') {
        rules[`${prefix}`] = _createRuleFromProperty(validate, defs, def, defName);
      } else if (def.type === 'object') {
        rules = _createRulesFromProperties(validate, defs, arraysMaxSize, def.properties, rules, `${prefix}/`);
      } else if (def.type === 'array') {
        for (let i=0; i<arraysMaxSize; i++) {
          rules = _createRulesFromProperties(validate, defs, arraysMaxSize, def.items, rules, `${prefix}[${i}]`);
        }
      }
    } else {
      // Embedded property
      let prop = props[propName];
      if (prop.type !== 'array' && prop.type !== 'object') {
        rules[`${prefix}${propName}`] = _createRuleFromProperty(validate, defs, prop, propName);
      } else if (prop.type === 'object') {
        rules = _createRulesFromProperties(validate, defs, arraysMaxSize, prop.properties, rules, `${prefix + propName}/`);
      } else if (prop.type === 'array') {
        for (let i=0; i<arraysMaxSize; i++) {
          const isNextOneObject = (prop.items.type === 'object');
          const nextPrefix = `${prefix}${propName}[${i}]${(isNextOneObject ? '/' : '')}`;
          rules = _createRulesFromProperties(validate, defs, arraysMaxSize, isNextOneObject ? prop.items.properties : prop.items, rules, nextPrefix);
        }
      }
    }
    return rules;
  }, initialRules);
}

/**
 * Creates an actual validation rule from a given schema property.
 *
 * @param {Object} defs the definitions given by the schema
 * @param {Object} prop the property for which to produce a rule
 *
 * @return {Object} the created rule
 */
function _createRuleFromProperty(validate, defs, prop, propName) {
  // TODO support internationlized labels (first, add the keys to the schema as "labelKey" property on the prop)
  let rule = {};

  if (prop.required) {
    rule.presence = {
      message(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format(i18n.getTranslation("app.shared-text.form-validation.is-required-w-field-name"), {
          fieldName: i18n.getTranslation(`app.shared-text.field-names.${propName}`)
        });
      }
    };
  }

  if (prop.pattern) {
    rule.format = {
      pattern: prop.pattern,
      message(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format(i18n.getTranslation("app.shared-text.form-validation.not-valid-w-field-name"), {
          fieldName: i18n.getTranslation(`app.shared-text.field-names.${propName}`)
        });
      }
    };
  }

  if (prop.email) {
    rule.email = {
      message(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format(i18n.getTranslation("app.shared-text.form-validation.not-valid-w-field-name"), {
          fieldName: i18n.getTranslation(`app.shared-text.field-names.${propName}`)
        });
      }
    }
  }

  if (Array.isArray(prop.enum)) {
    rule.inclusion = {
      within: [''].concat(prop.enum),
      message(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format(i18n.getTranslation("app.shared-text.form-validation.not-valid-w-field-name"), {
          fieldName: i18n.getTranslation(`app.shared-text.field-names.${propName}`)
        });
      }
    };
  }

  if (prop.maxLength) {
    rule.length = {
      maximum: prop.maxLength,
      message(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format(i18n.getTranslation("app.shared-text.form-validation.too-long-w-field-name"), {
          fieldName: i18n.getTranslation(`app.shared-text.field-names.${propName}`)
        });
      }
    };
  }

  if (prop['$ref']) {
    const defName = prop['$ref'].split('/').pop();
    const def = defs[defName];
    Object.assign(rule, _createRuleFromProperty(validate, defs, def, defName));
  }

  return rule;
}

/**
 * Tests if a string is blank.
 *
 * @param {String} str the string to test
 * @return {Boolean} whether the string is blank
 */
export function isBlankString(str) {
  return /^\s*$/.test(str);
}

/**
 * Takes a snapshot of form data in its pristine state, before editing takes place.
 * Can be used later to check if any form values actually changed.
 * @param {Object} formData the form data as a json object
 */
export function takeSnapshot(formData) {
  return JSON.stringify(formData);
};

/**
 * Checks if the form data is still pristine, e.g. no values have actually changed.
 * @param {Object} formData the form data as a json object
 * @param {String} pristineData the pristine data as a JSON string
 * @return {Boolean} whether the data is pristine or not
 */
export function isPristine(formData, pristineData) {
  return pristineData === JSON.stringify(formData) ? true : false;
};

/**
 * Checks if any required form inputs have not passed validation.
 * @param {Object} container the container to evaluate the inputs within
 * @return {Boolean} whether the data is valid or not
 */
export function isInvalid(container) {
  const inputs = getFormInputs(container);
  const inputsWithErrors = inputs.filter(input => input.classList.contains('sgx-input--has-error'));
  return inputsWithErrors && inputsWithErrors.length > 0 ? true : false;
};

/**
 * Same as isInvalid, but checks only visible inputs.
 * @param {Object} container the container to evaluate the inputs within
 * @return {Boolean} whether the data is valid or not
 */
export function isInvalidAndVisible(container) {
  const isVisible = input => !!(input.offsetWidth || input.offsetHeight || input.getClientRects().length);
  const inputs = getFormInputs(container);
  const inputsWithErrors = inputs.filter(input => input.classList.contains('sgx-input--has-error') && isVisible(input));
  return inputsWithErrors && inputsWithErrors.length > 0 ? true : false;
};

/**
 * Checks if all form inputs have been filled in.
 * @param {Object} container the container to evaluate the inputs within
 * @param {Array} excludeInput input names that are not mandatory
 * @return {Boolean} whether the form is complete or not
 */
export function isComplete(container, excludeInput = []) {
  const inputs = getFormInputs(container);
  const completeInputs = inputs.filter(input => !!input.getValue() || !!~excludeInput.indexOf(input.name));
  return inputs.length === completeInputs.length ? true : false;
};
