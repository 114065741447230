const template = (id) => `
  <sgx-dialog id="${id}" role="dialog">
    <h2 data-dialog="title"></h2>
    <cmp-portfolio-unit-cost data-dialog="body"></cmp-portfolio-unit-cost>
    <button data-i18n="cmp-portfolio-unit-cost-dialog.button.calculate" class="sgx-button--primary sgx-button--small" data-dialog="ok" disabled></button>
    <button data-i18n="cmp-portfolio-unit-cost-dialog.button.cancel" class="sgx-button--link sgx-button--small" data-dialog="cancel"></button>
  </sgx-dialog>
`;

export default template;
