const USER_ID_TYPE = {
  'nric': '1',
  'malaysia-ic': '1',
  'passport': '2',
  'corporate': '3'
};

export {
  USER_ID_TYPE
};
