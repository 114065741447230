import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-account-trading.html';
import i18n from 'sgx-localisation-service';
import TradingAccountService from 'services/trading-account-service';
import PortfolioService from 'services/portfolio-service';
import { fromEvent } from 'rxjs';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';

class TradingAccountWidget extends HTMLElement {
  constructor() {
    super();
    this._subscriptions = [];
    this._lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-account-trading');
    this._title = this.querySelector('.widget-account-trading-title');
    this._rowsContainer = this.querySelector('.widget-account-trading-container');
    this._promptRow = this.querySelector('.widget-account-trading-prompt');
    this._promptText = this.querySelector('.widget-account-trading-prompt-text');
    this._addBlbWidget = this.querySelector('.widget-settings-account-trading-dialog');
    this._addBlbDialog = this.querySelector('.widget-trading-dialog');
    this._statusIndicator = this.querySelector('.widget-account-trading-loader');
  }

  connectedCallback() {}
  disconnectedCallback() {}

  setData(data) {
    this._statusIndicator.show({
      status: 'loading'
    });
    this._accountId = data.accountId;
    Promise.all([
      TradingAccountService.getTradingAccounts(data.accountId),
      PortfolioService.getBalances(data.accountId)
    ])
      .then(([accounts, balances]) => {
        if (this._accountId !== data.accountId) {
          return;
        }
        this._balancedAccounts = [];

        if (accounts && accounts.length > 0) {

          // Apply balance as a property of an account if it has one
          this._balancedAccounts = accounts.reduce((acc, account) => {
            balances.forEach(balance => {
              if (account.brokerNo === balance.broker) {
                account.balance = balance;
              }
            });
            acc.push(account);
            return acc;
          }, []);
        }

        this._render(this._balancedAccounts);
        return this._balancedAccounts
      })
      .then(accounts => {
        if (!accounts) {
          return;
        }
        this._setListeners(accounts);
      })
      .then(_ => this._statusIndicator.hide())
      .catch(_ => {
        this._statusIndicator.show({
          status: 'error',
          title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
          description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
        });
      });
  }

  _setListeners(accounts) {
    accounts.forEach(account => {
      if (!account.balance) {
        // Get blb setup button by account id
        this._blbSetupButton = this.querySelector(`.trading-account-row-blb-button--${account.id}`);

        // Pass broker and initialize dialog
        this._subscriptions.push(fromEvent(this._blbSetupButton, 'click')
          .subscribe(() => {
            this._addBlbWidget.setBroker(account.brokerName, account.brokerNo);
            this._addBlbDialog.show({
              isModal: true
            });
          })
        );
      }
    })
  }

  _render(accounts) {
    if (accounts && accounts.length > 0) {
      // Initialize standalone list
      if (!this._list) {
        this._list = document.createElement('cmp-list-standalone');
        this._list.setConfig({ rowElementName: 'cmp-list-row-account-trading' });
        this._rowsContainer.insertBefore(this._list, this._promptRow);
      }
      this._list.show();
      this._list.setData(accounts);
      this._promptRow.style.display = 'none';

    } else {
      if (this._list) {
        this._list.hide();
      }
      this._promptRow.style.display = 'flex';
      this._promptText.innerHTML = i18n.getTranslation('app.widget-settings-account-trading.prompts.apply.text_html', {
        link: ConfigService.links.SGX_V2_RETAIL_BROKERS[this._lang]
      });
    }
  }
}

customElements.define('widget-settings-account-trading', withInitDOM(TradingAccountWidget));
