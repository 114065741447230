import i18n from 'sgx-localisation-service';
import { withInitDOM } from 'sgx-base-code';

const rmkClassName = 'sgx-table-cell-security-rmk';
const swlClassName = 'sgx-table-cell-security-swl';
const callClassName = 'sgx-table-cell-security-info';
const spacClassName = 'sgx-table-cell-security-spac';
const ttClassName = 'sgx-table-cell-security-tt';

/**
 * A custom HTML element used as a decorator for securities prices widget
 * @module sgx-table-decorator-securities
 * @type {HTMLElement}
 */

class SgxTableDecoratorSecurities extends HTMLElement {
  constructor() {
    super();
    this._onMouseEnter = this._onMouseEnter.bind(this);
    this._onScroll = this._onScroll.bind(this);
    this._onCellClick = this._onCellClick.bind(this);
    this._watchlistMessage = i18n.getTranslation('sgx-table-cell-securities.stockwatchlist-message');
  }

  initDOM() {
    this.style.display = 'none';
  }

  disconnectedCallback() {
    if (this._tableList) {
      this._tableList.removeEventListener('click', this._onCellClick);
    }
  }

  decorate(decoratorObject) {
    const config = decoratorObject.decoratorConfig;
    const cellId = decoratorObject.decoratorConfig.cellToDecorate;
    const rowData = decoratorObject.rowData;
    const rowElem = decoratorObject.element;
    const cell = rowElem.querySelector(`[data-column-id = ${cellId}]`);
    const table = decoratorObject.element.closest('sgx-table');
    const rmk = (rowData.rmk || []).join(';').trim();
    const info = (rowData.info || []).join(' ').trim();
    const spac = rowData.sip.toUpperCase() === 'SP';
    const tt = rowData.sip.toUpperCase() === 'TT';

    this._cellsToDecorate = Array.isArray(config.cellToDecorate) ? config.cellToDecorate : [config.cellToDecorate];

    if (!table.dataset.securitiesDecorator) {
      table.addEventListener('mouseover', this._onMouseEnter);
    }

    if (rmk.length) {
      const tooltipContent = cell.querySelector(`.${rmkClassName} [data-sgx-tooltip-content]`);
      const list = rmk.split(/;/gm).reduce((str, item) => `${str}<li>${item}</li>`, '');
      tooltipContent.innerHTML = `${i18n.getTranslation('sgx-table-cell-securities.remarks')}:<ul>${list}</ul>`;
      cell.setAttribute('data-rmk', rmk);
    } else {
      cell.removeAttribute('data-rmk');
    }

    if (rowData.swl) {
      const tooltipContent = cell.querySelector(`.${swlClassName} [data-sgx-tooltip-content]`);
      tooltipContent.innerHTML = this._watchlistMessage;
      cell.setAttribute('data-swl', 'true');
    } else {
      cell.removeAttribute('data-swl');
    }

    if (info) {
      const tooltipContent = cell.querySelector(`.${callClassName} [data-sgx-tooltip-content]`);
      tooltipContent.innerHTML = info;
      cell.setAttribute('data-info', info);
    } else {
      cell.removeAttribute('data-info');
    }

    if(spac){
      const tooltipContent = cell.querySelector(`.${spacClassName} [data-sgx-tooltip-content]`);
      tooltipContent.innerHTML = i18n.getTranslation('sgx-table-cell-securities.spac');
      cell.setAttribute('data-spac', 'spac');
    }

    if (tt) {
      const tooltipContent = cell.querySelector(`.${ttClassName} [data-sgx-tooltip-content]`);
      tooltipContent.innerHTML = i18n.getTranslation('sgx-table-cell-securities.tt');
      cell.setAttribute('data-tt', 'tt');
    }

    this._tableList = decoratorObject.element.closest('sgx-table').querySelector('sgx-table-list');
    this._tableList.addEventListener('click', this._onCellClick);
  }

  _onCellClick(evt) {
    evt.preventDefault();
    const target = evt.target.parentElement;
    const {columnId, disabled} = target.dataset;

    if (!!~this._cellsToDecorate.indexOf(columnId) && !disabled) {
      const rowId = target.parentElement.dataset.rowId;

      target.dispatchEvent(new CustomEvent('ROW_SECURITY_CLICKED', {
        bubbles: true,
        detail: {
          colId: columnId,
          rowId
        }
      }));
    }
  }

  _onScroll() {
    this.style.display = 'none';
  }

  _onMouseEnter(evt) {
    const target = evt.target;

    let analyticsCategory = '';

    if (target.className === rmkClassName) {
      analyticsCategory = 'Remark';
    } else if (target.className === swlClassName) {
      analyticsCategory = 'Stock Watchlist';
    } else if (target.className === callClassName) {
      analyticsCategory = 'Remark';
    } else {
      return;
    }

    const securityCell = target.closest('sgx-table-cell-securities');
    const securityName = securityCell.querySelector('.sgx-table-cell-security-name');
    if (securityName) {
      this.dataset.analyticsLabel = `${analyticsCategory}:${securityName.title}`;
      this.dispatchEvent(new CustomEvent('sgx-analytics', {bubbles: true, detail: 'hover'}));
    }
  }
}

customElements.define('sgx-table-decorator-securities', withInitDOM(SgxTableDecoratorSecurities));
const securitiesDecorator = document.createElement('sgx-table-decorator-securities');
securitiesDecorator.classList.add('sgx-table-decorator-securities');
securitiesDecorator.classList.add('sgx-base-text-caption');

document.body.appendChild(securitiesDecorator);
