import { withInitDOM } from '@sgx/sgx-base-code';
import DateService from '@sgx/sgx-date-time-service';
import { formatTimestamp } from 'utils/date-util';
import tmpl from './widget-dashboard-corporate-actions.html';
import SGXInViewport from 'sgx-in-viewport';
import { fromEvent } from 'rxjs';
import SgxAnalyticsService from 'sgx-analytics-service';
import { get } from 'lodash';

class WidgetDashboardCoporateActions extends SGXInViewport {
  constructor() {
    super();
    this._onViewClick = this._onViewClick.bind(this);
  }

  _sendGAEvent(event) {
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    SgxAnalyticsService.sendEvent(
      eventCategory,
      'Corporate Actions Form',
      'widget-viewed',
    );
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._timestamp = this.querySelector('.widget-dashboard-corporate-actions-timestamp');
    this._viewLink = this.querySelector('.widget-dashboard-corporate-actions-link');
    this._dialog = this.querySelector('widget-dashboard-corporate-actions-dialog');
    this._list = this.querySelector('.widget-dashboard-corporate-actions-list');
    this._list.setConfig();
    this._list.setData();
    const time = new DateService();
    this._timestamp.textContent = formatTimestamp(time);
    super.initDOM();
  }

  connectedCallback() {
    this._viewLink.addEventListener('click', this._onViewClick);
    fromEvent(this, 'onViewPortEnter').subscribe(event => this._sendGAEvent(event));
  }

  disconnectedCallback() {
    this._viewLink.removeEventListener('click', this._onViewClick);
  }

  _onViewClick(e) {
    e.preventDefault();
    this._dialog.showDialog();
  }
}

customElements.define('widget-dashboard-corporate-actions', withInitDOM(WidgetDashboardCoporateActions));
