import {withInitDOM} from 'sgx-base-code';
import tmpl from './template-dashboard-page.html';
import { isAuthenticated } from 'utils/auth-util';
import DeviceService from '@sgx/sgx-device-service';

/**
 * Dashboard page template.
 */
class TemplateDashboardPage extends HTMLElement {

  connectedCallback() {
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-dashboard-page');
  }

  setData(data) {
    this._dashboard = this.querySelector('.dashboard');
    const layout = isAuthenticated() ? this._postLoginLayout() : this._preLoginLayoutConfig();
    this._dashboard.setConfig({
      widgetBaseCls: 'sgx-card',
      rows: layout
    });
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }

  _preLoginLayoutConfig() {
    return [{
      columns: [{
        widgets: [{
          element: 'widget-alerts'
        }]
      }]
    },
      {
        columns: [
          {widgets: [{element: 'widget-dashboard-carousel', classNames: []}], size: 8},
          {widgets: [{element: 'widget-portfolio-account'}, {element: 'widget-stock-quick-search'}], size: 4}
        ]
      },
      {
        columns: [
          {widgets: [{element: 'widget-dashboard-corporate-actions'}]}
        ]
      },
      {
        columns: [
          {
            widgets: [
              {element: 'widget-index-single', attributes: {'data-layout': 'layout2'}, classNames: ['flex-1', 'sgx-card']},
              {element: 'widget-securities-market-performance', attributes: {'data-store': 'dashboardToday'}, classNames: ['flex-2', 'sgx-card']}
            ],
            size: 4,
            classNames: ['d-flex', 'flex-column']
          },
          {
            widgets: [
              {element: 'widget-list-row-tiles-wrapper', attributes: {'data-store': 'companyAnnouncement'}, classNames: ['flex-1', 'sgx-card']},
              {element: 'widget-list-row-tiles-wrapper', attributes: {'data-store': 'marketUpdates'}, classNames: ['flex-1', 'sgx-card']}
            ],
            size: 8,
            classNames: ['d-flex', 'flex-column']
          }
        ]
      },
      {
        columns: [
          {widgets: [{element: 'widget-dashboard-quick-links', attributes: {'data-store': 'accountOpening', 'type': 'preLogin'}}], classNames: 'dashboard-quick-links-row--container'},
          {widgets: [{element: 'widget-dashboard-quick-links', attributes: {'data-store': 'cdpServices', 'type': 'preLogin'}}], classNames: 'dashboard-quick-links-row--container'},
          {widgets: [{element: 'widget-dashboard-quick-links', attributes: {'data-store': 'resources', 'type': 'preLogin'}}], classNames: 'dashboard-quick-links-row--container'}
        ],
        classNames: 'dashboard-quick-links-row'
      }
    ]
  }

  _postLoginLayout() {
    // Social link repositioning for desktop and laptop based on UX design - Start

    const socialLinks = {element: 'widget-social-links', attributes: {'data-store': 'socialLinks'}};

    const socialLinkDesktopView = (DeviceService.isMobile()) ? {} : socialLinks;
    const socialLinkMobileView = (DeviceService.isMobile()) ? {
      columns: [
        {widgets: [socialLinks],
        size: 5}
      ]
    } : {};

    // Social link repositioning for desktop and laptop based on UX design - End

    return [{
      columns: [{
        widgets: [{
          element: 'widget-alerts'
        }]
      }]
    },
      {
        columns: [{
          widgets: [{element: 'widget-portfolio-account'}]
        }]
      },
      {
        columns: [
          {widgets: [{element: 'widget-dashboard-corporate-actions'}]}
        ]
      },
      {
        columns: [
          {widgets: [{element: 'widget-dashboard-carousel', classNames: []}], size: 8},
          {widgets: [{element: 'widget-stock-quick-search'},{element: 'widget-index-single', attributes: {'data-layout': 'layout2'}}], size: 4}
        ]
      },
      {
        columns: [
          {
            widgets: [
              {element: 'widget-securities-market-performance', attributes: {'data-store': 'dashboardToday'}, classNames: ['sgx-card', 'flex-1']},
              socialLinkDesktopView
            ],
            size: 4,
            classNames: ['d-flex', 'flex-column']
          },
          {
            widgets: [
              {element: 'widget-list-row-tiles-wrapper', attributes: {'data-store': 'companyAnnouncement'}},
              {element: 'widget-list-row-tiles-wrapper', attributes: {'data-store': 'marketUpdates'}}
            ],
            size: 8
          }
        ]
      },
      {
        columns: [
          {
            widgets: [
              {element: 'widget-dashboard-quick-links', attributes: {'data-store': 'cdpServices', 'type': 'postLogin'}}
            ],
            size: 6,
            classNames: 'dashboard-quick-links-row--container'
          },
          {
            widgets: [{
              element: 'widget-dashboard-quick-links', attributes: {'data-store': 'resources', 'type': 'postLogin'}}
            ],
            size: 6,
            classNames: 'dashboard-quick-links-row--container'
          }
        ],
        classNames: 'dashboard-quick-links-row'
      },
      socialLinkMobileView
    ]
  }
}

customElements.define('template-dashboard-page', withInitDOM(TemplateDashboardPage));
