import saveAs from '@sgx/sgx-base-code/src/utils/saveAs';
import { FetchUtils } from 'sgx-base-code';

/**
 * Creates link element to download the pdf
 *
 * @param  {string} base64 base64 string
 * @param  {string} name name of the file
 */
function download(base64, name) {
  const filename = `${name}.pdf`;
  // fix for IE
  if (navigator.msSaveBlob) {
    const blob = base64toBlob(base64);
    return saveAs(blob, filename);
  }

  FetchUtils.fetch(`data:application/pdf;base64,${base64}`)
    .then(response => response.blob())
    .then(blob => saveAs(blob, filename))
    .catch(_ => console.error('Unable to download'));
}

const base64toBlob = (base64, contentType = 'application/pdf', sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, {type: contentType});
}

module.exports = {
  download
};
