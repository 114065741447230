import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-onboarding-guide.html';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';

class ResourcesWidget extends HTMLElement {
  constructor() {
    super();
    this._lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-onboarding-guide');
    this.classList.add('sgx-card');
    this._tiles = Array.from(this.querySelector('.widget-onboarding-guide-tiles').children);
    this._footerTitle = this.querySelector('.widget-onboarding-guide-footer-title');
    this._footerNoteMsg = this.querySelector('.widget-onboarding-guide-footer-note-msg');
  }

  connectedCallback() {
    this._setTiles();
    this._setFooterData();
  }

  _setTiles() {
    this._tiles.forEach((tile, index) => {
      const ref = tile.getAttribute('ref');
      const url = ConfigService.links[ref.toUpperCase().replace(/-/g, '_')];
      tile.setData({
        link: url[this._lang] ? url[this._lang] : url,
        image: `${ref}.jpg`,
        text: i18n.getTranslation('app.widget-onboarding-guide.cards', { returnObjects: true })[index].label
      });
    });
  }

  _setFooterData() {
    this._footerTitle.innerHTML = i18n.getTranslation('app.widget-onboarding-guide.footer.title_html', {
      link: ConfigService.links.SURVEY_LINK
    });
  }

}

customElements.define('widget-onboarding-guide', withInitDOM(ResourcesWidget));
