import i18n from 'sgx-localisation-service';
import ConfigService from 'sgx-config-service';

/**
 * A simple header template element
 * @module template-header-simple
 * @type {HTMLElement}
 */
class TemplateHeaderSimple extends HTMLElement {
  //#region Custom Element API

  constructor() {
    super();
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  attributeChangedCallback() {
  }

  //#endregion

  //#region Public Methods

  setData(data) {
    const aboutLink = ConfigService.links.INVESTORS_PORTAL_ABOUT;
    this.innerHTML = `
      <div class="page-header${data.pageData.isDashboardPage ? ' page-header-dashboard' : ''}"><h1 class="page-title">${i18n.getTranslation(data.pageTitle)}</h1></div>
      <cmp-sub-header></cmp-sub-header>
    `;
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

//#endregion

//#region Private Methods


customElements.define('template-header-simple', TemplateHeaderSimple);
//#endregion
