import { withInitDOM } from '@sgx/sgx-base-code';
import ConfigService from '@sgx/sgx-config-service';
import DateService from '@sgx/sgx-date-time-service';
import DeviceService from '@sgx/sgx-device-service';
import i18n from '@sgx/sgx-localisation-service';
import CorporateActionsService from 'services/corporate-actions-service';
import tmpl from './widget-corporate-actions-list.html';
import { defaultTableConfig, dialogTableConfig, constants } from './widget-corporate-actions-list-config';
import StoreRegistry from 'stores/store-registry';
import {isAuthenticated} from 'utils/auth-util';
import { fromEvent } from 'rxjs';
import sgxAnalyticsService from '@sgx/sgx-analytics-service';
import { get } from 'lodash';

const {
  FULL_DATE_FORMAT,
  MINIMUM_ROW_VISIBLE_SIZE,
  DEFAULT_TABLE_HEIGHT,
  TABLE_ROW_HEIGHT,
  TABLE_TOOLBAR_AND_HEADER_HEIGHT,
  CURRENT_DATE_TIME_FORMAT
} = constants;

class WidgetCorporateActionsList extends HTMLElement {

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._table = this.querySelector('sgx-table');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._title = this.querySelector('.widget-corporate-actions-list-title');
    this._alertBar = this.querySelector('.widget-corporate-actions-alert');
    this._alertBar.setData({
      status: 'informational',
      text: i18n.getTranslation('app.widget-corporate-actions-list.schedule_html'),
      background: false
    });
    this._alertBar2 = this.querySelector('.widget-corporate-actions-alert2');
    this._rightsDesc = this.querySelector('.widget-corporate-actions-list-rights-desc');

    //Note: for now the important note needs to be hidden, but might come back with a new text in future
    // this.setCAStandingInstructionNotes();

  }

  setCAStandingInstructionNotes() {
    const lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');
    const link = ConfigService.links.CDP_GETTING_STARTED[lang];
    this._alertBar2.innerHTML = i18n.getTranslation('app.widget-corporate-actions-list.note_html', {
      link
    })
  }

  static get observedAttributes() {
    return ['header-i18n', 'fulldetails'];
  }

  attributeChangedCallback(attr, oldV, newV) {
    if (oldV === newV) {
      return;
    }

    switch (attr) {
      case 'header-i18n':
        this._setTitle(newV);
        break;
    }
  }

  /**
   *
   * @param {Object} config
   * @param {string} [config.context="default"] the context in which the list is shown (dialog or default)
   */
  setConfig({ context = 'default' } = {}) {
    const config = context === 'dialog' ? dialogTableConfig : defaultTableConfig;

    config.columns.securityName.cell.newTab = !isAuthenticated();
    this._table.setConfig(config);
  }

  setData() {
    this._rightsDesc.innerHTML = i18n.getTranslation('app.widget-corporate-actions-list.rightsDescription')
    this._indicator.show({ status: 'loading' });
    CorporateActionsService.status()
      .then(pts => this._getCorporateList(pts.timestamp))
      .then(() => this._indicator.hide())
      .catch(_ => this._setNoDataMessage() && this._indicator.show({
        status: 'error',
        title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
        description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
      }));
  }

  get _fulldetails() {
    return this.hasAttribute('fulldetails');
  }

  _setTitle(path) {
    if (!path) {
      return;
    }
    this._title.textContent = i18n.getTranslation(path);
  }

  _sendGAEvent(event) {
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;

    const securityName = get(event, 'target.textContent');
    if (securityName) {
      sgxAnalyticsService.sendEvent(eventCategory,
        `Corporate Actions:${securityName}`,
        'click'
      );
    }
  }

  _bindEventsForGA() {
    // TODO: Verify if this is going to be a costly event
    this._securityNames = this.querySelectorAll('[data-column-id="securityName"]');
    this._securityNames && fromEvent(this._securityNames, 'click').subscribe(event => this._sendGAEvent(event));
  }

  _getCorporateList(timestamp) {
    this._currentTimestamp = timestamp;
    CorporateActionsService.getCorporateActionsList()
      .then(data => {
        data = this._mapSecurityNameToLink(data);

        if(DeviceService.isDesktop()) {
          this._table.style.height = data && data.length > 5 ? `${(data.length * TABLE_ROW_HEIGHT) + TABLE_TOOLBAR_AND_HEADER_HEIGHT}px` : DEFAULT_TABLE_HEIGHT;
        }

        this._table.setData(data);
        this._table.recalculateSize();

        if (!data.length) {
          this._setNoDataMessage();
        }
        this._bindEventsForGA();
      });
  }

  _setNoDataMessage() {
    setTimeout(() => {
      this._table.showMessage(i18n.getTranslation('app.widget-corporate-actions-list.no-data-message'));
    }, 0);
  }

  _mapSecurityNameToLink(data) {
    const events = (data && data.length) ? data : [];
    const corporateList = events.sort((a, b) => {
      // sort by electionEnd in asc order
      const timestamp1 = DateService(a.electionEnd).format('X');
      const timestamp2 = DateService(b.electionEnd).format('X');
      return timestamp1 - timestamp2;
    })
    .reduce((accumulator, item, index) => {
      const { name, mediaName } = item;
      const timezone = ConfigService.env.timezone;
      // convert the current date time to a formatted datetime before converting it to timestamp to prevent the issue on the offset
      const currentTimeFormatted = DateService(this._currentTimestamp).tz(timezone).lang('en').format(CURRENT_DATE_TIME_FORMAT);
      const currentTimestamp = DateService(currentTimeFormatted).format('X');
      const electionEnd = DateService(item.electionEnd);
      const electionStart = DateService(item.electionStart);

      if (!(currentTimestamp > electionStart.format('X') && currentTimestamp < electionEnd.format('X'))) {
        return accumulator;
      }
      item.id = index;
      item.securityName = {
        label: name,
        link: `${ConfigService.links.CORPORATE_ACTIONS_LINK}/${mediaName}`
      };
      item.electionEnd = electionEnd.lang('en').format(FULL_DATE_FORMAT);
      item.electionStart = electionStart.lang('en').format(FULL_DATE_FORMAT);
      accumulator.push(item);
      return accumulator;
    }, []);

    return this._fulldetails ? corporateList : corporateList.slice(0, MINIMUM_ROW_VISIBLE_SIZE);
  }

  recalculateSize() {
    this._table.recalculateSize();
  }
}

customElements.define('widget-corporate-actions-list', withInitDOM(WidgetCorporateActionsList));
