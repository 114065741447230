import i18n from 'sgx-localisation-service';

export default {
  config: {
    type: 'content',
    editable: false,
  },
  state: {
    activeStateId: 0, // the index of the active tab (relative to the states array)
    states: [
      {
        label: i18n.getTranslation('app.security-details.tabs.overview'),
        data: 'widget-security-details-overview'
      },
      {
        label: i18n.getTranslation('app.security-details.tabs.information'),
        data: 'widget-security-details-information'
      },
      {
        label: i18n.getTranslation('app.security-details.tabs.announcements'),
        data: 'widget-security-details-announcements'
      },
      {
        label: i18n.getTranslation('app.security-details.tabs.market'),
        data: 'widget-security-details-market'
      }
    ]
  }
};
