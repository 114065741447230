import { withInitDOM } from 'sgx-base-code';
import DeviceService from 'sgx-device-service';
import dialogTemplate from './cmp-portfolio-unit-cost-dialog-template';

const DIALOG_ID = 'cmp-portfolio-unit-cost-dialog';

class CmpPortfolioUnitCostDialog extends HTMLElement {
  // #region - Custom Element API
  constructor() {
    super();

    this._onUnitCostChange = this._onUnitCostChange.bind(this);
    this._onUnitCostSubmit = this._onUnitCostSubmit.bind(this);
    this._afterShowDialog = this._afterShowDialog.bind(this);
  }

  initDOM() {
    this._makeDialogIfNeeded();
  }

  connectedCallback() {}

  // #endregion

  // #region - Public Function
  showDialog({title, data}) {
    const dialogConfig = {
      title,
      data,
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      afterShow: this._afterShowDialog
    };
    this._initialUnitCost = data;

    return this._dialog.show(dialogConfig)
      .then(() => this._dialogBody.getData())
      .catch(e => {
        this._setListeners(false);
        return Promise.reject(e);
      });
  }

  // #endregion


  // #region - Private Function
  _makeDialogIfNeeded() {
    const template = dialogTemplate(DIALOG_ID);
    this._dialog = document.getElementById(DIALOG_ID);

    if (this._dialog) {
      this._dialogBody = this._dialog.querySelector('[data-dialog="body"]');
      return this._dialog;
    }

    document.body.insertAdjacentHTML('beforeend', template);

    this._dialog = document.getElementById(DIALOG_ID);
    this._dialogBody = this._dialog.querySelector('[data-dialog="body"]');
    return this._dialog;
  }

  _afterShowDialog() {
    this._btnCalculate = this._dialog.querySelector('[data-dialog="ok"]');
    this._unitCost = this._dialog.querySelector('cmp-portfolio-unit-cost');
    this._btnCalculate.disabled = true;
    this._setListeners(true);
  }

  _setListeners(enable) {
    if (!this._unitCost) {
      return;
    }
    const listener = enable ? 'addEventListener' : 'removeEventListener';

    this._unitCost[listener]('unit-cost-change', this._onUnitCostChange);
    this._unitCost[listener]('unit-cost-submit', this._onUnitCostSubmit);
  }

  _onUnitCostChange(event) {
    const { unitCost, valid } = event.detail;
    this._btnCalculate.disabled = this._initialUnitCost == unitCost || !valid;
    if (unitCost === '' && this._initialUnitCost === 0) {
      this._btnCalculate.disabled = false;
    }
  }

  _onUnitCostSubmit(_) {
    if (!this._btnCalculate.disabled) {
      this._dialog.hide(this._dialogBody.getData());
    }
  }
}

customElements.define('cmp-portfolio-unit-cost-dialog', withInitDOM(CmpPortfolioUnitCostDialog));
