import Configuration from 'sgx-config-service';
import { FetchUtils } from 'sgx-base-code';
import get from 'lodash/get';

/**
 * CDP alerts related service.
 * CDP system alerts currently reside in the CMS.
 */
class CdpAlertsService {
  /**
   * Constructor initializing the configuration.
   */
  constructor() {
    this._cdpAlertsConfig = Configuration.env.api['cdp-alerts'];
  }
  /**
   * Gets the system alerts for CDP.
   *
   * @param {String} lang either en or zn
   * @return {Promise} a promise
   */
  getSystemAlerts(lang) {
    let targetedLang = this._cdpAlertsConfig['lang-en'];
    if (lang) {
      targetedLang = this._cdpAlertsConfig[`lang-${lang.toLowerCase().slice(0, 2)}`];
    }
    const cdpAlertsUrl = this._cdpAlertsConfig.tpl.replace(this._cdpAlertsConfig['lang-placeholder'], targetedLang);
    return FetchUtils.fetchJSON(cdpAlertsUrl, {
      mode: 'cors',
      cache: 'default'
    }).then(response => {
      const alerts = get(response, 'data.route.data.data.alerts.list') || [];
      return alerts.filter(x => x);
    });
  }

  /**
   * Gets the utils shared instance.
   * @return {CdpAlertsService} the utils shared instance
   */
  static get instance() {
    if (!CdpAlertsService._instance) {
      CdpAlertsService._instance = new CdpAlertsService();
    }
    return CdpAlertsService._instance;
  }
}

export default CdpAlertsService.instance;
