const template = (id) => `
  <sgx-dialog id="${id}" role="dialog">
    <h2 data-dialog="title"></h2>
    <cmp-portfolio-transfer-qty data-dialog="body"></cmp-portfolio-transfer-qty>
    <button data-i18n="cmp-portfolio-transfer-qty-dialog.button.confirm" class="sgx-button--primary sgx-button--small" data-dialog="ok" disabled></button>
    <button data-i18n="cmp-portfolio-transfer-qty-dialog.button.cancel" class="sgx-button--link sgx-button--small" data-dialog="cancel"></button>
  </sgx-dialog>
`;

export default template;
