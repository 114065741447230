import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-equity-single.html';
import DateService from '@sgx/sgx-date-time-service';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';
import SecuritiesService from 'sgx-securities-service';
import IndicesService from 'sgx-indices-service';
import DateUtil from 'utils/date-util';
import INDICES_CHART_CONFIG from './indices-chart-config';
import SECURITIES_CHART_CONFIG from './securities-chart-config';

export default class WidgetEquitySingle extends HTMLElement {
  //#region Custom Element API
  // TODO: Hide caption and header respectively until prices are retrieved
  // TODO add historic chart configs when required
  constructor() {
    super();
    this._pidPrice = null;
    this._priceSubscription = null;
    this._chartSubscription = null;
    this.renderChart = this.renderChart.bind(this);
    this.updatePrice = this.updatePrice.bind(this);
    this.updatePriceSecurities = this.updatePriceSecurities.bind(this);
    this.setData = this.setData.bind(this);
    this.parseChartData = this.parseChartData.bind(this);
    this.renderChartSecurities = this.renderChartSecurities.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._todayTitle = this.querySelector('.widget-equity-single-title');
  }

  connectedCallback() {
    this._isConnected = true;
    this._subscriptions = [];
    if (!this.dataset.store) {
      console.warn('widget equity single widget dataset store attribute not set');
    }

    // TODO Caption and href is temporarily hardcoded, query an external API to get back the caption and link
    this._store = StoreRegistry[this.dataset.store];
    let id = this._store.getData().id;
    if (!id) {
      const data = this._store.getData();
      data.id = '.STI';
      this._store.setData(data);
      this._store.saveData();
      id = '.STI';
    }
    const category = 'indices';
    const lang = i18n.getLanguage().replace(/-/g, "_");
    const caption = id.replace('.', '!'); // translation service '.' is reserved character
    const href = ConfigService.links.SGX_V2_INDICES_PRODUCTS[lang] + id.replace('.', '').toLowerCase(); // links mapping
    const title = i18n.getTranslation('app.widget-equity-single.title'); // TODO refactor with local storage when required
    this._todayTitle.innerHTML = `${title}<span class="widget-equity-single-title-date sgx-base-text-small">${new DateService().format(i18n.getTranslation('app.shared-text.date-format-full-date'))}</span>`;

    this.setData({
      // id: 'D05', // e.g. D05,.STI
      id: id,
      type: 'intraday',
      // category: 'stocks', // e.g. stocks, indices
      category: category, // e.g. stocks, indices
      caption: i18n.getTranslation(`app.widget-equity-single.caption.${caption}`),
      link: {href}
    });
  }

  disconnectedCallback() {
    this._isConnected = false;
    (this._subscriptions || []).map(sub => sub.unsubscribe());

    if (this._category === 'indices') {
      if (this._chartSubscription) {
        IndicesService.unsubscribeChart(this._chartSubscription);
        this._chartSubscription = null;
      }
      IndicesService.unsubscribePriceData(this._priceSubscription);
      this._priceSubscription = null;
    } else { // alternatively use a common name as an abstraction
      if (this._chartSubscription) {
        SecuritiesService.unsubscribeChart(this._chartSubscription);
        this._chartSubscription = null;
      }
      SecuritiesService.unsubscribeFromPrices(this._priceSubscription);
      this._priceSubscription = null;
    }
    this.chart = null;
  }

  //#endregion

  //#region Public Methods

  setData(data) {
    this._pidPrice = data.id;
    this._chartType = data.type;
    this._category = data.category;
    var captionText = data.caption;
    var href = data.link && data.link.href;
    this._headerContainer = this.querySelector('.widget-equity-single-header'); // TODO hide first, show on render
    this._headerTitle = this.querySelector('.widget-equity-single-header-title'); // counter name
    this._headerValue = this.querySelector('.widget-equity-single-header-value');  // absolute price
    this._subHeaderTitle = this.querySelector('.widget-equity-single-subheader-title'); // product code
    this._subHeaderValue = this.querySelector('.widget-equity-single-subheader-value'); // _changePrice
    this._caption = this.querySelector('.widget-equity-single-caption'); // TODO hide first, show on render
    this._container = this.querySelector('.widget-equity-single-chart-container');
    this._chart = this.querySelector('.widget-equity-single-chart');
    this._footer = this.querySelector('.widget-equity-single-footer');
    this._timestamp = this.querySelector('.widget-equity-single-timestamp');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._link = this.querySelector('.widget-equity-single-link');

    if (href) {
      this._link.textContent = i18n.getTranslation('app.shared-text.view-details');
      this._link.href = href;
      this._link.target = '_blank';
    }
    if (captionText) {
      this._caption.textContent = captionText;
    }
    if (this._category === 'indices') {
      this._renderIndicesEquity();
    } else {
      this._renderSecuritiesEquity();
    }
    this._chart.style.display = 'none';
    this._indicator.show({description: i18n.getTranslation('app.shared-text.status-indicator.loading.description')});
  }

  //#endregion

  //#region Private Methods
  /** ***************************** Private Methods ******************************/
  _renderIndicesEquity() {
    this._priceSubscription = IndicesService.subscribePriceData(
      this._pidPrice,
      this.updatePrice
    );
    if (this._chartType === 'intraday') {
      this._chartSubscription = IndicesService.subscribeChart(
        this.renderChart, this._pidPrice, this._chartType, 5, 'd'
      );
    } else {
      this._chartSubscription = null;
      IndicesService.getChartData(this._pidPrice, this._chartType, 1, 'w')
        .then(this.renderChart);
    }
  }

  _renderSecuritiesEquity() {
    this._priceSubscription = SecuritiesService.subscribeToPrices(
      this.updatePriceSecurities,
      {
        code: this._pidPrice,
        category: this._category
      }
    );
    // id = D05
    // idType =  code
    // category = stocks
    // chartType = intraday
    // period = 1d
    if (this._chartType === 'intraday') {
      this._chartSubscription = SecuritiesService.subscribeChart(
        this.parseChartData,
        this._pidPrice, // id
        'code', // idType
        this._category, // category
        this._chartType, // chartType historic
        '1d', // period
        {
          params: 'trading_time,vl,lt'
        }
      ); // chartType
      //   this._chartSubscription = IndicesService.subscribeChart(
      //     this.renderChart, this._pidPrice, this._chartType, 5, 'd'
      //   );
    }
    // else {
    //   this._chartSubscription = null;
    //   IndicesService.getChartData(this._pidPrice, this._chartType, 1, 'w')
    //     .then(this.renderChart);
    // }
  }

  /*
    @params priceData {
      nc // counter name code
      n // counter name
      c // change absolute
      p // % change
    }
  */
  updatePriceSecurities(data) {
    const priceData = data && data[this._pidPrice];
    if (!priceData) {
      console.warn('Invalid widgetEquitySingle Securities priceData object');
      return Promise.resolve({});
    }
    // Securities-Indices data mapping
    priceData.lp = priceData.lt; // map last traded to last price
    priceData.ptc = priceData.p; // map percentage change to percentage change
    priceData.pid = priceData.nc;// map counter code to product id
    return this.updatePrice(priceData);
  }

  /*
    @params object {
      lp // last price
      n // product name
      ptc // change in value from previous close to last price in %
      c // change in value absolute
      pid // product ID
    }
  */
  updatePrice(priceData) {
    if (!priceData || !priceData.n) {
      console.warn('Invalid widgetEquitySingle Indices priceData object');
      return Promise.resolve({});
    }

    this._headerTitle.textContent = priceData.n;
    this._headerValue.textContent = priceData.lp ? priceData.lp.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A';
    // if .STI becomes STI jira - INVESTORS-257
    this._subHeaderTitle.textContent = priceData.pid.indexOf('.') === 0 ? priceData.pid.substring(1) : priceData.pid;
    if (!priceData.c || !priceData.ptc) {
      this._subHeaderValue.textContent = ''; // handle missing price change data here
    } else if (priceData.c >= 0) {
      this._subHeaderValue.textContent = '+' + priceData.c.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' (+' + priceData.ptc + '%)';
      this._subHeaderValue.classList.add('widget-single-equity-price--up');
      this._headerValue.classList.add('widget-single-equity-price--up');
    } else {
      this._subHeaderValue.textContent = priceData.c.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' (' + priceData.ptc + '%)';
      this._subHeaderValue.classList.remove('widget-single-equity-price--up');
      this._headerValue.classList.remove('widget-single-equity-price--up');
    }
    this._timestamp.textContent = DateUtil.formatApiDate(priceData.trading_time);

    return Promise.resolve(priceData);
  }


  parseChartData(data) {
    return Promise.resolve(data.data.reduce(function (result, item) {
      var timestamp = DateService.utc(item.trading_time, 'YYYYMMDD_HHmmss');
      timestamp = timestamp.add(DateService().utcOffset(), 'm').valueOf();
      result.volumes.push([timestamp, item.vl]);
      result.prices.push([timestamp, item.lt]);
      return result;
    }, {
      volumes: [],
      prices: []
    }))
      .then(parsedData => {
        var seriesKeys = ['volumes', 'prices'];
        this.renderChartSecurities(SECURITIES_CHART_CONFIG, this._chart, parsedData, seriesKeys);
        // this._chart.style.display = 'block';
        this._indicator.hide();
      })
      .catch(_ => this._indicator.show({
        status: 'error',
        title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
        description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
      }));
  }

  renderChartSecurities(chartConfig, chart, data, seriesKeys) {
    chart.style.display = 'block';
    var seriesLength = seriesKeys.length;
    var hasData = false;
    var i;

    // Check that data object exists and each series we are looking for has data in it
    if (data) {
      var hasDataCounter = 0;
      for (i = 0; i < seriesKeys.length; i++) {
        var series = data[seriesKeys[i]];
        if (series && series.length) {
          hasDataCounter++;
        }
      }
      hasData = seriesLength === hasDataCounter;
    }

    chartConfig.tooltip.dateTimeLabelFormats = i18n.getTranslation('app.charts.intraday-tooltip-date-formats', {returnObjects: true});
    // If all series have data then update the config with them and render the chart
    if (hasData) {
      for (i = 0; i < seriesLength; i++) {
        chartConfig.series[i].data = data[seriesKeys[i]];
      }
      chart.setData(chartConfig);
      chart.getChartInstance().reflow();
    } else {
      // Clear the chart config and show a no data message if not enough data was found
      for (i = 0; i < seriesLength; i++) {
        chartConfig.series[i].data = [];
      }
      chart.setData(chartConfig);
      chart.getChartInstance().reflow();
      chart.getChartInstance().showLoading(i18n.getTranslation('app.message.no-data'));
    }
    this._indicator.hide();
  }


  renderChart(response) {
    if (!this._isConnected) return;
    let data = response && response.data;
    let config = INDICES_CHART_CONFIG;
    config.chart.height = null;
    config.tooltip.dateTimeLabelFormats = i18n.getTranslation('app.charts.' + this._chartType + '-tooltip-date-formats', {returnObjects: true});

    if (data && data.length) {
      config.series[0].data = data;
      config.series[0].name = this._pidPrice;
      this._chart.style.display = 'block';
      this._chart.setData(config);
      this._chart.getChartInstance().reflow();
    } else {
      this._chart.setData(config);
      this._chart.getChartInstance().showLoading(i18n.getTranslation('app.message.no-data'));
    }
    this._indicator.hide();
  }
}

customElements.define('widget-equity-single', withInitDOM(WidgetEquitySingle));
