module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-settings-user-signatory\">\n  <fieldset class=\"signatory-fieldset\">\n    <h3 class=\"details-title\" data-i18n=\"app.widget-settings-user-signatory.title\"></h3>\n    <sgx-input-text name=\"signatoryName\" class=\"fieldset-input details-name\" readonly hint-type=\"inline\" data-i18n=\"[label]app.widget-settings-user-signatory.inputs.name.label\"></sgx-input-text>\n    <sgx-input-phone name=\"signatoryMobilePhone\" class=\"fieldset-input details-mobilePhone\" readonly hint-type=\"inline\" data-i18n=\"[label]app.widget-settings-user-signatory.inputs.mobilePhone.label;[hint]app.widget-settings-user-signatory.inputs.mobilePhone.hint\"></sgx-input-phone>\n  </fieldset>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()