import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';

let instance = null;

/**
 * A home for various toggles that switch features on and off.
 */
class FeatureToggles {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Checks whether the webview context is active.
   * @returns {Promise} a promise with the value of the flag
   */
  isInWebview() {
    return new Promise ((resolve, reject) => {
      const flag = StoreRegistry.appContext.getData('webview');
      typeof flag === 'boolean' ? resolve(flag) : reject();
    });
  }

  /**
   * Checks whether the psn feature is enabled.
   * @returns {Promise} a promise with the value of the flag
   */
  isPSNEnabled() {
    return new Promise ((resolve, reject) => {
      const flag = ConfigService.features ? ConfigService.features.psn : null;
      typeof flag === 'boolean' ? resolve(flag) : reject(new Error('Could not get the PSN flag.'));
    });
  }

  /**
   * Checks whether the audit log feature is enabled.
   * @returns {Promise} a promise with the value of the flag
   */
  isAuditLogEnabled() {
    return new Promise ((resolve, reject) => {
      const flag = ConfigService.features ? ConfigService.features.auditLog : null;
      typeof flag === 'boolean' ? resolve(flag) : reject(new Error('Could not get the audit log flag.'));
    });
  }

  /**
   * Checks whether the BLB feature is enabled.
   * @returns {Promise} a promise with the value of the flag
   */
  isBlbEnabled() {
    return new Promise ((resolve, reject) => {
      const flag = ConfigService.features ? ConfigService.features.blb : null;
      typeof flag === 'boolean' ? resolve(flag) : reject(new Error('Could not get the BLB flag.'));
    });
  }

  /**
   * Checks whether the Register Fin feature is enabled.
   * @returns {Promise} a promise with the value of the flag
   */
  isRegisterFinEnabled() {
    return new Promise ((resolve, reject) => {
      const flag = ConfigService.features ? ConfigService.features.registerFin : null;
      typeof flag === 'boolean' ? resolve(flag) : reject(new Error('Could not get the Register Fin flag.'));
    });
  }

  /**
   * Checks whether Google Analytics is enabled.
   * @returns {Promise} a promise with the value of the flag
   */
  isGoogleAnalyticsEnabled() {
    return new Promise ((resolve, reject) => {
      const flag = ConfigService.features ? ConfigService.features.analytics : null;
      typeof flag === 'boolean' ? resolve(flag) : reject(new Error('Could not get the Google Analytics flag.'));
    });
  }

  /**
   * Checks whether the CDP feature is enabled.
   * @returns {Promise} a promise with the value of the flag
   */
  isCdpEnabled() {
    return new Promise ((resolve, reject) => {
      const flag = ConfigService.features ? ConfigService.features.cdp : null;
      typeof flag === 'boolean' ? resolve(flag) : reject(new Error('Could not get the CDP feature flag.'));
    });
  }

  /**
   * Checks whether the Stockfacts feature is enabled.
   * @returns {Promise} a promise with the value of the flag
   */
  isStockfactsEnabled() {
    return new Promise ((resolve, reject) => {
      const flag = ConfigService.features ? ConfigService.features.stockfacts : null;
      typeof flag === 'boolean' ? resolve(flag) : reject(new Error('Could not get the Stockfacts feature flag.'));
    });
  }

  /**
    * Checks whether the ERights feature is enabled.
    * @returns {Boolean} a flag if feature is enabled
    */
  isERightsEnabled() {
    let flag = ConfigService.features ? ConfigService.features.eRights : null;

    if (typeof flag !== 'boolean') {
      console.error(new Error('Could not get the eRights feature flag.'));
      flag = false;
    }

    return flag;
  }

  /**
   * Checks whether the Stockfacts feature is enabled for a particular security.
   * @param {Object} security the security to check
   * @returns {Promise} a promise with the value of the flag
   */
  isStockfactsEnabledForSecurity(security) {
    return isStockfactsEnabled().then(enabled => {
      return enabled && (security.type === 'stocks' || security.type === 'reits' || security.type === 'businesstrusts');
    });
  }

  /**
   * Toggles features depending on the flags set in the configuration.
   * @param {Object} menuData Route data from menu.json'
   * @returns {Promise} Returns a promise returning modified menu data
   */
  toggleFeatures(menuData) {
    return Promise.all([
      this.isCdpEnabled().catch(error => { console.error(error) }),
      this.isStockfactsEnabled().catch(error => { console.error(error) }),
      this.isBlbEnabled().catch(error => { console.error(error) }),
      this.isAuditLogEnabled().catch(error => { console.error(error) }),
      this.isPSNEnabled().catch(error => { console.error(error) }),
      this.isRegisterFinEnabled().catch(error => { console.error(error) }),
      this.isInWebview().catch(() => {}) // Do not log error as the param may or may not be present
    ]).then(([cdp, stockfacts, blb, auditLog, psn, registerFin, webview]) => {
      let modifiedMenu = menuData;

      if (!cdp) { // Disable CDP menu items and specific widgets
        this._hideAuth(); // Authentication is currently a CDP internet feature only
        this._hideElements([
          'cmp-star-icon',
          'widget-portfolio-account',
          'widget-create-account',
          '.template-about-links-cdp',
          'a[href="./profile/application"]'
        ]);
        const cdpMenuItemRoutes = [
          './securities/favourites',
          './portfolio'
        ];
        const cdpGlobalItemRoutes = [
          './profile/user',
          './profile/account',
          './profile/security',
          './profile/notifications'
        ];
        modifiedMenu = this._hideRoutes({ menuData: modifiedMenu, menuItemRoutes: cdpMenuItemRoutes, globalItemRoutes: cdpGlobalItemRoutes });
      }

      if (!stockfacts) { // Disable Stockfacts menu items and specific widgets
        this._hideElements([
          'widget-stock-screener'
        ]);
        modifiedMenu = this._hideRoutes({ menuData: modifiedMenu, menuItemRoutes: ['./stock-screener'] });
      }

      if (!blb) { // Disable BLB menu items and specific widgets
        this._hideElements([
          '.widget-portfolio-overview-blb-select',
          'sgx-data-model-tool[label="Transfer Securities"]',
          'sgx-tabs.portfolio-tabs li[data-analytics-label="Transfer Securities"]',
          '.trading-account-row-blb'
        ]);
      }

      if (!registerFin) {
        this._hideElements([
          '.sgx-login-1fa-singpass-work-pass'
        ]);
      }

      if (!auditLog) { // Disable audit log table
        this._hideElements([
          '.widget-audit-log-table'
        ]);
      }

      if (!psn) { // Disable PSN feature
        this._hideElements([
          '.widget-notifications-email-settlement'
        ]);
      }

      if (webview) {
        document.body.setAttribute('webview', '');
      }

      return modifiedMenu;
    });
  }

  /**
   * Creates a globally applied style tag to hide all links to the login page.
   */
  _hideAuth() {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      a[href="/login.html"] {
        display: none !important;
      }

      .sgx-header-user-link:last-of-type {
        padding: 0 !important;
      }
    `;
    const firstScript = document.querySelector('script');
    document.head.insertBefore(styleElement, firstScript);
  }

  /**
   * Creates a globally applied style tag to hide a list of elements.
   * @param {Array} elements The elements to hide
   */
  _hideElements(elements) {
    const styleElement = document.createElement('style');
    let styles = '';

    elements.forEach(element => {
      styles += `${element} { display: none !important; } `;
    });

    styleElement.innerHTML = styles;
    const firstScript = document.querySelector('script');
    document.head.insertBefore(styleElement, firstScript);
  }

  /**
   * Hides targeted menu routes.
   * @param {Object} config A configuration object
   * @param {Object} config.menuData Menu data from menu.json
   * @param {Array} config.menuItemRoutes The target menu routes to hide
   * @param {Array} config.globalItemRoutes The global menu routes to hide
   * @returns {Object} A copy of menu data with features toggled
   */
  _hideRoutes({ menuData = {}, menuItemRoutes = [], globalItemRoutes = [] }) {

    const data = { ...menuData }; // Make a copy to alter and return

    _processMenu({ menuSection: 'MENU_ITEMS', targetRoutes: menuItemRoutes });
    _processMenu({ menuSection: 'GLOBAL_ITEMS', targetRoutes: globalItemRoutes });

    // Helper function to process menu items
    function _processMenu({ menuSection, targetRoutes }) {
      data[menuSection].forEach((menuItem, menuItemIndex) => {
        targetRoutes.forEach(targetRoute => {
          if (!!~targetRoute.indexOf(menuItem.link)) { // Match route paths

            const isSubLink = /\/[^\/]*\//.test(targetRoute); // Get sublink paths (e.g. contains two non-consecutive forward slashes)

            if (isSubLink) { // Process sublink paths
              menuItem.subLinks.forEach((subLink, subLinkIndex) => {
                if (subLink.link === targetRoute) {
                  data[menuSection][menuItemIndex].subLinks.splice(subLinkIndex, 1);
                }
              });
            } else { // Process non-sublink paths
              data[menuSection].splice(menuItemIndex, 1);
            }
          }
        });
      });
    }

    return data;
  }
}

export default new FeatureToggles();
