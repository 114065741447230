import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-investor-journey.html';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';

class WidgetInvestorJourney extends HTMLElement {
  constructor() {
    super();
    this._lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-investor-journey');
    this._contentTable = this.querySelector('.widget-investor-journey-content-table');
    this._step1Link = this.querySelector('.widget-investor-journey-step-1-link');
    this._step2Link = this.querySelector('.widget-investor-journey-step-2-section-3-link');
    this._step2List = this.querySelector('.widget-investor-journey-step-2-section-2-list').children;
    this._step3Link = this.querySelector('.widget-investor-journey-step-3-link');
  }

  connectedCallback() {
    this._setLinks();
    this._setList();
    this._scrollAccountOpeningInView();
  }

  _scrollAccountOpeningInView(){
    const {hash} = window.location;
    const elementId = '#create-cdp-account';
    if (hash === elementId) {
      requestAnimationFrame(_ => {
        this.querySelector(`${elementId}`).scrollIntoView()
      });
    }
  }

  _setLinks() {
    this._step1Link.href = ConfigService.links.SGX_V2_INVESTOR_BEGINNER_GUIDE[this._lang];
    this._step2Link.href = ConfigService.links.SGX_V2_RETAIL_BROKERS[this._lang];
    this._step3Link.href = ConfigService.links.ACADEMY;
  }

  _setList() {
    const locales = i18n.getTranslation('app.widget-investor-journey.step2.section2.items', { returnObjects: true });
    const rows = [
      {
        icon: 'user-alt',
        text: locales[0].label,
        button: {
          label: locales[0].link,
          url: ConfigService.links.ACCOUNT_OPENING,
          type: 'primary'
        },
        border: true
      },
      {
        icon: 'user-couple',
        text: locales[1].label,
        button: {
          label: locales[1].link,
          url: ConfigService.links.SGX_V2_CREATE_JOINT_ACCOUNT[this._lang],
          type: 'secondary'
        },
        border: true
      },
      {
        icon: 'user-biz',
        text: locales[2].label,
        button: {
          label: locales[2].link,
          url: ConfigService.links.SGX_V2_CREATE_CORPORATE_ACCOUNT[this._lang],
          type: 'secondary'
        }
      }
    ];
    rows.forEach((row, index) => {
      this._step2List[index].setData(row);
    });
  }
}

customElements.define('widget-investor-journey', withInitDOM(WidgetInvestorJourney));
