module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-portfolio-rights\">\n  <sgx-alert-bar class=\"w-portfolio-rights-header\"></sgx-alert-bar>\n  <section class=\"w-portfolio-rights-container\">\n    <sgx-table class=\"w-portfolio-rights-table\"></sgx-table>\n    <sgx-status-indicator class=\"w-portfolio-rights-loader\"></sgx-status-indicator>\n  </section>\n  <div class=\"w-portfolio-rights-footer\">\n    <p class=\"sgx-base-text-body-bold-16\" data-i18n=\"widget-portfolio-rights.notes.title\"></p>\n    <ul class=\"w-portfolio-rights-footer-options\">\n      <li data-i18n=\"widget-portfolio-rights.notes.note-1\"></li>\n      <li data-i18n=\"widget-portfolio-rights.notes.note-2\"></li>\n    </ul>\n  </div>\n  <widget-rights-dialog></widget-rights-dialog>\n  <widget-rights-payment-dialog></widget-rights-payment-dialog>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()