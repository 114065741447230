import { withInitDOM } from 'sgx-base-code';
import tmpl from './template-settings-security.html';
import i18n from 'sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import { toggleAuthenticatedFeature } from 'utils/auth-util';
import ConfigService from "@sgx/sgx-config-service/src/sgx-config-service";

class TemplateSettingsSecurity extends HTMLElement {
  constructor() {
    super();
    this._store = StoreRegistry.settingsAccount;
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-settings-security');
    this._container = this.querySelector('.container-fluid--full-width');
    this._loginPrompt = this.querySelector('cmp-login-prompt');
    this._loginPromptText = this.querySelector('.cmp-login-prompt-text');
  }

  connectedCallback() {
    toggleAuthenticatedFeature({
      prompt: this._loginPrompt,
      promptText: i18n.getTranslation('app.settings.login-prompt', {
        link: ConfigService.links.CDP_ACCOUNT_CREATE
      }),
      feature: this._container
    });
  }
}

customElements.define('template-settings-security', withInitDOM(TemplateSettingsSecurity));
