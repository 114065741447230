import i18n from 'sgx-localisation-service';

// TODO - Retrieve the categories from taxonomy terms company announcements API
const FILTER_CONFIG = {
  'hideOperator': true,
  'editable': false,
  'columns': {
    'name': {
      'label': i18n.getTranslation('app.company-announcements.filter-labels.name'),
      'type': 'text',
      'autocomplete': true
    },
    'securityCode': {
      'label': i18n.getTranslation('app.company-announcements.filter-labels.securityCode'),
      'type': 'text',
      'autocomplete': true
    },
    'broadcast_date_time': {
      'label': i18n.getTranslation('app.company-announcements.filter-labels.period'),
      'type': 'date',
      'selection': 'day',
      'format': 'DD/MM/YYYY'
    },
    'cat': {
      'label': i18n.getTranslation('app.company-announcements.filter-labels.category'),
      'type': 'select',
      'multiple': true,
      'autocomplete': false,
      'options': [
        {
          id: 'ANNC',
          value: 'ANNC',
          label: 'app.company-announcements.filter-options.announcements'
        },
        {
          id: 'CACT',
          value: 'CACT',
          label: 'app.company-announcements.filter-options.corporate-action'
        },
        {
          id: 'PLST',
          value: 'PLST',
          label: 'app.company-announcements.filter-options.product-listings'
        },
        {
          id: 'TRAD',
          value: 'TRAD',
          label: 'app.company-announcements.filter-options.trading-status'
        }
      ],
      'strictSelectAll': true
    }
  }
};

const FILTER_STATE = {
  'operator': 'and',
  'conditions': [
    {
      'columnId': 'name',
      'type': 'text',
      'method': 'contains',
      'value': null,
      'valid': false
    },
    {
      'columnId': 'securityCode',
      'type': 'text',
      'method': 'equals',
      'value': null,
      'valid': false
    },
    {
      'columnId': 'broadcast_date_time',
      'type': 'date',
      'method': 'between',
      'value': {
        'fromDate': null,
        'toDate': null
      },
      'valid': false
    },
    {
      'columnId': 'cat',
      'type': 'text',
      'method': 'equals',
      'value': null,
      'valid': false
    }
  ]
};

export {
  FILTER_CONFIG,
  FILTER_STATE
};
