import tmpl from './cmp-txn-signing.html';
import DeviceService from '@sgx/sgx-device-service';
import {withInitDOM} from 'sgx-base-code';
import StoreRegistry from 'stores/store-registry';
import UserService from 'services/user-service';

class CmpTxnSigning extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-txn-signing');
    this._dialog = document.querySelector('#cmp-txn-signing-dialog');
    this._singpassTab = this.querySelector('cmp-txn-signing-singpass');
    this._indicator = this.querySelector('.cmp-txn-signing-indicator');
    this._userType = UserService.constants.USER_TYPES;
  }

  connectedCallback() {}

  disconnectedCallback() {}

  /**
   * Data to set for transaction signing flows.
   * @param {Object} data
   * @param {Object} data.service the service to call to get JWT and hash code
   */
  setData({service, isSingPassRedirect} = {}) {
    this._singpassTab.setData({service, isSingPassRedirect});
    this._setShowSingpassTab();
  }

  /**
   * set the state of showSingpassTab, based on the required business logic
   * @link https://confluence.sgx.com/display/DIGITAL/SGX+Investors+-+Singpass+Integration#SGXInvestors-SingpassIntegration-SingPassTransactionSigning
   * */
  _setShowSingpassTab() {
    const { idType } = StoreRegistry.cdpSession.getData();
    const showSingpassTab = (idType == this._userType.SC_PR) || idType == this._userType.FOREIGNER;

    if (showSingpassTab) {
      this._singpassTab.classList.add('cmp-txn-signing-tab--active');
    }
  }

  showDialog() {
    this._dialog.footer.classList.remove('sgx-hidden');
    this._dialog.show({
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      actions: {
        cancel: () => this.teardown()
      }
    });
  }

  getIndicator() {
    return this._indicator;
  }

  hideDialog() {
    this._dialog.hide();
  }

  /**
   * Public interface to show/hide the internal status indicator with a specified type.
   * @param {Object} config the config to pass the status indicator
   */
  showIndicator(config) {
    this._indicator.show(config);
    this._dialog.footer.classList.add('sgx-hidden');
  }

  hideIndicator() {
    this._indicator.hide();
    this._dialog.footer.classList.remove('sgx-hidden');
  }

  resetSubflows() {
    this._singpassTab.reset();
  }

  /**
   * Resets the state completely.
   */
  teardown() {
    this.resetSubflows();
    this.hideIndicator();
    this.hideDialog();
  }
}

customElements.define('cmp-txn-signing', withInitDOM(CmpTxnSigning));
