import {withInitDOM} from 'sgx-base-code';
import tmpl from './widget-security-details-remarks.html';
import i18n from 'sgx-localisation-service';

export default class WidgetSecurityDetailsRemarks extends HTMLElement {

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._remarksSection = this.querySelector('.widget-security-details-remarks--section');
    this._remarksStatusSwl = this.querySelector('.widget-security-details-remarks--swl');
    this._remarksStatusRmk = this.querySelector('.widget-security-details-remarks--rmk');
    this._remarksStatusInfo = this.querySelector('.widget-security-details-remarks--info');
    this._remarksStatusRmkLabel = this.querySelector('.widget-security-details-remarks--rmk-desc');
    this._remarksStatusInfoLabel = this.querySelector('.widget-security-details-remarks--info-desc');
    this._remarksStatusInfoSP = this.querySelector('.widget-security-details-remarks-sp--info');
    this._remarksStatusInfoSPDesc = this.querySelector('.widget-security-details-remarks-sp--info-desc');
    this._remarksStatusInfoTT = this.querySelector('.widget-security-details-remarks-tt--info');
    this._remarksStatusInfoTTDesc = this.querySelector('.widget-security-details-remarks-tt--info-desc');
  }

  connectedCallback() {
  }

  disconnectedCallback() {

  }

  setData(data) {
    let noRemarks = true;
    if (data.swl) {
      this._remarksStatusSwl.classList.remove('sgx-hidden');
      noRemarks = false;
    } else {
      this._remarksStatusSwl.classList.add('sgx-hidden');
    }
    if (data.rmk && data.rmk.length > 0) {
      this._remarksStatusRmk.classList.remove('sgx-hidden');
      noRemarks = false;
      this._remarksStatusRmkLabel.textContent = typeof data.rmk !== 'string' ? data.rmk.join(' ') : data.rmk;
    } else {
      this._remarksStatusRmk.classList.add('sgx-hidden');
    }
    if (data.info && data.info.length > 0) {
      this._remarksStatusInfo.classList.remove('sgx-hidden');
      noRemarks = false;
      // TODO - treat as array object once securities service has been updated from string object
      this._remarksStatusInfoLabel.textContent = typeof data.info !== 'string' ? data.info.join(' ') : data.info;
    } else {
      this._remarksStatusInfo.classList.add('sgx-hidden');
    }

    if (data.sip.toUpperCase() === 'SP') {
      noRemarks = false;
      this._remarksStatusInfoSP.classList.remove('sgx-hidden');
      this._remarksStatusInfoSPDesc.textContent = i18n.getTranslation('widget-security-details-remarks.spac');
    } else {
      this._remarksStatusInfoSP.classList.add('sgx-hidden');
    }

    if (data.sip.toUpperCase() === 'TT') {
      noRemarks = false;
      this._remarksStatusInfoTT.classList.remove('sgx-hidden');
      this._remarksStatusInfoTTDesc.textContent = i18n.getTranslation('widget-security-details-remarks.tt');
    } else {
      this._remarksStatusInfoTT.classList.add('sgx-hidden');
    }

    if (noRemarks) {
      this._remarksSection.classList.add('sgx-hidden');
    } else {
      this._remarksSection.classList.remove('sgx-hidden');
    }
  }
}

customElements.define('widget-security-details-remarks', withInitDOM(WidgetSecurityDetailsRemarks));
