import { withInitDOM } from 'sgx-base-code';
import DateService from 'sgx-date-time-service';
import DeviceService from 'sgx-device-service';
import i18n from 'sgx-localisation-service';
import TableUtil from 'utils/table-util';
import { getPastMonths, ISO_FORMAT } from 'utils/date-util';
import { formatThousands } from 'utils/price-util';
import { download } from 'utils/pdf-util';
import { getDcsCcyData } from 'utils/bank-account-util';
import { payoutsTableConfig, ccyTableConfig, constants } from './widget-portfolio-payouts-config';
import PortfolioAggregator from 'aggregators/portfolio-aggregator';
import ConfigService from 'sgx-config-service';
import CcyService from 'services/ccy-service';
import UserService from 'services/user-service';
import PrintService from 'services/print-service';
import StoreRegistry from 'stores/store-registry';
import tmpl from './widget-portfolio-payouts.html';

const {
  FULL_DATE_FORMAT,
  PDF_DATE_FORMAT,
  EXPORT_NAME_PAYOUTS,
  EXPORT_NAME_CCY,
  DEFAULT_TABLE_HEIGHT,
  TABLE_ROW_HEIGHT_CCY,
  TABLE_TOOLBAR_AND_HEADER_HEIGHT_CCY,
  TABLE_ROW_HEIGHT_PAYOUTS,
  TABLE_TOOLBAR_AND_HEADER_HEIGHT_PAYOUTS } = constants;
const { CCY_PAYOUT_STATUS, CCY_PTS_INSTRUCTION_STATUS } = CcyService.constants;
const { ACCOUNT_STATES } = UserService.constants;
const READ_ONLY_ACCOUNTS = [ACCOUNT_STATES.CORPORATE, ACCOUNT_STATES.CORPORATE_TRUSTEE, ACCOUNT_STATES.JOINT_AND];
const NO_TRANSACTION_FEE = i18n.getTranslation('app.widget-portfolio-payouts.no-fee');

class WidgetPortfolioPayouts extends HTMLElement {
  constructor() {
    super();
    this._onPeriodChange = this._onPeriodChange.bind(this);
    this._onDownloadPayouts = this._onDownloadPayouts.bind(this);
    this._onPrintPayouts = this._onPrintPayouts.bind(this);
    this._onDownloadCcy = this._onDownloadCcy.bind(this);
    this._onPrintCcy = this._onPrintCcy.bind(this);
    this._onSetupCcyButtonClick = this._onSetupCcyButtonClick.bind(this);
  }

  initDOM() {
    this.classList.add('widget-portfolio-payouts');
    this.appendChild(tmpl.getNode());
    this._payoutsTable = this.querySelector('.payouts-table');
    this._payoutsLoader = this.querySelector('.payouts-loader');
    this._ccyTable = this.querySelector('.payouts-ccy-table');
    this._ccyLoader = this.querySelector('.payouts-ccy-loader');
    this._periodSelect = this.querySelector('.payouts-period-select');

    this._payoutsTable.setConfig(payoutsTableConfig);
    this._ccyTable.setConfig(ccyTableConfig);

    TableUtil.addCustomToolbar(this._payoutsTable, 'widget-portfolio-payouts-toolbar');
    TableUtil.addCustomToolbar(this._ccyTable, 'widget-portfolio-payouts-toolbar');

    // get the reference once toolbar has been attached to the table
    this._toolbar = this.querySelector('cmp-portfolio-blb-toolbar');
    this._payoutsDownload = this._payoutsTable.querySelector('sgx-data-model-tool[icon="download"]');
    this._payoutsPrint = this._payoutsTable.querySelector('sgx-data-model-tool[icon="print"]');
    this._ccyDownload = this._ccyTable.querySelector('sgx-data-model-tool[icon="download"]');
    this._ccyPrint = this._ccyTable.querySelector('sgx-data-model-tool[icon="print"]');
    this._ccySection = this.querySelector('.payouts-ccy-section');
    this._setupCcyButton = this._payoutsTable.querySelector('.payouts-toolbar-btn--setup');
  }

  connectedCallback() {
    this._setListeners(true);
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  _getPdfFilename(name) {
    const dateFormat = i18n.getTranslation('app.shared-text.date-format-file-name');
    return `${name} ${this._accountIdFormatted} ${DateService().format(dateFormat)}`;
  }

  setData({ accountId, accountDesc, accountIdFormatted }) {
    if (this._accountId !== accountId) {
      this._accountId = accountId;
      this._accountDesc = accountDesc;
      this._accountIdFormatted = accountIdFormatted;
      this._startDate = DateService().subtract(1, 'month').startOf('month').format(ISO_FORMAT.FULL_DATE);
      this._endDate = DateService().endOf('month').format(ISO_FORMAT.FULL_DATE);

      this._ccySection.classList.add('sgx-hidden');

      Promise.all([
        this._setPayouts(accountId, this._startDate, this._endDate),
        this._setPayoutsToolbarButtons(accountId).catch(_ => {
          // TODO: waiting for UX's advice on how to handle error for toolbar buttons
        })
      ])
        .then(_ => this._shouldShowCcySection())
        .then(() => {
          const options = getPastMonths(1);
          const latestOption = options[0];

          latestOption.selected = true; // select the latest month on the list
          this._periodSelect.setOptions(options);

          const currentPeriod = latestOption.value;
          if (this._currentPeriod === currentPeriod) {
            this._refresh(currentPeriod); // manually trigger the _refresh when account has change but period selection didn't change
          }
          this._currentPeriod = currentPeriod;
        });
    } else {
      this._payoutsTable.recalculateSize();
      this._ccyTable.recalculateSize();
    }
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener': 'removeEventListener';

    this._periodSelect[fnName]('change', this._onPeriodChange);
    this._payoutsDownload[fnName]('click', this._onDownloadPayouts);
    this._payoutsPrint[fnName]('click', this._onPrintPayouts);
    this._ccyDownload[fnName]('click', this._onDownloadCcy);
    this._ccyPrint[fnName]('click', this._onPrintCcy);
    this._setupCcyButton[fnName]('click', this._onSetupCcyButtonClick);
  }

  _onPeriodChange(evt) {
    this._refresh(evt.target.getValue());
  }

  _shouldShowCcySection() {
    if (this._showCcySection) {
      this._ccySection.classList.remove('sgx-hidden');
      return this._setCcyPayouts(this._accountId, this._startDate, this._endDate);
    }
  }

  _setPayoutsToolbarButtons(accountId) {
    return getDcsCcyData(accountId)
      .then(({ dcsAccounts, canCCYUpdate }) => {
        const { selectedAccountType, selectedAccountSuspended } = StoreRegistry.cdpSession.getData();
        const instruction = dcsAccounts[0].standingInstruction;
        const visibleSetupButtonStatus = [
          CCY_PTS_INSTRUCTION_STATUS.REJECTED_EXTERNAL,
          CCY_PTS_INSTRUCTION_STATUS.REJECTED_INTERNAL,
          CCY_PTS_INSTRUCTION_STATUS.TERMINATED_PTS
        ];

        let setupButtonMethod = (!instruction || instruction && !!~visibleSetupButtonStatus.indexOf(instruction.status))
          && canCCYUpdate ? 'remove': 'add';

        if (!!~READ_ONLY_ACCOUNTS.indexOf(selectedAccountType) || selectedAccountSuspended) {
          setupButtonMethod = 'add';
        }

        this._showCcySection = !!instruction;
        this._setupCcyButton.classList[setupButtonMethod]('sgx-hidden');
      })
      .catch(e => {
        this._showCcySection = false;
        this._setupCcyButton.classList.add('sgx-hidden');
        return Promise.reject(e);
      });
  }

  _setPayouts(accountId, startDate, endDate) {
    this._payoutsLoader.show({status: 'loading'});
    return this._getPayouts(accountId, startDate, endDate)
      .then(payouts => {
        if (this._accountId !== accountId) {
          // targeted account has changed
          return;
        }
        // Group the arrays per month and year
        this._payouts = this._groupByDate(payouts, 'paymentDate');
      })
      .then(_ => this._payoutsLoader.hide())
      .catch(_ => this._payoutsLoader.show({status: 'error'}));
  }

  _setCcyPayouts(accountId, startDate, endDate) {
    this._ccyLoader.show({status: 'loading'});
    return CcyService.getPayouts(accountId, startDate, endDate)
      .then(data => {
        if (this._accountId !== accountId) {
          // targeted account has changed
          return;
        }
        const { SETTLED, REVERSED } = CCY_PAYOUT_STATUS;
        const payouts = data.filter(payout => !!~[SETTLED, REVERSED].indexOf(payout.status))
          .map((payout, index) => {
            const {
              conversionRate,
              returnedAmount,
              convertedAmount,
              originalAmount,
              netAmount,
              transactionFee,
              srcCurrency,
              destCurrency,
              status,
              failedReason } = payout;
            return {
              ...payout,
              id: index,
              convertedAmount: `${destCurrency} ${formatThousands(convertedAmount, 2, true)}`,
              originalAmount: `${srcCurrency} ${formatThousands(originalAmount, 2, true)}`,
              netAmount: `${srcCurrency} ${formatThousands(netAmount, 2, true)}`,
              transactionFee: transactionFee ? `${srcCurrency} ${formatThousands(transactionFee, 2, true)}` : NO_TRANSACTION_FEE,
              returnedAmount: returnedAmount ? `${srcCurrency} ${formatThousands(returnedAmount, 2, true)}` : '',
              conversionRate: formatThousands(conversionRate, 8, true),
              status: this._getDisplayStatus(status),
              failedReason: failedReason || ''
            };
          });
        this._ccyPayouts = this._groupByDate(payouts, 'sentDate');
      })
      .then(_ => this._ccyLoader.hide())
      .catch(_ => this._ccyLoader.show({status: 'error'}));
  }

  _getDisplayStatus(status) {
    return i18n.getTranslation(`app.widget-portfolio-payouts.ccy-statuses.${status.toLowerCase()}`);
  }

  _groupByDate(arr, dateKey) {
    return arr.reduce((accumulator, currentValue) => {
      const key = DateService(currentValue[dateKey]).format(ISO_FORMAT.YEAR_MONTH);
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(currentValue);
      return accumulator;
    }, {});
  }

  _getPayouts(accountId, startDate, endDate) {
    this._accountId = accountId;
    return PortfolioAggregator.getPayouts(accountId, startDate, endDate)
      .then(payouts => payouts.map((payout, index) => {
        const id = index;
        const { announcedCurrency, grossValueInAnnounced, paidCurrency, grossValueInPaid, paidValue, dayCount, paymentRate, caType } = payout;
        const grossAmount = [
          `${announcedCurrency} ${formatThousands(grossValueInAnnounced, 2, true)}`,
          `${paidCurrency} ${formatThousands(grossValueInPaid, 2, true)}`
        ];
        const amountPaid = `${paidCurrency} ${formatThousands(paidValue, 2, true)}`;
        const rate = (dayCount && `${formatThousands(paymentRate * 100, 2, true)}% @ ${dayCount}`) ||
          `${announcedCurrency} ${parseFloat(formatThousands(paymentRate, 4, true))}`;
        const paymentTypeAndRate = [caType, rate];
        const handlingFeeGst = `${paidCurrency} ${formatThousands(payout.handlingFee, 2, true)}`;
        const handlingFeeAndGST = payout.handlingFee;
        const taxFormatted = `${paidCurrency} ${formatThousands(payout.tax, 2, true)}`;
        if (announcedCurrency === paidCurrency) {
          grossAmount.shift();
        }

        return {
          ...payout,
          grossAmount,
          amountPaid,
          paymentTypeAndRate,
          handlingFeeGst,
          handlingFeeAndGST, // only use for pdf generation
          taxFormatted,
          id
        };
      }));
  }

  _refresh(period) {
    this._monthName = DateService(period, ISO_FORMAT.YEAR_MONTH).format(PDF_DATE_FORMAT);

    const dataPayouts = (this._payouts && this._payouts[period]) || [];
    if (DeviceService.isDesktop()) {
      this._payoutsTable.style.height = dataPayouts.length && dataPayouts.length > 3
        ? `${(dataPayouts.length * TABLE_ROW_HEIGHT_PAYOUTS) + TABLE_TOOLBAR_AND_HEADER_HEIGHT_PAYOUTS}px`
        : DEFAULT_TABLE_HEIGHT;
    }
    this._payoutsTable.setData(dataPayouts);
    this._payoutsTable.recalculateSize();

    const dataCcy = (this._ccyPayouts && this._ccyPayouts[period]) || [];
    if (DeviceService.isDesktop()) {
      this._ccyTable.style.height = dataCcy.length && dataCcy.length > 5
        ? `${(dataCcy.length * TABLE_ROW_HEIGHT_CCY) + TABLE_TOOLBAR_AND_HEADER_HEIGHT_CCY}px`
        : DEFAULT_TABLE_HEIGHT;
    }
    this._ccyTable.setData(dataCcy);
    this._ccyTable.recalculateSize();
  }

  _onDownloadPayouts() {
    this._exportTable(this._payoutsTable, EXPORT_NAME_PAYOUTS);
  }

  _onPrintPayouts() {
    this._payoutsTable.getDataModel().getCurrentStateData()
      .then(({ keys, values }) => {
        if (!this._monthName) {
          return;
        }
        const monthName = this._monthName;
        const accountNoDesc = this._accountDesc;
        const fields = Object.keys(keys).map(key => {
          const { securityAbbrevName, paymentDate, entitlementQuantity, paidValue,
              exchangeRate, paymentRef, paidCurrency, bookClosureDate, paymentTypeAndRate, tax,
              handlingFeeAndGST, grossValueInAnnounced, grossValueInPaid, announcedCurrency } = values[keys[key]];

          return {
            security: securityAbbrevName,
            registeredHoldings: formatThousands(entitlementQuantity, 0, true),
            paymentTypeAndRate: paymentTypeAndRate.join(' '),
            grossAmountAnnounced: formatThousands(grossValueInAnnounced, 2, true),
            grossAmountPaid: formatThousands(grossValueInPaid, 2, true),
            paymentDate: DateService(paymentDate).format(FULL_DATE_FORMAT),
            exchangeRate: formatThousands(exchangeRate, 2, true),
            paymentRef,
            paidCurrency,
            bookClosureDate: DateService(bookClosureDate).format(FULL_DATE_FORMAT),
            handlingFeeAndGST: formatThousands(handlingFeeAndGST, 2, true),
            amountPaid: formatThousands(paidValue, 2, true),
            announcedCurrency,
            tax: formatThousands(tax, 2, true)
          };
        });
        const body = {
          qualifiedName: 'payouts',
          params: {
            monthName,
            accountNoDesc
          },
          fields
        };

        PrintService.getPdf(body)
          .then(base64 => download(base64, this._getPdfFilename(EXPORT_NAME_PAYOUTS)));
      });
  }

  _onDownloadCcy() {
    this._exportTable(this._ccyTable, EXPORT_NAME_CCY);
  }

  _onPrintCcy() {
    this._ccyTable.getDataModel().getCurrentStateData()
      .then(({ keys, values }) => {
        if (!this._monthName) {
          return;
        }
        const monthName = this._monthName;
        const accountNoDesc = this._accountDesc;
        const fields = Object.keys(keys).map(key => {
          const data = values[keys[key]];
          const { conversionRate, sentDate } = data;

          return {
            ...data,
            sentDate: DateService(sentDate).format(FULL_DATE_FORMAT),
            conversionRate: parseFloat(conversionRate) + '' // remove trailing zeros and convert to string
          };
        });
        const body = {
          qualifiedName: 'currencyConversion',
          params: {
            monthName,
            accountNoDesc
          },
          fields
        };
        PrintService.getPdf(body)
          .then(base64 => download(base64, this._getPdfFilename(EXPORT_NAME_CCY)));
      });
  }

  _onSetupCcyButtonClick() {
    window.location.href = ConfigService.links.INVESTORS_PORTAL_PROFILE_ACCOUNT;
  }

  _exportTable(table, name) {
    table.setExportDataFileName(`${name} ${this._accountIdFormatted} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`);
    table.exportData();
  }
}

customElements.define('widget-portfolio-payouts', withInitDOM(WidgetPortfolioPayouts));
