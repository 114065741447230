import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-list-row-prompt.html';

/**
 * Simple row with text and a button.
 */
class CmpListRowPrompt extends HTMLElement {

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-prompt');
    this._text = this.querySelector('.cmp-list-row-prompt-text');
    this._button = this.querySelector('.cmp-list-row-prompt-button');
    this._buttonText = this.querySelector('.cmp-list-row-prompt-button-text');
  }

  connectedCallback() {}

  disconnectedCallback() {
    if (this._onButtonClick) {
      this._button.removeEventListener('click', this._onButtonClick);
    }
  }

  setConfig({ text = '', button = '', callback }) {
    this._text.innerText = text;
    this._buttonText.innerText = button;

    if (callback && typeof callback === 'function') {
      this._onButtonClick = callback;
      this._button.addEventListener('click', this._onButtonClick);
    }
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  disable() {
    this._button.setAttribute('disabled', true);
  }

  enable() {
    this._button.removeAttribute('disabled');
  }
}

customElements.define('cmp-list-row-prompt', withInitDOM(CmpListRowPrompt));
