import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-user-signatory.html';
import i18n from 'sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import * as constants from 'services/user-service/src/user-service-constants';
import get from 'lodash/get';

/**
 * Authorized signatory details fieldset.
 */
class UserSignatoryWidget extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-user-signatory');

    // References
    this._signatoryDetailsFieldset = this.querySelector('.signatory-fieldset');
    this._title = this.querySelector('.details-title');
    this._nameInput = this.querySelector('.details-name');
    this._mobilePhoneInput = this.querySelector('.details-mobilePhone');

    this._sessionStore = StoreRegistry.cdpSession;
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  setData(user) {

    // Subscribe for the accountType
    let {accountType, authorizedUser} = this._sessionStore.getData();
    this._sessionStore.subscribe(acc => {
      if (accountType !== acc.accountType && acc.accountType) {
        accountType = acc.accountType;
        this.setData(user);
      }
    });

    const isCorporateAccount = [constants.ACCOUNT_STATES.CORPORATE].includes(accountType);


    // Fill form values with user particulars
    if (isCorporateAccount) {
      this._nameInput.setValue(authorizedUser);
      this._mobilePhoneInput.setJsonValue({
        number: get(user, 'otherPhoneNumber.number') || '',
        code: (get(user, 'otherPhoneNumber.countryCode') || '').replace('+', '')
      });
      this.classList.remove('sgx-hidden');
    } else {
      this.classList.add('sgx-hidden');
    }
  }

  updateValidationConfig(validate, config) {
    // Adding custom validation for some special fields
    config.validate.rules['contacts[0]'] = {
      presence: {
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.is-required-w-field-name'), {fieldName: i18n.getTranslation('app.widget-settings-user-signatory.inputs.mobilePhone.label')})
      },
      format: {
        pattern: '^$|^\\+[1-9][0-9]*\\s[0-9]+$',
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-settings-user-signatory.inputs.mobilePhone.label')})
      },
      singaporePhone: {
        mobileOnly: true,
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-settings-user-signatory.inputs.mobilePhone.label')})
      }
    };
    return config;
  }
}

customElements.define('widget-settings-user-signatory', withInitDOM(UserSignatoryWidget));
