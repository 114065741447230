import { withInitDOM } from 'sgx-base-code';
import tmpl from './sgx-status-tag.html';

class SgxStatusTag extends HTMLElement {

  connectedCallback() {}

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('sgx-status-tag');
    this._indicator = this.querySelector('.sgx-status-tag-indicator');
    this._text = this.querySelector('.sgx-status-tag-text');
  }

  setData({ color = '', text = '', icon = '' }) {
    if (color) {
      this._indicator.classList.add(`sgx-status-tag-indicator--${color.toLowerCase()}`);
    }

    if (icon) {
      this.resetIcons();
      this._indicator.classList.add('sgx-icon--before');
      this._indicator.classList.add(`sgx-status-tag-indicator--${icon.toLowerCase()}`);
    }

    if (text) {
      this._text.textContent = text;
    }
  }

  resetIcons() {
    this._indicator.classList.remove('sgx-status-tag-indicator--success');
    this._indicator.classList.remove('sgx-status-tag-indicator--pending');
    this._indicator.classList.remove('sgx-status-tag-indicator--error');
  }

  disconnectedCallback() {}
}

customElements.define('sgx-status-tag', withInitDOM(SgxStatusTag));
