import SgxTableCellBase from 'sgx-table/src/sub-components/sgx-table-cell/sgx-table-cell-base';
import { withInitDOM } from 'sgx-base-code';
import { formatThousands } from 'utils/price-util';

/**
 * A custom HTML element used to render cells for portfolio overview
 * @module sgx-table-cell-blocked-balance
 * @type {HTMLElement}
 */
class SgxTableCellBlockedBalance extends SgxTableCellBase {
  constructor() {
    super();
    this.defaultConfig = {
      defaultValue: '-',
      replaceZero: true
    };
  }

  initDOM() {
    this.innerHTML = `
      <sgx-tooltip data-sgx-tooltip-hover data-sgx-tooltip-pos="right">
        <div data-sgx-tooltip-content></div>
        <div class="blocked-balance-label"></div>
      </sgx-tooltip>
    `;
    this._blockedBalance = this.querySelector('sgx-tooltip .blocked-balance-label');
  }

  connectedCallback() {
    super.connectedCallback();
  }

  setConfig(config, columnConfig, tableConfig) {
    this._config = {...this.defaultConfig, ...config};
  }

  setData(data) {
    const { defaultValue, replaceZero } = this._config;
    let value = formatThousands(data, 0, !replaceZero);

    if (value === '-' && defaultValue) {
      value = defaultValue;
    }
    this._blockedBalance.textContent = value;
  }
}

customElements.define('sgx-table-cell-blocked-balance', withInitDOM(SgxTableCellBlockedBalance));
