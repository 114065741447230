export default {
  "type": "object",
  "id": "SettingsUser",
  "properties": {
    "title": {
      "type": "string",
      "enum": [
        "Dr",
        "Mdm",
        "Mr",
        "Mrs",
        "Ms"
      ],
      "required": true,
    },
    "gender": {
      "type": "string",
      "enum": [
        "M",
        "F"
      ]
    },
    "identifierType": {
      "type": "string",
      "enum": [
        "passport",
        "nric",
        "companyRegNum"
      ]
    },
    "passport": {
      "type": "string"
    },
    "expiryDate": {
      "type": "string"
    },
    "nric": {
      "type": "string"
    },
    "issuingCountry": {
      "type": "string"
    },
    "countryOfBirth": {
      "type": "string"
    },
    "nationality": {
      "type": "string"
    },
    "dateOfBirth": {
      "type": "string"
    },
    "fullName": {
      "type": "string",
      "required": true,
      "pattern": "^$|^[^0-9<>#*%]+$",
      "maxLength": 70
    },
    "email": {
      "type": "string",
      "required": true,
      "email": true,
      "maxLength": 255
    },
    "contacts": {
      "description": "Mobile Phone, Home Phone, Office Phone",
      "type": "array",
      "required": true,
      "items": {
        "$ref": "#/definitions/contact"
      }
    },
    "addresses": {
      "description": "Residential Address, Mailing Address, Corporate Actions Address",
      "type": "array",
      "required": true,
      "items": {
        "$ref": "#/definitions/address"
      }
    }
  },
  "definitions": {
    "address": {
      "type": "object",
      "properties": {
        "addressType": {
          "type": "string",
          "required": true,
          "enum": [
            "RESIDENTIAL",
            "MAILING",
            "CA ADDRESS"
          ]
        },
        "country": {
          "required": true,
          "$ref": "#/definitions/country"
        },
        "postalCode": {
          "type": "string",
          "required": true,
          "maxLength": 10
        },
        "streetOne": {
          "type": "string",
          "required": true
        },
        "streetTwo": {
          "type": "string"
        },
        "streetThree": {
          "type": "string"
        },
        "streetFour": {
          "type": "string"
        }
      }
    },
    "contact": {
      "type": "object",
      "properties": {
        "contactType": {
          "type": "string",
          "required": true,
          "enum": [
            "MOBILE_PHONE",
            "HOME_NUMBER",
            "OFFICE_NUMBER"
          ]
        },
        "number": {
          "type": "string",
          "required": true,
          "pattern": "^$|[0-9]+"
        },
        "code": {
          "type": "string",
          "required": true
        },
        "countryCode": {
          "type": "string"
        }
      }
    },
    "country": {
      "type": "string",
      "enum": [
        "AX",
        "AF",
        "AL",
        "DZ",
        "AS",
        "AD",
        "AO",
        "AI",
        "AQ",
        "AG",
        "AR",
        "AM",
        "AW",
        "AU",
        "AT",
        "AZ",
        "BS",
        "BH",
        "BD",
        "BB",
        "BY",
        "BE",
        "BZ",
        "BJ",
        "BM",
        "BT",
        "BO",
        "BA",
        "BW",
        "BV",
        "BR",
        "IO",
        "BN",
        "BG",
        "BF",
        "BI",
        "KH",
        "CM",
        "CA",
        "CV",
        "BQ",
        "KY",
        "CF",
        "TD",
        "CL",
        "CN",
        "CX",
        "CC",
        "CO",
        "KM",
        "CK",
        "CR",
        "CI",
        "HR",
        "CU",
        "CW",
        "CY",
        "CZ",
        "KP",
        "CD",
        "DK",
        "DJ",
        "DM",
        "DO",
        "EC",
        "EG",
        "SV",
        "GQ",
        "ER",
        "EE",
        "ET",
        "FK",
        "FO",
        "FM",
        "FJ",
        "FI",
        "FR",
        "GF",
        "PF",
        "TF",
        "GA",
        "GM",
        "GE",
        "DE",
        "GH",
        "GI",
        "GR",
        "GL",
        "GD",
        "GP",
        "GU",
        "GT",
        "GG",
        "GN",
        "GW",
        "GY",
        "HT",
        "HM",
        "HN",
        "HK",
        "HU",
        "IS",
        "IN",
        "ID",
        "IR",
        "IQ",
        "IE",
        "IM",
        "IL",
        "IT",
        "JM",
        "JP",
        "JE",
        "JO",
        "KZ",
        "KE",
        "KI",
        "KW",
        "KG",
        "LA",
        "LV",
        "LB",
        "LS",
        "LR",
        "LY",
        "LI",
        "LT",
        "LU",
        "MO",
        "MG",
        "MW",
        "MY",
        "MV",
        "ML",
        "MT",
        "MH",
        "MQ",
        "MR",
        "MU",
        "YT",
        "MX",
        "MC",
        "MN",
        "ME",
        "MS",
        "MA",
        "MZ",
        "MM",
        "NA",
        "NR",
        "NP",
        "NL",
        "AN",
        "NC",
        "NZ",
        "NI",
        "NE",
        "NG",
        "NU",
        "NF",
        "MP",
        "NO",
        "OM",
        "PK",
        "PW",
        "PS",
        "PA",
        "PG",
        "PY",
        "PE",
        "PH",
        "PN",
        "PL",
        "PT",
        "PR",
        "QA",
        "CG",
        "KR",
        "MD",
        "RE",
        "RO",
        "RU",
        "RW",
        "BL",
        "SH",
        "KN",
        "LC",
        "PM",
        "VC",
        "MF",
        "SX",
        "WS",
        "SM",
        "ST",
        "SA",
        "SN",
        "RS",
        "YU",
        "SC",
        "SL",
        "SG",
        "SK",
        "SI",
        "SB",
        "SO",
        "ZA",
        "GS",
        "SS",
        "ES",
        "LK",
        "ZS",
        "SD",
        "SR",
        "SJ",
        "SZ",
        "SE",
        "CH",
        "SY",
        "TW",
        "TJ",
        "TH",
        "MK",
        "TL",
        "TG",
        "TK",
        "TO",
        "TT",
        "TN",
        "TR",
        "TM",
        "TC",
        "TV",
        "UG",
        "UA",
        "AE",
        "GB",
        "TZ",
        "US",
        "UM",
        "ZZ",
        "UY",
        "UZ",
        "VU",
        "VA",
        "VE",
        "VN",
        "VG",
        "VI",
        "WF",
        "EH",
        "YE",
        "ZM",
        "ZW"
      ]
    },
    'taxResidencyCountry': {
      'type': 'string',
      'enum': [
        'AU', 'BS', 'BH', 'BM',
        'VG', 'CA', 'KY', 'CN',
        'FR', 'DE', 'HK', 'IN',
        'ID', 'JP', 'MY', 'MC',
        'MS', 'NL', 'NZ', 'OM',
        'SG', 'SE', 'KR', 'CH',
        'TW', 'TC', 'AE', 'GB',
        'OTHERS'
      ]
    },
    'tinReason': {
      'type': 'string',
      'enum': [
        'DOES_NOT_ISSUE',
        'UNABLE_TO_OBTAIN',
        'NOT_REQUIRED'
      ]
    },
  },
}
