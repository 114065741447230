import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';

import PortfolioService from 'services/portfolio-service';
import PortfolioAggregator from 'aggregators/portfolio-aggregator';
import StoreRegistry from 'stores/store-registry';
import tmpl from './widget-portfolio-transfer-securities.html';
import {
  tableConfig,
  visibilityStateColumns,
  progressNavs,
  fieldStepStates
} from './widget-portfolio-transfer-securities-config';
import { formatThousands } from 'utils/price-util';
import { addCustomToolbar } from 'utils/table-util';

class WidgetPortfolioTransferSecurities extends HTMLElement {
  constructor() {
    super();
    this._onToggleTransferQty = this._onToggleTransferQty.bind(this);
    this._onNextButtonClick = this._onNextButtonClick.bind(this);
    this._onPrevButtonClick = this._onPrevButtonClick.bind(this);
    this._onProgressStateChange = this._onProgressStateChange.bind(this);
  }

  initDOM() {
    this._render();
  }

  connectedCallback() {
    // TODO: call _init once transfer security is ready to be implemented in the future, as it's currently doing some API calls which shouldn't happen yet
    // this._init();
  }

  disconnectedCallback() {
    // TODO: uncomment the cleanup below once transfer security is ready
    // this._subscription.unsubscribe();
    // this._setListeners(false);
  }

  setData(_) {
    this._transferTable.recalculateSize();
  }

  _init() {
    this._previousStep = -1;
    this._currentStep = 0;

    this._setElementRefs();
    this._setElementConfigs();
    this._setProgressNavButtons();
    this._setListeners(true);
    this._setSubscription();
  }

  _setElementConfigs() {
    this._progress.setData([
      {'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.progress.step1'), 'counterLabel': '1'},
      {'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.progress.step2'), 'counterLabel': '2'},
      {'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.progress.step3'), 'counterLabel': '3'},
      {'label': i18n.getTranslation('app.widget-portfolio-transfer-securities.progress.step4'), 'counterLabel': '4'}
    ]);

    // TODO - temporarily removed toolbar. need to show toolbar on step4
    addCustomToolbar(this._transferTable, 'span');
    this._transferTable.setConfig(tableConfig);
  }

  _render() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-portfolio-transfer-securities');
    this._initialize = true;
  }

  _setElementRefs() {
    this._transferQtyDialog = this.querySelector('cmp-portfolio-transfer-qty-dialog');
    this._progress = this.querySelector('.transfer-securities-progress');
    this._prompt = this.querySelector('.transfer-securities-prompt');
    this._transferFromSelect = this.querySelector('.transfer-securities-from');
    this._transferToSelect = this.querySelector('.transfer-securities-to');
    this._transferTable = this.querySelector('.transfer-securities-table');
    this._transferTableError = this.querySelector('.transfer-securities-table-error-message');
    this._totalTransferQty = this.querySelector('.transfer-securities-total-quantity');
    this._progressNavs = this.querySelector('.transfer-securities-progress-navigations');
    this._prevButton = this.querySelector('.transfer-securities-button-prev');
    this._nextButton = this.querySelector('.transfer-securities-button-next');
    this._stepFields = this.querySelector('.transfer-securities-step').children;
  }

  _setSubscription() {
    this._store = StoreRegistry.portfolioTransferSecurities;
    this._subscription = this._store.subscribe(({accountId, brokerId}) => {
      if (brokerId !== this._transferTo) {
        this._transferTo = brokerId;

        if (this._transferTo === 'NA') {
          this._toggleFieldsVisibility(fieldStepStates.step1NoSetup);
        } else {
          const stepState = !this._transferTo ? 'step1Empty' : 'step1Selected';
          this._toggleFieldsVisibility(fieldStepStates[stepState]);
          this._toggleTableColumnVisibility(visibilityStateColumns[stepState]);
        }
      }

      if (accountId !== this._transferFrom) {
        this._transferFrom = accountId;
        this._transferToSelect.setOptionsForAccount(accountId);

        this._getTableData()
          .then(assets => this._transferTable.setData(assets));
      }
    });
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this._progress[fnName]('stateChange', this._onProgressStateChange);
    this._nextButton[fnName]('click', this._onNextButtonClick);
    this._prevButton[fnName]('click', this._onPrevButtonClick);
    this._transferTable[fnName]('ROW_STAR_CHANGED', this._onToggleTransferQty);
    this._transferTable[fnName]('ROW_INPUT_BUTTON_CLICKED', this._onToggleTransferQty);
    this[fnName]('beforeunload', this._onBeforeUnload);
  }

  _getTableData() {
    const callback = ([securityAssets, exchangeRates]) => {
      const rates = exchangeRates.reduce((accumulator, currentValue) => {
        accumulator[currentValue.currency] = currentValue.rate;
        return accumulator;
      }, {});

      return PortfolioAggregator.combineAssets(securityAssets).map(asset => ({
        ...asset,
        fees: 0,
        quantity: 0,
        check: false,
        status: '',
        statusColorInd: 0, // color indicator for status, 1 for positive (green), -1 for negative (red) and 0 for neutral (black)
        rate: rates[asset.currency]
      }));
    };

    return Promise.all([
      PortfolioService.getAllSecurityAssets(this._transferFrom),
      PortfolioService.getConversionRates()
    ])
      .then(callback);
  }

  /**
   * Triggers transfer quantity dialog whenever you check the checkbox or click on the quantity button
   * @param {ROW_STAR_CHANGED|ROW_INPUT_BUTTON_CLICK} event
   */
  _onToggleTransferQty(event) {
    const tagName = event.target.tagName;
    const {rowId, selected} = event.detail;

    if (tagName !== 'SGX-TABLE-CELL-STAR' || (tagName === 'SGX-TABLE-CELL-STAR' && selected)) {
      this._transferTable.getDataModel().getRowData(rowId)
        .then(data => this._showTransferQtyDialog(data, rowId));
    } else {
      this._validateProgress();
    }
  }

  _onProgressStateChange() {
    this._setProgressNavButtons();
    this._setStepState();
  }

  _onNextButtonClick() {
    this._previousStep = this._currentStep;
    if (++this._currentStep > 3) {
      this._previousStep = -1;
      this._currentStep = 0;
    }
    this._progress.setState(this._currentStep, this._previousStep);
  }

  _onPrevButtonClick(e) {
    if (this._currentStep >= 3) {
      // TODO - navigate back to portfolio
      return;
    }
    this._previousStep = this._currentStep;
    this._progress.setActiveIndex(--this._currentStep);
  }

  _onBeforeUnload() {
    return true;
  }

  _showTransferQtyDialog(data, rowId) {
    const callback = quantity => {
      this._transferTable.updateData({
        id: rowId,
        values: {...data, quantity},
        type: 'update'
      });
      this._validateProgress();
    };

    this._transferQtyDialog.showDialog({
      title: i18n.getTranslation('app.widget-portfolio-transfer-securities.dialog.title'),
      data: {
        freeBalance: data.freeBalance,
        quantity: data.quantity,
        validationMessage: {
          lessThanOrEqualTo: i18n.getTranslation('app.widget-portfolio-transfer-securities.validation.quantity.less-than'),
          greaterThan: i18n.getTranslation('app.widget-portfolio-transfer-securities.validation.quantity.greater-than'),
          presence: i18n.getTranslation('app.widget-portfolio-transfer-securities.validation.quantity.presence'),
          numeric: i18n.getTranslation('app.widget-portfolio-transfer-securities.validation.quantity.numeric')
        }
      }
    })
      .then(callback)
      .catch(_ => this._validateProgress());
  }

  _toggleTableColumnVisibility(columns) {
    const tableState = this._transferTable.getState();
    const tableColumns = tableState.states[0].data.columns;

    Object.keys(tableColumns).forEach(key =>
      tableColumns[key].show = key === 'securityName' || !!~columns.indexOf(key));
    tableState.states[0].data.filters = !!this._currentStep ? this._getCheckFilter() : {};
    this._transferTable.setState(tableState);
  }

  _setProgressNavButtons() {
    const fnName = !this._currentStep ? 'add' : 'remove';
    this._prevButton.classList[fnName]('sgx-hidden');
    this._prevButton.textContent = progressNavs[this._currentStep].prev;
    this._nextButton.textContent = progressNavs[this._currentStep].next;
  }

  _setStepState() {
    if (!~this._previousStep) {
      this._getTableData()
        .then(assets => {
          this._transferTable.setData(assets);
          this._transferToSelect.value = '';
        });
      return;
    }

    const state = ['step1Selected', 'step2', 'step3', 'step4'];
    const step = state[this._currentStep];
    this._toggleTableColumnVisibility(visibilityStateColumns[step]);
    this._toggleFieldsVisibility(step);
  }

  _toggleFieldsVisibility(step) {
    const readonly = !!this._currentStep;
    this._transferFromSelect.readonly = readonly;
    this._transferToSelect.readonly = readonly;
    this._transferToSelect.message = step === fieldStepStates.step1Empty ? i18n.getTranslation('app.widget-portfolio-transfer-securities.validation.transfer-to.presence') : '';

    for (let field of this._stepFields) {
      if (field.dataset.step) {
        const fnName = !~field.dataset.step.indexOf(step) ? 'add' : 'remove';
        field.classList[fnName]('sgx-hidden');
      }
    }

    this._setTotalFields();
    this._validateProgress();
  }

  _setTotalFields() {
    this._transferTable.getDataModel().getDataForState({filters: this._getCheckFilter()})
      .then(data => {
        const totalQty = data.keys.reduce((total, key) => total + data.values[key].quantity, 0);
        this._totalTransferQty.value = formatThousands(totalQty, 0, true);
      });
  }

  _validateProgress() {
    if (this._currentStep && this._previousStep >= 0) {
      this._nextButton.disabled = false;
      this._transferTableError.classList.add('sgx-hidden');
    } else {
      this._transferTable.getDataModel().getDataForState({filters: this._getCheckFilter()})
        .then(data => {
          const selectedTransferCount = data.keys.length;
          const hasValidQuantities = data.keys.every(key => !!data.values[key].quantity);
          const disabled = !(selectedTransferCount && selectedTransferCount < 6 && hasValidQuantities);
          const classListFn = selectedTransferCount > 5 || !hasValidQuantities ? 'remove' : 'add';

          this._transferTableError.classList[classListFn]('sgx-hidden');
          this._transferTableError.textContent = (hasValidQuantities && i18n.getTranslation('app.widget-portfolio-transfer-securities.validation.table.exceed-limit'))
            || i18n.getTranslation('app.widget-portfolio-transfer-securities.validation.quantity.less-than')
          this._nextButton.disabled = disabled;
        });
    }
  }

  _getCheckFilter() {
    return {
      check: {
        method: 'equals',
        type: 'text',
        value: 'true'
      }
    }
  }
}

customElements.define('widget-portfolio-transfer-securities', withInitDOM(WidgetPortfolioTransferSecurities));
