/**
 * Custom validators for password validation.
 */
export const passwordValidators = {
  /**
   * Must contain at least two of the following character types:
   * - One alphabetic character
   * - One numeric character
   * - One special character
   */
  twoCharacterTypes(value, options) {
    if (!value) {
      return;
    }

    const alphabetic = /[a-zA-Z]/.test(value);
    const numeric = /[1-9]/.test(value);
    const special = /[\W]/.test(value);
    const hasTwoCharacterTypes = ((alphabetic && numeric) || (alphabetic && special) || (numeric && special));

    if (!hasTwoCharacterTypes) {
      return options.message;
    }
  },
  /**
   * Must match or not match the value of a second input element.
   * Re-validates the form if the second input element already has an error message displayed,
   * keeping the error states in sync.
   * 
   * @param {String} value
   * @param {Object} options 
   * @param {HTMLElement} options.scope custom element scope
   * @param {String} options.attribute name attribute of the input to compare with
   * @param {Boolean} options.shouldMatch indicates whether the validator should test equality or inequality
   * @param {String} options.message error message to return conditionally
   */
  matches(value, options) {
    const { scope, attribute, shouldMatch, message } = options;

    if (!value || !scope || !attribute) {
      return;
    }

    // The second element to compare with
    const secondElement = scope.querySelector(`[name=${attribute}]`);

    // The condition to test - either equality or inequality
    const condition = shouldMatch ? value !== secondElement.value : value === secondElement.value;
    
    if (condition) {
      return message;
    } else if (secondElement.getMessage()) { // Re-validate form to keep error states in sync
      setTimeout(() => {
        scope.querySelector('sgx-form').validate();
      })
    }
  },
  // TODO - Refactor away from usage in widget-settings-security-password-dialog to use the same generalized
  // equality and inequality validators used during login
  validateSamePassword(value, options, formRef) {
    const passwordElem = formRef.querySelector(`#${options.attribute}`);
    const passwordValue = passwordElem.getValue();

    if (!!passwordValue) {
      if (passwordValue === value) {
        return options.message;
      } else if (passwordElem.getMessage()) {
        setTimeout(_ => {
          formRef.validate();
        })
      }
    }
  }
};
