import { withInitDOM, URLUtils } from 'sgx-base-code';
import StoreRegistry from 'stores/store-registry';
import tmpl from './widget-portfolio.html';
import tabConfig from './widget-portfolio-config';
import { isAuthenticated } from 'utils/auth-util';
import { formatAccountId } from 'utils/bank-account-util';
import {deleteQueryParams} from "../../utils/query-param-util";

class WidgetPortfolio extends HTMLElement {
  constructor() {
    super();
    this._router = document.getElementById('sgx-app-router');
    this._store = StoreRegistry.portfolioAccount;
    this._onTabChange = this._onTabChange.bind(this);
    this._onViewDetailsClick = this._onViewDetailsClick.bind(this);
  }

  initDOM() {
    this.classList.add('widget-portfolio');
    this.appendChild(tmpl.getNode());
    this._tabs = this.querySelector('.portfolio-tabs');
    this._viewDetailsTabs = this.querySelector('.portfolio-view-details-tabs');
    this._viewDetailsDialog = this.querySelector('widget-portfolio-account-details-dialog');
    this._viewDetails = this.querySelector('button');
    this._accountSelect = this.querySelector('widget-account-select');

    if (isAuthenticated()) {
      this._tabs.setConfig(tabConfig.config);
      this._tabs.setState(tabConfig.state);
    }
  }

  connectedCallback() {
    if (isAuthenticated()) {
      this._tabs.addEventListener('stateChange', this._onTabChange);
      this._viewDetails.addEventListener('click', this._onViewDetailsClick);
      this._setDeeplinkToTabs();

      this._subscription = this._store.subscribe(({accountId}) => {
        if (accountId !== this._selectedAccount) {
          this._selectedAccount = accountId;
          this._setCurrentTabData();
        }
      });
    }
  }

  disconnectedCallback() {
    if (isAuthenticated()) {
      this._viewDetails.removeEventListener('click', this._onViewDetailsClick);
      this._tabs.removeEventListener('stateChange', this._onTabChange);
      this._subscription && this._subscription.unsubscribe();
    }
  }

  _setDeeplinkToTabs() {
    const params = URLUtils.decodeQueryString(window.location.search);
    if (!params.tab) {
      return;
    }
    const tabMap = {overview: 0, payout: 1, share: 2, eStatement: 3, rights: 4};
    if (tabMap[params.tab]) {
      this._tabs.activateTab(tabMap[params.tab])
    }

    deleteQueryParams(['tab'])

  }

  get _currentTab() {
    return this.querySelector('.portfolio-tabs-item:not(.sgx-hidden)');
  }

  _onTabChange(e) {
    const oldTab = this._currentTab;
    const activeTab = this.querySelector(`.${e.detail}`);
    if (oldTab) {
      oldTab.classList.add('sgx-hidden');
    }
    activeTab.classList.remove('sgx-hidden');
    this._setCurrentTabData();
  }

  _onViewDetailsClick() {
    this._viewDetailsDialog.showDialog({
      accountId: this._selectedAccount,
      accountDesc: this._accountSelect.selectedOptions[0].label
    })
      .then(update => {
        this._router.navigateToURL('/profile/user');
      }, dismiss => {});
  }

  _setCurrentTabData() {
    if (!this._selectedAccount || !isAuthenticated()) {
      return;
    }
    this._currentTab.setData({
      accountId: this._selectedAccount,
      accountIdFormatted: formatAccountId(this._selectedAccount),
      accountDesc: this._accountSelect.selectedOptions[0].label
    });
  }
}

customElements.define('widget-portfolio', withInitDOM(WidgetPortfolio));
