import tmpl from './cmp-error-handling.html';
import { withInitDOM } from 'sgx-base-code';
import DeviceService from '@sgx/sgx-device-service';

export default class CmpErrorHandling extends HTMLElement {

  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._indicator = this.querySelector('sgx-status-indicator');
    this._dialog = this.querySelector('#cmp-error-handling-dialog');
    this._dialogTitle = this._dialog.querySelector('.dialog-title');
  }

  setData({dialogTitle, status, title, description}) {
    this._dialogTitle.textContent = dialogTitle;
    this._indicator.show({status, title, description});
  }

  show() {
    this._dialog.show({
      fullscreen: DeviceService.isMobile(),
    });
  }

  hide() {
    this._dialog.hide();
  }
}

customElements.define('cmp-error-handling', withInitDOM(CmpErrorHandling));
