module.exports = (function() {
    var templateString = module.exports = "<template id=\"template-sgx-list-details\">\n  <div class=\"sgx-list-details-list\">\n    <div class=\"sgx-list-details-list-toolbar\"></div>\n    <sgx-list class=\"sgx-list-details-list-list\"></sgx-list>\n  </div>\n  <div class=\"sgx-list-details-details\">\n    <div class=\"sgx-list-details-details-header\">\n      <button type=\"button\" class=\"sgx-button--small sgx-list-details-details-header-back\">\n        <span class=\"sgx-list-details-details-header-back--label\"></span>\n      </button>\n      <h2 class=\"sgx-list-details-details-header--title\"></h2>\n    </div>\n    <sgx-status-indicator status=\"neutral\"\n      data-i18n=\"[title]list-details.status-indicator.empty.title;[description]list-details.status-indicator.empty.description;\"\n      class=\"sgx-list-details-details-loader\"></sgx-status-indicator>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()