module.exports = (function() {
    var templateString = module.exports = "<template id=\"template-settings-security\">\n  <div class=\"container-fluid--full-width\">\n    <div class=\"row settings-main-widgets\">\n      <div class=\"column col-md-6 col-xs-12\">\n        <widget-settings-security-password class=\"sgx-card\"></widget-settings-security-password>\n      </div>\n    </div>\n  </div>\n  <cmp-login-prompt class=\"cmp-login-prompt--top-margin\"></cmp-login-prompt>\n</template>";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()