import { withInitDOM } from 'sgx-base-code';
import SecuritiesService from 'sgx-securities-service';
import TABLE_CONFIG from './widget-security-details-gti-config';
import tmpl from './widget-security-details-gti.html';

/**
 * Stock screener widget.
 */
class WidgetSecurityDetailsGti extends HTMLElement {
  constructor() {
    super();
    this._renderResults = this._renderResults.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-gti');
    this._isInitialized = true;
    this._table = this.querySelector('sgx-table');
    this._table.setConfig(TABLE_CONFIG);
  }

  connectedCallback() {
  }

  setData(data) {
    this._table.classList.add('loading');
    SecuritiesService.getGtiByCode(data.nc)
      .then(this._renderResults)
      .catch(_ => {
        this._table.classList.remove('loading');
        this._table.classList.add('error');
      });
  }

  recalculateSize() {
    this._table.recalculateSize();
  }

  _renderResults(results) {
    const data = results || [];
    const tableData = data.map((item, index) => ({...item, id: index}))
      .sort((a, b) => a.year > b.year ? -1 : 1) // sort by year in descending order
      .slice(0, 10); // get first 10 only
    this._table.setData(tableData);
    this._table.recalculateSize();
    this._table.classList.remove('loading');
  }
}

customElements.define('widget-security-details-gti', withInitDOM(WidgetSecurityDetailsGti));
