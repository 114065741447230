import Configuration from 'sgx-config-service';
import { FetchUtils } from 'sgx-base-code';
import get from 'lodash/get';

let instance = null;

/**
 * Service to fetch reference data.
 */
class ReferenceDataService {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Gets a list of countries handled by SGX.
   * @return {Array} the list of supported countries in English (for now)
   */
  getNationalities() {
    return import('./nationality-en.json').then(module => {
      return {
        "status": {
          "code": "200",
          "message": "Success"
        },
        "data": module.default
      };
    });
  }

  /**
   * Gets a list of countries handled by SGX.
   * @return {Array} the list of supported countries in English (for now)
   */
  getNationalitiesMyInfo() {
    return import('./nationality-myinfo-en.json').then(module => {
      return {
        "status": {
          "code": "200",
          "message": "Success"
        },
        "data": module.default
      };
    });
  }

  /**
   * Gets a list of countries handled by SGX.
   * @return {Array} the list of supported countries in English (for now)
   */
  getCountries() {
    return import('./countries-en.json').then(module => {
      return {
        "status": {
          "code": "200",
          "message": "Success"
        },
        "data": module.default
      };
    });
  }

  /**
   * Gets a list of countries handled by SGX.
   * @return {Array} the list of supported countries in English (for now)
   */
  getCountriesMyInfo() {
    return import('./countries-myinfo-en.json').then(module => {
      return {
        "status": {
          "code": "200",
          "message": "Success"
        },
        "data": module.default
      };
    });
  }

  /**
   * Gets a list of eventually cached DCS banks handled by SGX.
   * @return {Array} the list of supported banks
   */
  getBanks() {
    if (this._getBanksResponse) {
      return Promise.resolve(this._getBanksResponse);
    } else {
      return this._getDataList('banks').then(response => this._getBanksResponse = response);
    }
  }

  /**
   * Gets a certain bank by ID.
   * @param {String} bankId the bank ID
   * @return {Object} the bank if it exists, null otherwise
   */
  getBank(bankId) {
    return this.getBanks()
      .then(response => response.data)
      .then(banks => banks.find(bank => get(bank, 'data.bankId') === bankId))
  }

  /**
   * Gets a list of eventually cached brokers handled by SGX.
   * @return {Array} the list of brokers
   */
  getBrokers() {
    if (this._getBrokersResponse) {
      return Promise.resolve(this._getBrokersResponse);
    } else {
      return this._getDataList('brokers').then(response => this._getBrokersResponse = response);
    }
  }

  /**
   * Gets a certain broker by code.
   * @param {String} brokerCode the broker code
   * @return {Object} the broker if it exists, null otherwise
   */
  getBroker(brokerCode) {
    return this.getBrokers()
      .then(response => response.data)
      .then(brokers => brokers.find(broker => get(broker, 'data.brokerCode') === brokerCode))
  }

  /**
   * Gets a list of a certain kind of data stored in CMS format.
   * @param {String} dataType the type of expected data
   * @return {Array} the list of supported banks
   */
  _getDataList(dataType) {
    return FetchUtils.fetchJSON(Configuration.env.api[dataType], {
      mode: 'cors',
      cache: 'default'
    }).then(response => {
      return {
        "status": {
          "code": "200",
          "message": "Success"
        },
        "data": get(response, 'data.list.results') || []
      };
    });
  }

}

/* Export the singleton */
export default new ReferenceDataService();
