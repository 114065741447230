import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-due-d-check.html';
import { fromEvent } from 'rxjs';

const TEMPLATE_ID = 'widget-due-d-check';

class WidgetDueDCheck extends HTMLElement {
  constructor() {
    super();
    this._subscriptions = [];
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add(TEMPLATE_ID);
    this._dialog = this.querySelector('.w-due-d-check-dialog');
    this._proceedBtn = this.querySelector('.proceed-btn');
    this._cancelButton = this.querySelector('.cancel-btn');
    this._countriesWrapper = this.querySelector('.exception-countries');
    this._exceptionCheck = this.querySelector('.exception-check');
    this._validateProceed();
  }

  connectedCallback() {
    this.handleEventListeners();
  }

  disconnectedCallback() {
    (this._subscriptions || []).map(sub => sub.unsubscribe());
  }

  setData(data) {
    this._countriesWrapper.innerHTML = '';
    if (data && data.length) {
      data.forEach((country) => {
        let countryElm = document.createElement('div');
        countryElm.classList.add('exception-country');
        countryElm.innerText = country;
        this._countriesWrapper.appendChild(countryElm);
      });
    } else {
      let countryElm = document.createElement('div');
      countryElm.innerText = '-';
      this._countriesWrapper.appendChild(countryElm);
    }
  }


  showDialog() {
    this._dialog.show({ isModal: true });
  }

  handleEventListeners() {
    this._subscriptions.push(fromEvent(this._exceptionCheck, 'change')
      .subscribe(() => {
        this._validateProceed();
      }));
    this._subscriptions.push(fromEvent(this._proceedBtn, 'click')
      .subscribe(e => {
        this.dispatchEvent(new CustomEvent('onDueCheckComplete', {
          'detail': {
            'agreed': true
          }
        }));
      }));
    this._subscriptions.push(fromEvent(this._cancelButton, 'click')
      .subscribe(e => {
      }));
  }

  _toggleProceed(toggle) {
    toggle ? this._proceedBtn.removeAttribute('disabled') : this._proceedBtn.setAttribute('disabled', true);
  }

  _validateProceed() {
    const enable = this._exceptionCheck.checked;
    this._toggleProceed(enable);
  }
}

customElements.define(TEMPLATE_ID, withInitDOM(WidgetDueDCheck));
