import { withInitDOM } from '@sgx/sgx-base-code';
import i18n from '@sgx/sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import BlbService from 'services/blb-service';
import tmpl from './widget-broker-select.html';

/**
 * Brokers input select component
 * Expects an attribute of store-name
 * @module widget-broker-select
 * @type { HTMLElement }
 */

class WidgetBrokerSelect extends HTMLElement {
  static get observedAttributes() {
    return ['value', 'label', 'readonly', 'message'];
  }

  constructor() {
    super();
    this._brokerSelectStore = StoreRegistry.brokerSelect;

    this._onInputSelectChange = this._onInputSelectChange.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-broker-select');

    // References
    this._inputSelect = this.querySelector('sgx-input-select');
    this._emptyDescription = this.querySelector('.widget-broker-select-empty-desc');
    // TODO - use sgx-input-select's label attribute once inline/horizontal label is supported
    this._label = this.querySelector('label');
  }


  connectedCallback() {

    // Listeners
    this._inputSelect.addEventListener('change', this._onInputSelectChange);

    this._shouldEnableSelect(false);
  }

  disconnectedCallback() {
    this._inputSelect.removeEventListener('change', this._onInputSelectChange);
  }

  /**
   * Getter and setter property for sgx-input-select value property
   */
  get value() {
    return this._inputSelect.value;
  }

  set value(val) {
    this.setAttribute('value', val);
  }

  /**
   * Getter and setter for readonly attribute
   */
  get readonly() {
    return this.hasAttribute('readonly');
  }

  set readonly(value) {
    if (value) {
      this.setAttribute('readonly', value);
    } else {
      this.removeAttribute('readonly');
    }
  }

  /**
   * Getter and setter for message attribute
   */
  get message() {
    return this._inputSelect.message;
  }

  set message(val) {
    this.setAttribute('message', val);
  }

  get storeName() {
    return this.getAttribute('store-name');
  }

  attributeChangedCallback(attr, oldValue, newValue) {
    switch (attr) {
      case 'label':
        this._setLabelContent(newValue);
        break;
      case 'readonly':
        this._setInputSelectReadonly();
        break;
      case 'value':
        this._setInputSelectValue(newValue);
        break;
      case 'message':
        this._setInputSelectMessage(newValue);
        break;
    }
  }

  /**
   * Set the input select options based on the account.
   */
  setOptionsForAccount(accountId) {
    // TODO - check if it's okay to cache broker per accountId
    // Requests broker info using the BlbService if store is empty, otherwise get the broker list from store
    return BlbService.getBlbEligibleBrokers(accountId)
      .then(response => {
        this._shouldEnableSelect(response.data && response.data.length);
        const options = this._toInputSelectOptions(response.data);
        this._brokerSelectStore.setData(response.data, 'options');
        this._inputSelect.setOptions(options, true);
      })
      .catch(_ => {
        this._shouldEnableSelect(false);
      });
  }

  _setLabelContent(value) {
    // TODO - set sgx-input-select's label attribute once inline/horizontal label is supported
    this._label.textContent = value || '';
  }

  _setInputSelectReadonly() {
    this._inputSelect.readOnly = this.readonly;
  }

  _setInputSelectMessage(value) {
    this._inputSelect.message = value;
  }

  _setInputSelectValue(value) {
    this._inputSelect.value = value;
  }

  _shouldEnableSelect(enable) {
    const classFn = !enable ? 'remove' : 'add';
    this._inputSelect.disabled = !enable;
    this._emptyDescription.classList[classFn]('sgx-hidden');

    if (!enable) {
      const options = [{
        label: i18n.getTranslation('widget-broker-select.options.not-applicable'),
        value: 'NA'
      }];
      this._brokerSelectStore.setData([], 'options');
      this._inputSelect.setOptions(options, true);
    }
  }

  /**
   * Transforms the array of brokers to input select options format
   *
   * @param {Array<Object>} brokers an array of broker
   * @return {Array<Object>} input select options format
   */
  _toInputSelectOptions(brokers) {
    const options = brokers.map(broker => {
      return {
        label: `${broker.name} - ${broker.id}`,
        value: broker.id
      };
    });
    options.unshift({label: i18n.getTranslation('widget-broker-select.options.placeholder'), value: ''});

    return options;
  }

  _onInputSelectChange() {
    if (this.storeName && StoreRegistry[this.storeName]) {
      StoreRegistry[this.storeName].setData(this.value, 'brokerId');
    }
  }
}

// #region Registeration of the Element

customElements.define('widget-broker-select', withInitDOM(WidgetBrokerSelect));

// #endregion
