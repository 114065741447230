import i18n from '@sgx/sgx-localisation-service';

const constants = {
  DEFAULT_TABLE_HEIGHT: '320px',
  TABLE_TOOLBAR_AND_HEADER_HEIGHT: 38,
  TABLE_ROW_HEIGHT: 32
};

const tableConfig = {
  options: {
    localise: false,
    downloadData: false,
    columnsConfiguration: false,
    sortable: true,
    resizable: true,
    reorderable: false,
    timezone: 'Asia/Singapore',
    autoWidth: true,
    model: 'sgx-data-model'
  },
  layout: {
    desktop: {
      rowHeight: 32, // 64,
      horizontalScrollStep: 96,
      verticalScrollStep: 96,
      showAllRows: false
    },
    mobile: {
      cellPerRow: [2, 2, 3, 3],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    },
    tablet: {
      cellPerRow: [2, 2, 3, 3],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    }
  },
  columns: {
    dateReceived: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('widget-portfolio-rights.table.date-received.label'),
      tooltip: i18n.getTranslation('widget-portfolio-rights.table.date-received.tooltip')
    },
    security: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'link',
        newTab: true,
        className: 'security-link-cell'
      },
      filter: false,
      sort: {
        type: 'text',
        valueKey: 'label'
      },
      label: i18n.getTranslation('widget-portfolio-rights.table.security-name.label'),
      tooltip: i18n.getTranslation('widget-portfolio-rights.table.security-name.tooltip')
    },
    rightsApplied: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'number'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('widget-portfolio-rights.table.rights-shares.label'),
      tooltip: i18n.getTranslation('widget-portfolio-rights.table.rights-shares.tooltip')
    },
    amountPayable: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'dialog'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      dialog: "amount-payable",
      label: i18n.getTranslation('widget-portfolio-rights.table.amount-payable.label'),
      tooltip: i18n.getTranslation('widget-portfolio-rights.table.amount-payable.tooltip')
    },
    referenceNumber: {
      minWidth: 165,
      header: {
        type: 'tooltip'
      },
      cell: {
        type: 'text'
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('widget-portfolio-rights.table.reference-number.label'),
      tooltip_component: i18n.getTranslation('widget-portfolio-rights.table.reference-number.tooltip')
    },
    amountPaid: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'number'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('widget-portfolio-rights.table.amount-paid.label'),
      tooltip: i18n.getTranslation('widget-portfolio-rights.table.amount-paid.tooltip')
    },
    paymentMode: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('widget-portfolio-rights.table.payment-mode.label'),
      tooltip: i18n.getTranslation('widget-portfolio-rights.table.payment-mode.tooltip')
    },
    paymentStatus: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'dialog'
      },
      sort: false,
      filter: false,
      dialog: "payment-status",
      label: i18n.getTranslation('widget-portfolio-rights.table.payment-status.label'),
      tooltip: i18n.getTranslation('widget-portfolio-rights.table.payment-status.tooltip')
    },
    submissionStatus: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('widget-portfolio-rights.table.submission-status.label'),
      tooltip: i18n.getTranslation('widget-portfolio-rights.table.submission-status.tooltip')
    }
  },
  states: {
    activeStateId: 0,
    states: [
      {
        data: {
          order: [
            'dateReceived',
            'security',
            'rightsApplied',
            'amountPayable',
            'referenceNumber',
            'amountPaid',
            'paymentMode',
            'paymentStatus',
            'submissionStatus'
          ],
          columns: {
            dateReceived: {
              show: true
            },
            security: {
              show: true
            },
            rightsApplied: {
              show: true
            },
            amountPayable: {
              show: true
            },
            referenceNumber: {
              show: true
            },
            amountPaid: {
              show: true
            },
            paymentMode: {
              show: true
            },
            paymentStatus: {
              show: true
            },
            submissionStatus: {
              show: true
            }
          },
          filters: {}
        }
      }
    ]
  }
};

export {
  tableConfig,
  constants
};
