/* Dependencies */
import i18n from 'sgx-localisation-service';
import { URLUtils, FetchUtils } from '@sgx/sgx-base-code';
import ConfigService from 'sgx-config-service';

const fetchJSON = FetchUtils.fetchJSON;
const CMS_API_URL = ConfigService.endpoints.CMS_API_URL;
const CMS_VERSION = ConfigService.CMS_VERSION;

const Operations = require('../json/operations.json');
const Instruments = require('../json/instruments.json');
const MediaCentreConstants = require('../json/media-centre.json');
const LanguageCodesConstants = require('../json/language-codes.json');
const MarketUpdatesConstants = require('../json/market-updates.json');

let instance = null;
/**
 * Announcements service searches for Announcements, summaries of Announcements, and a list of companies and securities involved.
 * http://confluence.sgx.com/display/DIGITAL/Company+Announcement+API+-+Specification+V1.0
 * @module cms-service
 * @type {HTMLElement}
 */
class CmsService {
  constructor() {
    if (!instance) {
      instance = this;
    }
    this._mediaCentreMetadata = null;
    return instance;
  }

  /**
   * Fetches a taxonomy of items from the CMS
   *
   * Heirarchy can be determined from the parentCode field of the returned items
   *
   * @param {String} id the id (cms operation) of the taxonomy to fetch
   * @returns {Promise<Array>} returns an array containing taxonomy items
   */
  getTaxonomyData(id) {
    return this._fetchCMS(Operations.TAXONOMY, { vid: id }).then(function(response) {
      const data = flattenCMSData(response && response.data);
      if (data === null || data.message) {
        console.warn('Error: Response timeout');
        return [];
      }
      if (data.taxonomyTermByVocabulary === null || data.taxonomyTermByVocabulary.results === 0) {
        return [];
      }
      return data.taxonomyTermByVocabulary.results;
    });
  }

  /**
   * Fetches listing of market update articles from the CMS
   *
   * @param {Object} [args] an object of optional query parameters that can be appended to the API call
   * @param {String} [args.offset] page number to start the search results on
   * @param {String} [args.limit] size of each page of results
   * @param {String} [args.title] title to filter by
   * @param {Boolean} [args.titleFilterEnabled] set this to true if filtering by title
   * @param {Date} [args.fromDate] date to search from
   * @param {Boolean} [args.fromDateFilterEnabled] set this to true if filtering by fromDate
   * @param {Date} [args.toDate] date to search to
   * @param {Boolean} [args.toDateFilterEnabled] set this to true if filtering by toDate
   * @param {String} [args.category] category to filter by
   * @param {Boolean} [args.categoryFilterEnabled] set this to true if filtering by category
   * @param {Array<String>} [args.assetClass] asset classes to filter by
   * @param {Boolean} [args.assetClassFilterEnabled] set this to true if filtering by assetClass
   * @param {Array<String>} [args.sector] sectors to filter by
   * @param {Boolean} [args.sectorFilterEnabled] set this to true if filtering by sector
   * @param {Array<String>} [args.country] countries to filter by
   * @param {Boolean} [args.countryFilterEnabled] set this to true if filtering by country
   * @param {String} [args.securityCode] security code to filter by
   * @param {Boolean} [args.securityCodeFilterEnabled] set this to true if filtering by security code
   * @returns {Promise<Object>} returns an object containing the listing data
   */
  getMarketUpdatesListingData(args) {
    return this._fetchCMS(Operations.MARKET_UPDATES, args || {});
  }

  /**
   * Fetches the first 8 latest listing of market research.
   *
   * @returns {Promise<Object>} returns an object containing the listing data
   */
  getMarketResearchLatestListingData() {
    return this._fetchCMS(Operations.MARKET_RESEARCH_LATEST, {});
  }

  /**
   * Fetches a market update article from the CMS
   *
   * @param {String} id page number to start the search results on
   * @returns {Promise<Object>} returns an object containing the listing data
   */
  getMarketUpdateById(id) {
    return this._fetchCMS(Operations.MARKET_UPDATES, { id, idFilterEnabled: true });
  }

  /**
   * Fetches listing of media centre articles from the CMS
   *
   * @param {Object} [args] an object of optional query parameters that can be appended to the API call
   * @param {String} [args.offset] page number to start the search results on
   * @param {String} [args.limit] size of each page of results
   * @param {String} [args.title] title to filter by
   * @param {Boolean} [args.titleFilterEnabled] set this to true if filtering by title
   * @param {Date} [args.fromDate] date to search from
   * @param {Boolean} [args.fromDateFilterEnabled] set this to true if filtering by fromDate
   * @param {Date} [args.toDate] date to search to
   * @param {Boolean} [args.toDateFilterEnabled] set this to true if filtering by toDate
   * @param {String} [args.category] category to filter by
   * @param {Boolean} [args.categoryFilterEnabled] set this to true if filtering by category
   * @param {Array<String>} [args.assetClass] asset classes to filter by
   * @param {Boolean} [args.assetClassFilterEnabled] set this to true if filtering by assetClass
   * @param {Array<String>} [args.sector] sectors to filter by
   * @param {Boolean} [args.sectorFilterEnabled] set this to true if filtering by sector
   * @param {Array<String>} [args.country] countries to filter by
   * @param {Boolean} [args.countryFilterEnabled] set this to true if filtering by country
   * @param {String} [args.mediaCentreType] type of media centre to filter by
   * @param {Boolean} [args.mediaCentreTypeFilterEnabled] set this to true if filtering by mediaCentreType
   * @param {Array<String>} [args.newsTopic] news topics to filter by
   * @param {Boolean} [args.newsTopicFilterEnabled] set this to true if filtering by newsTopic
   * @param {Array<String>} [args.speaker] speakers to filter by
   * @param {Boolean} [args.speakerFilterEnabled] set this to true if filtering by speaker
   * @param {String} [args.remarks] remarks to filter by
   * @param {Boolean} [args.remarksFilterEnabled] set this to true if filtering by remarks
   * @returns {Promise<Object>} returns an object containing the listing data
   */
  getMediaCentreListingData(args) {
    return this._fetchCMS(Operations.MEDIA_CENTRE, args || {});
  }

  /**
   * Private method that retrieves Market Updates metadata from CMS
   * For querying of market updates listing
   * @returns {Promise}
   */
  getMarketUpdatesMetadata() {
    if (this._marketUpdatesMetadata) {
      return Promise.resolve(this._marketUpdatesMetadata);
    }
    return Promise.all([
      this.getTaxonomyData(MarketUpdatesConstants.TAXONOMY.CATEGORIES),
      this.getInstrumentsMetadata()
    ]).then(data => {
      this._marketUpdatesMetadata = {categories: {}};
      data[0].forEach(category=> {
        this._marketUpdatesMetadata.categories[category.name] = category.id;
      });
      return Object.assign(this._marketUpdatesMetadata, data[1]);
    });
  }
  /**
   * Private method that retrieves financial instruments - Securities and Derivatives metadata from CMS
   * @returns {Promise}
   */
  getInstrumentsMetadata() {
    if (this._instrumentMetadata) {
      return Promise.resolve(this._instrumentMetadata);
    }
    return Promise.all([
      this.getTaxonomyData(Instruments.SECURITIES.TAXONOMY.CATEGORIES),
      this.getTaxonomyData(Instruments.DERIVATIVES.TAXONOMY.CATEGORIES)
    ]).then(data => {
      this._instrumentMetaData = {
        securities: {},
        derivatives: {}
      };
      data[0].forEach(category => {
        this._instrumentMetaData.securities[category.name] = category.id;
      });
      data[1].forEach(category => {
        this._instrumentMetaData.derivatives[category.name] = category.id;
      });
      return this._instrumentMetaData;
    });
  }
  /**
   * Private method that retrieves Media Centre metadata from CMS
   * @returns {Promise}
   */
  getMediaCentreMetadata() {
    if (this._mediaCentreMetadata) {
      return Promise.resolve(this._mediaCentreMetadata);
    }
    return Promise.all([
      this.getTaxonomyData(MediaCentreConstants.TAXONOMY.CATEGORIES),
      this.getTaxonomyData(MediaCentreConstants.TAXONOMY.NEWS_TOPICS)
    ]).then( data => {
      this._mediaCentreMetadata = { types: {}, topics: {} };
      data[0].forEach(type => {
        this._mediaCentreMetadata.types[type.name] = type.id;
      });
      data[1].forEach(topic => {
        this._mediaCentreMetadata.topics[topic.name] = topic.id;
      });
      return Promise.resolve(this._mediaCentreMetadata);
    });
  }
  /**
   * Private method to fetch data from the CMS API.
   * URL is as follow: '/graphql?queryId=1ve2rs3ion:myquery'
   *
   * @param {String} action - id of the page that will be fetch
   * @param {Object} args - given parameters to the method and added as variables to the url
   * @returns {Promise} - promise with raw CMS data based on query
   */
  _fetchCMS(action, args) {
    var params = args || {};
    var queryContent = CMS_VERSION + ':' + action;

    params.lang = LanguageCodesConstants[params.lang || i18n.getLanguage()];
    return fetchJSON(URLUtils.setQueryParams(CMS_API_URL, {
      queryId: queryContent,
      variables: JSON.stringify(params || '')
    }));
  }

  /**
   * Fetches the carousel data for dashboard.
   *
   * @returns {Promise<Object>} returns an object containing the listing data
   */
   getCarouselListingData() {
    return this._fetchCMS(Operations.DASHBOARD_CAROUSEL, { path: "/investors-portal" });
  }
}

// Flatten object from nested "data" property
function flattenCMSData(o, parent) {
  var object = o;

  for (var key in object) {
    if (!object.hasOwnProperty(key)) continue;

    if (object.hasOwnProperty('data') && Object.keys(object).length === 1) {
      if (object.data == null) {
        console.warn('null "data" object found for', parent);
      } else {
        object = flattenCMSData(object.data, object);
      }
    }
    // recurse through children
    if (typeof object[key] === 'object') {
      object[key] = flattenCMSData(object[key], object);
    }
  }

  return object;
}

/* Export the singleton */
const cmsServiceInstance = new CmsService();
export default cmsServiceInstance;
