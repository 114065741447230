import ConfigService from '@sgx/sgx-config-service';
import BaseService from "services/base-service";
import DateService from 'sgx-date-time-service';


class StopPaperService extends BaseService {


  /**
   * Gets the utils shared instance.
   * @return {StopPaperService} the utils shared instance
   */
  static get instance() {
    if (!StopPaperService._instance) {
      StopPaperService._instance = new StopPaperService();
    }
    return StopPaperService._instance;
  }

  getPaperSubscriptionStatus({accountId}) {
    const {token} = this._session;

    return this._fetchText(ConfigService.endpoints.STOP_PAPER_NOTIFICATION, {
      headers: {
        [this._requestHeaders.accountId]: accountId,
        [this._requestHeaders.authToken]: token
      }
    })
      .then(response => response.json())
      .then(response => {
        const {data, meta} = response;
        const details = data.details || [];
        const subscribedNotif = details.find(detail => detail.accountNotificationStatus === '1');
        if (subscribedNotif) {
          return Promise.resolve({
            status: DateService(subscribedNotif.endDateOfSubscription).format('X') >= DateService(meta.timestamp).startOf('day').format('X'),
            endDate: subscribedNotif.endDateOfSubscription,
            notificationSubscribeMode: subscribedNotif.notificationSubscribeMode
          });
        }

        return Promise.resolve({
          status: false,
          endDate: null,
          notificationSubscribeMode: null
        });
      });
  }
}

export default StopPaperService.instance;

