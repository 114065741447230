import SgxTableCellBase from 'sgx-table/src/sub-components/sgx-table-cell/sgx-table-cell-base';
import { Big } from 'utils/big-number-util';

const smallHyphen = '\ufe63';

class SgxTableCellBig extends SgxTableCellBase {
  constructor() {
    super();
    this.defaultConfig = {
      addSign: false,
      formatDecimals: true,
      formatThousands: true,
      decimalPlaces: 2,
      padZeros: false,
      replaceZero: true,
      replaceEmpty: true,
      defaultValue: smallHyphen
    };
  }

  setConfig(config, columnConfig, tableConfig) {
    this._config = Object.assign({}, this.defaultConfig, config || {});
    // lone dashes cause IE to crash unexpectedly
    if (this._config.defaultValue === '-') {
      console.warn('sgx-table-cell-number: Do not use single "-" for default value, as that will cause IE to crash unexpectedly.');
      this._config.defaultValue = smallHyphen;
    }
  }

  setData(data, action) {
    let displayText = data == null ? '' : data;
    const decimalPlaces = this._config.padZeros ? this._config.decimalPlaces : null;

    if ((this._config.replaceEmpty && displayText === '')
      || (this._config.replaceZero && displayText === 0)) {
      displayText = this._config.defaultValue;
      this.textContent = displayText;
      return;
    }

    try {
      if (this._config.formatDecimals) {
        displayText = Big(displayText).toFixed(decimalPlaces);
      }

      if (this._config.formatThousands) {
        displayText = Big(displayText).toFormat(decimalPlaces);
      }
    } catch(e) {
      // value is not a number and should just display the string as it is
    }

    if (this._config.addSign) {
      displayText = (data > 0 ? '+' : '') + displayText;
    }

    // IE11 prints "undefined" when setting textContent to undefined
    if (typeof displayText === 'undefined') {
      displayText = '';
    }

    this.textContent = displayText;
  }
}

customElements.define('sgx-table-cell-big-number', SgxTableCellBig);
export default SgxTableCellBig;
