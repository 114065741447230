module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-tax-residency-fieldsets\">\n  <div class=\"widget-tax-residency-fieldsets--header\">\n    <h3 data-i18n=\"app.tax-residency-fieldsets.title\"></h3>\n    <button type=\"button\" class=\"widget-tax-residency-reset-button sgx-button sgx-hidden\" data-i18n=\"app.tax-residency-fieldsets.reset\"></button>\n  </div>\n  <div class=\"sgx-base-text-small sgx-input-label widget-tax-residency-fieldsets--desc\" data-i18n=\"app.tax-residency-fieldsets.desc\"></div>\n  <sgx-alert-bar class=\"widget-tax-residency-fieldsets--indicator-warning sgx-hidden\"></sgx-alert-bar>\n  <sgx-alert-bar class=\"widget-tax-residency-fieldsets--indicator sgx-hidden\"></sgx-alert-bar>\n  <div class=\"widget-tax-residency-fieldsets--wrapper\">\n    <widget-tax-residency data-index=\"1\"></widget-tax-residency>\n    <widget-tax-residency class=\"sgx-hidden\" data-index=\"2\"></widget-tax-residency>\n    <widget-tax-residency class=\"sgx-hidden\" data-index=\"3\"></widget-tax-residency>\n    <widget-tax-residency class=\"sgx-hidden\" data-index=\"4\"></widget-tax-residency>\n    <widget-tax-residency class=\"sgx-hidden\" data-index=\"5\"></widget-tax-residency>\n  </div>\n  <div class=\"widget-tax-residency-fieldsets--footer\">\n    <div class=\"widget-tax-residency-prompt-content\" data-i18n=\"app.tax-residency-fieldsets.liability\"></div>\n    <div class=\"widget-tax-residency-fieldsets--footer-actions\">\n      <button type=\"button\" class=\"widget-tax-residency-prompt-button sgx-button--link\" data-i18n=\"app.tax-residency-fieldsets.add\"></button>\n    </div>\n  </div>\n  </template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()