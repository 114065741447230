import {withInitDOM} from 'sgx-base-code';
import SGXIPOService from 'sgx-ipo-service';
import MetadataService from 'sgx-metadata-service';
import i18n from 'sgx-localisation-service';
import tmpl from "./widget-security-details-ipo-performance.html";

class WidgetSecurityDetailsIpoPerformance extends HTMLElement {

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-ipo-performance');
    this._table = this.querySelector('sgx-content-table');
    this._loading = this.querySelector('.w-security-details-status-indicator');
  }

  connectedCallback() {
  }


  async setData(data) {
    this._data = data;
    const metaData = await MetadataService.getMetaDataByStockCode(data.nc);
    if (!metaData) {
      // hide the loading spinner
      this._loading.hide();
      return;
    }

    let ipoPerformanceData = await SGXIPOService.getIpoPerformance({companyname: metaData.fullName});

    // if (ipoPerformanceData.length !== 0) {
      this.dispatchEvent(new CustomEvent('widget-security-details-ipo-performance', {
        bubbles: true
      }))
    // }

    // hide the loading spinner
    this._loading.hide();
    this._table.setConfig({
      hideHeader: 1,
      columns: [{isColumnHeader: 1}]
    })

    if (!ipoPerformanceData || (ipoPerformanceData && !ipoPerformanceData[0])) {
      this._loading.show({ status: 'neutral', title: i18n.getTranslation('app.message.no-data') });
      return;
    }

    ipoPerformanceData = ipoPerformanceData[0];


    this._table.setData([
      [
        i18n.getTranslation('widget-security-details-ipo-performance.labels.first_day_closing_price'),
        ipoPerformanceData.first_day_closing_price
      ],
      [
        i18n.getTranslation('widget-security-details-ipo-performance.labels.prev_closing_price'),
        ipoPerformanceData.prev_closing_price
      ],
      [
        i18n.getTranslation('widget-security-details-ipo-performance.labels.prev_market_cap'),
        ipoPerformanceData.prev_market_cap
      ]
    ])
  }
}

customElements.define('widget-security-details-ipo-performance', withInitDOM(WidgetSecurityDetailsIpoPerformance));


