import i18n from 'sgx-localisation-service';

// TODO - Retrieve the categories from taxonomy terms company announcements API
const FILTER_CONFIG = {
  'hideOperator': true,
  'editable': false,
  'columns': {
    'name': {
      'label': i18n.getTranslation('app.corporate-actions.filter-labels.name'),
      'type': 'text',
      'method': 'contains'
    },
    'exDate': {
      'label': i18n.getTranslation('app.corporate-actions.filter-labels.ex-date'),
      'type': 'date',
      'selection': 'day',
      'format': 'DD/MM/YYYY'
    },
    'datePaid': {
      'label': i18n.getTranslation('app.corporate-actions.filter-labels.paid-date'),
      'type': 'date',
      'selection': 'day',
      'format': 'DD/MM/YYYY'
    },
    'recDate': {
      'label': i18n.getTranslation('app.corporate-actions.filter-labels.rec-date'),
      'type': 'date',
      'selection': 'day',
      'format': 'DD/MM/YYYY'
    },
    'cat': {
      'label': i18n.getTranslation('app.corporate-actions.filter-labels.types'),
      'type': 'select',
      'multiple': true,
      'autocomplete': false,
      'options': [],
      'strictSelectAll': true
    }
  }
};

const FILTER_STATE = {
  'operator': 'and',
  'conditions': [
    {
      'columnId': 'name',
      'type': 'text',
      'method': 'equals',
      'value': null,
      'valid': false
    },
    {
      'columnId': 'cat',
      'type': 'text',
      'method': 'equals',
      'value': null,
      'valid': false
    },
    {
      'columnId': 'exDate',
      'type': 'date',
      'method': 'between',
      'value': {
        'fromDate': null,
        'toDate': null
      },
      'valid': false
    },
    {
      'columnId': 'datePaid',
      'type': 'date',
      'method': 'between',
      'value': {
        'fromDate': null,
        'toDate': null
      },
      'valid': false
    },
    {
      'columnId': 'recDate',
      'type': 'date',
      'method': 'between',
      'value': {
        'fromDate': null,
        'toDate': null
      },
      'valid': false
    }
  ]
};

export {
  FILTER_CONFIG,
  FILTER_STATE
};
