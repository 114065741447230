import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DateUtil from 'utils/date-util';
import tmpl from './cmp-list-row-market-research.html';

/**
 * Market updates row summary within a list.
 */
class CmpListRowMarketUpdates extends HTMLElement {
  constructor() {
    super();
  }
  
  connectedCallback() {}

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._date = this.querySelector('.cmp-list-row-market-research__date');
    this._title = this.querySelector('.cmp-list-row-market-research__column__title');
    this._tags = this.querySelector('.cmp-list-row-market-research-container');
  }

  setConfig(config) {
  }

  setState(state) {
  }

  setData(market) {
    this._market = market;
    this.dataset.id = market.id;
    this._render();
  }

  getData() {
    return this._market;
  }

  updateData(data) {
    this.setData(data);
  }

  _renderTag(tagName) {
    const tag = document.createElement('span');
    tag.classList.add('cmp-list-row-market-research__tag');
    tag.textContent = tagName;
    return tag;
  }

  _render() {
    this._tags.innerHTML = '';
    const {dateArticle, title, assetClassNames, category} = this._market;
    const sector = category.data.data.name;
    const frag = document.createDocumentFragment();
    if (sector) {
      const tag = this._renderTag(sector);
      tag.dataset.type = 'sector';
      frag.appendChild(tag);
    }
    assetClassNames.forEach((label, index) => {
      if (index > 1) {
        return;
      } // we only want to show 3 tags max
      const tag = this._renderTag(label);
      tag.dataset.type = 'assetClass';
      if (index > 0) {
        tag.classList.add('hidden-xxs-down');
      }
      frag.appendChild(tag);
    });

    this._tags.appendChild(frag);
    this._date.textContent = DateUtil.formatDateTo(dateArticle, 'X', i18n.getTranslation('app.market-research.list-details.row.date-format'));
    this._title.textContent = title;
  }

  setSelected(selected) {
    if (selected) {
      this.setAttribute('selected', '');
    } else if (this.hasAttribute('selected')) {
      this.removeAttribute('selected');
    }
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('cmp-list-row-market-research', withInitDOM(CmpListRowMarketUpdates));
