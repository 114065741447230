import SimpleStore from './simple-store';
import { PersistedStore, PersistedStoreMode } from './persisted-store';

class StoreRegistry {

  /**
   * List of all simple and persisted stores across the entire app
   * @return {StoreRegistry}
   */
  static get instance() {
    if (!StoreRegistry._instance) {
      StoreRegistry._instance = new StoreRegistry();
    }
    return StoreRegistry._instance;
  }

  // Local Storage -----------------------------------------

  /**
   * Store for the CDP session token
   * @prop {Object} data The data in the store
   * @prop {string} data.token The CDP session token
   * @prop {string} data.userId
   * @prop {boolean} data.pref2FaSms is sms preferred method
   * @prop {boolean} data.pref2FaMobile is 2FA preferred method
   * @prop {string} data.authorizedUser authorized signatory name
   * @prop {number} data.idType id type 1=nric, 2=passport or 3=corporate
   * @prop {string} data.updateParticularMode is myinfo user particulars
   */
  get cdpSession() { // TODO - Discuss how cdp session store relates to permissions store
    const session = PersistedStore.get('cdp-session', PersistedStoreMode.session);
    session.setAutoSave(true);

    return session;
  }

  /**
   * Stores permissions for screens throughout the app
   */
  get permissions() {
    const permissions = PersistedStore.get('permissions', PersistedStoreMode.session);
    permissions.setAutoSave(true);

    return permissions;
  }

  /**
   * Stores values that describe the context the app is running in.
   * @prop {Object} data The data in the store
   * @prop {Boolean} data.webview If the app is running in a native app web view
   */
  get appContext() {
    return PersistedStore.get('context', PersistedStoreMode.session);
  }

  /**
   * Stores account registration code
   * @prop {Object} data The data in the store
   * @prop {String} data.code email verification code
   */
  get accountRegistration() {
    return PersistedStore.get('account-registration', PersistedStoreMode.session);
  }

  /**
   * Stores user preferences such as language and theme
   * @prop {Object} data The data in the store
   * @prop {String} data.theme The preferred theme, e.g. dark or light
   * @prop {String} data.lang The preferred language, e.g. en or cn
   */
  get appSettings() {
    return PersistedStore.get('preferences');
  }

  /**
   * Configuration for the market today dashboard widget
   * @prop {Object} data The data in the store
   * @prop {String} data.id The market code of the index (STI)
  */
  get dashboardEquity() {
    return PersistedStore.get('dashboard-equity');
  }

  /**
   * Configuration for the market performance dashboard widget
   * @prop {Object} data The data in the store
   * @prop {String} data.attribute Attribute for chart market config
   * @prop {String} data.criteria Config for the widget
   */
  get dashboardToday() {
    return PersistedStore.get('dashboard-today');
  }

  /**
   * Stores path to redirect to on successful SingPass login.
   * @prop {Object} data
   * @prop {string} data.caller the path to redirect back to
   * @prop {string} data.loginSource loginSource (IP, SINGPASS). IP means QR code and SINGPASS means redirect link
   */
  get singpassRedirect() {
    const permissions = PersistedStore.get('singpass-redirect');
    permissions.setAutoSave(true);
    return permissions;
  }

  /**
   * Stores related info to fin registration
   * @prop {Object} data
   * @prop {string} data.isRegisterFin identifier if fin registration request has been triggered
   */
  get registerFin() {
    const permissions = PersistedStore.get('register-fin');
    permissions.setAutoSave(true);
    return permissions;
  }

  // Session Storage -----------------------------------------

  /**
   * Stores maintenance alerts for the login page
   * @prop {Array} alerts the maintenance alerts, if any
   */
  get maintenance() {
    const maintenance = PersistedStore.get('maintenance', PersistedStoreMode.session);
    maintenance.setAutoSave(true);
    return maintenance;
  }

  /**
   * Stores the singpass session
   * @prop {Object} data The data in the store
   * @prop {String} data.authToken singpass auth token from the initiate-session
   */
  get singpassSession() {
    const session = PersistedStore.get('singpass-session', PersistedStoreMode.session);
    session.setAutoSave(true);
    return session;
  }

  /**
   * Stores non-sensitive user data for reference in the session
   * @prop {Object} data The data in the store
   * @prop {Array} data.name The user's name once authenticated
   */
  get user() {
    return PersistedStore.get('user', PersistedStoreMode.session);
  }

  /**
   * Internal store for widget-account-select
   * @prop {Object} data The data in the store
   * @prop {Array} data.options List of account objects from user service
   */
  get accountSelect() {
    return PersistedStore.get('account-select', PersistedStoreMode.session);
  }

  /**
   * Internal store for widget-broker-select
   * @prop {Object} data The data in the store
   * @prop {Array} data.options List of brokers objects from broker service
   */
  get brokerSelect() {
    return PersistedStore.get('broker-select', PersistedStoreMode.session);
  }

  /**
   * Portfolio-specific store for selected account
   * @prop {Object} data The data in the store
   * @prop {String} data.accountId The selected accountId
   */
  get portfolioAccount() {
    return PersistedStore.get('portfolio-account', PersistedStoreMode.session);
  }

  /**
   * Same as portfolioAccount but for dashboard widget
   */
  get portfolioAccountDashboard() {
    return PersistedStore.get('portfolio-account-dashboard', PersistedStoreMode.session);
  }

  /**
   * Portfolio-specific store for transfer securities
   * @prop {Object} data The data in the store
   * @prop {String} data.accountId The selected accountId (transfer from)
   * @prop {String} data.brokerId The selected brokerId (transfer to)
   */
  get portfolioTransferSecurities() {
    return PersistedStore.get('portfolio-transfer-securities', PersistedStoreMode.session);
  }

  /**
   * Settings-specific store for selected account
   * @prop {Object} data The data in the store
   * @prop {String} data.accountId The selected accountId
   */
  get settingsAccount() {
    return PersistedStore.get('settings-account', PersistedStoreMode.session);
  }

  /**
   * Contains dcs and ccy list
   * @prop {Object} data The data in the store
   * @prop {String} data.dcs dcs list
   * @prop {String} data.ccy ccy list
   */
  get dcsDisplay() {
    const permissions = PersistedStore.get('dcs-display', PersistedStoreMode.session);
    permissions.setAutoSave(true);

    return permissions;
  }

    /**
   * Stores form data for user particulars
   * @prop {Object} data The data in the store
   * @prop {Array} data.addresses Address objects
   * @prop {Array} data.contacts Phone number objects
   * @prop {String} data.email Email
   * @prop {String} data.fullName Full name
   * @prop {Object} data.signatoryMobilePhone User signatory details
   * @prop {String} data.signatoryName User signatory name
   * @prop {Object} data._control Controls for the form
   */
  get settingsUser() {
    const settingsUser = PersistedStore.get('settings-user', PersistedStoreMode.session);
    settingsUser.setAutoSave(true);

    return settingsUser;
  }

  get initialUserData() {
    const initialUserData = PersistedStore.get('initial-user-data', PersistedStoreMode.session);
    initialUserData.setAutoSave(true);

    return initialUserData;
  }

  // In-memory Storage -----------------------------------------

  /**
   * Stores form data for adding a DCS bank account
   * @prop {Object} data The data in the Store
   * @prop {String} data.bankId The bank acronym
   * @prop {String} data.bankAccountNo The bank account number
   * @prop {Boolean} data.accountTerms The terms checkbox
   */
  get settingsAccountBankDialog() {
    return SimpleStore.get('settings-account-bank-dialog');
  }

  /**
   * Stores form data for adding a BLB (Broker Linked Balance)
   * @prop {Object} data The data in the Store
   * @prop {String} data.name The name of the broker
   * @prop {String} data.accountNumber The CDP account number
   * @prop {String} data.idNumber The id of the broker
   * @prop {String} data.brokerName The name of the broker
   * @prop {Boolean} data.terms The terms checkbox
   */
  get settingsAccountTradingDialog() {
    return SimpleStore.get('settings-account-trading-dialog');
  }

  get settingsPasswordDialog() {
    return SimpleStore.get('settings-password-dialog');
  }

  /**
   * Stores form data object for notifications preferences
   * @prop {Object} data The data in the store
   * @prop {Boolean} data.acctStatementNotificationOption Preference for account statements via email
   * @prop {Boolean} data.confirmationNotificationOption Preference for CDP notifications via email
   * @prop {Boolean} data.emailSettlementNotifications Preference for settlement notifications via email
   * @prop {Boolean} data.marketingMaterialsIndicator Preference for marketing materials
   * @prop {Boolean} data.psnServiceStatus Preference for settlement notifications via sms
   */
  get settingsNotifications() {
    return SimpleStore.get('settings-notifications');
  }

  /**
   * Stores optional section of a page.
   * @prop {Object} data The data in the store
   * @prop {String} data.section The section of the page
   */
  get pageHeaderNavigation() {
    return SimpleStore.get('page-header-navigation');
  }

  /**
   * Stores header open-close state.
   * @prop {Object} data The data in the store
   * @prop {String} data.section The section of the page
   */
  get menuState() {
    const menuState = PersistedStore.get('menu-state');
    menuState.setAutoSave(true);
    return menuState;
  }

  /**
   * Stores myInfoSession State.
   * @prop {Object} data The data in the store
   */
  get myInfoSession() {
    const session = PersistedStore.get('myinfo-session', PersistedStoreMode.session);
    session.setAutoSave(true);
    return session;
  }
}

export default StoreRegistry.instance;
