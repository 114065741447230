import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-user-actions.html';
import i18n from 'sgx-localisation-service';
import ConfigService from 'sgx-config-service';
import UserService from 'services/user-service';
import StoreRegistry from 'stores/store-registry';

const {ACCOUNT_CATEGORY, ACCOUNT_STATUS, ACCOUNT_STATES} = UserService.constants;

/**
 * @deprecated since version 1.3
 */
class WidgetUserActions extends HTMLElement {
  constructor() {
    super();

    this.setStatus = this.setStatus.bind(this);
    this._updateStatus = this._updateStatus.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-user-actions');
    this._statusContainer = this.querySelector('.widget-user-status-container');
    this._status = this.querySelector('.widget-user-status');
    this._icon = this.querySelector('.widget-user-icon');
    this._corporateNote = this.querySelector('.widget-user-corporate-note');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._widgetSubmitBtn = this.querySelector('.widget-user-button-submit');
    this._widgetUserContainer = this.querySelector('.widget-user-container');
  }

  connectedCallback() {
    this._statusContainer.style.display = 'none';
    this._indicator.hide();
    this._updateSubmitState();
  }

  _updateSubmitState() {
    const readOnlyAccountTypes = [ACCOUNT_STATES.CORPORATE, ACCOUNT_STATES.CORPORATE_TRUSTEE];

    StoreRegistry.cdpSession
      .subscribe((account) => {
        if (account.hardTokenInfo && typeof account.hardTokenInfo === 'string') {
          account.hardTokenInfo = JSON.parse(account.hardTokenInfo);
        }
        if (account.hardTokenInfo && account.hardTokenInfo.linkedHardTokenSerialNumber === null || (readOnlyAccountTypes.includes(account.accountType))) {
          this._widgetSubmitBtn.classList.add('sgx-hidden');
        } else {
          this._widgetSubmitBtn.classList.remove('sgx-hidden');
        }
        this._adjustContainerPadding();
      });
  }

  disconnectedCallback() {
  }

  _updateStatus(e) {
    if (['200', '201'].indexOf('' + e.detail.status) !== -1) {
      this._widgetSubmitBtn.classList.remove('sgx-hidden');
    }
    this.setStatus(e.detail && e.detail.status, true);
  }

  setData(account) {
    // If account is corporate, display note
    if (account[0].accountCategory === ACCOUNT_CATEGORY.CORPORATE) {
      this._corporateNote.innerHTML = i18n.getTranslation('app.widget-settings-user-actions.corporate-note_html');
      this._corporateNote.style.marginBottom = '8px';
    }

    // If account suspended, set status as such
    if (account[0].accountStatus === ACCOUNT_STATUS.SUSPENDED) {
      this.setStatus('401');
    }

    this._indicator.hide();
  }

  /**
   * Shows a loading status
   */
  showLoading() {
    this._indicator.show({status: 'loading'});
  }

  /**
   * Set the status of the update response.
   * @module widget-settings-user-actions
   * @type {HTMLElement}
   * @param code The http response code
   * @param hasTokenStatus Boolean to set type of status to token
   */
  setStatus(code, hasTokenStatus) {

    if (code) {
      // Hide status indicator
      this._indicator.hide();

      // Show status message and icon
      this._statusContainer.style.display = 'flex';
    }

    // Determine status message
    switch (code) {
      case '200':
      case '201':
        this._displayStatus('success', hasTokenStatus);
        break;
      case '202':
        this._displayStatus('pending');
        break;
      case '401':
        this._displayStatus('suspended');
        break;
      case '403':
        this._displayStatus('unauthorized');
        break;
      default:
        this._displayStatus('failed');
        break;
    }
  }

  /**
   * Helper function to display update status.
   * @module widget-settings-user-actions
   * @type {HTMLElement}
   * @param type The type of status update
   * @param hasTokenStatus Boolean to set type of status to token
   */
  _displayStatus(type, hasTokenStatus) {

    // Reset classes to default state
    this._resetClasses();

    // Set status text and icon
    this._status.textContent = hasTokenStatus ? i18n.getTranslation(`app.widget-settings-user-actions.token.status.${type}`) : i18n.getTranslation(`app.widget-settings-user-actions.status.${type}`);
    this._icon.classList.add(`widget-user-icon--${type}`);

    // Set red text for failed states
    if (type === 'suspended' || type === 'unauthorized' || type === 'failed') {
      this._status.classList.add('widget-user-status--red');
    }

    // Set timeout for all but suspended states
    if (type !== 'suspended') {
      setTimeout(() => this._statusContainer.style.display = 'none', ConfigService.env.timeout.settings);
    }

    this._adjustContainerPadding();
  }

  /**
   * Helper function to reset classes of an element to its default state.
   * @module widget-settings-user-actions
   * @type {HTMLElement}
   */
  _resetClasses() {
    const elements = [
      {
        name: 'icon',
        classList: this._icon.classList
      },
      {
        name: 'status',
        classList: this._status.classList
      }
    ];

    elements.forEach(element => {
      element.classList.forEach(item => {
        if (item.startsWith(`widget-user-${element.name}--`)) {
          element.classList.remove(item);
        }
      })
    })
  }

  _adjustContainerPadding() {
    this._widgetUserContainer.classList.add('widget-user-container--padding');
  }
}

customElements.define('widget-settings-user-actions', withInitDOM(WidgetUserActions));
