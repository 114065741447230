/* Dependencies */
import CmsService from 'services/sgx-cms-service';
import ConfigService from 'sgx-config-service';
import DateService from 'sgx-date-time-service';
import AnnouncementsService from '@sgx/sgx-announcements-service';
import DefaultAnnouncementsCategories from '../json/announcements-taxonomy.json';

let instance = null;
/**
 * Company announcements related services aggregator.
 * @module company-announcements-aggregator
 * @type {HTMLElement}
 */
class CompanyAnnouncementsAggregator {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Fetches the taxonomy of all available Announcements
   *
   * @returns {Promise<Object>} returns an object containing the found Announcements
   */
  getCompanyAnnouncementsCategories() {
    if (this._categories) {
      return Promise.resolve(this._categories);
    }
    return CmsService.getTaxonomyData('company_announcements_categories')
      .then(categories => {
        if (!categories) {
          console.warn('Could not retrieve company announcements taxonomy, reverting to default ones');
          return DefaultAnnouncementsCategories;
        }
        this._categories = categories.reduce((map, category) => {
          map[category.code] = category;
          return map;
        }, {});
        return this._categories;
      })
      .catch(err => {
        console.error('Could not retrieve company announcements taxonomy, reverting to default ones', err);
        return DefaultAnnouncementsCategories;
      });
  }

  /**
   * Gets company annoucements.
   * @param {Object} config the configuration passed to the underlying [AnnouncementsService.getAnnouncementsAndCount] service.
   * @param {Object} [config.pageStart] optional - page start
   * @param {Object} [config.pageSize] optional - page size
   * @param {Object} [config.from] optional - date range start
   * @param {Object} [config.to] optional - date range end
   * @param {Object} [config.searchType] optional - type of the search, e.g. 'securitycode'
   * @param {Object} [config.searchValue] optional - value of the search
   * @return {Promise<Object>} returns an object with 'count', total number of existing announcements, and 'data', the company announcements
   */
  getAnnouncementsAndCount(config) {
    return Promise.all([this.getCompanyAnnouncementsCategories(), AnnouncementsService.getAnnouncementsAndCount(config)]).then(response => {
      const taxonomy = response[0];
      const count = response[1][0];
      const data = response[1][1].map(announcement => {
        // Some announcements might not have categories at all
        announcement.category_name_parent = announcement.cat ? taxonomy[announcement.cat].name : '';
        return announcement;
      });
      return { count, data };
    });
  }

  // Validate from date and return a default value if invalid
  getValidatedFromDate(date, useLocalTime, adjustByYears) {
    return this.getValidatedDate(date, useLocalTime, adjustByYears, false);
  }

  // Validate to date and return a default value if invalid
  getValidatedToDate(date, useLocalTime, adjustByYears) {
    return this.getValidatedDate(date, useLocalTime, adjustByYears, true);
  }

  getValidatedDate(date, useLocalTime, adjustByYears, isToDate) {
    const timezone = ConfigService.env.timezone;
    var newDate = DateService.tz(date ? date : null, timezone);

    if (!newDate.isValid()) {
      newDate = DateService.tz(timezone);

      if (adjustByYears) {
        newDate.add(adjustByYears, 'Y');
      } else if (!isToDate) {
        newDate.startOf('year');
      }
    }

    if (isToDate) {
      newDate.endOf('day');
    } else {
      newDate.startOf('day');
    }

    if (!useLocalTime) {
      newDate.utc();
      if (!isToDate) {
        var minDate = DateService.tz(timezone).startOf('y').add(adjustByYears, 'Y').startOf('day');
        if (newDate.isSameOrBefore(minDate)) {
          newDate = minDate;
        }
      }
    }

    return newDate;
  }
}

/* Export the singleton */
const companyAnnouncementsAggregatorInstance = new CompanyAnnouncementsAggregator();
export default companyAnnouncementsAggregatorInstance;
