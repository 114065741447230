import i18n from '@sgx/sgx-localisation-service';
import DateService from 'sgx-date-time-service';

const constants = {
  FULL_DATE_FORMAT: i18n.getTranslation('app.shared-text.date-format-full-date'),
  EXPORT_NAME: 'PresettlementNotifications',
  DEFAULT_TABLE_HEIGHT: '320px',
  TABLE_TOOLBAR_AND_HEADER_HEIGHT: 86,
  TABLE_ROW_HEIGHT: 32
}

const tableConfig = {
  options: {
    localise: true,
    downloadData: false,
    columnsConfiguration: false,
    sortable: true,
    resizable: true,
    reorderable: false,
    timezone: 'Asia/Singapore',
    autoWidth: true,
    exportDataFileName: constants.EXPORT_NAME
  },
  layout: {
    desktop: {
      type: 'infinite',
      rowHeight: 32,
      horizontalScrollStep: 96,
      verticalScrollStep: 96,
      scrollbar: true,
      showAllRows: false
    },
    mobile: {
      cellPerRow: [2, 2, 2, 2],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    },
    tablet: {
      cellPerRow: [2, 2, 2, 2],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    }
  },
  columns: {
    rowNumber: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'number'
      },
      sort: false,
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-statements.table.no.label'),
      export: false
    },
    securityName: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-statements.table.security-name.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-statements.table.security-name.tooltip')
    },
    tradeAllocationStatusDesc: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-statements.table.status.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-statements.table.status.tooltip')
    },
    tradeDate: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'date',
        format: constants.FULL_DATE_FORMAT,
        defaultValue: 'N/A'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-statements.table.trade-date.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-statements.table.trade-date.tooltip'),
      export: value => value && DateService(value).format(constants.FULL_DATE_FORMAT) || 'N/A'
    },
    tradeSideDesc: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-statements.table.trade-side.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-statements.table.trade-side.tooltip')
    },
    quantity: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        replaceZero: false,
        defaultValue: 0
      },
      sort: {
        type: 'number'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-statements.table.quantity.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-statements.table.quantity.tooltip')
    },
    settlementDate: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'date',
        format: constants.FULL_DATE_FORMAT,
        defaultValue: 'N/A'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-statements.table.settlement-date.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-statements.table.settlement-date.tooltip'),
      export: value => value && DateService(value).format(constants.FULL_DATE_FORMAT) || 'N/A'
    },
    brokerName: {
      minWidth: 40,
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      sort: {
        type: 'text'
      },
      filter: false,
      label: i18n.getTranslation('app.widget-portfolio-statements.table.broker-name.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-statements.table.broker-name.tooltip')
    }
  },
  states: {
    activeStateId: 0,
    states: [
      {
        data: {
          order: ['rowNumber', 'securityName', 'tradeAllocationStatusDesc', 'tradeDate', 'tradeSideDesc', 'quantity', 'settlementDate', 'brokerName'],
          columns: {
            rowNumber: {
              show: true
            },
            securityName: {
              show: true
            },
            tradeAllocationStatusDesc: {
              show: true
            },
            tradeDate: {
              show: true
            },
            tradeSideDesc: {
              show: true
            },
            quantity: {
              show: true
            },
            settlementDate: {
              show: true
            },
            brokerName: {
              show: true
            }
          },
          filters: {},
          sorts: {
            settlementDate: 'asc'
          }
        }
      }
    ]
  },
  decorators: {
    rows: [
      {
        name: 'row-number',
        config: {
          cellToDecorate: ['rowNumber']
        }
      }
    ]
  }
};

export {
  tableConfig,
  constants
};
