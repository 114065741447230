import i18n from '@sgx/sgx-localisation-service';
import DeviceService from 'sgx-device-service';
import { rangeFilterKeys, formatFilterData, createRangeConfig  } from './widget-stock-screener-filters-helper';

export const DIALOG_CONFIG = {
  title: i18n.getTranslation('app.widget-stock-screener.toolbar.filter-dialog.title_html'),
  hideOverlay: true,
  isModal: true,
  minHeight: DeviceService.isMobile() || DeviceService.isTablet() ? '80vh' : '100vh',
  position: {
    target: document.body,
    attachment: 'top right',
    targetAttachment: 'top right'
  }
};

export const MORE_ACTIONS_CONFIG = [
  {
    label: i18n.getTranslation('sgx-table.toolbar.action-download'),
    id: 'download'
  },
  {
    label: i18n.getTranslation('sgx-table.toolbar.action-customise'),
    id: 'customize'
  }
];

export const createFilterConfig = (stockData) => {

  const { rangeValuesMap, sectorsList } = formatFilterData(stockData);
  // Sort the sector options and add a default value
  const sectorFilter = {
    'label': i18n.getTranslation('app.widget-stock-screener.table.sector.label'),
    'type': 'select',
    'method': 'contains',
    'toggle': false,
    'multiple': true,
    'autocomplete': true,
    'options': sectorsList.sort((a, b) => a.localeCompare(b))
      .reduce((result, item) => {
        if (item) {
          result.push({
            value: item,
            label: item
          });
        }
        return result;
    },[])
  };

  const filterConfig =  {
    'editable': false,
    'autoApply': true,
    'hideOperator' : true,
    'hideMethod': true,
    'compact': true,
    'hideRowCount': !DeviceService.isMobile(),
    'columns': {sector: sectorFilter},
    'date': { 'format': 'DD MM YYYY' }
  };

  rangeFilterKeys.forEach(key => {
    filterConfig.columns[key] = {
      label: i18n.getTranslation(`app.widget-stock-screener.table.${key}.label`),
      type: 'range',
      toggle: false,
      // start: [],
      sliderConfig: {
        tooltips: [{
          from: value => formatNumberToString(value),
          to: value => formatNumberToString(value),
        }, {
          from: value => formatNumberToString(value),
          to: value => formatNumberToString(value),
        }],
        ...createRangeConfig(key, rangeValuesMap[key])
      }
    };
  });
  return filterConfig;
}

export const createInitFilterState = () => {
  return {filters: {
    'operator': 'and',
    'conditions': ['sector', ...rangeFilterKeys].map(key => {
      return {'columnId': key};
    })
  }};
}


/**
 * @param {Number} value
 * @desc: Format the passed number to 2 decimal places, and add comma's
 * */
function formatNumberToString(value) {
  return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
