// TODO - remove stubs when all API are integrated
import StoreRegistry from 'stores/store-registry';
import BaseService from 'services/base-service';
import * as constants from './user-service-constants';
import { FetchUtils } from 'sgx-base-code';

let instance;

/**
 * User service responsible for moderating user permissions,
 * particulars and account info.
 * @module user-service
 */
class UserService extends BaseService {

  /**
   * Get the constants associated to the user service
   */
  get constants() {
    return constants;
  }

  /**
   * Get user account info.
   * @module user-service
   * @return {Promise<Object>} Returns an object containing request status and data.
   * Data is returned as an array as there are two types of "user" instances:
   *  - Individual/Joint account user
   *  - Corporate account user
   */
  getUserAccounts() {
    return this._authFetch(this._endpoints.USER_ACCOUNTS_READ)
      .then(response => {
        if (response && response.data) {
          this.updateCDPSessionAccountType(response.data.gsaDetails);
          return response.data.gsaDetails;
        }
        return [];
      })
      .catch(error => Promise.reject(error));
  }

  /**
   * @module user-service
   * @param {String} accountID
   * @return {Promise<Object>} Returns an object containing request status and data.
   * accountInfo.specialFlag = String // 0 => INJUNCTION, 1 => ESTATE_ACCOUNT, 2 => COMMITTEE, 3 => OTHER, 4 => BANKRUPTCY, 5 => BLANK
   * */
  getUserAccount(accountID){
    return this._authFetch(`${this._endpoints.USER_ACCOUNTS_READ}/${accountID}`)
      .then(response => {
        if (response && response.data) {
          return response.data;
        }
        return {};
      })
      .catch(error => Promise.reject(error));
  }

  // todo: This needs a better approach
  updateCDPSessionSelectedAccountType(account) {
    const cdpSessionData = StoreRegistry.cdpSession.getData();
    const accountTypeKey = 'selectedAccountType';
    const accountCategory = account.accountCategory;
    const accountApprovalType = account.typeOfApproval;
    const accountType = `${accountCategory}-${accountApprovalType}`;

    if (accountCategory === constants.ACCOUNT_TYPES.INDIVIDUAL) {
      StoreRegistry.cdpSession.setData({
        ...cdpSessionData,
        [accountTypeKey]: constants.ACCOUNT_STATES.INDIVIDUAL
      });
    }

    if (accountCategory === constants.ACCOUNT_TYPES.CORPORATE) {
      StoreRegistry.cdpSession.setData({
        ...cdpSessionData,
        [accountTypeKey]: constants.ACCOUNT_STATES.CORPORATE
      });
    }

    switch (accountType) {
      case constants.ACCOUNT_TYPES.JOINT_AND:
        StoreRegistry.cdpSession.setData({
          ...cdpSessionData,
          [accountTypeKey]: constants.ACCOUNT_STATES.JOINT_AND
        });
        break;
      case constants.ACCOUNT_TYPES.JOINT_OR:
        StoreRegistry.cdpSession.setData({
          ...cdpSessionData,
          [accountTypeKey]: constants.ACCOUNT_STATES.JOINT_OR
        });
        break;
      case constants.ACCOUNT_TYPES.CORPORATE_TRUSTEE:
        StoreRegistry.cdpSession.setData({
          ...cdpSessionData,
          [accountTypeKey]: constants.ACCOUNT_STATES.CORPORATE_TRUSTEE
        });
        break;
    }
  }

  updateCDPSessionAccountType(accounts) {
    let accountTypeSet = false;
    const accountTypeKey = 'accountType';
    const cdpSessionData = StoreRegistry.cdpSession.getData();
    accounts = accounts.filter(accountElement => ![constants.ACCOUNT_STATUS.CLOSED, constants.ACCOUNT_STATUS.REJECTED].includes(accountElement.accountStatus));

    for (let i = 0; i < accounts.length; i++) {

      const accountCategory = accounts[i].accountCategory;
      const accountApprovalType = accounts[i].typeOfApproval;

      if (accountCategory === constants.ACCOUNT_TYPES.INDIVIDUAL) {
        StoreRegistry.cdpSession.setData({
          ...cdpSessionData,
          [accountTypeKey]: constants.ACCOUNT_STATES.INDIVIDUAL
        });
        break;
      }

      if (accountCategory === constants.ACCOUNT_TYPES.CORPORATE) {
        StoreRegistry.cdpSession.setData({
          ...cdpSessionData,
          [accountTypeKey]: constants.ACCOUNT_STATES.CORPORATE
        });
        break;
      }

      const accountType = `${accountCategory}-${accountApprovalType}`;

      switch (accountType) {
        case constants.ACCOUNT_TYPES.JOINT_AND:
          StoreRegistry.cdpSession.setData({
            ...cdpSessionData,
            [accountTypeKey]: constants.ACCOUNT_STATES.JOINT_AND
          });
          accountTypeSet = true;
          break;
        case constants.ACCOUNT_TYPES.JOINT_OR:
          StoreRegistry.cdpSession.setData({
            ...cdpSessionData,
            [accountTypeKey]: constants.ACCOUNT_STATES.JOINT_OR
          });
          accountTypeSet = true;
          break;
        case constants.ACCOUNT_TYPES.CORPORATE_TRUSTEE:
          StoreRegistry.cdpSession.setData({
            ...cdpSessionData,
            [accountTypeKey]: constants.ACCOUNT_STATES.CORPORATE_TRUSTEE
          });
          accountTypeSet = true;
          break;
        default:
          accountTypeSet = false;
      }

      if (accountTypeSet) {
        break;
      }
    }

  }

  /**
   * Get user particulars.
   * @module user-service
   * @return {Promise<Object>} Returns an object containing user particulars.
   */
  getUserParticulars() {
    return this._authFetch(this._endpoints.USER_PARTICULARS_READ)
      .then(response => (response && response.data) || {})
      .catch(error => Promise.reject(error));
  }

  /**
   * Get address Details.
   * @module user-service
   * @return {Promise<Object>} Returns an object containing address details.
   */
  getAddressDetails(postalCode) {
    return FetchUtils.fetch(this._endpoints.POSTAL_ADDRESS_READ + postalCode).then(res => res.json());
  }

  /**
   * Update user particulars.
   * @module user-service
   * @param {Object} data
   * @param {Object} data.payload form data payload to be sent
   * @param {string} data.token returned from successful transaction signing
   * @param {string} data.state the cryptographic state if SingPass transaction signing was used
   * @return {Promise<Object>} Returns an object containing the status of the call
   */
  updateUserParticulars({
    payload,
    token,
    state
  }) {
    // previously we need to pass `x-auth-authenticator` in the headers, which is not needed anymore as onekey token got decommissioned
    let headers = {};

    if (token) {
      headers[this._requestHeaders.authOtp] = token;
      headers[this._requestHeaders.singPassState] = state;
    }

    return this._authFetchText(this._endpoints.USER_PARTICULARS_UPDATE, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers
    });
  }

  /**
  * Update user particulars with My Info Data
  * @module user-service
  * @param {Object} data
  * @param {Object} data.payload form data payload to be sent
  * @param {string} data.myinfoUserToken returned from successful myinfo backened
  * @return {Promise<Object>} Returns an object containing the status of the call
  */
  updateUserParticularsMyInfo({
    payload,
    myinfoUserToken
  }) {
    let headers = {};

    if (myinfoUserToken) {
      headers[this._requestHeaders.myinfoUserToken] = myinfoUserToken;
    }
    return this._authFetchText(this._endpoints.USER_PARTICULARS_MYINFO_UPDATE, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers
    });
  }

  registerFin(securitiesAccountNumber, myinfoToken) {
    const headers = {
      [this._requestHeaders.myinfoUserToken]: myinfoToken
    };

    return this._authFetchText(this._endpoints.USER_REGISTER_FIN, {
      method: 'PUT',
      body: JSON.stringify({ securitiesAccountNumber }),
      headers
    });
  }

  /**
   * Get user contact reminder.
   * @module user-service
   * @return {Promise}
   */
  getUserContactReminder() {
    return this._authFetch(this._endpoints.USER_CONTACT_REMINDER)
      .then(response => (response && response.data) || {})
      .catch(error => Promise.reject(error));
  }

  /**
   * Update user contact reminder.
   * @module user-service
   * @param {string} action CONFIRM|CONFIRM_LATER|SKIP
   * @return {Promise}
   */
  updateUserContactReminder(action) {
    return this._authFetch(this._endpoints.USER_CONTACT_REMINDER, {
      method: 'PUT',
      body: JSON.stringify({ contactUpdateActionType: action })
    });
  }
}

if (!instance) {
  instance = new UserService();
}

export default instance;
