import AmeSecurityUtil from './ame-security-utils';

/**
 * @function encryptPasswordForUas
 * @param {Object} authCodes Has public key, random and other parameters
 * @param {string} password
 */
 export function encryptPasswordForUas(authCodes={}, password) {
  let ame2eea = AmeSecurityUtil.ame2eea;

  return ame2eea.encryptPinForAM(authCodes.e2eeSid, authCodes.pubKey, authCodes.serverRandom, password, authCodes.oaepHashAlgo);
}

/**
 * @function encryptPasswordForChangePassword
 * @param {Object} credentials
 * @param {string} credentials.authCodes AuthCodes
 * @param {string} credentials.oldPassword Unencrypted oldPassword
 * @param {string} credentials.newPassword Unencrypted newPassword
 */
export function encryptPasswordForChangePasswordUAS({ authCodes, oldPassword, newPassword}) {
  let ame2eea = AmeSecurityUtil.ame2eea;

  return ame2eea.encryptChangePinForAM(authCodes.e2eeSid, authCodes.pubKey, authCodes.serverRandom, oldPassword, newPassword, authCodes.oaepHashAlgo);

}
