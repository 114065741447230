import i18n from 'sgx-localisation-service';
import DateService from 'sgx-date-time-service';
import SecuritiesService from 'sgx-securities-service';
import MetadataService from 'sgx-metadata-service';
import PortfolioService from 'services/portfolio-service';
import { Big } from 'utils/big-number-util';
import { getSecurityLink } from 'utils/portfolio-util';
import CorporateActionsService from 'services/corporate-actions-service';

const { BALANCE_TYPE, BALANCE_SUB_TYPE } = PortfolioService.constants;
const LOAN_STATUS = i18n.getTranslation('app.widget-portfolio-overview.sbl.status');
const BOND_TYPE_SECURITIES = {
  0: 'Corporate Debt',
  8: 'TBill',
  9: 'TBond'
};
const MAS_ISSUED_BONDS = {
  2: 'SGS Bonds',
  3: '1-year SGS Tbill',
  4: '3-month SGS Tbil',
  6: 'SSG',
  7: 'SSB',
  10: '6-month SGS Tbill',
  11: '9-month SGS Tbill',
}

/**
 * Aggregator utils for portfolio
 *
 * @module PortfolioAggregator
 * @type {HTMLElement}
 */
class PortfolioAggregator {
  /**
   * Get payouts and their ibmcodes
   * @param {string} accountId
   * @param {string} startDate
   * @param {string} endDate
   */
  getPayouts(accountId, startDate, endDate) {
    return PortfolioService.getPayouts(accountId, startDate, endDate)
      .then(payouts => {
        const ibmCodes = payouts.map(payout => payout.securityCode); // securityCode is the ibm code

        return MetadataService.getMetaDataByIbmCodes(ibmCodes)
          .then(metadata => metadata.reduce((acc, currentValue) => {
            acc[currentValue.ibmCode] = currentValue;
            return acc;
          }, {}))
          .then(metadata => payouts.map(payout => {
            const stockCode = metadata[payout.securityCode] && metadata[payout.securityCode].securityCode || '';
            const security = {
              label: payout.securityAbbrevName,
              link: getSecurityLink(stockCode)
            };
            return {
              ...payout,
              stockCode,
              security
            };
          }));
      });
  }

  getSecuritiesLoanAndIntransit(accountId) {
    return Promise.all([
      PortfolioService.getIntransitBalances(accountId),
      PortfolioService.getLoanBalances(accountId)
    ])
      .then(([intransitBalances, loanBalances]) => {
        const loans = loanBalances.map(loan => ({
          ...loan,
          effectiveDate: loan.loanDate,
          qty: loan.loanQty,
          status: LOAN_STATUS,
          lenderRate: loan.lenderRate || 'N/A'
        }));
        const sbl = [
          ...intransitBalances.map(balance => ({
            ...balance,
            securityName: balance.securityDescription,
            lenderRate: balance.lenderRate || 'N/A'
          })),
          ...loans
        ].map((balance, index) => {
          let { effectiveDate } = balance;
          const isValidDate = DateService(effectiveDate).isValid();
          // replacing '/' to '-' removes the momentjs's deprecation warning
          const validEffectiveDate = isValidDate ? effectiveDate.replace(/\//g, '-') : false;

          return {
            ...balance,
            qty: balance.qty || 0,
            effectiveDate: validEffectiveDate,
            id: index
          }
        });
        const ibmCodes = sbl.map(balance => balance.securityCode) // securityCode in in-transit/loan balance is the ibmCode
          .filter((value, index, self) => self.indexOf(value) === index) // get distinct ibm codes

        return this._getSblWithStockCodes(sbl, ibmCodes);
      });
  }

  _getSblWithStockCodes(sbl, ibmCodes) {
    return MetadataService.getMetaDataByIbmCodes(ibmCodes)
      .then(metadata => metadata.reduce((acc, currentValue) => {
        acc[currentValue.ibmCode] = currentValue;
        return acc;
      }, {}))
      .then(metadata => sbl.map(balance => {
        const ibmCode = balance.securityCode;
        const stockCode = metadata[ibmCode] && metadata[ibmCode].securityCode || 'N/A'; // securityCode in metadata is the stockCode
        balance.security = {
          label: balance.securityName,
          link: getSecurityLink(stockCode, balance.type)
        };

        return {
          ...balance,
          stockCode
        };
      }));
  }

  getInvestmentDetails(accountId) {
    return Promise.all([
      PortfolioService.getBalances(accountId),
      SecuritiesService.getPrices({ params: 'nc,lt' }),
      PortfolioService.getPortfolioCosts(accountId)
    ])
      .then(([balances, prices, costs]) => [this._aggregateFreeAndBlockedBalance(balances), prices, costs])
      .then(([balances, prices, costs]) => this._calculateBalancesFields({balances, prices, costs}))
      .then((balanceFieldsResponse) => this._updateCorporateActions(balanceFieldsResponse));
  }

  /**
   * @desc: Update the value for corporate-actions, if available for the current security
   * */
  _updateCorporateActions(balanceFieldsResponse) {
    return Promise.all([CorporateActionsService.status(), CorporateActionsService.getCorporateActionsList()])
      .then(([status, caList]) => {
        const instrumentID_Map = caList.reduce((accumulator, {
          instrumentID = null, mediaName, type, electionStart, electionEnd, eRightsInstrumentID = null
        }) => {

          if (!instrumentID || !CorporateActionsService.getValidElectionDateTime({
            currentDateTime: status.timestamp,
            electionStart,
            electionEnd
          })) {
            return accumulator;
          }

          if (!accumulator[instrumentID]) {
            accumulator[instrumentID] = [{type, mediaName}];
          } else {
            accumulator[instrumentID].push({type, mediaName})
          }

          if (eRightsInstrumentID) {
            if (!accumulator[eRightsInstrumentID]) {
              accumulator[eRightsInstrumentID] = [{type, mediaName}];
            } else {
              accumulator[eRightsInstrumentID].push({type, mediaName})
            }
          }

          return accumulator;
        }, {});

        balanceFieldsResponse.forEach(item => {
          const caItem = instrumentID_Map[item.securityCode];
          item.corporateActions = [];

          if (caItem) {
            caItem.forEach(cItem => {
              item.corporateActions.push({
                link: `/corporate-actions/${cItem.mediaName}`,
                label: cItem.type
              })
            })
          }
        })
        return balanceFieldsResponse;
      })
  }

  /**
   * Retrieves the market price based on stockCode and calculate remaining fields (market value, unit cost and profit & loss)
   * @param {array<object>} obj.balances aggregated balances (output from this._aggregateFreeAndBlockedBalance method)
   * @param {object} obj.prices securities data
   * @param {array<object>} obj.costs unit costs
   * @return {Promise<object>}
   */
  _calculateBalancesFields({ balances, prices, costs }) {
    const ibmCodes = balances.map(({ securityCode }) => securityCode);
    costs = this.toUnitCostsObject(costs);

    return MetadataService.getMetaDataByIbmCodes(ibmCodes)
      .then(metadata => {
        const metadataObj = metadata.reduce((acc, cv) => {
          acc[cv.ibmCode] = cv;
          return acc;
        }, {});

        // fill in the marketPrice, stockCode and marketValue of each balances
        return balances.map((balance, index) => {
          let bondType = null;
          const { securityCode } = balance;
          const meta = metadataObj[securityCode] && metadataObj[securityCode] || {};
          const { productCode, sectorCode } = meta;
          const stockCode = meta.securityCode || 'N/A';
          const isTypeBond = !!BOND_TYPE_SECURITIES[balance.securityType];
          const isMasIssuedBond = isTypeBond &&
          !!MAS_ISSUED_BONDS[balance.instrumentSubType];
          if (isTypeBond && isMasIssuedBond) {
            bondType = 'MAS';
          } else if (isTypeBond) {
            bondType = 'SGX';
          }
          const removeLink = !bondType && (!stockCode || stockCode === 'N/A');
          balance.stockCode = stockCode;
          balance.id = index.toString();
          balance.profit = 'N/A';
          balance.marketPrice = 'N/A';
          balance.marketValue = 'N/A';
          balance.unitCost = '';

          if (stockCode) {
            if(prices[stockCode]) {
              let { lt, i, type } = prices[stockCode];
              balance.type = type;
              balance.marketPrice = lt;
              balance.marketValue = this.calculateMarketValue(balance.marketPrice, balance.totalBalance);
              balance.securityName = i ? `${balance.securityName} (${i})` : balance.securityName;
            }

            if ((costs[stockCode] || costs[stockCode] === 0) && stockCode !== 'N/A') {
              balance.unitCost = costs[stockCode];
              balance.profit = this.calculateProfitLoss(balance.marketPrice, balance.totalBalance, balance.unitCost);
            }
          }

          balance.security = {
            label: balance.securityName,
            link: removeLink ? '' : getSecurityLink(stockCode, balance.type, bondType)
          };

          // set product and sector based on its metadata
          balance.productCode = productCode;
          balance.sectorCode = sectorCode

          return balance;
        });
      });
  }

  /**
   * Aggregate and sum up, blocked, both free and blocked are from balanceQuantity, balanceType, balanceSubType
   *
   * @param {array<object>} balances
   * @return {array<object>} aggregated balances
   */
  _aggregateFreeAndBlockedBalance(balances) {
    const setBalance = (accumulator, balance, key) => {
      const { positionSecurity, securityInfo } = balance;
      const { securityAbbrevName, instrumentSubType, securityType } = securityInfo;
      const { isin, securityCode, currency } = positionSecurity;

      if (!accumulator[key]) {
        accumulator[key] = {
          currency,
          securityCode,
          instrumentSubType,
          securityType,
          securityName: securityAbbrevName,
          stockCode: null,
          freeBalance: 0,
          blockedBalance: 0,
          totalBalance: 0,
          marketPrice: null,
          unitCost: null,
          marketValue: null,
          profitLoss: null,
          isinCode: isin,
          blockedBalanceBreakdown: {
            [BALANCE_TYPE.EARMARKED]: 0,
            [BALANCE_TYPE.FROZEN]: 0,
            [BALANCE_TYPE.PENDING_WITHDRAWAL]: 0,
            [BALANCE_TYPE.CHARGE]: 0,
            [BALANCE_TYPE.PENDING_DEBIT]: 0,
            [BALANCE_TYPE.BLOCKED]: 0
          }
        };
      }
    };
    const aggregatedBalances = balances.reduce((accumulator, balance) => {
      const { positionSecurity } = balance;
      const { securityCode, currency, balanceType, balanceSubType, balanceQuantity } = positionSecurity;
      const key = `${securityCode}-${currency}`;

      if (balanceType === BALANCE_TYPE.FREE) {
        setBalance(accumulator, balance, key);
        accumulator[key].freeBalance += balanceQuantity;
      } else if (this._isValidBlocked(balanceType, balanceSubType)) {
        setBalance(accumulator, balance, key);
        accumulator[key].blockedBalance += balanceQuantity;
        accumulator[key].blockedBalanceBreakdown[balanceType] += balanceQuantity;
      } else {
        return accumulator;
      }

      accumulator[key].totalBalance = accumulator[key].freeBalance + accumulator[key].blockedBalance;
      return accumulator;
    }, {});
    return Object.keys(aggregatedBalances).map(key => aggregatedBalances[key]);
  }

  _isValidBlocked(balanceType, balanceSubType) {
    return (balanceType === BALANCE_TYPE.EARMARKED && (!balanceSubType || this._isEarmarkedSubType(balanceSubType))) ||
      (balanceType === BALANCE_TYPE.FROZEN && (!balanceSubType || this._isPendingDeliverySubType(balanceSubType))) ||
      (balanceType === BALANCE_TYPE.PENDING_WITHDRAWAL && !balanceSubType) ||
      (balanceType === BALANCE_TYPE.CHARGE && !balanceSubType) ||
      (balanceType === BALANCE_TYPE.PENDING_DEBIT && !balanceSubType) ||
      (balanceType === BALANCE_TYPE.BLOCKED && !balanceSubType);
  }

  _isEarmarkedSubType(balanceSubType) {
    const validSubTypes = [
      BALANCE_SUB_TYPE.SETTLEMENT_BATCH,
      BALANCE_SUB_TYPE.SETTLEMENT_INSTRUCT,
      BALANCE_SUB_TYPE.SETTLEMENT_RTGS,
      BALANCE_SUB_TYPE.CORPORATE_ACTIONS
    ];
    return !!~validSubTypes.indexOf(balanceSubType);
  }

  _isPendingDeliverySubType(balanceSubType) {
    const validSubTypes = [
      BALANCE_SUB_TYPE.MORATORIUM,
      BALANCE_SUB_TYPE.BROKER_WITHHOLD
    ];
    !!~[BALANCE_SUB_TYPE.MORATORIUM, BALANCE_SUB_TYPE.BROKER_WITHHOLD].indexOf(balanceSubType)
    return !!~validSubTypes.indexOf(balanceSubType);
  }

  /**
   * Calculates the Market Value based on market price and total balance
   *
   * @param {number} marketPrice security market price
   * @param {number} totalBalance sum of pending and free balance
   * @return {number|string} returns empty string when marketPrice is NaN otherwise the calculated market value
   */
  calculateMarketValue(marketPrice, totalBalance) {
    return isNaN(marketPrice) && !marketPrice && marketPrice !== 0 ? '' : Big(marketPrice * totalBalance).toFixed();
  }

  /**
   * Calculates the Profit loss based on this computation (Total Holdings * Market Price) - (Total Holdings * Unit Cost)
   * wherein Total Holdings refers to the summation of free and pending/blocked balance.
   *
   * @param {number} marketPrice
   * @param {number} totalBalance
   * @param {number} unitCost
   * @return {number} returns the calculated profit loss or empty string if params value contains NaN
   */
  calculateProfitLoss(marketPrice, totalBalance, unitCost) {
    const cost = !isNaN(unitCost) ? +unitCost : 0;
    const price = marketPrice || 0;
    if (!isNaN(marketPrice) && (marketPrice || marketPrice === 0) && ((cost || cost === 0) && unitCost !== '')) {
      const totalPrice = Big(totalBalance).times(price);
      const totalCost = Big(totalBalance).times(cost);
      return Big(totalPrice).minus(totalCost).toFixed();
    }
    return 'N/A';
  }

  /**
   * Converts the unit costs from user's preference into an object
   *
   * @param {Array} unitCosts a list of unit costs from user's preference
   * @return {Object}
   */
  toUnitCostsObject(unitCosts) {
    return unitCosts.reduce((acc, curr) => {
      acc[curr.securityCode] = curr.unitPrice;
      return acc;
    }, {});
  }

  static get instance() {
    if (!PortfolioAggregator._instance) {
      PortfolioAggregator._instance = new PortfolioAggregator();
    }
    return PortfolioAggregator._instance;
  }
}

export default PortfolioAggregator.instance;
