import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-list-row-security-announcement.html';
import DateUtil from 'utils/date-util';
import i18n from 'sgx-localisation-service';

/**
 * List Details row for company announcements page
 */
class CmpListRowSecurityAnnouncement extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-security-announcement');

    this._title = this.querySelector('.announcement-title');
    this._tag = this.querySelector('.announcement-tag');
    this._date = this.querySelector('.announcement-date');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  attributeChangedCallback(attr, oldV, newV) {
  }

  setConfig(config) {
  }

  setData(rowData) {
    this.dataset.id = rowData.id;
    this._data = rowData;
    this._data.src = rowData.url; // for sgx-list-details-iframe
    this._render(this._data);
  }

  getData() {
    return this._data;
  }

  _render(data) {
    this._title.textContent = data.title;
    this._tag.textContent = data.category_name;
    this._date.textContent = DateUtil.formatDateTo(data.broadcast_date_time, null, i18n.getTranslation('app.format.date.timestamp-simple'), true);
  }

  setState(state) {
  }

  setSelected(selected) {
    if (selected) {
      this.setAttribute('selected', '');
    } else if (this.hasAttribute('selected')) {
      this.removeAttribute('selected');
    }
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('cmp-list-row-security-announcement', withInitDOM(CmpListRowSecurityAnnouncement));
