import { withInitDOM } from 'sgx-base-code';
import DeviceService from 'sgx-device-service';
import dialogTemplate from './widget-stock-screener-details-dialog-template';

const DIALOG_ID = 'widget-stock-screener-details-dialog';

class WidgetStockScreenerDetailsDialog extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this._makeDialogIfNeeded();
  }

  show({data, actions}) {
    const dialogConfig = {
      data,
      actions,
      isModal: true,
      fullscreen: DeviceService.isMobile()
    };

    return this._dialog.show(dialogConfig)
      .then(() => this._dialogBody.getData())
      .catch((error) => Promise.reject(error));
  }

  hide() {
    this._dialog.hide();
  }

  _makeDialogIfNeeded() {
    const template = dialogTemplate(DIALOG_ID);
    this._dialog = document.getElementById(DIALOG_ID);

    if (this._dialog) {
      return this._dialog;
    }

    document.body.insertAdjacentHTML('beforeend', template);

    this._dialog = document.getElementById(DIALOG_ID);
    this._dialogBody = this._dialog.querySelector('[data-dialog="body"]');
    return this._dialog;
  }
}

customElements.define('widget-stock-screener-details-dialog', withInitDOM(WidgetStockScreenerDetailsDialog));
