import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DeviceService from 'sgx-device-service';
import MarketResearchService from 'market-research-service';
import tmpl from './template-market-research-page.html';
import { FILTER_CONFIG, FILTER_STATE } from './template-market-research-page-constants';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Dashboard page template.
 */
class TemplateMarketResearchPage extends HTMLElement {
  constructor() {
    super();

    // References
    this._router = document.getElementById('sgx-app-router');

    this._filterButton = null;
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._listDetails = this.querySelector('sgx-list-details');
  }

  connectedCallback() {
    this._attached = true;
  }

  disconnectedCallback() {
    this._attached = null;
  }

  _initListDetails(pageSize, currentPage) {
    const initState = {
      filters: FILTER_STATE
    };
    this._listDetails.setConfig({
      'toolbar': {
        'rows': [
          {
            'layout': 'default',
            'tools': [
              {
                'element': 'sgx-data-model-tool-filter',
                'onAttach': elem => {
                  this._filterButton = elem;
                },
                'onDetach': _ => {
                  this._filterButton = null;
                }
              }
            ]
          },
          {
            'layout': 'custom',
            'customToolbarLayout': new Promise((resolve, reject) => {
              const toolbarFragment = document.createDocumentFragment();

              const customToolbar = document.createElement('div');
              customToolbar.classList.add('sgx-list-details-list-toolbar-custom');
              customToolbar.innerHTML = `
                <div class="sgx-data-model-tool-display-container">
                  <sgx-data-model-tool-display-count></sgx-data-model-tool-display-count>
                  <sgx-data-model-tool-display-latest-update></sgx-data-model-tool-display-latest-update>
                </div>
              `;

              toolbarFragment.appendChild(customToolbar);

              if (toolbarFragment.hasChildNodes) {
                resolve(toolbarFragment);
              } else {
                reject(Error('Could not create custom toolbar'));
              }
            }),
            'onAttach': () => {
              const displayCountTool = this.querySelector('sgx-data-model-tool-display-count');
              const displayLatestUpdateTool = this.querySelector('sgx-data-model-tool-display-latest-update');

              const customTools = [displayCountTool, displayLatestUpdateTool];
              customTools.forEach(tool => {
                tool.setConfig({
                  model: this._listDetails.getList().getDataModel(),
                  isExternal: true
                });
              });
            }
          }
        ]
      },
      'list': {
        'view': {
          'type': 'infinite',
          'currentPage': currentPage ? currentPage - 1 : null,
          'pageSize': pageSize,
          'rowHeight': 71,
          'rowElementName': 'cmp-list-row-market-research',
          'selectionMode': 'single',
          'emptyMsg': i18n.getTranslation('app.shared-text.status-indicator.empty.description'),
          'errorMsg': i18n.getTranslation('app.shared-text.status-indicator.error.description'),
          'offsets': 7,
          'offsetsSmall': 7
        },
        'model': 'cmp-data-model-market-research',
        'modelLazyLoad': {
          'pageSize': 20,
          'threshold': 0.9
        },
        initState
      },
      'details': {
        'element': 'cmp-market-research-details',
        'resolveSelectedData': selectedRowData => {
          // Update route
          if (this._routesBound && !this._requestedMarketNews) {
            this._router.navigateWithoutTransition({
              news: selectedRowData.id
            });
          }
          if (!!this._requestedMarketNews) {
            this._requestedMarketNews = null;
          }
          return Promise.resolve(selectedRowData);
        },
        'resolveSelectedLoadingTitle': () => i18n.getTranslation('app.securities.loading-security'),
        'resolveSelectedTitleForMobile': resolvedSelectedData => resolvedSelectedData.title
      }
    });

    // Setup needed to show default filter dialog before loading the data from 'filter lists' api
    this._filterButton.setConfig({filter: FILTER_CONFIG, model: this._listDetails.getList().getDataModel()});

    MarketResearchService.getMarketUpdatesFiltersLists().then(lists => {
      const filterConfig = FILTER_CONFIG;
      filterConfig.columns.assetClass.options = lists.assetClassesSecurities;
      filterConfig.columns.sector.options = lists.sectors;
      this._filterButton.setConfig({filter: filterConfig, model: this._listDetails.getList().getDataModel()});
    });
  }

  setData(data, params) {
    this._routesBound = !!data.pageData.routesBound;
    this._requestedMarketNews = params.news;

    const {pageSize} = document.querySelector('sgx-app-router').getState().pageData.pageData;
    this._initListDetails(pageSize, params.page);
    const model = this._listDetails.getList().getDataModel();
    const showDetails = true;
    const isRefresh = false;
    fromEvent(model, 'update')
      .pipe(take(2)) // disregard the setState 'update' event but take the 'getDataRange-executeAPI' 'update' event
      .subscribe((evt) => {
        if (!(evt.detail && evt.detail.action === 'getDataRange')) {
          return;
        }
        if (this._requestedMarketNews) {
          MarketResearchService.getMarketUpdateById(this._requestedMarketNews).then(marketUpdate => {
            if (this._attached) {
              this._listDetails.setDetails(marketUpdate);
            }
          });
        } else if (!this._requestedMarketNews && DeviceService.isDesktop()) {
          model.getRowDataByIndex(0).then(data => {
            if (data && data.id) {
              model.getRowData(data.id).then(rowData => this._listDetails.setDetails(rowData, !isRefresh, showDetails));
            }
          });
        }
      });
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'flex';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

customElements.define('template-market-research-page', withInitDOM(TemplateMarketResearchPage));
