import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import ConfigService from 'sgx-config-service';
import tmpl from './widget-portfolio-overview-sbl-toolbar.html';
import StoreRegistry from 'stores/store-registry';

class WidgetPortfolioOverviewSblToolbar extends HTMLElement {
  constructor() {
    super();
    this._onTableDataModelUpdate = this._onTableDataModelUpdate.bind(this);
  }

  initDOM() {
    this.classList.add('widget-portfolio-overview-sbl-toolbar');
    this.appendChild(tmpl.getNode());
    const span = this.querySelector('span');
    const lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');

    span.innerHTML = i18n.getTranslation('app.widget-portfolio-overview.toolbar.sbl-label_html', {
      link: ConfigService.links.INVESTORS_PORTAL_PORTFOLIO_LENDING_POOL[lang]
    });

    this._print = this.querySelector('sgx-data-model-tool[icon="print"]');
    this._download = this.querySelector('sgx-data-model-tool[icon="download"]');
    this._table = this.closest('sgx-table');

    this._print.setDisabled(true);
    this._download.setDisabled(true);
  }

  connectedCallback() {
    this._setupListener(true);
  }

  disconnectedCallback() {
    this._setupListener(false);
  }

  _setupListener(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this[fnName]('click', this._onClick);
    this._table.whenConfigured().then(() => {
      this._table.getDataModel()[fnName]('update', this._onTableDataModelUpdate);
    });
  }

  _onClick(evt) {
    // QUICKFIX - prevent click events from triggering internal sgx-table actions
    evt.stopImmediatePropagation();
  }

  _onTableDataModelUpdate(_) {
    const disabled = this._table.getDataModel().getFilteredRowCount() === 0;
    this._print.setDisabled(disabled);
    this._download.setDisabled(disabled);
  }
}

customElements.define('widget-portfolio-overview-sbl-toolbar', withInitDOM(WidgetPortfolioOverviewSblToolbar));
