const CHART_COLORS = ['#0b236b', '#A4DD36', '#0094b3', '#bf0052', '#791e7f', '#ffcc00', '#8591b5', '#deeb98', '#80cad9', '#df80a9', '#bc8fbf', '#ffe680'];

/**
 * vertically and horizontally center the title text of the chart
 **/
function verticallyAndHorizontallyCenterTitle() {
  const chartTitle = (this.title && this.title.textStr) || '';
  if (this.title) {
      this.title.destroy();
  }
  const r = this.renderer;
  const x = this.series[0].center[0] + this.plotLeft;
  const y = this.series[0].center[1] + this.plotTop;
  this.title = r.text(chartTitle, 0, 0)
    .css({
      color: '#333',
      fontSize: '14px',
      fontWeight: '900'
    })
    .hide().add();
  const bbox = this.title.getBBox();
  this.title.attr({
      x: x - (bbox.width / 2),
      y
  }).show();
};

export {
  verticallyAndHorizontallyCenterTitle,
  CHART_COLORS
};
