import { withInitDOM } from 'sgx-base-code';
import DeviceService from 'sgx-device-service';

class SgxListDetailsIframe extends HTMLElement {
  constructor() {
    super();

    // Bound methods
    this.onFrameError = this.onFrameError.bind(this);
  }

  initDOM() {
    this.classList.add('sgx-list-details-iframe');
  }

  disconnectedCallback() {
    if (this.children.length) {
      this._setEventListeners(this.children[0], false);
    }
  }

  /**
   * Adds/removes error and load events of the target node (iframe)
   *
   * @param {Node} elem target DOM node
   * @param {boolean} enable adds event listener to the target node when true otherwise it removes the event
   */
  _setEventListeners(elem, enable) {
    // Only enable/disable event listener for iframe element
    if (elem.tagName !== 'IFRAME') {
      return;
    }
    const listenerMethodKey = enable ? 'addEventListener' : 'removeEventListener';
    elem[listenerMethodKey]('error', this.onFrameError);
  }

  /**
   * Replaces the first child of the host with new DOM node which is either iframe/object
   *
   * @param {Node} newNode new DOM node
   */
  _replaceNode(newNode) {
    const oldNode = this.children[0];
    this._setEventListeners(oldNode, false);
    this.replaceChild(newNode, oldNode);
  }

  /**
   * Transforms the src and mimeType into a sgx-download-list data format
   *
   * @param {string} src relative/absolute path of the content's source
   * @param {string} mimeType mimeType of the content (e.g application/pdf, application/vnd.ms-excel etc.)
   * @return {Object} returns sgx-download-list data format
   */
  _toDownloadListData(src, mimeType) {
    // By default make the fallbackLabel as the last part of the src path
    if (!this.fallbackLabel) {
      const path = src.split('/');
      this.fallbackLabel = path[path.length - 1];
    }

    return {
      'title': null,
      'footnote': null,
      'showDates': false,
      'downloadItems': [
        {
          'label': this.fallbackLabel,
          'file': {
            'mediaType': 'document',
            'file': {
              'url': src,
              'filemime': mimeType
            }
          }
        }
      ]
    };
  }

  /**
   * @return {Node} returns sgx-download-list element
   */
  _getSgxDownloadListElement() {
    return this.querySelector('sgx-download-list');
  }

  /**
   * Renders an object element with default fallback into the host element
   *
   * @param {string} src relative/absolute path of the content's source
   * @param {string} type mimeType of the content (e.g application/pdf, application/vnd.ms-excel etc.)
   */
  _renderObjectTag(src, type) {
    const elem = document.createElement('object');
    const fnName = this.children.length ? '_replaceNode' : 'appendChild';
    elem.appendChild(this._createFallback());

    // try to load pdf on object tag only for desktop otherwise always show fallback
    if (DeviceService.isDesktop()) {
      elem.type = type;
      elem.data = src;
    }

    this[fnName](elem);
    this._getSgxDownloadListElement()
      .setData(this._toDownloadListData(src, type));
  }

  /**
   * Renders an iframe element into the host element
   *
   * @param {string} src relative/absolute path of the content's source
   */
  _renderIframeTag(src) {
    const elem = document.createElement('iframe');
    const fnName = this.children.length ? '_replaceNode' : 'appendChild';

    elem.src = src;

    this[fnName](elem);
    this._setEventListeners(elem, true);
  }

  /**
   * Triggers the rendering of object/iframe tag depending on the content's mimeType
   *
   * @param {Object} args an object of optional query parameters that can be appended to the API call
   * @param {String} args.src absolute/relative path of the content's source
   * @param {String} args.fallbackLabel label of the content that will be used in case of fallback
   * @param {String} [args.mimeType=text/html] mimeType of the src
   */
  setData({src, fallbackLabel, mimeType = 'text/html'}) {
    this.fallbackLabel = fallbackLabel;
    if (!src) {
      return;
    }

    const url = src || '';

    if (mimeType === 'text/html') {
      this._renderIframeTag(url);
    } else {
      this._renderObjectTag(url, mimeType);
    }
  }

  onFrameError(_) {
    const src = this.children[0].src;
    this._replaceNode(this._createFallback());
    this._getSgxDownloadListElement()
      .setData(this._toDownloadListData(src, 'text/html'));
  }

  /**
   * Creates the fallback content
   *
   * @return {Node} returns the fallback element
   */
  _createFallback() {
    const template = document.createElement('template');
    template.innerHTML = `
      <sgx-download-list></sgx-download-list>
    `;
    return document.importNode(template.content, true);
  }
}

customElements.define('sgx-list-details-iframe', withInitDOM(SgxListDetailsIframe));
