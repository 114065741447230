import BaseService from 'services/base-service';
import { URLUtils } from 'sgx-base-code';
import * as constants from './ccy-service-constants';

let instance = null;

class CcyService extends BaseService {

  get constants() {
    return constants;
  }

  /**
   * Get CCY/FX standing instructions information.
   * @param {String} accountId The id associated with the CDP account
   * @param {Array} excludeStatus exclude results with the following list of possible statuses:
   * VERIFIED, SUSPENDED, SUBSCRIBED,TERMINATED, REJECTED_INTERNAL, REJECTED_EXTERNAL,
   * PENDING_VERIFICATION, PENDING_SUBMISSION, PENDING_EXTERNAL_VERIFICATION,
   * PENDING_TERMINATION
   * @return {Promise<Object>} Returns an object
   */
  getStandingInstructions(accountId, excludeStatus = []) {
    let url = this._endpoints.CCY_STANDING_INSTRUCTIONS;

    if (excludeStatus && excludeStatus.length) {
      url = URLUtils.setQueryParams(this._endpoints.CCY_STANDING_INSTRUCTIONS, { excludeStatus: excludeStatus.join(',') });
    }

    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => response.data || [])
      .catch(error => Promise.reject(error));
  }

  /**
   * Create or terminate CCY/FX standing instructions.
   * @param {String} accountId The id associated with the CDP account
   * @param {Object} payload The payload with standing instructions information
   * @param {String} payload.bankAccountNo
   * @param {String} payload.bankId
   * @param {String} payload.status  (TERMINATE|CREATE)
   * @param {String} [payload.srcCurrency] (For release v1.1 hard code to 'ALL')
   * @param {String} [payload.destCurrency] (For release v1.1 hard code to 'SGD)
   * @param {String} [payload.beneficiaryAccountType] (Always 1)
   * @return {Promise<Object>} Returns an object
   */
  setStandingInstruction(accountId, payload) {
    return this._authFetchText(this._endpoints.CCY_STANDING_INSTRUCTIONS, {
      method: 'POST',
      headers: {
        [this._requestHeaders.accountId]: accountId
      },
      body: JSON.stringify(Object.assign({
        srcCurrency: 'ALL',
        destCurrency: 'SGD',
        beneficiaryAccountType: '1'
      }, payload))
    })
      .catch(error => Promise.reject(error));
  }

  /**
   * Create a DCS with CCY/FX standing instruction.
   * @param {String} accountId The id associated with the CDP account
   * @param {Object} payload The payload with DCS & standing instructions information
   * @param {String} payload.bankAccountNo
   * @param {String} payload.bankId
   * @param {String} [payload.srcCurrency]
   * @param {String} [payload.destCurrency]
   * @return {Promise<Object>} Returns an object
   */
  updateDcsAccountWithStandingInstruction(accountId, payload) {
    return this._authFetchText(this._endpoints.CCY_WITH_DCS, {
      method: 'POST',
      headers: {
        [this._requestHeaders.accountId]: accountId
      },
      body: JSON.stringify({
        srcCurrency: 'ALL',
        destCurrency: 'SGD',
        directCreditIndicator: '1',
        beneficiaryAccountType: '1',
        ...payload
      })
    })
      .catch(error => Promise.reject(error));
  }

  /**
   * Get payout information.
   * @param {String} accountId The id associated with the CDP account
   * @param {String} startDate start date for fx payout transactions in YYYY-MM-DD format (see ISO_FORMAT.FULL_DATE in DateUtil)
   * @param {String} endDate end date for fx payout transactions in YYYY-MM-DD format (see ISO_FORMAT.FULL_DATE in DateUtil)
   * @return {Promise<Object>} Returns an object (result.status=PENDING_SETTLEMENT, SETTLED, CANCELLED, FAILED, REJECTED, EXPIRED, REVERSED)
   */
  getPayouts(accountId, startDate, endDate) {
    const url = URLUtils.setQueryParams(this._endpoints.CCY_PAYOUTS, { startDate, endDate });
    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => response.data || [])
      .catch(error => Promise.reject(error));
  }

  /**
   * Transform returned PTS standing instruction state enum to displayable text.
   * @param {String} status Enum returned from PTS representing standing instruction state
   */
  getDisplayStatus(status) {
    const { CCY_PTS_INSTRUCTION_STATUS, CCY_DISPLAY_INSTRUCTION_STATUS } = this.constants;
    const { ACTIVE, PENDING_ACTIVATION, PENDING_TERMINATION, REJECTED, SUSPENDED, TERMINATED } = CCY_DISPLAY_INSTRUCTION_STATUS;

    const {
      SUBSCRIBED,
      VERIFIED,
      PENDING_VERIFICATION,
      PENDING_SUBMISSION,
      PENDING_EXTERNAL_VERIFICATION,
      PENDING_TERMINATION_PTS,
      REJECTED_INTERNAL,
      REJECTED_EXTERNAL,
      SUSPENDED_PTS,
      TERMINATED_PTS
    } = CCY_PTS_INSTRUCTION_STATUS;

    switch (status) {
      case SUBSCRIBED:
        return ACTIVE;

      case VERIFIED:
      case PENDING_SUBMISSION:
      case PENDING_VERIFICATION:
      case PENDING_EXTERNAL_VERIFICATION:
        return PENDING_ACTIVATION;

      case PENDING_TERMINATION_PTS:
        return PENDING_TERMINATION;

      case REJECTED_EXTERNAL:
      case REJECTED_INTERNAL:
        return REJECTED;

      case SUSPENDED_PTS:
        return SUSPENDED;

      case TERMINATED_PTS:
        return TERMINATED;

      default:
        return '';
    }
  }

  /**
   * Get color associated with each standing instruction status.
   * @param {String} displayStatus Displayed text representing standing instruction status, e.g. 'ACTIVE'
   */
  getDisplayStatusColor(displayStatus) {
    const { CCY_DISPLAY_INSTRUCTION_STATUS } = this.constants;
    const { ACTIVE, PENDING_ACTIVATION, PENDING_TERMINATION, REJECTED, SUSPENDED, TERMINATED } = CCY_DISPLAY_INSTRUCTION_STATUS;

    switch (displayStatus) {
      case ACTIVE:
        return 'green';
      case PENDING_ACTIVATION:
      case PENDING_TERMINATION:
        return 'orange';
      case REJECTED:
      case SUSPENDED:
      case TERMINATED:
        return 'red';
      default:
        return '';
    }
  }


  /**
   * Get display text for subscription associated with each standing instruction status.
   * @param {String} displayStatus Displayed text representing standing instruction status, e.g. 'SUBSCRIBED'
   */
  getDisplayStatusForSubscription(displayStatus) {
    const { CCY_DISPLAY_SUBSCRIPTION_STATUS } = this.constants;
    const { SUBSCRIBED, PENDING, PENDING_ACTIVATION, PENDING_TERMINATION, REJECTED, SUSPENDED, TERMINATED, UNSUBSCRIBED, VERIFIED, PENDING_VERIFICATION, REJECTED_INTERNAL, REJECTED_EXTERNAL } = CCY_DISPLAY_SUBSCRIPTION_STATUS;

    switch (displayStatus.replace('_', ' ')) {
      case SUBSCRIBED:
      case SUSPENDED:
      case PENDING_TERMINATION:
        return SUBSCRIBED;
      case PENDING_ACTIVATION:
      case VERIFIED:
      case PENDING_VERIFICATION:
        return PENDING;
      case REJECTED:
      case REJECTED_INTERNAL:
      case REJECTED_EXTERNAL:
      case TERMINATED:
      case UNSUBSCRIBED:
        return UNSUBSCRIBED;
      default:
        return '';
    }
  }

  /**
   * Get color associated with each standing instruction status for subscription.
   * @param {String} displayStatus Displayed text representing standing instruction status, e.g. 'SUBSCRIBED'
   */
  getDisplayStatusForSubscriptionColor(displayStatus) {
    const { CCY_DISPLAY_SUBSCRIPTION_STATUS } = this.constants;
    const { SUBSCRIBED, PENDING_ACTIVATION, PENDING_TERMINATION, REJECTED, SUSPENDED, TERMINATED, UNSUBSCRIBED } = CCY_DISPLAY_SUBSCRIPTION_STATUS;

    switch (displayStatus.replace('_', ' ')) {
      case SUBSCRIBED:
      case PENDING_TERMINATION:
        return 'green';
      case PENDING_ACTIVATION:
        return 'orange';
      case REJECTED:
      case SUSPENDED:
      case TERMINATED:
      case UNSUBSCRIBED:
        return 'red';
      default:
        return '';
    }
  }
  /**
   * Get color associated with each standing instruction status for subscription.
   * @param {String} displayStatus Displayed text representing standing instruction status, e.g. 'SUBSCRIBED'
   */
  getDisplayStatusForSubscriptionIcon(displayStatus) {
    const { CCY_DISPLAY_SUBSCRIPTION_STATUS } = this.constants;
    const { SUBSCRIBED, PENDING_ACTIVATION, PENDING_TERMINATION, REJECTED, SUSPENDED, TERMINATED, UNSUBSCRIBED, VERIFIED, PENDING_VERIFICATION, REJECTED_INTERNAL, REJECTED_EXTERNAL } = CCY_DISPLAY_SUBSCRIPTION_STATUS;

    switch (displayStatus.replace('_', ' ')) {
      case SUBSCRIBED:
      case PENDING_TERMINATION:
      case SUSPENDED:
        return 'success';
      case PENDING_ACTIVATION:
      case PENDING_VERIFICATION:
      case VERIFIED:
        return 'pending';
      case REJECTED:
      case REJECTED_INTERNAL:
      case REJECTED_EXTERNAL:
      case TERMINATED:
      case UNSUBSCRIBED:
        return 'error';
      default:
        return '';
    }
  }

  /**
   * Get correct date/time property associated with each standing instruction status.
   * @param {String} displayStatus Displayed text representing standing instruction status, e.g. 'ACTIVE'
   */
  getDisplayStatusDate(displayStatus) {
    const { CCY_DISPLAY_INSTRUCTION_STATUS } = this.constants;
    const { ACTIVE, PENDING_ACTIVATION, PENDING_TERMINATION, REJECTED, SUSPENDED, TERMINATED } = CCY_DISPLAY_INSTRUCTION_STATUS;

    switch(displayStatus) {
      case ACTIVE:
        return 'effectiveDate';
      case PENDING_ACTIVATION:
      case PENDING_TERMINATION:
        return '';
      case REJECTED:
        return 'rejectedDateTime';
      case SUSPENDED:
        return 'lastUpdatedDateTime';
      case TERMINATED:
        return 'terminatedDateTime';
      default:
        return '';
    }
  }
}

if (!instance) {
  instance = new CcyService();
}

export default instance;
