import { withInitDOM } from 'sgx-base-code';
import MarketResearchService from 'services/market-research-service';
import ConfigService from 'sgx-config-service';
import i18n from 'sgx-localisation-service';
import tmpl from './widget-media-centre.html';

export default class WidgetMediaCentre extends HTMLElement {
  //#region Custom Element API

  constructor() {
    super();
    this._router = document.getElementById('sgx-app-router');
    this.setData = this.setData.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-media-centre');
    this._container = this.querySelector('.widget-media-centre-container');
    this._count = this.querySelector('.widget-media-centre-count');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._link = this.querySelector('.widget-media-centre-link');
    this._link.href = ConfigService.links.INVESTORS_PORTAL_MARKET_RESEARCH;
  }

  connectedCallback() {

    this._container.setConfig({
      'rowElementName': 'cmp-list-row-market-research',
      'rowClickedCallback': rowData => {
        if (this._router) {
          // TODO fix redirection that refreshes the browser
          this._router.navigateToURL(`${ConfigService.links.INVESTORS_PORTAL_MARKET_RESEARCH}?news=${rowData.id}`);
        }
      }
    });
    MarketResearchService.getMarketResearchLatestListingData()
      .then(this.setData)
      .catch(_ => this._indicator.show({
        status: 'error',
        title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
        description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
      }));
  }

  setData(data) {
    if (!data || !data.length) {
      this._indicator.show({
        status: 'neutral',
        description: i18n.getTranslation('app.shared-text.status-indicator.empty.description')
      });
      return;
    }
    this._indicator.hide();
    const mediaUpdates = data.slice(0, 3);
    this._count.textContent = i18n.getTranslation('app.widget-media-centre.updates', { count: mediaUpdates.length })
    this._container.setData(mediaUpdates);
  }
}

customElements.define('widget-media-centre', withInitDOM(WidgetMediaCentre));
