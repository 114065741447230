module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-session-timeout\">\n  <sgx-dialog class=\"session-timeout-dialog\" role=\"dialog\">\n    <h1 data-dialog=\"title\" style=\"display: none\"></h1>\n    <div data-dialog=\"body\">\n      <div class=\"status-indicator-container\">\n        <sgx-status-indicator class=\"session-timeout-indicator\"></sgx-status-indicator>\n      </div>\n    </div>\n    <button class=\"continue-session sgx-button--primary sgx-button--small\" data-dialog=\"ok\" data-i18n=\"cmp-session-timeout.buttons.continue-session\"></button>\n    <button class=\"logout-session sgx-button--link sgx-button--small\" data-dialog=\"cancel\" data-i18n=\"cmp-session-timeout.buttons.logout\"></button>\n  </sgx-dialog>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()