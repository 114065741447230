import SgxDataModel from 'sgx-data-model/src/sgx-data-model';
import CompanyAnnouncementsAggregator from 'aggregators/company-announcements-aggregator';
import AnnouncementsService from 'sgx-announcements-service';

let dataInitialized = false;
let pageStart = 1;
let totalDataSize = 0;
let fetchMoreAt = 0;
let isFetching = false;
let totalRecords = 0;

class SgxAnnouncementsModel extends SgxDataModel {


  setConfig(config) {
    super.setConfig(config);
    this._percentageResultsShown = config.modelLazyLoad ? config.modelLazyLoad.threshold : undefined;
    this._pageSize = config.modelLazyLoad ? config.modelLazyLoad.pageSize : 250;
  }

  /*
  * @returns {Promise<Object>} returns an object with array[0] = count, array[1] = array of announcements
  */
  getDataRange(fromIndex, length) {
    const filters = {
      ...this._toFilterObject(this._model._state.filters),
      pageSize: this._pageSize,
      pageStart
    };

    this._offset = fromIndex;

    if (!dataInitialized) {
      return this._executeApi(filters, 'getDataRange').then(res => {
        dataInitialized = true;
        return Promise.resolve(res);
      });
    }

    let from = fromIndex || 0;
    let tempTo = (from + (length || 0));
    let to = tempTo > this.getFilteredRowCount() ? this.getFilteredRowCount() : tempTo;

    const results = this._model._data.keys.slice(from, to).map(function (key) {
      return this._model._data.values[key];
    }, this);

    if (to >= fetchMoreAt && totalDataSize < totalRecords && !isFetching) {
      isFetching = true;
      this._fetchDataPromise = this._executeApi(filters, 'getDataRange')
        .then(res => {
          this._fetchDataPromise = null;
          isFetching = false;
          return isFetching;
        });
    }

    return Promise.resolve(results);
  }

  whenQueryCompletes() {
    return this._fetchDataPromise ? this._fetchDataPromise.then( _ => true) : Promise.resolve(true);
  }

  // #region - Methods required for Filter Dialog
  /**
   * Overrides the implementation of getDataForState when limit is count
   * to work with the filters dialog.
   * the getDataRange but doesn't trigger or dispatch the update event of the model.
   * @return {Promise<Any>} returns the total number of filtered data for limit='count'
   */
  getDataForState(state, limit) {
    if (limit === 'count') {
      const filters = {
        ...this._toFilterObject(state.filters || {}),
        pageSize: 1
      };
      return this._executeApi(filters, false, Boolean(state.filters.conditions && !state.filters.conditions.length))
        .then(() => {
          return this._meta.noRowsFiltered;
        });
    }
    return super.getDataForState.call(this, state, limit);
  }

  getRowCount() {
    return this._filterDialogTotalRows || 0;
  }

  getFilteredRowCount(isExternal) {
    return isExternal ? this._filteredRowCountExternal :  this.filterDialogNoRowsFiltered || 0;
  }

  getRowIndex(rowId) {
    if (typeof rowId === 'undefined') {
      return Promise.reject('rowId must be provided');
    }
    return Promise.resolve(this._model._data.keys.indexOf(rowId));
  }

  setState(state) {
    pageStart = 1;
    dataInitialized = false;
    const filters = {
      ...this._toFilterObject(state.filters || {}),
      pageSize: 1
    };
    return AnnouncementsService.getAnnouncementsCount(filters).then(count => {
      this.filterDialogNoRowsFiltered = this._pageSize; // update scrollbar
      this._filterDialogTotalRows = Number(count); // update records displayed count
      return super.setState(state);
    });
  }

  // #endregion


  /**
   * Executes Market Data APi using the CMS service
   *
   * @param {Object} [filters] simple key value object format (output from calling `_toFilterObject` method)
   * @return {Promise<Array>} an array of market updates
   */
  _executeApi(filters, action, isHiddenState) {
    return CompanyAnnouncementsAggregator.getAnnouncementsAndCount(filters).then(({count, data}) => {
      const parsedCount = Number(count); // converts [] to 0 for queries with invalid date
      // This will be the overall unfiltered count that will be displayed on the filter dialog and will only be set once
      if (!this._filterDialogTotalRows) {
        this._filterDialogTotalRows = parsedCount;
      }

      if (!isHiddenState) {
        this._filteredRowCountExternal = parsedCount;
      }

      totalRecords = parsedCount;

      // Update meta info
      this._meta.noRowsAll = this._filterDialogTotalRows;
      this._meta.noRowsFiltered = parsedCount;

      if (action && typeof action === 'string') {

        if (dataInitialized) {
          this._model.appendData(data);
        } else {
          this._model.setData(data);
        }

        totalDataSize = this._model._data.keys.length; //total data-sets already added to the model.
        fetchMoreAt = Math.floor(this._percentageResultsShown * totalDataSize);
        pageStart += 1;
        this.filterDialogNoRowsFiltered = totalDataSize;

        const payload = {
          action,
          meta: {
            noRowsAll: this._filterDialogTotalRows,
            noRowsFiltered: parsedCount
          }
        };
        if (typeof data !== 'undefined') {
          payload.result = data;
        }
        this.dispatchEvent(new CustomEvent('update', {detail: payload}));
      }
      return data;
    });
  }

  /**
   * Converts model state filter into simple object by just getting the filter key and value of each filter
   *
   * @param {Object} [filters] model state filter format
   * @param {Array<Object>} [filters.conditions] conditions format of the filter's state
   * @return {Object} simple key value object format
   */
  _toFilterObject({conditions}) {
    if (!conditions) {
      return {};
    }
    return conditions.reduce((filters, {columnId, value, method}) => {
      if (value == null) {
        return filters;
      }
      switch (columnId) {
        case 'broadcast_date_time':
          let fromDate = value.fromDate ? CompanyAnnouncementsAggregator.getValidatedFromDate(value.fromDate) : CompanyAnnouncementsAggregator.getValidatedFromDate(null, true, -(5));
          let toDate = value.toDate ? CompanyAnnouncementsAggregator.getValidatedToDate(value.toDate) : CompanyAnnouncementsAggregator.getValidatedToDate(null, true, 0);
          switch (method) {
            case 'after':
              filters['from'] = fromDate.add(1, 'day');
              break;
            case 'before':
              filters['from'] = fromDate; // a from date is always required by our api endpoint
              filters['to'] = toDate.subtract(1, 'day');
              break;
            case 'equals':
              filters['from'] = fromDate;
              break;
            default:
              filters['from'] = fromDate;
              filters['to'] = toDate;
              break;
          }
          break;
        case 'cat':
          filters['categories'] = value;
          break;
        case 'name':
          if (value) {
            filters['searchValue'] = value;
            filters['searchType'] = 'company';

            if (method === 'equals') {
              filters['exactsearch'] = true;
            }

          }
          break;
        case 'securityCode':
          if (value) {
            filters['searchValue'] = value;
            filters['searchType'] = 'securitycode';

            if (method === 'equals') {
              filters['exactsearch'] = true;
            }

          }
          break;
        default:
          break;
      }
      return filters;
    }, {});
  }
}


customElements.define('cmp-data-model-announcements', SgxAnnouncementsModel);
export default SgxAnnouncementsModel;
