import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-dcs-dialog.html';
import ReferenceDataService from 'services/reference-data-service';
import i18n from '@sgx/sgx-localisation-service';
import { fromEvent, of, merge } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';
import { isComplete, isInvalid } from 'utils/form-util';
import BankAccountService from 'services/bank-account-service';
import CcyService from 'services/ccy-service';
import StoreRegistry from 'stores/store-registry';
import ConfigService from 'sgx-config-service';
import DeviceService from 'sgx-device-service';
import get from 'lodash/get';

const { TERMINATED, REJECTED } = BankAccountService.constants.DISPLAY_ACCOUNT_STATUS;
const { SUBSCRIBED, SUSPENDED } = CcyService.constants.CCY_DISPLAY_SUBSCRIPTION_STATUS;

class AccountBankDialogWidget extends HTMLElement {
  constructor() {
    super();
    this._subscriptions = [];
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-dcs-dialog');
    this._dialog = this.querySelector('.widget-dcs-dialog');
    this._form = this.querySelector('.widget-dcs-dialog-form');
    this._title = this.querySelector('.widget-dcs-dialog-title');
    this._bankInput = this.querySelector('.widget-dcs-dialog-bank');
    this._numberInput = this.querySelector('.widget-dcs-dialog-number');
    this._ccySubscriptionInput = this.querySelector('.widget-dcs-dialog-ccy');
    this._termsInput = this.querySelector('.widget-dcs-dialog-terms');
    this._dismissButton = this.querySelector('.widget-dcs-dialog-button-dismiss');
    this._confirmButton = this.querySelector('.widget-dcs-dialog-button-confirm');
    this._backButton = this.querySelector('.widget-dcs-dialog-button-back');
    this._okButton = this.querySelector('.widget-dcs-dialog-button-ok');
    this._indicator = this.querySelector('sgx-status-indicator');
  }

  connectedCallback() {
    // Set up store
    this._store = StoreRegistry.settingsAccountBankDialog;
    this._cdpSession = StoreRegistry.cdpSession;
    this._dcsDisplayStore = StoreRegistry.dcsDisplay;

    // Get available banks
    ReferenceDataService.getBanks().then(banks => {
      // Format object to shape what sgx radio list wants
      const bankLogos = this._updateBanksOrder([...banks.data]).map(bank => {
        return {
          'value': get(bank, 'data.bankId'),
          'label': `<img src="${get(bank, 'data.logo.data.data.image.url')}" alt="${get(bank, 'data.logo.data.data.image.alt')}"></img>`
        }
      });
      this._bankInput.setOptions(bankLogos);
    });

    // Delay required to ensure form listeners are applied after dialog setup
    setTimeout(() => this._setListeners(), 0);
  }

  disconnectedCallback() {
    (this._subscriptions || []).forEach(sub => sub && sub.unsubscribe());
  }

  get _isCcySubcribed() {
    const status = this._cdpSession.getData('status');
    return this._autoCcy || !!~[SUBSCRIBED, SUSPENDED].indexOf(status);
  }

  /*
  * Till CMS can support the ordering of bank at author end, business needs order to be altered at render
  * */
  _updateBanksOrder(banks){
    return banks.sort((a, b) => {
      return a.data.name < b.data.name ? -1 : 1
    })
  }

  _setListeners() {
    // Construct rule for presence
    const validate = this._form.validator.validate;
    const presenceRule = {
      presence: {
        message: i18n.getTranslation('app.shared-text.form-validation.is-required')
      }
    };

    const bankAccountNumberRule = {
      presence: {
        message: i18n.getTranslation('app.widget-dcs-dialog.inputs.accountNumber.validation')
      }
    }

    const numberFormatRule = {
      format: {
        pattern: '^$|^([0-9]{9,12})$',
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {
          fieldName: i18n.getTranslation('app.widget-dcs-dialog.inputs.accountNumber.label')
        })
      }
    };

    // Fill config with presence rules
    const config = {
      alignment: 'vertical',
      validate: {
        rules: {
          bankAccountNo: { ...bankAccountNumberRule, ...numberFormatRule },
          bankId: presenceRule,
          accountTerms: {
            presence: {
              message: i18n.getTranslation('app.widget-dcs-dialog.inputs.terms.validation')
            }
          }
        }
      }
    };

    this._form.setConfig(config);

    // Subscribe to form changes
    this._subscriptions.push(
      merge(
        fromEvent(this._form, 'input'),
        fromEvent(this._form, 'change')
      )
      .pipe(
        debounceTime(10),
        switchMap(() => of(this._form.getJsonData())),
      )
      .subscribe(formData => {
        this._store.setData(formData);

        // Enable or disable update button based on whether data is pristine or invalid
        if (isComplete(this._form, ['ccySubscription']) && !isInvalid(this._form)) {
          this._confirmButton.removeAttribute('disabled');
        } else {
          this._confirmButton.setAttribute('disabled', true);
        }
      })
    );

    // Disable default form submit event
    this._subscriptions.push(fromEvent(this._form, 'submit')
      .subscribe(e => {
        e.preventDefault();
      })
    );

    // Listen for submit on child form, timeout to allow sgx-form to finish creating child form
    setTimeout(() => {
      this._childForm = this._form.firstElementChild;
      this._subscriptions.push(fromEvent(this._childForm, 'submit')
        .subscribe(e => {
          e.preventDefault();
          e.stopImmediatePropagation();
        })
      );
    }, 0);
  }

  _updateTermLink() {
    const anchor = this._termsInput.querySelector('a');
    if (anchor.href !== ConfigService.links.DCS_TERMS_OF_USE) {
      anchor.href = ConfigService.links.DCS_TERMS_OF_USE;
    }
  }

  /**
   * Calls the endpoint to update the user's DCS account.
   */
  _updateDcs(accountId) {
    const dcs = StoreRegistry.settingsAccountBankDialog.getData();

    delete dcs.accountTerms;
    delete dcs.ccySubscription;

    return new Promise(resolve => {
      if (this._isCcySubcribed || !!this._ccySubscriptionInput.value) {
        resolve(CcyService.updateDcsAccountWithStandingInstruction(accountId, { ...dcs }));
      } else {
        resolve(BankAccountService.updateDcsAccount(accountId, { ...dcs }));
      }
    })
      .then(response => this._handleFormSubmit(response, dcs))
      .catch(error => this._handleFormSubmit(error, dcs));
  }

  _handleFormSubmit(response) {
    const { status } = response;

    return response.json()
      .then(responseBody => ({ response: responseBody, status }));
  }

  _resetButtonsState() {
    this._confirmButton.removeAttribute('disabled');
    this._dismissButton.removeAttribute('disabled');
    this._confirmButton.classList.remove('sgx-hidden');
    this._dismissButton.classList.remove('sgx-hidden');
    this._okButton.classList.add('sgx-hidden');
    this._backButton.classList.add('sgx-hidden');
  }

  _reset() {
    this._form.reset();
    this._resetButtonsState();
    this._hideIndicator();
  }

  _shouldShowCcySubscription() {
    if (this._isCcySubcribed) {
      this._ccySubscriptionInput.classList.add('sgx-hidden');
    } else {
      this._ccySubscriptionInput.classList.remove('sgx-hidden');
    }
  }

  _onConfirm() {
    const dcsDisplay = this._dcsDisplayStore.getData() || [];
    // account exist if there is an existing dcs in the list with a same bank, account number and status is not terminated/rejected
    const isAccountExist = dcsDisplay.some(({ dcs }) => dcs.accountNumber === this._numberInput.value
      && dcs.bankId === this._bankInput.value
      && !~[TERMINATED, REJECTED, SUSPENDED].indexOf(dcs.status));
    if (isAccountExist) {
      this._confirmButton.classList.add('sgx-hidden');
      this._backButton.classList.remove('sgx-hidden');
      this._dismissButton.classList.remove('sgx-hidden');
      this._dismissButton.removeAttribute('disabled');
      this._showIndicator({
        status: 'error',
        title: i18n.getTranslation(`app.widget-dcs-dialog.states.account-exist.title`),
        description: i18n.getTranslation(`app.widget-dcs-dialog.states.account-exist.description`)
      });
      return;
    }

    this._showIndicator();
    this._dismissButton.setAttribute('disabled', true);
    this._confirmButton.setAttribute('disabled', true);
    this._updateDcs(this._accountId).then(({ response, status }) => {
      let indicatorStatus = 'success';
      this._status = status;
      this._dismissButton.classList.add('sgx-hidden');
      this._confirmButton.classList.add('sgx-hidden');
      switch(status) {
        case 200:
        case 201:
        case 202:
          indicatorStatus = status === 202 ? 'pending' : indicatorStatus;
          StoreRegistry.cdpSession.setData({...StoreRegistry.cdpSession.getData(), ccyUpdated: true});
          this._okButton.classList.remove('sgx-hidden');
          break;
        default:
          const reason = response.errors[0].reason;
          indicatorStatus = status === 400 && !!~['PREVIOUS_REQUEST_PENDING', 'REQUEST_EXIST'].indexOf(reason)
            ? 'account-exist' : 'generic-error';
          this._dismissButton.classList.remove('sgx-hidden');
          this._dismissButton.removeAttribute('disabled');
          this._backButton.classList.remove('sgx-hidden');
      }
      this._showIndicator({
        status: !!~['pending', 'success'].indexOf(indicatorStatus) ? 'success' : 'error',
        title: i18n.getTranslation(`app.widget-dcs-dialog.states.${indicatorStatus}.title`),
        description: i18n.getTranslation(`app.widget-dcs-dialog.states.${indicatorStatus}.description`)
      });
    });
  }

  _showIndicator(options) {
    const dialogBody = this._dialog.bodyElement;

    dialogBody.scrollTop = 0;
    dialogBody.style.overflow = 'hidden';
    this._indicator.show(options);
  }

  _hideIndicator() {
    this._dialog.bodyElement.style.overflow = 'auto';
    this._indicator.hide();
  }

  /**
   *
   * @param {*} data
   * @param {string} data.accountId account id
   * @param {boolean} data.autoCcy if true auto subscription to ccy is applied otherwise it will depend on cdpSession.status
   */
  show({ accountId, autoCcy }) {
    this._autoCcy = autoCcy;
    this._accountId = accountId;
    const options = {
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      actions: {
        confirm: _ => {
          this._onConfirm();
        },
        ok: _ => {
          this._dialog.hide(this._status);
        },
        back: _ => {
          this._hideIndicator();
          this._resetButtonsState();
        }
      }
    };
    this._updateTermLink();
    this._reset();
    this._shouldShowCcySubscription();
    return this._dialog.show(options);
  }

  hide() {
    this._dialog.hide();
  }
}

customElements.define('widget-dcs-dialog', withInitDOM(AccountBankDialogWidget));
