import i18n from 'sgx-localisation-service';

const defaultStateData = {
  'order': ['year', 'rank', 'totalScore'],
  'columns': {
    'year': {
      'show': true
    },
    'rank': {
      'show': true
    },
    'totalScore': {
      'show': true
    }
  },
  'filters': {},
  'sorts': {}
};

export default {
  states: {
    activeStateId: 0,
    states: [
      {
        label: 'All',
        data: defaultStateData,
        editable: false
      }
    ]
  },
  options: {
    'model': 'sgx-worker-model',
    'downloadData': false, // show download button
    'columnsConfiguration': false, // show column configure button to hide/show and reorder columns
    'filterable': false, // enable filtering
    'sortable': true, // enable sorting
    'localise': false, // enable localisation
    'reorderable': false, // enable column reordering
    'autoWidth': true, // enable table column width expanding to fit container
  },
  // column configuration, shared with data model
  'columns': {
    'year': {
      'resizable': false,
      'minWidth': 80,
      'label': i18n.getTranslation('app.security-details.gti.table.column.year'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'filter': false,
      'sort': false
    },
    'rank': {
      'resizable': false,
      'minWidth': 80,
      'label': i18n.getTranslation('app.security-details.gti.table.column.rank'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'filter': false,
      'sort': false
    },
    'totalScore': {
      'resizable': false,
      'minWidth': 80,
      'label': i18n.getTranslation('app.security-details.gti.table.column.score'),
      'tooltip': i18n.getTranslation('app.security-details.gti.table.tooltip.score'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text',
      },
      'filter': false,
      'sort': false
    }
  },
  'layout': {
    'desktop': {
      'type': 'infinite',
      'rowHeight': 32,
      'horizontalScrollStep': 96,
      'verticalScrollStep': 96,
      'scrollbar': false,
      'showAllRows': false,
      'height': 360
    },
    'mobile': {
      'type': 'paged',
      'cellPerRow': [3],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5,
      'height': 360
    },
    'tablet': {
      'type': 'paged',
      'cellPerRow': [3],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5,
      'height': 360
    }
  }
};
