module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-stop-paper\">\n  <section class=\"widget-stop-paper-container\">\n    <div class=\"widget-stop-paper-text-container\">\n      <h3 class=\"widget-stop-paper-title\" data-i18n=\"widget-stop-paper.title\"></h3>\n      <p class=\"widget-stop-paper-description\"></p>\n    </div>\n    <div class=\"widget-stop-paper-status-container\">\n      <sgx-status-tag class=\"widget-stop-paper-status-tag\"></sgx-status-tag>\n      <span class=\"widget-stop-paper-status-date sgx-base-text-body-12\"></span>\n    </div>\n  </section>\n\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()