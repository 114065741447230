import {withInitDOM} from 'sgx-base-code';
import tmpl from './widget-tax-residency.html';
import i18n from 'sgx-localisation-service';
import {
  setFieldOptions,
  showHideEl,
} from 'utils/form-util';
import {
  addUniqueCountryValidation,
  getTINChoiceValidation,
  getTINCountryValidations,
  getTINFormatValidations,
  getTINExplanationValidations
} from './utils/tax-residency-validation-utils';
import {fromEvent} from 'rxjs';
import get from 'lodash/get';
import jsonSchema from 'schemas/settings-user';
import ConfigService from '@sgx/sgx-config-service';
import referenceDataService from 'services/reference-data-service';


export const EXCEPTION_COUNTRIES = [
  'AE', 'BH', 'VG', 'BM', 'KY', 'MC', 'MS',
  'OM', 'BS', 'TC', 'AU'
];

export const MANDATORY_TIN_COUNTRIES = [
  'MY', 'IN', 'GB', 'NZ', 'ID', 'CN',
  'HK', 'JP', 'CA', 'NL', 'FR', 'KR', 'CH',
  'DE', 'TW', 'SE', 'SG'
]

/**
 * Tax Residence widget (Each tax residence within tax residency details widget).
 */
class WidgetTaxResidency extends HTMLElement {
  constructor() {
    super();

    this._initForm = this._initForm.bind(this);
    this._hasNoTIN = false;
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-tax-residency');
    this._title = this.querySelector('.widget-tax-residency-title');
    this._countrySelect = this.querySelector('.country-select');
    this._tinQuestionWrapper = this.querySelector('.widget-tax-residency--fields-tinQuestion');
    this._tinQuestionContent = this.querySelector('.widget-tax-residency--fields-tinQuestion-content');
    this._tinQuestionInput = this.querySelector('.widget-tax-residency-tin-question-input');
    this._tinInput = this.querySelector('.widget-tax-residency-tin-input');
    this._tinReasonChoice = this.querySelector('.widget-tax-residency--reason-input');
    this._tinReasonContainer = this.querySelector('.widget-tax-residency--reason-wrapper')
    this._tinReason = this.querySelector('.widget-tax-residency--reason-explanation')
    this._close = this.querySelector('.widget-tax-residency--close');
    this._indicator = this.querySelector('sgx-alert-bar');
    this._index = this.dataset.index || 1;
    this._isFirst = (this._index == 1);
    this._readOnly = false;
    this._fieldSetParent = document.querySelector('widget-tax-residency-fieldsets');
    this.setIndex(this._index);
    this._indicator.setData({
      status: 'warning',
      text: i18n.getTranslation('app.widget-tax-residency.warning.desc_html', { selfCertificationLink: ConfigService.links.SELF_CERTIFICATION_FORM_LINK})
    });

    this._initForm();
  }

  connectedCallback() {
    this._subscriptions = [];

    this._subscriptions.push(fromEvent(this._close, 'click')
    .subscribe(e => {
      e.stopImmediatePropagation();
      const fieldsets = document.querySelector('.widget-tax-residency-fieldsets');
      fieldsets.onclose(this._index);
      this._hasNoTIN = false;
    }));

    this._subscriptions.push(fromEvent(this._countrySelect, 'change')
    .subscribe(e => {
      if (!this._readOnly) {
        let countryValue = null;
        countryValue = e.target.getValue();
        if (countryValue === this._previousCountryInputValue) {
          return;
        }
        if (countryValue === 'OTHERS') {
          this._handleOtherCountries(true);
          return;
        } else {
          this._handleOtherCountries(false);
        }
        this._previousCountryInputValue = countryValue;
        this._tinInput.setValue('');
        this._setTINReason();
        this._toggleTINQuestion(!MANDATORY_TIN_COUNTRIES.includes(countryValue));
        this._fieldSetParent.refreshCountryList();
      }
    }));

    this._subscriptions.push(fromEvent(this._tinQuestionInput, 'change')
      .subscribe(e => {
        const tinQuestionValue = e.target.getValue();
        this._tinReason.setValue('');
        this._tinInput.setValue('');
        const tinReasonChoices = this._tinReasonChoice.querySelectorAll('input');
        for (let i = 0; i < tinReasonChoices.length; i++) {
          tinReasonChoices[i].checked = false;
        }
        this._tinReason.setValue('');
        this._toggleTinQuestionInput(tinQuestionValue);
    }));

    this._subscriptions.push(fromEvent(this._tinReasonChoice, 'change').subscribe(e => {
      const tinReasonValue = e.target.getValue();
      this._tinReason.setValue('');
      this._toggleTinReasonInput(tinReasonValue);
    }));
  }

  updateValidationConfig(validate, config, formRef) {
    config.validate.rules[`taxResidencies[${this._index}]/country`]  = getTINCountryValidations(validate, this._index);
    config.validate.rules[`taxResidencies[${this._index}]/tin`] = getTINFormatValidations(validate, this._index);
    config.validate.rules[`taxResidencies[${this._index}]/reason`] = getTINChoiceValidation(validate);
    config.validate.rules[`taxResidencies[${this._index}]/explanation`] = getTINExplanationValidations(validate, this._index);

    formRef.validator.validate.validators = addUniqueCountryValidation(formRef.validator.validate.validators);

    return config;
  }

  reset() {
    this.setAttribute('data-index', 0);
    this._countrySelect.setValue('');
    this._tinInput.setValue('');
    this._tinQuestionInput.setValue('');
    this._tinReasonChoice.setValue('');
    this._tinReason.setValue('');
    showHideEl(this._indicator, false);
  }

  getData() {
    const tinValue = this._tinInput.getValue();
    return {
      'country': this._countrySelect.getValue(),
      'tin': this._hasNoTIN ? null : tinValue,
      'reason': this._tinReasonChoice.getValue() || null,
      'explanation': this._tinReason.getValue() || null
    }
  }

  setData(taxResidencyDetails={}) {
    const countries = this._countryList;
    this._countrySelect.setOptions((get(countries, 'data') || []).map((country) => {
      return { value: country.id, label: country.name };
    }), false, false);
    this.setReadOnly();
    this._countrySelect.setValue(taxResidencyDetails.country);
    if (taxResidencyDetails.tin) {
      this._tinInput.setValue(taxResidencyDetails.tin);
      this._hasNoTIN = false;
    } else {
      this._hasNoTIN = true;
      this._tinInput.setValue(i18n.getTranslation('app.widget-tax-residency.notProvided'));
    }

    this.classList.remove('sgx-hidden');
  }

  getSelectedCountry() {
    return this._countrySelect.getValue();
  }

  getIndex() {
    return this._index;
  }

  setIndex(index) {
    this._index = index;
    this.setAttribute('data-index', index);
    this._title.textContent = i18n.getTranslation(`app.widget-tax-residency.title.${index}`);
    this._countrySelect.setAttribute('name', `taxResidencies[${index}]/country`);
    this._tinQuestionInput.setAttribute('name', `_control/taxResidencies[${index}]/question`);
    this._tinInput.setAttribute('name', `taxResidencies[${index}]/tin`);
    this._tinReasonChoice.setAttribute('name', `taxResidencies[${this._index}]/reason`);
    this._tinReason.setAttribute('name', `taxResidencies[${this._index}]/explanation`);
  }

  getCountryInput() {
    return this._countrySelect;
  }

  setEditable(canEdit) {
    showHideEl(this._close, canEdit);
  }

  _handleOtherCountries(showWarning = true) {
    showHideEl(this._tinQuestionWrapper, !showWarning);
    showHideEl(this._tinInput, !showWarning);
    showHideEl(this._tinReasonContainer, !showWarning);
    showHideEl(this._indicator, showWarning);
  }

  getCountryInput() {
    return this._countrySelect;
  }

  async _initForm() {
    setFieldOptions(this._countrySelect, get(jsonSchema, 'definitions.taxResidencyCountry.enum'), 'app.widget-tax-residency.countryInput.options');
    this._tinQuestionInput.setOptions([
      { label: i18n.getTranslation('app.widget-tax-residency.tinQuestion.yes'), value: true },
      { label: i18n.getTranslation('app.widget-tax-residency.tinQuestion.no'), value: false }]);
    setFieldOptions(this._tinReasonChoice, get(jsonSchema, 'definitions.tinReason.enum'), 'app.widget-tax-residency.tinReasonChoice');
    this._countryList = await referenceDataService.getCountries();
  }

  isReadOnly() {
    return this._readOnly;
  }

  setReadOnly(readOnly = true) {
    this._readOnly = readOnly;
    this._countrySelect.setReadOnly(readOnly);
    this._tinInput.setReadOnly(readOnly);
    this._tinQuestionInput.setReadOnly(readOnly);
    this._tinReasonChoice.setReadOnly(readOnly);
    this._tinReason.setReadOnly(readOnly);
    showHideEl(this._tinQuestionContent, !readOnly);

    //Only when new tax residency is added readonly will be false.
    if (!readOnly) {
      this._tinInput.setLabel(i18n.getTranslation('app.widget-tax-residency.tinNumberInput.label'));
      this._countrySelect.setValue('');
      this._tinQuestionInput.setValue('');
      this._toggleTinQuestionInput(this._tinQuestionInput.getValue());
      this._tinInput.setValue('');
    } else {
      this._tinInput.setLabel(i18n.getTranslation('app.widget-tax-residency.tinNumberInput.label-readonly'));
    }

    this._fieldSetParent.refreshCountryList();
    this._toggleTINQuestion(!readOnly);
  }

  /**
   * @private
   *
   * This method is used to show applicable reasons for not providing TIN based on
   * the country selection. REFER: https://jira.sgx.com/browse/INVESTORS-2111 for
   * more details.
   */
  _setTINReason() {
    const selectedCountry = this._countrySelect.getValue();
    let reasonList = get(jsonSchema, 'definitions.tinReason.enum');
    const NO_TIN_COUNTRIES = ['AE', 'BH', 'VG', 'BM', 'KY', 'MC', 'MS', 'OM', 'BS', 'TC'];
    let filteredReason = '';

    if (NO_TIN_COUNTRIES.includes(selectedCountry)) {
      filteredReason = 'NOT_REQUIRED';
    } else if (selectedCountry === 'AU') {
      filteredReason = 'DOES_NOT_ISSUE';
    }

    if (filteredReason) {
      reasonList = reasonList.filter(reason => {
        return reason !== filteredReason;
      });
    }

    if (!EXCEPTION_COUNTRIES.includes(selectedCountry)) {
      reasonList = reasonList.filter(reason => reason === 'UNABLE_TO_OBTAIN');
    }
    // It's better to reset the value on country change. so it doesnt confuse user.
    this._tinReasonChoice.setValue('');
    setFieldOptions(this._tinReasonChoice, reasonList, 'app.widget-tax-residency.tinReasonChoice');
    this._toggleTinReasonInput(false);
  }

  _toggleTinReasonInput(value) {
    showHideEl(this._tinReason, (value === 'UNABLE_TO_OBTAIN'));
  }

  _toggleTINQuestion(show) {
    showHideEl(this._tinQuestionInput, show);
    showHideEl(this._tinQuestionWrapper, show);
    this._tinQuestionInput.setValue(true);
    this._tinReason.setValue('');
    this._toggleTinQuestionInput(this._tinQuestionInput.getValue());
  }

  _toggleTinQuestionInput(value) {
    if (value === 'true') {
      showHideEl(this._tinInput, true);
      showHideEl(this._tinReasonContainer, false);
    } else if (value === 'false') {
      showHideEl(this._tinInput, false);
      showHideEl(this._tinReasonContainer, true);
      showHideEl(this._tinReason, false);
      this._tinReasonChoice.setAttribute('name', `taxResidencies[${this._index}]/reason`);
      this._tinReason.setAttribute('name', `taxResidencies[${this._index}]/explanation`);
    } else { // unselected
      showHideEl(this._tinInput, false);
      showHideEl(this._tinReasonContainer, false);
    }
  }
}

customElements.define('widget-tax-residency', withInitDOM(WidgetTaxResidency));
