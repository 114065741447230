import ConfigService from 'sgx-config-service';
import BaseService from 'services/base-service';
import { FetchUtils } from 'sgx-base-code';

/**
 * This module defines a service to fetch metadata information for the authentication process.
 */
class MetaAuthService extends BaseService {

  get RANDOM() {
    return ConfigService.endpoints.META_AUTH_RANDOM_READ;
  }

  /**
   * Gets a random and modulus used to hash authentication payloads.
   * @return {Promise<Object>}
   */
  getRandom(totalParam) {
    return new Promise((resolve, reject) => {
      let url = this.RANDOM;

      if (totalParam) {
        url += `?total=${totalParam}`;
      }

      return FetchUtils.fetch(url)
        .then(response => response.json())
        .then(json => {
          resolve(json.data)
        })
        .catch(() => {
          reject('sgx-login.error.default');
        });
    });
  }
}

let instance = new MetaAuthService();
export default instance;
