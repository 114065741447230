import i18n from 'sgx-localisation-service';

const DATE_FORMAT = i18n.getTranslation('app.format.date.default');

const ownershipDefaultStateData = {
  'order': ['name', 'type', 'holdingsDate', 'holdingsPercentage', 'sharesHeld', 'sharesHeldChange', 'turnoverRating'],
  'columns': {
    'name': {
      'show': true
    },
    'type': {
      'show': true
    },
    'holdingsDate': {
      'show': true
    },
    'holdingsPercentage': {
      'show': true
    },
    'sharesHeld': {
      'show': true
    },
    'sharesHeldChange': {
      'show': true
    },
    'turnoverRating': {
      'show': true
    }
  },
  'filters': {},
  'sorts': {}
};

const ownershipTableConfig = {
  'states': {
    activeStateId: 0,
    states: [
      {
        label: 'All',
        data: ownershipDefaultStateData,
        editable: false
      }
    ]
  },
  'options': {
    'downloadData': false, // show download button
    'columnsConfiguration': false, // show column configure button to hide/show and reorder columns
    'filterable': false, // enable filtering
    'sortable': false, // enable sorting
    'localise': false, // enable localisation
    'reorderable': false, // enable column reordering
    'autoWidth': true, // enable table column width expanding to fit container
    'timezone': 'Asia/Singapore'
  },
  // column configuration, shared with data model
  'columns': {
    'name': {
      'resizable': false,
      'minWidth': 250,
      'label': i18n.getTranslation('app.widget-security-prices-details.ownership.columns.shareholders'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'filter': false,
      'sort': false
    },
    'type': {
      'resizable': false,
      'minWidth': 100,
      'label': i18n.getTranslation('app.widget-security-prices-details.ownership.columns.shareholders-type'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'filter': false,
      'sort': false
    },
    'holdingsDate': {
      'resizable': false,
      'minWidth': 90,
      'label': i18n.getTranslation('app.widget-security-prices-details.ownership.columns.holdings-date'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'date',
        'format': DATE_FORMAT
      },
      'filter': false,
      'sort': false
    },
    'holdingsPercentage': {
      'resizable': false,
      'minWidth': 100,
      'label': i18n.getTranslation('app.widget-security-prices-details.ownership.columns.percent-shares-held'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'number',
      },
      'filter': false,
      'sort': false
    },
    'sharesHeld': {
      'resizable': false,
      'minWidth': 100,
      'label': i18n.getTranslation('app.widget-security-prices-details.ownership.columns.number-shares-held'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'number',
      },
      'filter': false,
      'sort': false
    },
    'sharesHeldChange': {
      'resizable': false,
      'minWidth': 100,
      'label': i18n.getTranslation('app.widget-security-prices-details.ownership.columns.change-shares-held'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'number',
      },
      'filter': false,
      'sort': false
    },
    'turnoverRating': {
      'resizable': false,
      'minWidth': 60,
      'label': i18n.getTranslation('app.widget-security-prices-details.ownership.columns.turnover'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text',
      },
      'filter': false,
      'sort': false
    }
  },
  'layout': {
    'desktop': {
      'type': 'infinite',
      'rowHeight': 32,
      'horizontalScrollStep': 96,
      'verticalScrollStep': 96,
      'scrollbar': true,
      'showAllRows': false,
      'height': 360
    },
    'mobile': {
      'type': 'paged',
      'cellPerRow': [2, 2, 3],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5,
      'height': 360
    },
    'tablet': {
      'type': 'paged',
      'cellPerRow': [3, 4, 4],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5,
      'height': 360
    }
  }
};

const dividendDefaultStateData = {
  'order': ['exDate', 'recDate', 'datePaid', 'particulars'],
  'columns': {
    'exDate': {
      'show': true
    },
    'recDate': {
      'show': true
    },
    'datePaid': {
      'show': true
    },
    'particulars': {
      'show': true
    }
  },
  'filters': {},
  'sorts': {}
};

const dividendTableConfig = {
  'states': {
    activeStateId: 0,
    states: [
      {
        label: 'All',
        data: dividendDefaultStateData,
        editable: false
      }
    ]
  },
  'options': {
    'downloadData': false, // show download button
    'columnsConfiguration': false, // show column configure button to hide/show and reorder columns
    'filterable': false, // enable filtering
    'sortable': false, // enable sorting
    'localise': false, // enable localisation
    'reorderable': false, // enable column reordering
    'autoWidth': true, // enable table column width expanding to fit container
    'timezone': 'Asia/Singapore'
  },
  // column configuration, shared with data model
  'columns': {
    'exDate': {
      'resizable': false,
      'autoWidth': false,
      'minWidth': 100,
      'label': i18n.getTranslation('app.widget-security-prices-details.dividend.columns.ex-date'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'date',
        'format': DATE_FORMAT
      },
      'filter': false,
      'sort': false
    },
    'recDate': {
      'resizable': false,
      'minWidth': 80,
      'label': i18n.getTranslation('app.widget-security-prices-details.dividend.columns.record-date'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'date',
        'format': DATE_FORMAT
      },
      'filter': false,
      'sort': false
    },
    'datePaid': {
      'resizable': false,
      'minWidth': 80,
      'label': i18n.getTranslation('app.widget-security-prices-details.dividend.columns.payment-date'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'date',
        'format': DATE_FORMAT
      },
      'filter': false,
      'sort': false
    },
    'particulars': {
      'resizable': false,
      'minWidth': 100,
      'label': i18n.getTranslation('app.widget-security-prices-details.dividend.columns.particulars'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'link',
        'newTab': true
      },
      'filter': false,
      'sort': false
    }
  },
  'layout': {
    'desktop': {
      'recDate': 'infinite',
      'rowHeight': 32,
      'horizontalScrollStep': 96,
      'verticalScrollStep': 96,
      'scrollbar': false,
      'showAllRows': false,
      'height': 360
    },
    'mobile': {
      'recDate': 'paged',
      'cellPerRow': [3, 1],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5,
      'height': 360
    },
    'tablet': {
      'recDate': 'paged',
      'cellPerRow': [3, 1],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5,
      'height': 360
    }
  }
};

const stockFactsTabConfig = {
  config: {
    type: 'content',
    editable: false
  },
  state: {
    activeStateId: 0,
    states: [
      {
        label: i18n.getTranslation('app.widget-security-prices-details.tabs.overview'),
        data: '_setOverview'
      },
      {
        label: i18n.getTranslation('app.widget-security-prices-details.tabs.valuation'),
        data: '_setValuation'
      },
      {
        label: i18n.getTranslation('app.widget-security-prices-details.tabs.financials'),
        data: '_setFinancials'
      },
      {
        label: i18n.getTranslation('app.widget-security-prices-details.tabs.dividends'),
        data: '_setDividends'
      },
      {
        label: i18n.getTranslation('app.widget-security-prices-details.tabs.ownership'),
        data: '_setOwnership'
      }
    ]
  }
};

export {
  dividendTableConfig,
  ownershipTableConfig,
  stockFactsTabConfig
};
