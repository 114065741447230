module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-broker-select\">\n  <!-- TODO - use sgx-input-select's label attribute once inline/horizontal label is supported -->\n  <label class=\"widget-broker-select-label sgx-input-label\"></label>\n  <sgx-input-select disabled></sgx-input-select>\n  <p data-i18n=\"widget-broker-select.empty-broker\" class=\"widget-broker-select-empty-desc sgx-base-text-body-12\"></p>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()