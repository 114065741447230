import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-otp-sms.html';
import AuthService from 'services/auth-service';
import { fromEvent } from 'rxjs';
import userService from 'services/user-service';

export default class WidgetOTPSMS extends HTMLElement {

  constructor() {
    super();
    this._subscriptions = [];

    this._setListeners = this._setListeners.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._otpInput = this.querySelector('.otp-sms');
    this.__resendOTPLink = this.querySelector('.resend-otp');
    this._maskedPhoneNumber = this.querySelector('.masked-phone-number');
    this._otpPrompt = this.querySelector('cmp-otp-prompt');
  }

  connectedCallback() {
    this._setListeners();
  }

  setData({maskedMobileNumber}) {
    this._maskedPhoneNumber.textContent = maskedMobileNumber;
  }

  _setListeners() {
    this._setResendOTPListener();
  }

  _setResendOTPListener() {
    this._subscriptions.push(fromEvent(this.__resendOTPLink, 'click')
      .subscribe(e => {
        AuthService.requestToken()
          .then(_ => {
            this.resetInputFields();
          })
          .catch((error) => {
            const _otpPrompt = document.querySelector('cmp-otp-prompt');
            if(_otpPrompt && error) {
              _otpPrompt.show('');
              _otpPrompt.showStatusIndicator('', error);
            }
            console.error(error);
          });
      }))
  }

  handleCancel() {
    this.resetInputFields();
    AuthService.resetPasswordState();
  }

  handleSubmit() {
    if (!this._otpInput.getValue()) {
      return Promise.reject({errors: [{message: 'Please enter OTP'}]})
    }
    return this.changePassword();
  }

  handleError(message) {
    this._otpInput.setMessage(message);
  }

  changePassword() {
    return AuthService.changePassword(this._otpInput.getValue(), true);
  }

  resetInputFields() {
    this._otpInput.setValue('');
    this._otpInput.setMessage('');
  }

  disconnectedCallback() {
    (this._subscriptions || []).forEach(sub => sub.unsubscribe());
  }
}


customElements.define('widget-otp-sms', withInitDOM(WidgetOTPSMS));
