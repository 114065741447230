import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-list-row-tiles-wrapper.html';
import CmsService from 'sgx-cms-service';
import moment from 'moment';
import CompanyAnnouncementsAggregator from 'aggregators/company-announcements-aggregator';
import { constants } from './config';
import StatusIndicatorUtil from 'utils/status-indicator-util';
import ConfigService from 'sgx-config-service';
import i18n from 'sgx-localisation-service';
import DateUtil from 'utils/date-util';
import SGXInViewport from 'sgx-in-viewport';
import { fromEvent } from 'rxjs';
import SgxAnalyticsService from 'sgx-analytics-service';
import { get } from 'lodash';

class WidgetListTiles extends SGXInViewport {
  constructor() {
    super();
    this._bindAllMethods = this._bindAllMethods.bind(this);
  }

  _sendGAEvent(event) {
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    SgxAnalyticsService.sendEvent(
      eventCategory,
      `${this._data.header}`,
      'widget-viewed'
    );
  }

  async connectedCallback() {
    const data = (await this._getData()) || {};
    this.setData(data);
    fromEvent(this, 'onViewPortEnter').subscribe(event => this._sendGAEvent(event));
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-list-row-tiles-wrapper');
    this._bindAllMethods();
    this._setAllSelectors = this._setAllSelectors.bind(this);
    this._setAllSelectors();
    super.initDOM();
  }

  /**
   * Sets value to the html template
   * @param {object} data
   * @param {string} data.header
   * @param {Array} data.mainContent
   * @param {string} data.mainContent.title
   * @param {string} data.mainContent.title.main
   * @param {string|Array} data.mainContent.title.sub
   * @param {object} data.mainContent.body
   * @param {string} data.mainContent.body.name
   * @param {object} data.mainContent.body.link
   * @param {string} data.mainContent.body.link.href
   * @param {string} data.mainContent.body.link.text
   * @param {object} data.footer
   * @param {object} data.footer.link
   * @param {string} data.footer.link.text
   * @param {string} data.footer.link.href
   * @param {string} data.footer.text
   *
   * {
   *    header: '',
   *    mainContent: [
   *      {
   *        title: {main: '', sub: '' || []},
   *        body: {name: '', link: {href: '', text: ''}},
   *      }
   *    ],
   *    footer: {link: {href: '', text: ''}, text: ''}
   * }
   *
   */
  setData(data) {
    if (!data) return;
    this._data = data;
    try {
      if (this._data.header) this._setHeaderData();
      if (this._data.mainContent) this._setMainContent();
      if (this._data.footer) this._setFooterData();
    } catch (error) {
      console.error('widget-list-row-tiles-wrapper - setData()', error);
    }
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  _bindAllMethods() {
    this._setAllSelectors = this._setAllSelectors.bind(this);
    this._setHeaderData = this._setHeaderData.bind(this);
    this._setMainContent = this._setMainContent.bind(this);
    this._setFooterData = this._setFooterData.bind(this);
    this._getData = this._getData.bind(this);
    this._getCompanyAnnouncementData = this._getCompanyAnnouncementData.bind(this);
    this._getMarketUpdateData = this._getMarketUpdateData.bind(this);
    this._showError = this._showError.bind(this);
    this._displayNoDataIndicator = this._displayNoDataIndicator.bind(this);
    this._handleClickEvent = this._handleClickEvent.bind(this)
  }

  _setAllSelectors() {
    this._header = this.querySelector('.widget-list-row-tiles-wrapper--header');
    this._mainContent = this.querySelector('.widget-list-row-tiles-wrapper--main-content');
    this._footer = this.querySelector('.widget-list-row-tiles-wrapper--footer');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._router = document.querySelector('sgx-app-router');
  }

  _setHeaderData() {
    this._header.innerText = this._data.header;
  }

  _setMainContent() {
    for (const object of this._data.mainContent) {
      const element = document.createElement('cmp-list-row-tiles');
      this._mainContent.appendChild(element);
      element.setData(object);

      element['addEventListener']('click', () => this._handleClickEvent(object));
    }
  }

  _handleClickEvent(data) {
    this._router.navigateToURL(data.body.link.href || '');
  }

  _setFooterData() {
    for (const property in this._data.footer) {
      const element = document.createElement('p');
      this._footer.appendChild(element);
      if (property.toLowerCase() === 'link') {
        const anchorElement = document.createElement('a');
        anchorElement.setAttribute('href', this._data.footer.link.href);
        anchorElement.innerText = this._data.footer.link.text;
        element.appendChild(anchorElement);
      } else {
        element.classList.add('sgx-base-text-body-12');
        element.innerText = this._data.footer[property];
      }
    }
  }

  async _getData() {
    StatusIndicatorUtil.displayLoader(this._indicator);

    const dataStoreValue = this.getAttribute('data-store');
    if (dataStoreValue === constants.companyAnnouncement.DATASTORE) {
      // call the service
      return CompanyAnnouncementsAggregator.getAnnouncementsAndCount(constants.companyAnnouncement.serviceConfig)
        .then((result) => {
          return this._getCompanyAnnouncementData(result);
        })
        .catch((e) => {
          console.error(e);
          this._showError();
          return {
            header: constants.companyAnnouncement.headerTitle,
            mainContent: '',
            footer: '',
          };
        });
    } else if (dataStoreValue === constants.marketUpdates.DATASTORE) {
      // call the service
      return CmsService.getMarketResearchLatestListingData()
        .then((response) => {
          return this._getMarketUpdateData(response);
        })
        .catch((e) => {
          console.error(e);
          this._showError();
          return {
            header: constants.marketUpdates.headerTitle,
            mainContent: '',
            footer: '',
          };
        });
    }
  }

  _formatCompanyAnnouncementDate(data) {
    if (isNaN(data)) return data; // to handle for chinese number
    return moment.unix(parseInt(data) / 1000).format(constants.DateFormat);
  }

  _getCompanyAnnouncementData(result) {
    if (!result.data) {
      this._displayNoDataIndicator();
      return {
        header: constants.companyAnnouncement.headerTitle,
        mainContent: '',
        footer: '',
      };
    }
    const toDisplay = [];
    const data = result.data;
    StatusIndicatorUtil.hideStatusIndicator(this._indicator);
    for (const OBJ of data) {
      toDisplay.push({
        title: {
          main: this._formatCompanyAnnouncementDate(OBJ.broadcast_date_time) || '',
          sub: OBJ.category_name || '',
        },
        body: {
          name: OBJ.issuer_name,
          link: {
            href: OBJ.url || '',
            text: OBJ.title || '',
          },
        },
      });
    }
    return {
      header: constants.companyAnnouncement.headerTitle,
      mainContent: toDisplay,
      footer: constants.companyAnnouncement.footer,
    };
  }

  _formatMarketUpdateDate(data) {
    return DateUtil.formatDateTo(data, 'X', i18n.getTranslation(constants.DateFormat));
  }

  _getMarketUpdateData(response) {
    if (!response.data) {
      this._displayNoDataIndicator();
      return {
        header: constants.marketUpdates.headerTitle,
        mainContent: '',
        footer: '',
      };
    }
    const toDisplay = [];
    const data = response.data;
    StatusIndicatorUtil.hideStatusIndicator(this._indicator);
    const latestThreedata = data.list.results.slice(0, 3);
    for (const OBJ of latestThreedata) {
      const subHeader = [];
      if (typeof OBJ.data.assetClass !== 'undefined') {
        for (const dataObj of OBJ.data.assetClass) {
          subHeader.push(dataObj.data.data.name || '');
        }
      }

      // to add category in the list
      if (typeof OBJ.data.category.data.data.name !== 'undefined') {
        subHeader.push(OBJ.data.category.data.data.name || '');
      }

      toDisplay.push({
        title: {
          main: this._formatMarketUpdateDate(OBJ.data.dateArticle) || '',
          sub: subHeader,
        },
        body: {
          link: {
            href: `${ConfigService.links.INVESTORS_PORTAL_MARKET_RESEARCH}?news=${OBJ.data.id}`,
            text: OBJ.data.title || '',
          },
        },
      });
    } // end of for loop

    return {
      header: constants.marketUpdates.headerTitle,
      mainContent: toDisplay,
      footer: constants.marketUpdates.footer,
    };
  }

  _showError() {
    StatusIndicatorUtil.displayNoData(this._indicator);
  }

  _displayNoDataIndicator() {
    StatusIndicatorUtil.displayNoData(this._indicator);
  }
}

customElements.define('widget-list-row-tiles-wrapper', withInitDOM(WidgetListTiles));
