class WidgetStockScreenerDetails extends HTMLElement {
  show(data) {
    this._details = document.createElement('widget-security-details');
    this.appendChild(this._details);

    this._details.setData(data);
  }


  hide() {
    this.removeChild(this._details);
  }
}

customElements.define('widget-stock-screener-details', WidgetStockScreenerDetails);
