import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-create-account.html';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';
import i18n from 'sgx-localisation-service';
import { isAuthenticated } from 'utils/auth-util';

class CreateAccountWidget extends HTMLElement {
  constructor() {
    super();
    this._lang = StoreRegistry.appSettings.getData('lang') || 'en';
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-create-account');
    this._individual = this.querySelector('.widget-create-account-individual');
    this._joint = this.querySelector('.widget-create-account-joint');
    this._corporate = this.querySelector('.widget-create-account-corporate');
    this._lang = StoreRegistry.appSettings.getData('lang') || 'en';
  }

  connectedCallback() {

    // Toggle UI given auth state
    isAuthenticated()
      ? this.style.display = 'none'
      : this.style.display = 'block';

    // Fill tiles
    const tiles = ['individual', 'joint', 'corporate'];
    tiles.forEach(tile => {
      this[`_${tile}`].setData({
        link: ConfigService.links[`SGX_V2_CREATE_${tile.toUpperCase()}_ACCOUNT`][this._lang.replace(/-/, '_')],
        image: `cdp_${tile}.jpg`,
        text: i18n.getTranslation(`app.widget-create-account.account.${tile}`)
      })
    });
  }
}

customElements.define('widget-create-account', withInitDOM(CreateAccountWidget));
