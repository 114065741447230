/* Dependencies */
const fetchJSON = require('sgx-base-code').FetchUtils.fetchJSON;
const URLUtils = require('sgx-base-code').URLUtils;
const ConfigService = require('sgx-config-service');

var CORPORATE_INFORMATION_API_URL = ConfigService.endpoints.CORPORATE_INFORMATION_API_URL;
var CORPORATE_INFORMATION_API_METALIST_URL = CORPORATE_INFORMATION_API_URL + '/metalist';

let instance = null;
/**
 * MetaData service retrieves different meta information for particular stock code.
 * @module sgx-metadata-service
 * @type {HTMLElement}
 */
class CorporateInformationService {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Fetches an object containing the Corporate Information found by a particular search
   *
   * See the API documentation for a list of possible parameters to include in config
   *
   * @param {Object} [params] an object of optional query parameters that can be appended to the API call
   * @param {String} [params.pagestart] page number to start the search results on
   * @param {String} [params.pagesize] size of each page of results
   * @param {String} [params.ibmcode] ibm code to filter for
   * @param {String} [params.companyname] name of the company to filter for
   * @param {String} [params.country] name of the company to filter for
   * @param {String} [params.market] name of the market to filter for
   * @param {String} [params.order] return the results in ascending or descending order
   * @param {String} [params.orderby] field to order by
   * @returns {Promise<Object>} returns an object containing the Corporate Information items
   */
  getCorporateInformation(params) {
    var url = URLUtils.setQueryParams(CORPORATE_INFORMATION_API_URL, params || {});
    return fetchJSON(url).then(function(response) {
      return response || {};
    });
  }

  /**
   * Fetches an id found by a particular search using ibmcode
   *
   * See the API documentation for a list of possible parameters to include in config
   *
   * @param {String} ibmcode ibmcodename of the company to filter for
   * @returns {Promise<Object>} returns an id
   */
  getCorporateInformationIdByIbmCode(ibmcode) {
    if (!ibmcode) {
      return Promise.resolve(null);
    }
    var url = URLUtils.setQueryParams(CORPORATE_INFORMATION_API_URL, { ibmcode: ibmcode });
    return fetchJSON(url).then(function(response) {
      var id = null;
      if (((response || {}).data || {}).length) {
        id = response.data[0].id;
      }
      return id;
    });
  }

  /**
   * Fetches an object containing the metadata of the Corporate Information items
   *
   * @return {Promise<Object>} Resolves to a metadata object
   */
  getCorporateInformationMetadata() {
    return fetchJSON(CORPORATE_INFORMATION_API_METALIST_URL).then(function(response) {
      return response.data || {};
    });
  }
}

/* Export the singleton */
const corporateInformationServiceInstance = new CorporateInformationService();
export default corporateInformationServiceInstance;
