import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-account-trading-dialog.html';
import i18n from '@sgx/sgx-localisation-service';
import { fromEvent, of } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';
import { isComplete } from 'utils/form-util';
import BlbService from 'services/blb-service';
import StoreRegistry from 'stores/store-registry';

class AccountTradingDialog extends HTMLElement {
  constructor() {
    super();
    this._subscriptions = [];
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-account-trading-dialog');

    // References
    this._dialog = this.querySelector('.widget-trading-dialog');
    this._form = this.querySelector('.widget-trading-dialog-form');
    this._title = this.querySelector('.widget-trading-dialog-title');
    this._description = this.querySelector('.widget-trading-dialog-description');
    this._nameInput = this.querySelector('.widget-trading-dialog-name');
    this._accountNumberInput = this.querySelector('.widget-trading-dialog-account-number');
    this._idNumberInput = this.querySelector('.widget-trading-dialog-id-number');
    this._brokerNameInput = this.querySelector('.widget-trading-dialog-broker-name');
    this._termsInput = this.querySelector('.widget-trading-dialog-terms');
    this._dismissButton = this.querySelector('.widget-trading-dialog-button-dismiss');
    this._confirmButton = this.querySelector('.widget-trading-dialog-button-confirm');
  }

  connectedCallback() {
    // Set up store
    this._store = StoreRegistry.settingsAccountTradingDialog;

    // Fill inputs
    // TODO - Placeholders until service is determined
    this._nameInput.setValue('June Bug');
    this._accountNumberInput.setValue('1234-5678-9XXX');
    this._idNumberInput.setValue('1234-5678-9XXX');

    // Fill broker options
    BlbService.getBlbEligibleBrokers().then(brokers => {

      const allBrokers = [];

      // Format object to shape what sgx radio list wants
      brokers.map(broker => {
        allBrokers.push({
          'value': `${broker.name} - ${broker.id}`,
          'label': ''
        })
      });

      this._brokerNameInput.setOptions(allBrokers);
    });

    // Delay required to ensure form listeners are applied after dialog setup
    setTimeout(() => this._setListeners(), 0);
  }

  disconnectedCallback() {
    (this._subscriptions || []).forEach(sub => sub.unsubscribe());
  }

  _setListeners() {

    // Toggle dialog
    this._subscriptions.push(fromEvent(this._dismissButton, 'click')
      .subscribe(() => {
        this._dialog.hide()
      })
    );

    // Construct rule for presence
    const presenceRule = {
      presence: {
        message: i18n.getTranslation('app.shared-text.form-validation.is-required')
      }
    };

    // Fill config with presence rules
    const config = {
      alignment: 'vertical',
      validate: {
        rules: {
          brokerName: presenceRule,
          terms: presenceRule
        }
      }
    };

    this._form.setConfig(config);

    // Subscribe to form changes
    this._subscriptions.push(fromEvent(this._form, 'input')
      .pipe(
        debounceTime(10),
        switchMap(() => of(this._form.getJsonData())),
      )
      .subscribe(formData => {
        this._store.setData(formData);

        // Enable or disable update button based on whether data is pristine or invalid
        if (isComplete(this._form)) {
          this._confirmButton.removeAttribute('disabled');
        } else {
          this._confirmButton.setAttribute('disabled', true);
        }
      })
    );

    // Disable default form submit event
    this._subscriptions.push(fromEvent(this._form, 'submit')
      .subscribe(e => {
        e.preventDefault();
      })
    );

    // Listen for submit on child form, timeout to allow sgx-form to finish creating child form
    setTimeout(() => {
      this._childForm = this._form.firstElementChild;
      this._subscriptions.push(fromEvent(this._childForm, 'submit')
        .subscribe(e => {

          // TODO - Show loading indicator

          // TODO - Initialize form update call

          // Prevent defaults
          e.preventDefault();
          e.stopImmediatePropagation();
        })
      );
    }, 0);
  }

  /**
   * Sets the broker to set up a blb with.
   * @param {String} name The broker's name.
   * @param {String} code The broker specific code (e.g. 201).
   */
  setBroker(name, code) {
    const value = `${name} - ${code}`;
    this._brokerNameInput.setValue(value);
  }
}

customElements.define('widget-settings-account-trading-dialog', withInitDOM(AccountTradingDialog));
