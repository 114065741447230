let instance = null;

class BlbService {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Get brokers eligible to set up a Broker Linked Balance (BLB) with.
   * @return {Promise<Object>} Returns the names and ids of eligible brokers.
   */
  getBlbEligibleBrokers() {
    return import('../stubs/blb-eligible-brokers.json').then(response => response.data);
  }
}

export default new BlbService();