import * as constants from './bank-account-service-constants';
import BaseService from 'services/base-service';

let instance = null;

class BankAccountService extends BaseService {

  get constants() {
    return constants;
  }

  /**
   * Get bank accounts.
   * @return {Promise<Object>} Returns an object containing bank account information.
   * @param {String} accountId The id associated with the CDP account
   * @return { Object }
   * bankCode bankId
   * 1 - Pending
   * 2 - Pending
   * 3 - Suspended
   * 4 - Active
   * 5 - Terminated
   * 6 - Pending
   * 7 - Pending
   * 8 - Rejected
   * 9 - Rejected
   * createdDateTime dateOfLinkage "2019-06-10"}
   */
  getBankAccounts(accountId) {
    return this._authFetch(this._endpoints.BANK_ACCOUNT_READ, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => (response.data && response.data.dcsStatusDetails) || [])
      .then(accounts => accounts.filter(account => {
        // CDP Internet only supports DCS which is using criteria DirectCreditIndicatorEnum is 1 and PaymentCurrency is SGD.
        // Others will be filtered out as there is no requirement to support Cheque or TT
        return account.directCreditIndicator === '1' && account.paymentCurrency === 'SGD';
      }))
      .catch(error => Promise.reject(error));
  }

  /**
   * Update DCS account.
   * @param {object} dcs payload
   * @param {string} accountId account id
   */
  updateDcsAccount(accountId, dcs) {
    const headers = {};

    headers[this._requestHeaders.accountId] = accountId;

    return this._authFetchText(this._endpoints.BANK_ACCOUNT_UPDATE, {
      method: 'PUT',
      body: JSON.stringify({
        directCreditIndicator: '1',
        currency: 'SGD',
        ...dcs
      }),
      headers: headers
    }).then(response => response)
      .catch(error => Promise.reject(error));
  }

  /**
   * Transform returned PTS account state enum to displayable text.
   * @param {String} status Enum returned from PTS representing account state
   */
  getDisplayStatus(status) {
    const { PTS_ACCOUNT_STATUS, DISPLAY_ACCOUNT_STATUS } = this.constants;
    const { ACTIVE, PENDING_ACTIVATION, REJECTED, SUSPENDED, TERMINATED } = DISPLAY_ACCOUNT_STATUS;

    const {
      ACTIVE_SUBSCRIBED,
      PENDING_SUBMISSION,
      PENDING_VERIFICATION,
      PENDING_EXTERNAL_VERIFICATION,
      PENDING_VERIFIED,
      REJECTED_EXTERNAL,
      REJECTED_INTERNAL,
      SUSPENDED_PTS,
      TERMINATED_PTS
    } = PTS_ACCOUNT_STATUS;

    switch (status) {
      case ACTIVE_SUBSCRIBED:
        return ACTIVE;

      case PENDING_SUBMISSION:
      case PENDING_VERIFICATION:
      case PENDING_EXTERNAL_VERIFICATION:
      case PENDING_VERIFIED:
        return PENDING_ACTIVATION;

      case REJECTED_EXTERNAL:
      case REJECTED_INTERNAL:
        return REJECTED;

      case SUSPENDED_PTS:
        return SUSPENDED;

      case TERMINATED_PTS:
        return TERMINATED;

      default:
        return '';
    }
  }

  /**
   * Get color associated with each account status.
   * @param {String} displayStatus Displayed text representing account status, e.g. 'ACTIVE'
   */
  getDisplayStatusColor(displayStatus) {
    const { DISPLAY_ACCOUNT_STATUS } = this.constants;
    const { ACTIVE, PENDING_ACTIVATION, REJECTED, SUSPENDED, TERMINATED } = DISPLAY_ACCOUNT_STATUS;

    switch (displayStatus) {
      case ACTIVE:
        return 'green';
      case PENDING_ACTIVATION:
        return 'orange';
      case REJECTED:
      case SUSPENDED:
      case TERMINATED:
        return 'red';
      default:
        return '';
    }
  }

  /**
   * Get correct date/time property associated with each account status.
   * @param {String} displayStatus Displayed text representing account status, e.g. 'ACTIVE'
   */
  getDisplayStatusDate(displayStatus) {
    const { DISPLAY_ACCOUNT_STATUS } = this.constants;
    const { ACTIVE, PENDING_ACTIVATION, REJECTED, SUSPENDED, TERMINATED } = DISPLAY_ACCOUNT_STATUS;

    switch(displayStatus) {
      case ACTIVE:
        return 'effectiveDate';
      case PENDING_ACTIVATION:
        return '';
      case REJECTED:
        return 'rejectedDate';
      case SUSPENDED:
        return 'lastUpdatedDateTime';
      case TERMINATED:
        return 'terminatedDateTime';
      default:
        return '';
    }
  }
}

if (!instance) {
  instance = new BankAccountService();
}

export default instance;
