import BaseService from 'services/base-service';

class PrintService extends BaseService {
  /**
   * Generates a pdf report
   * @param {object<string>} body request payload
   * @return {Promise<object>} a promise
   */
  getPdf(body) {
    return this._authFetch(this._endpoints.PRINT_READ, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(result => result && result.data && result.data.resultPdf);
  }

  /**
   * Gets the utils shared instance.
   * @return {PrintService} the utils shared instance
   */
  static get instance() {
    if (!PrintService._instance) {
      PrintService._instance = new PrintService();
    }
    return PrintService._instance;
  }
}

export default PrintService.instance;
