import { withInitDOM } from 'sgx-base-code';
import DeviceService from 'sgx-device-service';
import dialogTemplate from './widget-portfolio-account-details-dialog-template';

const DIALOG_ID = 'widget-portfolio-account-details-dialog';

class WidgetPortfolioAccountDetailsDialog extends HTMLElement {
  initDOM() {
    this._makeDialogIfNeeded();
  }

  showDialog({accountId, accountDesc}) {
    const dialogConfig = {
      minWidth: (window.innerWidth / 2),
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      afterShow: this._afterShowDialog
    };

    this._dialogBody.setData({ accountId, accountDesc });

    return this._dialog.show(dialogConfig)
      .catch(() => {
        this._setListeners(false);
        return Promise.reject();
      });
  }

  _makeDialogIfNeeded() {
    const template = dialogTemplate(DIALOG_ID);
    this._dialog = document.getElementById(DIALOG_ID);

    if (this._dialog) {
      this._dialogBody = this._dialog.querySelector('[data-dialog="body"]');
      return this._dialog;
    }

    document.body.insertAdjacentHTML('beforeend', template);

    this._dialog = document.getElementById(DIALOG_ID);
    this._dialogBody = this._dialog.querySelector('[data-dialog="body"]');
    return this._dialog;
  }
}

customElements.define('widget-portfolio-account-details-dialog', withInitDOM(WidgetPortfolioAccountDetailsDialog));
