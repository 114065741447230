import { withInitDOM, URLUtils } from 'sgx-base-code';
import tmpl from './cmp-tile.html';

class CmpTile extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {}

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-tile');
    this._link = this.querySelector('.tile-link');
    this._mediaContainer = this.querySelector('.tile-media-container');
    this._text = this.querySelector('.tile-text');
  }

  setData({link, image, text, mediaType = 'image'}) {
    this._link.href = link;
    if (mediaType === 'image') {
      if (!/^((http|https):\/\/)/.test(image)) {
        import(`../../assets/images/${image}`).then( /* istanbul ignore next */ path => {
          this._setImage(path.default);
        });
      } else {
        this._setImage(image);
      }
    } else {
      const { search } = URLUtils.parseUrl(link);
      const { v: videoid } = URLUtils.decodeQueryString(search);
      this._mediaContainer.innerHTML = `<sgx-lite-youtube videoid="${videoid}" controls="0"></sgx-lite-youtube>`;
    }
    this._text.textContent = text;
  }

  _setImage(image) {
    this._mediaContainer.innerHTML = `<image class="tile-media" src="${image}"></image>`;
    this._mediaContainer.style.backgroundImage = `url(${image})`;
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('cmp-tile', withInitDOM(CmpTile));
