import tmpl from './widget-join-video-call.html';
import { URLUtils, withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';

class WidgetJoinVideoCall extends HTMLElement {
  constructor() {
    super();
    this._onButtonClick = this._onButtonClick.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._promptText = this.querySelector('.widget-join-video-call-text');
    this._button = this.querySelector('.widget-join-video-call-button');
    this._text2 = this.querySelector('.widget-join-video-call-text-2');
  }

  connectedCallback() {
    this._button.addEventListener('click', this._onButtonClick);
    this._text2.innerHTML = i18n.getTranslation('widget-join-video-call.text-2');
  }

  disconnectedCallback() {
    this._button.removeEventListener('click', this._onButtonClick);
  }

  _onButtonClick(event) {
    const url = '/webex';
    window.location.assign(url);
  }
}

customElements.define('widget-join-video-call', withInitDOM(WidgetJoinVideoCall));
