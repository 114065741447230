import routes from '../routes.json';
import menuData from '../menu.json';

const menu = [...menuData.MENU_ITEMS, ...menuData.GLOBAL_ITEMS];
const payload = routes.reduce((payloadBuilder, route) => {
  if (!route.payload) {
    payloadBuilder[route.name] = route.forwardTo;
    return payloadBuilder;
  }
  menu.forEach(menuItem => {
    const routePathEquivalent = menuItem.link.replace(/^./,'') + '/'; // eg. ./securities becomes /securities/
    if (route.path.indexOf(routePathEquivalent) === 0) { // eg. /securities/:subPage.indexOf /securities/
      route.payload.pageData.subLinks = menuItem.subLinks;
    }
  });
  payloadBuilder[route.name] = route.payload;
  return payloadBuilder;
}, {});

/**
* @function fetchDataMiddleware
* @desc Middleware used to fetch data from stubs temporarily to display data.
* This is where we can update to get data from API and pass it as part
* of newState
* @param  {Object} router Router5 instance of application
* @return {*} {}
*/
function fetchDataMiddleware(/* router */) {
  return (newState, previousState, doneFn) => {
    var name = newState.name;
    var templateData = payload[name];
    if (typeof templateData === 'string') {
      templateData = payload[templateData];
    }
    if (templateData != null) {
      newState.pageData = templateData;
      doneFn();
    } else {
      newState.pageData = payload['404'];
      doneFn();
    }
  };
}

export default fetchDataMiddleware;
