module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-equity-single\">\n  <sgx-status-indicator data-i18n=\"[description]app.shared-text.status-indicator.loading.description\"></sgx-status-indicator>\n  <h3 class=\"widget-equity-single-title\"></h3>\n  <div class=\"widget-equity-single-header\">\n    <div class=\"widget-equity-single-header-title-container\">\n      <div class=\"widget-equity-single-header-title\"></div>\n      <div class=\"widget-equity-single-header-value sgx-icon--before\"></div>\n    </div>\n    <div class=\"widget-equity-single-subheader-title-container\">\n      <div class=\"widget-equity-single-subheader-title\"></div>\n      <div class=\"widget-equity-single-subheader-value sgx-base-text-body\"></div>\n    </div>\n    <div class=\"widget-equity-single-caption sgx-base-text-body\"></div>\n  </div>\n  <div class=\"widget-equity-single-container\">\n    <sgx-chart class=\"widget-equity-single-chart\"></sgx-chart>\n  </div>\n  <div class=\"widget-equity-single-footer\">\n    <a class=\"widget-equity-single-link\"></a>\n    <div class=\"widget-equity-single-timestamp sgx-base-text-caption\"></div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()