import { withInitDOM } from 'sgx-base-code';
import tmpl from './template-about-page.html';
import i18n from 'sgx-localisation-service';
import ConfigService from 'sgx-config-service';
import StoreRegistry from 'stores/store-registry';

/**
 * Dashboard page template.
 */
class TemplateAboutPage extends HTMLElement {
  constructor() {
    super();

    // References
    this._lang = StoreRegistry.appSettings.getData('lang') || 'en';
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-about-page');
  }

  setData() {
    this._setExternalLinks();
  }

  /**
   * Set anchor labels and hrefs using a unique custom attribute.
   */
  _setExternalLinks() {
    // Create array of link references
    const links = Array.from(this.querySelectorAll('a[ref]')).map(link => link.getAttribute('ref'));

    // Set labels and hrefs
    links.forEach(link => {
      const element = this.querySelector(`a[ref=${link}]`);

      // Apply localised labels
      element.textContent = i18n.getTranslation(`app.about.links.${link}`);

      // Apply localised href
      const configLink = `${link.replace(/-/g, '_').toUpperCase()}`;
      element.href = ConfigService.links[configLink][this._lang.replace(/-/g, '_')];
    });
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

customElements.define('template-about-page', withInitDOM(TemplateAboutPage));
