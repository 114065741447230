import { BaseBusService } from 'services/base-bus-service';

/**
 * Gets the QR code elements for SingPass.
 */
class SingPassCloudQrService extends BaseBusService {
  constructor() {
    super();
    this._topic = 'singpass-cloud-qr';
  }

  /**
   * Initialize SingPass QR code module.
   * @param {Object} bus service bus class
   * @param {Array} params SingPass QR code init parameters
   */
  handleRequest(bus, params) {
    return new Promise((resolve, reject) => {
      try {
        window.NDI.initAuthSession(...params);
        resolve();
      } catch (error) {
        console.error('Error initiating singpass ', error);
        reject(error);
      }
    });
  }
}

export default new SingPassCloudQrService();
