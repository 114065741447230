import i18n from 'sgx-localisation-service';

export function addUniqueCountryValidation(validators) {
  return Object.assign(validators, {
    uniqueCountryIf(value, options) {
      let result = null;
      if (value && options.predicate.call(options.scope)) {
        result = options.message.call(options.scope);
      }

      return result;
    }
  });
}

function canValidate(index) {
  const taxFieldsets = document.querySelector('widget-tax-residency-fieldsets');
  const allTaxResidencies = taxFieldsets.getAllTaxResidencies();
  let currentTaxResidency = null;

  for(var i=0; i < allTaxResidencies.length; i++) {
    const taxResidency = allTaxResidencies[i];
    if (taxResidency.getIndex().toString() === index.toString()) {
      currentTaxResidency = taxResidency;
      break;
    }
  }

  return !currentTaxResidency.isReadOnly();
}

export function getTINCountryValidations(validate, index) {
  return {
    presence: {
      message() {
        return validate.format(i18n.getTranslation("app.shared-text.form-validation.is-required-w-field-name"), {
          fieldName: i18n.getTranslation('app.widget-tax-residency.countryInput.label')
        });
      }
    },
    uniqueCountryIf: {
      predicate: () => {
        const taxFieldsets = document.querySelector('widget-tax-residency-fieldsets');
        const allTaxResidencies = taxFieldsets.getAllTaxResidencies();
        const activeTaxResidencies = taxFieldsets.getAllActiveTaxResidencies();
        let currentTaxResidency = null;
        let selectedCountries = [];

        for(var i=0; i < allTaxResidencies.length; i++) {
          const taxResidency = allTaxResidencies[i];
          if (taxResidency.getIndex().toString() === index.toString()) {
            currentTaxResidency = taxResidency;
            break;
          }
        }

        activeTaxResidencies.forEach((taxResidency) => {
          if (taxResidency.getIndex().toString() !== index.toString()) {
            selectedCountries.push(taxResidency.getSelectedCountry());
          }
        });

        const country = currentTaxResidency.getSelectedCountry();
        return selectedCountries.includes(country) && !currentTaxResidency.isReadOnly();
      },
      scope: this,
      message() {
        return validate.format(i18n.getTranslation('app.widget-tax-residency.countryInput.uniqueError'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
      }
    },
  };
}

export function getTINFormatValidations(validate, index) {
  return {
    presenceIf: {
      message() {
        return validate.format(i18n.getTranslation('app.shared-text.form-validation.is-required-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')});
      },
      predicate: (index) => {
        return canValidate(index);
      },
      param: index
    },
    format: {
      pattern: "^$|^(?!.*-\\s*-)(?!.*\\/\\s*\\*)[^&'<>\"#]+$",
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    tinLengthIf: {
      predicate: (index) => {
        return canValidate(index);
      },
      param: index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.too-long-w-field-name'), { fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName') })
    },
    malaysiaTINIf: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        // TODO: Hardcoding the country code is not good practice, if we change all places will break.
        // Instead we can use a key value pair which will be easy to fetch from jsonSchema.
        return country === 'MY' && canValidate(index);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    indiaTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'IN' && canValidate(countryIdx);
      },
      index,
      scope: this,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    UKTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'GB' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    AuTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'AU' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    NzTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'NZ' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    IdrTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'ID' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    ChTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'CN' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    hKTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'HK' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    jpTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'JP' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    caTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'CA' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    ntTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'NL' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    frTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'FR' && canValidate(countryIdx);
      },
      index,
      scope: this,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    krTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'KR' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    swissTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'CH' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    deTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'DE' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    twTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'TW' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    seTINif: {
      predicate: (countryIdx) => {
        let countrySelect = document.querySelector(`sgx-input-select[name="taxResidencies[${countryIdx}]/country"]`);
        const country = countrySelect.getValue();
        return country === 'SE' && canValidate(countryIdx);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    },
    otherTINif: {
      predicate: (index) => {
        return canValidate(index);
      },
      scope: this,
      index,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'), {fieldName: i18n.getTranslation('app.widget-tax-residency.tinNumberInput.fieldName')})
    }
  }
}

export function getTINChoiceValidation(validate) {
  return {
    presence: {
      message() {
        return validate.format(i18n.getTranslation("app.shared-text.form-validation.is-required-w-field-name"), {
          fieldName: i18n.getTranslation('app.widget-tax-residency.tinReasonChoice.fieldName')
        });
      }
    }
  }
}

export function getTINExplanationValidations(validate, index) {
  return {
    presenceIf: {
      message() {
        return validate.format(i18n.getTranslation("app.shared-text.form-validation.is-required-w-field-name"), {
          fieldName: i18n.getTranslation('app.widget-tax-residency.tinReasonChoice.fieldName')
        });
      },
      predicate: (index) => {
        const tinReasonChoice = document.querySelector(`[name="taxResidencies[${index}]/reason"]`);
        return tinReasonChoice.getValue() === 'UNABLE_TO_OBTAIN'
      },
      param: index
    },
    length: {
      maximum: 250,
      message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.too-long-w-field-name'), { fieldName: i18n.getTranslation('app.widget-tax-residency.tinReasonChoice.fieldName') })
    },
  }
}
