import SgxDataModel from '@sgx/sgx-data-model/src/sgx-data-model';
import CompanyAnnouncementsAggregator from 'aggregators/company-announcements-aggregator';
import MarketResearchService from 'services/market-research-service';

let dataInitialized = false;
let pageStart = 0;
let totalRecordsLocally = 0;
let totalRecordsExternally = 0;
let fetchMoreAt = 0;
let isFetching = false;

class CmpMarketResearchDataModel extends SgxDataModel {
  /**
   * Calls the MarketResearchService.getMarketUpdatesListingData that triggers CMS API
   * to get the listing of market updates then formats the result into an array of market updates
   * @return {Promise<Array>} an array of market updates
   */
  setConfig(config) {
    super.setConfig(config);
    this._percentageResultsShown = config.modelLazyLoad ? config.modelLazyLoad.threshold : undefined ;
    this._pageSize = config.modelLazyLoad ? config.modelLazyLoad.pageSize : 250 ;
  }

  getDataRange(fromIndex, length) {
    const filterObject = this._toFilterObject(this._model._state.filters);
    const filters = {
      ...filterObject,
      offset: pageStart,
      limit: this._pageSize
    };
    this._offset = fromIndex;

    if (!dataInitialized) {
      return this._executeApi(filters, 'getDataRange').then( (firstOffset) => {
        dataInitialized = true;
        // return Promise.resolve(firstOffset); // TODO - Use this when below is fixed
        // TODO - Workaround until sgx-list is patched to cater for small limit edge cases
        filters.offset = pageStart;
        return this._executeApi(filters, 'getDataRange').then(secondOffset => {
          return Promise.resolve([...firstOffset, ...secondOffset]);
        });
      });
   }

    // Limit the from and to, to only what we can provide at this point in time
    let from = fromIndex || 0;
    let tempTo = (from + (length || 0)); //!
    let to = tempTo > this.getFilteredRowCount() ? this.getFilteredRowCount() : tempTo;
    const results = this._model._data.keys.slice(from, to).map(function (key) {
      return this._model._data.values[key];
    }, this);

    // If there are more records to fetch and the fetchMoreAt threshold is breached, commence fetching
    if (to >= fetchMoreAt && totalRecordsLocally < totalRecordsExternally && !isFetching) {
      isFetching = true;
      this._fetchDataPromise = this._executeApi(filters, 'getDataRange')
        .then(_ => {
          this._fetchDataPromise = null;
          isFetching = false;
          return isFetching;
        });
    }

    return Promise.resolve(results);
  }

  whenQueryCompletes() {
    return this._fetchDataPromise ? this._fetchDataPromise.then( _ => true) : Promise.resolve(true);
  }

  // #region - Methods required for Filter Dialog
  /**
   * Overrides the implementation of getDataForState when limit is count
   * to work with the filters dialog. This calls MarketResearchService.getMarketUpdatesListingData API like
   * the getDataRange but doesn't trigger or dispatch the update event of the model.
   * @return {Promise<Any>} returns the total number of filtered data for limit='count'
   */
  getDataForState(state, limit) {
    if (limit === 'count') {
      const filters = {
        ...this._toFilterObject(state.filters || {}),
        limit: 1
      };
      return this._executeApi(filters, false, Boolean(state.filters.conditions && !state.filters.conditions.length))
        .then(() => {
          return this._meta.noRowsFiltered;
        });
    }
    return super.getDataForState.call(this, state, limit);
  }

  getRowCount() {
    return this._filterDialogTotalRows || 0;
  }

  getFilteredRowCount(isExternal) {
    return isExternal ? this._filteredRowCountExternal :  this.filterDialogNoRowsFiltered || 0;
  }

  getRowIndex(rowId) {
    if (typeof rowId === 'undefined') {
      return Promise.reject('rowId must be provided');
    }
    return Promise.resolve(this._model._data.keys.indexOf(rowId));
  }

  setState(state) {
    pageStart = 0;
    dataInitialized = false;
    this.filterDialogNoRowsFiltered = this._pageSize; // this is for the initial scrollbar size
    return this._executeApi({category: 26, limit: 1}, false).then(_ => super.setState(state));
  }

  // #endregion

  /**
   * Executes Market Data APi using the CMS service
   *
   * @param {Object} [filters] simple key value object format (output from calling `_toFilterObject` method)
   * @return {Promise<Array>} an array of market updates
   */
  _executeApi(filters, action, isHiddenState) {
    // Retrieve markets that are only relevant to 'Securities' (category=26) as per INVESTORS-43
    filters.category = '26';
    return MarketResearchService.getMarketUpdatesListingData(filters)
      .then(({ data, count }) => {
        const parsedCount = Number(count || 0); // converts [] to 0 for queries with invalid date

        // This will be the overall unfiltered count that will be displayed on the filter dialog and will only be set once
        if (!this._filterDialogTotalRows) {
          this._filterDialogTotalRows = parsedCount;
        }

        if (!isHiddenState) {
          this._filteredRowCountExternal = parsedCount;
          // this.filterDialogNoRowsFiltered = parsedCount;
        }

        totalRecordsExternally = parsedCount;
        // Update meta info
        this._meta.noRowsAll = this._filterDialogTotalRows;
        this._meta.noRowsFiltered = parsedCount;

        if (action && typeof action === 'string') {

          if (dataInitialized) {
            this._model.appendData(data);
          } else {
            this._model.setData(data);
          }

          totalRecordsLocally = this._model._data.keys.length; //total data-sets already added to the model.
          fetchMoreAt = Math.floor(this._percentageResultsShown * totalRecordsLocally);
          pageStart += this._pageSize;
          this.filterDialogNoRowsFiltered = totalRecordsLocally;

          const payload = {
            action,
            meta: {
              noRowsAll: this._filterDialogTotalRows,
              noRowsFiltered: parsedCount
            }
          };

          if (typeof data !== 'undefined') {
            payload.result = data;
          }

          this.dispatchEvent(new CustomEvent('update', { detail: payload }));
        }
        return data;
      });
  }

  /**
   * Converts model state filter into simple object by just getting the filter key and value of each filter
   *
   * @param {Object} [filters] model state filter format
   * @param {Array<Object>} [filters.conditions] conditions format of the filter's state
   * @return {Object} simple key value object format
   */
  _toFilterObject({ conditions }) {
    if (!conditions) { return {}; }

    return conditions.reduce((filters, { columnId, value, method }) => {
      if (value === undefined) { return filters; }

      switch (columnId) {
        case 'title':
          if (value) {
            filters['title'] = `%${value}%`;
            filters['titleFilterEnabled'] = true;
            filters['securityCode'] = value;
            filters['securityCodeFilterEnabled'] = true;
          }
          break;
        case 'dateArticle':
          if (value.fromDate) {
            let fromDate = CompanyAnnouncementsAggregator.getValidatedToDate(value.fromDate, true);
            if (method === 'after') {
              fromDate = fromDate.add(1, 'day');
            }
            filters['fromDate'] = String(fromDate.startOf('day').unix());
          }
          if (value.toDate) {
            let toDate = CompanyAnnouncementsAggregator.getValidatedToDate(value.toDate, true);
            if (method === 'before') {
              toDate = toDate.subtract(1, 'day');
            }
            filters['toDate'] = String(toDate.endOf('day').unix());
          }
          break;
        default:
          const arr = (value && value.split(',')) || [];
          if (arr.length) { filters[columnId] = arr; }
          break;
      }
      return filters;
    }, {});
  }
}

customElements.define('cmp-data-model-market-research', CmpMarketResearchDataModel);
export default CmpMarketResearchDataModel;
