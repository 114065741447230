module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-user-profile-myinfo-confirmation\">\n  <sgx-dialog id=\"cmp-user-profile-myinfo-confirmation-dialog\" role=\"dialog\">\n    <h2 data-dialog=\"title\" class=\"sgx-theme-v2-high-emp-default sgx-base-text-title-20 form-intro-title\"></h2>\n    <div data-dialog=\"body\" class=\"cmp-user-profile-myinfo-confirmation-body\">\n      <p class=\"sgx-base-text-body-16 sgx-theme-v2-high-emp-default myinfo-update-info\"></p>\n      <ul class=\"myinfo-attributes-list sgx-base-list sgx-base-text-body-16 sgx-theme-v2-high-emp-default\"></ul>\n      <sgx-alert-bar class=\"cmp-user-profile-myinfo-nric-alert\"></sgx-alert-bar>\n      <p class=\"sgx-base-text-body-16 sgx-theme-v2-high-emp-default myinfo-data\" data-i18n=\"app.myInfo.data\"></p>\n      <p class=\"sgx-base-text-body-16 sgx-theme-v2-high-emp-default myinfo-disclaimer\"></p>\n    </div>\n    <button  class=\"sgx-button--primary cmp-user-myinfo-button-submit\" data-dialog=\"button\" data-action=\"submit\" data-i18n=\"app.myInfo.button.proceed\"></button>\n    <button class=\"sgx-button--text cmp-user-myinfo-button-cancel\" data-dialog=\"cancel\" data-i18n=\"app.myInfo.button.cancel\"></button>\n  </sgx-dialog>\n  <widget-user-details-myinfo class=\"widget-user-details-myinfo\"></widget-user-details-myinfo>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()