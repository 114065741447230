module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-otp-prompt\">\n  <sgx-dialog class=\"cmp-otp-dialog\" role=\"dialog\">\n\n    <!-- Dialog Header -->\n    <h2 class=\"cmp-otp-prompt-text\" data-dialog=\"title\" data-i18n=\"otp-prompt.label\"></h2>\n\n    <!-- Dialog Body -->\n    <div class=\"cmp-otp-prompt-button-container\" data-dialog=\"body\">\n      <div class=\"cmp-otp-prompt-tabs-container\">\n        <sgx-tabs class=\"cmp-otp-tabs\"></sgx-tabs>\n\n        <div class=\"cmp-otp-tabs-container\">\n          <widget-otp-sms class=\"cmp-otp-tab cmp-otp-container-sms\"></widget-otp-sms>\n          <widget-otp-token class=\"cmp-otp-tab cmp-otp-container-token sgx-hidden\"></widget-otp-token>\n        </div>\n      </div>\n\n      <div class=\"status-indicator-container sgx-hidden\">\n        <sgx-status-indicator class=\"cmp-otp-indicator\"></sgx-status-indicator>\n      </div>\n\n      <!-- Dialog Footer -->\n      <div class=\"cta-container\">\n        <button type=\"button\"\n                class=\"cancel-btn sgx-button--secondary cancel-button\"\n                data-i18n=\"sgx-forms.sgx-button.cancel\"></button>\n\n        <button type=\"button\"\n                class=\"sgx-button--primary submit-button\"\n                data-i18n=\"sgx-forms.sgx-button.confirm\"></button>\n\n        <button type=\"button\"\n                class=\"sgx-button--primary continue-button sgx-hidden\"\n                data-i18n=\"otp-prompt.buttons.continue\"></button>\n      </div>\n\n    </div>\n\n  </sgx-dialog>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()