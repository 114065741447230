import tmpl from './cmp-accordion-item.html';

/**
 * An accordion item used when calling setData on sgx-accordion.
 * Creates required header, collapser and container.
 */
class CmpAccordionItem extends HTMLElement {
  constructor() {
    super();
  }

  /**
   *
   * @param {string} title The text title of the accordion item
   * @param {string} titleHTML The HTML title of the accordion item, if it has one
   * @param {Array.<HTMLElement>} children Children to append to the accordion item
   */
  setData({ title = '', titleHTML, children = [] }) {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-accordion-item');
    this._header = this.querySelector('.sgx-accordion-header');
    this._headerTitle = this.querySelector('.sgx-accordion-title');
    this._container = this.querySelector('.sgx-accordion-container');

    if (titleHTML) {
      this._header.classList.add('sgx-accordion-header--flex');
      this._headerTitle.style.display = 'block';
      this._headerTitle.innerHTML = titleHTML;
    } else {
      this._headerTitle.textContent = title;
    }

    children.forEach(child => this._container.appendChild(child));
  }

  show() {
    this._container.style.display = 'block';
  }

  hide() {
    this._container.style.display = 'none';
  }
}

customElements.define('cmp-accordion-item', CmpAccordionItem);
