import SgxTableCellBase from 'sgx-table/src/sub-components/sgx-table-cell/sgx-table-cell-base';
import {SgxContextMenuService} from 'sgx-context-menu';
import {withInitDOM} from 'sgx-base-code';

/**
 * A custom HTML element used to render cells for portfolio overview
 * @module sgx-table-cell-corporate-action-links
 * @type {HTMLElement}
 */
class SgxTableCellCorporateActionLinks extends SgxTableCellBase {

  connectedCallback() {
    super.connectedCallback();
  }

  setConfig(config, columnConfig, tableConfig) {
  }

  setData(data = [], action) {
    const totalItems = data.length;

    this.innerHTML = '';

    if (totalItems === 0) {
      const span = document.createElement('span');
      span.textContent = 'N/A';
      this.appendChild(span);
      return;
    }

    const link = this._getCALinkItem(data);

    this.appendChild(link);

    if (totalItems === 1) {
      link.style.width = 'auto';
      return;
    }

    const icon = this._getContextMenuIcon();

    this.appendChild(icon);

    setTimeout(_ => {
      this._initContextMenu(data);
    })
  }

  /**
   * @desc get icon to be appended for triggering context meny
   * */
  _getContextMenuIcon() {
    const icon = document.createElement('button');
    icon.classList.add('sgx-icon');
    icon.classList.add('sgx-button--low-emp');
    icon.classList.add('ca-links-menu');
    icon.classList.add('ctx-menu-link');

    return icon;
  }

  /**
   * @desc get CA link item
   * */
  _getCALinkItem(data) {
    const link = document.createElement('a');
    link.className = 'corporate-actions-link-item';
    link.href = data[0].link;
    link.textContent = data[0].label;

    return link;
  }

  /**
   * @desc init context menu
   * */
  _initContextMenu(data = []) {
    const contextMenuIcon = this.querySelector('.ctx-menu-link');
    let items = JSON.parse(JSON.stringify(data));

    items = items.map(({label, link}, index) => ({label, url: link, id: `ctx-menu-item-${index}`}));

    contextMenuIcon.onclick = (evt => SgxContextMenuService.show(items, evt.target, true));
  }


}

customElements.define('sgx-table-cell-corporate-action-links', withInitDOM(SgxTableCellCorporateActionLinks));
