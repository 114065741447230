import { withInitDOM } from 'sgx-base-code';
import MetadataService from 'sgx-metadata-service';
import CorporateActionsService from 'sgx-corporate-actions-service';
import ConfigService from 'sgx-config-service';
import TABLE_CONFIG from './widget-security-details-corporate-actions-config';
import tmpl from './widget-security-details-corporate-actions.html';

class WidgetSecurityDetailsCorporateActions extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-corporate-actions');
    this._table = this.querySelector('sgx-table');
    this._link = this.querySelector('.w-security-details-corporate-actions-link');
    this._table.setConfig(TABLE_CONFIG);
  }

  connectedCallback() {}

  setData(data) {
    this._data = data;
    this._table.classList.add('loading');
    this._getCorporateActions();
  }

  async _getCorporateActions() {
    const { nc } = this._data || {};

    try {
      const metadataResponse = await MetadataService.getMetaDataByStockCode(nc);
      const { ibmCode, fullName } = metadataResponse || {};

      this._data.fullName = fullName;
      const options = {
        pagesize: 10,
        pagestart: 0,
        ibmcode: ibmCode,
        cat: 'dividend'
      };

      if (!ibmCode) {
        return this._renderResults([]);
      }

      const result = await CorporateActionsService.getCorporateActions(options);
      const caData = result.data || [];
      this._renderResults(caData);
      // if (caData.length) {
        this.dispatchEvent(new CustomEvent('widget-security-details-corporate-actions', {bubbles: true}));
      // }
    } catch(_) {
      this._table.classList.remove('loading');
      this._table.classList.add('error');
    };
  }

  recalculateSize() {
    this._table.recalculateSize();
  }

  _renderResults(results) {
    const { fullName } = this._data;
    const data = results || [];
    const tableData = data.map((item, index) => ({...item, id: index}))
      .slice(0, 10); // get first 10 only

    this._table.setData(tableData);
    this._table.recalculateSize();
    this._table.classList.remove('loading');

    if (fullName && data.length !== 0) {
      this._link.classList.remove('sgx-hidden');
      this._link.href = `${ConfigService.links.INVESTORS_PORTAL_CORPORATE_ACTIONS}?value=${fullName}`;
      return;
    }
    this._link.classList.add('sgx-hidden');
  }
}

customElements.define('widget-security-details-corporate-actions', withInitDOM(WidgetSecurityDetailsCorporateActions));
