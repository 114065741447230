const template = (id) => `
  <sgx-dialog id="${id}" role="dialog">
    <h2 data-dialog="title">
      <button class="sgx-button--link sgx-button--small sgx-icon--before screener-dialog-icon--open"
        data-i18n="app.widget-stock-screener.dialog.open" data-action="open"></button>
      <button class="sgx-button--link sgx-button--small sgx-icon--before screener-dialog-icon--close"
        data-i18n="app.widget-stock-screener.dialog.close" data-action="close"></button>
    </h2>
    <widget-stock-screener-details data-dialog="body"></widget-stock-screener-details>
  </sgx-dialog>
`;

export default template;
