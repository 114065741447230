import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DateUtil from 'utils/date-util';
import TradingAccountService from 'services/trading-account-service';
import ReferenceDataService from 'services/reference-data-service';
import get from 'lodash/get';

const ACCOUNT_LINKAGE_STATUS = TradingAccountService.constants.ACCOUNT_LINKAGE_STATUS;

/**
 * List row for account tab in settings page.
 */
import tmpl from './cmp-list-row-account-trading.html';

class CmpListRowAccountTrading extends HTMLElement {
  connectedCallback() {} // Required so initDOM is called before setData

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-account-trading');
    this._accountLogo = this.querySelector('.trading-account-row-logo');
    this._accountNumberInput = this.querySelector('.trading-account-row-number-input');
    this._accountLinkageStatus = this.querySelector('.trading-account-row-state-status');
    this._accountDate = this.querySelector('.trading-account-row-state-date');
    this._blb = this.querySelector('.trading-account-row-blb-item');
    this._blbInput = this.querySelector('.trading-account-row-blb-input');
    this._blbInputElem = this._blbInput.querySelector('input');
    this._blbButton = this.querySelector('.trading-account-row-blb-button');
  }

  setData(data) {
    this._render(data);
  }

  _render(data) {
    const { id, balance, brokerNo, positionAcNo, accountLinkageStatus, accountLinkageDate } = data;

    // Bind button to account id
    this._blbButton.classList.add(`trading-account-row-blb-button--${id}`);

    // Get not applicable text
    const na = i18n.getTranslation('tradingAccountRow.blb.na');

    // Make input read only but do not hide hint
    this._blbInputElem.setAttribute('readonly', '');

    // Render BLB states
    if (balance) {
      this._blbButton.classList.add('trading-account-row--disabled');

      if (accountLinkageStatus === ACCOUNT_LINKAGE_STATUS.SUSPENDED || accountLinkageStatus === ACCOUNT_LINKAGE_STATUS.PENDING) {
        this._blbInput.setValue(na);
      } else {
        this._blbInput.setValue(positionAcNo);
      }
    } else {

      if (accountLinkageStatus === ACCOUNT_LINKAGE_STATUS.SUSPENDED || accountLinkageStatus === ACCOUNT_LINKAGE_STATUS.PENDING) {
        this._blbButton.classList.add('trading-account-row--disabled');
        this._blbInput.setValue(na);
      } else {
        this._blbInputElem.classList.add('trading-account-row--disabled');
        this._blb.style.display = 'block';
      }
    }

    // Provide broker image source
    if (brokerNo) {
      ReferenceDataService.getBroker(brokerNo).then(broker => {
        const logo = document.createElement('img');
        logo.src = get(broker, 'data.logo.data.data.image.url');
        logo.alt = get(broker, 'data.logo.data.data.image.alt');
        this._accountLogo.appendChild(logo);
      });
    }

    // Fill with data
    this._accountNumberInput.setValue(positionAcNo);
    this._accountDate.textContent = `${i18n.getTranslation('tradingAccountRow.number.since')} ${DateUtil.formatDateTo(accountLinkageDate, null, i18n.getTranslation('app.format.date.default'))}`;
    this._setAccountLinkageStatus(accountLinkageStatus);
  }

  _setAccountLinkageStatus(status) {
    let accountLinkageStatus = '';
    switch (status) {
      case ACCOUNT_LINKAGE_STATUS.APPROVED:
      case ACCOUNT_LINKAGE_STATUS.APPROVED_DIFFERENT_ADDRESS:
      case ACCOUNT_LINKAGE_STATUS.FORCED_APPROVED:
        accountLinkageStatus = 'active';
        break;
      case ACCOUNT_LINKAGE_STATUS.SUSPENDED:
        accountLinkageStatus = 'suspended';
        break;
    }
    this._accountLinkageStatus.textContent = i18n.getTranslation(`tradingAccountRow.status.${accountLinkageStatus}`);

    // Style appropriately
    this._accountLinkageStatus.classList.add(`trading-account-row-state-status--${accountLinkageStatus}`);
  }
}

customElements.define('cmp-list-row-account-trading', withInitDOM(CmpListRowAccountTrading));
