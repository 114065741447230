import { URLUtils } from 'sgx-base-code';

/**
* @function paramMiddleware
* @desc Used to query params from url
* @param  {Object} router Router5 instance of application
* @return {*} undefined
*/
function paramMiddleware(/* router */) {
  return (newState, previousState, doneFn) => {
    newState.query = URLUtils.getQueryParams(newState.path);
    doneFn();
  };
}

export default paramMiddleware;
