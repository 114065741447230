import { withInitDOM } from 'sgx-base-code';
import { DATA_MODEL_TOOL_ICON } from './sgx-data-model-tool-constants';
import tmpl from './sgx-data-model-tool.html';

class SgxDataModelTool extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('sgx-data-model-tool');
    this._labelContainer = this.querySelector('span');
    this._button = this.querySelector('.sgx-data-model-tool--button');
  }

  static get observedAttributes() {
    return ['label', 'icon', 'count', 'disabled', 'selected'];
  }

  attributeChangedCallback(attr, oldV, newV) {
    if (oldV === newV) {
      return;
    }

    switch (attr) {
      case 'label':
        this._setLabel(newV);
        break;
      case 'icon':
        this._setIcon(newV);
        break;
      case 'count':
        this.setCount(newV);
        break;
      case 'disabled':
        this.setDisabled(newV);
        break;
      case 'selected':
        this.setSelected(newV);
        break;
      default:
        break;
    }
  }

  _setLabel(text) {
    this._labelContainer.textContent = text || '';
  }

  _setIcon(icon) {
    if (this._isValidIcon(icon)) {
      if (this._currentIcon) {
        this._button.classList.remove(this._currentIcon);
      }
      const className = `sgx-data-model-tool-label--${icon}`;
      this._button.classList.add(className);
      this._currentIcon = className;
    }
  }

  setCount(count) {
    if (/^\d+$/.test(count)) {
      this._button.dataset.sgxBadge = count;
    } else {
      delete this._button.dataset.sgxBadge;
    }
  }

  getDisabled() {
    return !!this._button.disabled;
  }

  setDisabled(isDisabled) {
    this._button.disabled = !!isDisabled;
    this.disabled = !!isDisabled;
  }

  setSelected(isSelected) {
    this._button.classList[!!isSelected ? 'add' : 'remove']('sgx-button--selected');
  }

  _isValidIcon(icon) {
    return Object.keys(DATA_MODEL_TOOL_ICON)
      .some(key => DATA_MODEL_TOOL_ICON[key] === icon);
  }
}

customElements.define('sgx-data-model-tool', withInitDOM(SgxDataModelTool));
