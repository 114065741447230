import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-portfolio-registered-accounts.html';
import BankAccountService from 'services/bank-account-service';
import TradingAccountService from 'services/trading-account-service';
import DateUtil from 'utils/date-util';
import i18n from 'sgx-localisation-service';
import ReferenceDataService from 'services/reference-data-service';
import get from 'lodash/get';

const { ACCOUNT_LINKAGE_STATUS } = TradingAccountService.constants;

class WidgetPortfolioRegisteredAccounts extends HTMLElement {
  initDOM() {
    this.appendChild(tmpl.getNode());
    this._dcsDisplay = this.querySelector('.widget-portfolio-registered-accounts-dcs');
    this._tradingContentTable = document.createElement('sgx-content-table');
    this._tradingContainer = this.querySelector('#trading-accounts-container');
    this._promptRow = this.querySelector('.widget-account-trading-prompt');
    this._promptText = this.querySelector('.widget-account-trading-prompt-text');

    //todo: might be required in future in trading Account Columns
    // { label: i18n.getTranslation('widget-portfolio-account-details.registered-accounts.trading-account.blb-account-number') },

    const tradingAccountColumns = [
      { label: i18n.getTranslation('widget-portfolio-account-details.registered-accounts.trading-account.broker'), type: 'html' },
      { label: i18n.getTranslation('widget-portfolio-account-details.registered-accounts.trading-account.account-number') },
      { label: i18n.getTranslation('widget-portfolio-account-details.registered-accounts.trading-account.status'), type: 'html' },
      { label: i18n.getTranslation('widget-portfolio-account-details.registered-accounts.trading-account.activation-date') },
    ];

    const config = {
      removeEmptyRows: true,
      equalColumnWidth: true,
      hideHeader: 0,
      allowScrolling: true,
      columns: [],
      emptyRowOffset: 1
    };

    this._tradingContentTable.setConfig({ ...config, columns: tradingAccountColumns });
    this._tradingContainer.appendChild(this._tradingContentTable);
  }

  connectedCallback() {}

  setData({ accountId }) {
    if (this._accountId != accountId) {
      this._accountId = accountId;
      this._dcsDisplay.setData({ accountId });
      this._getTradingAccounts();
    }
  }

  _getTradingAccounts() {
    TradingAccountService.getTradingAccounts(this._accountId)
      .then(accounts => this._setTradingData(accounts));
  }

  _setTradingData(accounts) {
    if (!accounts.length) {
      this._promptRow.style.display = 'flex';
      this._tradingContentTable.style.display = 'none';
      this._promptText.innerHTML = i18n.getTranslation('app.widget-settings-account-trading.prompts.apply.text_html');
      return;
    }

    this._promptRow.style.display = 'none';
    this._tradingContentTable.style.display = 'block';

    ReferenceDataService.getBrokers().then(brokersList => {
      return brokersList.data.reduce((brokers, broker) => {
        brokers[get(broker, 'data.brokerCode')] = broker;
        return brokers;
      }, {});
    }).then(brokers => {
      const data = accounts.reduce((acc, cv) => {
        let accountStatus;

        if ([ACCOUNT_LINKAGE_STATUS.APPROVED, ACCOUNT_LINKAGE_STATUS.APPROVED_DIFFERENT_ADDRESS].includes(cv.accountLinkageStatus)) {
          accountStatus = 'active';
        } else if (ACCOUNT_LINKAGE_STATUS.SUSPENDED === cv.accountLinkageStatus) {
          accountStatus = 'suspended';
        }

        const broker = brokers[cv.brokerNo];
        //todo: might be required in the future after positionAcNo
        // cv.brokerNo,
        acc.push([
          this._getImageHtmlString(get(broker, 'data.logo.data.data.image.url'), get(broker, 'data.logo.data.data.image.alt')),
          cv.positionAcNo,
          this._getStatusHtmlString(accountStatus),
          this._getDataInUTC(cv.accountLinkageDate)
        ]);
        return acc;
      }, []);
      this._tradingContentTable.setData(data);
    });
  }

  _getDataInUTC(date) {
    return DateUtil.formatDateTo(date, BankAccountService.constants.DATE_FORMAT, i18n.getTranslation('app.format.date.default'), true);
  }

  _getImageHtmlString(src, alt) {
    return !src ? '' : `<img src="${src}" ${alt ? `alt="${alt}" ` : ' '}class="widget-portfolio-registered-accounts--image"/>`;
  }

  _getStatusHtmlString(status) {
    if (!status) {
      return '';
    }

    status = status.toLowerCase();
    return `
      <div class="bank-account-row-state-status sgx-base-text-body-14 bank-account-row-state-status--${status}">${status}</div>
    `;
  }
}

customElements.define('widget-portfolio-registered-accounts', withInitDOM(WidgetPortfolioRegisteredAccounts));
