import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-otp-token.html';

export default class WidgetOTPToken extends HTMLElement {

  initDOM() {
    this.appendChild(tmpl.getNode());
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }
}


customElements.define('widget-otp-token', withInitDOM(WidgetOTPToken));
