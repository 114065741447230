/*
   // 1 - Pending
   // 2 - Pending
   // 3 - Suspended
   // 4 - Active
   // 5 - Terminated
   // 6 - Pending
   // 7 - Pending
   // 8 - Rejected
   // 9 - Rejected
*/
const PTS_ACCOUNT_STATUS = {
  ACTIVE_SUBSCRIBED: '4',
  PENDING_SUBMISSION: '6',
  PENDING_VERIFICATION: '1',
  PENDING_EXTERNAL_VERIFICATION: '7',
  PENDING_VERIFIED: '2',
  REJECTED_INTERNAL: '8',
  REJECTED_EXTERNAL: '9',
  SUSPENDED_PTS: '3', // Key adjusted to avoid conflict with display status key
  TERMINATED_PTS: '5' // Key adjusted to avoid conflict with display status key
}

const DISPLAY_ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING_ACTIVATION: 'PENDING ACTIVATION', // There is no PENDING TERMINATION from DCS endpoint
  REJECTED: 'REJECTED',
  SUSPENDED: 'SUSPENDED',
  TERMINATED: 'TERMINATED'
}

const DATE_FORMAT = 'YYYYMMDD-HH:mm:ss';
const ISO_DATE_FORMAT = 'YYYY/MM/DD';

export {
  PTS_ACCOUNT_STATUS,
  DISPLAY_ACCOUNT_STATUS,
  DATE_FORMAT,
  ISO_DATE_FORMAT
}
