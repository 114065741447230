import StoreRegistry from 'stores/store-registry';

/**
 * Checks the permissions store for authentication state.
 */
export function isAuthenticated() {
  const authState = StoreRegistry.permissions.getData();
  const sessionState = StoreRegistry.cdpSession.getData();
  return authState === '2FA' && !!sessionState.userId; // Currently all features require 2FA, we'll update this if requirements change
}

/**
 * Toggles a feature given the authentication state.
 * @param {Object} object the object containing required arguments
 * @param {HTMLElement} object.prompt the login prompt element
 * @param {string} object.promptText the translated string to show in the login prompt
 * @param {HTMLElement} object.feature the feature to toggle given the authentication state
 */
export function toggleAuthenticatedFeature({ prompt, promptText, feature }) {
  if (isAuthenticated()) {
    prompt.hide();
  } else {
    feature.style.display = 'none';
    prompt.setConfig({ promptText });
    prompt.show();
    return;
  }
}

/*
* Clear User Session
* */
export function clearUserSession() {
  StoreRegistry.cdpSession.clear();
  StoreRegistry.permissions.clear();
  sessionStorage.clear();
  StoreRegistry.user.clear(); // Still required to delete store reference
  return Promise.resolve(true);
}
