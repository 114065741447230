import i18n from 'sgx-localisation-service';

export const tableConfig = {
  'options': {
    'localise': true,
    'downloadData': false,
    'columnsConfiguration': false,
    'filterable': false,
    'sortable': false,
    'resizable': true,
    'reorderable': false,
    'frozenColumns': 3,
    'timezone': 'Asia/Singapore',
    'autoWidth': true
  },
  'layout': {
    'desktop': {
      'rowHeight': 64,
      'horizontalScrollStep': 96,
      'verticalScrollStep': 96,
      'showAllRows': false
    },
    'mobile': {
      'cellPerRow': [1, 1, 1, 1],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5
    },
    'tablet': {
      'cellPerRow': [1, 1, 1, 1],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5
    }
  },
  'columns': {
    'serialNo': {
      'resizable': false,
      'autoWidth': false,
      'minWidth': 40,
      'label': i18n.getTranslation('app.widget-audit-log.serialNo.text'),
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'filter': false,
      'sort': false
    },
    'transactionName': {
      'minWidth': 40,
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'sort': {
        'type': 'text'
      },
      'filter': false,
      'label': i18n.getTranslation('app.widget-audit-log.transaction.text')
    },
    'timestamp': {
      'minWidth': 40,
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'sort': {
        'type': 'text'
      },
      'filter': false,
      'label': i18n.getTranslation('app.widget-audit-log.date.text')
    },
    'status': {
      'minWidth': 40,
      'header': {
        'type': 'text'
      },
      'cell': {
        'type': 'text'
      },
      'sort': {
        'type': 'text'
      },
      'filter': false,
      'label': i18n.getTranslation('app.widget-audit-log.status.text')
    }
  },
  'states': {
    'activeStateId': 0,
    'states': [
      {
        'data': {
          'order': [
            'serialNo',
            'transactionName',
            'timestamp',
            'status'
          ],
          'columns': {
            'serialNo': {
              'width': 40,
              'show': true
            },
            'transactionName': {
              'width': 300,
              'show': true
            },
            'timestamp': {
              'width': 300,
              'show': true
            },
            'status': {
              'width': 160,
              'show': true
            }
          },
          'filters': {},
          'sorts': {}
        }
      }
    ]
  }
}
