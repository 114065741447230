import { withInitDOM } from 'sgx-base-code';
import { SgxContextMenuService } from 'sgx-context-menu';
import DeviceService from 'sgx-device-service';
import tmpl from './widget-stock-screener-toolbar.html';
import DateService from '@sgx/sgx-date-time-service';
import i18n from '@sgx/sgx-localisation-service';
import { constants } from './widget-stock-screener-config';

import {
  createInitFilterState,
  createFilterConfig,
  DIALOG_CONFIG,
  MORE_ACTIONS_CONFIG
} from './widget-stock-screener-filters-config';

/**
 * Stock screener widget.
 */
class WidgetStockScreenerToolbar extends HTMLElement {
  constructor() {
    super();
    this._onMoreActionsClick = this._onMoreActionsClick.bind(this);
    this._onMoreActionsContextMenuClick = this._onMoreActionsContextMenuClick.bind(this);
    this._onClick = this._onClick.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-stock-screener-toolbar');
    this._filter = this.querySelector('.widget-stock-screener-toolbar-filter');
    this._search = this.querySelector('.widget-stock-screener-toolbar-search');
    this._count = this.querySelector('.widget-stock-screener-toolbar-count');
    this._btnContainer = this.querySelector('.widget-stock-screener-toolbar-button-container');
    this._moreActionsBtn = this.querySelector('.widget-stock-screener-toolbar-button--more-actions');
    this._filterTool = this._filter.getTool();
  }

  connectedCallback() {
    this.toggleListeners(true);
  }

  disconnectedCallback() {
    this._filter.getFiltersDialog().getDialog().hide(); // prevents filter staying open when changing pages
    this.toggleListeners(false);
  }

  toggleListeners(enable) {
    const method = enable ? 'addEventListener' : 'removeEventListener';
    this[method]('click', this._onClick);
    this._moreActionsBtn[method]('click', this._onMoreActionsClick);
    this[method]('sgx-data-model-tool-lock', this._onToolLock);
    this[method]('sgx-data-model-tool-unlock', this._onToolUnlock);
    
    this._search.querySelector('input')[method](DeviceService.isIe() ? 'keydown' : 'input', _ => this._filterTool.setDisabled(this._search.getSearchValue() !== ''))
  }

  setConfig({model, table, data}) { // data required to initialize config ranges and sectors
    model.setState(createInitFilterState());
    const filter = createFilterConfig(data);
    this._table = table;
    this._count.setConfig({model});
    this._filter.setConfig({filter, model, dialog: DIALOG_CONFIG});
    this._search.setConfig({model});
    this._init = true;
  }

  _onToolLock(e) {
    if (e.target.classList.contains('allow-event-propagation')) {
      return;
    }
    const button = e.target;
    const tool = button.getTool();

    if (tool === this._filterTool) {
      this._search.setDisabled(true);
    } else if (this._search.getSearchValue() !== '') {
      this._filterTool.setDisabled(true);
    }
    tool.setSelected && tool.setSelected(true);
    const callback = e.detail && e.detail.callback;
    if (callback && button[callback]) {
      button[callback]();
    }
    return;
  }

  _onToolUnlock(e) {
    if (e.target.classList.contains('allow-event-propagation')) {
      return;
    }
    const button = e.target;
    const tool = button.getTool();

    if (tool === this._filterTool) {
      this._search.setDisabled(false);
    } else {
      this._filterTool.setDisabled(false);
    }
    tool.setSelected && tool.setSelected(false);
    const callback = e.detail && e.detail.callback;
    if (callback && button[callback]) {
      button[callback]();
    }
  }

  _onMoreActionsClick(evt) {
    if (!this._init) {
      return;
    }
    SgxContextMenuService.show(MORE_ACTIONS_CONFIG, this._moreActionsBtn, false)
      .then(this._onMoreActionsContextMenuClick);
  }

  _onMoreActionsContextMenuClick(action) {
    if (!action) {
      return;
    }
    if (action === 'download') {
      this._table.setExportDataFileName(`${constants.EXPORT_NAME} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`);
      this._table.exportData();
    } else {
      const position = !DeviceService.isMobile() ? {
        target: this._moreActionsBtn,
        attachment: 'top right',
        targetAttachment: 'bottom right',
        offset: '-2px -10px'
      } : null;
      this._table.showConfigPopup(position);
    }
  }

  _onClick(evt) {
    // prevent click events from triggering internal sgx-table actions
    if (evt.target.classList.contains('allow-event-propagation')) {
      return;
    }
    evt.stopImmediatePropagation();
  }
}

customElements.define('widget-stock-screener-toolbar', withInitDOM(WidgetStockScreenerToolbar));
