import i18n from '@sgx/sgx-localisation-service';

const constants = {
  EXPORT_NAME: 'SGX Screener Data'
};

const defaultVal = '-';
const states = {
  activeStateId: 0,
  states: [{
    label: '',
    data: {
      order: ['n', 'nc', 'lt', 'roe-perc', 'market-cap', 'total-revenue', 'pe', 'yield', 'sector', 'gti', 'ric', 'price-perc-change-4',
        'price-perc-change-13', 'price-perc-change-26', 'price-perc-change-52', 'net-profit-margin',
        'cash-flow', 'debt-equity-ratio', 'sales-perc-change', 'price-book-ratio'],
      columns: {
        n: {
          width: 270,
          show: true
        },
        nc: {
          width: 70,
          show: true
        },
        lt: {
          width: 90,
          show: true
        },
        'roe-perc': {
          width: 90,
          show: true
        },
        'market-cap': {
          width: 90,
          show: true
        },
        'total-revenue': {
          width: 90,
          show: true
        },
        pe: {
          width: 70,
          show: true
        },
        yield: {
          width: 70,
          show: true
        },
        sector: {
          width: 120,
          show: true
        },
        gti: {
          width: 70,
          show: true
        },
        ric: {
          width: 80,
          show: false
        },
        'price-perc-change-4': {
          width: 90,
          show: false
        },
        'price-perc-change-13': {
          width: 90,
          show: false
        },
        'price-perc-change-26': {
          width: 90,
          show: false
        },
        'price-perc-change-52': {
          width: 90,
          show: false
        },
        'net-profit-margin': {
          width: 100,
          show: false
        },
        'cash-flow': {
          width: 90,
          show: false
        },
        'debt-equity-ratio': {
          width: 90,
          show: false
        },
        'sales-perc-change': {
          width: 90,
          show: false
        },
        'price-book-ratio': {
          width: 90,
          show: false
        }
      },
      filters: {},
      sorts: {
        n: 'asc'
      }
    }
  }]
};

const columns = {
  n: {
    minWidth: 180,
    label: i18n.getTranslation('app.widget-stock-screener.table.n.label'),
    header: {
      type: 'text'
    },
    cell: {
      type: 'securities'
    },
    sort: {
      type: 'text'
    },
    filter: {}
  },
  nc: {
    minWidth: 50,
    label: i18n.getTranslation('app.widget-stock-screener.table.nc.label'),
    header: {
      type: 'text'
    },
    cell: {
      type: 'text',
      defaultValue: defaultVal
    },
    sort: {
      type: 'text'
    },
    filter: {}
  },
  lt: {
    minWidth: 90,
    label: i18n.getTranslation('app.widget-stock-screener.table.lt.label'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 3,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'roe-perc': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.roe-perc.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.roe-perc.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'market-cap': {
    minWidth: 90,
    label: i18n.getTranslation('app.widget-stock-screener.table.market-cap.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.market-cap.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      valueKey: 'label',
      decimalPlaces: 3,
      padZeros: true
    },
    sort: {
      type: 'number',
      valueKey: 'value'
    },
    filter: {
      type: 'number',
      valueKey: 'value'
    }
  },
  'total-revenue': {
    minWidth: 90,
    label: i18n.getTranslation('app.widget-stock-screener.table.total-revenue.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.total-revenue.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      valueKey: 'label',
      decimalPlaces: 3,
      padZeros: true
    },
    sort: {
      type: 'number',
      valueKey: 'value'
    },
    filter: {
      type: 'number',
      valueKey: 'value'
    }
  },
  pe: {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.pe.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.pe.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  yield: {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.yield.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.yield.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  sector: {
    minWidth: 100,
    label: i18n.getTranslation('app.widget-stock-screener.table.sector.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.sector.tooltip'),
    header: {
      type: 'text'
    },
    cell: {
      type: 'text',
      defaultValue: defaultVal
    },
    sort: {
      type: 'text'
    },
    filter: {}
  },
  gti: {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.gti.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.gti.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number'
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  ric: {
    minWidth: 80,
    label: i18n.getTranslation('app.widget-stock-screener.table.ric.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.ric.tooltip'),
    header: {
      type: 'text'
    },
    cell: {
      type: 'text',
      defaultValue: defaultVal
    },
    sort: {
      type: 'text'
    },
    filter: {}
  },
  'price-perc-change-4': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.price-perc-change-4.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.price-perc-change-4.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'price-perc-change-13': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.price-perc-change-13.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.price-perc-change-13.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'price-perc-change-26': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.price-perc-change-26.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.price-perc-change-26.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'price-perc-change-52': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.price-perc-change-52.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.price-perc-change-52.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'net-profit-margin': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.net-profit-margin.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.net-profit-margin.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'cash-flow': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.cash-flow.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.cash-flow.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'debt-equity-ratio': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.debt-equity-ratio.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.debt-equity-ratio.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'sales-perc-change': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.sales-perc-change.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.sales-perc-change.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  },
  'price-book-ratio': {
    minWidth: 70,
    label: i18n.getTranslation('app.widget-stock-screener.table.price-book-ratio.label'),
    tooltip: i18n.getTranslation('app.widget-stock-screener.table.price-book-ratio.tooltip'),
    header: {
      type: 'number'
    },
    cell: {
      type: 'number',
      decimalPlaces: 2,
      padZeros: true
    },
    sort: {
      type: 'number'
    },
    filter: {}
  }
};

const tableConfig = {
  'columns': columns,
  'states': states,
  'decorators': {
    'rows': [
      {
        'name': 'securities',
        'config': {
          'cellToDecorate': 'n'
        }
      },
      {
        'name': 'price-movement',
        'config': {
          'cellToDecorate': ['lt'],
          'cellMovement': 'p'
        }
      }
    ]
  },
  'options': {
    'localise': true,
    'model': 'sgx-worker-model',
    'downloadData': true,
    'exportDataFileName': `${constants.EXPORT_NAME}`,
    'columnsConfiguration': true,
    'autoWidth': true,
    'sortable': true,
    'resizable': true,
    'filterable': false,
    'reorderable': true,
    'frozenColumns': 3
  },
  'layout': {
    'desktop': {
      'rowHeight': 32,
      'horizontalScrollStep': 96,
      'verticalScrollStep': 96,
      'showAllRows': false
    },
    'mobile': {
      'cellPerRow': [2, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5
    },
    'tablet': {
      'cellPerRow': [2, 6, 6, 6, 6, 6],
      'noRowsDisplayedByDefault': 10,
      'noRowsToAddStep': 5
    }
  }
};

export {
  constants,
  tableConfig
};
