/* Dependencies */
import CmsService from 'sgx-cms-service';
import ConfigService from 'sgx-config-service';

let instance = null;
/**
 * @module market-research-service
 */
class MarketResearchService {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Calls CmsService.getMarketUpdatesListingData to get listing of market updates and returns a transformed data
   * specific to market updates page needs
   *
   * @param {Object} [args] an object of optional query parameters that can be appended to the API call
   * @param {String} [args.offset] page number to start the search results on
   * @param {String} [args.limit] size of each page of results
   * @param {Date} [args.fromDate] date to search from
   * @param {Date} [args.toDate] date to search to
   * @param {Array<String>} [args.sector] sectors to filter by
   * @param {Array<String>} [args.securityCode] security code to filter by
   * @param {Array<String>} [args.assetClass] asset classes to filter by
   * @param {Array<String>} [args.category] category to filter by
   * @returns {Promise<{count: number, data: Array}>} returns an object containing market updates count and array of data
   */
  getMarketUpdatesListingData({ limit, offset, assetClass, sector, fromDate, toDate, category, securityCode }) {
    const filterArg = arguments[0];
    const filterKeys = ['assetClass', 'sector', 'fromDate', 'toDate', 'category', 'securityCode', 'title'];
    const filters = filterKeys.reduce((obj, key) => {
      if (filterArg[key] && filterArg[key].length) {
        obj[key] = filterArg[key];
        obj[`${key}FilterEnabled`] = true;
      }
      return obj;
    }, { limit, offset });

    return CmsService.getMarketUpdatesListingData(filters)
      .then(response => {
        const data = response.data.list.results.map(({ data }) => ({
          ...this._toMarketUpdatePageData(data)
        }));
        return {
          data,
          count: response.data.list.count
        }
      });
  }
  /**
   * Calls CmsService.getMarketUpdatesListingData to get the listing of filters
   *
   * @returns {Promise<Array>} returns an array containing market data
   */
  getMarketUpdatesFiltersLists() {
    return CmsService.getMarketUpdatesListingData()
      .then(response => {
        const data = (response && response.data) || {};
        const filterLists = ['categories','sectors', 'assetClassesSecurities'].reduce((obj, key) => {
          const arr = (data[key] && data[key].data) || [];
          obj[key] = arr.map(({ data }) => ({
            id: data.id,
            label: data.name,
            value: data.id
          }));
          return obj;
        }, {});
        return { ...filterLists };
      });
  }

  /**
   * Calls CmsService.getMarketResearchLatestListingData to get the latest 8 listing
   *
   * @returns {Promise<{categories: Array, sectors: Array, assetClassesSecutiries: Array}>} returns an object containing the filter lists
   */
  getMarketResearchLatestListingData() {
    return CmsService.getMarketResearchLatestListingData()
      .then(response => {
        return response.data.list.results.map(({ data }) => ({
          ...this._toMarketUpdatePageData(data)
        }));
      });
  }

  /**
   * Fetches a market update article from the CMS
   *
   * @param {String} id page number to start the search results on
   * @returns {Promise<Object>} returns an object containing the listing data
   */
  getMarketUpdateById(id) {
    // TODO - Finalize all properties that needs to be pass on CMS API including the filters from the user input
    return CmsService.getMarketUpdateById(id)
    .then(response => {
      const data = response.data.list.results.map(({ data }) => ({
        ...this._toMarketUpdatePageData(data)
      }));
      return data && data[0];
    });
  }
  /**
   * Retrieves the src and mimeType based on the market update data
   *
   * @param {Object} [args] data structure of a market update item
   * @param {String} [args.link] contains the info of the content source
   * @param {String} [args.migrated] contains the info of the pdf source
   * @returns {{src: string, mimeType: string}} returns an object containing source and mimeType of a market update
   */
  _getLinkSrcAndMimeType(data) {
    let src =  `${ConfigService.links.SGX_V2}${data.link.url}${ConfigService.links.SGX_V2_CMS_URL_EMBED_SUFFIX}`;
    let mimeType = 'text/html';

    if (data.migrated && data.migrated.data && data.migrated.data.url) {
      src = data.migrated.data.url;
      mimeType = 'application/pdf';
    }

    return { src, mimeType };
  }
  /**
   * Converts the market data receive from the CMS to an object that contains all required data for the market update page
   *
   * @param {Object} [args] data structure of a market update item
   * @returns {Object} returns an object containing all the data needed for the market updates
   */
  _toMarketUpdatePageData(data) {
    const transformedAssetClass = (data.assetClass || []).reduce((obj, currValue) => {
      obj.assetClass.push(currValue.data.data.id);
      obj.assetClassNames.push(currValue.data.data.name);
      return obj;
    }, { 'assetClass': [], 'assetClassNames': [] });

    return {
      ...data,
      ...this._getLinkSrcAndMimeType(data),
      ...transformedAssetClass
    }
  }
}

/* Export the singleton */
const marketResearchServiceInstance = new MarketResearchService();
export default marketResearchServiceInstance;
