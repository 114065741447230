import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import ConfigService from 'sgx-config-service';
import SecuritiesService from 'sgx-securities-service';
import { Big } from 'utils/big-number-util';
import { toggleAuthenticatedFeature, isAuthenticated } from 'utils/auth-util';
import { mapToChartSeriesData } from 'utils/portfolio-util';
import { getChartConfig, EMPTY_INDICATOR_TITLE } from './widget-portfolio-account-config';
import StoreRegistry from 'stores/store-registry';
import PortfolioAggregator from 'aggregators/portfolio-aggregator';
import ExchangeRateService from 'services/exchange-rate-service';
import tmpl from './widget-portfolio-account.html';
import StatusIndicatorUtil from 'utils/status-indicator-util';

class WidgetPortfolioAccount extends HTMLElement {
  constructor() {
    super();
    this._store = StoreRegistry.portfolioAccountDashboard;
    this._onPriceDataReceived = this._onPriceDataReceived.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-portfolio-account');
    this._accountSection = this.querySelector('.widget-portfolio-account-section');
    this._loginPrompt = this.querySelector('cmp-login-prompt-dashboard');
    this._totalMarketValue = this.querySelector('#total-market-value');
    this._totalProfitLoss = this.querySelector('#total-profit-loss-value');
    this._productChart = this.querySelector('.portfolio-account-product-chart');
    this._sectorChart = this.querySelector('.portfolio-account-sector-chart');
    this._productIndicator = this.querySelector('.portfolio-account-product-indicator');
    this._sectorIndicator = this.querySelector('.portfolio-account-sector-indicator');
    this._link = this.querySelector('.portfolio-account-link-text');
    this._portfolioAccountContainer = this.querySelector('.portfolio-account');
    this._errorState = this.querySelector('.portfolio-account-no-data');
    this._statusIndicator = this.querySelector('.portfolio-account-loader');
    this._slides = this.querySelector('sgx-slides');
  }

  connectedCallback() {
    toggleAuthenticatedFeature({
      prompt: this._loginPrompt,
      promptText: i18n.getTranslation('app.widget-portfolio-account.title'),
      feature: this._accountSection
    });

    if (isAuthenticated()) {
      const portfolioLink = ConfigService.links.INVESTORS_PORTAL_PORTFOLIO;
      this._link.dataset.analyticsLabel = portfolioLink;
      this._link.href = portfolioLink;

      this._priceSubscription = SecuritiesService.subscribeToPrices(this._onPriceDataReceived);
      this._storeSubscription = this._store.subscribe(({accountId}) => {
        if (accountId !== this._accountId) {
          this._accountId = accountId;
          this._setAccountDetails();
        }
      });
    }
  }

  disconnectedCallback() {
    this._storeSubscription && this._storeSubscription.unsubscribe();
    this._priceSubscription && SecuritiesService.unsubscribeFromPrices(this._priceSubscription);
  }

  get _chartAccountBalances() {
    // do not include inactive securities (securities that don't have stock code or type is undefined)
    return (this._balances || [])
      .filter(balance => !!balance.type);
  }

  async _setAccountDetails() {
    this._toggleErrorState(false);
    StatusIndicatorUtil.displayLoader(this._statusIndicator);

    try {
      this._balances = await PortfolioAggregator.getInvestmentDetails(this._accountId);
      const currencies = this._balances.map(balance => balance.currency)
        .filter((value, index, self) => self.indexOf(value) === index);

      const rates = await this._getExchangeRate(currencies);
      const products = {};
      const sectors = {};
      let totalProfitLoss = Big(0);
      let totalMarketValue = Big(0);

      this._balances.forEach(balance => {
        const { currency, profit } = balance;
        const rate = rates[currency] || 1;
        const convertedProfit = Big(isNaN(profit) ? 0 : profit).times(rate);
        let marketValue = Big(isNaN(balance.marketValue) ? 0 : balance.marketValue).times(rate);

        if (marketValue) {
          let { productCode, sectorCode } = balance;
          productCode = (!productCode || productCode === '*') ? 'others' : productCode;
          sectorCode = (!sectorCode || sectorCode === '*') ? 'others' : sectorCode;
          totalMarketValue = totalMarketValue.plus(marketValue);

          products[productCode] = Big(products[productCode] || 0).plus(marketValue);
          sectors[sectorCode] = Big(sectors[sectorCode] || 0).plus(marketValue);
        }
        totalProfitLoss = totalProfitLoss.plus(convertedProfit);
      });

      this._slides.setData(Array(this._chartAccountBalances.length).fill('<sgx-indices-card></sgx-indices-card>'));

      this._formatValueToCurrency(this._totalProfitLoss, totalProfitLoss);
      this._formatValueToCurrency(this._totalMarketValue, totalMarketValue);

      const productSeries = mapToChartSeriesData(products, totalMarketValue);
      const sectorSeries = mapToChartSeriesData(sectors, totalMarketValue);

      this._productChart.setData(getChartConfig(productSeries, i18n.getTranslation('app.widget-portfolio-overview.chart.product')));
      this._sectorChart.setData(getChartConfig(sectorSeries, i18n.getTranslation('app.widget-portfolio-overview.chart.sector')));
      if (!productSeries.length && !sectorSeries.length) {
        this._toggleErrorState(true);
      } else {
        this._shouldShowChartIndicator(this._productIndicator, productSeries);
        this._shouldShowChartIndicator(this._sectorIndicator, sectorSeries);
      }
      this._setChartSlides();
      StatusIndicatorUtil.hideStatusIndicator(this._statusIndicator);
    } catch(_) {
      StatusIndicatorUtil.displayNoData(this._statusIndicator);
    }
  }

  async _setChartSlides() {
    const balances = this._chartAccountBalances;
    const cards = [...this.querySelectorAll('sgx-indices-card')];
    if (!cards.length) {
      return;
    }

    const historicData = await this._getChartsHistoryData(balances);
    balances.forEach((balance, index) => {
      const { lt, p, c } = this._priceData[balance.stockCode] || {};
      const { securityName, security } = balance;
      const prevData = cards[index].data;
      cards[index].setData({
        name: securityName,
        link: security.link,
        value: lt || prevData.value,
        change: c || prevData.change,
        percentChange: p || prevData.percentChange,
        historicData: historicData[index]
      });
    });
  }

  async _getChartsHistoryData(balances) {
    return await Promise.all(balances.map(async balance => {
      try {
        // if type is undefined it means that the stock code for this security is N/A
        if (!balance.type) {
          return [];
        }
        const result = await SecuritiesService.getChartData({
          category: balance.type,
          chartType: 'historic',
          idType: 'code',
          id: balance.stockCode,
          period: '1y'
        }, {
          params: 'trading_time,vl,lt'
        });
        return this._parseChartData(result);
      } catch(e) {
        console.error(e);
      }
      return [];
    }));
  }

  _parseChartData(response) {
    const data = response.data;
    return data.reduce((result, item) => {
      result.push(item.lt);
      return result;
    }, []);
  };

  _toggleErrorState(state) {
    this._portfolioAccountContainer.classList[state ? 'add' : 'remove']('sgx-hidden');
    this._errorState.classList[state ? 'remove' : 'add']('sgx-hidden');
  }

  _getExchangeRate(currencies) {
    return ExchangeRateService.getExhangeRatesToSgd(currencies)
      .then(exchangeRates => {
        return exchangeRates.reduce((rates, { source, rate }) => {
          rates[source] = rate;
          return rates;
        }, {});
      });
  }

  _onPriceDataReceived(data) {
    this._priceData = data;
    this._setChartSlides();
  }

  _shouldShowChartIndicator(indicator, data) {
    if (!data.length) {
      indicator.show({ title: EMPTY_INDICATOR_TITLE, status: 'neutral' });
      return;
    }
    indicator.hide();
  }

  /**
   * Sets the target element's value
   *
   * @param {HTMLElement} element target element
   * @param {number} value
   */
  _formatValueToCurrency(element, value) {
    const total = i18n.getTranslation('app.widget-portfolio-overview.currency-format', { value: value.toFormat(2) });
    element.value = total;
  }
}

customElements.define('widget-portfolio-account', withInitDOM(WidgetPortfolioAccount));
