import MetadataService from 'sgx-metadata-service';
import CorporateInformationService from 'sgx-corporate-information-service';

let instance = null;
/**
 * Allows you to query corp information service with stockCode instead of ibmCode
 *
 * Corporate information service requires ibmCode which is not returned by securities API
 * This aggregator helps to retrieve the ibmCode from stock code and return corp information
 * @module security-details-aggregator
 * @type {HTMLElement}
 */
class SecurityDetailsAggregator {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  /**
   * Queries Corporate Information API based on given Securities API stock code
   *
   * @param {string} stockCode code, which is returned from securities api
   * @returns {Promise<Object>} returns a a string 'NOT_FOUND' or an id which can be used to construct a link containg company data
   * e.g. id = 1131,  https://links.sgx.com/1.0.0/corporate-information/1131
   */
  getCorporateInformationIdFromCode(stockCode) {
    return MetadataService.getMetaDataByStockCode(stockCode)
      .then(response => response.ibmCode)
      .then(CorporateInformationService.getCorporateInformationIdByIbmCode)
      .catch(_ => Promise.resolve('NOT_FOUND'));
  }
}

/* Export the singleton */
const SecurityDetailsAggregatorInstance = new SecurityDetailsAggregator();
export default SecurityDetailsAggregatorInstance;
