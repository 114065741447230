import i18n from 'sgx-localisation-service';
import PriceUtil from 'utils/price-util';

export default {
  credits: false,
  navigator: false,
  scrollbar: false,
  rangeSelector: false,
  lang: {
    thousandsSep: ','
  },
  colors: ['#0093B4'],
  chart: {
    // height: 445
    backgroundColor: 'none'
  },
  plotOptions: {
    series: {
      animation: false,
      marker: {
        states: {
          hover: {
            radiusPlus: 3,
            fillColor: '#BFCE00'
          }
        }
      }
    },
    area: {
      states: {
        hover: {
          lineWidthPlus: 0
        }
      }
    }
  },
  tooltip: {
    valueDecimals: 2
  },
  series: [{
    type: 'area',
    threshold: null
  }],
  yAxis: {
    offset: 35,
    opposite: true,
    labels: {
      formatter: PriceUtil.getAbbreviateFormatter(2)
    },
    gridZIndex: 3
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: i18n.getTranslation('app.charts.xaxis-date-formats', { returnObjects: true })
  }
};
