import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-list-row-account-bank.html';
import i18n from 'sgx-localisation-service';

/**
 * List row containing other rows with DCS bank account and CCY information.
 */
class CmpListRowAccountBank extends HTMLElement {

  connectedCallback() {}

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-account-bank');
    this._infoColumn = this.querySelector('.cmp-list-row-account-bank-info');
    this._accountLogo = this.querySelector('.cmp-list-row-account-bank-info-logo');
    this._accountNumber = this.querySelector('.cmp-list-row-account-bank-info-number');
    this._dcsSubRow = this.querySelector('cmp-list-row-account-state[ref=dcs]');
    this._ccySubRow = this.querySelector('cmp-list-row-account-state[ref=ccy]');
  }

  disconnectedCallback() {}

  setConfig() {}

  setData({ dcs = {}, ccy = {} } = {}) {
    this._setDcsData(dcs);
    this._setCcyData(ccy);
  }

  _setDcsData({ infoColumnWidth = 100, logo = {}, accountNumber = '', bankId = '', color = '', status, date = '', updatable } = {}) {
    
    // Set info column width
    this._infoColumn.style.minWidth = `${infoColumnWidth}px`;

    // Set DCS bank logo
    logo.then(({ src, alt }) => {
      if (src) {
        const logoElem = document.createElement('img');
        logoElem.src = src;
        logoElem.alt = alt ? alt : bankId;
        this._accountLogo.appendChild(logoElem);
      }
    });

    // Set DCS bank account number
    if (accountNumber) {
      this._accountNumber.textContent = accountNumber;
    }

    // Set DCS state sub-row data
    this._dcsSubRow.setData({
      title: i18n.getTranslation('app.cmp-list-row-account-bank.dcs'),
      status: {
        color,
        text: status ? i18n.getTranslation(`app.cmp-list-row-account-bank.status.${status.toLowerCase().replace(/\s/g, '-')}`) : ''
      },
      date,
      button: {
        label: i18n.getTranslation('app.cmp-list-row-account-bank.button.update'),
        callback: updatable ? this._showDcsDialog : null
      }
    });
  }

  _setCcyData({ status, color, date } = {}) {
    this._ccySubRow.setData({
      title: i18n.getTranslation('app.cmp-list-row-account-bank.ccy'),
      status: {
        text: i18n.getTranslation(`app.cmp-list-row-account-bank.status.${status ? status.toLowerCase().replace(/\s/g, '-') : 'unsubscribed'}`),
        color
      },
      date
    });
  }

  _showDcsDialog() {
    this.dispatchEvent(new CustomEvent('show-dcs-dialog', { bubbles: true }));
  }
}

customElements.define('cmp-list-row-account-bank', withInitDOM(CmpListRowAccountBank));
