module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-portfolio-payouts\">\n  <div class=\"payouts-select-container\">\n    <label class=\"sgx-base-text-body-14 payouts-select-label\" data-i18n=\"cmp-portfolio-blb-toolbar.label.month\"></label>\n    <sgx-input-select class=\"payouts-period-select sgx-table-toolbar-filter-item\"></sgx-input-select>\n  </div>\n\n  <section class=\"payouts-section\">\n    <div class=\"sgx-base-text-title-16 payouts-title\" data-i18n=\"app.widget-portfolio-payouts.section-name.payouts\"></div>\n    <div class=\"payouts-table-container\">\n      <sgx-table class=\"payouts-table\"></sgx-table>\n      <sgx-status-indicator class=\"payouts-loader\"></sgx-status-indicator>\n    </div>\n  </section>\n\n  <section class=\"payouts-ccy-section sgx-hidden\">\n    <div class=\"sgx-base-text-title-16 payouts-title\" data-i18n=\"app.widget-portfolio-payouts.section-name.ccy\"></div>\n    <div class=\"payouts-table-container\">\n      <sgx-table class=\"payouts-ccy-table\"></sgx-table>\n      <sgx-status-indicator status=\"loading\" class=\"payouts-ccy-loader\"></sgx-status-indicator>\n    </div>\n  </section>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()