import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import tmpl from './template-portfolio-page.html';
import { toggleAuthenticatedFeature } from 'utils/auth-util';
import ConfigService from "@sgx/sgx-config-service/src/sgx-config-service";

/**
 * Portfolio page template.
 */
class TemplatePortfolioPage extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._portfolio = this.querySelector('widget-portfolio');
    this._loginPrompt = this.querySelector('cmp-login-prompt');
    this._loginPromptText = this.querySelector('.cmp-login-prompt-text');
  }

  connectedCallback() {
    toggleAuthenticatedFeature({
      prompt: this._loginPrompt,
      promptText: i18n.getTranslation('app.portfolio.generic-login-prompt', {
        link: ConfigService.links.CDP_ACCOUNT_CREATE
      }),
      feature: this._portfolio
    });
  }

  setData(data) {
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

customElements.define('template-portfolio-page', withInitDOM(TemplatePortfolioPage));
