import { Utils, withInitDOM } from 'sgx-base-code';
import SecuritiesService from 'sgx-securities-service';
import tmpl from './template-stock-screener-details.html';
import StoreRegistry from 'stores/store-registry';
import ConfigService from 'sgx-config-service';

/**
 * Stock Screener details page template.
 */
class TemplateSecurityDetailsPage extends HTMLElement {
  // #endregion

  constructor() {
    super();
    this._ready = Utils.deferred();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-stock-screener-details');
    this._securityDetails = this.querySelector('widget-security-details');
    this._ready.resolve();
  }

  connectedCallback() {
  }

  setData(_, params) {
    // If type is known beforehand, we can optimize the getPrices call
    const {code, type} = params;
    const pricesCallParams = type ? {
      category: type,
      code
    } : {};

    if (['retailbonds', 'retailpreferenceshares', 'sgsbonds'].includes(type)) {
      const lang = (StoreRegistry.appSettings.getData('lang') || 'en').replace(/-/, '_');

      window.location.href = `${ConfigService.links.SGX_FIXED_INCOME[lang]}${code}`;
      return;
    }

    Promise.all([
      SecuritiesService.getPrices({...pricesCallParams}),
      this._whenReady()
    ])
      .then(([prices]) => {
        this._securityDetails.setData(prices[code]);
      });
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }

  _whenReady() {
    return this._ready.promise;
  }
}

customElements.define('template-stock-screener-details', withInitDOM(TemplateSecurityDetailsPage));
