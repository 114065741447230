import { withInitDOM } from 'sgx-base-code';

/**
 * Very simple configurable dashboard component.
 */
class Dashboard extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {

  }

  initDOM() {
    this.innerHTML = '<div class="sgx-dashboard container-fluid container-fluid--full-width"></div>';
    this._content = this.querySelector('.sgx-dashboard');
  }

  setConfig(config) {
    this._config = config;
    this.refreshView();
  }

  refreshView() {
    this.clearWidgets();
    const contentFragment = document.createDocumentFragment();
    const widgetBaseCls = this._config.widgetBaseCls;
    this._config.rows.forEach(row => {
      if (typeof row.columns === 'undefined') return; // added condition to validate empty object
      const rowEl = document.createElement('div');
      rowEl.classList.add('row');
      // add class name for customization of the row
      if (row.classNames) {
        Array.isArray(row.classNames) ? rowEl.classList.add(...row.classNames) : rowEl.classList.add(row.classNames);
      }

      const columnsCount = row.columns.length;
      row.columns.forEach(column => {
        const colEl = document.createElement('div');
        column.size ? colEl.classList.add(`col-md-${column.size}`) : colEl.classList.add(`col-md-${12 / columnsCount}`);
        colEl.classList.add('col-xs-12');
        // add class name for customization of the column
        if (column.classNames) {
          Array.isArray(column.classNames) ? colEl.classList.add(...column.classNames) : colEl.classList.add(column.classNames);
        }

        column.widgets.forEach(widget => {
          if (!widget.element) {
            return;
          }
          const widgetEl = document.createElement(widget.element);
           // overwrite the base widget className with specific widget className
           const classNames = typeof widget.classNames !== 'undefined' ? widget.classNames : [widgetBaseCls];

           classNames.length && widgetEl.classList.add(...classNames)
          const attributes = widget.attributes || {};
          Object.keys(attributes).forEach(key => {
            widgetEl.setAttribute(key, attributes[key]);
          });
          colEl.appendChild(widgetEl);
        });
        rowEl.appendChild(colEl);
      });
      contentFragment.appendChild(rowEl);
    });
    this._content.appendChild(contentFragment);
  }

  clearWidgets() {
    while (this._content.lastChild) {
      this._content.removeChild(this._content.lastChild);
    }
  }
}

customElements.define('sgx-dashboard', withInitDOM(Dashboard));
