import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-user-general.html';
import i18n from 'sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import * as constants from 'services/user-service/src/user-service-constants';
import {
  setFieldOptions,
  showHideEl
} from 'utils/form-util';
import jsonSchema from 'schemas/settings-user';
import { get } from 'lodash';
import {
  USER_RESIDENTIAL_STATUS,
  USER_TYPES,
  USER_ALTERNATIVE_ID_TYPES
} from '../../services/user-service/src/user-service-constants';
import ReferenceDataService from 'services/reference-data-service';

/**
 * General details widget.
 */
class UserGeneralWidget extends HTMLElement {
  constructor() {
    super();
  }

  _hideFields() {
    showHideEl(this._userTitle, false);
    showHideEl(this._gender, false);
    showHideEl(this._identificationNumber, false);
    showHideEl(this._idIssuingCountryInput, false);
    showHideEl(this._singaporeanPR, false);
    showHideEl(this._countryOfBirth, false);
    showHideEl(this._expiryDate, false);
    showHideEl(this._nationality, false);
    showHideEl(this._dateOfBirth, false);
    showHideEl(this._alternateId, false);
  }

  async initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-user-general');

    // References
    this._generalDetailsFieldset = this.querySelector('.general-fieldset');
    this._title = this.querySelector('.details-title');
    this._userTitle = this.querySelector('.details-user-title');
    this._gender = this.querySelector('.details-gender');
    this._identificationNumber = this.querySelector('.details-identification-number');
    this._idIssuingCountryInput = this.querySelector('.details-issuing-country');
    this._singaporeanPR = this.querySelector('.details-singaporean-pr');
    this._countryOfBirth = this.querySelector('.details-birth-country');
    this._expiryDate = this.querySelector('.details-expiry-date');
    this._nationality = this.querySelector('.details-nationality');
    this._dateOfBirth = this.querySelector('.details-dob');
    this._alternateId = this.querySelector('.details-alternate-id');
    this._fullNameInput = this.querySelector('.details-fullName');

    this._sessionStore = StoreRegistry.cdpSession;
    let { accountType } = this._sessionStore.getData();

    if (accountType === constants.ACCOUNT_STATES.CORPORATE) {
      this._hideFields();
    }
    else {
      setFieldOptions(this._userTitle, get(jsonSchema, 'properties.title.enum'), 'app.widget-settings-user-general.inputs.title');
      setFieldOptions(this._gender, get(jsonSchema, 'properties.gender.enum'), 'app.widget-settings-user-general.inputs.gender');
    }

    await this._initForm();
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  async _initForm() {
    this._nationalityList = await ReferenceDataService.getNationalities();
    this._countryList = await ReferenceDataService.getCountries();
  }

  /**
   * @method
   * @param {String} dob
   */
  _setDateOfBirth(dob) {
    const DEFAULT_DOB = '1900/01/01';

    if (dob !== DEFAULT_DOB) {
      this._dateOfBirth.setValue(dob.toUpperCase());
      this._dateOfBirth._input.value = get(this,
        '_dateOfBirth._input.value').toUpperCase();
    }
  }

  _setSingaporeanPRFlag(user) {
    const isPR = (USER_RESIDENTIAL_STATUS.SINGAPORE_PR === user.prFlag);
    if (isPR) {
      this._singaporeanPR.setValue(i18n.getTranslation('app.widget-settings-user-general.inputs.singaporeanPr.status'));
    }
    showHideEl(this._singaporeanPR, isPR);
  }

  _setNationality(ele, value) {
    const { data: nationalities } = this._nationalityList;
    const nationalityOptions = Object.keys(nationalities).map((key, value) => (
      { value: key, label: nationalities[key] }
    ));
    ele.setOptions(nationalityOptions);
    value && ele.setValue(value);
  }

  _setCountry(ele, value) {
    const { data: countries } = this._countryList;
    const countryOptions = countries.map(country => (
      { value: country.id, label: country.name }
    ));
    ele.setOptions(countryOptions);
    value && ele.setValue(value);
  }

  _setUserIndentifierNumber(user) {
    const type = get(user, 'clientInfoId.clientIdType');
    const issuingCountry = get(user, 'clientInfoId.issuingCountry')

    if (type === USER_TYPES.FOREIGNER) {
      if(get(user, 'passportExpiryDate')) {
        this._expiryDate.setValue(get(user, 'passportExpiryDate'));
        this._expiryDate._input.value = this._expiryDate._input.value.toUpperCase();
      }
      this._setCountry(this._idIssuingCountryInput, issuingCountry);
      showHideEl(this._expiryDate, get(user, 'passportExpiryDate'));
      showHideEl(this._idIssuingCountryInput, true);
      this._identificationNumber.label = i18n.getTranslation('app.widget-settings-user-general.inputs.passportNumber.label')
    }

    if (type === USER_TYPES.SC_PR) {
      const idType = issuingCountry === 'SG' ? 'nric' : issuingCountry === 'MY' ? 'mic' : undefined;
      if (!idType) {
        return;
      }
      this._identificationNumber.label = i18n.getTranslation(`app.widget-settings-user-general.inputs.${idType}.label`)
    }

    const clientIdNo = get(user, 'clientInfoId.clientIdNo');

    if (clientIdNo) {
      showHideEl(this._identificationNumber, true)
      this._identificationNumber.setValue(get(user, 'clientInfoId.clientIdNo'));
    } else {
      showHideEl(this._identificationNumber, false)
    }
  }

  //Alternate Id's can only be FIN.
  _setAlternateId(user) {
    const fin = (get(user, 'clientAlternateIds') || []).find((id) => {
      return id.idType === USER_ALTERNATIVE_ID_TYPES.FIN;
    });

    if(fin) {
      showHideEl(this._alternateId, true);
      this._alternateId.parentElement.insertBefore(this._alternateId, this._idIssuingCountryInput);
      let alternateId = fin.idNumber;
      this._alternateId.label = i18n.getTranslation('app.widget-settings-user-general.inputs.fin.label')
      this._alternateId.setValue(alternateId);
    } else {
      showHideEl(this._alternateId, false);
    }
  }

  setData(user, account) {

    // Subscribe for the accountType
    let {accountType} = this._sessionStore.getData();
    const isCorporateAccount = [constants.ACCOUNT_STATES.CORPORATE, constants.ACCOUNT_STATES.CORPORATE_TRUSTEE].includes(accountType);

    this._sessionStore.subscribe(acc => {
      if (accountType !== acc.accountType && acc.accountType) {
        accountType = acc.accountType;
        this.setData(user, account);
      }
      if (isCorporateAccount) {
        this._hideFields();
      }
    });



    // Fill form values with user particulars
    if (!!user) {
      const userTitleMap = {
        "5": "Dr",
        "10": "Mdm",
        "1": "Mr",
        "2": "Mrs",
        "3": "Ms"
      }
      const userGenderMap = {
        "1": "M",
        "2": "F"
      }
      user.title && userTitleMap[user.title] && this._userTitle.setValue(userTitleMap[user.title]);
      user.gender && userGenderMap[user.gender] && this._gender.setValue(userGenderMap[user.gender]);
      this._fullNameInput.setValue(user.name);
      this._setUserIndentifierNumber(user);
      this._setCountry(this._countryOfBirth, get(user, 'countryOfBirth'));
      this._setNationality(this._nationality, get(user, 'nationality'));
      this._setSingaporeanPRFlag(user);
      this._setAlternateId(user);
      if (get(user, 'dateOfBirth')) {
        this._setDateOfBirth(get(user, 'dateOfBirth'));
      }
    }

    // Adjust title and fields if corporate account
    if (isCorporateAccount) {
      this._title.textContent = i18n.getTranslation('app.widget-settings-user-general.corporateTitle');
    }
  }
}

customElements.define('widget-settings-user-general', withInitDOM(UserGeneralWidget));
