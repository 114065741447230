import SgxTableCellBase from 'sgx-table/src/sub-components/sgx-table-cell/sgx-table-cell-base';
import { withInitDOM } from 'sgx-base-code';

export default class SgxTableCellTextDoubleRow extends SgxTableCellBase {
  initDOM() {
    this.innerHTML = `<div class="sgx-table-cell-text-double-row-label"></div>
      <div class="sgx-table-cell-text-double-row-label"></div>`;
    this._labelElements = this.querySelectorAll('.sgx-table-cell-text-double-row-label');
  }

  connectedCallback() {
    super.connectedCallback();
  }

  setConfig(config, columnConfig, tableConfig) {
    this._config = config;
    this._config.defaultValue = this._config.defaultValue || '';
  }

  setData(data, action) {
    this._data = data;
    if (this._data) {
      this._labelElements[0].textContent = data[0];
      this._labelElements[1].textContent = data[1];
    } else {
      this._labelElements[0].textContent = this._config.defaultValue;
      this._labelElements[1].textContent = '';
    }
  }
}

customElements.define('sgx-table-cell-text-double-row', withInitDOM(SgxTableCellTextDoubleRow));
