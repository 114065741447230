import SgxDataModel from '@sgx/sgx-data-model/src/sgx-data-model';
import CorporateActionsService from 'services/sgx-corporate-actions-service';
import DateUtil from 'utils/date-util';
import i18n from 'sgx-localisation-service';

let dataInitialized = false;
let pageStart = 0;
let totalDataSize = 0;
let fetchMoreAt = 0;
let isFetching = false;
let totalRecords = 0;

class SgxCorporateActionsModel extends SgxDataModel {

  setConfig(config) {
    super.setConfig(config);
    this._percentageResultsShown = config.modelLazyLoad ? config.modelLazyLoad.threshold : undefined;
    this._pageSize = config.modelLazyLoad ? config.modelLazyLoad.pageSize : 250;
  }

  /*
   * @returns {Promise<Object>} returns an object with array[0] = count, array[1] = array of announcements
  */
  getDataRange(fromIndex, length) {
    const filtersForURL = this._toFilterObject(this._model._state.filters);
    const filters = {
      ...filtersForURL,
      pagesize: this._pageSize,
      pagestart: pageStart// length = page size
    };
    this._offset = fromIndex;

    if (!dataInitialized) {
      return this._executeApi(filters, 'getDataRange').then(res => {
        dataInitialized = true;
        return Promise.resolve(res);
      });
    }

    let from = fromIndex || 0;
    let tempTo = (from + (length || 0));
    let to = tempTo > this.getFilteredRowCount() ? this.getFilteredRowCount() : tempTo;

    const results = this._model._data.keys.slice(from, to).map(function (key) {
      return this._model._data.values[key];
    }, this);

    if (to >= fetchMoreAt && totalDataSize < totalRecords && !isFetching) {
      isFetching = true;
      this._fetchDataPromise = this._executeApi(filters, 'getDataRange')
        .then(res => {
          this._fetchDataPromise = null;
          isFetching = false;
          return isFetching;
        });
    }

    // document.querySelector('sgx-app-router').navigateWithoutTransition(filtersForURL);
    return Promise.resolve(results);
  }

  whenQueryCompletes() {
    return this._fetchDataPromise ? this._fetchDataPromise.then(_ => true) : Promise.resolve(true);
  }

  setState(state) {
    pageStart = 0;
    dataInitialized = false;
    this.filterDialogNoRowsFiltered = this._pageSize; // this is for the initial scrollbar size
    return this._executeApi({pagesize: 1}, false).then(_ => super.setState(state));
  }

  // #region - Methods required for Filter Dialog
  /**
   * Overrides the implementation of getDataForState when limit is count
   * to work with the filters dialog.
   * the getDataRange but doesn't trigger or dispatch the update event of the model.
   * @return {Promise<Object>} returns the total number of filtered data for limit='count'
   */
  getDataForState(state, limit) {
    if (limit === 'count') {
      const filters = {
        ...this._toFilterObject(state.filters || {}),
        pagesize: 1
      };
      return this._executeApi(filters, false, Boolean(state.filters.conditions && !state.filters.conditions.length))
        .then((e) => {
          return this._meta.noRowsFiltered
        });
    }
    return super.getDataForState.call(this, state, limit);
  }

  getRowCount() {
    return this._filterDialogTotalRows || 0;
  }

  getFilteredRowCount(isExternal) {
    return isExternal ? this._filteredRowCountExternal :  this.filterDialogNoRowsFiltered || 0;
  }

  getRowIndex(rowId) {
    if (typeof rowId === 'undefined') {
      return Promise.reject('rowId must be provided');
    }

    return Promise.resolve(this._model._data.keys.indexOf(rowId));
  }

  // #endregion


  /**
   * Executes Market Data APi using the CMS service
   *
   * @param {Object} [filters] simple key value object format (output from calling `_toFilterObject` method)
   * @return {Promise<Array>} an array of market updates
   */
  _executeApi(filters, action, isHiddenState) {
    return CorporateActionsService.getCorporateActions(filters).then(({data, totalItems, totalPages}) => {
      const parsedCount = Number(totalItems || 0); // converts [] to 0 for queries with invalid date
      // This will be the overall unfiltered count that will be displayed on the filter dialog and will only be set once
      if (!this._filterDialogTotalRows) {
        this._filterDialogTotalRows = parsedCount;
      }

      if (!isHiddenState) {
        this._filteredRowCountExternal = parsedCount;
      }

      // this.filterDialogNoRowsFiltered = parsedCount;
      totalRecords = parsedCount;

      // Update meta info
      this._meta.noRowsAll = this._filterDialogTotalRows;
      this._meta.noRowsFiltered = parsedCount;



      if (action && typeof action === 'string') {

        if (dataInitialized) {
          this._model.appendData(data);
        } else {
          this._model.setData(data);
        }

        totalDataSize = this._model._data.keys.length;
        fetchMoreAt = Math.floor(this._percentageResultsShown * totalDataSize);
        pageStart += 1;
        this.filterDialogNoRowsFiltered = totalDataSize;

        const payload = {
          action,
          meta: {
            noRowsAll: this._filterDialogTotalRows,
            noRowsFiltered: parsedCount
          }
        };
        if (typeof data !== 'undefined') {
          payload.result = data;
        }
        this.dispatchEvent(new CustomEvent('update', {detail: payload}));
      }
      return data;
    });
  }

  /**
   * Converts model state filter into simple object by just getting the filter key and value of each filter
   *
   * @param {Object} [filters] model state filter format
   * @param {Array<Object>} [filters.conditions] conditions format of the filter's state
   * @return {Object} simple key value object format
   */
  _toFilterObject({conditions}) {
    if (!conditions) {
      return {};
    }
    return conditions.reduce((filters, {columnId, value, method}) => {
      if (value === null || (typeof value === 'string' && value.trim() === '')) {
        return filters;
      }
      switch (columnId) {
        case 'exDate':
          value.fromDate && (filters.exdatestart = DateUtil.formatDateTo(value.fromDate, null, i18n.getTranslation('app.format.date.number'), true));
          value.toDate && (filters.exdateend = DateUtil.formatDateTo(value.toDate, null, i18n.getTranslation('app.format.date.number'), true));
          break;
        case 'datePaid':
          value.fromDate && (filters.payabledatestart = DateUtil.formatDateTo(value.fromDate, null, i18n.getTranslation('app.format.date.number'), true));
          value.toDate && (filters.payabledateend = DateUtil.formatDateTo(value.toDate, null, i18n.getTranslation('app.format.date.number'), true));
          break;
        case 'recDate':
          value.fromDate && (filters.recdatestart = DateUtil.formatDateTo(value.fromDate, null, i18n.getTranslation('app.format.date.number'), true));
          value.toDate && (filters.recdateend = DateUtil.formatDateTo(value.toDate, null, i18n.getTranslation('app.format.date.number'), true));
          break;
        case 'cat':
          filters['cat'] = value;
          break;
        case 'name':
          filters['name'] = value;
          break;
        default:
          break;
      }
      return filters;
    }, {});
  }
}


customElements.define('cmp-data-model-corporate-actions', SgxCorporateActionsModel);
