module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-ccy-subscription\">\n\n  <section class=\"widget-ccy-subscription-section-details\">\n    <div class=\"widget-ccy-subscription-title-section\">\n      <h3 class=\"widget-ccy-subscription-title\" data-i18n=\"widget-ccy-subscription.title\"></h3>\n      <sgx-status-tag class=\"widget-ccy-subscription-status-indicator\"></sgx-status-tag>\n    </div>\n    <p class=\"widget-ccy-subscription-text widget-ccy-subscription-footer-title\"></p>\n    <p class=\"widget-ccy-subscription-text widget-ccy-subscription-footer-description\"></p>\n  </section>\n\n  <section class=\"widget-ccy-subscription-section-cta\">\n    <button class=\"widget-ccy-subscription-update-btn sgx-button--primary sgx-hidden\"></button>\n  </section>\n\n\n  <widget-ccy-subscription-dialog></widget-ccy-subscription-dialog>\n\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()