import SgxTableCellBase from 'sgx-table/src/sub-components/sgx-table-cell/sgx-table-cell-base';
import {withInitDOM} from 'sgx-base-code';

/**
 * A custom HTML element used to render cells for portfolio e-rights
 * @module sgx-table-cell-dialog
 * @type {HTMLElement}
 */
class SgxTableCellDialog extends SgxTableCellBase {

  constructor() {
    super();
    this._toggleDialog = this._toggleDialog.bind(this);
    this.dialogTypeData = {
      'amount-payable': {}
    }
  }

  initDOM() {

  }

  connectedCallback() {
    super.connectedCallback();
    this._setEventListeners(true);
  }

  disconnectedCallback() {
    this._setEventListeners(false);

  }

  _setEventListeners(state) {
    const fnName = state === true ? 'addEventListener' : 'removeEventListener';
    this[fnName]('click', this._toggleDialog)
  }

  _toggleDialog() {
    switch (this._dialogType) {
      case 'amount-payable':
        const amountData = this.dialogTypeData['amount-payable'];
        if (!amountData) {
          return;
        }
        const paymentDialog = document.querySelector('widget-rights-payment-dialog');
        paymentDialog.setData(amountData);
        paymentDialog.showDialog();
        break;
      case 'payment-status':
        const statusData = this.dialogTypeData['payment-status'];
        if (!statusData) {
          return;
        }
        const paymentDetailsDialog = document.querySelector('widget-rights-dialog');
        paymentDetailsDialog.setData(statusData);
        paymentDetailsDialog.showDialog();
        break;
    }
  }

  setConfig(config, columnConfig, tableConfig) {
    this._dialogType = columnConfig.dialog;
  }

  setData(data, action) {
    if (!data) {
      return;
    }
    if (data.isLink) {
      this.innerHTML = '';
      this._link = document.createElement('span');
      this._link.classList.add('cell-link');

      this.appendChild(this._link);
      this._link.innerHTML = data.label;
      this.dialogTypeData[this._dialogType] = data.data;
    } else {
      this.innerHTML = data.label;
      this.dialogTypeData[this._dialogType] = null;
    }
  }


}

customElements.define('sgx-table-cell-dialog', withInitDOM(SgxTableCellDialog));
