import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-portfolio-payouts-toolbar.html';

class WidgetPortfolioPayoutsToolbar extends HTMLElement {
  constructor() {
    super();
    this._onTableDataModelUpdate = this._onTableDataModelUpdate.bind(this);
  }

  initDOM() {
    this.classList.add('widget-portfolio-payouts-toolbar');
    this.appendChild(tmpl.getNode());

    this._print = this.querySelector('sgx-data-model-tool[icon="print"]');
    this._download = this.querySelector('sgx-data-model-tool[icon="download"]');
    this._table = this.closest('sgx-table');

    this._print.setDisabled(true);
    this._download.setDisabled(true);
  }

  connectedCallback() {
    this._setupListener(true);
  }

  disconnectedCallback() {
    this._setupListener(false);
  }

  _setupListener(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this[fnName]('click', this._onClick);
    this._table.getDataModel()[fnName]('update', this._onTableDataModelUpdate);
  }

  _onClick(evt) {
    // QUICKFIX - prevent click events from triggering internal sgx-table actions
    evt.stopImmediatePropagation();
  }

  _onTableDataModelUpdate(_) {
    const disabled = this._table.getDataModel().getFilteredRowCount() === 0;
    this._print.setDisabled(disabled);
    this._download.setDisabled(disabled);
  }
}

customElements.define('widget-portfolio-payouts-toolbar', withInitDOM(WidgetPortfolioPayoutsToolbar));
