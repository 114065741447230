'use strict';

/**
 * To add a custom toolbar in favor of sgx-table default toolbar
 * @param {sgx-table} table The sgx-table component instance to be modified
 * @param {String | Element} String or custom element representing the toolbar to be added
 * @returns {sgx-table}
 */
function addCustomToolbar(table, toolbar) {
  const customToolbar = typeof toolbar === 'string' ? 
    document.createDocumentFragment().appendChild(document.createElement(toolbar)) :
    toolbar;
  customToolbar.classList.add('sgx-table-toolbar-container');
  table.querySelector('sgx-table-toolbar').classList.add('sgx-table-toolbar--custom');
  const filterBar = table.querySelector('.table-filters-container');
  const filterBarContainer = filterBar.parentElement;
  filterBarContainer.appendChild(customToolbar);
  filterBarContainer.removeChild(filterBar);
  return table;
}

module.exports = {
  addCustomToolbar: addCustomToolbar
};
