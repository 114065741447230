import i18n from 'sgx-localisation-service';
import featureToggleUtil from '../../utils/feature-toggle-util';

let states = [
  {
    label: i18n.getTranslation('app.widget-portfolio.tabs.overview'),
    data: 'widget-portfolio-overview'
  },
  // it appears that transaction securities tab appears for chinese translation, safer to not include this at ll in the config
  // {
  //   label: i18n.getTranslation('app.widget-portfolio.tabs.transfer-securities'),
  //   data: 'widget-portfolio-transfer-securities'
  // },
  {
    label: i18n.getTranslation('app.widget-portfolio.tabs.payouts'),
    data: 'widget-portfolio-payouts'
  },
  {
    label: i18n.getTranslation('app.widget-portfolio.tabs.share-transactions'),
    data: 'widget-portfolio-transactions'
  },
  {
    label: i18n.getTranslation('app.widget-portfolio.tabs.e-statements'),
    data: 'widget-portfolio-statements'
  }
]

if (featureToggleUtil.isERightsEnabled()) {
  states.push({
    label: i18n.getTranslation('app.widget-portfolio.tabs.rights'),
    data: 'widget-portfolio-rights'
  });
}

export default {
  config: {
    type: 'content',
    editable: false
  },
  state: {
    activeStateId: 0,
    states
  }
};
