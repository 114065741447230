'use strict';

var abbreviations = ['', 'K', 'M', 'B', 'T'];

/**
 * Converts the given number to an abbreviated string with the given decimal places
 * @param {Number} num the number to abbreviate
 * @param {Number} dec the number of decimal places (uses toFixed method)
 * @param {Number} [removeTrailingZeroes] flag indicating whether the trailing zeroes should be removed
 * @returns {String}
 */
function abbreviateNumber(num, dec, removeTrailingZeroes) {
  if (num == null) { return null; }
  if (num === 0) { return '0'; }

  var dp = (!dec || dec < 0) ? 0 : dec;
  var b = (num).toPrecision(2).split('e'); // get power
  var k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions
  var c = k < 1 ? num.toFixed(dp) : (num / Math.pow(10, k * 3) ).toFixed(dp); // divide by power

  if (removeTrailingZeroes) {
    var rounded = Math.floor(c);
    if (c % rounded === 0) {
      c = rounded;
    }
  }

  var e = c + abbreviations[k]; // append abbreviation
  return e;
}

/**
 * Call abbreviateNumber method with num value set to the current chart value
 * @param {Number} dp
 * @returns {String}
 */
function getAbbreviateFormatter(dp) {
  return function() {
    return abbreviateNumber(this.value, dp);
  };
}

/**
 * Formats a number to add thousand seperator (currently commas)
 * @param {Number} n
 * @param {Number} dp
 * @param {Boolean} allowZero
 * @returns {String}
 */
function formatThousands(n, dp, allowZero) {
  var number = Number(n || 0);

  if (number === 0 && !allowZero) {
    return '-';
  }

  if (!isNaN(dp) && dp != null) {
    number = toFixed(number, dp);
  }

  var parts = (number || 0).toString().split('.');
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');
}

/**
 * Custom version of toFixed that bypasses native javascript rounding errors
 * @param {Number} num - number to perform operation on
 * @param {Number} p - level of precision for formatted number
 * @returns {String}
 */
function toFixed(num, p) {
  var n = Number(num);
  var d = (Math.floor(n) === n) ? '' : (n.toString().split('.')[1] || '');
  // only round up if numbers has 1 more digit than the required precision
  // and the last decimal is 5 or more
  var shouldRoundUp = (d.length - p === 1) && d.slice(-1) >= 5;

  if (p > 0 && shouldRoundUp) {
    // increase the last significant digit by 1
    n = n + ((n > 0 ? 1 : -1) / Math.pow(10, p + 1));
  }

  return n.toFixed(p);
}

/**
 * Custom version of toFixedPrecision to disallow rounding up of decimal numbers
 * @param {Number} num - number to perform operation on
 * @returns {String}
 * */
function toFixedPrecision(num) {
  let n = Number(num);
  let [nonDecimal, decimal] = n.toString().split('.');

  decimal = decimal ? decimal.split('').splice(0, 9).join().replace(/,/g, '') : ''

  return +`${nonDecimal}.${decimal}`;
}

module.exports = {
  abbreviateNumber: abbreviateNumber,
  getAbbreviateFormatter: getAbbreviateFormatter,
  formatThousands: formatThousands,
  toFixed
};
