import { URLUtils } from 'sgx-base-code';
import BaseService from 'services/base-service';
import * as constants from './portfolio-service-constants';
import StoreRegistry from 'stores/store-registry';

class PortfolioService extends BaseService {
  /**
   * Get the constants associated to the portfolio service
   */
  get constants() {
    return constants;
  }

  /**
   * Gets account balances
   * @param {String} accountId CDP Account ID
   * @return {Promise<object>} a promise
   */
  getBalances(accountId) {
    const params = { snapshotIndicator: 'Y' };
    const url = URLUtils.setQueryParams(this._endpoints.PORTFOLIO_BALANCES_READ, params);

    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => {
        // The `securityCode` that we're getting back for each balance is map to `ibmCode` to get the correct `stockCode`
        return response && response.data && response.data.balancesDetails || []
      });
    // return PortfolioServiceStub.getBalances(accountId);
  }

  /**
   * Get list of monthly statement info for a specified account by date range
   * @param {string} accountId CDP Account ID
   * @param {string} startMonth start month in YYYY-MM format
   * @param {string} endMonth end month in YYYY-MM format
   * @return {Promise<object>} a promise
   */
  getAccountStatements(accountId, startMonth, endMonth) {
    const params = { startMonth, endMonth };
    const url = URLUtils.setQueryParams(this._endpoints.PORTFOLIO_ACCOUNT_STATEMENTS_READ, params);

    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => (response && response.data) || []);
  }

  /**
   * Get a monthly statement for a securities account by notification id
   * @param {string} accountId CDP Account ID
   * @param {string} notfId notification id
   * @return {Promise<object>} a promise
   */
  getAccountStatementById(accountId, notfId) {
    return this._authFetch(`${this._endpoints.PORTFOLIO_ACCOUNT_STATEMENTS_READ}/${notfId}`, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => response.data);
  }

  /**
   * Get a list of daily notification info for a securities account by date range
   * @param {string} accountId CDP Account ID
   * @param {string} startDate start date in YYYY-MM-DD format
   * @param {string} endDate end date in YYYY-MM-DD format
   * @return {Promise<object>} a promise
   */
  getNotifications(accountId, startDate, endDate) {
    const params = { startDate, endDate };
    const url = URLUtils.setQueryParams(this._endpoints.PORTFOLIO_NOTIFICATIONS_READ, params);

    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => (response && response.data) || []);
  }

  /**
   * Get a daily notification for a securities account by notification id
   * @param {*} accountId CDP Account ID
   * @param {*} notfId notification id
   * @return {Promise<object>} a promise
   */
  getNotificationById(accountId, notfId) {
    return this._authFetch(`${this._endpoints.PORTFOLIO_NOTIFICATIONS_READ}/${notfId}`, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => response.data);
  }

  /**
   * Get a list of pre-settlement notifications by specified securities account and date range
   * @param {string} accountId CDP Account ID
   * @param {string} startDate start date as the current date in YYYY-MM-DD format
   * @return {Promise<object>} a promise
   */
  getPreSettlementNotifications(accountId, startDate) {
    const params = { startDate };
    const url = URLUtils.setQueryParams(this._endpoints.PORTFOLIO_PSN_READ, params);

    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => (response && response.data) || []);
  }

  /**
   * Get account's payment info
   * @param {string} accountId CDB Account ID
   * @param {string} startDate start date in YYYY-MM-DD format
   * @param {string} endDate end date in YYYY-MM-DD format
   * @return {Promise<object>} a promise
   */
  getPayouts(accountId, startDate, endDate) {
    const params = { startDate, endDate };
    const url = URLUtils.setQueryParams(this._endpoints.PORTFOLIO_PAYOUTS_READ, params);

    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => (response && response.data && response.data.paymentSummaryDetails) || []);
  }

  /**
   * Get account's shared transactions
   * @param {string} accountId CDB Account ID
   * @param {string} startDate start date in YYYY-MM-DD format
   * @param {string} endDate end date in YYYY-MM-DD format
   * @return {Promise<object>} a promise
   */
  getTransactions(accountId, startDate, endDate) {
    const getAll = 'N';
    const params = { startDate, endDate, getAll };
    const url = URLUtils.setQueryParams(this._endpoints.PORTFOLIO_TRANSACTIONS_READ, params);

    return this._authFetch(url, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => (response && response.data) || {});
  }

  /**
   * Get pending SI details
   * @param {string} accountId
   * @return {Promise<object>} a promise
   */
  getIntransitBalances(accountId) {
    return this._authFetch(this._endpoints.PORTFOLIO_IN_TRANSIT_READ, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => (response && response.data && response.data.pendingSIDetails) || []);
  }

  async getLoanBalances(accountId) {
    try {
      const account = StoreRegistry.accountSelect.getData('options').filter(account => account.accountId === accountId)[0];

      if ([2, 4].includes(+account.lendingStatus)) {
        return this._authFetch(this._endpoints.PORTFOLIO_SBL_LOAN_INFO_READ, {
          headers: {
            [this._requestHeaders.accountId]: accountId
          }
        })
          .then(response => (response && response.data && response.data.sblLoanInfo) || []);
      }
      return [];

    } catch (e) {
      return [];
    }

  }

  /**
   * Gets portfolio unit cost for a security account (including total balance unit costs). Only non-zero cost will be returned
   * @param {string} accountId
   * @return {Promise<object>} a promise
   */
  getPortfolioCosts(accountId) {
    return this._authFetch(this._endpoints.PORTFOLIO_UNIT_COST_READ_UPDATE, {
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    })
      .then(response => (response && response.data && response.data.counters) || []);
  }

  /**
   *
   * @param {string} accountId CDP Account ID
   * @param {object} payload request payload
   * @param {string} payload.securityCode securityCode (ibmCode) of the balance
   * @param {number} payload.unitPrice
   * @return {Promise<object>} a promise
   */
  updatePortfolioCost(accountId, payload) {
    const body = JSON.stringify([{
      ...payload,
      isTotal: false
    }]);
    return this._authFetch(this._endpoints.PORTFOLIO_UNIT_COST_READ_UPDATE, {
      method: 'PUT',
      body,
      headers: {
        [this._requestHeaders.accountId]: accountId
      }
    });
  }

  /**
   * Gets the utils shared instance.
   * @return {PortfolioService} the utils shared instance
   */
  static get instance() {
    if (!PortfolioService._instance) {
      PortfolioService._instance = new PortfolioService();
    }
    return PortfolioService._instance;
  }
}

export default PortfolioService.instance;
