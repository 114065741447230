import { URLUtils, FetchUtils } from 'sgx-base-code';
import ConfigService from '@sgx/sgx-config-service';
import i18n from '@sgx/sgx-localisation-service';
import get from 'lodash/get';

const CMS_API_URL = ConfigService.endpoints.CMS_API_URL;
const SGX_CMS_VERSION = ConfigService.SGX_CMS_VERSION;

class SgxComCmsService {

  /**
   * Gets sgx.com product details
   * @param {String} product product code
   * @return {Promise<object>} a promise
   */
  getProductDetails(product) {
    let path = `/securities/products/${product}`;
    let lang = 'EN';
    if (i18n.getLanguage() === 'zh-cn') {
      path = `/zh-hans${path}`;
      lang = 'ZH_HANS';
    }
    return this._fetchCMS('page', { path, lang })
      .then(data => get(data, 'data.route.data.data'));
  }

  _fetchCMS(action, params) {
    const queryContent = `${SGX_CMS_VERSION}:${action}`;

    return FetchUtils.fetchJSON(URLUtils.setQueryParams(CMS_API_URL, {
      queryId: queryContent,
      variables: JSON.stringify(params)
    }));
  };

  /**
   * Gets the utils shared instance.
   * @return {SgxComCmsService} the utils shared instance
   */
  static get instance() {
    if (!SgxComCmsService._instance) {
      SgxComCmsService._instance = new SgxComCmsService();
    }
    return SgxComCmsService._instance;
  }
}

export default SgxComCmsService.instance;
