import tmpl from './widget-user-details-myinfo.html';
import i18n from 'sgx-localisation-service';
import DeviceService from '@sgx/sgx-device-service';
import { withInitDOM } from 'sgx-base-code';
import StoreRegistry from 'stores/store-registry';
import myInfoService from 'services/myinfo-service';
import userService from 'services/user-service';
import jwt_decode from 'jwt-decode';
import {
  validateContact,
  validateRegAddress,
  validateEmail,
  validateUinfin,
  validateName,
  validateDob
} from 'utils/myinfo-util';
import ReferenceDataService from 'services/reference-data-service';
import ConfigService from 'sgx-config-service';
import {
  ACCOUNT_STATES,
  USER_TYPES
} from 'services/user-service/src/user-service-constants';
import {cloneDeep, get} from 'lodash';
import { createFormData } from 'utils/profile-particulars';
import InternationalCallingCode from '@sgx/sgx-forms/src/sgx-input-phone/InternationalCallingCode.js';
import DateTimeService from '@sgx/sgx-date-time-service';

/**
 * General details widget.
 */
class WidgetMyInfoDetails extends HTMLElement {
  constructor() {
    super();
    this._subscriptions = [];
    this._updateParticularsWithMyInfoData = this._updateParticularsWithMyInfoData.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._cdpStore = StoreRegistry.cdpSession;
    this._initialUserData = StoreRegistry.initialUserData;
    const {fullList = true} = StoreRegistry.user.getData();
    this._fullList = fullList;
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-user-details-myinfo');
    this._statusIndicator = this.querySelector('.widget-user-details-myinfo-indicator');
    this._myinfoAlert = this.querySelector('.widget-user-details-myinfo-alert');
    this._router = document.querySelector('sgx-app-router');
  }

  async _getCountries() {
    const { data: countries } = await ReferenceDataService.getCountriesMyInfo();
    let countryOptions = new Map();
    countries.forEach(country => (
      countryOptions.set(country.id, country.name)
    ));
    return countryOptions;
  }

  async _getNationalities(){
    const { data: nationalities } = await ReferenceDataService.getNationalitiesMyInfo();
    let nationalityOptions = new Map();
    Object.keys(nationalities).forEach((key) => {
      nationalityOptions.set(key, nationalities[key])
    })
    return nationalityOptions;
  }

  setData(code) {
    // prevent from calling twice with the same authcode
    if (this._authCode === code) {
      return;
    }

    const { idType, issuingCountry } = this._cdpStore.getData();
    const currentState = this._router.getState().name;
    this._isForeigner = (idType == USER_TYPES.FOREIGNER)  || (idType == USER_TYPES.SC_PR && issuingCountry !== 'SG');
    this._authCode = code;

    // show a loading indicator
    this._statusIndicator.show({
      status: 'loading'
    });
    this._dialog.bodyElement.classList.add('active-indicator');
    this._dialog.bodyElement.scrollTop = 0;
    this._showDialogFooter(false);
    myInfoService.getParticulars(this._authCode, false, currentState)
      .then(myinfoDataEncoded => {
        this._myinfoUserToken = myinfoDataEncoded;
        this._parseMyInfoParticulars(myinfoDataEncoded);
        this._showDialogFooter(true);
        this._statusIndicator.hide();
        this._dialog.bodyElement.classList.remove('active-indicator');
      })
      .catch(error => {
        console.error(`Failed to load data into form view. ${error}`);
        this._showDialogFooter(false);
        this._statusIndicator.show({
          status: 'error',
          title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
          description: i18n.getTranslation('app.shared-text.status-indicator.error.description'),
          button: {
            text: i18n.getTranslation('app.userDetailsMyinfo.buttons.ok'),
            callback: () => {
              this.teardown();
            }
          }
        });
      });
  }

  showDialog(code) {
    this.setData(code);
    this._dialog.show({
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      actions: {
        cancel: () => this.teardown()
      }
    });
  }

  hideDialog() {
    this._dialog.hide();
  }

  teardown() {
    this.hideDialog();
    if (!this._fullList) {
      StoreRegistry.user.setData(true, 'fullList');
      StoreRegistry.user.saveData();
    }
  }

  connectedCallback() {
    this._dialog = document.querySelector('#widget-user-details-myinfo-dialog');
    this._fullNameInput = this._dialog.querySelector('.widget-user-details-myinfo-fullName');
    this._genderInput = this._dialog.querySelector('.widget-user-details-myinfo-gender');
    this._countryOfBirthInput = this._dialog.querySelector('.widget-user-details-myinfo-countryOfBirth');
    this._nationalityInput = this._dialog.querySelector('.widget-user-details-myinfo-nationality');
    this._dateOfBirthInput = this._dialog.querySelector('.widget-user-details-myinfo-dob');
    this._userMobileNumber = this._dialog.querySelector('.widget-user-details-myinfo-mobileNo');
    this._userEmailAddress = this._dialog.querySelector('.widget-user-details-myinfo-emailAddress');
    this._userResidentialAddress = this._dialog.querySelector('.widget-user-details-myinfo-residentialAddress');
    this._userMailingAddress = this._dialog.querySelector('.widget-user-details-myinfo-mailingAddress');
    this._userResidentialStatus = this._dialog.querySelector('.widget-user-details-myinfo-residential-status');
    this._updateButton = this._dialog.querySelector('.widget-user-details-myinfo-button-update');
    this._cancelButton = this._dialog.querySelector('.widget-user-details-myinfo-button-cancel');
    this._form = this._dialog.querySelector('.widget-user-details-myinfo-form');
    this._userUinfin = this._dialog.querySelector('.widget-user-details-myinfo-userUinfin');
    this._nricLabel = this._dialog.querySelector('.widget-user-details-myinfo-userUinfin-label');
    this._hanyupinyinName = this._dialog.querySelector('.details_hanyupinyinName');
    this._aliasName = this._dialog.querySelector('.details_aliasName');
    this._hanyupinyinAliasName = this._dialog.querySelector('.details_hanyupinyinAliasName');
    this._marriedName = this._dialog.querySelector('.details_marriedName');

    this._nricLabel.innerHTML = i18n.getTranslation('app.userDetailsMyinfo.userUinfin.hint');
    this._setListeners(true);
  }

  disconnectedCallback() {
    (this._subscriptions || []).forEach(sub => sub.unsubscribe());
    this._setListeners(false);
  }

  _showDialogFooter(show) {
    const classFn = show ? 'remove' : 'add';
    this._updateButton.classList[classFn]('sgx-hidden');
    this._cancelButton.classList[classFn]('sgx-hidden');
  }

  _updateParticularsWithMyInfoData(e) {
    this._statusIndicator.show({
      status: 'loading'
    });
    this._dialog.bodyElement.classList.add('active-indicator');
    this._dialog.bodyElement.scrollTop = 0;
    this._showDialogFooter(false);
    e.preventDefault();
    this._setupPayload()
    const myinfoUserToken = this._myinfoUserToken;
    const payload = this._payload;
    userService.updateUserParticularsMyInfo({ payload, myinfoUserToken })
      .then(({ status = 200 }) => {
        //this._updateButton.setAttribute('disabled', true);
        this._statusIndicator.show({
          status: 'warning',
          description: i18n.getTranslation(`app.userDetailsMyinfo.success.${status}`),
          button: {
            text: i18n.getTranslation('app.userDetailsMyinfo.buttons.ok'),
            callback: () => {
              this.teardown();
            }
          }
        });
        // update cdpSession to refresh template-settings-user's data.
        // `myinfoParticularsUpdatedTime` is just use to make sure that template-settings-user will be updated everytime user tried to update using the myinfo
        this._cdpStore.setData({ ...this._cdpStore.getData(), myinfoParticularsUpdatedTime: new Date().getTime() });
      })
      .catch(error => {
        this._showDialogFooter(false);
        const status = error.status;
        const indicator = {
          status: 'error',
          description: i18n.getTranslation('app.userDetailsMyinfo.error.500'),
          button: {
            text: i18n.getTranslation('app.userDetailsMyinfo.buttons.ok'),
            callback: () => {
              this.teardown();
            }
          }
        };

        if (status === 400) {
          error.json()
            .then(response => {
              const reason = response.errors[0].reason;
              switch (reason) {
                case 'ID_DOES_NOT_MATCH':
                  this._statusIndicator.show({
                    ...indicator,
                    description: i18n.getTranslation('app.userDetailsMyinfo.error.400_NRIC_Mismatch_html', {
                      link: ConfigService.links.SGX_V2_UPDATE_PARTICULARS
                    })
                  });
                  break;
                case 'CLIENT_NAME_INVALID':
                  this._statusIndicator.show({
                    ...indicator,
                    description: i18n.getTranslation('app.userDetailsMyinfo.error.invalid_name', {
                      link: ConfigService.links.SGX_V2_UPDATE_PARTICULARS
                    })
                  });
                  break;
                case 'US_TAX_RESIDENCY_MANDATORY':
                  this._statusIndicator.show({
                    ...indicator,
                    description: i18n.getTranslation('app.userDetailsMyinfo.error.US_TAX_RESIDENCY_MANDATORY_html', {
                      link: ConfigService.links.SGX_V2_UPDATE_PARTICULARS
                    })
                  });
                  break;
                default:
                  this._statusIndicator.show(indicator);
              }
            });
          return;
        }
        this._statusIndicator.show(indicator);
      });

  }

  _onFormChange(e) {
    e.preventDefault();

  }

  _setupPayload(){
    let payload = {};
    let userParticulars = createFormData({isMyinfo: true});
    let isMailingAddressUpdated, isResidentialAddressUpdated = false;
    let isContactUpdated = get(userParticulars, 'mobileContactCountry') !== 'SG';

    isResidentialAddressUpdated = get(this, '_residentialAddress.country') ? get(this, '_residentialAddress.country') !== userParticulars.residentalCountry : false;

    payload['personalEmail'] = this._personalEmail;
    payload['mobileContact'] = this._mobileContact;

    if (!this._fullList) {
      let isValid = [this._userEmailAddress, this._userMobileNumber].some(el => el.getAttribute('valid') !== 'false');
      userParticulars = this._formatUserParticulars(payload, userParticulars, { isResidentialAddressUpdated, isContactUpdated, isMailingAddressUpdated });

      this._payload = {
        myInfoParticulars: payload,
        fatcaCrsDueDCheck: !isValid ? null : userParticulars.fatcaCrsDueDCheck
      };

      this._updateButton.disabled = isValid;

      return;
    }

    payload['residentialAddress'] = this._residentialAddress;
    payload['principalName'] = this._fullName;
    payload['hanyuPinyinName'] = this._myInfo_hanyupinyinName;
    payload['aliasName'] = this._myInfo_aliasName;
    payload['hanyuPinyinAliasName'] = this._myInfo_hanyupinyinAliasName;
    payload['marriedName'] = this._myInfo_marriedName;
    payload['dateOfBirth'] = this._dateOfBirth ? DateTimeService(this._dateOfBirth).format('YYYY-MM-DD') : '';
    payload['gender'] = this._gender;
    payload['nationality'] = this._nationality;
    payload['countryOfBirth'] = this._countryOfBirth;
    payload['residentialStatus'] = this._residentialStatus;

    if (this._userMailingAddress.value === true) {
      payload['mailingAddress'] = cloneDeep(this._residentialAddress);
      isMailingAddressUpdated = get(this, '_residentialAddress.country') !== userParticulars.mailingAddressCountry;
    }

    this._payload = payload;
    this._updateButton.disabled = [this._userEmailAddress, this._userMobileNumber, this._userResidentialAddress,
      this._fullNameInput, this._dateOfBirthInput, this._genderInput, this._nationalityInput,
      this._countryOfBirthInput].some(el => el.getAttribute('valid') !== 'false');

    userParticulars = this._formatUserParticulars(payload, userParticulars, { isResidentialAddressUpdated, isContactUpdated, isMailingAddressUpdated });

    this._payload = {
      myInfoParticulars: payload,
      mobileContactCountry: userParticulars.mobileContactCountry || null,
      officeContactCountry: userParticulars.officeContactCountry || null,
      homeContactCountry: userParticulars.homeContactCountry || null,
      fatcaCrsDueDCheck: userParticulars.fatcaCrsDueDCheck
    };

    this._updateButton.disabled = [this._userEmailAddress, this._userMobileNumber, this._userResidentialAddress].some(el => el.getAttribute('valid') !== 'false');
  }

  _formatUserParticulars(payload, userParticulars, config) {
    let { isResidentialAddressUpdated, isContactUpdated, isMailingAddressUpdated } = config;
    let countryFields = ['mailingAddressCountry', 'residentalCountry'];

    if (!isResidentialAddressUpdated && !isMailingAddressUpdated && !isContactUpdated) {
      userParticulars.fatcaCrsDueDCheck = null;
      return userParticulars;
    }

    let { hasAddressMismatch, hasIddMismatch, hasMismatch} = false;
    const declaredCountries = userParticulars.taxResidencyDetails.map((taxResidency) => taxResidency.country);

    if (isResidentialAddressUpdated || isMailingAddressUpdated) {
      const fieldName = isResidentialAddressUpdated ? 'residentialAddress' : 'mailingAddress';
      const selectedCountry = get(payload, `${fieldName}.country`);
      hasAddressMismatch = !declaredCountries.includes(selectedCountry);
    }

    if(!isResidentialAddressUpdated && !isMailingAddressUpdated) {
      hasAddressMismatch = hasAddressMismatch || _validateFatcaForAddress(countryFields);
    } else if (isResidentialAddressUpdated && !isMailingAddressUpdated) {
      countryFields = ['mailingAddressCountry'];
      hasAddressMismatch = hasAddressMismatch || _validateFatcaForAddress(countryFields);
    } else if (!isResidentialAddressUpdated && isMailingAddressUpdated) {
      countryFields = ['residentalCountry'];
      hasAddressMismatch = hasAddressMismatch || _validateFatcaForAddress(countryFields);
    }

    function _validateFatcaForAddress(countryFields=[]) {
      let fatcaAddressFlag = false;
      countryFields.forEach((countryField) => {
        if (userParticulars[countryField]) {
          let isDeclared = declaredCountries.includes(userParticulars[countryField]);
          if (!isDeclared) {
            fatcaAddressFlag = true;
          }
        }
      });

      return fatcaAddressFlag;
    };

    if (isContactUpdated) {
      const codeDetails = InternationalCallingCode.getCountryByDialCode(get(payload, 'mobileContact.mobileContactIddCode')) || {};
      hasIddMismatch = !declaredCountries.includes(codeDetails.countryCode);
      if (codeDetails.countryCode === 'SG' && declaredCountries.includes('SG')) {
        hasIddMismatch = false;
      }
    } else {
      const phoneFields = ['homeContactIddCode', 'mobileContactIddCode', 'officeContactIddCode'];
      for(var i=0; i <= phoneFields.length; i++) {
        const phoneField = phoneFields[i];
        if (userParticulars[phoneField]) {
          let codeDetails = InternationalCallingCode.getCountryByDialCode(userParticulars[phoneField]) || {};
          let isDeclared = declaredCountries.includes(codeDetails.countryCode);
          if (codeDetails.countryCode === 'SG' && declaredCountries.includes('SG')) {
            isDeclared = true;
            hasIddMismatch = false;
            break;
          }
          if (!isDeclared) {
            hasIddMismatch = true;
          }
        }
      }
    }

    hasMismatch = hasIddMismatch || hasAddressMismatch;
    userParticulars.fatcaCrsDueDCheck = hasMismatch ? '1' : '0';

    return userParticulars;
  }

  async _parseMyInfoParticulars(myinfoDataEncoded) {
    const myinfoData = jwt_decode(myinfoDataEncoded);
    const myinfoMobileNo = validateContact(myinfoData.mobileno);
    const emailAddress = validateEmail(myinfoData.email);
    const uinfin = validateUinfin(myinfoData.uinfin);
    const fullName = validateName(myinfoData.name);
    const dob = validateDob(myinfoData.dob, 'DD MMMM YYYY');
    this._userUinfin.setValue(uinfin.value);

    const mobileNumber = [`${myinfoMobileNo.prefix || ''}${myinfoMobileNo.code || ''}`, myinfoMobileNo.number].filter(Boolean).join(' ');

    this._mobileContact = {
      "mobileContactIddCode": myinfoMobileNo.code || null,
      "mobileContactNum": myinfoMobileNo.number || null
    }

    this._personalEmail = emailAddress.email;

    if (!this._fullList) {
      this._setElement(this._userMobileNumber, myinfoMobileNo.isValid, mobileNumber);
      this._setElement(this._userEmailAddress, emailAddress.isValid, emailAddress.email);
      this._fullNameInput.classList.add('sgx-hidden');
      this._genderInput.classList.add('sgx-hidden');
      this._countryOfBirthInput.classList.add('sgx-hidden');
      this._nationalityInput.classList.add('sgx-hidden');
      this._dateOfBirthInput.classList.add('sgx-hidden');
      this._userResidentialAddress.classList.add('sgx-hidden');
      this._userMailingAddress.classList.add('sgx-hidden');
      this._userResidentialStatus.classList.add('sgx-hidden');
      this._hanyupinyinName.classList.add('sgx-hidden');
      this._aliasName.classList.add('sgx-hidden');
      this._hanyupinyinAliasName.classList.add('sgx-hidden');
      this._marriedName.classList.add('sgx-hidden');
      this._form.querySelectorAll('div').forEach(div => {
        div.classList.remove('col-md-6', 'col-lg-6');
      })
      this._updateButton.disabled = [this._userEmailAddress, this._userMobileNumber].some(el => el.getAttribute('valid') === 'false');
      return;
    }

    this._countries = await this._getCountries();
    this._nationalities = await this._getNationalities();

    this._fullName = fullName.value;
    this._gender = get(myinfoData, 'sex.code');
    this._nationality = get(myinfoData, 'nationality.code');
    this._countryOfBirth = get(myinfoData, 'birthcountry.code');
    this._dateOfBirth = dob.value;
    this._residentialStatus = get(myinfoData, 'residentialstatus.code');

    const isValidGender = ['M','F'].includes(this._gender);

    this._setElement(this._fullNameInput, fullName.isValid, this._fullName);

    this._setElement(this._genderInput, isValidGender, isValidGender ? i18n.getTranslation(`app.widget-settings-user-general.inputs.gender.${this._gender}`) : '');
    this._setElement(this._nationalityInput, !!this._nationalities.get(this._nationality), this._nationalities.get(this._nationality));
    this._setElement(this._countryOfBirthInput, !!this._countries.get(this._countryOfBirth), this._countries.get(this._countryOfBirth));
    this._setElement(this._dateOfBirthInput, dob.isValid, dob.value.toUpperCase());

    this._setElement(this._userMobileNumber, myinfoMobileNo.isValid, mobileNumber);
    this._setElement(this._userEmailAddress, emailAddress.isValid, emailAddress.email);
    this._setResidentialAddress(myinfoData);

    //set Alias Values
    this._setAliasFields(myinfoData);

    this._updateButton.disabled = [this._userEmailAddress, this._userMobileNumber, this._userResidentialAddress,
      this._fullNameInput, this._dateOfBirthInput, this._genderInput, this._nationalityInput,
      this._countryOfBirthInput].some(el => el.getAttribute('valid') === 'false');
  }

  _setAliasFields(myinfoData){
    this._myInfo_hanyupinyinName = myinfoData.hanyupinyinname.value;
    this._myInfo_aliasName = myinfoData.aliasname.value;
    this._myInfo_hanyupinyinAliasName = myinfoData.hanyupinyinaliasname.value;
    this._myInfo_marriedName = myinfoData.marriedname.value;

    if (myinfoData.hanyupinyinname.value) {
      this._hanyupinyinName.setValue(`(${myinfoData.hanyupinyinname.value})`);
      this._hanyupinyinName.classList.remove('sgx-hidden');
      this._hanyupinyinName.classList.add('merge-fields');
      this._fullNameInput.classList.add('merge-fields');
    }
    if (myinfoData.aliasname.value) {
      this._aliasName.setValue(`@${myinfoData.aliasname.value}`);
      this._aliasName.classList.remove('sgx-hidden');
      this._aliasName.classList.add('merge-fields');
      this._fullNameInput.classList.add('merge-fields');
    }
    if (myinfoData.hanyupinyinaliasname.value) {
      this._hanyupinyinAliasName.setValue(`@${myinfoData.hanyupinyinaliasname.value}`);
      this._hanyupinyinAliasName.classList.remove('sgx-hidden');
      this._hanyupinyinAliasName.classList.add('merge-fields');
      this._fullNameInput.classList.add('merge-fields');
    }
    if (myinfoData.marriedname.value) {
      this._marriedName.setValue(`@${myinfoData.marriedname.value}`);
      this._marriedName.classList.remove('sgx-hidden');
      this._marriedName.classList.add('merge-fields');
      this._fullNameInput.classList.add('merge-fields');
    }

    //remove un-needed elems
    this._dialog.querySelectorAll('.name-fields sgx-input-text')
      .forEach(el => {
        if (Array.from(el.classList).includes('sgx-hidden')) {
          el.remove();
        }
      })
  }

  /**
   * Display the residential address for non-foreigner otherwise hide it
   * @param {Object} myinfoData myinfo encoded jwt data
   */
  _setResidentialAddress(myinfoData) {
      const regAddress = validateRegAddress(myinfoData.regadd, !myinfoData.regadd.country.code);
      const block = regAddress.block;
      const street = regAddress.street;
      const floorUnit = `${(regAddress.floor || '')} ${(regAddress.unit || '')}`.trim();
      const postal = regAddress.postal;
      const country = regAddress.country;
      const residentialAddress = [block, street, floorUnit, postal, country].filter(Boolean).join(', ');

      this._residentialAddress = {
        "addressLine1": regAddress.street,
        "addressLine2": regAddress.block,
        "addressLine3": regAddress.floor,
        "addressLine4": regAddress.unit,
        "country": regAddress.country,
        "postalCode": regAddress.postal
      }

      this._setElement(this._userResidentialAddress, regAddress.isValid, residentialAddress, regAddress.highlightError);
      this._setElement(this._userResidentialStatus, true, i18n.getTranslation('app.userDetailsMyinfo.prLabel'));

      let fnName = myinfoData.residentialstatus.desc === 'PR' ? 'remove' : 'add';
      this._userResidentialStatus.classList[fnName]('sgx-hidden');
  }

  _setElement(element, isValid, value, highlightError) {
    //element.setDisabled(!isValid);
    if (element.name !== 'mailingAddress') {
      element.setValue(value || '-');
    }

    if (highlightError) {
      element.setAttribute('valid', false);
      element.setAttribute('message', i18n.getTranslation(`app.userDetailsMyinfo.error.invalid`));
    }

    if (!isValid) {
      element.setAttribute('valid', false);
      element.setAttribute('message', i18n.getTranslation(`app.userDetailsMyinfo.error.invalid`));
      if (element.name === 'residentialAddress') {
        this._dialog.bodyElement.classList.add('active-indicator');
        this._dialog.bodyElement.scrollTop = 0;
        this._showDialogFooter(false);
        this._statusIndicator.show({
          status: 'error',
          description: i18n.getTranslation('app.userDetailsMyinfo.error.invalid_residential_address', {
            link: ConfigService.links.SGX_V2_UPDATE_PARTICULARS
          }),
          button: {
            text: i18n.getTranslation('app.userDetailsMyinfo.buttons.ok'),
            callback: () => {
              this.teardown();
            }
          }
        });
      }
      this._myinfoAlert.classList.remove('sgx-hidden');
      this._myinfoAlert.setData({
        status: 'alert',
        text: i18n.getTranslation('app.userDetailsMyinfo.alertInfo'),
        background: true
      });
    }
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this._updateButton[fnName]('click', this._updateParticularsWithMyInfoData);
    this._form[fnName]('change', this._onFormChange);
  }
}

customElements.define('widget-user-details-myinfo', withInitDOM(WidgetMyInfoDetails));
