import i18n from '@sgx/sgx-localisation-service';
import ConfigService from 'sgx-config-service';

import QR_IMAGE from 'assets/images/dashboard/wechat-qr-code.png';

const constants = {
  headerTitle: i18n.getTranslation('app.widget-social-links.headertitle'),
  links: [
    {
      url: ConfigService.links.SGX_V2_dashboard.SOCIAL_LINKS.FACEBOOK_URL,
      className: 'facebook',
    },
    {
      url: ConfigService.links.SGX_V2_dashboard.SOCIAL_LINKS.TWITTER_URL,
      className: 'twitter',
    },
    {
      url: ConfigService.links.SGX_V2_dashboard.SOCIAL_LINKS.LINKEDIN_URL,
      className: 'linkedin',
    },
    {
      url: ConfigService.links.SGX_V2_dashboard.SOCIAL_LINKS.TELEGRAM_URL,
      className: 'telegram',
    },
    {
      className: 'wechat',
    }
  ],
  viewMore: {
    text: i18n.getTranslation('app.widget-social-links.viewmore'),
    url: ConfigService.links.SGX_V2_dashboard.SOCIAL_LINKS.VIEWMORE_URL
  },
  dialog: {
    text: i18n.getTranslation('app.widget-social-links.dialogtitle'),
    img: {
      url: QR_IMAGE,
      alt: 'weChat QR Code',
    },
  },
};

export { constants };
