import i18n from 'sgx-localisation-service';

/**
 * Display / Hide status indicator
 * Provides a common functions for status indicator
 * Common functions like display error, information and hide status
 *
 */

/**
 * Display the loader spin 
 * @param {HTMLElement} element
 * @returns
 */

function displayLoader(element) {
  if (!element) return;

  hideStatusIndicator(element);
  element.show({
    status: 'loading',
    description: i18n.getTranslation('app.shared-text.status-indicator.loading.description'), 
  });
}

/**
 * Display the Error as 'Network Error'  
 * @param {HTMLElement} element
 * @returns
 */

function displayError(element) {
  if (!element) return;

  hideStatusIndicator(element);
  element.show({
    status: 'error',
    title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
    description: i18n.getTranslation('app.shared-text.status-indicator.error.description'),
  });
}

/**
 * Hide the status indicator  
 * @param {HTMLElement} element
 * @returns
 */

function hideStatusIndicator(element) {
  if (!element) return;

  element.hide();
}

/**
 * Display the 'No data to display' info  
 * @param {HTMLElement} element
 * @returns
 */

function displayNoData(element) {
  if (!element) return;

  hideStatusIndicator(element);
  element.show({
    status: 'neutral',
    description: i18n.getTranslation('app.shared-text.status-indicator.empty.description'),
  });
}

module.exports = {
  displayLoader,
  displayError,
  hideStatusIndicator,
  displayNoData,
};
