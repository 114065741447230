/* eslint-disable */
function sha256Hash(b){var d=[1116352408,1899447441,3049323471,3921009573,961987163,1508970993,2453635748,2870763221,3624381080,310598401,607225278,1426881987,1925078388,2162078206,2614888103,3248222580,3835390401,4022224774,264347078,604807628,770255983,1249150122,1555081692,1996064986,2554220882,2821834349,2952996808,3210313671,3336571891,3584528711,113926993,338241895,666307205,773529912,1294757372,1396182291,1695183700,1986661051,2177026350,2456956037,2730485921,2820302411,3259730800,3345764771,
3516065817,3600352804,4094571909,275423344,430227734,506948616,659060556,883997877,958139571,1322822218,1537002063,1747873779,1955562222,2024104815,2227730452,2361852424,2428436474,2756734187,3204031479,3329325298],h=[1779033703,3144134277,1013904242,2773480762,1359893119,2600822924,528734635,1541459225];b+=String.fromCharCode(128);for(var k=Math.ceil((b.length/4+2)/16),n=Array(k),m=0;m<k;m++){n[m]=Array(16);for(var p=0;16>p;p++)n[m][p]=b.charCodeAt(64*m+4*p)<<24|b.charCodeAt(64*m+4*p+1)<<16|b.charCodeAt(64*
m+4*p+2)<<8|b.charCodeAt(64*m+4*p+3)}n[k-1][14]=8*(b.length-1)/Math.pow(2,32);n[k-1][14]=Math.floor(n[k-1][14]);n[k-1][15]=8*(b.length-1)&4294967295;b=Array(64);for(var q,r,w,z,x,y,s,m=0;m<k;m++){for(var v=0;16>v;v++)b[v]=n[m][v];for(v=16;64>v;v++)b[v]=sigma1(b[v-2])+b[v-7]+sigma0(b[v-15])+b[v-16]&4294967295;p=h[0];q=h[1];r=h[2];w=h[3];z=h[4];x=h[5];y=h[6];s=h[7];for(v=0;64>v;v++){var A=s+Sigma1(z)+Ch(z,x,y)+d[v]+b[v],B=Sigma0(p)+Maj(p,q,r);s=y;y=x;x=z;z=w+A&4294967295;w=r;r=q;q=p;p=A+B&4294967295}h[0]=
h[0]+p&4294967295;h[1]=h[1]+q&4294967295;h[2]=h[2]+r&4294967295;h[3]=h[3]+w&4294967295;h[4]=h[4]+z&4294967295;h[5]=h[5]+x&4294967295;h[6]=h[6]+y&4294967295;h[7]=h[7]+s&4294967295}return h[0].toHexStr()+h[1].toHexStr()+h[2].toHexStr()+h[3].toHexStr()+h[4].toHexStr()+h[5].toHexStr()+h[6].toHexStr()+h[7].toHexStr()}function ROTR(b,d){return d>>>b|d<<32-b}function Sigma0(b){return ROTR(2,b)^ROTR(13,b)^ROTR(22,b)}function Sigma1(b){return ROTR(6,b)^ROTR(11,b)^ROTR(25,b)}
function sigma0(b){return ROTR(7,b)^ROTR(18,b)^b>>>3}function sigma1(b){return ROTR(17,b)^ROTR(19,b)^b>>>10}function Ch(b,d,h){return b&d^~b&h}function Maj(b,d,h){return b&d^b&h^d&h}Number.prototype.toHexStr=function(){for(var b="",d,h=7;0<=h;h--)d=this>>>4*h&15,b+=d.toString(16);return b};function Util(){}Util.parseBigInt=function(b,d){return new BigInteger(b,d)};Util.randomString=function(b){for(var d="",h=0,h=0;h<b;h++)d+=String.fromCharCode(Math.ceil(255*Math.random()));return d};
Util.randomBytes=function(b){for(var d=[],h=0,h=0;h<b;h++)d[h]=Math.ceil(255*Math.random());return d};Util.toHexString=function(b){for(var d="",h=0;h<b.length;h++){var k;"number"==typeof b[h]?k=b[h].toString(16):"string"==typeof b[h]&&(k=b.charCodeAt(h).toString(16));1==k.length&&(k="0"+k);d+=k}return d};Util.fromHexString=function(b){b=0==b.length%2?b:"0"+b;for(var d=b.length/2,h=[],k=0,n=0;k<d;k++,n++){var m=2*k;h[n]=parseInt("0x"+b.substring(m,m+2))}return h};
Util.fromHexToString=function(b){b=0==b.length%2?b:"0"+b;for(var d=b.length/2,h="",k=0;k<d;k++)var n=2*k,h=h+String.fromCharCode(parseInt("0x"+b.substring(n,n+2)));return h};Util.cByteArrayToNString=function(b){for(var d="",h=0;h<b.length;h++)d+=String.fromCharCode(b[h]);return d};Util.getByteArray=function(b){var a=[];for(var d=0;d<b.length;d++)a[d]=b.charCodeAt(d);return a};
Util.xorByteArray=function(b,d){if(b.length>d.length)throw"Invalid parameters.";for(var h=[],k=0;k<b.length;k++)h[k]=b[k]^d[k];return h};Util.stringToHex=function(b){for(var d="",h="0123456789abcdef".split(""),k=0;k<b.length;k++)d+=h[b.charCodeAt(k)>>4]+h[b.charCodeAt(k)&15];return d};

module.exports = {
  sha256Hash
};
