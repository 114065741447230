import { FetchUtils, URLUtils } from '@sgx/sgx-base-code';

import ConfigService from '@sgx/sgx-config-service';

// #region Dependencies

const fetchJSON = FetchUtils.fetchJSON;
const CIRCULARS_API_URL = ConfigService.endpoints.CIRCULARS_API_URL;
const CIRCULARS_API_METALIST_URL = `${ConfigService.endpoints.CIRCULARS_API_URL}/metalist`;
const FINANCIAL_REPORTS_API_URL = ConfigService.endpoints.FINANCIAL_REPORTS_API_URL;
const FINANCIAL_REPORTS_API_METALIST_URL = `${FINANCIAL_REPORTS_API_URL}/metalist`;

// #endregion

// #region Custom Element API

/**
 * Circular service retrieves financial reports and circulars
 * http://confluence.sgx.com/display/DIGITAL/Circulars+API+-+Specification+V1.0
 * @module sgx-circulars-service
 */

class CircularsService {

  // #region Public Methods

  /**
   * Fetches an object containing circulars based on params
   *
   * @param {Object} params an object containing parameters to include in the API search
   * @param {String} [params.companyname] name of company to filter circulars by
   * @param {String} [params.pageSize] size of each page of results
   * @param {String} [params.pagestart] page number to start the search results on
   * @param {Date} [params.documentdatestart] date to search from
   * @param {Date} [params.documentdateend] date to search to
   * @param {String} [params.prospectusType] type of circular to search for
   * @returns {Promise<Object>} returns an object containing circulars
   */
  getCirculars(params) {
    const url = _setQueryParams(CIRCULARS_API_URL, params || {});
    return fetchJSON(url).then(response => {
      response.data = _postProcessResponse(response.data || []);
      return response;
    });
  }

  /**
   * Fetches an object containing the metadata of the Circulars API
   *
   * @return {Promise<Object>} Resolves to a metadata object
   */
  getCircularsMetadata() {
    return fetchJSON(CIRCULARS_API_METALIST_URL).then(response => {
      return response.data || {};
    });
  }

  /**
   * Fetches an array containing all available Financial Reports objects
   *
   * @return {Promise<Array>} Resolves to a Financial Reports array
   */
  getFinancialReports() {
    const pageSize = 2000;
    const params = 'id,companyName,documentDate,securityName,title,url';
    return fetchJSON(URLUtils.setQueryParams(FINANCIAL_REPORTS_API_URL, { pagestart: 0, pagesize: pageSize, params })).then(response => {
      var meta = (response || {}).meta || {};
      if (typeof meta.totalItems === 'undefined' || meta.totalItems === 0) {
        return [];
      }
      const n = meta.totalPages - 1;
      const p = [];
      for (var i = 1; i <= n; i++) {
        p.push(fetchJSON(URLUtils.setQueryParams(FINANCIAL_REPORTS_API_URL, { pagestart: i, pagesize: pageSize, params })));
      }
      return Promise.all(p).then(data => {
        let results = [].concat.apply(response.data, data.map(item => {
          return item.data;
        }));

        // TODO: remove upon sgx-data-model fix
        results = results.map(r => {
          r.companyName = r.companyName.replace(/,/g, '');
          r.securityName = r.securityName.replace(/,/g, '');
          return r;
        });

        return results;
      });
    });
  }

  /**
   * Fetches an object containing the metadata of the Financial Reports
   *
   * @return {Promise<Object>} Resolves to a metadata object
   */
  getFinancialReportsMetadata() {
    return fetchJSON(FINANCIAL_REPORTS_API_METALIST_URL).then(response => {
      const results = response.data || {};

      // TODO: remove upon sgx-data-model fix
      const companies = results.companyName || [];
      const securities = results.securityName || [];

      results.companyName = companies.map(i => {
        return i.replace(/,/g, '');
      });

      results.securityName = securities.map(i => {
        return i.replace(/,/g, '');
      });

      return results;
    });
  }
}

// #endregion

// #region Private Methods

function _setQueryParams(url, opts) {
  const params = Object.assign({}, opts);

  if (opts.documentdatestart) {
    params.documentdatestart = opts.documentdatestart.format('YYYYMMDD_HHmmss');
  }

  if (opts.documentdateend) {
    params.documentdateend = opts.documentdateend.format('YYYYMMDD_HHmmss');
  }

  return URLUtils.setQueryParams(url, params);
}

function _postProcessResponse(data) {
  data.forEach(dataItem => {
    dataItem.documentUrl = `${ConfigService.endpoints.V1_PROSPECTUS_CIRCULARS_DATA_URL}/${dataItem.id}`;
  });
  return data;
}

// #endregion

// #region Singleton export

const circularsServiceInstance = new CircularsService();

export default circularsServiceInstance;

// #endregion