export const widgetConfig = {
  'options' : {
    'labels': 'app.widget-securities-market-performance.table-sort-options',
    'values': [
      'advancers_v',
      'advancers_vl',
      'advancers_c',
      'decliners_c'
    ]
  },
  'itemsToShow': 5,
  'tabs': [
    'stocks',
    'reits',
    'etfs',
    'structuredwarrants',
    'dlcertificates'
  ]
}
