module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-login-prompt-dashboard\">\n  <div class=\"cmp-login-prompt-dashboard-container\">\n    <h3 class=\"cmp-login-prompt-dashboard-text\"></h3>\n    <div class=\"cmp-login-prompt-dashboard-section sgx-base-text-body-16\">\n      <a type=\"button\" rel=\"external\" data-analytics-label=\"Portfolio (CDP Internet) Login button\" class=\"cmp-login-prompt-dashboard-button sgx-button--primary\" data-i18n=\"cmp-login-prompt-dashboard.button.login\"></a>\n      <a rel=\"external\" class=\"cmp-login-prompt-dashboard-cdp-account-opening-link\" data-i18n=\"cmp-login-prompt-dashboard.button.cdp-account-opening-link\"></a>\n      <a rel=\"external\" class=\"cmp-login-prompt-dashboard-token-management-link\" data-i18n=\"cmp-login-prompt-dashboard.button.getting-started-link\"></a>\n    </div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()