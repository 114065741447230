import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-collapsible-two-column.html';
import cloneDeep from 'lodash/cloneDeep';

/**
 * A row rendering two columns.
 * @module cmp-collapsible-two-column
 * @type {HTMLElement}
 */

class CmpCollapsibleTwoColumn extends HTMLElement {
  // #region Custom Element API

  constructor() {
    super();
    this._data = [];
  }

  initDOM() {
    const template = tmpl.getNode();
    this._columnNode = template.querySelector('.cmp-collapsible-two-column-col');
    this._tooltipNode = template.querySelector('sgx-tooltip');
    this.classList.add('cmp-collapsible-two-column');
  }

  // #endregion

  // #region Public Methods

  setData(data) {
    this.innerHTML = '';
    this._data = cloneDeep(data);
    this.appendChild(data.reduce((docfrag, item, _) => {
      docfrag.appendChild(this._createColumn(item));
      return docfrag;
    }, document.createDocumentFragment()));
  }

  getData() {
    return this._data;
  }

  // #endregion

  // #region Private Methods

  _createColumn({title, value, tooltip}) {
    const col = this._columnNode.cloneNode(true);
    const titleEl = col.querySelector('.cmp-collapsible-two-column-item-title');
    const titleContentEl = titleEl.querySelector('span');
    const valueEl = col.querySelector('.cmp-collapsible-two-column-item-value');
    titleContentEl.textContent = title;
    valueEl.textContent = value;

    if (tooltip) {
      const tooltipEl = this._tooltipNode.cloneNode(true);
      const tooltipContentEl = tooltipEl.querySelector('[data-sgx-tooltip-content]');
      tooltipContentEl.textContent = tooltip;
      titleContentEl.appendChild(tooltipEl);
    }
    return col;
  }
}

// #endregion

// #region Registeration of the Element

customElements.define('cmp-collapsible-two-column', withInitDOM(CmpCollapsibleTwoColumn));

// #endregion
