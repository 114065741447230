module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-language-switcher\">\n  <div class=\"cmp-sub-header--desktop-view sgx-hidden\">\n    <a class=\" sgx-icon--before sgx-base-text-title-24 cmp-sub-header--language-switcher-link\">\n      <span class=\"sgx-base-text-body-16\" data-i18n=\"app.lang-toggle\"></span>\n    </a>\n    <a class=\"sgx-icon--before cmp-sub-header--help-center-link\"><span data-i18n=\"app.help-center\"></span></a>\n    <a class=\"sgx-icon--before cmp-sub-header--profile-page-link\" auth=\"false\"><span data-i18n=\"app.profile-page\"></span></a>\n  </div>\n  <div class=\"cmp-sub-header--mobile-view sgx-hidden\">\n    <button class=\"sgx-button--low-emp sgx-icon cmp-sub-header--mobile-view--actions\"></button>\n    <div class=\"cmp-sub-header--mobile-view-sub-menu sgx-hidden\">\n      <a class=\"sgx-icon--before sgx-base-text-title-24 cmp-sub-header--mobile-view--language-switcher-link\">\n        <span class=\"sgx-base-text-body-16\" data-i18n=\"app.lang-toggle\"></span>\n      </a>\n      <a class=\"sgx-icon--before cmp-sub-header--mobile-view--help-center-link\"><span data-i18n=\"app.help-center\"></span></a>\n      <a class=\"sgx-icon--before cmp-sub-header--mobile-view-profile-page-link\" auth=\"false\"><span data-i18n=\"app.profile-page\"></span></a>\n    </div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()