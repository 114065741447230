export function addTINValidations(validators) {
  return Object.assign(validators, {
    malaysiaTINIf(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 *
			 * As per above requirement, the malaysia TIN number should prefix with 'SG' or 'OG' followed
			 * by 11 numerics for a individual account or same as the Malaysian NRIC. For non individual account
			 * it will differ, for now we are doing only for individual account.
			 */
      const isRegexMatch = /^(IG)\d{9}$/i.test(value) || /^(IG)\d{10}$/i.test(value) || /^(IG)\d{11}$/i.test(value) || /^\d{6}\-\d{2}\-\d{4}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    indiaTINif(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 *
			 * As per above requirement, the India TIN number should be length of 10characters.
			 * First 5 are alphabets followed by 4 numberic and by one alphabet.
			 */
      const isRegexMatch = (/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/.test(value));

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    UKTINif(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 *
			 * As per above requirement, the UK TIN number should be length of 10 numerals for UTR and
       * 9 characters for NINO which starts with 2 letters followed by 6 numerals and 1 letter.
			 */
      const isRegexMatch = (/^[0-9]{10}$|^[A-Za-z]{2}\d{6}[A-Da-d]$/.test(value));

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    AuTINif(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 *
			 * As per above requirement, the AU TIN number should be 8 or 9 numerics without space.
			 */
      const isRegexMatch = (/^[0-9]{8}$/.test(value) || /^[0-9]{9}$/.test(value));

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    NzTINif(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 *
			 * As per above requirement, the NZ TIN number should be 8 or 9 numerics without space.
			 */
      const isRegexMatch = (/^[0-9]{8}$/.test(value) || /^[0-9]{9}$/.test(value));

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    IdrTINif(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 *
			 * NPWP comprised by 15 numeric digits with the following details:
			 * the first 9 digits (1-9) are unique identity of tax payer
			 * the next 3 digits are the code for the tax office where the tax payer registered for the first time; and
			 * the last 3 digits are code for central or branch status
			 * Example TIN: 312666837412000 or 32.444.333.1-777.888
			 */
      const idrFormat = /^\d{16}$/;

      const isRegexMatch = idrFormat.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },

    ChTINif(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 *
			 * China have several formats, check the document for the same,
			 */
      const isRegexMatch = /^\d{18}$|^\d{17}[Xx]$|^[CWHMTcwhmt]\d{16}[0-9A-Za-z]$|^[J,j]\d{14}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    UsTINif(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 *
			 * SSN : xxx-xx-xxxx.
			 * The first 3 digits have geographical significance,
			 * the next 2 digits have no real significance and are issued sequentially,
			 * and the last four digits are random
			 *
			 * EIN: xx-xxxxxxx, xxxxxxxxx
			 *
			 * ITIN: begins with the number 9 and us in the following format: 9xx-xx-xxxx
			 *
			 * the first 9 digits (1-9) are unique identity of tax payer
			 * the next 3 digits are the code for the tax office where the tax payer registered for the first time; and
			 * the last 3 digits are code for central or branch status
			 * Example TIN: 333-12-1234 or 12-1234567 or 12-34-5678
			 */
      const isRegexMatch = /^\d{3}-\d{2}-\d{4}$|^\d{2}-\d{7}$|^[9][0-9]{2}\-[0-9]{2}\-[0-9]{4}$|^\d{9}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    hKTINif(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 *
			 * The standard format is @123456# where
			 * '@' represents any one or two capital letters of the alphabet.
			 * '#' is the check digit which has 11 possible values from 0 to 9 and A.
			 */
      const isRegexMatch = /^\d{10}$|^[A-Za-z]{2}\d{6}[0-9Aa]$|^[A-Za-z]{1}\d{6}[0-9Aa]$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    jpTINif(value, options) {
      let result = null;
      /**
			 * Refer: https://confluence.sgx.com/display/PMO/Improve+Account+Opening+and+Profile+Update+using+MyInfo#
			 * Individual number is 12-digit number comprised of numerals only.
			 */

      const isRegexMatch = /^\d{12}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    caTINif(value, options) {
      let result = null;

      const isRegexMatch = /^\d{9}$/.test(value) || /^[Tt]\d{8}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    ntTINif(value, options) {
      let result = null;

      const isRegexMatch = /^[0-9]{9}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    frTINif(value, options) {
      let result = null;

      const isRegexMatch = /^[0123]\d{12}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    krTINif(value, options) {
      let result = null;

      const isRegexMatch = /^\d{3}-\d{2}-\d{5}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    swissTINif(value, options) {
      let result = null;

      const isRegexMatch = /^[C|c][H|h][E|e]-\d{3}\.\d{3}\.\d{3}$|^756\.\d{4}\.\d{4}\.\d{2}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    deTINif(value, options) {
      let result = null;

      const isRegexMatch = /^[0-9]{11}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    twTINif(value, options) {
      let result = null;

      let isRegexMatch = /^[A-Za-z]\d{9}$|^[A-Za-z]{2}\d{8}$|^\d{8}$|^9\d{6}$|^\d{8}[A-Za-z]{2}$/.test(value);

      // Uncomment this if we have to validate against the actual dob in the future.

      /** const personalFieldSet = document.querySelector('.personal-details-fieldset');
      let dob = personalFieldSet.getDateOfBirth();
      if (dob) {
        const yearShort = DateService(dob).format('YY');
        const yearLong = dob.split('-')[0];
        const month = dob.split('-')[1];
        const date = dob.split('-')[2];
        const validDobShort = yearShort + month + date;
        const validDobLong = yearLong + month + date;
        isRegexMatch = isRegexMatch || (value === `9${validDobShort}`) || (value.substr(0, 8) === validDobLong);
      } **/

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    seTINif(value, options) {
      let result = null;

      const isRegexMatch = /^\d{6}-\d{4}$|^\d{6}\+\d{4}$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    otherTINif(value, options) {
      let result = null;
      /**
       * Don't allow space for other countries under myinfo.
       */
      const isRegexMatch = /^\S*$/.test(value);

      if (value && options.predicate.call(options.scope, options.index) && !isRegexMatch) {
        result = options.message.call(options.scope);
      }

      return result;
    },
    tinLengthIf(value, options) {
      if (options.predicate.call(options.scope, options.param) && value && value.length > 35) {
        return options.message.call(options.scope);
      }
      return;
    }
  });
}
