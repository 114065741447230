module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-portfolio-blb-toolbar\">\n  <div class=\"cmp-portfolio-blb-toolbar-column\">\n    <sgx-input-select label=\"\" class=\"cmp-portfolio-blb-toolbar cmp-portfolio-blb-toolbar-type-select sgx-table-toolbar-filter-item sgx-hidden\" data-i18n=\"[label]app.widget-securities-prices.table.rmk.label;\"></sgx-input-select>\n    <label class=\"cmp-portfolio-blb-toolbar-label\" data-i18n=\"cmp-portfolio-blb-toolbar.label.month\"></label>\n    <sgx-input-select class=\"cmp-portfolio-blb-toolbar cmp-portfolio-blb-toolbar-period-select sgx-table-toolbar-filter-item\" data-i18n=\"[label]app.widget-securities-prices.table.m.label;\"></sgx-input-select>\n  </div>\n  <div class=\"cmp-portfolio-blb-toolbar-column\">\n    <sgx-data-model-tool class=\"sgx-base-text-body-14 cmp-portfolio-blb-toolbar-print\" data-i18n=\"[label]app.icon.pdf\" icon=\"print\"></sgx-data-model-tool>\n    <sgx-data-model-tool class=\"sgx-base-text-body-14 cmp-portfolio-blb-toolbar-download\" data-i18n=\"[label]app.icon.csv\" icon=\"download\"></sgx-data-model-tool>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()