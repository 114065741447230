import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-tax-residency-fieldsets.html';
import { setFieldOptions, showHideEl } from 'utils/form-util';
import { addTINValidations } from 'utils/oecd-util';
import { fromEvent } from 'rxjs';
import i18n from '@sgx/sgx-localisation-service';
import {
  cloneDeep,
  some,
  get
} from 'lodash';
import jsonSchema from 'schemas/settings-user';
import referenceDataService from 'services/reference-data-service';


/**
 * Tax Residency Details Widget.
 */
class WidgetTaxResidencyFieldSets extends HTMLElement {
  constructor() {
    super();
    this._fetchCountryList = this._fetchCountryList.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-tax-residency-fieldsets');
    this._addBtn = this.querySelector('.widget-tax-residency-prompt-button');
    this._resetBtn = this.querySelector('.widget-tax-residency-reset-button');
    this._indicator = this.querySelector('.widget-tax-residency-fieldsets--indicator');
    this._fatcaWarning = this.querySelector('.widget-tax-residency-fieldsets--indicator-warning');
    this._pristineTaxDetails = {};
    this._footer = this.querySelector('.widget-tax-residency-fieldsets--footer');
    this._desc = this.querySelector('.widget-tax-residency-fieldsets--desc');

    this._indicator.setData({
      status: 'error',
      text: i18n.getTranslation('app.tax-residency-fieldsets.error')
    });

    this._fatcaWarning.setData({
      status: 'warning',
      text: i18n.getTranslation('app.tax-residency-fieldsets.warning')
    });
    this._fetchCountryList();
  }

  connectedCallback() {
    this._subscriptions = [];

    this._subscriptions.push(fromEvent(this._addBtn, 'click').subscribe(e => {
      e.stopImmediatePropagation();
      this._onAdd();
    }));

    this._subscriptions.push(fromEvent(this._resetBtn, 'click').subscribe(e => {
      e.stopImmediatePropagation();
      this.setData({
        taxResidencyDetails: this._pristineTaxDetails,
        fatcaCrsDueDCheck: this._pristineFatcaCrsCheck
      });
    }));
  }

  disconnectedCallback() {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * @method
   * @param {String} index
   *
   * This method is called when user removes one of the tax residency.
   * This method takes care of sorting logic and assign new index.
   */
  onclose(index) {
    let taxResidency = this.querySelector(`.widget-tax-residency[data-index='${index}']`);

    if (taxResidency && taxResidency.isReadOnly()) {
      showHideEl(this._resetBtn, true);
    }

    if (taxResidency) {
      showHideEl(taxResidency, false);
      taxResidency.setIndex(0);
      taxResidency.reset();
    }


    this._total = this.querySelectorAll('.widget-tax-residency:not(.sgx-hidden)').length;
    const hasError = (this._total === 0);

    //Keeping it inside if so warning is shown only when user has saved record and not during tin removal.
    if (hasError) {
      showHideEl(this._indicator, true);
      showHideEl(this._fatcaWarning, false);
    }

    if (this._total < 5) {
      this._addBtn.removeAttribute('disabled');
    }

    this._sortTaxResidencies();
    this.refreshCountryList();
    const settings = document.querySelector('template-settings-user');
    settings.dispatchEvent(new CustomEvent('re-validate', {
      bubbles: true
    }))
  }

  updateValidationConfig(validate, config, formRef) {
    this._form = formRef;
    const taxResidencies = this.querySelectorAll('.widget-tax-residency');
    taxResidencies.forEach((taxResidency) => {
      config = taxResidency.updateValidationConfig(validate, config, this._form);
    });
    this._form.validator.validate.validators = addTINValidations(this._form.validator.validate.validators);
    return config;
  }

  getAllTaxResidencies() {
    return this.querySelectorAll('widget-tax-residency') || [];
  }

  getAllActiveTaxResidencies() {
    return this.querySelectorAll('.widget-tax-residency:not(.sgx-hidden)') || [];
  }

  getData() {
    const taxResidencies = this.querySelectorAll('.widget-tax-residency:not(.sgx-hidden)');
    let taxResidencyDetails = [];
    taxResidencies.forEach((residency) => {
      taxResidencyDetails.push(residency.getData());
    });

    if (taxResidencyDetails.length === 1 && !taxResidencyDetails[0].country) {
      taxResidencyDetails = [];
    }

    return { taxResidencyDetails };
  }

  getPristineData() {
    return this._pristineTaxDetails;
  }

  hasError() {
    const { taxResidencyDetails } = this.getData();
    const hasOtherCountries = some(taxResidencyDetails, { 'country': 'OTHERS' });
    return !this._indicator.classList.contains('sgx-hidden') || hasOtherCountries;
  }

  setData(user={}) {
    const taxResidencyDetails = user.taxResidencyDetails;
    this._pristineTaxDetails = cloneDeep(taxResidencyDetails);
    this._pristineFatcaCrsCheck = user.fatcaCrsDueDCheck;
    if (taxResidencyDetails && taxResidencyDetails.length) {
      this._updateTaxResidency(taxResidencyDetails);
    }
    showHideEl(this._fatcaWarning, (user.fatcaCrsDueDCheck === "1"));
  }

  async _fetchCountryList() {
    this._countryList = await referenceDataService.getCountries();
  }

  refreshCountryList() {
    let widgets = this.querySelectorAll('widget-tax-residency');
    const countries = this._countryList;
    let countryInputOptions = [];
    const excludeOptions = [];
    for (let i = 0; i < widgets.length; i++) {
      const widget = widgets[i];
      const countryInput = widget.getCountryInput();
      const selections = countryInput.getSelectedOptions();
      if (selections.length > 0) {
        excludeOptions.push(selections[0].value);
      } else {
        excludeOptions.push('');
      }
    }
    for (var i = 0; i < widgets.length; i++) {
      const selectedWidget = widgets[i];

      if (!selectedWidget.isReadOnly()) {
        countryInputOptions = get(jsonSchema, 'definitions.taxResidencyCountry.enum');
      } else {
        countryInputOptions = (get(countries, 'data') || []).map((country) => {
          return { value: country.id, label: country.name };
        });
      }
      const selectedCountryInput = selectedWidget.getCountryInput();
      const currentValue = excludeOptions[i];
      const filteredCountryInputOptions = countryInputOptions.reduce((result, option) => {
        if (excludeOptions[i] === option || excludeOptions.indexOf(option) === -1) { // does not match an excluded value
          result.push(option);
        }
        return result;
      }, []);

      if (!selectedWidget.isReadOnly()) {
        setFieldOptions(selectedCountryInput, filteredCountryInputOptions, 'app.widget-tax-residency.countryInput.options', {
          selectFirst: false,
          silent: true
        });
      } else {
        selectedCountryInput.setOptions(filteredCountryInputOptions, false, true);
      }

      if (currentValue) {
        selectedCountryInput.setValue(currentValue);
      }
    }
  }

  setEditable(canEdit = true) {
    showHideEl(this._desc, canEdit);
    showHideEl(this._footer, canEdit);
    showHideEl(this._fatcaWarning, canEdit);
    this.updateWarning(!canEdit);

    if (!this._pristineTaxDetails || (this._pristineTaxDetails.length === 0)) {
      for (let i=1; i<=5 ; i++) {
        this.onclose(i);
      }
    }

    let taxResidencies = this.querySelectorAll('.widget-tax-residency');
    taxResidencies.forEach((taxResidency) => {
      taxResidency.setEditable(canEdit);
    });
  }

  updateWarning(inactive = false) {
    let errorMsg = i18n.getTranslation(`app.tax-residency-fieldsets.${inactive ? 'error_corp' : 'error'}`);
    this._indicator.setData({
      status: 'error',
      text: errorMsg
    });
  }

  _onAdd() {
    this._total = this.querySelectorAll('.widget-tax-residency:not(.sgx-hidden)').length;
    this._addBtn.removeAttribute('disabled');
    let newTaxResidency = this.querySelector('.widget-tax-residency.sgx-hidden');
    showHideEl(newTaxResidency, true);
    newTaxResidency.setIndex(this._total + 1);
    newTaxResidency.setReadOnly(false);
    showHideEl(this._indicator, false);
    showHideEl(this._fatcaWarning, (this._pristineFatcaCrsCheck === "1"));

    if (this._total === 4) {
      this._addBtn.setAttribute('disabled', true);
      this._total = 5;
    }
    this._sortTaxResidencies();
  }

  _sortTaxResidencies() {
    const taxResidencies = this.querySelectorAll('.widget-tax-residency:not(.sgx-hidden)');
    const wrapper = this.querySelector('.widget-tax-residency-fieldsets--wrapper');
    let taxDeclarations = [...taxResidencies];

    taxDeclarations.sort((a, b) => a.getIndex() - b.getIndex());

    taxDeclarations.forEach((node, index) => {
      node.setIndex(index + 1);
      wrapper.appendChild(node);
    });
  }

  _updateTaxResidency(taxResidencyDetails=[]) {
    this.querySelectorAll('.widget-tax-residency').forEach((taxResidency) => taxResidency._close.click());
    let taxResidencies = this.querySelectorAll('.widget-tax-residency');
    taxResidencyDetails.forEach((taxResidency, index) => {
      const taxResidencyField = taxResidencies[index];
      // index start from one for the translation and fields
      taxResidencyField.setIndex(index + 1);
      taxResidencyField.setData(taxResidency);
    });

    showHideEl(this._indicator, (taxResidencyDetails.length === 0));
    showHideEl(this._resetBtn, false);
  }
}

customElements.define('widget-tax-residency-fieldsets', withInitDOM(WidgetTaxResidencyFieldSets));
