import UserService from 'services/user-service';
import BankAccountService from 'services/bank-account-service';
import CcyService from 'services/ccy-service';
import DateService from 'sgx-date-time-service';
import StoreRegistry from 'stores/store-registry';
import get from 'lodash/get';

const { ACCOUNT_CATEGORY } = UserService.constants;

/**
 * Format an Account ID
 * @param {accountId} accountId to convert to representational format
 * @return {String} a string in the following format xxxx-xxxx-xxxx
 * */
function formatAccountId(accountId) {
  return accountId.match(/.{1,4}/g)
    .join('-');
}

/**
 * Get Account Category for render
 * @param {accountCategory} accountCategory to convert to representational format
 * @param {typeOfApproval} typeOfApproval to convert to representational format
 * @return {String} a string in the following format Individual Account
 * */
function getCategoryTranslationPath(accountCategory, typeOfApproval) {
  let categoryKey = `${accountCategory}-${typeOfApproval}`;
  if (accountCategory === ACCOUNT_CATEGORY.INDIVIDUAL || accountCategory === ACCOUNT_CATEGORY.CORPORATE) {
    categoryKey = accountCategory;
  }
  return `widget-account-select.category.${categoryKey}`;
}

/**
   * Organize the DCS accounts to display.
   * @param {Array} dcsAccounts
   */
function organizeDcsAccounts(dcsAccounts) {

  // If there's no DCS, return
  if (!dcsAccounts.length) {
    return dcsAccounts;
  }

  // If there's only one DCS, set the display status and return
  if (dcsAccounts.length === 1) {
    dcsAccounts[0].displayStatus = BankAccountService.getDisplayStatus(dcsAccounts[0].status);
    return dcsAccounts;
  }

  // Otherwise, organize display order to match requirements
  const { ACTIVE, PENDING_ACTIVATION, SUSPENDED, REJECTED, TERMINATED } = BankAccountService.constants.DISPLAY_ACCOUNT_STATUS;
  let activeAccounts = [];
  let pendingAccounts = [];
  let suspendedAccounts = [];

  for (let i = 0; i < dcsAccounts.length; i++) {
    const account = dcsAccounts[i];
    account.displayStatus = BankAccountService.getDisplayStatus(account.status);
    account.createdDateTimestamp = +DateService(account.createdDateTime).format('x');
    switch (account.displayStatus) {
      case ACTIVE:
        activeAccounts.push(account);
        break;
      case SUSPENDED:
        suspendedAccounts.push(account);
        break;
      case PENDING_ACTIVATION:
        pendingAccounts.push(account);
        break;
    }
  }

  if (activeAccounts.length) {
    return [...activeAccounts, ...pendingAccounts];
  }

  // As per UAT 15.12 - 9a, but subject to change in the future
  // https://confluence.sgx.com/pages/viewpage.action?pageId=117019577
  if (suspendedAccounts.length) {
    return [...suspendedAccounts, ...pendingAccounts];
  }

  const [mostRecentAccount, nextRecentAccount] = dcsAccounts.sort((a, b) => b.createdDateTimestamp - a.createdDateTimestamp).slice(0, 2);
  switch (mostRecentAccount.displayStatus) {
    case PENDING_ACTIVATION:
      return ((nextRecentAccount.displayStatus === REJECTED) || (nextRecentAccount.displayStatus === TERMINATED))
        ? [mostRecentAccount]
        : [nextRecentAccount, mostRecentAccount];
    case REJECTED:
    case TERMINATED:
      return ((nextRecentAccount.displayStatus === REJECTED) || (nextRecentAccount.displayStatus === TERMINATED))
        ? [mostRecentAccount]
        : [nextRecentAccount];
    default:
      return [mostRecentAccount, ...pendingAccounts];
  }
}

/**
 * Matches DCS accounts with CCY standing instructions
 * @param {Array} dcsAccounts
 * @param {Array} standingInstructions
 * @param {Boolean} updateStatus checks whether to update store
 */
function matchDcsCcy(dcsAccounts, standingInstructions, updateStatus = true) {

  // If there are no DCS accounts but there are standing instructions, return error state
  if (!dcsAccounts.length && standingInstructions.length) {
    // Update the state of CCY Widget with the updated state of standingInstructions[0], as this is already an error state
    // Assume there will be just 1 ccy if a scenario comes NO DCS, HAS CCY
    updateStatus && updateCCYSubscriptionStatus([{ standingInstruction: standingInstructions[0] }], standingInstructions);
    return [{ hasErrorState: true }];
  }

  // Match DCS accounts with CCY standing instructions and check for error states
  const organizedDcsAccounts = dcsAccounts.map(dcsAccount => {

    // Default error state to false
    dcsAccount.hasErrorState = false;

    // If there are standing instructions and they match a DCS, check for error state
    standingInstructions.forEach(standingInstruction => {
      const accountNumbersMatch = dcsAccount.accountNumber === standingInstruction.bankAccountNumber;
      const banksIdsMatch = dcsAccount.bankId === standingInstruction.bankId;
      if (accountNumbersMatch && banksIdsMatch) {
        standingInstruction.displayStatus = CcyService.getDisplayStatus(standingInstruction.status);
        standingInstruction.createdDateTimestamp = +DateService(standingInstruction.createdDateTime).format('x');

        // Show the most recent matching standing instruction
        let mostRecentStandingInstruction = standingInstruction;
        const previousStandingInstruction = dcsAccount.standingInstruction;
        if (previousStandingInstruction) {
          mostRecentStandingInstruction = [
            previousStandingInstruction,
            standingInstruction
          ].sort((a, b) => b.createdDateTimestamp - a.createdDateTimestamp)[0];
        }
        dcsAccount.standingInstruction = mostRecentStandingInstruction;
        dcsAccount.hasErrorState = checkErrorState(dcsAccount, standingInstruction);
      }
    });
    return dcsAccount;
  });

  updateStatus && updateCCYSubscriptionStatus(organizedDcsAccounts, standingInstructions);

  return organizedDcsAccounts;
}

/**
 * Update CCY subscription state basis DCS state and standingInstructions State
 * @param {Array} dcsAccounts dcsAccounts for display
 * @param {Array} standingInstructions associated standingInstructions for display
 */
function updateCCYSubscriptionStatus(dcsAccounts, standingInstructions) {
  const canCCYUpdate = canUpdateCcy(dcsAccounts, standingInstructions);
  const cdpSessionStore = StoreRegistry.cdpSession;

  cdpSessionStore.setData({
    ...cdpSessionStore.getData(),
    bankAccountNumber: get(dcsAccounts[0], 'accountNumber') || null,
    bankId: get(dcsAccounts[0], 'bankId') || null,
    status: get(dcsAccounts[0], 'standingInstruction.status') || 'UNSUBSCRIBED',
    canCCYUpdate
  });
}

function canUpdateCcy(dcsAccounts, standingInstructions) {
  const { PENDING_ACTIVATION, REJECTED, TERMINATED, SUSPENDED } = BankAccountService.constants.DISPLAY_ACCOUNT_STATUS;
  const CCY_STATUS = CcyService.constants.CCY_DISPLAY_INSTRUCTION_STATUS;

  const dcsActive = !dcsAccounts.some(dcsAccount => [PENDING_ACTIVATION, REJECTED, TERMINATED, SUSPENDED].indexOf(dcsAccount.displayStatus) > -1);

  const ccyActive = !standingInstructions.some(standingInstruction => [CCY_STATUS.PENDING_ACTIVATION, CCY_STATUS.PENDING_TERMINATION].indexOf(standingInstruction.displayStatus) > -1);;

  return dcsAccounts.length && dcsAccounts[0].bankId && dcsActive && ccyActive;
}

/**
 * Check if DCS account and CCY standing instruction exist in an impossible state
 * @param {Object} dcs the dcs account
 * @param {Object} ccy the standing instruction associated with the dcs account
 */
function checkErrorState(dcs, ccy) {
  const { TERMINATED } = BankAccountService.constants.DISPLAY_ACCOUNT_STATUS;
  const { ACTIVE, PENDING_ACTIVATION, PENDING_TERMINATION, SUSPENDED } = CcyService.constants.CCY_DISPLAY_INSTRUCTION_STATUS;

  if (dcs.displayStatus !== TERMINATED) {
    return false;
  }

  // If there is a terminated DCS and an active/pending/suspended CCY, return error state
  return dcs.displayStatus === TERMINATED && !!~[ACTIVE, PENDING_ACTIVATION, PENDING_TERMINATION, SUSPENDED].indexOf(ccy.displayStatus);
}

/**
 * Get the organized & matched dcs-ccy data
 * @param {Array} dcsAccounts
 * @returns {Promise<Object>} return object that contains dcsAccounts and canCCYUpdate properties
 */
function getDcsCcyData(accountId) {
  return Promise.all([
    BankAccountService.getBankAccounts(accountId),
    CcyService.getStandingInstructions(accountId)
  ])
    .then(([dcsAccounts, standingInstructions]) => {
      const organizedDcsAccounts = matchDcsCcy(
        organizeDcsAccounts(dcsAccounts),
        standingInstructions,
        false
      );
      const canCCYUpdate = !dcsAccounts.length && standingInstructions.length ? false : canUpdateCcy(organizedDcsAccounts, standingInstructions);
      return {
        dcsAccounts: organizedDcsAccounts,
        canCCYUpdate
      }
    });
}

export {
  formatAccountId,
  getCategoryTranslationPath,
  matchDcsCcy,
  organizeDcsAccounts,
  getDcsCcyData
};
