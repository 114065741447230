import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-security-details-information.html';
import ConfigService from 'sgx-config-service';
import DateService from 'sgx-date-time-service';
import SecurityDetailsAggregator from 'aggregators/security-details-aggregator/security-details-aggregator';
import i18n from 'sgx-localisation-service';
import { formatThousands } from 'utils/price-util';

const corpInfoBaseLink = ConfigService.links.CORPORATE_INFORMATION_BASE;

class SecurityDetailsInformationWidget extends HTMLElement {
  constructor() {
    super();
    this._onSummaryToggle = this._onSummaryToggle.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-information');

    // Fallback iframe references
    this._indicator = this.querySelector('sgx-status-indicator');
    this._iframe = this.querySelector('.widget-security-details-information-iframe');

    // Stockfacts references
    this._general = this.querySelector('.widget-security-details-information-general');
    this._summary = this.querySelector('.widget-security-details-information-summary');
    this._mobileSummary = this.querySelector('.widget-security-details-information-mobile-summary');
    this._summaryToggle = this.querySelector('.widget-security-details-information-summary-toggle');
    this._contact = this.querySelector('.widget-security-details-information-details-contact');
    this._email = this.querySelector('.widget-security-details-information-details-email');
    this._website = this.querySelector('.widget-security-details-information-details-website');
    this._fiscalYear = this.querySelector('.widget-security-details-information-details-fiscal-year');
    this._publicSince = this.querySelector('.widget-security-details-information-details-public-since');
    this._inc = this.querySelector('.widget-security-details-information-details-inc');
    this._employees = this.querySelector('.widget-security-details-information-details-employees');
    this._news = this.querySelector('.widget-security-details-information-news');
    this._accordion = this.querySelector('.widget-security-details-information-news-accordion');
    this._accordionLatestUpdates = this.querySelector('.widget-security-details-information-news-latest-updates');
    this._footer = this.querySelector('cmp-stocks-terms');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
    this._iframe.removeEventListener('load', this._onIframeLoadCallback);
    this._summaryToggle.removeEventListener('click', this._onSummaryToggle);
  }

  setData(data) {
    if (data.isStockfacts) {
      if (!data || !data.stockData) {
        this._indicator.show({
          status: 'neutral',
          title: i18n.getTranslation('app.shared-text.status-indicator.empty.description')
        });
        return;
      }
      this._renderCompanyInfo(data);
      this._renderNews(data);
      this._footer.classList.remove('sgx-hidden');
      this._indicator.hide();
    } else { // Not a stock, reit, or business trust, so fallback to iframe

      this._onIframeLoadCallback = this._onIframeLoadCallback(this);
      this._iframe.addEventListener('load', this._onIframeLoadCallback);

      if (data.nc && this._companyName !== data.nc) { // refresh company information if id is different
        this._companyName = data.nc;
        this._indicator.show({ status: 'loading' });
        SecurityDetailsAggregator.getCorporateInformationIdFromCode(data.nc).then(id => {
          this._indicator.hide();
          if (!id || id === 'NOT_FOUND') {
            this._indicator.show({
              status: 'neutral',
              title: i18n.getTranslation('app.shared-text.status-indicator.empty.description')
            });
          } else {
            this._iframe.src = `${corpInfoBaseLink + id}/`;
          }
        })
          .catch(_ => this._indicator.show({
            status: 'error',
            title: i18n.getTranslation('app.shared-text.status-indicator.error.title'),
            description: i18n.getTranslation('app.shared-text.status-indicator.error.description')
          }));
      }
    }
  }

  _renderCompanyInfo(data) {
    // Parse Stockfacts data
    const {
      registeredOffice1,
      registeredOffice2,
      registeredOffice3,
      registeredOffice4,
      webAddress,
      incorporatedOn,
      countryCode,
      background
    } = (((data || {}).stockData || {}).corporateInfo || {});

    const generalInfo = (((data || {}).stockData || {}).generalInfo || {});
    const {
      contact,
      publicDate,
      employees,
      incorporated
    } = generalInfo;

    const useFullDate = !!incorporatedOn;
    let {summary} = generalInfo;
    let {
      address1,
      address2,
      address3,
      links
    } = contact || {};

    let {currentFiscalYear} = ((((data || {}).stockData || {}).snapshotReport || {}).forecast || {});
    let {email, website} = links;

    summary = i18n.getLanguage() !== 'zh-cn' ? (background || summary) : summary;
    const publicText = publicDate ? publicDate.format(i18n.getTranslation('app.securities.format.date.default')) : '-';
    const incInfo = incorporated || {};
    const incDate = useFullDate ? DateService(incorporatedOn, 'YYYYMMDD').format(i18n.getTranslation('app.format.date.default')) : incInfo.year;
    const incCountry = (countryCode || incInfo.country || '').trim();
    const incText = incDate && incCountry
      ? i18n.getTranslation(`app.security-details.information.inc-${useFullDate ? 'on': 'in'}-text`, {incCountry, incDate})
      : '-';
    const employeesDate = (employees || {}).lastUpdated;
    const employeesDateFormatted = employeesDate ? employeesDate.format(i18n.getTranslation('app.securities.format.date.default')) : null;
    const employeesCount = (employees || {}).number;
    const employeesText = employeesDateFormatted && employeesCount ?
      i18n.getTranslation('app.security-details.information.employees-text', {
        employeesDate: employeesDateFormatted,
        employeesCount: formatThousands(employeesCount, 0, true)
      }) : '-';

    // Fill DOM
    this._iframe.classList.add('sgx-hidden');
    this._general.classList.remove('sgx-hidden');
    if (summary) {
      if (summary.length > 500) {
        this._fullSummary = summary;
        this._slicedSummary = summary.slice(0, 500).concat('...');
        this._mobileSummary.textContent = this._slicedSummary;
        this._summaryToggle.addEventListener('click', this._onSummaryToggle);
      } else {
        this._mobileSummary.textContent = summary;
        this._summaryToggle.style.display = 'none';
      }
      // innerHTML to convert HTML entities (e.g. &ldquo;)
      this._summary.innerHTML = summary;
    }

    const contactObject = {
      address1: registeredOffice1 ? registeredOffice1.trim() : address1 || null,
      address2: registeredOffice2 ? registeredOffice2.trim() : address2 || null,
      address3: registeredOffice3 ? registeredOffice3.trim() : address3 || null,
      address4: registeredOffice4 ? registeredOffice4.trim() : null,
      website: webAddress ?  webAddress.trim() : website || null
    }

    const noContactData = !Object.values(contactObject).some(contact => contact !== null);

    let contactText = !noContactData ? `
        ${contactObject.address1 ? `${contactObject.address1}<br />` : ''}
        ${contactObject.address2 ? `${contactObject.address2}<br />` : ''}
        ${contactObject.address3 ? `${contactObject.address3}<br />` : ''}
        ${contactObject.address4 ? `${contactObject.address4}<br />` : ''}
    ` : '-';

    this._contact.innerHTML = (contactText !== '' ? contactText : '-');

    if (email) {
      this._email.classList.remove('sgx-hidden');
      this._email.href = `mailto:${email}`;
      this._email.textContent = email;
    }
    if (contactObject.website) {
      this._website.classList.remove('sgx-hidden');
      this._website.href = contactObject.website;
      this._website.textContent = contactObject.website;
    }
    this._fiscalYear.setValue(currentFiscalYear ? currentFiscalYear : '-');
    this._publicSince.setValue(publicText);
    this._inc.setValue(incText);
    this._employees.setValue(employeesText);
  }

  _renderNews(data) {
    let newsItems = (((data || {}).stockData || {}).newsItems || []);

    if (!newsItems || !newsItems.length) {
      return;
    }

    const accordionData = newsItems.map(item => {
      let {description, headline, lastUpdated, topics} = (item || {});

      lastUpdated = lastUpdated ? lastUpdated.format(i18n.getTranslation('app.article.list.date-format')) : '';

      let categories = '';
      topics && topics.length && topics.forEach(topic => {
        categories += `<div class="widget-security-details-information-news-category sgx-base-text-helper-10">${topic}</div>`;
      })

      const titleHTML = `
        <div class="widget-security-details-information-news-section">
          <div class="widget-security-details-information-news-section-title sgx-base-text-title-14">${headline}</div>
          <div class="widget-security-details-information-news-section-info">
            <div class="widget-security-details-information-news-date sgx-base-text-helper-10">${lastUpdated}</div>
            <div class="widget-security-details-information-news-categories">
              ${categories}
            </div>
          </div>
        </div>
      `;

      const body = document.createElement('div');
      body.classList.add('widget-security-details-information-news-description', 'sgx-base-text-body-14');
      body.textContent = description;
      return {title: headline, titleHTML, children: [body]}
    })

    this._accordion.setData(accordionData, 'cmp-accordion-item', false);

    this._accordionLatestUpdates.textContent = i18n.getTranslation('app.article.results.updates-count_interval', {
      postProcess: 'interval',
      count: newsItems.length
    });

    this._news.classList.remove('sgx-hidden');
  }

  _onIframeLoadCallback() {
    setTimeout(() => this._indicator.hide());
  }

  _onSummaryToggle() {
    this._mobileSummary.classList.toggle('widget-security-details-information-mobile-summary--read-more');
    const showingAll = this._mobileSummary.classList.contains('widget-security-details-information-mobile-summary--read-more');
    this._mobileSummary.innerHTML = showingAll ? this._fullSummary : this._slicedSummary;
    this._summaryToggle.innerHTML = i18n.getTranslation(`app.security-details.information.read-${showingAll ? 'less' : 'more'}`);
  }
}

customElements.define('widget-security-details-information', withInitDOM(SecurityDetailsInformationWidget));
