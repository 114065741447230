module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-settings-account-actions\">\n  <div class=\"widget-settings-account-actions-container widget-user-container\">\n    <div class=\"widget-user-row--top\">\n      <widget-account-select inline class=\"settings-account-select\" store-name=\"settingsAccount\"\n                             used-in=\"dcs\"\n                             data-i18n=\"[label]app.widget-settings-account-actions.label\"></widget-account-select>\n    </div>\n    <div class=\"widget-user-row--bottom\">\n      <div class=\"widget-user-status-container\">\n        <p class=\"widget-user-status sgx-base-text-body-14\"></p>\n        <span class=\"widget-user-icon sgx-icon\"></span>\n      </div>\n    </div>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()