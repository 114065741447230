import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-list-row-account-state.html';

class CmpListRowAccountState extends HTMLElement {
  connectedCallback() {}

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-account-state');
    this._title = this.querySelector('.cmp-list-row-account-state-title');
    this._status = this.querySelector('.cmp-list-row-account-state-status-tag');
    this._date = this.querySelector('.cmp-list-row-account-state-status-date');
    this._button = this.querySelector('.cmp-list-row-account-state-button');
  }

  disconnectedCallback() {
    if (this._onButtonClick) {
      this._button.removeEventListener('click', this._onButtonClick);
    }
  }

  setData({ title = '', status = {}, date = '', button } = {}) {
    if (title) {
      this._title.textContent = title;
    }

    this._status.setData(status);
    
    if (date) {
      this._date.textContent = date;
    }

    if (button && button.callback && typeof button.callback === 'function') {
      const { label = '', style = 'secondary', callback } = button;
      this._button.classList.remove('cmp-list-row-account-state--hidden');
      this._button.textContent = label;
      this._button.classList.add(`sgx-button--${style}`)
      this._onButtonClick = callback;
      this._button.addEventListener('click', this._onButtonClick);
    }
  }
}

customElements.define('cmp-list-row-account-state', withInitDOM(CmpListRowAccountState));