import {withInitDOM} from 'sgx-base-code';
import tmpl from './cmp-list-row-ca-standing-instruction.html';
import i18n from 'sgx-localisation-service';

class CmpListRowCaStandingInstructions extends HTMLElement {

  connectedCallback() {
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-ca-standing-instruction');
    this._title = this.querySelector('.cmp-list-row-ca-standing-instruction-title');
    this._subTitle = this.querySelector('.cmp-list-row-ca-standing-instruction-subtitle');
    this._status = this.querySelector('.cmp-list-row-ca-standing-instruction-status-tag');
    this._button = this.querySelector('.cmp-list-row-ca-standing-instruction-button');
    this._date = this.querySelector('.cmp-list-row-ca-standing-instruction-status-date');
    this._alertBar = this.querySelector('sgx-alert-bar');
  }

  disconnectedCallback() {
    if (this._onButtonClick) {
      this._button.removeEventListener('click', this._onButtonClick);
    }
  }

  setData({
            securityName = '',
            status = {},
            date = '',
            button,
            hideButton,
            unAvailable,
            disableCancelButton
          } = {}) {
    if (securityName) {
      this._title.textContent = securityName;
    }

    this._status.setData(status);

    if (date) {
      this._date.textContent = date;
    }

    if (hideButton) {
      return;
    }

    if (button && button.callback && typeof button.callback === 'function') {
      const {label = '', style = 'secondary', callback} = button;
      this._button.classList.remove('cmp-list-row-ca-standing-instruction--hidden');
      this._button.textContent = label;
      this._button.classList.add(`sgx-button--${style}`)
      this._onButtonClick = callback;
      this._button.addEventListener('click', this._onButtonClick);
    }

    this._disableStandingInstruction({disableCancelButton, unAvailable});

  }

  /**
   * @desc set disable state for standing-instructions
   * */
  _disableStandingInstruction({disableCancelButton, unAvailable}) {

    if (!disableCancelButton && !unAvailable) {
      return;
    }

    this._button.setAttribute('disabled', 'disabled');

    if (unAvailable) {
      this._alertBar.setData({
        status: 'alert',
        text: i18n.getTranslation('cmp-list-row-ca-standing-instruction.status-indicator.unavailable'),
        background: false
      })
    }
  }

}

customElements.define('cmp-list-row-ca-standing-instruction', withInitDOM(CmpListRowCaStandingInstructions));
