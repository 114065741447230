import { withInitDOM } from 'sgx-base-code';
import template from './sgx-slides.html';
import DeviceService from '@sgx/sgx-device-service';

const mediaBreakpointDownXS = () => {
  if (typeof matchMedia !== 'function') {
    return false; // ignore old browsers
  }

  return matchMedia('(max-width: 767px)').matches;
};

export default class SgxSlides extends HTMLElement {
  constructor() {
    super();

    this._currentSlidePosition = 0;
    const isPhoneScreen = mediaBreakpointDownXS();
    this._isMobile = !DeviceService.isDesktop();
    this._config = this._defaultConfig = {
      slidesPerPage: isPhoneScreen ? 1 : 4,
      slideWidthPercent: isPhoneScreen ? 0.8 : 0.25,
      mobile: {
        slidesPerPage: 1,
        slideWidthPercent: 0.8
      },
      slideMarginPx: 24
    };
    this._initCurrentMove();

    this._gotoNextPage = this._gotoNextPage.bind(this);
    this._gotoPrevPage = this._gotoPrevPage.bind(this);
    this._touchStartHandler = this._touchStartHandler.bind(this);
    this._touchMoveHandler = this._touchMoveHandler.bind(this);
    this._touchEndHandler = this._touchEndHandler.bind(this);
  }

  initDOM() {
    this.appendChild(template.getNode());
    this._slidesContainer = this.querySelector('.slides-container');
    this._nextIcon = this.querySelector('.next-slide-icon');
    this._nextOverlay = this.querySelector('.next-slide-overlay');
    this._prevIcon = this.querySelector('.prev-slide-icon');
    this._prevOverlay = this.querySelector('.prev-slide-overlay');
    this._setListeners(true);
  }

  setData(slides) {
    this._totalSlidesCount = slides.length;
    this._slidesContainer.innerHTML = slides.join('');

    this._setPageIndex(0);
    this._setTouchEvents(true);
  }

  setConfig(config) {
    this._config = Object.assign({}, this._defaultConfig, config || {});
  }

  _setPageIndex(pageIndex) {
    this._pageIndex = pageIndex;
    if (this._isMobile) {
      return;
    }

    if ((this._pageIndex + 1) * this.slidesPerPage < this._totalSlidesCount) {
      this._nextIcon.classList.remove('hidden');
      this._nextOverlay.classList.remove('hidden');
    } else {
      this._nextIcon.classList.add('hidden');
      this._nextOverlay.classList.add('hidden');
    }

    if (this._pageIndex > 0) {
      this._prevIcon.classList.remove('hidden');
      this._prevOverlay.classList.remove('hidden');
    } else {
      this._prevIcon.classList.add('hidden');
      this._prevOverlay.classList.add('hidden');
    }
  }

  get slidesPerPage() {
    return this._config.slidesPerPage;
  }

  get slideWidthPercent() {
    return this._config.slideWidthPercent;
  }

  get slideMarginPx() {
    return this._config.slideMarginPx;
  }

  disconnectedCallback() {
    this._setListeners(false);
    this._setTouchEvents(false);
  }

  _setListeners(enable) {
    const action = enable ? 'addEventListener' : 'removeEventListener';
    this._nextIcon[action]('click', this._gotoNextPage);
    this._nextOverlay[action]('click', this._gotoNextPage);
    this._prevIcon[action]('click', this._gotoPrevPage);
    this._prevOverlay[action]('click', this._gotoPrevPage);
  }

  _gotoNextPage() {
    this._gotoPage(this._pageIndex + 1);
  }

  _gotoPrevPage() {
    this._gotoPage(this._pageIndex - 1);
  }

  _gotoPage(pageIndex) {
    let newPageIndex = pageIndex;
    if (newPageIndex > Math.ceil(this._totalSlidesCount / this.slidesPerPage) - 1) {
      newPageIndex = Math.ceil(this._totalSlidesCount / this.slidesPerPage) - 1;
    }

    if (newPageIndex < 0) {
      newPageIndex = 0;
    }

    this._setPageIndex(newPageIndex);
    this._currentSlidePosition = -(this.offsetWidth * this.slideWidthPercent + this.slideMarginPx)
      * newPageIndex * this.slidesPerPage;
    this._slidesContainer.style.transform = `translateX(${this._currentSlidePosition}px)`;
  }

  _initCurrentMove() {
    this._currentMove = {
      coords: [],
      isTouching: false
    };
  }

  _setTouchEvents(enable) {
    if (!this._isMobile || this._totalSlidesCount <= 1) {
      return;
    }

    if (enable) {
      this._slidesContainer.addEventListener('touchstart', this._touchStartHandler, { passive: true });
      this._slidesContainer.addEventListener('touchmove', this._touchMoveHandler);
      this._slidesContainer.addEventListener('touchend', this._touchEndHandler);
      this._slidesContainer.addEventListener('touchcancel', this._touchEndHandler);
    } else {
      this._slidesContainer.removeEventListener('touchstart', this._touchStartHandler);
      this._slidesContainer.removeEventListener('touchmove', this._touchMoveHandler);
      this._slidesContainer.removeEventListener('touchend', this._touchEndHandler);
      this._slidesContainer.removeEventListener('touchcancel', this._touchEndHandler);
    }
  }

  _touchStartHandler(e) {
    this._currentMove.isTouching = true;
    this._currentMove.coords.push({
      x: e.clientX ? e.clientX : e.touches[0].clientX,
      y: e.clientY ? e.clientY : e.touches[0].clientY
    });
    this._slidesContainer.style.transition = 'none';
  }

  _touchMoveHandler(e) {
    if (!this._currentMove.isTouching) {
      return;
    }

    const startingCoords = this._currentMove.coords[0];
    const currentCoords = {
      x: e.clientX ? e.clientX : e.touches[0].clientX,
      y: e.clientY ? e.clientY : e.touches[0].clientY
    };
    this._currentMove.coords.push(currentCoords);
    const xDiff = startingCoords.x - currentCoords.x;
    const offset = this._currentSlidePosition - xDiff;
    this._slidesContainer.style.transform = `translateX(${offset}px)`;
  }

  _touchEndHandler(e) {
    if (!this._currentMove.isTouching) {
      return;
    }

    const startingCoords = this._currentMove.coords[0];
    const finishingCoords = this._currentMove.coords[this._currentMove.coords.length - 1];
    const xDiff = startingCoords.x - finishingCoords.x;
    const xDiffAbs = Math.abs(xDiff);
    const shouldSlide = 0.1 * this.offsetWidth < xDiffAbs;
    const currentIndex = this._pageIndex;
    let nextIndex = currentIndex;

    if (shouldSlide) {
      // If negative diff, slide to the left. Otherwise slide to the right
      nextIndex = (xDiff < 0) ? currentIndex - 1 : currentIndex + 1;
    }

    this._initCurrentMove();
    this._slidesContainer.style.transition = 'transform 0.3s ease';
    this._gotoPage(nextIndex);
  }
}

customElements.define('sgx-slides', withInitDOM(SgxSlides));
