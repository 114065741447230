import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-portfolio-blb-toolbar.html';

class CmpPortfolioBlbToolbar extends HTMLElement {
  constructor() {
    super();
    this._type = null;
    this._period = null;
    this._onDownloadClick = this._onDownloadClick.bind(this);
    this._onPrintClick = this._onPrintClick.bind(this);
    this._onTableDataModelUpdate = this._onTableDataModelUpdate.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-portfolio-blb-toolbar');
    this._typeSelect = this.querySelector('.cmp-portfolio-blb-toolbar-type-select');
    this._periodSelect = this.querySelector('.cmp-portfolio-blb-toolbar-period-select');
    this._print = this.querySelector('.cmp-portfolio-blb-toolbar-print');
    this._download = this.querySelector('.cmp-portfolio-blb-toolbar-download');
    this._table = this.closest('sgx-table');

    this._print.setDisabled(true);
    this._download.setDisabled(true);
  }

  connectedCallback() {
    this._setupListener(true);
  }

  disconnectedCallback() {
    this._setupListener(false);
  }

  setData(data) {
    // balance type is currently optional as blb is not part of the current release
    this._typeSelect.classList.add('sgx-hidden');
    if (data.balanceType) {
      this._typeSelect.setOptions(data.balanceType);
      this._typeSelect.classList.remove('sgx-hidden');
    }

    this._periodSelect.setOptions(data.period);
    this._periodSelect.classList.remove('sgx-hidden');
  }

  _setupListener(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this[fnName]('click', this._onClick);
    this[fnName]('change', this._onChange);
    this._download[fnName]('click', this._onDownloadClick);
    this._print[fnName]('click', this._onPrintClick);
    this._table.whenConfigured().then(() => {
      this._table.getDataModel()[fnName]('update', this._onTableDataModelUpdate);
    });
  }

  _onChange(evt) {
    const type = this._typeSelect.getValue();
    const period = this._periodSelect.getValue();
    if (!period || this._period === period) {
      return;
    }
    this._type = type;
    this._period = period;
    this.dispatchEvent(new CustomEvent('portfolio-toolbar-change', {
      detail: {
        type,
        period
      },
      bubbles: true
    }));
  }

  _onDownloadClick(_) {
    this.dispatchEvent(new CustomEvent('portfolio-toolbar-download', {
      bubbles: true
    }));
  }

  _onPrintClick(_) {
    this.dispatchEvent(new CustomEvent('portfolio-toolbar-print', {
      bubbles: true
    }));
  }

  _onTableDataModelUpdate(_) {
    const disabled = this._table.getDataModel().getFilteredRowCount() === 0;
    this._print.setDisabled(disabled);
    this._download.setDisabled(disabled);
  }

  _onClick(evt) {
    // QUICKFIX - prevent click events from triggering internal sgx-table actions
    evt.stopImmediatePropagation();
  }
}

customElements.define('cmp-portfolio-blb-toolbar', withInitDOM(CmpPortfolioBlbToolbar));
