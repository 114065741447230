const CCY_PTS_INSTRUCTION_STATUS = {
  SUBSCRIBED: 'SUBSCRIBED',
  VERIFIED: 'VERIFIED',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  PENDING_SUBMISSION: 'PENDING_SUBMISSION',
  PENDING_EXTERNAL_VERIFICATION: 'PENDING_EXTERNAL_VERIFICATION',
  PENDING_TERMINATION_PTS: 'PENDING_TERMINATION', // Key adjusted to avoid conflict with display status key
  REJECTED_INTERNAL: 'REJECTED_INTERNAL',
  REJECTED_EXTERNAL: 'REJECTED_EXTERNAL',
  SUSPENDED_PTS: 'SUSPENDED', // Key adjusted to avoid conflict with display status key
  TERMINATED_PTS: 'TERMINATED' // Key adjusted to avoid conflict with display status key
}

const CCY_DISPLAY_INSTRUCTION_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING_ACTIVATION: 'PENDING ACTIVATION',
  PENDING_TERMINATION: 'PENDING TERMINATION',
  REJECTED: 'REJECTED',
  SUSPENDED: 'SUSPENDED',
  TERMINATED: 'TERMINATED'
}

const CCY_DISPLAY_SUBSCRIPTION_STATUS = {
  SUBSCRIBED: 'SUBSCRIBED',
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  PENDING_ACTIVATION: 'PENDING ACTIVATION',
  PENDING_VERIFICATION: 'PENDING VERIFICATION',
  PENDING_TERMINATION: 'PENDING TERMINATION',
  REJECTED: 'REJECTED',
  REJECTED_INTERNAL: 'REJECTED INTERNAL',
  REJECTED_EXTERNAL: 'REJECTED EXTERNAL',
  SUSPENDED: 'SUSPENDED',
  TERMINATED: 'TERMINATED',
  UNSUBSCRIBED: 'UNSUBSCRIBED'
}

const CCY_PAYOUT_STATUS = {
  PENDING_SETTLEMENT: 'PENDING_SETTLEMENT',
  SETTLED: 'SETTLED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  REVERSED: 'REVERSED'
}

export {
  CCY_PTS_INSTRUCTION_STATUS,
  CCY_DISPLAY_INSTRUCTION_STATUS,
  CCY_DISPLAY_SUBSCRIPTION_STATUS,
  CCY_PAYOUT_STATUS
};
