import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-alerts.html';
import i18n from 'sgx-localisation-service';
import CdpAlertsService from 'services/cdp-alerts-service';
import StatusIndicatorUtil from 'utils/status-indicator-util';

/**
 * CMS alerts display widget.
 */
class TemplateAlertsWidget extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-alerts');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._announcementsList = this.querySelector('.alerts-container');
  }

  connectedCallback() {

    StatusIndicatorUtil.displayLoader(this._indicator);

    CdpAlertsService.getSystemAlerts(i18n.getLanguage())
      .then(alerts => {
        if (!alerts || !alerts.length) {
          StatusIndicatorUtil.displayNoData(this._indicator);
          return;
        }

        const frag = document.createDocumentFragment();
        alerts.map(alert => {
          const alertBar = document.createElement('sgx-alert-bar');
          alertBar.classList.add('widget-alerts-item');
          alertBar.setData({text: alert.content, background: false, status: "informational"});
          frag.appendChild(alertBar);
        });

        this._announcementsList.appendChild(frag);

        /*this._announcementsList.setData(alerts.map(alert => {
          alert.category = 'warning';
          return alert;
        }));*/
        StatusIndicatorUtil.hideStatusIndicator(this._indicator);
      })
      .catch(error => {
        console.error(`Failed to get alerts. ${error}`);
        StatusIndicatorUtil.displayNoData(this._indicator);
      });
  }

  show() {
    document.body.scrollTop = 0;
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

customElements.define('widget-alerts', withInitDOM(TemplateAlertsWidget));
