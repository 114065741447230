module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-stock-screener-toolbar\">\n  <sgx-data-model-tool-display-count data-sgx-display-mode=\"total\" class=\"widget-stock-screener-toolbar-count sgx-base-text-body-bold-14\"></sgx-data-model-tool-display-count>\n  <sgx-data-model-tool-search emit class=\"widget-stock-screener-toolbar-search\"></sgx-data-model-tool-search>\n  <sgx-data-model-tool-filter emit class=\"widget-stock-screener-toolbar-filter\"></sgx-data-model-tool-filter>\n  <!-- Refresh button reserved for future use cases -->\n  <!-- <div>\n    <button type=\"button\" class=\"sgx-button--low-emp sgx-data-model-tool--button sgx-icon--before sgx-icon--after sgx-data-model-tool-label--refresh\" data-sgx-badge--after=\"\">\n      <span class=\"sgx-data-model-tool-label\">Refresh</span>\n    </button>\n  </div> -->\n  <div class=\"widget-stock-screener-toolbar-button-container\">\n    <button type=\"button\" class=\"sgx-data-model-tool--button sgx-button--low-emp sgx-icon widget-stock-screener-toolbar-button--more-actions\"></button>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()