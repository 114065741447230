/**
 * A custom HTML element
 * @module template-header-error
 * @type {HTMLElement}
 */

export default class TemplateHeaderError extends HTMLElement {
  //#region Custom Element API

  constructor() {
    super();
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  attributeChangedCallback() {
  }

  //#endregion

  //#region Public Methods

  setData(data) {
    this.innerHTML = `<div class="page-header"><h1 class="page-title">${data.pageTitle}</h1></div>`;
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }
}

//#endregion

//#region Private Methods


customElements.define('template-header-error', TemplateHeaderError);
//#endregion
