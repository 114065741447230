module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-settings-user-contact\">\n  <fieldset class=\"contact-fieldset\">\n    <h3 class=\"details-title\" data-i18n=\"app.widget-settings-user-contact.title\"></h3>\n    <sgx-input-text name=\"fullName\" class=\"fieldset-input details-fullName sgx-hidden\" readonly hint-type=\"inline\" data-i18n=\"[label]app.widget-settings-user-general.inputs.fullName.label\"></sgx-input-text>\n    <sgx-input-email name=\"email\" class=\"fieldset-input details-email\" hint-type=\"inline\" data-i18n=\"[label]app.widget-settings-user-contact.inputs.email.label\"></sgx-input-email>\n    <sgx-input-phone name=\"contacts[0]\" optionalidd=\"true\" class=\"fieldset-input details-mobile\" hint-type=\"inline\" data-i18n=\"[label]app.widget-settings-user-contact.inputs.mobilePhone.label;\"></sgx-input-phone>\n    <sgx-input-phone name=\"contacts[1]\" optionalidd=\"true\" class=\"fieldset-input details-home\" hint-type=\"inline\" data-i18n=\"[label]app.widget-settings-user-contact.inputs.homePhone.label;[hint]app.widget-settings-user-contact.inputs.homePhone.hint\"></sgx-input-phone>\n    <sgx-input-phone name=\"contacts[2]\" optionalidd=\"true\" class=\"fieldset-input details-office\" hint-type=\"inline\" data-i18n=\"[label]app.widget-settings-user-contact.inputs.officePhone.label;[hint]app.widget-settings-user-contact.inputs.officePhone.hint\"></sgx-input-phone>\n  </fieldset>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()