import { withInitDOM } from 'sgx-base-code';
import DeviceService from 'sgx-device-service';
import dialogTemplate from './cmp-portfolio-transfer-qty-dialog-template';

const DIALOG_ID = 'cmp-portfolio-transfer-qty-dialog';

class CmpPortfolioTransferQtyDialog extends HTMLElement {
  // #region - Custom Element API
  constructor() {
    super();

    this._onQuantityChange = this._onQuantityChange.bind(this);
    this._onQuantitySubmit = this._onQuantitySubmit.bind(this);
    this._afterShowDialog = this._afterShowDialog.bind(this);
  }

  initDOM() {
    this._makeDialogIfNeeded();
  }

  // #endregion

  // #region - Public Function
  showDialog({title, data}) {
    const dialogConfig = {
      title,
      data,
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      afterShow: this._afterShowDialog
    };
    this._initialQuantity = data.quantity;

    return this._dialog.show(dialogConfig)
      .then(() => this._dialogBody.getData())
      .catch(() => {
        this._setListeners(false);
        return Promise.reject();
      });
  }

  // #endregion


  // #region - Private Function
  _makeDialogIfNeeded() {
    const template = dialogTemplate(DIALOG_ID);
    this._dialog = document.getElementById(DIALOG_ID);

    if (this._dialog) {
      return this._dialog;
    }

    document.body.insertAdjacentHTML('beforeend', template);

    this._dialog = document.getElementById(DIALOG_ID);
    this._dialogBody = this._dialog.querySelector('[data-dialog="body"]');
    return this._dialog;
  }

  _afterShowDialog() {
    this._btnCalculate = this._dialog.querySelector('[data-dialog="ok"]');
    this._transferQty = this._dialog.querySelector('cmp-portfolio-transfer-qty');

    this._setListeners(true);
  }

  _setListeners(enable) {
    const listener = enable ? 'addEventListener' : 'removeEventListener';

    this._transferQty[listener]('quantity-change', this._onQuantityChange);
    this._transferQty[listener]('quantity-submit', this._onQuantitySubmit);
  }

  _onQuantityChange(event) {
    const {quantity, valid} = event.detail;
    this._btnCalculate.disabled = this._initialQuantity == quantity || !valid;
  }

  _onQuantitySubmit(_) {
    this._dialog.hide(this._dialogBody.getData());
  }
}

customElements.define('cmp-portfolio-transfer-qty-dialog', withInitDOM(CmpPortfolioTransferQtyDialog));
