import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-audit-log.html';
import { tableConfig } from './table-config.js';
import AuditLogService from 'services/audit-log-service';
import StoreRegistry from 'stores/store-registry';
import TableUtil from 'utils/table-util';
import DateUtil from 'utils/date-util';
import authService from 'services/auth-service';

class AuditLogWidget extends HTMLElement {
  constructor() {
    super();
    this._sessionStore = StoreRegistry.cdpSession;
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-audit-log');
    this._table = this.querySelector('.widget-audit-log-table');
    this._indicator = this.querySelector('sgx-status-indicator');

    this._table.setConfig(tableConfig);
  }

  connectedCallback() {
    this._indicator.show();
    if (Object.keys(this._sessionStore.getData()).length) {
      Promise.all([
        this._table.whenConfigured(),
        AuditLogService.getAuditLogs()
      ])
        .then(response => {
          const logs = response[1].data;
          const tableData = logs.reduce((auditBundle, auditLog, index) => {
            index++;
            const key = index.toString();
            auditLog.serialNo = key;
            auditLog.timestamp = DateUtil.formatTimestamp(auditLog.transactionTime);
            auditBundle.keys.push(key);
            auditBundle.values[key] = auditLog;
            return auditBundle
          }, {
            keys: [],
            values: {}
          });
          this._table.setData(tableData);
          this._table.recalculateSize();
        })
        .then(_ => authService.logout())
        .then(_ => this._indicator.hide())
        .catch(error => {
          console.error(`Could not fetch audit logs: ${error}`);
          authService.logout();
          this._indicator.hide()
        });
    } else {
      this._table.setData([]);
      this._indicator.hide();
    }

    TableUtil.addCustomToolbar(this._table, 'widget-audit-log-toolbar');
  }

  setData(data) {
    this._table.recalculateSize();
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('widget-audit-log', withInitDOM(AuditLogWidget));
