import { Utils, withInitDOM } from 'sgx-base-code';
import { formatThousands } from 'utils/price-util';
import tmpl from './cmp-stocks-consensus.html';

const SCALE_MAX = 5;
const MINIMUM_ANALYSTS_COUNT_TO_SHOW_CONSENSUS = 3;

/**
 * A widget for showing a visual representation of consensus data for a stock
 * @module cmp-stocks-consensus
 * @type {HTMLElement}
 */

export default class CmpStocksConsensus extends HTMLElement {
  constructor() {
    super();
    this._ready = Utils.deferred();
    this._setConsensusValues = this._setConsensusValues.bind(this);
  }

  connectedCallback() {
  }
  initDOM() {
    this.appendChild(tmpl.getNode());
    this._targetPrice = this.querySelector('.cmp-stocks-consensus-target-price-text');
    this._consensusContainer = this.querySelector('.cmp-stocks-consensus-container');
    this._inactiveLine = this.querySelector('.cmp-stocks-consensus-scale-line--inactive');
    this._inactiveLineIndicator = this.querySelector('.cmp-stocks-consensus-scale-line-indicator');
    this._statusContainer = this.querySelector('.cmp-stocks-consensus-status-container');

    this._ready.resolve();
  }

  setData(data) {
    this._data = data || {};
    let consensus = this._data.consensus ? +this._data.consensus : null;
    let analystsCount = this._data.analystsCount ? +this._data.analystsCount : 0;

    this.whenReady().then(() => {
      if (isNaN(consensus) || consensus > SCALE_MAX || consensus < 1 || analystsCount < MINIMUM_ANALYSTS_COUNT_TO_SHOW_CONSENSUS) {
        this._setNoData();
        return;
      }

      this._setConsensusValues(consensus);
    });
  }

  whenReady() {
    return this._ready.promise;
  }

  _setConsensusValues(consensus) {
    const targetPrice = this._data.targetPrice;
    const tradedCurrency = this._data.tradedCurrency;

    if (consensus && targetPrice && tradedCurrency) {
      const scalePercentage = (consensus - 1 === 0) ? 0 : (consensus - 1) / (SCALE_MAX - 1);
      const price = formatThousands(targetPrice, 3, true);
      this._inactiveLine.style.transform = `scaleX(${scalePercentage})`;
      this._inactiveLineIndicator.style.right = `${(scalePercentage * 100)}%`;
      this._targetPrice.value = `${tradedCurrency} ${price}`;

      this._statusContainer.classList.add('sgx-hidden');
      this._consensusContainer.classList.remove('sgx-hidden');
    } else {
      console.warn('No consensus data found');
      this._setNoData();
    }
  }

  _setNoData() {
    this._statusContainer.classList.remove('sgx-hidden');
    this._consensusContainer.classList.add('sgx-hidden');
  }
}

customElements.define('cmp-stocks-consensus', withInitDOM(CmpStocksConsensus));
