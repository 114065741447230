module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-security-details-gti\">\n  <h4 class=\"sgx-base-text-title-16 widget-security-details--print-title\" data-i18n=\"app.security-details.tabs.gti\"></h4>\n  <section class=\"security-details-gti\">\n    <div class=\"security-details-gti-title sgx-base-text-title-16\">\n      <span data-i18n=\"app.security-details.gti.section.label\"></span>\n      <sgx-tooltip>\n        <div data-sgx-tooltip-content data-i18n=\"[html]app.security-details.gti.section.hint_html\"></div>\n        <button class=\"sgx-icon sgx-tooltip-button\"></button>\n      </sgx-tooltip>\n    </div>\n    <sgx-table class=\"security-details-gti-table sgx-icon--after\"></sgx-table>\n    <cmp-stocks-terms></cmp-stocks-terms>\n  </section>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()