module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-security-details-financial\">\n  <h4 class=\"sgx-base-text-title-16 widget-security-details--print-title\" data-i18n=\"app.security-details.tabs.financial-statements\"></h4>\n  <sgx-status-indicator class=\"widget-security-details-financial-indicator\"></sgx-status-indicator>\n  <div class=\"widget-security-details-financial-accordion-container\">\n    <sgx-accordion data-expand-all class=\"widget-security-details-financial-accordion\"></sgx-accordion>\n    <div class=\"widget-security-details-financial-timestamp sgx-base-text-caption\"></div>\n  </div>\n  <cmp-stocks-terms class=\"sgx-hidden\"></cmp-stocks-terms>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()