import { BaseBusService } from 'services/base-bus-service';

/**
 * Gets the QR code elements for SingPass.
 */
class SingPassQrService extends BaseBusService {
  constructor() {
    super();
    this._topic = 'singpass-qr';
  }

  /**
   * Initialize SingPass QR code module.
   * @param {Object} bus service bus class
   * @param {Array} params SingPass QR code init parameters
   */
  handleRequest(bus, params) {
    return new Promise((resolve, reject) => {
      try {
        window.SPCPQR.init(...params);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new SingPassQrService();
