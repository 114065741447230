class SgxDataModelToolBase extends HTMLElement {
  constructor() {
    super();
    this._shouldEmit = this.hasAttribute('emit');
  }

  /**
   * To be implemented by concrete data model tool types.
   */
  _onSetModel() {
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  emit(eventName, detail = {}, bubbles = true) {
    if (!eventName) {
      return;
    }
    if (!this._shouldEmit) {
      if (detail.callback) {
        this[detail.callback]();
      }
      return;
    }
    this.dispatchEvent(new CustomEvent(`sgx-data-model-tool-${eventName}`, {bubbles: true, detail}));
  }
}

Object.defineProperty(SgxDataModelToolBase.prototype, 'setDataModel', {
  value(model) {
    this._model = model;
    this._onSetModel();
  },
  writable: false
});

Object.defineProperty(SgxDataModelToolBase.prototype, 'getDataModel', {
  value() {
    return this._model;
  },
  writable: false
});


export default SgxDataModelToolBase;
