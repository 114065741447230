import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DateService from 'sgx-date-time-service';
import DeviceService from 'sgx-device-service';
import { getPastMonths, ISO_FORMAT } from 'utils/date-util';
import { addCustomToolbar } from 'utils/table-util';
import { download } from 'utils/pdf-util';
import PortfolioService from 'services/portfolio-service';
import PrintService from 'services/print-service';
import { tableConfig, constants } from './widget-portfolio-statements-config';
import tmpl from './widget-portfolio-statements.html';

const { TRADE_SIDE, TRADE_ALLOCATION_STATUS } = PortfolioService.constants;
const {
  FULL_DATE_FORMAT,
  EXPORT_NAME,
  DEFAULT_TABLE_HEIGHT,
  TABLE_ROW_HEIGHT,
  TABLE_TOOLBAR_AND_HEADER_HEIGHT } = constants;

const CDP_STATEMENTS_LABEL_DATE_FORMAT = i18n.getTranslation('app.widget-portfolio-statements.download-list.cdp-statements.date-format');
const CDP_NOTIFICATIONS_LABEL_DATE_FORMAT = i18n.getTranslation('app.widget-portfolio-statements.download-list.cdp-notifications.date-format');
const EMPTY_INDICATOR_TITLE = i18n.getTranslation('sgx-table.messages.no-filter-title');

class WidgetPortfolioStatements extends HTMLElement {
  constructor() {
    super();
    this._onNotificationPeriodChange = this._onNotificationPeriodChange.bind(this);
    this._onStatementPeriodChange = this._onStatementPeriodChange.bind(this);
    this._onDownloadStatement = this._onDownloadStatement.bind(this);
    this._onDownloadNotification = this._onDownloadNotification.bind(this);
    this._onPsnDownloadClick = this._onPsnDownloadClick.bind(this);
    this._onPsnPrintClick = this._onPsnPrintClick.bind(this);
  }

  initDOM() {
    this.classList.add('widget-portfolio-statements');
    this.appendChild(tmpl.getNode());
    this._statementSelect = this.querySelector('.widget-portfolio-statements-cdp-statements-select');
    this._statementDownload = this.querySelector('.widget-portfolio-statements-cdp-statements-download');
    this._statementIndicator = this.querySelector('.widget-portfolio-statements-cdp-statements-indicator');
    this._notificationSelect = this.querySelector('.widget-portfolio-statements-cdp-notifications-select');
    this._notificationDownload = this.querySelector('.widget-portfolio-statements-cdp-notifications-download');
    this._notificationIndicator = this.querySelector('.widget-portfolio-statements-cdp-notifications-indicator');
    this._errorStateStatement = this.querySelector('.portfolio-statement-no-data');
    this._errorStateNotification = this.querySelector('.portfolio-notification-no-data');

    this._psnTable = this.querySelector('.widget-portfolio-statements-psn-table');
    this._psnIndicator = this.querySelector('.widget-portfolio-statements-cdp-psn-indicator');
    this._notesBody = this.querySelector('.widget-portfolio-statements-notes-body');

    addCustomToolbar(this._psnTable, 'widget-portfolio-statements-toolbar');

    // get the reference once toolbar has been attached to the table
    this._psnDownload = this._psnTable.querySelector('sgx-data-model-tool[icon="download"]');
    this._psnPrint = this._psnTable.querySelector('sgx-data-model-tool[icon="print"]');
  }

  connectedCallback() {
    this._psnTable.setConfig(tableConfig);

    this._setListeners(true);
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  setData({ accountId, accountDesc, accountIdFormatted }) {
    this._accountIdFormatted = accountIdFormatted;
    this._accountDesc = accountDesc;
    this._getPortfolioStatements(accountId);
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';

    this._statementSelect[fnName]('change', this._onStatementPeriodChange);
    this._notificationSelect[fnName]('change', this._onNotificationPeriodChange);
    this._statementDownload[fnName]('click', this._onDownloadStatement);
    this._notificationDownload[fnName]('click', this._onDownloadNotification);
    this._psnDownload[fnName]('click', this._onPsnDownloadClick);
    this._psnPrint[fnName]('click', this._onPsnPrintClick);
  }

  _onPsnDownloadClick() {
    this._psnTable.setExportDataFileName(`${EXPORT_NAME} ${this._accountIdFormatted} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`);
    this._psnTable.exportData();
  }

  _onPsnPrintClick() {
    this._psnTable.getDataModel().getCurrentStateData()
      .then(({ keys, values }) => {
        const accountNoDesc = this._accountDesc;
        const fields = Object.keys(keys).map(key => {
          const {
            tradeDate,
            tradeAllocationStatusDesc,
            securityName,
            tradeSideDesc,
            tradeType,
            quantity,
            settlementDate,
            brokerName } = values[keys[key]];

          return {
            tradeDate: DateService(tradeDate).format(FULL_DATE_FORMAT),
            settlementDate: DateService(settlementDate).format(FULL_DATE_FORMAT),
            tradeAllocationStatus: tradeAllocationStatusDesc,
            securityName,
            tradeSide: tradeSideDesc,
            tradeType,
            quantity,
            brokerName
          };
        });
        const body = {
          qualifiedName: 'psnInfo',
          params: { accountNoDesc },
          fields
        };

        PrintService.getPdf(body)
          .then(base64 =>  download(base64, `${EXPORT_NAME} ${this._accountIdFormatted} ${DateService.tz(DateService.tz.guess()).format(i18n.getTranslation('app.shared-text.date-format-file-name'))}`));
      });
  }

  _onNotificationPeriodChange(e) {
    this._setNotificationsDownloadList(e.target.getSelectedOptions()[0].value);
  }

  _onStatementPeriodChange(e) {
    this._setAccountStatementsDownloadList(e.target.getSelectedOptions()[0].value);
  }

  _onDownloadStatement(e) {
    e.preventDefault();
    const notfId = e.target.getAttribute('ref');
    if (notfId) {
      let name = e.target.textContent;
      if (e.target.parentElement) {
        const link = e.target.parentElement.querySelector('.sgx-download-list-item-link');
        name = link ? link.textContent : name;
      }
      PortfolioService.getAccountStatementById(this._accountId, notfId)
        .then(data => {
          if (data && data.content) {
            download(data.content, name);
          }
        });
    }
  }

  _onDownloadNotification(e) {
    e.preventDefault();
    const notfId = e.target.getAttribute('ref');
    if (notfId) {
      let name = e.target.textContent;
      if (e.target.parentElement) {
        const link = e.target.parentElement.querySelector('.sgx-download-list-item-link');
        name = link ? link.textContent : name;
      }
      PortfolioService.getNotificationById(this._accountId, notfId)
        .then(data => {
          if (data && data.content) {
            download(data.content, name);
          }
        });
    }
  }

  _getPortfolioStatements(accountId) {
    const startMonth = DateService().subtract(24, 'months').format(ISO_FORMAT.YEAR_MONTH);
    const endMonth = DateService().subtract(1, 'months').format(ISO_FORMAT.YEAR_MONTH);
    const startDate = DateService().subtract(59, 'days').format(ISO_FORMAT.FULL_DATE);
    const endDate = DateService().format(ISO_FORMAT.FULL_DATE);
    const psnStartDate = endDate;

    this._accountId = accountId;

    this._statementIndicator.show({ status: 'loading' });
    this._notificationIndicator.show({ status: 'loading' });
    this._psnIndicator.show({ status: 'loading' });

    Promise.all([
      PortfolioService.getAccountStatements(accountId, startMonth, endMonth).catch(_ => []),
      PortfolioService.getNotifications(accountId, startDate, endDate).catch(_ => []),
      PortfolioService.getPreSettlementNotifications(accountId, psnStartDate).catch(_ => [])
    ])
      .then(([monthly, daily, psn]) => {
        if (this._accountId !== accountId) {
          // targeted accound has changed
          return;
        }
        this._statementIndicator.hide();
        this._notificationIndicator.hide();
        this._psnIndicator.hide();
        this._setAccountStatements(monthly);
        this._setNotifications(daily);
        this._setPsn(psn);
      });
  }

  _setAccountStatements(monthly) {
    // Group the arrays per month and year
    this._statements = monthly.reduce((accumulator, currentValue) => {
      const translationPath = 'app.widget-portfolio-statements.download-list.cdp-statements.duplicate-label';
      const notfDate = DateService(currentValue.notfDate, ISO_FORMAT.FULL_DATE);
      const key = notfDate.format(ISO_FORMAT.YEAR_MONTH);
      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      const duplicateDates = accumulator[key].filter(obj => obj.notfDate === currentValue.notfDate);
      if (duplicateDates.length) {
        const part = duplicateDates.length + 1;
        const date = DateService(currentValue.notfDate, ISO_FORMAT.FULL_DATE)
          .format(CDP_STATEMENTS_LABEL_DATE_FORMAT);
        currentValue.label = i18n.getTranslation(translationPath, { date: notfDate.format(CDP_STATEMENTS_LABEL_DATE_FORMAT), part });

        // make sure to update the label of the first part of the pdf
        accumulator[key][0].label = i18n.getTranslation(translationPath, { date, part: 1 });
      } else {
        currentValue.label = notfDate.locale('en').format(CDP_STATEMENTS_LABEL_DATE_FORMAT);
      }

      accumulator[key].push(currentValue);
      return accumulator;
    }, {});

    // disable options that don't have statements
    let options = getPastMonths(24, false).reverse();
    let firstMonthWithEntryPos = options.findIndex(option => !!this._statements[option.value]);
    if (!!~firstMonthWithEntryPos) {
      // start from the month that has data
      options = options.slice(firstMonthWithEntryPos);
    }
    options = options.map(option => ({ ...option, disabled: !(!!this._statements[option.value]) }))
      .reverse();

    const shouldHideOptions = options.every(option => option.disabled);
    const latestOption = options[0];
    const fnName = shouldHideOptions ? 'add' : 'remove';

    latestOption.selected = true; // select the latest month on the list
    this._statementSelect.classList[fnName]('sgx-hidden'); // hide dropdown when all options are disabled
    this._statementSelect.setOptions(options);
  }

  _setNotifications(daily) {
    // Group the arrays per month and year
    this._notifications = daily.reduce((accumulator, currentValue) => {
      const notfDate = DateService(currentValue.notfDate, ISO_FORMAT.FULL_DATE);
      const key = notfDate.format(ISO_FORMAT.YEAR_MONTH);
      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      currentValue.label = notfDate.locale('en').format(CDP_NOTIFICATIONS_LABEL_DATE_FORMAT);
      accumulator[key].push(currentValue);
      return accumulator;
    }, {});

    // disable options that don't have statements
    const options = getPastMonths(2);
    const latestOption = options[0];

    latestOption.selected = true; // select the latest month on the list
    this._notificationSelect.setOptions(options);
  }

  _setPsn(psn) {
    const data = psn.map((notif, i) => ({
      ...notif,
      rowNumber: i + 1,
      tradeSideDesc: i18n.getTranslation(`app.widget-portfolio-statements.table.trade-side.enum.${TRADE_SIDE[notif.tradeSide]}`),
      tradeAllocationStatusDesc: i18n.getTranslation(`app.widget-portfolio-statements.table.status.enum.${TRADE_ALLOCATION_STATUS[notif.tradeAllocationStatus]}`)
    }));

    if (DeviceService.isDesktop()) {
      this._psnTable.style.height = data.length && data.length > 5 ? `${(data.length * TABLE_ROW_HEIGHT) + TABLE_TOOLBAR_AND_HEADER_HEIGHT}px` : DEFAULT_TABLE_HEIGHT;
    }
    this._psnTable.setData(data);
    this._psnTable.recalculateSize();
  }

  _setAccountStatementsDownloadList(period) {
    if (!this._statements[period]) {
      this._statementDownload.classList.add('sgx-hidden');
      this._errorStateStatement.classList.remove('sgx-hidden');
    } else {
      this._statementDownload.classList.remove('sgx-hidden');
      this._errorStateStatement.classList.add('sgx-hidden');

      this._statementDownload.setData(this._toDownloadList(this._statements[period]), true);
      this._adjustDownloadList(this._statementDownload);
    }
  }

  _setNotificationsDownloadList(period) {
    if (!this._notifications[period]) {
      this._notificationDownload.classList.add('sgx-hidden');
      this._errorStateNotification.classList.remove('sgx-hidden');
    } else {
      this._notificationDownload.classList.remove('sgx-hidden');
      this._errorStateNotification.classList.add('sgx-hidden');

      this._notificationDownload.setData(this._toDownloadList(this._notifications[period]), true);
      this._adjustDownloadList(this._notificationDownload);
    }
  }

  _toDownloadList(arr) {
    const downloadItems = (arr || []).map(notif => ({
      label: notif.label,
      file: {
        mediaType: 'document',
        file: {
          url: notif.notfId,
          filemime: 'application/pdf'
        }
      }
    }));

    return {
      title: null,
      footnote: null,
      showDates: true,
      downloadItems
    };
  }

  /**
   * Remove href attributes and reassign them as ref attributes to avoid browsers interpreting
   * the items as true links.
   * @param {HTMLElement} downloadList the sgx-download-list to iterate over
   */
  _adjustDownloadList(downloadList) {
    Array.from(downloadList.querySelectorAll('a[href]')).forEach(item => {
      const itemRef = item.attributes['href'].value;
      item.removeAttribute('href');
      item.setAttribute('ref', itemRef);
    });
  }
}

customElements.define('widget-portfolio-statements', withInitDOM(WidgetPortfolioStatements));
