// #region Dependencies

import { withInitDOM } from '@sgx/sgx-base-code';
import tmpl from './widget-social-links.html';
import { constants } from './config';

// #endregion

// #region Custom Element API

/**
 * A custom HTML element used as a decorator for securities prices widget
 * @module widget-social-links
 * @type {HTMLElement}
 */

class SocialLinks extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._footerWechatDialog = document.getElementById('widget-social-links-wechat-dialog');
  }

  connectedCallback() {
    this.setData();
  }

  disconnectedCallback() {
    this._registerEventListeners(false);
    this._footerWechatDialog.hide();
  }

  setData() {
    // set title
    this._setHeaderFooter();

    // set icons and links
    this._setIcons();

    // set dialog text and links
    this._setDialogItems();

    // register event listener
    this._registerEventListeners(true);
  }

  _setHeaderFooter() {
    try {
      this.querySelector('.widget-social-links--title').innerText = constants.headerTitle;

      const viewMoreLink = this.querySelector('.widget-social-links--view-more-link');
      viewMoreLink.innerText = constants.viewMore.text;
      viewMoreLink.setAttribute('href', constants.viewMore.url);
    } catch (error) {
      console.log('error while setting title', error);
    }
  }

  _setIcons() {
    try {
      const socialLinkElement = this.querySelector('.widget-social-links--social-list');

      for (const link of constants.links) {
        const { className } = link;
        // only for wechat icon set as button since there will be a dialog with QR code will appear
        if (link.className === 'wechat') {
          const buttonElement = document.createElement('button');
          const buttonClasses = ['widget-social-links-social-icon', `widget-social-links-social-icon--${className}`];
          buttonElement.classList.add(...buttonClasses);
          buttonElement.setAttribute('id', `widget-social-links-social-icon--${className}`);
          socialLinkElement.append(buttonElement);
        } else {
          const { url } = link;

          const anchorElement = document.createElement('a');
          anchorElement.setAttribute('href', url);
          anchorElement.setAttribute('id', `widget-social-links-social-icon--${className}`);
          const classes = ['widget-social-links-social-icon', `widget-social-links-social-icon--${className}`];
          anchorElement.classList.add(...classes);
          socialLinkElement.append(anchorElement);
        }
      }
    } catch (error) {
      console.log('error while setting icons', error);
    }
  }

  _setDialogItems() {
    try {
      document.querySelector('.widget-social-links-wechat-dialog--wechat-body-content').innerText =
        constants.dialog.text;

      const dialogImg = document.querySelector('.widget-social-links-wechat-dialog--wechat-qr-code');
      dialogImg.setAttribute('src', constants.dialog.img.url);
      dialogImg.setAttribute('alt', constants.dialog.img.alt);
    } catch (error) {
      console.error('error while setting dialog items', error);
    }
  }

  _registerEventListeners(enable) {
    this._wechatBtn = document.querySelector('#widget-social-links-social-icon--wechat');
    const listener = enable ? 'addEventListener' : 'removeEventListener';
    this._wechatBtn && this._wechatBtn[listener]('click', this._handleWechatButtonClick.bind(this));
  }

  /**
   * Show the footer following a specific configuration on click to weChat button.
   * @param {Object} event
   * @returns {Promise}
   */
  _handleWechatButtonClick(event) {
    if (this._footerWechatDialog.isShown()) return this._footerWechatDialog.hide();

    return this._footerWechatDialog.show({
      hideFooter: true,
      hideOverlay: true,
      hideHeader: true,
      zIndex: 1000,
      position: {
        target: this._wechatBtn,
        attachment: 'bottom right',
        targetAttachment: 'top right',
        offset: this._isSmall ? '10 -15' : '10 0',
      },
    });
  }
}

export default customElements.define('widget-social-links', withInitDOM(SocialLinks));
