import tmpl from './cmp-list-row-investor-journey.html';
import { withInitDOM } from 'sgx-base-code';

class CmpListRowInvestorJourney extends HTMLElement {

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-list-row-investor-journey');
    this._container = this.querySelector('.cmp-list-row-investor-journey-container');
    this._icon = this.querySelector('.cmp-list-row-investor-journey-icon');
    this._text = this.querySelector('.cmp-list-row-investor-journey-section-text');
    this._button = this.querySelector('.cmp-list-row-investor-journey-section-button');
  }

  connectedCallback() {}
  disconnectedCallback() {}

  setData(data) {
    this._render(data);
  }

  _render({ icon = '', text = '', button = {}, border = false }) {
    this._icon.classList.add(`cmp-list-row-investor-journey-icon-${icon}`);
    this._text.textContent = text;
    this._button.textContent = button.label;
    this._button.href = button.url;
    this._button.classList.add(`sgx-button--${button.type}`);
    if (border) {
      this._container.classList.add('cmp-list-row-investor-journey-container--border');
    }
  }
}

customElements.define('cmp-list-row-investor-journey', withInitDOM(CmpListRowInvestorJourney));