import BaseService from 'services/base-service';

class ExchangeRateService extends BaseService {
  /**
   * Gets exchange rates
   * @param {array<string>} currencies list of currency to be converted to SGD
   * @return {Promise<object>} a promise
   */
  getExhangeRatesToSgd(currencies) {
    const body = JSON.stringify(currencies.map(currency => ({
      source: currency,
      target: 'SGD'
    })));

    return this._fetch(this._endpoints.EXCHANGE_RATE_READ, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(result => result && result.response || []);
  }

  /**
   * Gets the utils shared instance.
   * @return {ExchangeRateService} the utils shared instance
   */
  static get instance() {
    if (!ExchangeRateService._instance) {
      ExchangeRateService._instance = new ExchangeRateService();
    }
    return ExchangeRateService._instance;
  }
}

export default ExchangeRateService.instance;
