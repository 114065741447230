'use strict';

require('theme-light.scss');
/* eslint-disable global-require */

// Base code needs to be first to load polyfills
// require('@sgx/sgx-base-code');
import SgxBaseCode from '@sgx/sgx-base-code';
import SgxDeviceService from '@sgx/sgx-device-service';
import SgxConfigService from '@sgx/sgx-config-service';
import i18n from '@sgx/sgx-localisation-service';
import { i18nConfig } from './i18n-config';

import StoreRegistry from 'stores/store-registry';
import { isAuthenticated } from 'utils/auth-util';


// Load services, starting from DeviceService
SgxDeviceService.whenReady({ allowUnsupported: false })
  .then(function startConfigService() {
    return SgxConfigService.whenReady('./config/appconfig.js');
  }, function showUnsupportedMessage() {
    document.body.setAttribute('class', 'no-browser-support');
    document.querySelector('sgx-header').style.display = 'none';
    document.querySelector('.website-main-layout.sgx-header-layout-target').style.display = 'none';
    return Promise.reject();
  })
  .then(function startLocalisationService() {
    return i18n.init(i18nConfig(StoreRegistry.appSettings.getData('lang')));
  })
  .then(function loadDependencies() {
    // Digital Core Components
    require('sgx-alert-bar');
    require('sgx-analytics-service');
    require('sgx-header');
    require('sgx-consent-banner');
    require('sgx-accordion');
    require('sgx-forms');
    require('sgx-state-tabs');
    require('sgx-app-router');
    require('sgx-chart');
    require('sgx-download-list');
    require('sgx-filters/sgx-filters-type-range');
    require('sgx-filters');
    require('sgx-filters-dialog');
    require('sgx-status-indicator');
    require('sgx-table');
    require('sgx-tabs');
    require('sgx-tooltip');
    require('sgx-progress-tracker');
    require('sgx-content-table');
    require('sgx-lite-youtube');
    require('sgx-alert-bar');
    require('sgx-carousel');
    require('sgx-mini-chart');
    require('sgx-payments');
    require('sgx-in-viewport');
    // TODO - check if we need all the components
    require('sgx-table/src/sub-components/sgx-table-cell-text/sgx-table-cell-text');
    require('sgx-table/src/sub-components/sgx-table-cell-link/sgx-table-cell-link');
    require('sgx-table/src/sub-components/sgx-table-cell-date/sgx-table-cell-date');
    require('sgx-table/src/sub-components/sgx-table-cell-number/sgx-table-cell-number');
    require('sgx-table/src/sub-components/sgx-table-cell-star/sgx-table-cell-star');
    require('sgx-table/src/sub-components/sgx-table-header-text/sgx-table-header-text');
    require('sgx-table/src/sub-components/sgx-table-header-number/sgx-table-header-number');
    require('sgx-table/src/sub-components/sgx-table-header-date/sgx-table-header-date');
    require('sgx-table/src/sub-components/sgx-table-decorator-price-movement-color/sgx-table-decorator-price-movement-color');
    require('sgx-table/src/sub-components/sgx-table-decorator-star/sgx-table-decorator-star');

    // App Services
    require('./services/cdp-alerts-service');
    require('./services/market-research-service');
    require('./services/sgx-corporate-information-service');
    require('./services/sgx-metadata-service');
    require('./aggregators/security-details-aggregator/security-details-aggregator');
    require('./aggregators/company-announcements-aggregator');
    require('./services/user-preferences-service');
    require('./services/user-service');
    require('./services/auth-service');
    require('./services/bank-account-service');
    require('./services/trading-account-service');
    require('./services/blb-service');
    require('./services/audit-log-service');
    require('./services/sgx-corporate-actions-service');
    require('./services/ccy-service');
    require('./services/sgx-ipo-service');

    // App Components
    require('./components/cmp-accordion-item/cmp-accordion-item');
    require('./components/sgx-dashboard/sgx-dashboard.js');
    require('./components/sgx-list-details');
    require('./components/sgx-data-model-tools');
    require('./components/sgx-table-cell-input-button/sgx-table-cell-input-button.js');
    require('./components/sgx-table-cell-big-number/sgx-table-cell-big-number.js');
    require('./components/sgx-table-decorator-input-button/sgx-table-decorator-input-button.js');
    require('./components/sgx-table-decorator-cdp-notification/sgx-table-decorator-cdp-notification.js');
    require('./components/sgx-table-decorator-row-number/sgx-table-decorator-row-number.js');
    require('./components/cmp-login-prompt/cmp-login-prompt.js');
    require('./components/cmp-login-prompt-dashboard/cmp-login-prompt-dashboard.js');
    require('./components/cmp-txn-signing/cmp-txn-signing.js');
    require('./components/cmp-txn-signing/subflows/cmp-txn-signing-singpass.js');
    require('./components/cmp-list-row-prompt/cmp-list-row-prompt.js');
    require('./components/cmp-tile/cmp-tile.js');
    require('./components/cmp-star-icon/cmp-star-icon.js');
    require('./components/cmp-otp-prompt/cmp-otp-prompt.js');
    require('./components/cmp-list-row-account-state/cmp-list-row-account-state');
    require('./components/sgx-status-tag/sgx-status-tag.js');
    require('./components/sgx-indices-card/sgx-indices-card.js');
    require('./components/sgx-slides/sgx-slides.js');
    require('./templates/template-header-simple/template-header-simple.js');
    require('./templates/template-header-navs/template-header-navs.js');
    require('./templates/template-header-error/template-header-error.js');
    require('./templates/template-error-page/template-error-page.js');
    require('./components/cmp-sub-header/cmp-sub-header.js');
    // Myinfo Update Particulars
    require('./components/cmp-user-profile-myinfo-confirmation/cmp-user-profile-myinfo-confirmation.js');
    require('./widgets/widget-user-details-myinfo/widget-user-details-myinfo.js');


    // App Widgets
    require('./widgets/widget-account-select/widget-account-select.js');
    require('./widgets/widget-broker-select/widget-broker-select.js');
    require('./widgets/widget-dcs-display/widget-dcs-display');
    require('./widgets/widget-user-details-dialog/widget-user-details-dialog');
    require('./widgets/widget-dcs-update-dialog/widget-dcs-update-dialog');

    // App Components
    require('./components/cmp-error-handling/cmp-error-handling.js');

    // Dashboard
    require('./templates/template-dashboard-page/template-dashboard-page.js');
    // Dashboard - Information
    require('./widgets/widget-alerts/widget-alerts.js');
    // Dashboard - Index
    require('./widgets/widget-equity-single/widget-equity-single.js');
    require('./widgets/widget-index-single/widget-index-single.js');
    // Dashboard - Market Performance
    require('./components/cmp-list-row-market-performance/cmp-list-row-market-performance.js');
    require('./widgets/widget-securities-market-performance/widget-securities-market-performance.js');
    // Dashboard - Create Account Redirect
    require('./widgets/widget-create-account/widget-create-account.js');
    // Dashboard - Market News
    require('./widgets/widget-media-centre/widget-media-centre.js');
    // Dashboard - Portfolio Account
    require('./widgets/widget-portfolio-account/widget-portfolio-account.js');
    // Dashboard - Resources
    require('./widgets/widget-resources/widget-resources.js');
    // Dashboard - Investor Journey
    require('./widgets/widget-investor-journey/widget-investor-journey');
    require('./components/cmp-list-row-investor-journey/cmp-list-row-investor-journey');
    // Dashboard - Stock Screener Quick Search
    require('./widgets/widget-stock-quick-search/widget-stock-quick-search');
    // Dashboard - Corporate Actions
    require('./widgets/widget-corporate-actions-list/widget-corporate-actions-list');
    require('./widgets/widget-dashboard-corporate-actions');
    // Dashboard - Widget Carousel
    require('./widgets/widget-dashboard-carousel/widget-dashboard-carousel.js');
    // Dashboard - Common company annoncements and Market updates
    require('./components/cmp-list-row-tiles/cmp-list-row-tiles');
    require('./widgets/widget-list-row-tiles-wrapper/widget-list-row-tiles-wrapper');
    // Dashboard - Quicklinks
    require('./components/cmp-list-row-quick-links/cmp-list-row-quick-links');
    require('./widgets/widget-dashboard-quick-links/widget-dashboard-quick-links');
    // Dashboard - Social links
    require('./widgets/widget-social-links/widget-social-links');

    // Securities
    require('./components/cmp-price-info/cmp-price-info.js');
    require('./components/cmp-stocks-terms/cmp-stocks-terms.js');
    require('./components/cmp-list-standalone/cmp-list-standalone.js');
    require('./components/cmp-collapsible-two-column/cmp-collapsible-two-column.js');
    require('./components/cmp-list-row-security/cmp-list-row-security.js');
    require('./components/cmp-list-row-security-announcement/cmp-list-row-security-announcement.js');
    require('./widgets/widget-security-prices-details/widget-security-prices-details.js');
    require('./widgets/widget-security-details/widget-security-details.js');
    require('./widgets/widget-security-details-overview/widget-security-details-overview.js');
    require('./widgets/widget-security-details-information/widget-security-details-information.js');
    require('./widgets/widget-security-details-financial/widget-security-details-financial.js');
    require('./widgets/widget-security-details-announcements/widget-security-details-announcements.js');
    require('./widgets/widget-security-details-market/widget-security-details-market.js');
    require('./widgets/widget-security-details-gti/widget-security-details-gti.js');
    require('./widgets/widget-security-details-remarks/widget-security-details-remarks.js');
    require('./widgets/widget-security-details-corporate-actions/widget-security-details-corporate-actions.js');
    require('./widgets/widget-security-details-product-information/widget-security-details-product-information.js');
    require('./widgets/widget-security-details-product-specifications/widget-security-details-product-specifications.js');
    require('./templates/template-securities-page/template-securities-page.js');
    require('./widgets/widget-security-details-ipo-performance/widget-security-details-ipo-performance');
    require('./widgets/widget-security-details-warrant-info/widget-security-details-warrant-info');

    // Market research
    require('./components/cmp-data-model-market-research/cmp-data-model-market-research.js');
    require('./components/cmp-list-row-market-research/cmp-list-row-market-research.js');
    require('./components/cmp-market-research-details/cmp-market-research-details.js');
    require('./templates/template-market-research-page/template-market-research-page.js');

    // Company announcements
    require('./components/cmp-data-model-announcements/cmp-data-model-announcements.js');
    require('./components/cmp-list-row-announcement/cmp-list-row-announcement.js');

    // Company Disclosures
    require('./templates/template-company-disclosures-page/template-company-disclosures-page.js');
    require('./widgets/widget-company-announcements/widget-company-announcements.js');
    require('./widgets/widget-corporate-actions/widget-corporate-actions.js');
    require('./components/cmp-data-model-corporate-actions/cmp-data-model-corporate-actions.js');
    require('./components/cmp-list-row-corporate-actions/cmp-list-row-corporate-actions.js');

    // Settings
    require('./templates/template-settings-page/template-settings-page.js');
    // Settings - User
    require('./widgets/widget-settings-user-general/widget-settings-user-general.js');
    require('./widgets/widget-settings-user-signatory/widget-settings-user-signatory.js');
    require('./widgets/widget-settings-user-contact/widget-settings-user-contact.js');
    require('./widgets/widget-settings-user-address/widget-settings-user-address.js');
    require('./widgets/widget-settings-user-actions/widget-settings-user-actions.js');
    require('./templates/template-settings-user/template-settings-user.js');
    require('./widgets/widget-otp-sms/widget-otp-sms.js');
    require('./widgets/widget-otp-token/widget-otp-token.js');
    require('./widgets/widget-tax-residency-fieldsets/widget-tax-residency-fieldsets.js');
    require('./widgets/widget-tax-residency/widget-tax-residency.js');
    // Settings - Account
    require('./components/cmp-list-row-account-bank/cmp-list-row-account-bank.js');
    require('./components/cmp-list-row-account-trading/cmp-list-row-account-trading.js');
    require('./widgets/widget-dcs-dialog/widget-dcs-dialog.js');
    require('./widgets/widget-due-d-check/widget-due-d-check.js');
    require('./widgets/widget-settings-account-bank/widget-settings-account-bank.js');
    require('./widgets/widget-settings-account-trading-dialog/widget-settings-account-trading-dialog.js');
    require('./widgets/widget-settings-account-trading/widget-settings-account-trading.js');
    require('./widgets/widget-settings-account-actions/widget-settings-account-actions.js');
    require('./templates/template-settings-account/template-settings-account.js');
    require('./widgets/widget-ccy-subscription/widget-ccy-subscription.js');
    require('./widgets/widget-ccy-subscription-dialog/widget-ccy-subscription-dialog.js');
    require('./widgets/widget-corporate-actions-standing-instructions/widget-corporate-actions-standing-instructions.js');
    require('./components/cmp-list-row-ca-standing-instruction/cmp-list-row-ca-standing-instruction.js');
    require('./widgets/widget-ca-standing-instructions-dialog/widget-ca-standing-instructions-dialog.js');
    require('./widgets/widget-stop-paper/widget-stop-paper');
    // Settings - Security
    require('./widgets/widget-settings-security-password/widget-settings-security-password.js');
    require('./widgets/widget-settings-security-password-dialog/widget-settings-security-password-dialog.js');
    require('./templates/template-settings-security/template-settings-security.js');
    // Settings - Notifications
    require('./widgets/widget-settings-notifications-email/widget-settings-notifications-email.js');
    require('./widgets/widget-settings-notifications-marketing/widget-settings-notifications-marketing.js');
    require('./widgets/widget-settings-notifications-sms/widget-settings-notifications-sms.js');
    require('./widgets/widget-settings-notifications-actions/widget-settings-notifications-actions.js');
    require('./templates/template-settings-notifications/template-settings-notifications.js');
    // Settings - Application
    require('./widgets/widget-settings-application-language/widget-settings-application-language.js');
    require('./templates/template-settings-application/template-settings-application.js');

    // About
    require('./templates/template-about-page/template-about-page.js');
    // About - Onboarding Guide
    require('./widgets/widget-onboarding-guide/widget-onboarding-guide');
    // About - Join Video Call Widget
    require('./widgets/widget-join-video-call/widget-join-video-call');

    // Portfolio
    require('./templates/template-portfolio-page/template-portfolio-page.js');
    require('./components/cmp-portfolio-unit-cost/cmp-portfolio-unit-cost.js');
    require('./components/cmp-portfolio-unit-cost-dialog/cmp-portfolio-unit-cost-dialog.js');
    require('./components/cmp-portfolio-transfer-qty/cmp-portfolio-transfer-qty.js');
    require('./components/cmp-portfolio-transfer-qty-dialog/cmp-portfolio-transfer-qty-dialog.js');
    require('./components/cmp-portfolio-blb-toolbar/cmp-portfolio-blb-toolbar');
    require('./components/sgx-table-decorator-row-background/sgx-table-decorator-row-background.js');
    require('./widgets/widget-portfolio/widget-portfolio.js');
    require('./components/sgx-table-cell-corporate-action-links/sgx-table-cell-corporate-action-links.js');
    // Portfolio - Overview
    require('./components/sgx-table-cell-blocked-balance/sgx-table-cell-blocked-balance.js');
    require('./components/sgx-table-decorator-blocked-balance/sgx-table-decorator-blocked-balance.js');
    require('./widgets/widget-portfolio-overview');
    // Portfolio - Transfer Securities
    require('./components/cmp-transfer-securities-prompt/cmp-transfer-securities-prompt.js');
    require('./widgets/widget-portfolio-transfer-securities');
    // Portfolio - Payouts
    require('./widgets/widget-portfolio-payouts');
    // Portfolio - Share Transactions
    require('./widgets/widget-portfolio-transactions');
    // Portfolio - E-Statements
    require('./widgets/widget-portfolio-statements');
    require('./widgets/widget-portfolio-account-details');
    require('./widgets/widget-portfolio-account-details-dialog');
    require('./widgets/widget-portfolio-owner-info');
    require('./widgets/widget-portfolio-registered-accounts');
    require('./widgets/widget-portfolio-notification-settings');
    // Portfolio - Rights Application Status
    require('./widgets/widget-portfolio-rights');
    require('./widgets/widget-rights-dialog');
    require('./widgets/widget-rights-payment-dialog');
    require('./components/sgx-table-cell-dialog/sgx-table-cell-dialog.js');
    require('./components/sgx-table-header-tooltip/sgx-table-header-tooltip.js');
    require('./components/cmp-rights-payment-summary');

    // Tools & Services
    require('./templates/template-tools-page/template-tools-page.js');

    //Stockfacts
    require('./components/cmp-stocks-consensus');
    require('./components/sgx-table-cell-securities/sgx-table-cell-securities.js');
    require('./components/sgx-table-decorator-securities/sgx-table-decorator-securities.js');
    require('./components/sgx-table-decorator-price-movement-icon/sgx-table-decorator-price-movement-icon.js');
    require('./widgets/widget-stock-screener');
    require('./widgets/widget-stock-screener/widget-stock-screener-toolbar.js');

    // Stockfacts Details
    require('./templates/template-stock-screener-details/template-stock-screener-details.js');

    // Logout
    require('./widgets/widget-audit-log/toolbars/widget-audit-log-toolbar.js');
    require('./widgets/widget-audit-log/widget-audit-log.js');
    require('./templates/template-logout/template-logout.js');

    // Session Timeout
    require('./components/cmp-session-timeout/cmp-session-timeout.js');

    // Update User Particulars Through Single-Sign-On CTAs
    require('./widgets/widget-user-particulars-update-sso/widget-user-particulars-update-sso.js');
    return Promise.resolve();
  })
  .then(function loadApp() {
    var App = require('./App.js');
    window._sgxComApp = new App();
  })
  .then(() => {
    if(isAuthenticated()){
      const singpassScript = document.createElement('script');
      singpassScript.setAttribute('src', SgxConfigService.links.SINGPASS_TXN_SIGN_SCRIPT_LINK);
      document.body.appendChild(singpassScript);
    }

  })
  .catch(err => {
    console.error(err);
  });
