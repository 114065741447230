import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-security-details-announcements.html';
import DateService from 'sgx-date-time-service';
import ConfigService from 'sgx-config-service';
import CompanyAnnouncementsAggregator from 'aggregators/company-announcements-aggregator';
import i18n from 'sgx-localisation-service';

class TemplateSecurityDetailsAnnouncementsWidget extends HTMLElement {
  constructor() {
    super();
    this._router = document.getElementById('sgx-app-router');
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-security-details-announcements');
    this._indicator = this.querySelector('sgx-status-indicator');
    this._list = this.querySelector('.widget-security-details-announcements-list');
    this._link = this.querySelector('.widget-security-details-announcements-link');

    this._link.href = ConfigService.links.INVESTORS_PORTAL_COMPANY_ANNOUNCEMENT;
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  attributeChangedCallback(attr, oldV, newV) {
  }

  setData(data) {
    this._link.target = '_blank';
    this._link.href += `?securityCode=${data.nc}`;

    this._list.setConfig({
      'rowElementName': 'cmp-list-row-security-announcement',
      'rowClickedCallback': rowData => {
        if (this._router) {
          if (this._link.target === '_blank') {
            window.open(`${this._link.href}&annc=${rowData.id}`);
            return;
          }
          this._router.navigateToURL(`/company-disclosures/company-announcements?annc=${rowData.id}`);
        }
      }
    });

    if (!data.nc) {
      return;
    }
    this._indicator.show();

    CompanyAnnouncementsAggregator.getAnnouncementsAndCount({
      pageStart: 0,
      pageSize: 5,
      searchType: 'securitycode',
      searchValue: data.nc,
      // get all announcements from max (rolling window of 5 years till today)
      from: CompanyAnnouncementsAggregator.getValidatedFromDate(null, true, -(5)),
      to: CompanyAnnouncementsAggregator.getValidatedToDate(null, true, 0)
    }).then(dataAndCount => {
      // Make a reference to fixed number of list items
      this._fixedDataLength = dataAndCount.data.length;

      this._list.setData(dataAndCount.data);
      this._indicator.hide();

      if (!this._fixedDataLength) {
        this._indicator.show({
          status: 'neutral',
          title: i18n.getTranslation('app.shared-text.status-indicator.empty.description')
        });
        this._link.classList.add('sgx-hidden');
      } else {
        this._link.classList.remove('sgx-hidden');
      }
    });
  }
}

customElements.define('widget-security-details-announcements', withInitDOM(TemplateSecurityDetailsAnnouncementsWidget));
