import fetchDataMiddleware from './fetch-data';
import paramMiddleware from './param';
import dashboardTitleMiddleware from './dashboard-title';
import helpCenterMiddleware from './help-center';
import handle404Middleware from './handle-404';

export {
  fetchDataMiddleware,
  paramMiddleware,
  dashboardTitleMiddleware,
  helpCenterMiddleware,
  handle404Middleware
};
