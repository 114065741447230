/**
   * Helper function to shape NAF token serial number to
   * what the backend services require.
   * 
   * User input format may be:
   *  - VA-XX-XXXXXXX-X
   *  - VA-XXXXXXXX
   *  - XX-XXXXXXX-X
   *  - XXXXXXXXXX
   * 
   * Backend service expects format to be:
   *  - VA-XXXXXXXXXX
   * @param {string} serial the NAF token serial number 
   */
  export function shapeSerialNumber(serial) {
    if (/^VA-/.test(serial)) {
      return `VA-${serial.substring(3).replace(/-/g, '')}`;
    } else {
      return `VA-${serial.replace(/-/g, '')}`;
    }
  }
  