// Omnibus which has value of 3 is not part of CDP Account
/**
 * CDP Account Categories
 * - Individual (Account Category: 0)
 * - Joint AND (Account Category: 1 and Type of Approval: 1)
 * - Joint OR (Account Category: 1 and Type of Approval: 2)
 * - Corporate (Account Category: 2)
 * - Corporate Trustee (Account Category: 4 and Type of Approval: 3)
 */
const ACCOUNT_CATEGORY = {
  INDIVIDUAL: '0',
  JOINT: '1',
  CORPORATE: '2',
  OTHERS: '4'
};

const ACCOUNT_STATUS = {
  ACTIVE: '0',
  SUSPENDED: '1',
  PENDING: '2',
  REJECTED: '4',
  CLOSED: '5'
};

const ACCOUNT_TYPES = {
  INDIVIDUAL: '0',
  JOINT_AND: '1-1',
  JOINT_OR: '1-2',
  CORPORATE: '2',
  CORPORATE_TRUSTEE: '4-3'
};

const ACCOUNT_STATES = {
  INDIVIDUAL: 'individual',
  CORPORATE: 'corporate',
  JOINT_AND: 'joint-and',
  JOINT_OR: 'joint-or',
  CORPORATE_TRUSTEE: 'corporate-trustee',
};

const USER_TYPES = {
  SC_PR: '1',
  FOREIGNER: '2',
  CORPORATE: '3',
  FIN_HOLDER: '4'
}

const USER_ALTERNATIVE_ID_TYPES = {
  NRIC: "1",
  PASSPORT: "2",
  GSA: "3",
  FIN: "4"
}

const USER_RESIDENTIAL_STATUS = {
  FOREIGNER: '0',
  SINGAPORE_PR: '1',
  SINGAPORE_CITIZEN: '2',
}

const CONTACT_REMINDER_ACTION = {
  CONFIRM: 'CONFIRM',
  CONFIRM_LATER: 'CONFIRM_LATER',
  SKIP: 'SKIP'
}

export {
  ACCOUNT_CATEGORY,
  ACCOUNT_STATUS,
  ACCOUNT_TYPES,
  ACCOUNT_STATES,
  USER_TYPES,
  CONTACT_REMINDER_ACTION,
  USER_RESIDENTIAL_STATUS,
  USER_ALTERNATIVE_ID_TYPES
};
