import { withInitDOM } from 'sgx-base-code';

/**
 * A custom HTML element used as a decorator to set row background color
 * @module sgx-table-decorator-row-background
 * @type {HTMLElement}
 */

class SgxTableDecoratorRowBackground extends HTMLElement {
  initDOM() {
    this.style.display = 'none';
  }

  connectedCallback() {}

  decorate(decoratorObject) {
    const { cellToDecorate, isEven } = decoratorObject.decoratorConfig;
    const cellId = cellToDecorate;
    const rowData = decoratorObject.rowData;
    const rowElem = decoratorObject.element;
    const cell = rowElem.querySelector(`[data-column-id = ${cellId}]`);

    if (typeof isEven === 'function' && isEven(rowData)) {
      cell.parentElement.dataset.even = true;
      delete cell.parentElement.dataset.odd;
    } else {
      cell.parentElement.dataset.odd = true;
      delete cell.parentElement.dataset.even;
    }
  }
}

customElements.define('sgx-table-decorator-row-background', withInitDOM(SgxTableDecoratorRowBackground));
const securitiesDecorator = document.createElement('sgx-table-decorator-row-background');
securitiesDecorator.classList.add('sgx-table-decorator-row-background');
securitiesDecorator.classList.add('sgx-base-text-caption');

document.body.appendChild(securitiesDecorator);
