import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-notifications-sms.html';
import { PSN_SOFT_COPY_STATUS } from '../../services/user-preferences-service/src/user-preferences-service-constants';

/**
 * SMS notifications settings widget.
 */
class NotificationsSmsWidget extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-notifications-sms');
    this._smsNotificationsFieldset = this.querySelector('.widget-notifications-sms-fieldset');
    this._title = this.querySelector('.widget-notifications-sms-title');
    this._settlementInput = this.querySelector('.widget-notifications-sms-settlement');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  setData(preferences) {
    if (!!preferences) {
      this._settlementInput.setValue(preferences.psnServiceStatus === PSN_SOFT_COPY_STATUS.CHECKED_SUB_SC);
    }
  }
}

customElements.define('widget-settings-notifications-sms', withInitDOM(NotificationsSmsWidget));
