import {withInitDOM} from 'sgx-base-code';
import CorporateActionsService from 'services/corporate-actions-service';
import DateService from 'sgx-date-time-service';
import ConfigService from '@sgx/sgx-config-service';
import {tableConfig, constants} from './widget-portfolio-rights-config';
import tmpl from './widget-portfolio-rights.html';
import i18n from 'sgx-localisation-service';

import { Big } from '../../utils/big-number-util';

const {
  DEFAULT_TABLE_HEIGHT,
  TABLE_ROW_HEIGHT,
  TABLE_TOOLBAR_AND_HEADER_HEIGHT
} = constants;

class WidgetPortfolioRights extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.classList.add('widget-portfolio-rights');
    this.appendChild(tmpl.getNode());
    this._table = this.querySelector('.w-portfolio-rights-table');
    this._loader = this.querySelector('.w-portfolio-rights-loader');
    this._alertBar = this.querySelector('.w-portfolio-rights-header');
    this._rightsPaymentDialog = this.querySelector('widget-rights-payment-dialog');

    this._table.setConfig(tableConfig);
    this._alertBar.setData({
      status: 'informational',
      text:  i18n.getTranslation('widget-portfolio-rights.header'),
      background: false
    })
    this._dialog = this.querySelector('widget-rights-dialog');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  async setData({accountId}) {
    if (this._accountId !== accountId) {
      this._loader.show({status: 'loading'});
      try {
        const status = await CorporateActionsService.status();
        const caList = await CorporateActionsService.getCorporateActionsList()
        const rightsEventsMap = {};
        let rightsEvents = caList.filter(caEvent => {
          if (typeof caEvent.eRightsCARef !== 'undefined' && CorporateActionsService.getActivePaymentStatus({
            currentDateTime: status.timestamp,
            paymentDateTime: caEvent.paymentDate
          })) {
            rightsEventsMap[caEvent.eRightsCARef] = caEvent;
            return caEvent;
          }
        });
        rightsEvents = rightsEvents.map(caEvent => CorporateActionsService.getRights({
          accountId,
          corporateActionRef: caEvent.eRightsCARef
        }));
        const rights = await Promise.all(rightsEvents);
        this._setupTableData({rights, rightsEventsMap, accountId});
        this._loader.hide();
      } catch (_) {
        this._loader.show({status: 'error'});
      }
    } else {
      this._table.recalculateSize();
    }
  }

  _setupTableData({rights, rightsEventsMap, accountId}) {
    let rightsApplied = [];
    rights.forEach(event => {
      if (event.length !== 0) {
        rightsApplied = [...rightsApplied, ...event];
      }
    });
    rightsApplied = rightsApplied.filter(right => right.instructionStatus !== 'ISSUER_REJECTED');
    let tableData = [];
    rightsApplied.forEach((right) => {
      const eventRef = rightsEventsMap[right.corporateActionRef];

      let amountPayable = right.rightsPaymentCurrency !== null
        ? `${right.rightsPaymentCurrency} ${this._getAmountWith2Decimals(right.rightsAmountInPaymentCurrency)}`
        : i18n.getTranslation('widget-portfolio-rights.empty-data');

      let amountPaid = right.totalAmountPaidCurrency !== null
        ? `${right.totalAmountPaidCurrency} ${this._getAmountWith2Decimals(right.totalAmountPaid)}`
        : i18n.getTranslation('widget-portfolio-rights.not-paid');

      if ([0, 1, 2].includes((right.instructionPaymentMode) && right.instructionStatus === 'VERIFICATION_IN_PROGRESS') || right.totalAmountPaid === 0) {
        amountPaid = '-';
      }

      const rightsApplied = right.instructedQuantity;
      const isNonIP = [0, 1, 2, 3].includes(right.instructionPaymentMode)
      amountPayable = isNonIP ? '-' : amountPayable;

      const referenceNumber = isNonIP ? '-' : (right.refId || '-');
      const instructionPaymentMode = (right.instructionPaymentMode !== null && right.instructionPaymentMode !== undefined)
        ? i18n.getTranslation(`widget-portfolio-rights.paymentMode.${right.instructionPaymentMode}`) : null;
      const paymentMode = instructionPaymentMode || right.rightsPaymentMode;
      let paymentStatus = right.instructionStatus;

      if(right.instructionPaymentMode === 0 && ['REJECTED', 'CANCELLED'].includes(right.instructionStatus)){
        paymentStatus = 'TO_BE_REFUNDED_BY_BANK';
      }

      if(right.instructionPaymentMode === 4 && ['CANCELLED'].includes(right.instructionStatus)){
        paymentStatus = 'PENDING_REFUND';
      }

      if ([1, 2, 3].includes(right.instructionPaymentMode) && ['REJECTED', 'CANCELLED'].includes(right.instructionStatus)) {
        paymentStatus = 'PENDING_REFUND';
      }

      let submissionStatus = right.instructionStatus ? i18n.getTranslation(`widget-portfolio-rights.submission-status.${right.instructionStatus}`) : '-';

      if (!isNonIP && right.instructionStatus === 'CANCELLED') {
        submissionStatus = i18n.getTranslation(`widget-portfolio-rights.submission-status.UNSUCCESSFUL`)
      }

      tableData.push({
        dateReceived: right.createdDate === null ? '-' : right.createdDate,
        security: {
          label: eventRef.name,
          link: `${ConfigService.links.INVESTORS_PORTAL_SECURITIES}?security=${eventRef.instrumentID}`
        },
        rightsApplied,
        amountPayable: {
          label: amountPayable,
          isLink: !!right.rightsAmountInPaymentCurrency,
          data: {
            rightsApplied,
            txnFee: right.txnFeeInPaymentCurrency,
            gst: right.taxInPaymentCurrency,
            issuePrice: eventRef.issuePrice,
            total: amountPayable,
            totalAmount: right.rightsAmountInPaymentCurrency,
            currency: right.rightsPaymentCurrency,
          }
        },
        referenceNumber,
        amountPaid,
        paymentMode,
        paymentStatus: {
          label: paymentStatus ? i18n.getTranslation(`widget-portfolio-rights.payment-status.${paymentStatus}`) : '',
          isLink: (right.instructionStatus === 'PENDING_PAYMENT')
            && right.rightsPaymentMode
            && (right.instructionPaymentMode === null || right.instructionPaymentMode == 4), // payment mode is digital
          data: {
            referenceNumber,
            paymentMode,
            rightsPaymentMode: right.rightsPaymentMode,
            rightsApplied,
            issuePrice: eventRef.issuePrice,
            txnFee: right.txnFeeInPaymentCurrency,
            gst: right.taxInPaymentCurrency,
            total: amountPayable,
            totalAmount: right.rightsAmountInPaymentCurrency,
            currency: right.rightsPaymentCurrency,
            accountNumber: this._accountId,
            expiryDateTime: right.expiredDateTime,
            accountId
          }
        },
        submissionStatus
      });
    });
    tableData = tableData.sort((a, b) => {
      return DateService(b.dateReceived).toDate() - DateService(a.dateReceived).toDate();
    });
    tableData = tableData.map((item, id) => {
      return {
        id: id + 1,
        ...item
      }
    })


    this._table.setData(tableData);
    this._table.recalculateSize();
  }

  _getAmountWith2Decimals(val) {
    if (isNaN(Number(val)) || val === null || val === undefined) {
      return 'NA';
    } else {
      return Big(val).toFormat(2);
    }
  }

}

customElements.define('widget-portfolio-rights', withInitDOM(WidgetPortfolioRights));
