import UserPreferencesServiceInstance from './user-preferences-service-stub.js';
import BaseService from 'services/base-service';

let instance = null;

/**
 * User preferences service responsible for getting
 * or updating one or many of the following preferences:
 *  - SMS OTP
 *  - Marketing materials agreement
 *  - Preferred unit costs
 *  - Preferred notifications settings
 *
 * @module user-preferences-service
 */
class UserPreferencesService extends BaseService {
  constructor() {
    super();
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  getUserNotifications() {
    return this._authFetch(this._endpoints.USER_PREFERENCES_NOTIFICATION_READ)
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  }

  /**
   * Update selected user preferences.
   * @param {Object} preferences The names and payloads of the selected preferences available to call:
   *  - sms-otp
   *  - marketing-agreement
   *  - unit-costs
   *  - notifications
   * @return {Promise<Object>} Returns an object containing user preferences.
   */
  updateUserPreferences(preferences) {
    return this._authFetchText(this._endpoints.USER_PREFERENCES_NOTIFICATION_UPDATE, {
      method: 'PUT',
      body: JSON.stringify(preferences),
      headers: {
        'content-type': 'application/json'
      }
    });
  }

  /**
   * Update selected user portfolio.
   * @param {String} accountId the CDP account ID.
   * @param {Array<Object>} config The names and payloads of the selected preferences available to call:
   * @param {Object} config.payload The payload to be sent.
   * @return {Promise<Object>} Returns an object containing user preferences.
   */
  updateUserPortfolioCost(accountId, config) {
    // TODO: Remove stubs once endpoint is ready
    return UserPreferencesServiceInstance.updateUserPreferences(config);

    // const preferences = config.map(preference => this._authFetch(`/preferences/${preference.name}`, {
    //     method: 'PUT',
    //     payload: preference.payload,
    //     headers: {
    //       [this._requestHeaders.accountId]: accountId
    //     }
    //   })
    // );

    // return Promise.all(preferences.catch(error => error)).then(response => response)
  }

  /**
   * Gets a user's favorite securities
   *
   * @return {Promise<Array>} a promise
   */
  getFavoriteSecurities() {
    return this._authFetch(this._endpoints.WATCHLIST_READ_UPDATE)
      .then(response => (response.data && response.data.watchlist) || [])
      .catch(error => Promise.reject(error));
  }

  /**
   * Updates the entire list of user's favorite securities
   *
   * @param {array<string>} counters list of the favorite security codes
   * @return {Promise} a promise
   */
  updateFavoriteSecurities(counters) {
    const body = JSON.stringify({
      counter: counters
    });

    return this._authFetch(this._endpoints.WATCHLIST_READ_UPDATE, {
      method: 'POST',
      body
    });
  }
}

export default new UserPreferencesService();
