module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-security-password\">\n  <h3 class=\"widget-security-password-title\" data-i18n=\"app.widget-settings-security-password.title\"></h3>\n\n  <div class=\"widget-security-password-container\">\n\n    <div class=\"widget-security-password-row--top\">\n      <cmp-list-row-prompt class=\"widget-security-password-prompt\"></cmp-list-row-prompt>\n    </div>\n\n    <div class=\"widget-security-password-row--bottom\">\n      <sgx-status-indicator class=\"widget-security-password-indicator\"></sgx-status-indicator>\n      <div class=\"widget-security-password-status-container\">\n        <p class=\"widget-security-password-status sgx-base-text-body-14\"></p>\n        <span class=\"widget-security-password-icon sgx-icon\"></span>\n      </div>\n    </div>\n\n  </div>\n\n  <widget-settings-security-password-dialog></widget-settings-security-password-dialog>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()