import { withInitDOM } from 'sgx-base-code';
import ConfigService from 'sgx-config-service';
import StockfactsAggregator from 'aggregators/stockfacts-aggregator';
import { tableConfig } from './widget-stock-screener-config';
import tmpl from './widget-stock-screener.html';
import { addCustomToolbar } from 'utils/table-util';
import { URLUtils } from 'sgx-base-code';
import DeviceService from 'sgx-device-service';
import StoreRegistry from 'stores/store-registry';
import SGXInViewport from 'sgx-in-viewport';
import { fromEvent } from 'rxjs';
import sgxAnalyticsService from '@sgx/sgx-analytics-service';
import { get } from 'lodash';
import i18n from '@sgx/sgx-localisation-service';


/**
 * Stock screener widget.
 */
class WidgetStockScreener extends SGXInViewport {
  constructor() {
    super();
    this._allRangeFilters = {};
    this._onShowSecurityDetail = this._onShowSecurityDetail.bind(this);
  }

  _sendGAEvent() {
    const cdpSessionData = StoreRegistry.cdpSession;
    const { userId } = cdpSessionData.getData();
    const eventLabel = userId ? 'Stock Screener - Post Login' : 'Stock Screener';
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    sgxAnalyticsService.sendEvent(
      eventCategory,
      eventLabel,
      'widget-viewed',
    );
  }

  _checkAndShowAlert() {
    const isInMaintenance = get(ConfigService, 'maintenance.STOCK_SCREENER');

    if (isInMaintenance) {
      const pageHeader = document.querySelector('#page-header-container');
      const maintenanceBanner = document.createElement('div');
      maintenanceBanner.innerHTML = i18n.getTranslation('app.widget-stock-screener.maintenance.title_html');
      maintenanceBanner.className = 'sgx-investors-maintenance-banner sgx-base-text-body-12';
      if (DeviceService.isDesktop()) {
        pageHeader.insertBefore(maintenanceBanner, pageHeader.firstChild);
      } else {
        pageHeader.appendChild(maintenanceBanner);
      }
    }
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-stock-screener');
    this._dialog = this.querySelector('widget-stock-screener-details-dialog');
    this._table = this.querySelector('.stock-screener-table');
    this._table.setConfig(tableConfig);
    this._toolbar = document.createElement('widget-stock-screener-toolbar');
    addCustomToolbar(this._table, this._toolbar);
    super.initDOM();
    this._checkAndShowAlert();
  }

  connectedCallback() {
    this._table.addEventListener('ROW_SECURITY_CLICKED', this._onShowSecurityDetail);
    fromEvent(this, 'onViewPortEnter').subscribe(event => this._sendGAEvent(event));
  }

  disconnectedCallback() {
    this._table.removeEventListener('ROW_SECURITY_CLICKED', this._onShowSecurityDetail);
  }

  setData() {
    this._table.classList.add('loading');
    StockfactsAggregator.getScreenerData()
      .then(stocksData => {
        this._toolbar.setConfig({
          table: this._table,
          model: this._table.getDataModel(),
          data: stocksData
        })
        this._table.setData(StockfactsAggregator.formatTableData(stocksData));
        // This is because there currently is a race condition that sometimes messes with the creation of rows
        this._table.recalculateSize();
        this._table.classList.remove('loading');
      })
      .then(() => { // Hook to set search state via query params
        const search = URLUtils.getQueryParams(window.location.search).search;
        if (typeof search !== 'undefined') {
          this._setSearchState(search);
        }
      })
      .catch((err) => {
        console.error(err);
        this._table.classList.remove('loading');
        this._table.classList.add('error');
      });
  }

  _setSearchState(search) {
    const searchButton = document.querySelector('.widget-stock-screener-toolbar-search');
    const searchInput = document.querySelector('.sgx-data-model-tool-search-input');
    searchButton.click();
    searchInput.setValue(search);
    searchInput.dispatchEvent(new Event(DeviceService.isIe() ? 'keydown' : 'input')); // IE only works keydown event, not input
    window.history.replaceState({}, null, window.location.pathname);
  }

  _onShowSecurityDetail(e) {
    const {rowId} = e.detail;
    this._table.getDataModel().getRowData(rowId)
      .then(data => this._showDialog(data));
  }

  _showDialog(data) {
    const baseUrl = ConfigService.links.INVESTORS_SECURITY_DETAILS_WITH_TYPE;
    const dialogConfig = {
      data,
      actions: {
        close: _ => this._dialog.hide(),
        open: _ => window.open(`${baseUrl}/${data.type}/${data.nc}`, '_blank')
      }
    };
    this._dialog.show(dialogConfig)
      .catch(error => console.log(error));
  }
}

customElements.define('widget-stock-screener', withInitDOM(WidgetStockScreener));
