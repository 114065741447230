module.exports = (function() {
    var templateString = module.exports = "<template id=\"widget-stock-quick-search\">\n  <div class=\"widget-stock-quick-search--container\">\n    <h3 data-i18n=\"app.widget-stock-quick-search.title\"></h3>\n    <form class=\"widget-stock-quick-search-form\">\n      <div class=\"widget-stock-quick-search-input-container\">\n        <sgx-input-text name=\"stock-quick-search\" class=\"widget-stock-quick-search-input\"\n          data-i18n=\"[placeholder]app.widget-stock-quick-search.input-placeholder\"></sgx-input-text>\n        <span class=\"widget-stock-quick-search-icon sgx-icon\"></span>\n      </div>\n      <button class=\"widget-stock-quick-search-button sgx-button--primary\"\n        data-i18n=\"app.widget-stock-quick-search.submit-button\"></button>\n    </form>\n  </div>\n</template>";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()