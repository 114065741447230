import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-securities-market-performance.html';
import StoreRegistry from 'stores/store-registry';
import ConfigService from 'sgx-config-service';
import DateService from 'sgx-date-time-service';
import SecuritiesService from 'sgx-securities-service';
import i18n from 'sgx-localisation-service';
import { widgetConfig } from './config/widget-config';
import DateUtil from 'utils/date-util';
import { abbreviateNumber, toFixed, formatThousands } from 'utils/price-util';
import StatusIndicatorUtil from 'utils/status-indicator-util';
import SGXInViewport from 'sgx-in-viewport';
import { fromEvent } from 'rxjs';
import SgxAnalyticsService from 'sgx-analytics-service';
import { get } from 'lodash';

/**
 * A widget rendering a table of securities market performance data
 * @module widget-securities-market-performance
 * @type {HTMLElement}
 */
class SecuritiesMarketPerformance extends SGXInViewport {
  constructor() {
    super();
    this._updateList = this._updateList.bind(this);
    this._loadMarketData = this._loadMarketData.bind(this);
  }

  _sendGAEvent(event) {
    const parentElement = this.closest('[data-analytics-category]');
    const eventCategory = get(parentElement, 'dataset.analyticsCategory') || document.title;
    SgxAnalyticsService.sendEvent(
      eventCategory,
      `Market Performance: ${this._tabs.getSelectedStateData()}-${this._criteria}`,
      'widget-viewed'
    );
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._tabs = this.querySelector('.market-performance-state-tabs');
    this._indicator = this.querySelector('.market-performance-loader');
    this._dailyVolume = this.querySelector('.market-performance-today-volume');
    this._dailyValue = this.querySelector('.market-performance-today-value');
    this._overallDailyVolume = this.querySelector('.market-performance-today-volume-overall');
    this._overallDailyValue = this.querySelector('.market-performance-today-value-overall');
    this._listSort = this.querySelector('.market-performance-table-sort');
    this._link = this.querySelector('.market-performance-link');
    this._list = this.querySelector('.market-performance-list');
    this._timestamp = this.querySelector('.market-performance-timestamp');
    super.initDOM();
  }

  connectedCallback() {
    this._tabs.addEventListener('stateChange', this._loadMarketData);
    this._listSort.addEventListener('change', this._updateList);

    fromEvent(this, 'onViewPortEnter').subscribe(event => this._sendGAEvent(event));

    this._store = StoreRegistry[this.dataset.store];
    if (!this.dataset.store) {
      console.warn('Securities market performance widgets store unset.');
    }
    const data = this._store.getData();
    let {attribute, criteria} = data;
    if (!criteria || !attribute) {
      data.criteria = this.dataset.criteria || 'advancers';
      data.attribute = this.dataset.attribute || 'v';
      this._store.setData(data);
      this._store.saveData();
      criteria = data.criteria;
      attribute = data.attribute;
    }
    widgetConfig.criteria = criteria;
    widgetConfig.attribute = attribute;

    this._list.setConfig({
      'rowElementName': 'cmp-list-row-market-performance',
      'rowClickedCallback': rowData => {
        if (this._router) {
          this._router.navigateToURL(`${ConfigService.links.INVESTORS_PORTAL_SECURITIES}?security=${rowData.nc}`);
        }
      }
    });
    this.setData(widgetConfig);
  }

  disconnectedCallback() {
    this._tabs.removeEventListener('stateChange', this._loadMarketData);
    this._listSort.removeEventListener('change', this._updateList);
  }

  setData(data) {
    this._itemsToShow = data.itemsToShow;
    this._criteria = data.criteria;
    this._attribute = data.attribute;
    this._setTableSortOptions(data.options.labels, data.options.values, `${this._criteria}_${this._attribute}`);
    this._link.dataset.analyticsLabel = ConfigService.links.INVESTORS_PORTAL_SECURITIES;
    this._link.textContent = i18n.getTranslation('app.widget-securities-market-performance.link.label');
    this._link.href = ConfigService.links.INVESTORS_PORTAL_SECURITIES;

    this._tabs.setConfig();
    this._tabs.setState({states: data.tabs.map(this._mapTab)});
    this._updateList();
  }

  _setTableSortOptions(labelKey, values, tableSortValue) {
    // Advancers by value, advancers by volume, advancers by change, decliners by change
    const labelList = i18n.getTranslation(labelKey, {returnObjects: true});
    const options = values.map((val, idx) => {
      return {
        label: labelList[idx],
        value: val
      };
    });
    this._listSort.setOptions(options);
    this._listSort.setValue(tableSortValue);
  }

  _mapTab(item) {
    return {label: i18n.getTranslation(`app.widget-securities-market-performance.tabs.${item}`), data: item};
  }

  _updateList() {
    const securityType = this._tabs.getSelectedStateData();
    const tableSortValues = this._listSort.value.split('_');
    const criteria = tableSortValues[0];
    const attribute = tableSortValues[1];

    if (this._criteria !== criteria || this._attribute !== attribute) {
      const data = this._store.getData();
      this._attribute = attribute;
      this._criteria = criteria;
      data.attribute = attribute;
      data.criteria = criteria;
      this._store.setData(data);
      this._store.saveData();
    }

    StatusIndicatorUtil.displayLoader(this._indicator);

    Promise.all([
      SecuritiesService.getTopSecuritiesData({
        'category': securityType,
        'criteria': criteria,
        'size': this._itemsToShow,
        'attribute': attribute
      }),
      SecuritiesService.getAggregateData('volume', securityType),
      SecuritiesService.getAggregateData('value', securityType),
      SecuritiesService.getAggregateData(),
    ])
      .then(([data, sectionVolume, sectionValue, overall]) => {
        this._dailyVolume.textContent = abbreviateNumber(sectionVolume, 1);
        this._dailyValue.textContent = `S$${abbreviateNumber(sectionValue, 2)}`;
        this._overallDailyVolume.textContent = abbreviateNumber((overall || {}).volume, 1);
        this._overallDailyValue.textContent = `S$${abbreviateNumber((overall || {}).value, 2)}`;
        if (!data || !data.length) {
          StatusIndicatorUtil.displayNoData(this._indicator);
          return;
        }
        const listData = data.sort((a, b) => a[attribute] === b[attribute] ? a.n.localeCompare(b.n) : 0)
          .map(item => {
            const sign = item.c < 0 ? '' : '+';
            const percentChange = item.c === 0 ? '-' : `${sign}${item.c}`;
            item.lt = `${abbreviateNumber(item.lt, 3)} (${percentChange})`;
            item.vl = formatThousands(item.vl, 1);
            item.v = toFixed(item.v, 2);
            item.link = `${ConfigService.links.INVESTORS_PORTAL_SECURITIES}?security=${item.nc}`;
            return item;
          });
        this._list.setData(listData)
        StatusIndicatorUtil.hideStatusIndicator(this._indicator);
        this._updateTime();
      })
      .catch(error => {
        console.error(`Failed to set table data: ${error}`);
        StatusIndicatorUtil.displayNoData(this._indicator);
      });
  }

  _loadMarketData() {
    StatusIndicatorUtil.displayLoader(this._indicator);
    this._updateList();
  }

  _updateTime() {
    var time = new DateService();
    this._timestamp.textContent = DateUtil.formatTimestamp(time);
  }
}

customElements.define('widget-securities-market-performance', withInitDOM(SecuritiesMarketPerformance));
