const ACCOUNT_LINKAGE_STATUS = {
  APPROVED: '1',
  APPROVED_DIFFERENT_ADDRESS: '2',
  PENDING: '3',
  DISAPPROVED: '4',
  SUSPENDED: '5',
  CLOSED: '6',
  FORCED_APPROVED: '7',
  NO_GSA_LINKED: '8'
};

const ACCOUNT_POSITION_STATUS = {
  0: 'Active',
  1: 'Suspended',
  2: 'Closed'
};

export {
  ACCOUNT_LINKAGE_STATUS,
  ACCOUNT_POSITION_STATUS
};
