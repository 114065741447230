import i18n from 'sgx-localisation-service';
import DeviceService from 'sgx-device-service';
import DateService from 'sgx-date-time-service';
import { Big } from 'utils/big-number-util';

import { verticallyAndHorizontallyCenterTitle, CHART_COLORS } from 'utils/chart-util';

const exportFormatThousands = (value, decimal, allowZero, defaultValue = 'N/A') => {
  if (isNaN(value) || (value == 0 && !allowZero)) {
    return defaultValue;
  }
  return Big(value).toFormat(decimal);
};

const constants = {
  FULL_DATE_FORMAT: i18n.getTranslation('app.shared-text.date-format-full-date'),
  EXPORT_NAME: {
    PORTFOLIO: 'Portfolio',
    SBL: 'SecuritiesOnLoan'
  },
  MARKET_VALUE_SUB_TOTAL: i18n.getTranslation('app.widget-portfolio-overview.labels.sub-total-market-value'),
  PROFIT_LOSS_SUB_TOTAL: i18n.getTranslation('app.widget-portfolio-overview.labels.sub-total-profit-loss'),
  DEFAULT_TABLE_HEIGHT: '320px',
  TABLE_TOOLBAR_AND_HEADER_HEIGHT: 86,
  TABLE_ROW_HEIGHT: 32,
  EMPTY_INDICATOR_TITLE: i18n.getTranslation('sgx-table.messages.no-filter-title')
};
const getLabelFormatter = function (width = "300px") {
  return `<div class="legend-item-content" style="width:${width};">
    <span class="legend-item-content-item">${this.name}</span>
    <span class="legend-item-content-item">${this.description}</span>
  </div>`;
};

const getChartConfig = (data, title) => {
  return {
    navigator: { enabled: false },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    tooltip: {
      headerFormat: null,
      followPointer: false,
      pointFormat: '{point.name}: <b>{point.y}%</b><br/>',
      valueDecimals: 1
    },
    chart: {
      events: {
        load: verticallyAndHorizontallyCenterTitle,
        redraw: verticallyAndHorizontallyCenterTitle
      },
      animation: false,
      height: 280,
      type: 'pie'
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      symbolRadius: 0,
      symbolWidth: 14,
      symbolHeight: 14,
      itemMarginTop: 4,
      itemMarginBottom: 10,
      itemStyle: {
        cursor: 'default'
      },
      labelFormatter() {
        return getLabelFormatter.call(this);
      },
      maxHeight: 165,
      useHTML: true
    },
    title: {
      verticalAlign: 'middle',
      floating: true,
      text: title
    },
    plotOptions: {
      pie: {
        colors: CHART_COLORS,
        size: 200,
        innerSize: '65%',
        showInLegend: true,
        stickyTracking: false,
        point: {
          events: {
            legendItemClick: (event) => {
              event.preventDefault();
            }
          }
        },
        dataLabels: {
          connectorShape: 'straight',
          enabled: false,
          formatter: function() {
            return this.point.label;
          }
        }
      },
      series: {
        animation: false
      }
    },
    series: [{
      data
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 586
        },
        chartOptions: {
          chart: {
            height: 480
          },
          plotOptions: {
            pie: {
              center: [null, 90]
            }
          },
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            labelFormatter() {
              return getLabelFormatter.call(this);
            },
            y: -40 + (data.length <= 5 ? -22: 0), //To align legend
            x: -15,
            navigation: {
              arrowSize: 17,
              style: {
                fontSize: '14px'
              }
            }
          }
        }
      }, {
        condition: {
          callback: function() {
            return DeviceService.getResolution() === 'large' && this.chartWidth > 585;
          }
        },
        chartOptions: {
          chart: {
            marginLeft: 20
          },
          legend: {
            labelFormatter() {
              return getLabelFormatter.call(this, '230px');
            }
          }
        }
      }, {
        condition: {
          callback: function() {
            return DeviceService.getResolution() === 'large' && this.chartWidth > 685;
          }
        },
        chartOptions: {
          legend: {
            labelFormatter() {
              return getLabelFormatter.call(this);
            }
          }
        }
      }]
    }
  };
};

const blbDefaultStateData = {
  order: ['rowNumber', 'security', 'stockCode', 'freeBalance', 'blockedBalance', 'totalBalance', 'currency', 'marketPrice', 'unitCost', 'marketValue', 'profit', 'corporateActions'],
  columns: {
    rowNumber: {
      show: true
    },
    security: {
      show: true
    },
    stockCode: {
      show: true
    },
    freeBalance: {
      show: true
    },
    blockedBalance: {
      show: true
    },
    totalBalance: {
      show: true
    },
    currency: {
      show: true
    },
    marketPrice: {
      show: true
    },
    unitCost: {
      show: true
    },
    marketValue: {
      show: true
    },
    profit: {
      show: true
    },
    corporateActions: {
      show: true
    }
  },
  filters: {
    currency: {
      type: 'text',
      value: 'SGD',
      valueKey: 'label',
      method: 'equals'
    }
  },
  sorts: {
    currency: 'asc',
    security: 'asc'
  }
};

const blbTableConfig = {
  tabs: false,
  states: {
    activeStateId: 0,
    states: [
      {
        label: 'SGD',
        data: blbDefaultStateData,
        editable: false
      }
    ]
  },
  options: {
    downloadData: false, // show download button
    columnsConfiguration: false, // show column configure button to hide/show and reorder columns
    filterable: false, // enable filtering
    sortable: true, // enable sorting
    localise: false, // enable localisation
    reorderable: false, // enable column reordering
    resizable: true,
    autoWidth: true, // enable table column width expanding to fit container
    exportDataFileName: constants.EXPORT_NAME.PORTFOLIO
  },
  // column configuration, shared with data model
  columns: {
    rowNumber: {
      autoWidth: false,
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.number.label'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      filter: false,
      sort: false,
      export: false
    },
    security: {
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.security-name.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.security-name.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'link',
        newTab: true,
        className: 'security-link-cell',
        disableLinkIfEmpty: true
      },
      filter: false,
      sort: {
        type: 'text',
        valueKey: 'label'
      }
    },
    stockCode: {
      minWidth: 100,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.stock-code.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.stock-code.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'text',
        defaultValue: 'N/A'
      },
      filter: false,
      sort: false
    },
    freeBalance: {
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.free.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.free.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        decimalPlaces: 0,
        replaceZero: false
      },
      filter: false,
      sort: false,
      export: value => exportFormatThousands(value, 0, true)
    },
    blockedBalance: {
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.blocked.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.blocked.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'blocked-balance',
        replaceZero: false
      },
      filter: false,
      sort: false,
      export: value => exportFormatThousands(value, 0, true)
    },
    totalBalance: {
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.total-balance.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.total-balance.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        decimalPlaces: 0,
        replaceZero: false
      },
      filter: false,
      sort: false,
      export: value => exportFormatThousands(value, 0, true)
    },
    currency: {
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.currency.label'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'text',
        valueKey: 'label'
      },
      filter: true,
      sort: {
        type: 'text',
        valueKey: 'order'
      },
      export: value => value === '1' ? 'SGD' : value
    },
    marketPrice: {
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.market-price.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.market-price.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        defaultValue: 'N/A',
        decimalPlaces: 3,
        padZeros: true,
        replaceZero: false
      },
      filter: false,
      sort: {
        type: 'number'
      },
      export: value => exportFormatThousands(value, 3, true)
    },
    unitCost: {
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.unit-cost.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.unit-cost.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'input-button',
        formatThousands: true,
        decimalPlaces: 3,
        defaultValue: '',
        replaceZero: false
      },
      filter: false,
      sort: false,
      export: value => (!!+value || value === 0) && exportFormatThousands(value, 3, true, '') || ''
    },
    marketValue: {
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.market-value.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.market-value.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        defaultValue: 'N/A',
        decimalPlaces: 2,
        padZeros: true,
        replaceZero: false
      },
      filter: false,
      sort: {
        type: 'number'
      },
      export: value => exportFormatThousands(value, 2, true)
    },
    profit: {
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.profit-loss.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.profit-loss.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'big-number',
        addSign: true,
        defaultValue: 'N/A',
        decimalPlaces: 2,
        padZeros: true,
        replaceZero: false
      },
      filter: false,
      sort: {
        type: 'number'
      },
      export: value => exportFormatThousands(value, 2, true)
    },
    corporateActions: {
      autoWidth: true,
      minWidth: 100,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.corporate-actions.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.corporate-actions.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'corporate-action-links',
        className: 'corporate-actions-link-cell'
      },
      filter: false,
      sort: false,
      export: values => {
        let finalString = '';
        values.forEach(value => {
          finalString += `${value.label}; `
        });

        if (!values.length) {
          finalString = 'N/A';
        }
        return finalString;
      }
    }
  },
  decorators: {
    rows: [
      {
        name: 'price-movement-color',
        config: {
          cellToDecorate: ['profit'],
          cellMovement: 'profit',
          colors: {
            positive: ['#74ac00', null], // Equivalent of $sgx-theme-v2-functional-02
            negative: ['#e60000', null], // Equivalent of $sgx-theme-v2-functional-01
            neutral: ['', null] // Default to already applied value
          }
        }
      },
      {
        name: 'input-button',
        config: {
          cellToDecorate: ['unitCost'],
          shouldHide: rowData => {
            return !rowData.stockCode || rowData.stockCode === 'N/A';
          }
        }
      },
      {
        name: 'blocked-balance',
        config: {
          cellToDecorate: ['blockedBalance']
        }
      },
      {
        name: 'row-number',
        config: {
          cellToDecorate: ['rowNumber']
        }
      }
    ]
  },
  layout: {
    desktop: {
      type: 'infinite',
      rowHeight: 32,
      horizontalScrollStep: 96,
      verticalScrollStep: 96,
      scrollbar: true,
      showAllRows: false
    },
    mobile: {
      type: 'paged',
      cellPerRow: [2, 2, 2, 2, 2, 2],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    },
    tablet: {
      type: 'paged',
      cellPerRow: [2, 4, 4, 4],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    }
  }
};

const sblDefaultStateData = {
  order: ['rowNumber', 'security', 'stockCode', 'status', 'effectiveDate', 'qty', 'lenderRate'],
  columns: {
    rowNumber: {
      show: true
    },
    security: {
      show: true
    },
    stockCode: {
      show: true
    },
    status: {
      show: true
    },
    effectiveDate: {
      show: true
    },
    qty: {
      show: true
    },
    lenderRate: {
      show: true
    }
  },
  filters: {},
  sorts: {}
};

const sblTableConfig = {
  states: {
    activeStateId: 0,
    states: [
      {
        label: 'All',
        data: sblDefaultStateData,
        editable: false
      }
    ]
  },
  options: {
    downloadData: false, // show download button
    columnsConfiguration: false, // show column configure button to hide/show and reorder columns
    filterable: false, // enable filtering
    sortable: true, // enable sorting
    localise: false, // enable localisation
    reorderable: false, // enable column reordering
    resizable: true,
    autoWidth: true, // enable table column width expanding to fit container
    exportDataFileName: constants.EXPORT_NAME.SBL
  },
  // column configuration, shared with data model
  columns: {
    rowNumber: {
      autoWidth: false,
      minWidth: 40,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.number.label'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'text'
      },
      filter: false,
      sort: false,
      export: false
    },
    security: {
      minWidth: 100,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.security-name.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.security-name.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'link',
        newTab: true,
        className: 'security-link-cell'
      },
      filter: false,
      sort: {
        type: 'text',
        valueKey: 'label'
      }
    },
    stockCode: {
      minWidth: 70,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.stock-code.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.stock-code.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'text',
        defaultValue: 'N/A'
      },
      filter: false,
      sort: {
        type: 'text'
      }
    },
    status: {
      minWidth: 100,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.status.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.status.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'text',
      },
      filter: false,
      sort: {
        type: 'text'
      }
    },
    effectiveDate: {
      minWidth: 100,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.effective-date.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.effective-date.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'date',
        format: constants.FULL_DATE_FORMAT,
        defaultValue: 'N/A'
      },
      filter: false,
      sort: {
        type: 'text'
      },
      export: value => value && DateService(value).format(constants.FULL_DATE_FORMAT) || 'N/A'
    },
    qty: {
      minWidth: 100,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.qty.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.qty.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        decimalPlaces: 0,
        replaceZero: false
      },
      filter: false,
      sort: {
        type: 'number'
      },
      export: value => exportFormatThousands(value, 0, true)
    },
    lenderRate: {
      minWidth: 100,
      label: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.lender-rate.label'),
      tooltip: i18n.getTranslation('app.widget-portfolio-overview.table.column-name.lender-rate.tooltip'),
      header: {
        type: 'text'
      },
      cell: {
        type: 'number',
        decimalPlaces: 2,
        defaultValue: 'N/A',
        replaceZero: false,
        padZeros: true
      },
      filter: false,
      sort: {
        type: 'number'
      },
      export: value => exportFormatThousands(value, 2, true)
    }
  },
  decorators: {
    rows: [
      {
        name: 'row-number',
        config: {
          cellToDecorate: ['rowNumber']
        }
      }
    ]
  },
  layout: {
    desktop: {
      type: 'infinite',
      rowHeight: 32,
      horizontalScrollStep: 96,
      verticalScrollStep: 96,
      scrollbar: true,
      showAllRows: false
    },
    mobile: {
      type: 'paged',
      cellPerRow: [2, 2, 2, 2],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    },
    tablet: {
      type: 'paged',
      cellPerRow: [2, 5, 5, 5],
      noRowsDisplayedByDefault: 10,
      noRowsToAddStep: 5
    }
  }
};

export {
  exportFormatThousands,
  getChartConfig,
  sblTableConfig,
  blbTableConfig,
  blbDefaultStateData,
  constants
};
