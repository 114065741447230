import { withInitDOM } from 'sgx-base-code';
import StoreRegistry from 'stores/store-registry';
import tmpl from './widget-settings-user-contact.html';
import * as constants from 'services/user-service/src/user-service-constants';
import i18n from 'sgx-localisation-service';
import { get } from 'lodash';
import { showHideEl } from 'utils/form-util';

/**
 * This class is used for adding contact details in user settings (Profile page).
 * Added from `teamplate-user-settings`.
 * @class
 * @extends HTMLElement
 *
 */
export class UserContactWidget extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-user-contact');
    this._mobilePhoneInput = this.querySelector('.details-mobile');
    this._emailInput = this.querySelector('.details-email');
    this._homePhoneInput = this.querySelector('.details-home');
    this._officePhoneInput = this.querySelector('.details-office');
    this._fullName = this.querySelector('.details-fullName');
    this._title = this.querySelector('.details-title');
    this._sessionStore = StoreRegistry.cdpSession;
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  setData(user, account) {
    let { accountType } = this._sessionStore.getData();
    const isCorporateAccount = accountType === constants.ACCOUNT_STATES.CORPORATE;
    const isCorporateTrusteeAccount = accountType === constants.ACCOUNT_STATES.CORPORATE_TRUSTEE;

    this._sessionStore.subscribe(acc => {
      if (accountType !== acc.accountType && acc.accountType) {
        accountType = acc.accountType;
        this.setData(user, account);
      }
    });


    if (!!user) {
      this._fullName.setValue(user.name);
      this._emailInput.setValue(user.email);

      this._mobilePhoneInput.setJsonValue({
        number: get(user, 'otherPhoneNumber.number'),
        code: get(user, 'otherPhoneNumber.countryCode') && get(user, 'otherPhoneNumber.countryCode').replace('+', '')
      });
      this._homePhoneInput.setJsonValue({
        number: get(user, 'phoneNumber.number'),
        code: get(user, 'phoneNumber.countryCode') && get(user, 'phoneNumber.countryCode').replace('+', '')
      });
      this._officePhoneInput.setJsonValue({
        number: get(user, 'officeNumber.number'),
        code: get(user, 'officeNumber.countryCode') && get(user, 'officeNumber.countryCode').replace('+', '')
      });
    }

    if (isCorporateAccount) {
      showHideEl(this._mobilePhoneInput, false);
      showHideEl(this._homePhoneInput, false);
      this._fullName.classList.remove('sgx-hidden');
      this._title.innerText = i18n.getTranslation('app.widget-settings-user-contact.title-corporate')
    }

    if (isCorporateTrusteeAccount) {
      this._fullName.classList.remove('sgx-hidden');
      this._title.innerText = i18n.getTranslation('app.widget-settings-user-contact.title-corporate-trustee')
    }
  }

  updateValidationConfig(validate, config) {
    // Adding custom validation for some special fields
    const PHONE_PATTERN_REGEX = '^$|^\\+[1-9][0-9]*\\s[0-9]+$';

    config.validate.rules['contacts[0]'] = {
      mobilePhoneRule: {
        scope: this._mobilePhoneInput,
        message: (errorMessage) =>  {
          return validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'),
            {
              fieldName: errorMessage
            });
        }
      },
      singaporePhone: {
        mobileOnly: true,
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'),
          {
            fieldName: i18n.getTranslation('app.widget-settings-user-general.inputs.mobilePhone.label')
          })
      }
    };

    config.validate.rules['contacts[1]'] = {
      format: {
        pattern: PHONE_PATTERN_REGEX,
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'),
          {
            fieldName: i18n.getTranslation('app.widget-settings-user-general.inputs.homePhone.label')
          })
      },
      singaporePhone: {
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'),
          {
            fieldName: i18n.getTranslation('app.widget-settings-user-general.inputs.homePhone.label')
          })
      }
    };

    config.validate.rules['contacts[2]'] = {
      format: {
        pattern: PHONE_PATTERN_REGEX,
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'),
          {
            fieldName: i18n.getTranslation('app.widget-settings-user-general.inputs.officePhone.label')
          })
      },
      singaporePhone: {
        message: () => validate.format(i18n.getTranslation('app.shared-text.form-validation.not-valid-w-field-name'),
          {
            fieldName: i18n.getTranslation('app.widget-settings-user-general.inputs.officePhone.label')
          })
      }
    };

    return config;
  }
}

customElements.define('widget-settings-user-contact', withInitDOM(UserContactWidget));
