import SgxDataModelToolBaseProto from '../../sgx-data-model-tool-base';
import tmpl from './sgx-data-model-tool-display-count.html';
import i18n from 'sgx-localisation-service';
import { withInitDOM } from 'sgx-base-code';

/**
 * Display the item count of displayed list data (e.g. "1035 records displayed").
 */
class SgxDataModelToolDisplayCount extends SgxDataModelToolBaseProto {
  constructor() {
    super();
    // Bound methods
    this._onModelUpdate = this._onModelUpdate.bind(this);
  }

  initDOM() {
    this.classList.add('sgx-data-model-tool-display-count');
    this.appendChild(tmpl.getNode());

    // References
    this._count = this.querySelector('.sgx-data-model-tool-display-count__text');
  }

  connectedCallback() {
    this.setCount(0);
  }

  disconnectedCallback() {
    if (this._model) {
      this._model.removeEventListener('update', this._onModelUpdate);
    }
  }

  setConfig({model, isExternal}) {
    this._model = model;
    this._model.addEventListener('update', this._onModelUpdate);
    this._isExternal = isExternal;
  }

  setCount(count, total = 0) {
    if (!!count || count === 0) {
      this._count.textContent = i18n.getTranslation(
        this.dataset.sgxDisplayMode === 'total' ? 'app.shared-text.list-details.header.number-displayed-total' :
          'app.shared-text.list-details.header.number-displayed', { count, total });
    }
  }

  _onModelUpdate(event) {
    switch (event.detail.action) {
      case 'getDataRange':
      case 'setState':
      case 'updateData':
        this.setCount(this._model.getFilteredRowCount(this._isExternal), this._model.getRowCount());
        break;
      default:
        break;
    }
  }
}

customElements.define('sgx-data-model-tool-display-count', withInitDOM(SgxDataModelToolDisplayCount));
