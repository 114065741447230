import { withInitDOM } from 'sgx-base-code';
import tmpl from './cmp-list-row-market-performance.html';

/**
 * Market updates row summary within a list.
 */
class CmpListRowMarketPerformance extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {}

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._name = this.querySelector('.cmp-list-row-market-performance--name');
    this._price = this.querySelector('.cmp-list-row-market-performance--price');
    this._volume = this.querySelector('.cmp-list-row-market-performance--volume');
    this._value = this.querySelector('.cmp-list-row-market-performance--value');
  }

  setConfig(config) {}

  setState(state) {}

  setData(market) {
    this._market = market;
    this._render();
  }

  getData() {
    return this._market;
  }

  updateData(data) {
    this.setData(data);
  }

  _render() {
    const { n, lt, vl, v, c } = this._market || {};
    this._name.textContent = n;
    this._price.textContent = lt;
    this._volume.textContent = vl;
    this._value.textContent = v;

    if (c > 0) {
      this._price.classList.add('cmp-list-row-market-performance--up');
      this._price.classList.remove('cmp-list-row-market-performance--down');
    } else if (c === 0) {
      this._price.classList.remove('cmp-list-row-market-performance--up');
      this._price.classList.remove('cmp-list-row-market-performance--down');
    } else {
      this._price.classList.remove('cmp-list-row-market-performance--up');
      this._price.classList.add('cmp-list-row-market-performance--down');
    }
  }

  setSelected(selected) {
    if (selected) {
      this.setAttribute('selected', '');
    } else if (this.hasAttribute('selected')) {
      this.removeAttribute('selected');
    }
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }
}

customElements.define('cmp-list-row-market-performance', withInitDOM(CmpListRowMarketPerformance));
