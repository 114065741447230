import DateService from 'sgx-date-time-service';
import i18n from 'sgx-localisation-service';

const ISO_FORMAT = {
  FULL_DATE: 'YYYY-MM-DD',
  YEAR_MONTH: 'YYYY-MM'
};

/**
 * Format a date
 *
 * @see    {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLHyperlinkElementUtils}
 * @param  {Date} date the date to format
 * @return {String} a string element with the day, the month on 3 characters and the year
 */

function formatDate(date, inputFormat, isUtc, locale) {
  return formatDateTo(date, inputFormat, i18n.getTranslation('app.format.date.default'), isUtc, locale);
}

function formatApiDate(date) {
  return formatTimestamp(date, 'YYYYMMDD_HHmmss', true);
}

function formatTimestamp(date, inputFormat, isUtc) {
  return formatDateTo(date, inputFormat, i18n.getTranslation('app.format.date.timestamp'), isUtc);
}

function formatDateTo(date, inputFormat, outputFormat, isUtc, locale) {
  const timestamp = isUtc ? DateService.utc(date, inputFormat, locale) : DateService(date, inputFormat, locale);
  const formattedDate = timestamp.tz('Asia/Singapore').format(outputFormat)
  return formattedDate !== 'Invalid date' ? formattedDate : '';
}

function isToday(date) {
  return DateService(date).isSame(DateService(), 'day');
}

function formatDateToYearMonth(date) {
  if (!date.isValid()) {
    return '';
  }
  if (i18n.getLanguage() === 'zh-cn') {
    return date.format('LL').split('月')[0] + '月';
  }
  return date.format(i18n.getTranslation('app.format.date.yearMonth'));
}

/**
 * Generates list of month in desc order
 * @param {number} numOfMonths number of month to be generate starting from current month
 * @param {boolean} includeCurrentMonth indicator to include current month
 */
function getPastMonths(numOfMonths, includeCurrentMonth = true) {
  const months = [];
  const yearMonthFormat = i18n.getTranslation('app.format.date.yearMonth');
  const getOption = d => ({
    label: d.lang('en').format(yearMonthFormat),
    value: d.format('YYYY-MM')
  });

  for(let i = 1; i <= numOfMonths; i++) {
    months.push(getOption(DateService.tz().subtract(i, 'months')));
  }

  if (includeCurrentMonth) {
    months.unshift(getOption(DateService.tz(), true)); // current month
  }
  return months;
}

module.exports = {
  formatDate,
  formatApiDate,
  formatTimestamp,
  formatDateTo,
  isToday,
  formatDateToYearMonth,
  getPastMonths,
  ISO_FORMAT
};
