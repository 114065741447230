
import tmpl from './cmp-user-profile-myinfo-confirmation.html';
import i18n from 'sgx-localisation-service';
import DeviceService from '@sgx/sgx-device-service';
import { URLUtils, withInitDOM } from 'sgx-base-code';
import StoreRegistry from 'stores/store-registry';
import { USER_TYPES } from 'services/user-service/src/user-service-constants';
import LocalSecurityUtil from '../../services/auth-service/src/utils/local-security-auth-util';
import ConfigService from 'sgx-config-service';
import MyInfoService from 'services/myinfo-service';

class CmpMyInfoConfirmation extends HTMLElement {
  constructor() {
    super();
    this._proceedMyInfoRedirectLink = this._proceedMyInfoRedirectLink.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-user-profile-myinfo-confirmation');
    this._dialog = document.querySelector('#cmp-user-profile-myinfo-confirmation-dialog');
    this._proceedButton = this.querySelector('.cmp-user-myinfo-button-submit');
    this._disclaimerText = this.querySelector('.myinfo-disclaimer');
    this._updateInfo = this.querySelector('.myinfo-update-info');
    this._nricAlertInfo = this.querySelector('.cmp-user-profile-myinfo-nric-alert');
    this._attributesList = this.querySelector('.myinfo-attributes-list');
    this._router = document.querySelector('sgx-app-router');
  }

  connectedCallback() {
    this._setFieldTranslations();
    this._setListeners(true);
  }

  disconnectedCallback() {
    this._setListeners(false);
  }

  /**
   * Identifier if it's from register fin
   */
  get isFin() {
    return this.hasAttribute('fin');
  }

  showDialog(fullList = true) {
    const titleKeyPath = this.isFin ? 'myInfoFin' : 'myInfo';
    const { idType, issuingCountry } = StoreRegistry.cdpSession.getData();
    this._isForeigner = (idType == USER_TYPES.FOREIGNER)  || (idType == USER_TYPES.SC_PR && issuingCountry !== 'SG');
    this._fullList = fullList;

    if (!fullList) {
      const translationKey = this.isFin ? 'myInfoFin' : 'myInfo';
      this._attributesList.innerHTML = i18n.getTranslation(`app.${translationKey}.updateInfo.attributesList_short_html`);
      this._attributesList.classList.add('short-list');
    }

    this._dialog.show({
      title: i18n.getTranslation(`app.${titleKeyPath}.title`),
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      actions: {
        cancel: () => this.teardown()
      }
    });
  }

  teardown() {
    this.hideDialog();
  }

  hideDialog() {
    this._dialog.hide();
  }

  _setFieldTranslations() {
    const lang = StoreRegistry.appSettings.getData('lang');
    const translationKey = this.isFin ? 'myInfoFin' : 'myInfo';
    this._disclaimerText.innerHTML = i18n.getTranslation('app.myInfo.disclaimer_html', { url: ConfigService.links.SGX_V2_TERMS_USE[lang] });
    this._updateInfo.textContent = i18n.getTranslation(`app.${translationKey}.updateInfo.text`);
    this._attributesList.innerHTML = i18n.getTranslation(`app.${translationKey}.updateInfo.attributesList_html`);
    this._residentialAddressAttribute = this._attributesList.querySelector('.myinfo-residential-address');
    this._nricAlertInfo.setData({
      status: 'informational',
      text: i18n.getTranslation(`app.${translationKey}.nricAlertInfo_html`),
      background: false
    });
  }

  _setListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';
    this._proceedButton[fnName]('click', this._proceedMyInfoRedirectLink);
  }

  _proceedMyInfoRedirectLink() {
    setTimeout(async () => {
      const userIdState = LocalSecurityUtil.sha256Hash(StoreRegistry.cdpSession.getData().userId);
      let configKey = 'particulars';
      let myInfoRedirectKey = 'particulars';
      // need to check for router's existence since this is also use in the login page and currently doesn't use router
      const currentState = this._router && this._router.getState().name;

      if (this.isFin) {
        configKey = 'fin';
        myInfoRedirectKey = StoreRegistry.appContext.getData('webview') ? 'fin-webview' : 'fin';
        StoreRegistry.registerFin.setData(true, 'isRegisterFin');
      } else {
        myInfoRedirectKey = ['portfolio', 'dashboard'].includes(currentState) ? currentState : myInfoRedirectKey;
        StoreRegistry.cdpSession.setData({ ...StoreRegistry.cdpSession.getData(), updateParticularMode: 'myinfo' });
      }

      const res = await MyInfoService.getSession(this.isFin)
        .catch(e => console.error(e));

      if(!res){
        return;
      }

      let attributesKey = !this.isFin && this._isForeigner ? `${configKey}-foreigner` : configKey;
      if (!this._fullList) {
        attributesKey = 'particulars-short';
      }

      if (['fin', 'fin-webview'].includes(myInfoRedirectKey)) {
        attributesKey = 'register_fin';
      }

      const redirectUri = ConfigService.env['myinfo-redirect-uri'][myInfoRedirectKey];
      const clientId = ConfigService.env['myinfo-clientId'][configKey];
      const attributes = ConfigService.env['myinfo-attributes'][attributesKey];
      const purpose_id = res.purpose_id;
      const code_challenge = res.code_challenge;
      const code_challenge_method = res.code_challenge_method;

      window.location = URLUtils.setQueryParams(ConfigService.env['myinfo-authorize-url'], {
        purpose_id,
        response_type: 'code',
        scope: attributes,
        redirect_uri: redirectUri,
        client_id: clientId,
        code_challenge,
        code_challenge_method
      });
    }, 500);
  }
}
customElements.define('cmp-user-profile-myinfo-confirmation', withInitDOM(CmpMyInfoConfirmation));
