import {withInitDOM} from "@sgx/sgx-base-code";
import tmpl from './widget-corporate-actions-standing-instructions.html';
import i18n from 'sgx-localisation-service';
import DateService from 'sgx-date-time-service';
import CorporateActionsService from 'services/corporate-actions-service';
import UserService from 'services/user-service';
import { ACCOUNT_STATUS } from 'services/user-service/src/user-service-constants';


class WidgetCorporateActionsStandingInstructions extends HTMLElement {

  connectedCallback() {
    this._handleEventListeners(true);
  }

  disconnectedCallback() {
    this._handleEventListeners(false);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-ca-standing-instructions');
    this.classList.add('sgx-hidden');
    this._statusIndicator = this.querySelector('.widget-ca-standing-instructions-loader');
    this._dialog = this.querySelector('.widget-ca-standing-instructions-dialog');
  }

  /**
   * @desc handle event listeners
   * */
  _handleEventListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';

    this[fnName]('standing-instruction-cancelled', _ => {
      this.classList.add('sgx-hidden');
      this.setData({accountId: this._accountId})
    });
  }


  /**
   * @desc setData based on the accountId
   * @param {String} accountId
   * */
  setData({accountId}) {

    this._accountId = accountId;

    this._statusIndicator.show();

    Promise.all([
      CorporateActionsService.getCorporateActionsList(),
      CorporateActionsService.getStandingInstructions({
        accountId
      }),
      CorporateActionsService.status(),
      UserService.getUserAccount(accountId)
    ]).then(([activeCorporateActions, standingInstructionsResponse, ptsResponse, userAccountDetails]) => {
      const currentTime = +new Date(standingInstructionsResponse.meta.timestamp);
      const standingInstructions = standingInstructionsResponse.data.instructions;
      const disableCancelButton = !ptsResponse.status ||
        ![null, undefined, '', '5'].includes(userAccountDetails.accountInfo.specialFlag) || userAccountDetails.accountInfo.accountStatus === ACCOUNT_STATUS.SUSPENDED;

      this._render({
        activeCorporateActions,
        standingInstructions,
        currentTime,
        disableCancelButton
      })
    })
      .then(() => {
        this._statusIndicator.hide();
      })
      .catch(res => {
        this.classList.add('sgx-hidden');
      })
  }

  /**
   * @desc transform data received as arguments in form that can be passed to render list
   * @param {Array} activeCorporateActions
   * @param {Array} standingInstructions
   * @param {String} currentTime
   * @param {Boolean} disableCancelButton
   * */
  _render({activeCorporateActions, standingInstructions, currentTime, disableCancelButton}) {

    const list = standingInstructions.filter(item => {
      if (item.status !== 1) {
        return;
      }
      item.hideButton = item.status === 0;
      item.status = this._getStatus(item.status);
      item.unAvailable = false;
      item.button = {
        label: i18n.getTranslation('app.widget-corporate-actions-standing-instructions.buttons.cancel'),
        callback: _ => this._dialog.showDialog({
          securityCode: item.securityCode,
          securityName: item.securityName,
          optionType: item.optionType,
          eventType: item.eventType,
          accountId: this._accountId
        })
      }

      // Check if cancellation is unavailable
      activeCorporateActions.forEach(acItem => {

        if (acItem.instrumentID === item.securityCode) {
          item.unAvailable = currentTime > +new Date(acItem.electionEnd) && currentTime < +new Date(acItem.paymentDate)
        }

        if (disableCancelButton) {
          item.disableCancelButton = true;
        }

      })

      item.date = `${i18n.getTranslation('app.widget-corporate-actions-standing-instructions.date.since')} ${DateService(item.approvedDateTime).format(i18n.getTranslation('app.format.date.default'))}`

      return item;
    });

    if (list.length !== 0) {
      this.classList.remove('sgx-hidden');
    }

    this._createList({list})
  }

  /**
   * @param {Number} statusCode {0: Pending Verification, 1: Verified, 2: Terminated}
   * @returns status object to be used in the list
   * */
  _getStatus(statusCode) {
    const statusMap = {
      0: {
        color: 'orange',
        text: i18n.getTranslation('app.widget-corporate-actions-standing-instructions.status.pending')
      },
      1: {
        color: 'green',
        text: i18n.getTranslation('app.widget-corporate-actions-standing-instructions.status.verified')
      },
      2: {
        color: 'red',
        text: i18n.getTranslation('app.widget-corporate-actions-standing-instructions.status.terminated')
      },
    };

    return statusMap[statusCode];
  }


  /**
   * @desc create configuration for cmp-list-standalone
   * @params {Array} list
   * */
  _createList({list = []} = {}) {
    if (!this._list) {
      this._list = document.createElement('cmp-list-standalone');
      this._list.setConfig({
        rowElementName: 'cmp-list-row-ca-standing-instruction',
        rowElementClasses: ['ca-standing-instruction-row']
      });
      this.appendChild(this._list);
    }

    this._list.setData(list);
    this._list.show();
  }

}

customElements.define('widget-ca-standing-instructions', withInitDOM(WidgetCorporateActionsStandingInstructions));
