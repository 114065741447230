module.exports = (function() {
    var templateString = module.exports = "<template id=\"w-due-d-check\">\n    <sgx-dialog class=\"w-due-d-check-dialog\" role=\"dialog\">\n        <h2 data-dialog=\"title\" data-i18n=\"w-due-d-check-dialog.title\"></h2>\n        <div data-dialog=\"body\">\n            <p class=\"sgx-base-text-body\">\n                <span data-i18n=\"w-due-d-check-dialog.description1\"></span>\n            </p>\n      <p class=\"sgx-base-text-body\">\n                <span data-i18n=\"w-due-d-check-dialog.description2\"></span>\n            </p>\n      <label class=\"sgx-input-label\" data-i18n=\"w-due-d-check-dialog.countries\"></label>\n      <div class=\"exception-countries\"></div>\n      <p class=\"sgx-base-text-body\">\n                <span data-i18n=\"w-due-d-check-dialog.description3\"></span>\n            </p>\n      <p class=\"sgx-base-text-body\">\n                <span data-i18n=\"w-due-d-check-dialog.description4\"></span>\n            </p>\n            <sgx-input-checkbox class=\"exception-check\" data-i18n=\"[label]w-due-d-check-dialog.exceptionLabel\"></sgx-input-checkbox>\n            <button class=\"sgx-button--primary sgx-button--small proceed-btn\" disabled data-dialog=\"ok\"\n                    data-i18n=\"w-due-d-check-dialog.proceed\"></button>\n            <button class=\"sgx-button--link sgx-button--small cancel-btn\" data-dialog=\"cancel\"\n            data-i18n=\"w-due-d-check-dialog.cancel\"></button>\n        </div>\n    </sgx-dialog>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()