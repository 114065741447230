module.exports = (function() {
    var templateString = module.exports = "<template id=\"cmp-list-row-security\">\n  <div class=\"cmp-list-row-security-row\">\n    <div class=\"cmp-list-row-security-column cmp-list-row-security-column--flex\">\n      <cmp-star-icon class=\"sgx-base-text-label-14\"></cmp-star-icon>\n      <div class=\"cmp-list-row-security-column--full-width\">\n        <div class=\"cmp-list-row-security-column-name sgx-base-text-title-16\"></div>\n        <div class=\"cmp-list-row-security-column-code-container\">\n          <div class=\"cmp-list-row-security-code\"></div>\n          <sgx-tooltip data-sgx-tooltip-hover data-sgx-tooltip-pos=\"right\" class=\"cmp-list-row-security-status--swl sgx-hidden\">\n            <div data-sgx-tooltip-content data-i18n=\"sgx-table-cell-securities.stockwatchlist-message\"></div>\n            <span class=\"sgx-icon\"></span>\n          </sgx-tooltip>\n          <sgx-tooltip data-sgx-tooltip-hover data-sgx-tooltip-pos=\"right\" class=\"cmp-list-row-security-status--rmk sgx-hidden\">\n            <div data-sgx-tooltip-content></div>\n            <span class=\"sgx-icon\"></span>\n          </sgx-tooltip>\n          <sgx-tooltip data-sgx-tooltip-hover data-sgx-tooltip-pos=\"right\" class=\"cmp-list-row-security-status--info sgx-hidden\">\n            <div data-sgx-tooltip-content></div>\n            <span class=\"sgx-icon\"></span>\n          </sgx-tooltip>\n        </div>\n      </div>\n    </div>\n    <cmp-price-info class=\"cmp-list-row-security-column cmp-price-info--small\" vertical-stacked></cmp-price-info>\n  </div>\n</template>\n";;
    return function parseDOMString(tagString) {

  function prepare () {
    var userAgent = 'navigator' in window && 'userAgent' in navigator && navigator.userAgent.toLowerCase() || '';
    var vendor = 'navigator' in window && 'vendor' in navigator && navigator.vendor.toLowerCase() || '';
    var range = document.createRange();
    var node = range.createContextualFragment(tagString);
    var firstChild = node.firstChild;

    if (firstChild && firstChild.tagName.toLowerCase() === 'template') {
      var parts = navigator.userAgent.match(/(trident).+rv[:\s]([\w\.]+).+like\sgecko/i);
      if (parts && parts[1] == 'Trident' && parts[2] == '11.0' ) {
        node = range.createContextualFragment(firstChild.innerHTML);
      } else {
        node = firstChild.content;
      }
    } else {
      console.warn(tagString);
    }
    return node;
  }

  return {
    node : null,
    getNode: function() {
      if(!this.node) {
        this.node = prepare();
      }
      return document.importNode(this.node,true);
    }
  };
}(templateString);
  })()