import {withInitDOM} from 'sgx-base-code';
import tmpl from './widget-ccy-subscription-dialog.html';
import i18n from '@sgx/sgx-localisation-service';
import DeviceService from '@sgx/sgx-device-service';
import StoreRegistry from 'stores/store-registry';
import CcyService from 'services/ccy-service';
import ConfigService from 'sgx-config-service';
import {getSubscriptionStateToRender} from 'utils/ccy-util';

export default class WidgetCcySubscriptionDialog extends HTMLElement {

  constructor() {
    super();

    this._cdpSessionStore = StoreRegistry.cdpSession;
    this._settingsAccountStore = StoreRegistry.settingsAccount;

    this.updateStatusIndicator = this.updateStatusIndicator.bind(this);
    this.updateSubscriptionState = this.updateSubscriptionState.bind(this);
    this.updateCCYState = this.updateCCYState.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());

    this._dialog = this.querySelector('.widget-ccy-subscription-dialog');
    this._dialogTitle = this.querySelector('.widget-ccy-subscription-dialog-title');
    this._dialogDescription = this.querySelector('.widget-ccy-subscription-dialog-description');
    this._dialogSubscriptionBtn = this.querySelector('.widget-ccy-subscription-dialog-subscription-btn');

    this._defaultButtons = this.querySelectorAll('.widget-ccy-subscription-dialog-subscription-btn--default');
    this._sgxStatusIndicator = this.querySelector('sgx-status-indicator');
  }

  connectedCallback() {
    this.updateCurrentSubscriptionStatus();
  }

  disconnectedCallback() {
  }

  setData(ccySubscriptionState = 'subscribe') {
    this.ccySubscriptionState = ccySubscriptionState;
    this._dialogTitle.textContent = i18n.getTranslation(`widget-ccy-subscription-dialog.title_${ccySubscriptionState}`);
    this._dialogDescription.textContent = i18n.getTranslation(`widget-ccy-subscription-dialog.description_${ccySubscriptionState}`);
    this._dialogSubscriptionBtn.textContent = i18n.getTranslation(`widget-ccy-subscription-dialog.button.${ccySubscriptionState}`);
  }

  updateCurrentSubscriptionStatus() {
    const { status: lastSavedStatus } = this._cdpSessionStore.getData();
    this._cdpSessionStore.subscribe(({status}) => {
      if (status) {
        const currentStatus = getSubscriptionStateToRender(status);
        this.setData(currentStatus === 'pending' ? lastSavedStatus : currentStatus);
      }
    })
  }

  showDialog() {
    this.toggleButtonState(true);
    this._sgxStatusIndicator.hide();
    const dialogConfig = {
      isModal: true,
      fullscreen: DeviceService.isMobile(),
      actions: {
        submit: _ => this.updateSubscriptionState(),
        change: _ => this.updateCCYState()
      }
    };
    return this._dialog.show(dialogConfig);
  }

  updateCCYState() {
    this._dialog.hide();
    StoreRegistry.cdpSession.setData(true, 'ccyUpdated');
  }

  updateSubscriptionState() {
    const {bankAccountNumber, bankId} = this._cdpSessionStore.getData();
    const {accountId} = this._settingsAccountStore.getData();
    const status = this.getStatusForIntegration();

    this._sgxStatusIndicator.show();

    CcyService.setStandingInstruction(accountId, {
      bankAccountNo: bankAccountNumber,
      bankId,
      status
    }).then(response => {
      const { status } = response;
      let indicatorStatus = 'error';

      if (!!~[200, 201, 202].indexOf(status)) {
        indicatorStatus = status === 202 ? 'pending': 'success';
      }

      // pass any message when hiding the dialog to resolve a promise. undefined will reject a promise
      this.updateStatusIndicator(indicatorStatus);
    }).catch(error => {
      this.updateStatusIndicator('error');
    });
  }

  getStatusForIntegration() {
    let status = 'CREATE';
    switch (this.ccySubscriptionState) {
      case 'subscribe':
        status = 'CREATE';
        break;
      case 'unsubscribe':
        status = 'TERMINATE';
        break;
    }

    return status;
  }

  updateStatusIndicator(state) {
    const status = !!~['pending', 'success'].indexOf(state) ? 'success': state;
    this._sgxStatusIndicator.show({
      status,
      description: i18n.getTranslation(`widget-ccy-subscription-dialog.status-message.${state}.description`, {
        contactNumber: ConfigService.common.CONTACT_NUMBER,
        email: ConfigService.common.CONTACT_EMAIL
      }),
      button: {
        text: i18n.getTranslation('widget-ccy-subscription-dialog.button.ok'),
        callback: () => status === 'success' ? this.updateCCYState() : this._dialog.hide()
      }
    });
    this.toggleButtonState(false);
  }

  toggleButtonState(showDefaultState = true) {
    if (showDefaultState) {
      this._defaultButtons.forEach(btn => btn.classList.remove('sgx-hidden'));
      this._dialog.bodyElement.classList.remove('active-indicator');
    } else {
      this._defaultButtons.forEach(btn => btn.classList.add('sgx-hidden'));
      this._dialog.bodyElement.classList.add('active-indicator');
    }
  }
}

customElements.define('widget-ccy-subscription-dialog', withInitDOM(WidgetCcySubscriptionDialog));
