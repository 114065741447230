import { Utils, withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DeviceService from 'sgx-device-service';
import SgxDataModelToolBaseProto from '../../sgx-data-model-tool-base';
import { DATA_MODEL_TOOL_ICON } from '../../sgx-data-model-tool-constants';

const DIALOG_ID = 'sgx-data-model-tool-search-dialog';
const template = require('./sgx-data-model-tool-search-dialog')(DIALOG_ID);

/**
 * Search tool.
 */
class SgxDataModelToolSearch extends SgxDataModelToolBaseProto {
  constructor() {
    super();
    this.setConfig = this.setConfig.bind(this);
    this.getTool = this.getTool.bind(this);
    this._onResetClick = this._onResetClick.bind(this);
    this._onBtnClick = this._onBtnClick.bind(this);
  }

  initDOM() {
    this._addSearchField();
    this.setAttribute('label', i18n.getTranslation('data-model.tools.search'));
    this.setAttribute('icon', DATA_MODEL_TOOL_ICON.SEARCH);
    this.classList.add('sgx-data-model-tool-search');
    this._tool = this.querySelector('.sgx-data-model-tool-search-input');
    this._resetButton = this.querySelector('.sgx-data-model-tool-search-reset');
    this._input = this.querySelector('.sgx-data-model-tool-search-input');

    this._onSearchChangeDebounced = Utils.debounce(this._onSearchChange.bind(this), DeviceService.isIe() ? 500 : 100);
  }

  connectedCallback() {
    this.toggleListeners(true);
  }


  disconnectedCallback() {
    this.toggleListeners(false);
  }

  toggleListeners(enable) {
    const method = enable ? 'addEventListener' : 'removeEventListener';
    this[method]('click', this._onBtnClick);
    this._input[method](DeviceService.isIe() ? 'keydown' : 'input', this._onSearchChangeDebounced); // IE only works keydown event, not input
  }

  setConfig({model, filters, callback}) {
    this._model = model;
    this._filters = filters;
    this._init = true;
  }

  _updateCount(count) {
    if (this._count === count) {
      return;
    }
    this._count = count;
    this._tool.setCount(count ? count : null);
  }

  _onSearchChange(evt) {
    const value = this._input.getValue().trim();
    this._model.getState().then(oldState => {
      let newState = null;
      if (value) {
        this._tool.setAttribute('selected', true);
        newState = this._performSearch(oldState, value);
      } else {
        this._tool.removeAttribute('selected');
        newState = this._clearSearch(oldState);
      }
      this._model.setState(newState);
    });
  }

  _performSearch(stateData, value) {
    stateData.search = {
      method: 'contains',
      type: 'text',
      value
    };
    delete stateData.filters;
    return stateData;
  }

  _clearSearch(stateData) {
    stateData.filters = this._initialFilters || {};
    delete stateData.search;
    return stateData;
  }

  _onBtnClick(evt) {
    if (!this._init || this._tool.getDisabled()) {
      return;
    }
    if (this.classList.contains('sgx-data-model-tool--is-active')) {
      this._unlock('hideDialog');
      return;
    }
    this.classList.add('sgx-data-model-tool--is-active');
    if (this._initialFilters) {
      this.emit('lock', {callback: 'showDialog'});
    } else {
      this._model.getState().then(initialState => {
        this._initialFilters = initialState.filters;
        this.emit('lock', {callback: 'showDialog'});
      });
    }
  }

  _onResetClick() {
    this._unlock('hideDialog');
  }

  _unlock(callback) {
    this._input.value = '';
    this._model.getState().then(state => {
      const newState = this._clearSearch(state || {});
      this._model.setState(newState);
      this._initialFilters = null; // reset state
      this.classList.remove('sgx-data-model-tool--is-active');
      this.emit('unlock', {callback});
    });
  }

  getTool() {
    return this._tool;
  }

  /**
   * @desc
   * 1. add the search box
   * 2. add the reset button
   * 3. add info icon
   * */
  _addSearchField() {
    const button = document.createElement('button');
    button.classList.add('sgx-button--low-emp', 'sgx-icon', 'sgx-data-model-tool-search-reset');
    button.setAttribute('data-action', 'reset');

    const searchInput = document.createElement('sgx-input-text');
    searchInput.classList.add('sgx-data-model-tool-search-input');
    searchInput.setAttribute('data-i18n', '[placeholder]app.sgx-data-model-tool-search.search-placeholder');

    const infoToolTip = document.createElement('sgx-tooltip');
    infoToolTip.innerHTML = `
        <div data-sgx-tooltip-content data-i18n="[html]app.widget-stock-screener.toolbar.filter-dialog.tooltip_search"></div>
        <button class="sgx-icon sgx-tooltip-button sgx-tooltip-button-info-icon allow-event-propagation"></button>`;

    this.appendChild(infoToolTip);
    this.appendChild(button);
    this.appendChild(searchInput);

  }

  setDisabled(isDisabled) {
    this._tool.disabled = !!isDisabled;
    this._resetButton.disabled = !!isDisabled;
    this.disabled = !!isDisabled;
  }

  getDisabled() {
    return !!this._tool.disabled && !!this._resetButton.disabled;
  }

  getSearchValue(){
    return this._input.getValue();
  }

}

customElements.define('sgx-data-model-tool-search', withInitDOM(SgxDataModelToolSearch));

