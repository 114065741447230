import {withInitDOM} from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import DeviceService from 'sgx-device-service';
import tmpl from './widget-rights-payment-dialog.html';

class WidgetRightsPaymentDialog extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());

    this._dialog = this.querySelector('.w-rights-payment-dialog');
    this._paymentSummary = this.querySelector('cmp-rights-payment-summary');
    this._summaryValues = this.querySelector('.w-rights-payment-dialog-summary-values');
    this._total = this.querySelector('.w-rights-payment-dialog-summary-total');
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }

  showDialog() {
    const dialogConfig = {
      isModal: true,
      fullscreen: DeviceService.isMobile()
    };
    return this._dialog.show(dialogConfig);
  }

  setData(data) {

    const {currency, total} = data;
    this._paymentSummary.setData({
      paymentCurrency: currency,
      sharesApplied: data.rightsApplied,
      issuePrice: data.issuePrice,
      transFee: data.txnFee,
      gst: data.gst,
      total,
      totalAmount: data.totalAmount
    })
    /*this._summaryValues.innerHTML = i18n.getTranslation('widget-rights-payment-dialog.summary.values_html', {
      sharesApplied: `${data.rightsApplied}`,
      issuePrice: 'N/A',
      transFee: `${currency} ${data.txnFee}`,
      gst: `${currency} ${data.gst}`
    })
    this._total.innerHTML = i18n.getTranslation('widget-rights-payment-dialog.summary.total_html', {total: `${currency} ${data.total}`});*/
  }
}

customElements.define('widget-rights-payment-dialog', withInitDOM(WidgetRightsPaymentDialog));
