import SgxTableDecoratorBase from '@sgx/sgx-table/src/sub-components/sgx-table-decorator/sgx-table-decorator-base';

class SgxTableDecoratorInputButton extends SgxTableDecoratorBase {
  constructor() {
    super();

    this._onCellClick = this._onCellClick.bind(this);
  }

  disconnectedCallback() {
    if (this._tableList) {
      this._tableList.removeEventListener('click', this._onCellClick);
    }
  }

  decorate(decoratorObject, action) {
    const config = decoratorObject.decoratorConfig;
    const tableConfig = decoratorObject.tableConfig;
    const rowData = decoratorObject.rowData;
    const rowElem = decoratorObject.element;
    this._cellsToDecorate = Array.isArray(config.cellToDecorate) ? config.cellToDecorate : [config.cellToDecorate];

    this._cellsToDecorate.forEach(cell => {
      if (typeof config.shouldDisable === 'function' && config.shouldDisable(rowData, tableConfig)) {
        rowElem.querySelector(`[data-column-id = ${cell}]`).dataset.disabled = true;
        return;
      }
      if (typeof config.shouldHide === 'function' && config.shouldHide(rowData, tableConfig)) {
        rowElem.querySelector(`[data-column-id = ${cell}]`).dataset.hidden = true;
        return;
      }
      delete rowElem.querySelector(`[data-column-id = ${cell}]`).dataset.disabled;
      delete rowElem.querySelector(`[data-column-id = ${cell}]`).dataset.hidden;
    });

    this._tableList = decoratorObject.element.closest('sgx-table').querySelector('sgx-table-list');
    this._tableList.addEventListener('click', this._onCellClick);
  }

  _onCellClick(evt) {
    const target = evt.target.parentElement;
    const { columnId, disabled, hidden } = target.dataset;

    if (!!~this._cellsToDecorate.indexOf(columnId) && !disabled && !hidden) {
      const rowId = target.parentElement.dataset.rowId;

      target.dispatchEvent(new CustomEvent('ROW_INPUT_BUTTON_CLICKED', {
        bubbles: true,
        detail: {
          colId: columnId,
          rowId
        }
      }));
    }
  }
}

customElements.define('sgx-table-decorator-input-button', SgxTableDecoratorInputButton);
const decorator = document.createElement('sgx-table-decorator-input-button');
document.body.appendChild(decorator);
export default decorator;
