import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-settings-security-password.html';
import i18n from 'sgx-localisation-service';
import ConfigService from '@sgx/sgx-config-service';

class PasswordUpdateWidget extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-settings-security-password');
    this._promptRow = this.querySelector('cmp-list-row-prompt');
    this._updatePasswordDialog = this.querySelector('.widget-password-dialog');
    this._statusContainer = this.querySelector('.widget-security-password-status-container');
    this._status = this.querySelector('.widget-security-password-status');
    this._icon = this.querySelector('.widget-security-password-icon');
    this._indicator = this.querySelector('sgx-status-indicator');
  }

  connectedCallback() {
    this._promptRow.setConfig({
      text: i18n.getTranslation('app.widget-settings-security-password.prompt.text'),
      button: i18n.getTranslation('app.widget-settings-security-password.prompt.button'),
      callback: this._showDialog.bind(this)
    });
    this._setListener(true);
  }

  disconnectedCallback() {
    this._setListener(false);
  }

  /**
   * Shows the update password dialog
   */
  _showDialog() {
    this._updatePasswordDialog.show({ hideFooter: true, isModal: true })
  }

  _setListener(enable) {
    const funcName = enable ? 'addEventListener' : 'removeEventListener';
    this[funcName]('reset-change-password-state', this.setStatus);
  }

  /**
   * Set the status of the update response.
   * @module widget-settings-user-actions
   * @type {HTMLElement}
   * @param code The http response code
   */
  setStatus(e) {
    const code = e.detail.code;

    if (code) {
      // Hide status indicator
      this._indicator.hide();
      this._updatePasswordDialog.hide();

      // Show status message and icon
      this._statusContainer.style.display = 'flex';
    }

    // Determine status message
    switch (code) {
      case '200':
      case '201':
        this._displayStatus('success');
        break;
      case '202':
        this._displayStatus('pending');
        break;
      case '401':
        this._displayStatus('suspended');
        break;
      case '403':
        this._displayStatus('unauthorized');
        break;
      case '500':
        this._displayStatus('failed');
        break;
    }
  }

  /**
   * Helper function to display update status.
   * @module widget-settings-user-actions
   * @type {HTMLElement}
   * @param type The type of status update
   */
  _displayStatus(type) {

    // Reset classes to default state
    this._resetClasses();

    // Set status text and icon
    this._status.textContent = i18n.getTranslation(`app.widget-settings-security-password.status.${type}`);
    this._icon.classList.add(`widget-security-password-icon--${type}`);

    // Set red text for failed states
    if (type === 'suspended' || type === 'unauthorized' || type === 'failed') {
      this._status.classList.add('widget-security-password-status--red');
    }

    // Set timeout for all but suspended states
    if (type !== 'suspended') {
      setTimeout(() => this._statusContainer.style.display = 'none', ConfigService.env.timeout.settings);
    }
  }

  /**
   * Helper function to reset classes of an element to its default state.
   * @module widget-settings-user-actions
   * @type {HTMLElement}
   */
  _resetClasses() {

    const elements = [
      {
        name: 'icon',
        classList: this._icon.classList
      },
      {
        name: 'status',
        classList: this._status.classList
      }
    ];

    elements.forEach(element => {
      element.classList.forEach(item => {
        if (item.startsWith(`widget-security-password-${element.name}--`)) {
          element.classList.remove(item);
        }
      })
    })
  }
}

customElements.define('widget-settings-security-password', withInitDOM(PasswordUpdateWidget));
