import { withInitDOM } from 'sgx-base-code';
import tmpl from './widget-audit-log-toolbar.html';

class AuditLogToolbar extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('widget-audit-log-toolbar');
  }

  connectedCallback() {

  }
}

customElements.define('widget-audit-log-toolbar', withInitDOM(AuditLogToolbar));
