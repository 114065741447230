import StoreRegistry from 'stores/store-registry';
import tmpl from "./cmp-sub-header.html";
import {withInitDOM} from "sgx-base-code";
import ConfigService from "sgx-config-service";
import DeviceService from '@sgx/sgx-device-service';
import {SgxTooltipService} from 'sgx-tooltip';
import sgxAnalyticsService from '@sgx/sgx-analytics-service';
import { isAuthenticated } from 'utils/auth-util';
import { get } from 'lodash';
import i18n from "@sgx/sgx-localisation-service";

/**
 * A simple header template element
 * @module template-header-simple
 * @type {HTMLElement}
 */
class CmpSubHeader extends HTMLElement {
  //#region Custom Element API

  constructor() {
    super();
    this._subMenuShown = false;
    StoreRegistry.appSettings.setAutoSave(true);
    this._handleLanguageSwitch = this._handleLanguageSwitch.bind(this);
    this._toggleSubMenu = this._toggleSubMenu.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('cmp-sub-header');
    this._languageSwitcherRef = this.querySelector('.cmp-sub-header--language-switcher-link');
    this._subHeaderActionsBtn = this.querySelector('.cmp-sub-header--mobile-view--actions');
    this._mobileView = this.querySelector('.cmp-sub-header--mobile-view');
    this._desktopView = this.querySelector('.cmp-sub-header--desktop-view');
    this._helpCenter = this.querySelector('.cmp-sub-header--help-center-link');
    this._profilePage = this.querySelector('.cmp-sub-header--profile-page-link');
    this._profilePageMobile = this.querySelector('.cmp-sub-header--mobile-view-profile-page-link');
    this._helpCenterMobile = this.querySelector('.cmp-sub-header--mobile-view--help-center-link');

    this._helpCenter.href = ConfigService.links.INVESTORS_PORTAL_ABOUT;
    this._helpCenterMobile.href = ConfigService.links.INVESTORS_PORTAL_ABOUT;
    this._profilePage.href = ConfigService.links.INVESTORS_PORTAL_PROFILE_USER;
    this._profilePageMobile.href = ConfigService.links.INVESTORS_PORTAL_PROFILE_USER;
    this._toggleViews();
    this._checkAndSendGAEvent();
    this._setupProfileIcon();
  }

  connectedCallback() {
    this._handleEventListeners(true)
  }

  disconnectedCallback() {
    this._handleEventListeners(false);
    this._languageSwitcherMobileRef && this._languageSwitcherMobileRef['removeEventListener']('click', this._handleLanguageSwitch);
  }

  _setupProfileIcon() {
    StoreRegistry.permissions
      .subscribe(authState => {
          const isLoggedIn = isAuthenticated();
          this._profilePage.setAttribute('auth', isLoggedIn);
          this._profilePageMobile.setAttribute('auth', isLoggedIn);
        }
      );
    this._setProfileText()
  }

  _setProfileText() {
    StoreRegistry.user
      .subscribe(_ => {
        const isLoggedIn = isAuthenticated();
        const name = StoreRegistry.user.getData().name || i18n.getTranslation('app.profile-page');
        this._profilePage.innerText = isLoggedIn ? name : i18n.getTranslation('app.profile-page');
        this._profilePageMobile.innerText = isLoggedIn ? name : i18n.getTranslation('app.profile-page');
      })
  }

  _checkAndSendGAEvent() {
    const isLanguageSwitched = StoreRegistry.appSettings.getData('languageSwitch');

    if (isLanguageSwitched) {
      const title = get(document.querySelector('body'), 'dataset.analyticsCategory');
      const lang = StoreRegistry.appSettings.getData('lang');
      sgxAnalyticsService.sendEvent(
        title,
        `Language Switch : ${lang}`,
        'click'
      );
      StoreRegistry.appSettings.setData(false, 'languageSwitch');
    }

  }

  _handleEventListeners(enable) {
    const fnName = enable ? 'addEventListener' : 'removeEventListener';

    this._languageSwitcherRef[fnName]('click', this._handleLanguageSwitch);
    this._subHeaderActionsBtn[fnName]('click', this._toggleSubMenu);
    SgxTooltipService.hide();
  }

  _handleLanguageSwitch() {
    const lang = StoreRegistry.appSettings.getData('lang') === 'en' ? 'zh-cn' : 'en';
    StoreRegistry.appSettings.setData(lang, 'lang');
    StoreRegistry.appSettings.setData(true, 'languageSwitch');
    window.location.reload();
  }

  _toggleViews() {
    if (DeviceService.isMobile()) {
      this._mobileView.classList.remove('sgx-hidden');
      this._desktopView.classList.add('sgx-hidden');
      this._subMenuBody = this.querySelector('.cmp-sub-header--mobile-view-sub-menu');
    } else {
      this._mobileView.classList.add('sgx-hidden');
      this._desktopView.classList.remove('sgx-hidden');
    }
  }

  _toggleSubMenu() {
    const funcName = this._subMenuShown ? 'hide' : 'show';
    this._languageSwitcherMobileRef && this._languageSwitcherMobileRef['removeEventListener']('click', this._handleLanguageSwitch);
    SgxTooltipService[funcName](this._subMenuBody.innerHTML, this._subHeaderActionsBtn,
      {
        isModal: true,
        attachment: 'middle left',
        targetAttachment: 'middle right',
        theme: 'light'
      });
    setTimeout(_ => {
      this._languageSwitcherMobileRef = document.querySelector('#sgx-tooltip-dialog .cmp-sub-header--mobile-view--language-switcher-link');
      this._languageSwitcherMobileRef['addEventListener']('click', this._handleLanguageSwitch);
    }, 0)
    this._subMenuShown = !this._subMenuShown;
  }

}

//#endregion

//#region Private Methods


customElements.define('cmp-sub-header', withInitDOM(CmpSubHeader));
//#endregion
