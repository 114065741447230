import { withInitDOM } from 'sgx-base-code';
import tmpl from './template-settings-application.html';

class TemplateSettingsApplication extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-settings-application');
  }

  connectedCallback() {
  }
}

customElements.define('template-settings-application', withInitDOM(TemplateSettingsApplication));
