import { withInitDOM } from 'sgx-base-code';

class CmpStarIcon extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    const icon = document.createElement('span');
    icon.className = 'sgx-icon cmp-star-icon--star';
    this.appendChild(icon);
    this.classList.add('cmp-star-icon');
    this._icon = this.querySelector('.cmp-star-icon--star');
  }

  connectedCallback() {
    this.addEventListener('click', this._onClick);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this._onClick);
  }

  /**
   * Getter property for account-suspension-disabled attribute
   */
  get selected() {
    return this.hasAttribute('selected');
  }

  set selected(value) {
    if (value) {
      this.setAttribute('selected', '');
      return;
    }
    this.removeAttribute('selected');
  }

  _onClick(e) {
    e.stopPropagation();
    this.selected = !this.selected;
    this.dispatchEvent(new CustomEvent('cmp-star-icon-click'));
  }
}

customElements.define('cmp-star-icon', withInitDOM(CmpStarIcon));
