import tmpl from './template-company-disclosures-page.html';
import StoreRegistry from 'stores/store-registry';
import { withInitDOM } from 'sgx-base-code';

export default class TemplateCompanyDisclosures extends HTMLElement {
  constructor() {
    super();
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this.classList.add('template-company-disclosures-page');
    this.widgetCompanyAnnouncements = this.querySelector('widget-company-announcements');
    this.widgetCorporateActions = this.querySelector('widget-corporate-actions');
  }

  setData(data, params) {
    this.data = data;
    this.params = params;
    this._initStore();
  }

  _initStore() {
    this._store = StoreRegistry.pageHeaderNavigation;
    this._subscription = this._store.subscribe(data => {
      let section = (data && data.section) || '';
      const {lastState} = data || {};
      if (['company-announcements', 'corporate-actions'].includes(lastState) && lastState !== section) {
        delete this.params.annc;
      }
      if (section === 'company-announcements') {
        if (!this._announcementsInitialized) {
          this._announcementsInitialized = true;
          this.widgetCompanyAnnouncements.setData(this.data, this.params);
        }
        this.widgetCompanyAnnouncements.style.display = 'flex';
        const companyAnnouncementsView = this.widgetCompanyAnnouncements.querySelector('sgx-list').getView();
        companyAnnouncementsView && companyAnnouncementsView.recalculateSize();
        this.widgetCorporateActions.style.display = 'none';
      } else {
        if (!this._actionsInitialized) {
          this._actionsInitialized = true;
          this.widgetCorporateActions.setData(this.data, this.params);
        }
        this.widgetCorporateActions.style.display = 'flex';
        const corporateActionsView = this.widgetCorporateActions.querySelector('sgx-list').getView();
        corporateActionsView && corporateActionsView.recalculateSize();
        this.widgetCompanyAnnouncements.style.display = 'none';
      }
    });
  }

  show() {
  }
}

customElements.define('template-company-disclosures-page', withInitDOM(TemplateCompanyDisclosures));
