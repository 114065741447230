import { withInitDOM } from 'sgx-base-code';
import i18n from 'sgx-localisation-service';
import StoreRegistry from 'stores/store-registry';
import tmpl from './template-header-navs.html';
import ConfigService from 'sgx-config-service';
import { isAuthenticated } from 'utils/auth-util';

/**
 * A header with navigations template element.
 * Linked to the application router, it can also read the "section" query param
 * from the URL to decide which navigation element to highlight.
 * @module template-header-navs
 * @type {HTMLElement}
 */
export default class TemplateHeaderNavs extends HTMLElement {
  //#region Custom Element API

  constructor() {
    super();
    this._router = document.getElementById('sgx-app-router');
    this.setData = this.setData.bind(this);
    this._onStateChange = this._onStateChange.bind(this);
    this._onLinkClicked = this._onLinkClicked.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._pageTitle = this.querySelector('.page-title');
    this._navs = this.querySelector('.page-navs');
    this._navs.setConfig({
      type: 'page',
      editable: false,
      linkable: true,
      updateFromURL: true
    });
  }

  connectedCallback() {
    this._navs.addEventListener('stateChange', this._onStateChange);
    this._navs.addEventListener('click', this._onLinkClicked);
  }

  disconnectedCallback() {
    this._store.clear();
    this._navs.removeEventListener('stateChange', this._onStateChange);
    this._navs.removeEventListener('click', this._onLinkClicked);
  }

  attributeChangedCallback() {
  }

  //#endregion

  //#region Public Methods

  setData(data, params) {
    this._store = StoreRegistry.pageHeaderNavigation;
    this._pageTitle.textContent = i18n.getTranslation(data.pageTitle);
    if (data.pageData.isDashboardPage) {
      this.querySelector('.page-header').classList.add('page-header-dashboard');
    }

    this._routesBound = !!data.pageData.routesBound;

    // todo: this is commented, in-case required back in future | should be removed eventually
    /*if (data.pageData.editable) {
      setTimeout(_ => {
        document.querySelector(data.templateId).updateNavigationHeaders(data.pageData.subLinks)
          .then(subLinks => {
            this._setNavigations(subLinks, params && params.section, params.section === 'default');
          })
          .catch(({subLinks, activateTab}) => {
            this._setNavigations(subLinks, params && params.section, params.section === 'default');
            this._navs.activateTab(activateTab);
          })
      });
      return;
    }*/
    this._setNavigations(data.pageData.subLinks, params && params.section, params.section === 'default');
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.parentNode.removeChild(this);
  }

  /**
   * Sets up the navigation
   *
   * @param {LabelData[]} navsConfig - an array of object { label, data } used to set the content of the navigation
   * @param {String} section - optional section to set as active
   * @memberof TemplateHeaderNavsProto
   */
  _setNavigations(navsConfig, section, isDefault) {
    const defaultSection = isDefault && this._store.getData('section');

    // INVESTORS-1075 {Link: https://jira.sgx.com/browse/INVESTORS-1075}
    const ds3AccountAvailable = StoreRegistry.cdpSession.getData('ds3AccountAvailable');

    if (defaultSection) {
      this._router.navigateWithoutTransition({section: defaultSection});
      section = defaultSection;
    }
    this._store.setData(section, 'section');
    const states = [];
    navsConfig.forEach(({title, link, auth, ds3AccountRequired}) => {
      if ((ds3AccountRequired && !ds3AccountAvailable)) {
        return;
      }

      if (!auth || (auth && isAuthenticated())) {
        states.push({
          label: i18n.getTranslation(title, ''),
          url: link,
          data: link
        });
      }
    });

    this._navs.setState({states});
  }

  _onStateChange(event) {
    const section = event.detail.split('/')[2]; // ./securities/ets => etfs
    const lastState = window.location.pathname && window.location.pathname.split('/')[2];
    this._router.navigateWithoutTransition({section});
    this._store.setData(section, 'section');
    this._store.setData(lastState, 'lastState');
  }

  _onLinkClicked(event) {
    if (!event || event.button !== 0) {
      return;
    }
    // event.stopPropagation();
    event.preventDefault();
  }
}

//#endregion

//#region Private Methods


customElements.define('template-header-navs', withInitDOM(TemplateHeaderNavs));
//#endregion
