import tmpl from './cmp-login-prompt-dashboard.html';
import { URLUtils, withInitDOM } from 'sgx-base-code';
import StoreRegistry from 'stores/store-registry';
import ConfigService from 'sgx-config-service';

class CmpLoginPromptDashboard extends HTMLElement {
  constructor() {
    super();
    this._onButtonClick = this._onButtonClick.bind(this);
  }

  initDOM() {
    this.appendChild(tmpl.getNode());
    this._promptText = this.querySelector('.cmp-login-prompt-dashboard-text');
    this._button = this.querySelector('.cmp-login-prompt-dashboard-button');
    this._tokenManagementLink = this.querySelector('.cmp-login-prompt-dashboard-token-management-link');
    this._cdpAccountOpeningLink = this.querySelector('.cmp-login-prompt-dashboard-cdp-account-opening-link');
  }

  connectedCallback() {
    this._button.addEventListener('click', this._onButtonClick);
    const lang = StoreRegistry.appSettings.getData('lang') || 'en';
    this._tokenManagementLink.href = ConfigService.links.SGX_V2_TOKEN_MANAGEMENT[lang.replace(/-/g, '_')];
    this._cdpAccountOpeningLink.href = ConfigService.links.SGX_V2_CREATE_INDIVIDUAL_ACCOUNT[lang.replace(/-/g, '_')];
  }

  disconnectedCallback() {
    this._button.removeEventListener('click', this._onButtonClick);
  }

  setConfig({ promptText }) {
    const { webview } = StoreRegistry.appContext.getData();
    this._promptText.innerHTML = !webview ? promptText.replace('target="_blank"', '') : promptText;
  }

  show() {
    this.style.display = 'block';
  }

  hide() {
    this.style.display = 'none';
  }

  _onButtonClick(event) {
    const url = URLUtils.setQueryParams('/login.html', {});
    window.location.assign(url);
  }
}

customElements.define('cmp-login-prompt-dashboard', withInitDOM(CmpLoginPromptDashboard));
