import {
  BaseBusService
} from 'services/base-bus-service';

/**
 * Gets the transaction signing QR code elements for SingPass.
 */
class SingPassTxnSigningService extends BaseBusService {
  constructor() {
    super();
    this._topic = 'singpass-txn-signing';
  }

  /**
   * Initialize SingPass transaction signing QR code module.
   * @param {Object} bus service bus class
   * @param {Array} params SingPass QR code init parameters
   */
  handleRequest(bus, params) {
    return new Promise((resolve, reject) => {
      try {
        window.NDI.initTxnSigning(...params);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default new SingPassTxnSigningService();
