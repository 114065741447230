import {withInitDOM} from '@sgx/sgx-base-code';
import i18n from 'sgx-localisation-service';

/**
 * A custom HTML element
 * @module sgx-indices-card
 * @type {HTMLElement}
 */

export default class SgxIndicesCard extends HTMLElement {
  // #region Custom Element API

  constructor() {
    super();
  }

  initDOM() {
  }

  connectedCallback() {
    this._setLayout();
    this.classList.add('sgx-indices-card');
    this._link = this.querySelector('.sgx-indices-card-link');
    this._indexName = this.querySelector('.sgx-indices-card-name');
    this._arrow = this.querySelector('.sgx-indices-card-arrow');
    this._indexValue = this.querySelector('.sgx-indices-card-value');
    this._indexPercentage = this.querySelector('.sgx-indices-card-percentage');
    this._miniChart = this.querySelector('sgx-mini-chart');
  }

  // #endregion

  // #region Public Methods

  setData(data) {
    if (!data) {
      throw new Error('sgx-indices-card setData method should have data param');
    }
    this._data = data;

    this._link.href = data.link;
    this._link.setAttribute('data-analytics-label', data.name);
    this._indexName.title = data.name;
    this._indexName.textContent = data.name;
    if (typeof data.value !== 'undefined' && data.value !== null) {
      this._indexValue.textContent = data.value;
    }

    const chartData = {
      points: data.historicData || []
    };

    if (data.change > 0) {
      chartData.colour = '#74ac00';
    } else if (data.change < 0) {
      chartData.colour = '#e60000';
    }
    this._miniChart.setData(chartData);

    if (data.change > 0) {
      this._arrow.className = 'sgx-indices-card-arrow sgx-icon--before sgx-indices-card-arrow--up';
      this._indexPercentage.className = 'sgx-indices-card-percentage--positive';
      this._indexPercentage.textContent = `+${data.change} (+${data.percentChange}%)`;
    } else if (data.change < 0) {
      this._arrow.className = 'sgx-indices-card-arrow sgx-icon--before sgx-indices-card-arrow--down';
      this._indexPercentage.className = 'sgx-indices-card-percentage--negative';
      this._indexPercentage.textContent = `${data.change} (${data.percentChange}%)`;
    } else {
      this._arrow.className = 'sgx-indices-card-arrow sgx-icon--before sgx-indices-card-arrow--none';
      this._indexPercentage.className = 'sgx-indices-card-percentage--neutral';
      this._indexPercentage.textContent = `(${i18n.getTranslation('cmp-price-info.no-price-change')})`;
    }
  }

  get data() {
    return this._data || {};
  }

  _setLayout() {
    const layout1 = `<a class="sgx-indices-card-link" href="" target="_blank"></a>
      <div class="sgx-indices-card-container">
        <span class="sgx-indices-card-name sgx-base-text-body-14"></span>
        <sgx-mini-chart class="sgx-indices-card-chart"></sgx-mini-chart>
        <div class="indices-card-detail-container">
          <div class="indices-card-detail-value sgx-base-text-body-14">
            <span class="sgx-indices-card-arrow"></span>
            <span class="sgx-indices-card-value"></span>
          </div>
          <div class="indices-card-detail-percent-change sgx-base-text-body-14">
            <span class="sgx-indices-card-percentage"></span>
          </div>
        </div>
      </div>`
    const layout2 = `<a class="sgx-indices-card-link" href="" target="_blank"></a>
      <div class="sgx-indices-card-container">
        <span class="sgx-indices-card-name sgx-base-text-body-14"></span>
        <div class="indices-card-detail-container">
          <div class="indices-card-detail-value sgx-base-text-body-14">
            <span class="sgx-indices-card-arrow"></span>
            <span class="sgx-indices-card-value"></span>
          </div>
          <div class="indices-card-detail-percent-change sgx-base-text-body-14">
            <span class="sgx-indices-card-percentage"></span>
          </div>
        </div>
      </div>
      <sgx-mini-chart class="sgx-indices-card-chart"></sgx-mini-chart>`;

    this.innerHTML = `${this.dataset.layout === 'layout2' ? layout2 : layout1}`;
  }
}

// #endregion

// #region Registration of the Element

customElements.define('sgx-indices-card', withInitDOM(SgxIndicesCard));

//#endregion
